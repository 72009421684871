
import { defineComponent } from "vue";
import { mapGetters, mapActions } from "vuex";
import Card from "primevue/card";
import Button from "primevue/button";

import LookupDataTable from "@/components/Sales/LineItems/LookupDataTable.vue";
import Pdf from "@/types/pdf";
import SalesOrder from "@/types/salesorder";
import Customer from "@/types/customer";
import Lookup from "@/types/lookup";
import EmailFileDialog from "@/components/UI/EmailFileDialog.vue";
import { cloneDeep } from "lodash";

import SalesService from "@/services/SalesService";
import CustomerService from "@/services/CustomerService";
import { AccessRights } from "@/types/accessControl/AbstractAccessGetter";

const customerService = new CustomerService();
const salesService = new SalesService();

export default defineComponent({
  components: {
    Card,
    LookupDataTable,
    Button,
    EmailFileDialog,
  },
  emits: ["release-order"],
  inject: ["orderAccessRights"],
  data() {
    return {
      activeTab: 0,
      dateRanges: [] as any[],
      extraDateRanges: [] as any[],
      showAddTabDialog: false,
      showEmailDialog: false,
      orderIdToEmail: "",
      customerContacts: [] as any[],
      newTab: {
        label: "",
        range: "",
      },
      loadingReleaseOrder: {} as any,
    };
  },
  computed: {
    ...mapGetters({
      getActiveTab: "accountingPayables/getActiveTab",
      getDefaultDateRanges: "accountingPayables/getDefaultDateRanges",
      getCustomDateRanges: "accountingPayables/getCustomDateRanges",
      getNewPayableCount: "accountingInquiry/getNewPayableCount",
      mrkAccountingOrdersLookup: "mrkControl/getAccountingOrdersLookup",
      getClient: "session/getClient",
      getPDFs: "sales/getPDFS",
      getLoadingPDFs: "sales/getLoadingPDFs",
    }),
    getLookup(): Lookup {
      const accountLookupName = this.mrkAccountingOrdersLookup;
      const lookupData = accountLookupName.split("*");
      return {
        key: accountLookupName,
        file: lookupData[0],
        lookupName: lookupData[1],
        correls: "sold_to",
      } as Lookup;
    },
    lookupParams() {
      return {};
    },
    editAccess(): boolean {
      return (this.orderAccessRights as AccessRights).change;
    },
  },
  created() {
    this.activeTab = this.getActiveTab;
    this.setCurrentActivity("PARTS");
  },
  methods: {
    ...mapActions({
      addCustomDateRange: "accountingPayables/addCustomDateRange",
      removeCustomDateRange: "accountingPayables/removeCustomDateRange",
      updateCustomDateRange: "accountingPayables/updateCustomDateRange",
      addOpenedAccountingTab: "accountingInquiry/addOpenedAccountingTab",
      changeMainActiveTab: "accountingOrders/changeMainActiveTab",
      getOrderPDF: "sales/getOrderPDF",
      addNotification: "notification/add",
      setCurrentActivity: "pos/setCurrentActivity",
    }),
    async handleRowClick(row: any) {
      const response = await salesService.getOrderById(row.so_id, "cust_name");

      const record = new SalesOrder();
      record.initFromSalesOrder(response);
      const data = {
        record: record,
        old_record: cloneDeep(record),
        accountType: "orders",
        lineItemsMap: [],
        lineItemsActiveTab: 0,
        activeTab: 0,
      };
      await this.addOpenedAccountingTab(data);
      this.$router.push(`/accounting/orders/${row.so_id}`);
    },
    getSalesOrderPDF(id: string) {
      this.getOrderPDF({ client: this.getClient, recordId: id });
    },
    salePDFIcon(id: string) {
      let downloaded: Array<Pdf> = this.getPDFs;
      let downloading: boolean =
        this.getLoadingPDFs.find((i: string) => i === id) !== undefined;
      return {
        "pi pi-download":
          downloaded.find((i: Pdf) => i.id === id) === undefined &&
          !downloading,
        "pi pi-spin pi-spinner": downloading,
        "pi pi-file-pdf":
          downloaded.find((i: Pdf) => i.id === id) !== undefined &&
          !downloading,
      };
    },
    async releaseOrder(event: any, id: string) {
      event.stopPropagation();
      try {
        this.loadingReleaseOrder[id] = true;
        const resp = await salesService.getOrderById(id, "");
        this.$emit("release-order", resp);
      } catch (e) {
        console.log(e);
      } finally {
        this.loadingReleaseOrder[id] = false;
      }
    },
    async handleEmailPDF(salesOrder: SalesOrder) {
      const orderId = salesOrder.so_id;
      this.orderIdToEmail = orderId;

      await customerService
        .getCustomer(salesOrder.sold_to, "", "contact_name contact_email")
        .then((response: any) => {
          const customer = response as Customer;
          this.customerContacts = customer.contact_id_items;
        })
        .catch((error) => {
          this.addNotification({
            message: `Customer contacts could not load: ${error}`,
            type: "error",
          });
        })
        .finally(() => {
          this.showEmailDialog = true;
        });
    },
    sendEmail(data: any) {
      salesService
        .getOrderPDF(this.orderIdToEmail, this.getClient, data)
        .then((response) => {
          if (response === "success") {
            this.addNotification({
              message: `Sales Order has been emailed successfully`,
              type: "success",
            });
          } else {
            this.addNotification({
              message: `Sales Order was not sent`,
              type: "error",
            });
          }
        })
        .catch((error) => {
          this.addNotification({
            message: `Sales Order could not be sent: ${error}`,
            type: "error",
          });
        });
    },
  },
  watch: {
    getCustomDateRanges: {
      handler() {
        this.extraDateRanges = this.getCustomDateRanges;
      },
      deep: true,
    },
    getActiveTab(idx: number) {
      this.activeTab = idx;
    },
  },
});

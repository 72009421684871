import { defineStore } from "pinia";
import { assign, cloneDeep } from "lodash";
import {
  InventoryObject,
  PartsObject,
  ReceiptsObject,
  FileType,
  isPartsObject,
  EnsurePartResult,
  InventoryType,
} from "@/types/state/inventoryModule";
import Receipts, { ReceiptQuantityItem } from "@/types/receipts";
import PO from "@/types/po";
import InventoryState from "@/types/state/inventory";
import Commit from "@/types/commit";
import Alloc from "@/types/alloc";
import Transaction from "@/types/transaction";
import Utils from "@/utility/utils";

// Services
import ReceiptsService, {
  ReceiptsMetaData,
  ReceiptsPostRequest,
} from "@/services/ReceiptsService";
import PartsService from "@/services/PartsService";
import InventoryService from "@/services/inventory";
import AllocationService from "@/services/AllocationService";
import CommitService from "@/services/CommitService";
import TransactionService from "@/services/TransactionService";
import Part from "@/types/part";
import { ref, computed } from "@vue/runtime-core";
import { useLocalStorage } from "@vueuse/core";
import OrdersService from "@/services/OrdersService";
import Order from "@/types/order";
import LotService from "@/services/LotInfoService";
import Lot from "@/types/lot";
import ShortsService from "@/services/ShortsService";
import UsageService from "@/services/UsageService";
import Usage from "@/types/usage";
import { PiniaLocalStorageKeys } from "@/utility/PiniaLocalStorageKeys";

// Initialize services
const receiptsService = new ReceiptsService();
const partService = new PartsService();
const inventoryService = new InventoryService(
  process.env.VUE_APP_ABSTRACTION_API,
);
const allocationService = new AllocationService(
  process.env.VUE_APP_ABSTRACTION_API,
);
const commitService = new CommitService(process.env.VUE_APP_ABSTRACTION_API);
const transactionService = new TransactionService(
  process.env.VUE_APP_ABSTRACTION_API,
);

const lotService = new LotService(process.env.VUE_APP_ABSTRACTION_API);
const ordersService = new OrdersService(process.env.VUE_APP_ABSTRACTION_API);
const shortsService = new ShortsService();
const usageService = new UsageService();

const allowCaching = true;

export const useInventoryStore = defineStore("inventoryModule", () => {
  // State
  const activeTab = ref(0);

  const inventoryMap = ref<InventoryObject<InventoryType>[]>([]);

  // Getters
  const getInventory = computed(() => inventoryMap.value);

  const getActiveInventory = computed(
    () => inventoryMap.value[activeTab.value - 1],
  );

  const getActiveTab = computed(() => activeTab.value);

  const getActiveReceipts = computed(() => {
    if (inventoryMap.value.length > activeTab.value - 1) {
      const map = inventoryMap.value[activeTab.value - 1];
      if (map && map.fileType === FileType.RECEIPTS) {
        return map as ReceiptsObject;
      }
    }
    return null;
  });

  const getActivePart = computed(() => {
    if (inventoryMap.value.length > activeTab.value - 1) {
      const map = inventoryMap.value[activeTab.value - 1];
      if (map && map.fileType === FileType.PARTS) {
        return map as PartsObject;
      }
    }
    return null;
  });

  // Actions
  function setActiveTab(tab: number) {
    activeTab.value = tab;
  }

  function setActiveTabById(guid: string) {
    const index = inventoryMap.value.findIndex((map) => map.id === guid);
    if (index != -1) {
      activeTab.value = index + 1;
    }
  }

  function changeActiveTab(tabIndex: number) {
    activeTab.value = tabIndex;
  }

  function removeInventoryById(id: string) {
    inventoryMap.value = inventoryMap.value.filter((map) => map.id !== id);
    saveInventoryModule();
  }

  function resetPartById(id: string) {
    const parts = inventoryMap.value.filter(isPartsObject) as PartsObject[];
    const partMap = parts?.find((map) => map.record.part_no === id);
    if (partMap) {
      partMap.inv = undefined;
      partMap.commit = undefined;
      partMap.alloc = undefined;
      partMap.transactions = undefined;
    }
  }

  function addReceipt(receipts: Receipts, metaData: ReceiptsMetaData, po?: PO) {
    const receipt = new ReceiptsObject(receipts, metaData, po);
    inventoryMap.value = [receipt, ...inventoryMap.value];
    saveInventoryModule();
    return { id: receipt.id };
  }

  function addPart(part: Part) {
    const partObject = new PartsObject(part);
    inventoryMap.value = [partObject, ...inventoryMap.value];
    saveInventoryModule();
    return { id: partObject.id };
  }

  function updateReceiptsById(receipts: Receipts, id: string, po?: PO) {
    const receiptMap = inventoryMap.value.find(
      (map) => map.id === id,
    ) as ReceiptsObject;

    if (receiptMap) {
      if (receiptMap.metaData.po_number != po?.po_id) {
        receiptMap.metaData.po_number = po?.po_id || "";
      }
      if (
        receipts.receipts_id &&
        receiptMap.old_record.receipts_id != receipts.receipts_id
      ) {
        receiptMap.old_record = cloneDeep(receipts);
      }
      receiptMap.record = cloneDeep(receipts);
      receiptMap.po = po;
    } else {
      const receipt = new ReceiptsObject(
        receipts,
        {
          po_number: po?.po_id || "",
        },
        po,
      );
      inventoryMap.value = [receipt, ...inventoryMap.value];
      saveInventoryModule();
    }
  }

  function addNewReceipt() {
    const receipts = new Receipts();
    const { id } = addReceipt(receipts, {
      po_number: "",
    });
    return { id: id };
  }

  function addNewPart() {
    const part = {} as Part;
    const resp = addPart(part);
    return { id: resp.id };
  }

  async function fetchPartById(id: string, refresh = false) {
    let guid = "";
    const parts = inventoryMap.value.filter(isPartsObject) as PartsObject[];
    const partMap = parts?.find((map) => map.record.part_no === id);
    try {
      if (partMap && !refresh) {
        return { success: true, id: partMap.id };
      }

      const response = (await partService.getPartByID({
        client: "",
        id,
      })) as Part;
      if (response.part_no === id) {
        if (refresh) {
          if (partMap) {
            partMap.record = response;
            partMap.old_record = cloneDeep(response);
            guid = partMap.id;
          }
        } else {
          const part = addPart(response as Part);
          guid = part.id;
        }
      }
    } catch (error: any) {
      return {
        success: false,
        error: Utils.parseErrorMessage(error),
        id: guid,
      };
    }
    return { success: true, id: guid };
  }

  async function ensurePartExists(id: string): Promise<EnsurePartResult> {
    if (!id) {
      return { success: false, error: "Part Number not defined." };
    }

    const parts = inventoryMap.value.filter(isPartsObject) as PartsObject[];
    const partMap = parts?.find((map) => map.record.part_no === id);

    if (!partMap) {
      try {
        const response = (await partService.getPartByID({
          client: "",
          id,
        })) as Part;
        if (response.part_no === id) {
          const { id } = addPart(response);
          return { success: true, id: id };
        }
      } catch (error: any) {
        return { success: false, error: Utils.parseErrorMessage(error) };
      }
    }
    return { success: true, part: partMap, id: partMap?.id };
  }

  async function fetchInventoryById(
    id: string,
    client: string,
    correls: string,
    refresh?: boolean,
  ) {
    const partResult = await ensurePartExists(id);
    if (!partResult.success) {
      return { success: false, error: partResult.error };
    }

    const part = partResult.part;
    if (part?.inv && !refresh) {
      return { success: true, inv: part.inv };
    }

    try {
      const inv: InventoryState = await inventoryService.getInventory(
        id,
        client,
        correls,
      );
      if (inv.part_no === id) {
        part!.inv = inv;
        return { success: true, inv: inv };
      }
      return { success: false, error: "Failed to fetch inventory" };
    } catch (error: any) {
      return { success: false, error: Utils.parseErrorMessage(error) };
    }
  }

  async function fetchAllocationsById({
    id,
    client,
    refresh,
  }: {
    id: string;
    client: string;
    refresh: boolean;
  }) {
    const partResult = await ensurePartExists(id);
    if (!partResult.success) {
      return { error: partResult.error, success: false };
    } else if (partResult.part?.alloc && !refresh) {
      return { success: true, alloc: partResult.part.alloc };
    }

    try {
      const response = await allocationService.getAllocation(id, client);
      if (response.alloc_items[0].part_id === id) {
        const alloc: Alloc = response.alloc_items[0];
        partResult.part!.alloc = alloc;
        return { success: true, alloc: alloc };
      } else {
        return { error: "Failed to fetch allocations" };
      }
    } catch (error: any) {
      return { error: Utils.parseErrorMessage(error) };
    }
  }

  async function fetchShortsById(id: string, refresh: boolean) {
    const partResult = await ensurePartExists(id);
    if (!partResult.success) {
      return { error: partResult.error, success: false };
    } else if (partResult.part?.shorts && !refresh) {
      return { success: true, shorts: partResult.part.shorts };
    }

    try {
      const response = await shortsService.fetchShortsById(id);
      if (response.shorts_items[0].part_no === id) {
        const shorts = response.shorts_items[0];
        partResult.part!.shorts = shorts;
        return { success: true, shorts: shorts };
      } else {
        return { error: "Failed to fetch shorts" };
      }
    } catch (error: any) {
      return { error: Utils.parseErrorMessage(error) };
    }
  }

  async function fetchOrdersById(id: string, refresh: boolean) {
    const partResult = await ensurePartExists(id);
    if (!partResult.success) {
      return { error: partResult.error, success: false };
    } else if (partResult.part?.order && !refresh) {
      return { success: true, order: partResult.part.order };
    }

    try {
      const response = await ordersService.getOrders(id, 1, 100);
      const order: Order = response?.order_items[0];

      if (order.part_id === id) {
        partResult.part!.order = order;
        return { success: true, order: order };
      } else {
        return { error: "Failed to fetch orders." };
      }
    } catch (error: any) {
      return { error: Utils.parseErrorMessage(error) };
    }
  }

  async function fetchUsageById(id: string, refresh: boolean) {
    const partResult = await ensurePartExists(id);
    if (!partResult.success) {
      return { error: partResult.error, success: false };
    } else if (partResult.part?.usage && !refresh) {
      return { success: true, usage: partResult.part.usage };
    }

    try {
      const response = await usageService.fetchUsageById(id);
      const usage: Usage = response?.usage_items[0] as Usage;

      if (usage.part_no === id) {
        partResult.part!.usage = usage;
        return { success: true, usage: usage };
      } else {
        return { error: "Failed to fetch usage." };
      }
    } catch (error: any) {
      return { error: Utils.parseErrorMessage(error) };
    }
  }

  async function fetchLotById(id: string, refresh: boolean) {
    const partResult = await ensurePartExists(id);
    if (!partResult.success) {
      return { error: partResult.error, success: false };
    } else if (partResult.part?.lots && !refresh) {
      return { success: true, lots: partResult.part.lots };
    }

    const lotIds = partResult.part?.inv?.lot_no_items
      ?.map((lot) => lot.lot_no)
      .join(" ");

    try {
      const response = await lotService.getLot(lotIds, "");
      const lots: Array<Lot> = response?.lot_items;

      if (lots.length > 0) {
        partResult.part!.lots = lots;
        return { success: true, lots: lots };
      } else {
        return { error: "Failed to fetch orders." };
      }
    } catch (error: any) {
      return { error: Utils.parseErrorMessage(error) };
    }
  }

  async function fetchCommitmentsById({
    id,
    client,
    refresh,
  }: {
    id: string;
    client: string;
    refresh: boolean;
  }) {
    const partResult = await ensurePartExists(id);
    if (!partResult.success) {
      return { error: partResult.error, success: false };
    } else if (partResult.part?.commit && !refresh) {
      return { success: true, commit: partResult.part.commit };
    }

    try {
      const response = await commitService.getCommit(id, client);

      const commit: Commit = response?.commit_items[0];

      if (commit.part_id === id) {
        partResult.part!.commit = commit;
        return { success: true, commit: commit };
      } else {
        return { error: "Failed to fetch commit." };
      }
    } catch (error: any) {
      return { error: Utils.parseErrorMessage(error) };
    }
  }

  async function fetchTransactionsById({
    id,
    refresh,
  }: {
    id: string;
    client: string;
    rangeStart?: string;
    rangeEnd?: string;
    refresh?: boolean;
  }) {
    const partResult = await ensurePartExists(id);

    if (refresh) {
      await fetchInventoryById(id, "", "", true);
    } else if (!partResult.success) {
      return { error: partResult.error, success: false };
    } else if (partResult.part?.transactions) {
      return { success: true, transactions: partResult.part.transactions };
    }

    try {
      const it_items = partResult?.part?.inv?.it_date_items;

      const ids: string[] = [];
      it_items?.forEach((item) => {
        item?.it_id_items?.forEach((id) => {
          if (id.it_id) {
            ids.push(id.it_id);
          }
        });
      });

      const idString = ids.join(" ");

      const resp = await transactionService.getTransaction(id, idString);

      if (resp?.it_items) {
        partResult.part!.transactions = resp?.it_items;
        return { success: true, transactions: resp?.it_items as Transaction[] };
      } else {
        return { error: "Failed to fetch transactions." };
      }
    } catch (error: any) {
      return { error: Utils.parseErrorMessage(error) };
    }
  }

  async function createReceiptsById({
    receipts,
    id,
  }: {
    receipts: Receipts;
    id: string;
  }) {
    const receiptMap = inventoryMap.value.find(
      (map) => map.id === id,
    ) as ReceiptsObject;

    try {
      const receiptsRequest: ReceiptsPostRequest = {
        record: receipts,
        metaData: JSON.stringify(receiptMap?.metaData),
      };
      const response = await receiptsService.createReceipts(receiptsRequest);

      if (response.response.status != "success") {
        return response.response;
      }

      const { record } = response.response;
      const newReceipts = new Receipts();
      newReceipts.initFromReceipts(record);
      updateReceiptsById(newReceipts, id, receiptMap?.po);
      return response.response;
    } catch (error: any) {
      return { error: Utils.parseErrorMessage(error) };
    }
  }

  async function postReceiptsById({
    receipts,
    id,
  }: {
    receipts: Receipts;
    id: string;
  }) {
    const receiptMap = inventoryMap.value.find(
      (map) => map.id === id,
    ) as ReceiptsObject;

    try {
      const receiptsRequest: ReceiptsPostRequest = {
        record: receipts,
        oldRecord: receiptMap?.old_record,
        metaData: JSON.stringify(receiptMap?.metaData),
      };
      const response = await receiptsService.updateReceiptsById(
        receipts.receipts_id,
        receiptsRequest,
      );

      if (response.response.status != "success") {
        return response.response;
      }

      const { record } = response.response;
      const newReceipts = new Receipts();
      newReceipts.initFromReceipts(record);
      updateReceiptsById(newReceipts, id, receiptMap?.po);
      return response.response;
    } catch (error: any) {
      return { error: Utils.parseErrorMessage(error) };
    }
  }

  async function createReceiptsFromPurchaseOrder({
    po,
    id,
  }: {
    po: PO;
    id?: string;
  }) {
    try {
      const response = await receiptsService.createReceiptsFromPurchaseOrder(
        po.po_id,
      );

      if (response.response.status != "success") {
        throw new Error("Failed to create receipt from purchase order");
      }

      const { record, metaData } = response.response;

      const receipts = new Receipts();
      receipts.initFromReceipts(record);

      receipts.li_no_items.forEach((item) => {
        if (!item.receipt_qty_items) {
          item.receipt_qty_items = [new ReceiptQuantityItem()];
        }
      });

      if (!receipts.rec_date) {
        receipts.rec_date = Utils.roverDateString(new Date());
      }

      receipts.vendor_name = metaData?.vendor_name;
      receipts.addScheduleLineItem(metaData?.line_items);

      if (id) {
        updateReceiptsById(receipts, id, po);
      } else {
        const resp = addReceipt(receipts, { po_number: po.po_id }, po);
        id = resp.id;
        setActiveTab(1);
      }
    } catch (error) {
      return { success: false, error: Utils.parseErrorMessage(error) };
    }
    return { success: true, id: id };
  }

  async function getReceiptPDF(payload: {
    recordId: string;
    reportName: string;
    pageWidth: string;
    email?: string;
  }) {
    try {
      const resp = await receiptsService.getReceiptPdf({
        id: payload.recordId,
        reportName: payload.reportName,
        pageWidth: payload.pageWidth,
        email: payload.email,
      });

      if (!payload.email) {
        const bufferArray = Utils.base64ToArrayBuffer(resp);
        const blobStore = new Blob([bufferArray], {
          type: "application/pdf",
        });
        const data = window.URL.createObjectURL(blobStore);
        window.open(data, "_blank");
      } else if (resp === "success") {
        return {
          success: true,
          message: "Receipt has been emailed successfully",
        };
      }
    } catch (e) {
      return { success: false, error: Utils.parseErrorMessage(e) };
    }

    return { success: true, message: "Success" };
  }
  function restorePartRecord(id: string) {
    const parts = inventoryMap.value.filter(isPartsObject) as PartsObject[];
    const partMap = parts?.find((map) => map.id === id);

    if (partMap) {
      partMap.record = cloneDeep(partMap.old_record);
    }
  }

  async function savePartRecord(id: string) {
    const parts = inventoryMap.value.filter(isPartsObject) as PartsObject[];
    const partMap = parts?.find((map) => map.id === id);

    if (!partMap) {
      return { success: false, error: "Part is not in the store." };
    }

    let resp = null;
    try {
      if (!partMap?.old_record) {
        resp = await partService.createNewPart(partMap.record);

        if (resp.record) {
          partMap!.record = cloneDeep(resp.record);
          partMap!.old_record = cloneDeep(resp.record);
          resp = {
            success: true,
            message: "Created new part.",
          };
        } else if (resp.status === "success") {
          resp = { success: true, message: "Successfully created Part." };
        } else {
          resp = { success: false, message: "Part not created" };
        }
      } else {
        resp = await partService.updatePartById({
          newPart: partMap.record,
          oldPart: partMap.old_record,
          part_id: partMap.record.part_no,
          metaData: JSON.stringify({
            api_version: "2",
            process_type: "SAVE",
          }),
        });
        if (resp.record) {
          partMap!.record = cloneDeep(resp.record);
          partMap!.old_record = cloneDeep(resp.record);
          resp = {
            success: true,
            message: "Updated part.",
          };
        } else if (resp.status === "success") {
          resp = { success: true, message: "Successfully updated Part." };
        } else {
          resp = { success: false, message: "Part not updated" };
        }
      }
    } catch (e) {
      resp = { success: false, error: e };
    }

    return resp;
  }

  function saveInventoryModule() {
    if (!allowCaching) {
      return;
    }

    sessionStorage.setItem(
      PiniaLocalStorageKeys.INVENTORY_MODULE_MAP,
      JSON.stringify(inventoryMap.value),
    );
  }
  function loadInventoryMap() {
    if (!allowCaching) {
      return;
    }

    if (inventoryMap.value.length > 0) {
      return;
    }

    const data = sessionStorage.getItem(
      PiniaLocalStorageKeys.INVENTORY_MODULE_MAP,
    );
    if (data) {
      const invObjArray = JSON.parse(data) as InventoryObject<InventoryType>[];
      inventoryMap.value = invObjArray.map((obj) => {
        if (obj.fileType === FileType.PARTS) {
          const newPart = new PartsObject({} as Part);
          assign(newPart, obj);
          return newPart;
        } else if (obj.fileType === FileType.RECEIPTS) {
          const newReceipt = new ReceiptsObject(
            {} as Receipts,
            {} as ReceiptsMetaData,
          );
          assign(newReceipt, obj);
          return newReceipt;
        } else {
          return obj;
        }
      });
    }
  }

  function loadStore() {
    if (!allowCaching) {
      return;
    }

    setInterval(
      () => {
        saveInventoryModule();
      },
      1000 * 60 * 15, // auto save every 15 mins
    );
    window.addEventListener("beforeunload", saveInventoryModule);
  }

  function unloadStore() {
    if (!allowCaching) {
      return;
    }

    window.removeEventListener("beforeunload", saveInventoryModule);
  }

  return {
    // State
    inventoryMap,
    activeTab,

    // Getters
    getInventory,
    getActiveInventory,
    getActiveTab,
    getActiveReceipts,
    getActivePart,

    // Actions
    loadStore,
    unloadStore,
    loadInventoryMap,
    setActiveTab,
    setActiveTabById,
    changeActiveTab,
    createReceiptsFromPurchaseOrder,
    addReceipt,
    addNewReceipt,
    addNewPart,
    createReceiptsById,
    postReceiptsById,
    removeInventoryById,
    updateReceiptsById,
    ensurePartExists,
    fetchPartById,
    fetchInventoryById,
    fetchTransactionsById,
    fetchAllocationsById,
    fetchCommitmentsById,
    fetchOrdersById,
    fetchShortsById,
    fetchUsageById,
    fetchLotById,
    resetPartById,
    getReceiptPDF,
    savePartRecord,
    restorePartRecord,
  };
});

import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "grid m-0" }
const _hoisted_2 = { class: "col-12 p-0 m-0 mt-1" }
const _hoisted_3 = { class: "grid mt-1" }
const _hoisted_4 = { class: "col-12" }
const _hoisted_5 = { class: "grid p-0" }
const _hoisted_6 = { class: "col-12 lg:col-4" }
const _hoisted_7 = { class: "p-inputgroup mt-2" }
const _hoisted_8 = { class: "p-inputgroup mt-2" }
const _hoisted_9 = { class: "p-inputgroup mt-2" }
const _hoisted_10 = { class: "p-inputgroup mt-2" }
const _hoisted_11 = { class: "p-inputgroup mt-2" }
const _hoisted_12 = { class: "p-inputgroup mt-2" }
const _hoisted_13 = { class: "p-inputgroup mt-2" }
const _hoisted_14 = { class: "col-12 lg:col-4" }
const _hoisted_15 = { class: "p-inputgroup mt-2" }
const _hoisted_16 = { class: "p-inputgroup mt-2" }
const _hoisted_17 = { class: "p-inputgroup mt-2" }
const _hoisted_18 = { class: "col-12 lg:col-4" }
const _hoisted_19 = { class: "p-inputgroup mt-2" }
const _hoisted_20 = { class: "p-inputgroup mt-2" }
const _hoisted_21 = { class: "p-inputgroup mt-2" }
const _hoisted_22 = { class: "col-12 p-0 m-0 mt-1" }
const _hoisted_23 = { class: "grid mt-1" }
const _hoisted_24 = { class: "col-12" }
const _hoisted_25 = { class: "grid rows-inputnumber-left" }
const _hoisted_26 = { class: "col-12 m-0 p-0" }
const _hoisted_27 = { class: "rows-wrapper" }
const _hoisted_28 = { class: "col-12 m-0 flex" }
const _hoisted_29 = { class: "grid" }
const _hoisted_30 = { class: "col-11" }
const _hoisted_31 = { class: "grid" }
const _hoisted_32 = { class: "mt-2 col-2 flex justify-content-center align-items-center" }
const _hoisted_33 = { class: "mt-2 col-2 flex justify-content-center align-items-center" }
const _hoisted_34 = { class: "mt-2 col-2 flex justify-content-center align-items-center" }
const _hoisted_35 = { class: "mt-2 col-2 flex justify-content-center align-items-center" }
const _hoisted_36 = { class: "col-11" }
const _hoisted_37 = { class: "grid" }
const _hoisted_38 = { class: "mt-2 col-2 flex justify-content-center align-items-center" }
const _hoisted_39 = { class: "col-11" }
const _hoisted_40 = { class: "grid" }
const _hoisted_41 = { class: "mt-2 col-2 flex justify-content-left align-items-center" }
const _hoisted_42 = { class: "col-12 m-0 flex" }
const _hoisted_43 = { class: "grid" }
const _hoisted_44 = { class: "col-12 m-0 flex" }
const _hoisted_45 = { class: "grid" }
const _hoisted_46 = { class: "col-12" }
const _hoisted_47 = { class: "col-12" }
const _hoisted_48 = { class: "col-12 m-0" }
const _hoisted_49 = { class: "grid" }
const _hoisted_50 = { class: "add-remove-row-button-wrapper" }
const _hoisted_51 = { class: "col-12 m-0 checkbox-wrapper" }
const _hoisted_52 = { class: "grid" }
const _hoisted_53 = { class: "col-2 col-offset-2 field-checkbox text-sm text-600" }
const _hoisted_54 = { class: "col-2 field-checkbox text-sm text-600" }
const _hoisted_55 = { class: "deal-sheet-notes-wrapper col-12 m-0" }
const _hoisted_56 = { class: "col-12 m-0" }
const _hoisted_57 = { class: "grid" }
const _hoisted_58 = { class: "p-inputgroup col-6 mt-2" }
const _hoisted_59 = { class: "p-inputgroup col-6 mt-2" }
const _hoisted_60 = { class: "col-6 mt-2" }
const _hoisted_61 = { class: "col-6 mt-2" }
const _hoisted_62 = { class: "p-inputgroup col-6 mt-2" }
const _hoisted_63 = { class: "p-inputgroup col-6 mt-2" }
const _hoisted_64 = { class: "col-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Calendar = _resolveComponent("Calendar")!
  const _component_Card = _resolveComponent("Card")!
  const _component_CollapsibleSection = _resolveComponent("CollapsibleSection")!
  const _component_InputNumber = _resolveComponent("InputNumber")!
  const _component_Checkbox = _resolveComponent("Checkbox")!
  const _component_Divider = _resolveComponent("Divider")!
  const _component_PosRow = _resolveComponent("PosRow")!
  const _component_Button = _resolveComponent("Button")!
  const _component_TextArea = _resolveComponent("TextArea")!
  const _component_Signature = _resolveComponent("Signature")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_CollapsibleSection, {
          isOpen: _ctx.isDiscountIconDown,
          header: "Contact Info",
          "onUpdate:isOpen": _cache[15] || (_cache[15] = ($event: any) => (_ctx.toggleIcon('Discount')))
        }, {
          content: _withCtx(() => [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _createVNode(_component_Card, null, {
                  content: _withCtx(() => [
                    _cache[47] || (_cache[47] = _createElementVNode("h4", { id: "header-contact-info" }, "Customer Discount Deal", -1)),
                    _createElementVNode("div", _hoisted_5, [
                      _createElementVNode("div", _hoisted_6, [
                        _createElementVNode("div", _hoisted_7, [
                          _cache[34] || (_cache[34] = _createElementVNode("small", {
                            class: "p-inputgroup-addon font-semibold general-label-color",
                            style: { width: '156px' }
                          }, "Customer ID", -1)),
                          _createVNode(_component_InputText, {
                            modelValue: _ctx.dealsheet.cust_id,
                            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.dealsheet.cust_id) = $event)),
                            type: "text",
                            class: "text-sm",
                            disabled: "true"
                          }, null, 8, ["modelValue"])
                        ]),
                        _createElementVNode("div", _hoisted_8, [
                          _cache[35] || (_cache[35] = _createElementVNode("small", {
                            class: "p-inputgroup-addon font-semibold general-label-color",
                            style: { width: '156px' }
                          }, "Customer Name", -1)),
                          _createVNode(_component_InputText, {
                            modelValue: _ctx.dealsheet.cust_name,
                            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.dealsheet.cust_name) = $event)),
                            type: "text",
                            class: "text-sm",
                            disabled: "true"
                          }, null, 8, ["modelValue"])
                        ]),
                        _createElementVNode("div", _hoisted_9, [
                          _cache[36] || (_cache[36] = _createElementVNode("small", {
                            class: "p-inputgroup-addon font-semibold general-label-color",
                            style: { width: '156px' }
                          }, "Street Address", -1)),
                          _createVNode(_component_InputText, {
                            modelValue: _ctx.dealsheet.street_address,
                            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.dealsheet.street_address) = $event)),
                            type: "text",
                            class: "text-sm",
                            disabled: "true"
                          }, null, 8, ["modelValue"])
                        ]),
                        _createElementVNode("div", _hoisted_10, [
                          _cache[37] || (_cache[37] = _createElementVNode("small", {
                            class: "p-inputgroup-addon font-semibold general-label-color",
                            style: { width: '156px' }
                          }, "City", -1)),
                          _createVNode(_component_InputText, {
                            modelValue: _ctx.dealsheet.city,
                            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.dealsheet.city) = $event)),
                            type: "text",
                            class: "text-sm",
                            disabled: "true"
                          }, null, 8, ["modelValue"])
                        ]),
                        _createElementVNode("div", _hoisted_11, [
                          _cache[38] || (_cache[38] = _createElementVNode("small", {
                            class: "p-inputgroup-addon font-semibold general-label-color",
                            style: { width: '156px' }
                          }, "State", -1)),
                          _createVNode(_component_InputText, {
                            modelValue: _ctx.dealsheet.state,
                            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.dealsheet.state) = $event)),
                            type: "text",
                            class: "text-sm",
                            disabled: "true"
                          }, null, 8, ["modelValue"])
                        ]),
                        _createElementVNode("div", _hoisted_12, [
                          _cache[39] || (_cache[39] = _createElementVNode("small", {
                            class: "p-inputgroup-addon font-semibold general-label-color",
                            style: { width: '156px' }
                          }, "Zip", -1)),
                          _createVNode(_component_InputText, {
                            modelValue: _ctx.dealsheet.zip,
                            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.dealsheet.zip) = $event)),
                            type: "text",
                            class: "text-sm",
                            disabled: "true"
                          }, null, 8, ["modelValue"])
                        ]),
                        _createElementVNode("div", _hoisted_13, [
                          _cache[40] || (_cache[40] = _createElementVNode("small", {
                            class: "p-inputgroup-addon font-semibold general-label-color",
                            style: { width: '156px' }
                          }, "Number of shops", -1)),
                          _createVNode(_component_InputText, {
                            modelValue: _ctx.dealsheet.num_of_shops,
                            "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.dealsheet.num_of_shops) = $event)),
                            type: "text",
                            class: "text-sm"
                          }, null, 8, ["modelValue"])
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_14, [
                        _createElementVNode("div", _hoisted_15, [
                          _cache[41] || (_cache[41] = _createElementVNode("small", {
                            class: "p-inputgroup-addon font-semibold general-label-color",
                            style: { width: '156px' }
                          }, "Paint Supplier Name", -1)),
                          _createVNode(_component_InputText, {
                            modelValue: _ctx.dealsheet.paint_supplier_name,
                            "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.dealsheet.paint_supplier_name) = $event)),
                            type: "text",
                            class: "text-sm"
                          }, null, 8, ["modelValue"])
                        ]),
                        _createElementVNode("div", _hoisted_16, [
                          _cache[42] || (_cache[42] = _createElementVNode("small", {
                            class: "p-inputgroup-addon font-semibold general-label-color",
                            style: { width: '156px' }
                          }, "Paint Supplier Rep", -1)),
                          _createVNode(_component_InputText, {
                            modelValue: _ctx.dealsheet.paint_supplier_rep,
                            "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.dealsheet.paint_supplier_rep) = $event)),
                            type: "text",
                            class: "text-sm"
                          }, null, 8, ["modelValue"])
                        ]),
                        _createElementVNode("div", _hoisted_17, [
                          _cache[43] || (_cache[43] = _createElementVNode("small", {
                            class: "p-inputgroup-addon font-semibold general-label-color",
                            style: { width: '156px' }
                          }, "Paint Phone", -1)),
                          _createVNode(_component_InputText, {
                            modelValue: _ctx.dealsheet.paint_phone_num,
                            "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.dealsheet.paint_phone_num) = $event)),
                            type: "text",
                            class: "text-sm"
                          }, null, 8, ["modelValue"])
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_18, [
                        _createElementVNode("div", _hoisted_19, [
                          _cache[44] || (_cache[44] = _createElementVNode("small", {
                            class: "p-inputgroup-addon font-semibold general-label-color",
                            style: { width: '156px' }
                          }, "Agreement Start Date", -1)),
                          _createVNode(_component_Calendar, {
                            modelValue: _ctx.dealsheet.agreement_start_date,
                            "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.dealsheet.agreement_start_date) = $event)),
                            showIcon: true,
                            dateFormat: "mm-dd-yy",
                            inputClass: "text-sm",
                            onDateSelect: _cache[11] || (_cache[11] = ($event: any) => (
                            _ctx.formatDate($event, 'agreement_start_date')
                          ))
                          }, null, 8, ["modelValue"])
                        ]),
                        _createElementVNode("div", _hoisted_20, [
                          _cache[45] || (_cache[45] = _createElementVNode("small", {
                            class: "p-inputgroup-addon font-semibold general-label-color",
                            style: { width: '156px' }
                          }, "Agreement End Date", -1)),
                          _createVNode(_component_Calendar, {
                            modelValue: _ctx.dealsheet.agreement_end_date,
                            "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.dealsheet.agreement_end_date) = $event)),
                            showIcon: true,
                            dateFormat: "mm-dd-yy",
                            inputClass: "text-sm",
                            onDateSelect: _cache[13] || (_cache[13] = ($event: any) => (
                            _ctx.formatDate($event, 'agreement_end_date')
                          ))
                          }, null, 8, ["modelValue"])
                        ]),
                        _createElementVNode("div", _hoisted_21, [
                          _cache[46] || (_cache[46] = _createElementVNode("small", {
                            class: "p-inputgroup-addon font-semibold general-label-color",
                            style: { width: '156px' }
                          }, "IF Sales Rep", -1)),
                          _createVNode(_component_InputText, {
                            modelValue: _ctx.dealsheet.if_sales_rep,
                            "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.dealsheet.if_sales_rep) = $event)),
                            type: "text",
                            class: "text-sm"
                          }, null, 8, ["modelValue"])
                        ])
                      ])
                    ])
                  ]),
                  _: 1
                })
              ])
            ])
          ]),
          _: 1
        }, 8, ["isOpen"])
      ]),
      _createElementVNode("div", _hoisted_22, [
        _createVNode(_component_CollapsibleSection, {
          isOpen: _ctx.isRowsIconDown,
          header: "Deal Sheet",
          "onUpdate:isOpen": _cache[31] || (_cache[31] = ($event: any) => (_ctx.toggleIcon('inputRows')))
        }, {
          content: _withCtx(() => [
            _createElementVNode("div", _hoisted_23, [
              _createElementVNode("div", _hoisted_24, [
                _createVNode(_component_Card, null, {
                  content: _withCtx(() => [
                    _createElementVNode("div", _hoisted_25, [
                      _createElementVNode("div", _hoisted_26, [
                        _createElementVNode("div", _hoisted_27, [
                          _createElementVNode("div", _hoisted_28, [
                            _createElementVNode("div", _hoisted_29, [
                              _cache[53] || (_cache[53] = _createElementVNode("div", { class: "col-11 grid m-0" }, [
                                _createElementVNode("div", { class: "col-2" }),
                                _createElementVNode("div", { class: "col-2 text-900 text-sm font-semibold" }, " Industrial Finishes "),
                                _createElementVNode("div", { class: "col-1" }),
                                _createElementVNode("div", { class: "col-2 text-900 text-sm font-semibold" }, " Supplier Name "),
                                _createElementVNode("div", { class: "col-2 text-900 text-sm font-semibold" }, " Supplier Share "),
                                _createElementVNode("div", { class: "col-1" }),
                                _createElementVNode("div", { class: "col-2 text-900 text-sm font-semibold" }, " Total ")
                              ], -1)),
                              _createElementVNode("div", _hoisted_30, [
                                _createElementVNode("div", _hoisted_31, [
                                  _cache[48] || (_cache[48] = _createElementVNode("div", { class: "mt-2 col-2 flex flex-column justify-content-center" }, [
                                    _createElementVNode("span", { class: "block text-sm font-semibold text-900" }, "Investments")
                                  ], -1)),
                                  _createElementVNode("div", _hoisted_32, [
                                    _createVNode(_component_InputNumber, {
                                      modelValue: _ctx.dealsheet.if_investment,
                                      "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.dealsheet.if_investment) = $event)),
                                      class: "text-sm",
                                      mode: "currency",
                                      currency: "USD",
                                      locale: "en-US",
                                      minFractionDigits: 2
                                    }, null, 8, ["modelValue"])
                                  ]),
                                  _cache[49] || (_cache[49] = _createElementVNode("div", { class: "mt-2 col-1 flex justify-content-center align-items-center" }, [
                                    _createElementVNode("span", { class: "" }, " + ")
                                  ], -1)),
                                  _createElementVNode("div", _hoisted_33, [
                                    _createVNode(_component_InputText, {
                                      modelValue: _ctx.dealsheet.supplier_name_investment,
                                      "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.dealsheet.supplier_name_investment) = $event)),
                                      class: "text-sm",
                                      style: {"width":"100%"}
                                    }, null, 8, ["modelValue"])
                                  ]),
                                  _createElementVNode("div", _hoisted_34, [
                                    _createVNode(_component_InputNumber, {
                                      modelValue: 
                                      _ctx.dealsheet.supplier_share_investment
                                    ,
                                      "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => ((
                                      _ctx.dealsheet.supplier_share_investment
                                    ) = $event)),
                                      class: "text-sm input-number-custom",
                                      style: {"width":"100%"},
                                      mode: "currency",
                                      currency: "USD",
                                      locale: "en-US",
                                      minFractionDigits: 2
                                    }, null, 8, ["modelValue"])
                                  ]),
                                  _cache[50] || (_cache[50] = _createElementVNode("div", { class: "mt-2 col-1 flex justify-content-center align-items-center" }, [
                                    _createElementVNode("span", { class: "" }, " = ")
                                  ], -1)),
                                  _createElementVNode("div", _hoisted_35, [
                                    _createVNode(_component_InputNumber, {
                                      modelValue: _ctx.dealsheet.total_investment,
                                      "onUpdate:modelValue": _cache[19] || (_cache[19] = ($event: any) => ((_ctx.dealsheet.total_investment) = $event)),
                                      class: "text-sm input-number-custom",
                                      style: {"width":"100%"},
                                      mode: "currency",
                                      currency: "USD",
                                      locale: "en-US",
                                      minFractionDigits: 2
                                    }, null, 8, ["modelValue"])
                                  ])
                                ])
                              ]),
                              _createElementVNode("div", _hoisted_36, [
                                _createElementVNode("div", _hoisted_37, [
                                  _cache[51] || (_cache[51] = _createElementVNode("div", { class: "mt-2 col-2 flex flex-column justify-content-center" }, [
                                    _createElementVNode("span", { class: "block text-sm font-semibold text-900" }, "Est. Monthly Paint Spend")
                                  ], -1)),
                                  _createElementVNode("div", _hoisted_38, [
                                    _createVNode(_component_InputNumber, {
                                      modelValue: _ctx.dealsheet.est_mo_paint_spend,
                                      "onUpdate:modelValue": _cache[20] || (_cache[20] = ($event: any) => ((_ctx.dealsheet.est_mo_paint_spend) = $event)),
                                      class: "text-sm",
                                      style: {"width":"100%"},
                                      mode: "currency",
                                      currency: "USD",
                                      locale: "en-US",
                                      minFractionDigits: 2
                                    }, null, 8, ["modelValue"])
                                  ])
                                ])
                              ]),
                              _createElementVNode("div", _hoisted_39, [
                                _createElementVNode("div", _hoisted_40, [
                                  _cache[52] || (_cache[52] = _createElementVNode("div", { class: "mt-2 col-2 flex flex-column justify-content-center" }, [
                                    _createElementVNode("span", { class: "block text-sm font-semibold text-900" }, "Consignment Products?")
                                  ], -1)),
                                  _createElementVNode("div", _hoisted_41, [
                                    _createVNode(_component_Checkbox, {
                                      modelValue: _ctx.dealsheet.consignment_products,
                                      "onUpdate:modelValue": _cache[21] || (_cache[21] = ($event: any) => ((_ctx.dealsheet.consignment_products) = $event)),
                                      id: "",
                                      binary: true
                                    }, null, 8, ["modelValue"])
                                  ])
                                ])
                              ])
                            ]),
                            _cache[54] || (_cache[54] = _createElementVNode("div", { class: "add-remove-row-button-wrapper" }, null, -1))
                          ]),
                          _createVNode(_component_Divider, {
                            type: "solid",
                            class: "col-12 mx-4"
                          }),
                          _createElementVNode("div", _hoisted_42, [
                            _createElementVNode("div", _hoisted_43, [
                              _cache[55] || (_cache[55] = _createElementVNode("div", { class: "col-12 grid m-0" }, [
                                _createElementVNode("div", { class: "col-2" }),
                                _createElementVNode("div", { class: "col-2 text-900 text-sm font-semibold" }, " Supplier Name "),
                                _createElementVNode("div", { class: "col-2 text-900 text-sm font-semibold" }, " Prod Line "),
                                _createElementVNode("div", { class: "col-2 text-900 text-sm font-semibold" }, " Sub Prod Line "),
                                _createElementVNode("div", { class: "col-2" }),
                                _createElementVNode("div", { class: "col-2 text-900 text-sm font-semibold" }, " +Supp% ")
                              ], -1)),
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.dealsheet.month_end_credit_from_supplier_to_if, (value, index) => {
                                return (_openBlock(), _createElementBlock("div", {
                                  class: "col-12 m-0",
                                  key: index
                                }, [
                                  _createVNode(_component_PosRow, {
                                    rowLabel: "Month end Credit from Supplier to I.F",
                                    posObject: value,
                                    onUpdatePosObject: ($event: any) => (
                                  _ctx.updateMonthEndCredit($event, index)
                                )
                                  }, null, 8, ["posObject", "onUpdatePosObject"])
                                ]))
                              }), 128))
                            ]),
                            _cache[56] || (_cache[56] = _createElementVNode("div", { class: "add-remove-row-button-wrapper" }, null, -1))
                          ]),
                          _createElementVNode("div", _hoisted_44, [
                            _createElementVNode("div", _hoisted_45, [
                              _cache[57] || (_cache[57] = _createElementVNode("div", { class: "col-12 grid" }, [
                                _createElementVNode("div", { class: "col-2" }),
                                _createElementVNode("div", { class: "col-2 text-900 text-sm font-semibold" }, " Supplier Name "),
                                _createElementVNode("div", { class: "col-2 text-900 text-sm font-semibold" }, " Prod Line "),
                                _createElementVNode("div", { class: "col-2 text-900 text-sm font-semibold" }, " Sub Prod Line "),
                                _createElementVNode("div", { class: "col-2 text-900 text-sm font-semibold" }, " IF% "),
                                _createElementVNode("div", { class: "col-2 text-900 text-sm font-semibold" }, " +Supp% ")
                              ], -1)),
                              _createElementVNode("div", _hoisted_46, [
                                _createVNode(_component_PosRow, {
                                  rowLabel: "Month end Credit to Customer",
                                  posObject: 
                                  _ctx.dealsheet.month_end_credit_to_customer
                                ,
                                  onUpdatePosObject: 
                                  _ctx.updateMonthEndCreditToCustomer
                                
                                }, null, 8, ["posObject", "onUpdatePosObject"])
                              ]),
                              _createElementVNode("div", _hoisted_47, [
                                _createVNode(_component_PosRow, {
                                  rowLabel: "AR Credit at Time of Payment Only",
                                  posObject: _ctx.dealsheet.ar_credit,
                                  onUpdatePosObject: _ctx.updateArCredit
                                }, null, 8, ["posObject", "onUpdatePosObject"])
                              ])
                            ]),
                            _cache[58] || (_cache[58] = _createElementVNode("div", { class: "add-remove-row-button-wrapper" }, null, -1))
                          ]),
                          _createElementVNode("div", _hoisted_48, [
                            _createElementVNode("div", _hoisted_49, [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.dealsheet.pos, (value, index) => {
                                return (_openBlock(), _createElementBlock("div", {
                                  key: index,
                                  class: "col-12 flex"
                                }, [
                                  _createVNode(_component_PosRow, {
                                    rowLabel: 'Point of Sale #' + (index + 1),
                                    posObject: value,
                                    onUpdatePosObject: ($event: any) => (_ctx.updatePOS($event, index))
                                  }, null, 8, ["rowLabel", "posObject", "onUpdatePosObject"]),
                                  _createElementVNode("div", _hoisted_50, [
                                    (index === _ctx.dealsheet.pos.length - 1)
                                      ? (_openBlock(), _createBlock(_component_Button, {
                                          key: 0,
                                          icon: "pi pi-plus",
                                          class: "p-button-text",
                                          title: "Add new row",
                                          onClick: _ctx.addNewPosRow
                                        }, null, 8, ["onClick"]))
                                      : (_openBlock(), _createBlock(_component_Button, {
                                          key: 1,
                                          icon: "pi pi-minus",
                                          class: "p-button-text",
                                          title: "Remove row",
                                          onClick: ($event: any) => (_ctx.removeRow(index))
                                        }, null, 8, ["onClick"]))
                                  ])
                                ]))
                              }), 128))
                            ])
                          ])
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_51, [
                        _createElementVNode("div", _hoisted_52, [
                          _createElementVNode("div", _hoisted_53, [
                            _createVNode(_component_Checkbox, {
                              modelValue: _ctx.dealsheet.bodyshop_pricing,
                              "onUpdate:modelValue": _cache[22] || (_cache[22] = ($event: any) => ((_ctx.dealsheet.bodyshop_pricing) = $event)),
                              id: "",
                              binary: true
                            }, null, 8, ["modelValue"]),
                            _cache[59] || (_cache[59] = _createElementVNode("label", { for: "secure" }, "  Bodyshop Pricing", -1))
                          ]),
                          _createElementVNode("div", _hoisted_54, [
                            _createVNode(_component_Checkbox, {
                              modelValue: _ctx.dealsheet.wholesale_pricing,
                              "onUpdate:modelValue": _cache[23] || (_cache[23] = ($event: any) => ((_ctx.dealsheet.wholesale_pricing) = $event)),
                              id: "",
                              binary: true
                            }, null, 8, ["modelValue"]),
                            _cache[60] || (_cache[60] = _createElementVNode("label", { for: "secure" }, "  Wholesale Pricing", -1))
                          ])
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_55, [
                        _cache[61] || (_cache[61] = _createElementVNode("h4", null, "Notes", -1)),
                        _createVNode(_component_TextArea, {
                          modelValue: _ctx.dealsheet.notes,
                          "onUpdate:modelValue": _cache[24] || (_cache[24] = ($event: any) => ((_ctx.dealsheet.notes) = $event)),
                          rows: "9",
                          placeholder: "Enter note here...",
                          class: "text-sm"
                        }, null, 8, ["modelValue"])
                      ]),
                      _createElementVNode("div", _hoisted_56, [
                        _createElementVNode("div", _hoisted_57, [
                          _createElementVNode("div", _hoisted_58, [
                            _cache[62] || (_cache[62] = _createElementVNode("small", {
                              class: "p-inputgroup-addon font-semibold general-label-color",
                              style: { width: '156px' }
                            }, "Submitted by", -1)),
                            _createVNode(_component_InputText, {
                              modelValue: _ctx.dealsheet.submitted_by,
                              "onUpdate:modelValue": _cache[25] || (_cache[25] = ($event: any) => ((_ctx.dealsheet.submitted_by) = $event)),
                              type: "text",
                              class: "text-sm"
                            }, null, 8, ["modelValue"])
                          ]),
                          _createElementVNode("div", _hoisted_59, [
                            _cache[63] || (_cache[63] = _createElementVNode("small", {
                              class: "p-inputgroup-addon font-semibold general-label-color",
                              style: { width: '156px' }
                            }, "Approved by", -1)),
                            _createVNode(_component_InputText, {
                              modelValue: _ctx.dealsheet.approved_by,
                              "onUpdate:modelValue": _cache[26] || (_cache[26] = ($event: any) => ((_ctx.dealsheet.approved_by) = $event)),
                              type: "text",
                              class: "text-sm"
                            }, null, 8, ["modelValue"])
                          ]),
                          _createElementVNode("div", _hoisted_60, [
                            _createVNode(_component_Button, {
                              style: { width: '100%' },
                              label: "Submitted by Signature",
                              onClick: _ctx.showSubmittedBySignature
                            }, null, 8, ["onClick"])
                          ]),
                          _createElementVNode("div", _hoisted_61, [
                            _createVNode(_component_Button, {
                              style: { width: '100%' },
                              label: "Approved by Signature",
                              onClick: _ctx.showApprovedBySignature
                            }, null, 8, ["onClick"])
                          ]),
                          _createElementVNode("div", _hoisted_62, [
                            _cache[64] || (_cache[64] = _createElementVNode("small", {
                              class: "p-inputgroup-addon font-semibold general-label-color",
                              style: { width: '156px' }
                            }, "Date", -1)),
                            _createVNode(_component_Calendar, {
                              modelValue: _ctx.dealsheet.submitted_by_date,
                              "onUpdate:modelValue": _cache[27] || (_cache[27] = ($event: any) => ((_ctx.dealsheet.submitted_by_date) = $event)),
                              showIcon: true,
                              dateFormat: "mm-dd-yy",
                              inputClass: "text-sm",
                              onDateSelect: _cache[28] || (_cache[28] = ($event: any) => (
                              _ctx.formatDate($event, 'submitted_by_date')
                            ))
                            }, null, 8, ["modelValue"])
                          ]),
                          _createElementVNode("div", _hoisted_63, [
                            _cache[65] || (_cache[65] = _createElementVNode("small", {
                              class: "p-inputgroup-addon font-semibold general-label-color",
                              style: { width: '156px' }
                            }, "Date", -1)),
                            _createVNode(_component_Calendar, {
                              modelValue: _ctx.dealsheet.approved_by_date,
                              "onUpdate:modelValue": _cache[29] || (_cache[29] = ($event: any) => ((_ctx.dealsheet.approved_by_date) = $event)),
                              showIcon: true,
                              dateFormat: "mm-dd-yy",
                              inputClass: "text-sm",
                              onDateSelect: _cache[30] || (_cache[30] = ($event: any) => (
                              _ctx.formatDate($event, 'approved_by_date')
                            ))
                            }, null, 8, ["modelValue"])
                          ])
                        ])
                      ]),
                      _cache[66] || (_cache[66] = _createElementVNode("div", { class: "col-8" }, null, -1)),
                      _createElementVNode("div", _hoisted_64, [
                        _createVNode(_component_Button, {
                          style: { width: '100%' },
                          label: "Submit",
                          onClick: _ctx.submitDealSheet
                        }, null, 8, ["onClick"])
                      ])
                    ])
                  ]),
                  _: 1
                })
              ])
            ])
          ]),
          _: 1
        }, 8, ["isOpen"])
      ])
    ]),
    _createVNode(_component_Dialog, {
      visible: _ctx.visibleSubmittedBySignatureDialog,
      "onUpdate:visible": _cache[32] || (_cache[32] = ($event: any) => ((_ctx.visibleSubmittedBySignatureDialog) = $event)),
      modal: "",
      header: "Submitted By Signature",
      style: { width: '80vw' }
    }, {
      default: _withCtx(() => [
        _createVNode(_component_Signature, {
          signature: _ctx.dealsheet?.submitted_by_signature?.signature,
          signatureType: _ctx.dealsheet?.submitted_by_signature?.signatureType,
          receivedBy: _ctx.dealsheet?.submitted_by_signature?.receivedBy,
          receivedDate: _ctx.dealsheet?.submitted_by_signature?.receivedDate,
          receivedDateTime: _ctx.dealsheet?.submitted_by_signature?.receivedTime,
          readOnly: _ctx.isEdit,
          onSave: _ctx.saveSubmittedBy
        }, null, 8, ["signature", "signatureType", "receivedBy", "receivedDate", "receivedDateTime", "readOnly", "onSave"])
      ]),
      _: 1
    }, 8, ["visible"]),
    _createVNode(_component_Dialog, {
      visible: _ctx.visibleApprovedBySignatureDialog,
      "onUpdate:visible": _cache[33] || (_cache[33] = ($event: any) => ((_ctx.visibleApprovedBySignatureDialog) = $event)),
      modal: "",
      header: "Approved By Signature",
      style: { width: '80vw' }
    }, {
      default: _withCtx(() => [
        _createVNode(_component_Signature, {
          signature: _ctx.dealsheet?.approved_by_signature?.signature,
          signatureType: _ctx.dealsheet?.approved_by_signature?.signatureType,
          receivedBy: _ctx.dealsheet?.approved_by_signature?.receivedBy,
          receivedDate: _ctx.dealsheet?.approved_by_signature?.receivedDate,
          receivedDateTime: _ctx.dealsheet?.approved_by_signature?.receivedTime,
          readOnly: _ctx.isEdit,
          onSave: _ctx.saveApprovedBy
        }, null, 8, ["signature", "signatureType", "receivedBy", "receivedDate", "receivedDateTime", "readOnly", "onSave"])
      ]),
      _: 1
    }, 8, ["visible"])
  ], 64))
}
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "grid mr-0 mt-0 ml-0 justify-content-center" }
const _hoisted_2 = { class: "col-12 mr-0 mt-1 ml-0" }
const _hoisted_3 = { class: "sm:col-12 md:col-12 lg:col-12 col-12 p-0" }
const _hoisted_4 = { class: "sales-tab" }
const _hoisted_5 = { class: "menutab-item" }
const _hoisted_6 = { class: "sales-tab p-0" }
const _hoisted_7 = { class: "align-self-center" }
const _hoisted_8 = { class: "col-12 md:col-12 lg:col-12 p-0" }
const _hoisted_9 = { class: "formgrid grid lg:flex" }
const _hoisted_10 = { class: "col-10" }
const _hoisted_11 = { class: "menutab-item" }
const _hoisted_12 = { class: "flex col-2 justify-content-end" }
const _hoisted_13 = { class: "sm:col-12 md:col-12 lg:col-12 m-0 p-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Search = _resolveComponent("Search")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_Button = _resolveComponent("Button")!
  const _component_TabPanel = _resolveComponent("TabPanel")!
  const _component_TabView = _resolveComponent("TabView")!
  const _component_SplitButton = _resolveComponent("SplitButton")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_MessageBox = _resolveComponent("MessageBox")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_Search, {
          ref: "cust",
          label: "name",
          file: "CUST",
          currentView: "Customers",
          placeholder: "Search Customer",
          "minimum-characters": 1,
          leftIcon: true,
          class: "large-search-input",
          autofocus: "",
          onCustomerSearch: _ctx.handleCustomerSearch
        }, null, 8, ["onCustomerSearch"])
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_TabView, {
          "active-index": _ctx.getActiveTab,
          onTabChange: _ctx.handleTabChange,
          scrollable: true,
          class: "customers-list-tabview"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_TabPanel, null, {
              header: _withCtx(() => [
                _createElementVNode("div", _hoisted_4, [
                  _createVNode(_component_router_link, {
                    to: '/fieldservices',
                    class: "line-height-4 sales-tab-item",
                    onClick: _ctx.handleTabChange
                  }, {
                    default: _withCtx(() => _cache[0] || (_cache[0] = [
                      _createElementVNode("span", { class: "align-self-center" }, "Field Services", -1)
                    ])),
                    _: 1
                  }, 8, ["onClick"])
                ])
              ]),
              default: _withCtx(() => [
                _createVNode(_component_TabView, {
                  scrollable: true,
                  onTabChange: _ctx.handleFieldServiceTabChange,
                  activeIndex: _ctx.active,
                  class: "customers-menu-tabview sales-menu-tabview tabview-panels-hidden p-0 sm:col-12 md:col-12 lg:col-12 p-0 border-noround border-none ml-3"
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (tab) => {
                      return (_openBlock(), _createBlock(_component_TabPanel, {
                        key: tab.label
                      }, {
                        header: _withCtx(() => [
                          _createElementVNode("div", _hoisted_5, [
                            _createVNode(_component_router_link, {
                              to: tab.to,
                              class: "menutab-item-link"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_Button, {
                                  label: tab.label,
                                  class: "menutab-button",
                                  badge: tab.badge
                                }, null, 8, ["label", "badge"])
                              ]),
                              _: 2
                            }, 1032, ["to"])
                          ])
                        ]),
                        _: 2
                      }, 1024))
                    }), 128))
                  ]),
                  _: 1
                }, 8, ["onTabChange", "activeIndex"])
              ]),
              _: 1
            }),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.fieldServices.FieldServiceMap, (tab) => {
              return (_openBlock(), _createBlock(_component_TabPanel, {
                key: tab.old_record.order_no,
                class: "pt-0"
              }, {
                header: _withCtx(() => [
                  _createElementVNode("div", _hoisted_6, [
                    _createVNode(_component_router_link, {
                      to: '/fieldservices/' + tab.old_record.order_no,
                      class: "line-height-4 sales-tab-item"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("span", _hoisted_7, _toDisplayString(`${
                        tab.old_record.order_no?.startsWith("New")
                          ? ""
                          : "Field Service #"
                      }`) + _toDisplayString(tab.old_record.order_no) + _toDisplayString(_ctx.orderHasChanges(tab)), 1)
                      ]),
                      _: 2
                    }, 1032, ["to"]),
                    _createVNode(_component_Button, {
                      icon: "pi pi-times",
                      class: "p-button-text p-0 m-0 pb-1",
                      onClick: _withModifiers(($event: any) => (_ctx.handleRemoveFieldServiceTab(tab)), ["stop"])
                    }, null, 8, ["onClick"])
                  ])
                ]),
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_8, [
                    _createElementVNode("div", _hoisted_9, [
                      _createElementVNode("div", _hoisted_10, [
                        _createVNode(_component_TabView, {
                          scrollable: true,
                          class: "customers-menu-tabview sales-menu-tabview tabview-panels-hidden p-0 sm:col-12 md:col-12 lg:col-12 p-0 border-round border-none ml-0",
                          "active-index": _ctx.getActiveInnerTab(tab.old_record.order_no),
                          onTabChange: (event) => _ctx.handleInnerTabChange(event, tab)
                        }, {
                          default: _withCtx(() => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.innerTabs, (option) => {
                              return (_openBlock(), _createBlock(_component_TabPanel, {
                                key: option.label
                              }, {
                                header: _withCtx(() => [
                                  _createElementVNode("div", _hoisted_11, [
                                    _createVNode(_component_router_link, {
                                      class: "menutab-item-link",
                                      to: `${option.prefix}/${tab.old_record.order_no}/${option.suffix}`
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_Button, {
                                          label: option.label,
                                          class: "menutab-button inner-tab"
                                        }, null, 8, ["label"])
                                      ]),
                                      _: 2
                                    }, 1032, ["to"])
                                  ])
                                ]),
                                _: 2
                              }, 1024))
                            }), 128))
                          ]),
                          _: 2
                        }, 1032, ["active-index", "onTabChange"])
                      ]),
                      _createElementVNode("div", _hoisted_12, [
                        _createVNode(_component_SplitButton, {
                          label: "Submit",
                          icon: "pi pi-save",
                          iconPos: "left",
                          class: "text-sm font-bold align-self-center collapsible-mobile-view",
                          style: {"height":"40px"},
                          onClick: _ctx.saveFieldService,
                          model: _ctx.splitButtonItems,
                          disabled: _ctx.isLoading
                        }, null, 8, ["onClick", "model", "disabled"])
                      ])
                    ])
                  ])
                ]),
                _: 2
              }, 1024))
            }), 128))
          ]),
          _: 1
        }, 8, ["active-index", "onTabChange"]),
        _createElementVNode("div", _hoisted_13, [
          _createVNode(_component_router_view)
        ])
      ])
    ]),
    _createVNode(_component_MessageBox, {
      showDialog: _ctx.showConfirmDeleteTab,
      icon: "WARNING",
      header: "Unsaved Changes",
      message: "Are you sure you want to close the tab without saving the changes?",
      primaryButton: "Yes",
      cancelButton: "Cancel",
      onPrimaryButtonClick: _ctx.handleCloseSaveFieldServiceTab,
      onCancelButtonClick: _ctx.handleCancelSaveFieldServiceTab
    }, null, 8, ["showDialog", "onPrimaryButtonClick", "onCancelButtonClick"])
  ]))
}
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card flex justify-content-center" }
const _hoisted_2 = { class: "grid col-12 p-0 m-0" }
const _hoisted_3 = { class: "p-inputgroup p-0 m-0 pt-1 lg:pb-1 col-12 pl-1" }
const _hoisted_4 = { class: "p-inputgroup p-0 m-0 pt-1 lg:pb-1 col-12 pl-1" }
const _hoisted_5 = { class: "field-checkbox p-0 m-0 pt-1 lg:pb-1 col-6 lg:col-6 pl-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_InputNumber = _resolveComponent("InputNumber")!
  const _component_CheckBox = _resolveComponent("CheckBox")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Button, {
      label: "Add",
      icon: "pi pi-plus",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.visible = true)),
      class: "text-sm font-bold"
    }),
    _createVNode(_component_Dialog, {
      visible: _ctx.visible,
      "onUpdate:visible": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.visible) = $event)),
      modal: "",
      header: "New Credit Memo",
      class: "w-6 lg:w-4 xl:w-3"
    }, {
      footer: _withCtx(() => [
        _createVNode(_component_Button, {
          label: "Cancel",
          icon: "pi pi-times",
          onClick: _ctx.cancel,
          class: "p-button-text"
        }, null, 8, ["onClick"]),
        _createVNode(_component_Button, {
          label: "Add",
          icon: "pi pi-check",
          onClick: _ctx.addRecord,
          autofocus: ""
        }, null, 8, ["onClick"])
      ]),
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _cache[5] || (_cache[5] = _createElementVNode("small", { class: "p-inputgroup-addon medium-addon" }, "Credit Memo", -1)),
            _createVNode(_component_InputText, {
              type: "text",
              class: "text-sm",
              modelValue: _ctx.newCreditMemo.memo,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.newCreditMemo.memo) = $event))
            }, null, 8, ["modelValue"])
          ]),
          _createElementVNode("div", _hoisted_4, [
            _cache[6] || (_cache[6] = _createElementVNode("small", { class: "p-inputgroup-addon medium-addon" }, "Amount", -1)),
            _createVNode(_component_InputNumber, {
              class: "text-sm",
              modelValue: _ctx.newCreditMemo.amount,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.newCreditMemo.amount) = $event))
            }, null, 8, ["modelValue"])
          ]),
          _createElementVNode("div", _hoisted_5, [
            _cache[7] || (_cache[7] = _createElementVNode("small", { class: "p-inputgroup-addon medium-addon text-sm single-last-field" }, "Apply", -1)),
            _createVNode(_component_CheckBox, {
              id: "apply",
              name: "apply",
              modelValue: _ctx.newCreditMemo.apply,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.newCreditMemo.apply) = $event)),
              trueValue: "Y",
              falseValue: null,
              binary: true,
              class: "field-checkbox p-0 m-1 mt-0 mb-0 ml-3"
            }, null, 8, ["modelValue"])
          ])
        ])
      ]),
      _: 1
    }, 8, ["visible"])
  ]))
}
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "grid m-0 order-tab-content p-0" }
const _hoisted_2 = { class: "col-12 justify-content-between p-0 m-0" }
const _hoisted_3 = { class: "grid p-0 m-0" }
const _hoisted_4 = { class: "col-12 pt-0 pb-0 mt-0 mb-0 lg:pr-0 xl:pr-1" }
const _hoisted_5 = { class: "grid col-12 p-0 m-0" }
const _hoisted_6 = { class: "col-12 lg:col-9 xl:col-10" }
const _hoisted_7 = {
  key: 0,
  class: "grid col-12 hidden lg:inline-flex p-0 m-0"
}
const _hoisted_8 = {
  key: 1,
  class: "grid col-12 justify-content-center pt-4 pb-0"
}
const _hoisted_9 = {
  key: 2,
  class: "p-0 h-30rem lg:h-8rem overflow-y-auto overflow-x-hidden pl-0 ml-0"
}
const _hoisted_10 = { class: "p-inputgroup hidden lg:inline-flex col p-0 m-0" }
const _hoisted_11 = { class: "p-inputgroup p-0 mt-1 col-12 lg:hidden" }
const _hoisted_12 = { class: "p-inputgroup hidden lg:inline-flex col p-0 m-0 pl-1" }
const _hoisted_13 = { class: "p-inputgroup p-0 mt-1 col-12 lg:hidden" }
const _hoisted_14 = { class: "p-inputgroup hidden lg:inline-flex col p-0 m-0 pl-1 pr-1" }
const _hoisted_15 = { class: "p-inputgroup p-0 mt-1 col-12 lg:hidden" }
const _hoisted_16 = { class: "p-inputgroup hidden lg:inline-flex col p-0 m-0 pr-1" }
const _hoisted_17 = { class: "p-inputgroup p-0 mt-1 col-12 lg:hidden" }
const _hoisted_18 = { class: "p-inputgroup hidden lg:inline-flex col p-0 m-0 pr-1" }
const _hoisted_19 = { class: "p-inputgroup p-0 mt-1 col-12 lg:hidden" }
const _hoisted_20 = { class: "p-inputgroup hidden lg:inline-flex col p-0 m-0 pr-1" }
const _hoisted_21 = { class: "p-inputgroup p-0 mt-1 col-12 lg:hidden" }
const _hoisted_22 = { class: "p-inputgroup hidden lg:inline-flex col p-0 m-0 pr-1" }
const _hoisted_23 = { class: "p-inputgroup p-0 mt-1 col-12 lg:hidden" }
const _hoisted_24 = { class: "p-inputgroup hidden lg:inline-flex col p-0 m-0" }
const _hoisted_25 = { class: "p-inputgroup p-0 mt-1 col-12 lg:hidden" }
const _hoisted_26 = { class: "grid col-12 lg:col-3 xl:col-2 pr-0" }
const _hoisted_27 = { class: "grid col-12 hidden lg:inline-flex justify-content-center pb-0 pr-0" }
const _hoisted_28 = { class: "p-inputgroup hidden lg:inline-flex lg:col-12 p-0 m-0 pt-1 ml-3" }
const _hoisted_29 = { class: "p-inputgroup pt-0 pb-0 pr-0 mt-1 col-12 lg:hidden" }
const _hoisted_30 = { class: "p-inputgroup pt-0 pb-0 pr-0 mt-1 col-12" }
const _hoisted_31 = { class: "p-inputgroup pt-0 pb-0 pr-0 mt-1 col-12" }
const _hoisted_32 = { class: "col-12 justify-content-center pr-0 mr-0 pl-2" }
const _hoisted_33 = { class: "grid col-12 lg:col-6 p-0 m-0 pt-1" }
const _hoisted_34 = { class: "p-0 mt-1 col-12 md:col-6 pr-1 lg:p-1 lg:pt-0" }
const _hoisted_35 = { class: "p-inputgroup" }
const _hoisted_36 = { class: "p-0 mt-1 col-12 md:col-6 pr-1 lg:p-1 lg:pt-0" }
const _hoisted_37 = { class: "p-inputgroup" }
const _hoisted_38 = { class: "p-0 mt-1 col-12 lg:col-6 pr-1 lg:p-1 lg:pt-0" }
const _hoisted_39 = { class: "p-inputgroup" }
const _hoisted_40 = { class: "grid col-12 lg:col-6 p-0 m-0 pt-1 justify-content-center lg:pr-1" }
const _hoisted_41 = { class: "p-0 mt-1 col-12 xl:col-9 pr-1 lg:p-1 lg:pt-0" }
const _hoisted_42 = { class: "p-inputgroup" }
const _hoisted_43 = { class: "p-0 mt-1 col-9 xl:col-6 pr-1 lg:p-1 lg:pt-0" }
const _hoisted_44 = { class: "p-inputgroup" }
const _hoisted_45 = { class: "p-0 mt-1 col-3 xl:col-3 pr-1 lg:p-1 lg:pt-0" }
const _hoisted_46 = { class: "p-inputgroup" }
const _hoisted_47 = { class: "grid col-12 p-0 m-0 pt-1" }
const _hoisted_48 = { class: "ptn-section col-12 p-0 m-0 lg:pl-1" }
const _hoisted_49 = { class: "grid col-12 lg:col-4 p-0 m-0 pb-1" }
const _hoisted_50 = { class: "col-9 p-0 m-0 pr-1" }
const _hoisted_51 = { class: "p-inputgroup hidden lg:inline-flex col-12 p-0 m-0" }
const _hoisted_52 = { class: "p-inputgroup p-0 col-12 lg:hidden pt-1" }
const _hoisted_53 = { class: "col p-0 m-0 pt-1 lg:pt-0" }
const _hoisted_54 = { class: "p-inputgroup col-12 p-0 m-0" }
const _hoisted_55 = { class: "grid col-12 lg:col-4 p-0 m-0" }
const _hoisted_56 = { class: "col p-0 m-0 lg:pl-1 lg:pr-1" }
const _hoisted_57 = { class: "p-inputgroup hidden lg:inline-flex col-12 p-0 m-0" }
const _hoisted_58 = { class: "p-inputgroup p-0 col-12 lg:hidden" }
const _hoisted_59 = { class: "grid col-12 lg:col-4 p-0 m-0" }
const _hoisted_60 = { class: "col-12 lg:col-4 p-0 m-0" }
const _hoisted_61 = { class: "p-inputgroup hidden lg:inline-flex col-12 p-0 m-0" }
const _hoisted_62 = { class: "p-inputgroup p-0 col-12 lg:hidden pt-1" }
const _hoisted_63 = { class: "col-12 lg:col-4 p-0 m-0 lg:pl-1" }
const _hoisted_64 = { class: "p-inputgroup hidden lg:inline-flex col-12 p-0 m-0" }
const _hoisted_65 = { class: "p-inputgroup p-0 col-12 lg:hidden pt-1" }
const _hoisted_66 = { class: "col-12 lg:col-4 p-0 m-0" }
const _hoisted_67 = { class: "p-inputgroup hidden lg:inline-flex col-12 p-0 m-0 pl-1" }
const _hoisted_68 = { class: "p-inputgroup p-0 col-12 lg:hidden pt-1" }
const _hoisted_69 = { class: "grid col-12 lg:col-12 p-0 m-0 p-0 pb-3 justify-content-end" }
const _hoisted_70 = { class: "p-0 m-0 mt-1 col-12 lg:col-4 pr-2" }
const _hoisted_71 = { class: "p-inputgroup" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Divider = _resolveComponent("Divider")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_AccordionTab = _resolveComponent("AccordionTab")!
  const _component_Calendar = _resolveComponent("Calendar")!
  const _component_CustomAccordion = _resolveComponent("CustomAccordion")!
  const _component_Card = _resolveComponent("Card")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Card, { class: "col-12 border-round-3xl mb-0 p-0 pl-2 tab-card-content" }, {
      content: _withCtx(() => [
        _createVNode(_component_CustomAccordion, { activeIndex: [0, 1] }, {
          "accordion-content": _withCtx(() => [
            _createVNode(_component_AccordionTab, { header: "Calculated Freight Chargers" }, {
              header: _withCtx(() => [
                _createVNode(_component_Divider, {
                  type: "dashed",
                  class: "ml-3"
                })
              ]),
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_2, [
                  _createElementVNode("div", _hoisted_3, [
                    _createElementVNode("div", _hoisted_4, [
                      _createElementVNode("div", _hoisted_5, [
                        _createElementVNode("div", _hoisted_6, [
                          (_ctx.currentOrder.pkg_type_code_items)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_7, _cache[4] || (_cache[4] = [
                                _createElementVNode("div", { class: "p-inputgroup col font-semibold p-0 m-0 justify-content-center text-sm" }, " Pkg Type ", -1),
                                _createElementVNode("div", { class: "p-inputgroup col font-semibold p-0 m-0 justify-content-center text-sm" }, " Weight ", -1),
                                _createElementVNode("div", { class: "p-inputgroup col font-semibold p-0 m-0 justify-content-center text-sm" }, " W UM ", -1),
                                _createElementVNode("div", { class: "p-inputgroup col font-semibold p-0 m-0 justify-content-center text-sm" }, " Length ", -1),
                                _createElementVNode("div", { class: "p-inputgroup col font-semibold p-0 m-0 justify-content-center text-sm" }, " Width ", -1),
                                _createElementVNode("div", { class: "p-inputgroup col font-semibold p-0 m-0 justify-content-center text-sm" }, " Height ", -1),
                                _createElementVNode("div", { class: "p-inputgroup col font-semibold p-0 m-0 justify-content-center text-sm" }, " UM ", -1),
                                _createElementVNode("div", { class: "p-inputgroup col font-semibold p-0 m-0 justify-content-center text-sm" }, " Pkg Value ", -1)
                              ])))
                            : _createCommentVNode("", true),
                          (_ctx.currentOrder.pkg_type_code_items?.length === 0)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_8, _cache[5] || (_cache[5] = [
                                _createElementVNode("div", { class: "p-inputgroup font-bold pb-0 mb-0 justify-content-center text-sm" }, " No items ", -1)
                              ])))
                            : (_openBlock(), _createElementBlock("div", _hoisted_9, [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.currentOrder.pkg_type_code_items, (freight) => {
                                  return (_openBlock(), _createElementBlock("div", {
                                    key: freight.id,
                                    class: "grid col-12 p-0 m-0 pt-1"
                                  }, [
                                    _createVNode(_component_Divider, {
                                      type: "dashed",
                                      class: "lg:hidden p-0 m-0 pt-2 pb-2"
                                    }),
                                    _createElementVNode("div", _hoisted_10, [
                                      _createVNode(_component_InputText, {
                                        type: "text",
                                        class: "text-sm",
                                        modelValue: freight.pkg_type_code,
                                        "onUpdate:modelValue": ($event: any) => ((freight.pkg_type_code) = $event)
                                      }, null, 8, ["modelValue", "onUpdate:modelValue"])
                                    ]),
                                    _createElementVNode("div", _hoisted_11, [
                                      _cache[6] || (_cache[6] = _createElementVNode("small", { class: "p-inputgroup-addon medium-addon" }, "Pkg Type", -1)),
                                      _createVNode(_component_InputText, {
                                        type: "text",
                                        class: "text-sm",
                                        modelValue: freight.pkg_type_code,
                                        "onUpdate:modelValue": ($event: any) => ((freight.pkg_type_code) = $event),
                                        readOnly: ""
                                      }, null, 8, ["modelValue", "onUpdate:modelValue"])
                                    ]),
                                    _createElementVNode("div", _hoisted_12, [
                                      _createVNode(_component_InputText, {
                                        type: "text",
                                        class: "text-sm",
                                        modelValue: freight.pkg_weight,
                                        "onUpdate:modelValue": ($event: any) => ((freight.pkg_weight) = $event),
                                        readOnly: ""
                                      }, null, 8, ["modelValue", "onUpdate:modelValue"])
                                    ]),
                                    _createElementVNode("div", _hoisted_13, [
                                      _cache[7] || (_cache[7] = _createElementVNode("small", { class: "p-inputgroup-addon medium-addon" }, "Weight", -1)),
                                      _createVNode(_component_InputText, {
                                        type: "text",
                                        class: "text-sm",
                                        modelValue: freight.pkg_weight,
                                        "onUpdate:modelValue": ($event: any) => ((freight.pkg_weight) = $event),
                                        readOnly: ""
                                      }, null, 8, ["modelValue", "onUpdate:modelValue"])
                                    ]),
                                    _createElementVNode("div", _hoisted_14, [
                                      _createVNode(_component_InputText, {
                                        type: "text",
                                        class: "text-sm",
                                        modelValue: freight.pkg_weight_uom,
                                        "onUpdate:modelValue": ($event: any) => ((freight.pkg_weight_uom) = $event),
                                        readOnly: ""
                                      }, null, 8, ["modelValue", "onUpdate:modelValue"])
                                    ]),
                                    _createElementVNode("div", _hoisted_15, [
                                      _cache[8] || (_cache[8] = _createElementVNode("small", { class: "p-inputgroup-addon medium-addon" }, "Weight UM", -1)),
                                      _createVNode(_component_InputText, {
                                        type: "text",
                                        class: "text-sm",
                                        modelValue: freight.pkg_weight_uom,
                                        "onUpdate:modelValue": ($event: any) => ((freight.pkg_weight_uom) = $event),
                                        readOnly: ""
                                      }, null, 8, ["modelValue", "onUpdate:modelValue"])
                                    ]),
                                    _createElementVNode("div", _hoisted_16, [
                                      _createVNode(_component_InputText, {
                                        type: "text",
                                        class: "text-sm",
                                        modelValue: freight.pkg_length,
                                        "onUpdate:modelValue": ($event: any) => ((freight.pkg_length) = $event),
                                        readOnly: ""
                                      }, null, 8, ["modelValue", "onUpdate:modelValue"])
                                    ]),
                                    _createElementVNode("div", _hoisted_17, [
                                      _cache[9] || (_cache[9] = _createElementVNode("small", { class: "p-inputgroup-addon medium-addon" }, "Length", -1)),
                                      _createVNode(_component_InputText, {
                                        type: "text",
                                        class: "text-sm",
                                        modelValue: freight.pkg_length,
                                        "onUpdate:modelValue": ($event: any) => ((freight.pkg_length) = $event),
                                        readOnly: ""
                                      }, null, 8, ["modelValue", "onUpdate:modelValue"])
                                    ]),
                                    _createElementVNode("div", _hoisted_18, [
                                      _createVNode(_component_InputText, {
                                        type: "text",
                                        class: "text-sm",
                                        modelValue: freight.pkg_width,
                                        "onUpdate:modelValue": ($event: any) => ((freight.pkg_width) = $event),
                                        readOnly: ""
                                      }, null, 8, ["modelValue", "onUpdate:modelValue"])
                                    ]),
                                    _createElementVNode("div", _hoisted_19, [
                                      _cache[10] || (_cache[10] = _createElementVNode("small", { class: "p-inputgroup-addon medium-addon" }, "Width", -1)),
                                      _createVNode(_component_InputText, {
                                        type: "text",
                                        class: "text-sm",
                                        modelValue: freight.pkg_width,
                                        "onUpdate:modelValue": ($event: any) => ((freight.pkg_width) = $event),
                                        readOnly: ""
                                      }, null, 8, ["modelValue", "onUpdate:modelValue"])
                                    ]),
                                    _createElementVNode("div", _hoisted_20, [
                                      _createVNode(_component_InputText, {
                                        type: "text",
                                        class: "text-sm",
                                        modelValue: freight.pkg_height,
                                        "onUpdate:modelValue": ($event: any) => ((freight.pkg_height) = $event),
                                        readOnly: ""
                                      }, null, 8, ["modelValue", "onUpdate:modelValue"])
                                    ]),
                                    _createElementVNode("div", _hoisted_21, [
                                      _cache[11] || (_cache[11] = _createElementVNode("small", { class: "p-inputgroup-addon medium-addon" }, "Height", -1)),
                                      _createVNode(_component_InputText, {
                                        type: "text",
                                        class: "text-sm",
                                        modelValue: freight.pkg_height,
                                        "onUpdate:modelValue": ($event: any) => ((freight.pkg_height) = $event),
                                        readOnly: ""
                                      }, null, 8, ["modelValue", "onUpdate:modelValue"])
                                    ]),
                                    _createElementVNode("div", _hoisted_22, [
                                      _createVNode(_component_InputText, {
                                        type: "text",
                                        class: "text-sm",
                                        modelValue: freight.pkg_dim_uom,
                                        "onUpdate:modelValue": ($event: any) => ((freight.pkg_dim_uom) = $event),
                                        readOnly: ""
                                      }, null, 8, ["modelValue", "onUpdate:modelValue"])
                                    ]),
                                    _createElementVNode("div", _hoisted_23, [
                                      _cache[12] || (_cache[12] = _createElementVNode("small", { class: "p-inputgroup-addon medium-addon" }, "UM", -1)),
                                      _createVNode(_component_InputText, {
                                        type: "text",
                                        class: "text-sm",
                                        modelValue: freight.pkg_dim_uom,
                                        "onUpdate:modelValue": ($event: any) => ((freight.pkg_dim_uom) = $event),
                                        readOnly: ""
                                      }, null, 8, ["modelValue", "onUpdate:modelValue"])
                                    ]),
                                    _createElementVNode("div", _hoisted_24, [
                                      _createVNode(_component_InputText, {
                                        type: "text",
                                        class: "text-sm",
                                        modelValue: freight.pkg_value,
                                        "onUpdate:modelValue": ($event: any) => ((freight.pkg_value) = $event),
                                        readOnly: ""
                                      }, null, 8, ["modelValue", "onUpdate:modelValue"])
                                    ]),
                                    _createElementVNode("div", _hoisted_25, [
                                      _cache[13] || (_cache[13] = _createElementVNode("small", { class: "p-inputgroup-addon medium-addon" }, "Pkg Value", -1)),
                                      _createVNode(_component_InputText, {
                                        type: "text",
                                        class: "text-sm",
                                        modelValue: freight.pkg_value,
                                        "onUpdate:modelValue": ($event: any) => ((freight.pkg_value) = $event),
                                        readOnly: ""
                                      }, null, 8, ["modelValue", "onUpdate:modelValue"])
                                    ])
                                  ]))
                                }), 128))
                              ]))
                        ]),
                        _createElementVNode("div", _hoisted_26, [
                          _createElementVNode("div", _hoisted_27, [
                            _cache[14] || (_cache[14] = _createElementVNode("div", { class: "p-inputgroup col-12 font-semibold pb-0 mb-0 justify-content-center text-base" }, " Service Code ", -1)),
                            _createElementVNode("div", _hoisted_28, [
                              _createVNode(_component_InputText, {
                                type: "text",
                                class: "text-sm",
                                modelValue: _ctx.currentOrder.ship_via,
                                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.currentOrder.ship_via) = $event)),
                                readOnly: ""
                              }, null, 8, ["modelValue"])
                            ])
                          ]),
                          _createElementVNode("div", _hoisted_29, [
                            _cache[15] || (_cache[15] = _createElementVNode("small", { class: "p-inputgroup-addon" }, "Service Code", -1)),
                            _createVNode(_component_InputText, {
                              type: "text",
                              class: "text-sm text-left",
                              modelValue: _ctx.currentOrder.ship_via,
                              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.currentOrder.ship_via) = $event)),
                              readOnly: ""
                            }, null, 8, ["modelValue"])
                          ]),
                          _createElementVNode("div", _hoisted_30, [
                            _cache[16] || (_cache[16] = _createElementVNode("small", { class: "p-inputgroup-addon" }, "Freight Amount", -1)),
                            _createVNode(_component_InputText, {
                              type: "text",
                              class: "text-sm",
                              modelValue: _ctx.currentOrder.freight_amount,
                              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.currentOrder.freight_amount) = $event)),
                              readOnly: ""
                            }, null, 8, ["modelValue"])
                          ]),
                          _createElementVNode("div", _hoisted_31, [
                            _cache[17] || (_cache[17] = _createElementVNode("small", { class: "p-inputgroup-addon" }, "Markup Amount", -1)),
                            _createVNode(_component_InputText, {
                              type: "text",
                              class: "text-sm",
                              modelValue: _ctx.currentOrder.frt_markup_amt,
                              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.currentOrder.frt_markup_amt) = $event)),
                              readOnly: ""
                            }, null, 8, ["modelValue"])
                          ])
                        ])
                      ])
                    ])
                  ])
                ])
              ]),
              _: 1
            }),
            false
              ? (_openBlock(), _createBlock(_component_AccordionTab, {
                  key: 0,
                  header: "Shipments and Actual Freight Chargers"
                }, {
                  header: _withCtx(() => [
                    _createVNode(_component_Divider, {
                      type: "dashed",
                      class: "ml-3"
                    })
                  ]),
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_32, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.currentOrder.track_order, (track) => {
                        return (_openBlock(), _createElementBlock("div", {
                          key: track.shipNo,
                          class: "grid col-12 m-0 p-0 pb-3"
                        }, [
                          _createElementVNode("div", _hoisted_33, [
                            _createElementVNode("div", _hoisted_34, [
                              _createElementVNode("div", _hoisted_35, [
                                _cache[18] || (_cache[18] = _createElementVNode("small", { class: "p-inputgroup-addon medium-addon" }, "Ship #", -1)),
                                _createVNode(_component_InputText, {
                                  type: "text",
                                  class: "text-sm",
                                  modelValue: track.shipNo,
                                  "onUpdate:modelValue": ($event: any) => ((track.shipNo) = $event)
                                }, null, 8, ["modelValue", "onUpdate:modelValue"])
                              ])
                            ]),
                            _createElementVNode("div", _hoisted_36, [
                              _createElementVNode("div", _hoisted_37, [
                                _cache[19] || (_cache[19] = _createElementVNode("small", { class: "p-inputgroup-addon medium-addon" }, "Ship Date", -1)),
                                _createVNode(_component_Calendar, {
                                  showIcon: true,
                                  dateFormat: "mm-dd-yy",
                                  inputClass: "text-sm",
                                  modelValue: track.shipDate,
                                  "onUpdate:modelValue": ($event: any) => ((track.shipDate) = $event)
                                }, null, 8, ["modelValue", "onUpdate:modelValue"])
                              ])
                            ]),
                            _createElementVNode("div", _hoisted_38, [
                              _createElementVNode("div", _hoisted_39, [
                                _cache[20] || (_cache[20] = _createElementVNode("small", { class: "p-inputgroup-addon medium-addon" }, "Invoice", -1)),
                                _createVNode(_component_InputText, {
                                  type: "text",
                                  class: "text-sm",
                                  modelValue: track.invoice,
                                  "onUpdate:modelValue": ($event: any) => ((track.invoice) = $event)
                                }, null, 8, ["modelValue", "onUpdate:modelValue"])
                              ])
                            ])
                          ]),
                          _createElementVNode("div", _hoisted_40, [
                            _createElementVNode("div", _hoisted_41, [
                              _createElementVNode("div", _hoisted_42, [
                                _cache[21] || (_cache[21] = _createElementVNode("small", { class: "p-inputgroup-addon medium-addon" }, "Ship Via", -1)),
                                _createVNode(_component_InputText, {
                                  type: "text",
                                  class: "text-sm",
                                  modelValue: track.shipVia,
                                  "onUpdate:modelValue": ($event: any) => ((track.shipVia) = $event)
                                }, null, 8, ["modelValue", "onUpdate:modelValue"])
                              ])
                            ]),
                            _createElementVNode("div", _hoisted_43, [
                              _createElementVNode("div", _hoisted_44, [
                                _cache[22] || (_cache[22] = _createElementVNode("small", { class: "p-inputgroup-addon medium-addon" }, "Waybill #", -1)),
                                _createVNode(_component_InputText, {
                                  type: "text",
                                  class: "text-sm",
                                  modelValue: track.waybillNo,
                                  "onUpdate:modelValue": ($event: any) => ((track.waybillNo) = $event)
                                }, null, 8, ["modelValue", "onUpdate:modelValue"])
                              ])
                            ]),
                            _createElementVNode("div", _hoisted_45, [
                              _createElementVNode("div", _hoisted_46, [
                                _createVNode(_component_InputText, {
                                  type: "text",
                                  class: "text-sm single-field",
                                  modelValue: track.waybillStatus,
                                  "onUpdate:modelValue": ($event: any) => ((track.waybillStatus) = $event)
                                }, null, 8, ["modelValue", "onUpdate:modelValue"])
                              ])
                            ])
                          ]),
                          _createElementVNode("div", _hoisted_47, [
                            _cache[29] || (_cache[29] = _createElementVNode("div", { class: "grid col-12 hidden lg:inline-flex p-0 m-0 pr-3" }, [
                              _createElementVNode("div", { class: "grid col-12 lg:col-4 p-0 m-0 pt-1 pb-1" }, [
                                _createElementVNode("div", { class: "p-inputgroup col font-semibold p-0 m-0 justify-content-center text-sm" }, " Package Tracking Number ")
                              ]),
                              _createElementVNode("div", { class: "grid col-12 lg:col-4 p-0 m-0 pt-1 pb-1" }, [
                                _createElementVNode("div", { class: "p-inputgroup col font-semibold p-0 m-0 justify-content-center text-sm" }, " Package Type ")
                              ]),
                              _createElementVNode("div", { class: "grid col-12 lg:col-4 p-0 m-0 pt-1 pb-1" }, [
                                _createElementVNode("div", { class: "col-12 lg:col-4 p-0 m-0" }, [
                                  _createElementVNode("div", { class: "p-inputgroup col font-semibold p-0 m-0 justify-content-center text-sm" }, " Weight ")
                                ]),
                                _createElementVNode("div", { class: "col-12 lg:col-4 p-0 m-0" }, [
                                  _createElementVNode("div", { class: "p-inputgroup col font-semibold p-0 m-0 justify-content-center text-sm" }, " Dimensions ")
                                ]),
                                _createElementVNode("div", { class: "col-12 lg:col-4 p-0 m-0" }, [
                                  _createElementVNode("div", { class: "p-inputgroup col font-semibold p-0 m-0 justify-content-center text-sm" }, " Pkg Charge ")
                                ])
                              ])
                            ], -1)),
                            _createElementVNode("div", _hoisted_48, [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(track.packagesList, (elem) => {
                                return (_openBlock(), _createElementBlock("div", {
                                  key: elem.pkgTrackingNumber,
                                  class: "grid col-12 p-0 m-0 pr-2"
                                }, [
                                  _createElementVNode("div", _hoisted_49, [
                                    _createElementVNode("div", _hoisted_50, [
                                      _createElementVNode("div", _hoisted_51, [
                                        _createVNode(_component_InputText, {
                                          type: "text",
                                          class: "text-sm h-2rem single-field",
                                          modelValue: elem.pkgTrackingNumber,
                                          "onUpdate:modelValue": ($event: any) => ((elem.pkgTrackingNumber) = $event)
                                        }, null, 8, ["modelValue", "onUpdate:modelValue"])
                                      ]),
                                      _createElementVNode("div", _hoisted_52, [
                                        _cache[23] || (_cache[23] = _createElementVNode("small", { class: "p-inputgroup-addon medium-addon" }, "Pkg tracking #", -1)),
                                        _createVNode(_component_InputText, {
                                          type: "text",
                                          class: "text-sm",
                                          modelValue: elem.pkgTrackingNumber,
                                          "onUpdate:modelValue": ($event: any) => ((elem.pkgTrackingNumber) = $event)
                                        }, null, 8, ["modelValue", "onUpdate:modelValue"])
                                      ])
                                    ]),
                                    _createElementVNode("div", _hoisted_53, [
                                      _createElementVNode("div", _hoisted_54, [
                                        _createVNode(_component_InputText, {
                                          type: "text",
                                          class: "text-sm lg:h-2rem single-field",
                                          modelValue: elem.pkgStatus,
                                          "onUpdate:modelValue": ($event: any) => ((elem.pkgStatus) = $event)
                                        }, null, 8, ["modelValue", "onUpdate:modelValue"])
                                      ])
                                    ])
                                  ]),
                                  _createElementVNode("div", _hoisted_55, [
                                    _createElementVNode("div", _hoisted_56, [
                                      _createElementVNode("div", _hoisted_57, [
                                        _createVNode(_component_InputText, {
                                          type: "text",
                                          class: "text-sm h-2rem single-field",
                                          modelValue: elem.pkgType,
                                          "onUpdate:modelValue": ($event: any) => ((elem.pkgType) = $event)
                                        }, null, 8, ["modelValue", "onUpdate:modelValue"])
                                      ]),
                                      _createElementVNode("div", _hoisted_58, [
                                        _cache[24] || (_cache[24] = _createElementVNode("small", { class: "p-inputgroup-addon" }, "Package type", -1)),
                                        _createVNode(_component_InputText, {
                                          type: "text",
                                          class: "text-sm",
                                          modelValue: elem.pkgType,
                                          "onUpdate:modelValue": ($event: any) => ((elem.pkgType) = $event)
                                        }, null, 8, ["modelValue", "onUpdate:modelValue"])
                                      ])
                                    ])
                                  ]),
                                  _createElementVNode("div", _hoisted_59, [
                                    _createElementVNode("div", _hoisted_60, [
                                      _createElementVNode("div", _hoisted_61, [
                                        _createVNode(_component_InputText, {
                                          type: "text",
                                          class: "text-sm h-2rem single-field",
                                          modelValue: elem.pkgWeight,
                                          "onUpdate:modelValue": ($event: any) => ((elem.pkgWeight) = $event)
                                        }, null, 8, ["modelValue", "onUpdate:modelValue"])
                                      ]),
                                      _createElementVNode("div", _hoisted_62, [
                                        _cache[25] || (_cache[25] = _createElementVNode("small", { class: "p-inputgroup-addon" }, "Weight", -1)),
                                        _createVNode(_component_InputText, {
                                          type: "text",
                                          class: "text-sm",
                                          modelValue: elem.pkgWeight,
                                          "onUpdate:modelValue": ($event: any) => ((elem.pkgWeight) = $event)
                                        }, null, 8, ["modelValue", "onUpdate:modelValue"])
                                      ])
                                    ]),
                                    _createElementVNode("div", _hoisted_63, [
                                      _createElementVNode("div", _hoisted_64, [
                                        _createVNode(_component_InputText, {
                                          type: "text",
                                          class: "text-sm h-2rem single-field",
                                          modelValue: elem.pkgDimensions,
                                          "onUpdate:modelValue": ($event: any) => ((elem.pkgDimensions) = $event)
                                        }, null, 8, ["modelValue", "onUpdate:modelValue"])
                                      ]),
                                      _createElementVNode("div", _hoisted_65, [
                                        _cache[26] || (_cache[26] = _createElementVNode("small", { class: "p-inputgroup-addon" }, "Dimensions", -1)),
                                        _createVNode(_component_InputText, {
                                          type: "text",
                                          class: "text-sm",
                                          modelValue: elem.pkgDimensions,
                                          "onUpdate:modelValue": ($event: any) => ((elem.pkgDimensions) = $event)
                                        }, null, 8, ["modelValue", "onUpdate:modelValue"])
                                      ])
                                    ]),
                                    _createElementVNode("div", _hoisted_66, [
                                      _createElementVNode("div", _hoisted_67, [
                                        _createVNode(_component_InputText, {
                                          type: "text",
                                          class: "text-sm h-2rem single-field",
                                          modelValue: elem.pkgCharge,
                                          "onUpdate:modelValue": ($event: any) => ((elem.pkgCharge) = $event)
                                        }, null, 8, ["modelValue", "onUpdate:modelValue"])
                                      ]),
                                      _createElementVNode("div", _hoisted_68, [
                                        _cache[27] || (_cache[27] = _createElementVNode("small", { class: "p-inputgroup-addon" }, "Pkg Charge", -1)),
                                        _createVNode(_component_InputText, {
                                          type: "text",
                                          class: "text-sm",
                                          modelValue: elem.pkgCharge,
                                          "onUpdate:modelValue": ($event: any) => ((elem.pkgCharge) = $event)
                                        }, null, 8, ["modelValue", "onUpdate:modelValue"])
                                      ])
                                    ])
                                  ]),
                                  _createVNode(_component_Divider, {
                                    type: "dashed",
                                    class: "lg:hidden mt-2 mb-2"
                                  })
                                ]))
                              }), 128))
                            ]),
                            _createElementVNode("div", _hoisted_69, [
                              _createElementVNode("div", _hoisted_70, [
                                _createElementVNode("div", _hoisted_71, [
                                  _cache[28] || (_cache[28] = _createElementVNode("small", { class: "p-inputgroup-addon" }, "Total Ship Charge", -1)),
                                  _createVNode(_component_InputText, {
                                    type: "text",
                                    class: "text-sm",
                                    disabled: true,
                                    value: _ctx.getShipChargeTotal(track.packagesList)
                                  }, null, 8, ["value"])
                                ])
                              ])
                            ])
                          ])
                        ]))
                      }), 128))
                    ])
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true)
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}
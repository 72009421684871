import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  id: "grid",
  class: "result-grid-wrapper"
}
const _hoisted_2 = ["title", "onClick"]
const _hoisted_3 = ["onClick"]
const _hoisted_4 = ["title", "onClick"]
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { key: 1 }
const _hoisted_7 = ["onClick"]
const _hoisted_8 = ["src", "alt"]
const _hoisted_9 = {
  key: 1,
  class: "no-product-image"
}
const _hoisted_10 = ["onClick"]
const _hoisted_11 = { class: "final-price blue-text" }
const _hoisted_12 = { class: "quantity-wrapper" }
const _hoisted_13 = { class: "p-inputgroup" }
const _hoisted_14 = {
  key: 0,
  class: "col-12 flex pb-0 justify-content-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Tag = _resolveComponent("Tag")!
  const _component_InputNumber = _resolveComponent("InputNumber")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Card = _resolveComponent("Card")!
  const _component_Paginator = _resolveComponent("Paginator")!
  const _component_ProductDialog = _resolveComponent("ProductDialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getProductsToDisplay, (item) => {
        return (_openBlock(), _createBlock(_component_Card, {
          class: "product-item flex flex-column",
          key: item.part_number
        }, {
          header: _withCtx(() => [
            (item.desc)
              ? (_openBlock(), _createElementBlock("span", {
                  key: 0,
                  class: "blue-text product-description",
                  title: item.desc,
                  onClick: ($event: any) => (_ctx.onElemClick(item))
                }, _toDisplayString(item.desc), 9, _hoisted_2))
              : (_openBlock(), _createElementBlock("span", {
                  key: 1,
                  class: "blue-text product-description emphasized",
                  title: "No description available",
                  onClick: ($event: any) => (_ctx.onElemClick(item))
                }, "No description available", 8, _hoisted_3)),
            _createElementVNode("span", {
              class: "blue-text product-number",
              title: item.part_number,
              onClick: ($event: any) => (_ctx.onElemClick(item))
            }, [
              _createTextVNode(_toDisplayString(_ctx.partNumberLabel) + " " + _toDisplayString(item.part_number) + " ", 1),
              (item.upc)
                ? (_openBlock(), _createElementBlock("span", _hoisted_5, "| UPC #" + _toDisplayString(item.upc), 1))
                : _createCommentVNode("", true),
              (item.code)
                ? (_openBlock(), _createElementBlock("span", _hoisted_6, "| Code #" + _toDisplayString(item.code), 1))
                : _createCommentVNode("", true),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.partIdFields, (field) => {
                return (_openBlock(), _createElementBlock("span", {
                  key: field.field_no
                }, " | " + _toDisplayString(field.label) + " " + _toDisplayString(item[field.json_name]), 1))
              }), 128))
            ], 8, _hoisted_4)
          ]),
          content: _withCtx(() => [
            _createElementVNode("div", {
              class: "icon-name-wrapper",
              onClick: ($event: any) => (_ctx.onElemClick(item))
            }, [
              (item.image)
                ? (_openBlock(), _createElementBlock("img", {
                    key: 0,
                    class: "product-image",
                    src: item.image,
                    alt: item.part_number
                  }, null, 8, _hoisted_8))
                : (_openBlock(), _createElementBlock("div", _hoisted_9, _cache[2] || (_cache[2] = [
                    _createElementVNode("span", { class: "p-0 m-0 font-normal" }, "NO IMAGE AVAILABLE", -1)
                  ]))),
              _createVNode(_component_Tag, {
                severity: 
              _ctx.handleOnHandTagColor(
                item.avail_qty !== undefined ? item.avail_qty : 0,
              )
            ,
                value: 
              _ctx.handleOnHandTagText(
                item.avail_qty !== undefined ? item.avail_qty : 0,
              )
            ,
                rounded: "",
                class: "text-center on-hand-tag"
              }, null, 8, ["severity", "value"])
            ], 8, _hoisted_7),
            _createElementVNode("div", {
              class: "prices-wrapper flex justify-content-evenly align-items-center",
              onClick: ($event: any) => (_ctx.onElemClick(item))
            }, [
              _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.formatPrice(item.price)), 1)
            ], 8, _hoisted_10),
            _createElementVNode("div", _hoisted_12, [
              _createElementVNode("div", _hoisted_13, [
                _createVNode(_component_InputNumber, {
                  class: "quantity-input",
                  modelValue: item.quantity,
                  "onUpdate:modelValue": ($event: any) => ((item.quantity) = $event),
                  inputId: "horizontal-buttons",
                  min: 0,
                  step: _ctx.calculateStep(item),
                  showButtons: "",
                  buttonLayout: "horizontal",
                  decrementButtonclass: "p-button-danger",
                  incrementButtonclass: "p-button-success",
                  incrementButtonIcon: "pi pi-plus",
                  decrementButtonIcon: "pi pi-minus",
                  onFocus: _cache[0] || (_cache[0] = ($event: any) => ($event.target.select())),
                  minFractionDigits: _ctx.useFractionalQuantities(item) ? 1 : undefined,
                  onInput: ($event: any) => (_ctx.handleDecimals($event, item))
                }, null, 8, ["modelValue", "onUpdate:modelValue", "step", "minFractionDigits", "onInput"]),
                _createVNode(_component_Button, {
                  icon: "pi pi-shopping-cart",
                  class: "cart-btn",
                  onClick: ($event: any) => (_ctx.addItem(item))
                }, null, 8, ["onClick"])
              ])
            ])
          ]),
          _: 2
        }, 1024))
      }), 128))
    ]),
    (_ctx.showPaginator)
      ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
          _createVNode(_component_Paginator, {
            rows: _ctx.rowsPerPage,
            totalRecords: _ctx.getTotalRecords,
            rowsPerPageOptions: _ctx.pageOptions,
            first: _ctx.firstItem,
            template: "FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown",
            currentPageReportTemplate: "Showing {first} to {last} of {totalRecords} Products",
            "onUpdate:rows": _cache[1] || (_cache[1] = ($event: any) => {
        _ctx.rowsPerPage = $event;
        _ctx.$emit('update:rows', $event);
      }),
            onPage: _ctx.onPage
          }, null, 8, ["rows", "totalRecords", "rowsPerPageOptions", "first", "onPage"])
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_ProductDialog, {
      open: _ctx.showProductDetails,
      selectedProduct: _ctx.selectedProduct,
      customerId: _ctx.getCustomer.cust_id,
      onAddProductToCart: _ctx.addItem,
      onCloseProductDialog: _ctx.closeDialog
    }, null, 8, ["open", "selectedProduct", "customerId", "onAddProductToCart", "onCloseProductDialog"])
  ], 64))
}
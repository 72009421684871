import store from "@/store";
import {
  createRouter,
  createWebHistory,
  Router,
  RouteRecordRaw,
} from "vue-router";
import Home from "../views/Home.vue";
import Barcode from "../views/Barcode.vue";
import salesRoutes from "./salesRoutes";
import accountingRoutes from "./accountingRoutes";
import inventoryRoutes from "./inventoryRoutes";
import { App, nextTick } from "vue";
import LoginService from "@/services/LoginService";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/dashboard",
    name: "Home",
    component: Home,
    meta: {
      requiresAuth: true,
      title: "Home",
    },
  },
  {
    path: "/scan",
    name: "Barcode",
    component: Barcode,
    meta: {
      requiresAuth: true,
      title: "Barcode",
    },
    children: [
      {
        path: "inventory",
        component: () => import("../components/Scan/Inventory/Details.vue"),
      },
      {
        path: "order",
        component: () => import("../components/Scan/Order/Details.vue"),
      },
      {
        path: "receiving",
        component: () => import("../components/Scan/ScanBarcode.vue"),
        props: { menu: "RECEIVING" },
      },
      {
        path: "picking",
        component: () => import("../components/Scan/ScanBarcode.vue"),
        props: { menu: "PICKING" },
      },
      {
        path: "picking/details",
        component: () => import("../components/Scan/Picking/Details.vue"),
        props: { menu: "PICKING" },
      },
      {
        path: "transfer",
        component: () => import("../components/Scan/ScanBarcode.vue"),
        props: { menu: "TRANSFER" },
      },
      {
        path: "transfer/details",
        component: () => import("../components/Scan/Transfer/Details.vue"),
        props: true,
      },
      {
        path: "count",
        component: () => import("../components/Scan/Count/Details.vue"),
        props: { menu: "COUNT" },
      },
      {
        path: "recount",
        component: () => import("../components/Scan/Recount/Details.vue"),
        props: { menu: "RECOUNT" },
      },
      {
        path: "startJob",
        component: () => import("../components/Scan/StartJob/Details.vue"),
      },
      {
        path: "endJob",
        component: () => import("../components/Scan/EndJob/Details.vue"),
      },
      {
        path: "shopTransaction",
        component: () =>
          import("../components/Scan/ShopTransaction/Details.vue"),
      },
      {
        path: "pick",
        component: () => import("../components/Scan/PickShipment/Details.vue"),
      },
      {
        path: "pack",
        component: () => import("../components/Scan/PackShipment/Details.vue"),
      },
      {
        path: "shipment",
        component: () => import("../components/Scan/Shipment/Details.vue"),
      },
      {
        path: "woCompletion",
        component: () => import("../components/Scan/WoCompletion/Details.vue"),
      },
    ],
  },
  {
    path: "/customers",
    name: "Customer Inquiry",
    component: () => import("../views/CustomerInquiry.vue"),
    redirect: "/customers/general",
    props: true,
    children: [
      {
        path: "/customers/general",
        component: () => import("../components/CustomerInquiry/General.vue"),
        props: true,
      },
      {
        path: "/customers/ship-to-addresses",
        component: () =>
          import("../components/CustomerInquiry/ShipToAddresses.vue"),
        props: true,
      },
      {
        path: "/customers/contacts",
        component: () => import("../components/CustomerInquiry/Contacts.vue"),
        props: true,
      },
      {
        path: "/customers/contact-log",
        component: () => import("../components/CustomerInquiry/ContactLog.vue"),
        props: true,
      },
      {
        path: "/customers/credit-cards",
        component: () => import("../components/CustomerInquiry/CC.vue"),
        props: true,
      },
      {
        path: "/customers/deal-sheet/:cust_id",
        name: "DealSheet",
        component: () =>
          import("../components/CustomerInquiry/DealSheet/DealSheetForm.vue"),
        props: true,
      },
      {
        path: "/customers/deal-sheet",
        component: () => import("../components/CustomerInquiry/DealSheet.vue"),
        props: true,
      },
      {
        path: "/customers/change-history",
        component: () =>
          import("../components/CustomerInquiry/ChangeHistory.vue"),
        props: true,
      },
      {
        path: "/customers/attachments",
        component: () =>
          import("../components/CustomerInquiry/Attachments.vue"),
        props: true,
      },
      {
        path: "/customers/sales/orders",
        component: () =>
          import("../components/CustomerInquiry/sales/Sales.vue"),
        props: true,
      },
      {
        path: "/customers/sales/opportunities",
        component: () =>
          import("../components/CustomerInquiry/sales/SalesOpps.vue"),
        props: true,
      },
      {
        path: "/customers/:cust_id/sales/opportunities/:id",
        component: (): Promise<unknown> =>
          import("@/components/Sales/Opps/OpportunitiesTab.vue"),
        props: true,
      },
      {
        path: "/customers/rma",
        component: () => import("../components/CustomerInquiry/RMA.vue"),
        props: true,
      },
      {
        path: "/customers/sales/quotes",
        component: () =>
          import("../components/CustomerInquiry/sales/SalesQuotes.vue"),
        props: true,
      },
      {
        path: "/customers/ar",
        component: () => import("../components/CustomerInquiry/Invoices.vue"),
        props: true,
      },
      {
        path: "/customers/billing",
        component: () => import("../components/Billing.vue"),
        props: true,
      },
      {
        path: "/customers/fieldservices",
        component: () =>
          import("../components/CustomerInquiry/CustomerFieldServices.vue"),
        redirect: "/customers/fieldservices/general",
        props: true,
        children: [
          {
            path: "/customers/fieldservices/general",
            component: () =>
              import("../components/CustomerInquiry/FieldServices.vue"),
          },
          {
            path: "/customers/:cust_id/fieldservices/:order_id",
            component: () => import("../components/FieldService/General.vue"),
            props: true,
          },
        ],
      },
    ],
    meta: {
      requiresAuth: true,
      title: "Customer Inquiry",
    },
  },
  salesRoutes,
  accountingRoutes,
  inventoryRoutes,
  {
    path: "/printable-view",
    name: "Printable View",
    component: () => import("../components/PrintableDataTable.vue"),
    meta: {
      requiresAuth: true,
      title: "Printable View",
    },
  },

  {
    path: "/manufacturing",
    name: "Manufacturing",
    redirect: "/manufacturing/original-orders",
    component: () => import("../views/Cosway.vue"),
    children: [
      {
        name: "Original Orders",
        path: "/manufacturing/original-orders",
        component: () =>
          import("../components/Manufacturing/OriginalOrders.vue"),
        props: true,
      },
      {
        name: "Manufacturing Sales Orders",
        path: "/manufacturing/orders",
        component: () => import("../components/Manufacturing/SalesOrders.vue"),
        props: true,
      },
      {
        name: "Work In Progress",
        path: "/manufacturing/WIP",
        component: () =>
          import("../components/Manufacturing/WorkInProgress.vue"),
        props: true,
      },
      {
        name: "Not Released",
        path: "/manufacturing/not-released",
        component: () => import("../components/Manufacturing/NotReleased.vue"),
        props: true,
      },
      {
        name: "Released",
        path: "/manufacturing/released",
        component: () => import("../components/Manufacturing/Released.vue"),
        props: true,
      },
      {
        name: "Bulk Left Over",
        path: "/manufacturing/bulk",
        component: () => import("../components/Manufacturing/Bulk.vue"),
        props: true,
      },
      {
        name: "Archive",
        path: "/manufacturing/archive",
        component: () => import("../components/Manufacturing/Archive.vue"),
        props: true,
      },
      {
        name: "Deleted",
        path: "/manufacturing/deleted",
        component: () => import("../components/Manufacturing/Deleted.vue"),
        props: true,
      },
      {
        name: "Search",
        path: "/manufacturing/search",
        component: () => import("../components/Manufacturing/Search.vue"),
        props: true,
      },
    ],
    meta: {
      requiresAuth: true,
      title: "manufacturing",
    },
  },
  // {
  //   path: "/parts",
  //   name: "Parts",
  //   component: () => import("../views/Parts.vue"),
  //   meta: {
  //     requiresAuth: true,
  //     title: "Parts",
  //   },
  // },
  {
    path: "/pos",
    name: "POS",
    redirect: "/pos/customers",
    component: () => import(/* webpackChunkName: "pos" */ "../views/Pos.vue"),
    children: [
      {
        name: "Customers",
        path: "/pos/customers",
        component: () =>
          import(
            /* webpackChunkName: "pos" */ "../components/Pos/Customers.vue"
          ),
        props: true,
      },
      {
        name: "POF",
        path: "/pos/pof",
        component: () =>
          import(/* webpackChunkName: "pos" */ "../components/Pos/Pof.vue"),
        props: true,
      },
      {
        name: "Parts",
        path: "/pos/parts",
        component: () =>
          import(/* webpackChunkName: "pos" */ "../components/Pos/Pos.vue"),
        props: true,
      },
      {
        name: "Open Orders",
        path: "/pos/orders",
        component: () =>
          import(/* webpackChunkName: "pos" */ "../components/Pos/Orders.vue"),
        props: true,
      },
      {
        name: "Ship Order Details",
        path: "/pos/ship",
        component: () =>
          import(/* webpackChunkName: "pos" */ "../components/Pos/Ship.vue"),
        props: true,
      },
      {
        name: "Open Invoices",
        path: "/pos/invoices",
        component: () =>
          import(
            /* webpackChunkName: "pos" */ "../components/Pos/Invoices.vue"
          ),
        props: true,
      },
      {
        name: "Shipping Information",
        path: "/pos/shipping",
        component: () =>
          import(
            /* webpackChunkName: "pos" */ "../components/Pos/OrderHeader.vue"
          ),
        props: true,
      },
      {
        name: "Payment",
        path: "/pos/payment",
        component: () =>
          import(/* webpackChunkName: "pos" */ "../components/Pos/Pay.vue"),
        props: true,
      },
      {
        name: "Receipt History",
        path: "/pos/receipt-history",
        component: () =>
          import(
            /* webpackChunkName: "pos" */ "../components/Pos/ReceiptHistory.vue"
          ),
        props: true,
      },
      {
        name: "Confirmation",
        path: "/pos/confirmation",
        component: () =>
          import(
            /* webpackChunkName: "pos" */ "../components/Pos/Confirmation.vue"
          ),
        props: true,
      },
      {
        name: "Order Created",
        path: "/pos/order-created",
        component: () =>
          import(
            /* webpackChunkName: "pos" */ "../components/Pos/OrderCreated.vue"
          ),
        props: true,
      },
    ],
    meta: {
      requiresAuth: true,
      title: "POS",
    },
  },
  {
    path: "/fieldservices",
    name: "Field Service Main",
    redirect: "/fieldservices",
    component: () => import("../views/FieldServices.vue"),
    props: true,
    children: [
      {
        name: "Field Services",
        path: "/fieldservices",
        component: () => import("../components/FieldService/FieldService.vue"),
        props: true,
      },
      {
        name: "Recent Customers",
        path: "/fieldservices/recent-customers",
        component: () =>
          import("../components/FieldService/RecentCustomers.vue"),
        props: true,
      },
      {
        name: "Created Field Service",
        path: "/fieldservices/created",
        component: () =>
          import("../components/FieldService/FieldServiceCreated.vue"),
        props: true,
      },
      {
        name: "Search Results",
        path: "/fieldservices/search-results",
        component: () =>
          import("../components/FieldService/CustomerSearchResults.vue"),
        props: true,
      },
      {
        name: "Field Service",
        path: "/fieldservices/:id",
        component: () => import("../components/FieldService/General.vue"),
        props: true,
      },
      {
        name: "General",
        path: "/fieldservices/:id/general",
        component: () => import("../components/FieldService/General.vue"),
        props: true,
      },
    ],
    meta: {
      requiresAuth: true,
      title: "Field Service",
    },
  },
  {
    path: "/tickets",
    name: "Billing",
    component: () => import("../views/Billing.vue"),
    meta: {
      requiresAuth: true,
      title: "Billing",
    },
    props: true,
  },
  //   // children: [
  //   //   {
  //   //     path: "/billing/ticketEntry",
  //   //     component: () => import("../components/Billing/TicketEntry.vue"),
  //   //     props: true,
  //   //   },
  //   //   {
  //   //     path: "/billing/timeEntry",
  //   //     component: () => import("../components/Billing/TimeEntry.vue"),
  //   //     props: true,
  //   //   },
  //   // ],
  // },
  // {
  //   path: "/documentControl",
  //   name: "Document Control",
  //   component: () => import("../views/UnderConstruction.vue"),
  //   meta: {
  //     requiresAuth: true,
  //     title: "Coming Soon",
  //   },
  //   props: true,
  // },
  // {
  //   path: "/engineering",
  //   name: "Engineering",
  //   component: () => import("../views/UnderConstruction.vue"),
  //   meta: {
  //     requiresAuth: true,
  //     title: "Coming Soon",
  //   },
  //   props: true,
  // },
  // {
  //   path: "/generalLedger",
  //   name: "General Ledger",
  //   component: () => import("../views/UnderConstruction.vue"),
  //   meta: {
  //     requiresAuth: true,
  //     title: "Coming Soon",
  //   },
  //   props: true,
  // },
  //{
  //  path: "/manufacturingPlanning",
  //  name: "Manufacturing Planning",
  //  component: () => import("../views/UnderConstruction.vue"),
  //  meta: {
  //    requiresAuth: true,
  //    title: "Coming Soon",
  //  },
  //  props: true,
  //},
  // {
  //   path: "/marketingAndSales",
  //   name: "Marketing and Sales",
  //   component: () => import("../views/UnderConstruction.vue"),
  //   meta: {
  //     requiresAuth: true,
  //     title: "Coming Soon",
  //   },
  //   props: true,
  // },
  {
    path: "/production",
    name: "Production",
    redirect: "/production/work-order",
    component: () => import("../views/Production.vue"),
    meta: {
      requiresAuth: true,
      title: "Production",
    },
    props: true,
    children: [
      {
        path: "/production/scheduling",
        component: () => import("../components/Production/Scheduling.vue"),
        props: true,
      },
      // {
      //   path: "/production/maintenance",
      //   component: () => import("../components/Production/Maintenance.vue"),
      //   props: true,
      // },
      {
        path: "/production/work-order",
        component: () => import("../components/Production/WorkOrder.vue"),
        props: true,
        children: [
          {
            path: "/production/work-order/:id",
            component: () =>
              import("../components/Production/WorkOrderDetails.vue"),
            props: true,
          },
        ],
      },
    ],
  },
  // {
  //   path: "/projects",
  //   name: "Projects",
  //   component: () => import("../views/UnderConstruction.vue"),
  //   meta: {
  //     requiresAuth: true,
  //     title: "Coming Soon",
  //   },
  //   props: true,
  // },
  // {
  //   path: "/purchasingReceiving",
  //   name: "Purchasing/Receiving",
  //   component: () => import("../views/UnderConstruction.vue"),
  //   meta: {
  //     requiresAuth: true,
  //     title: "Coming Soon",
  //   },
  //   props: true,
  // },
  // {
  //   path: "/workFlows",
  //   name: "Work Flows",
  //   component: () => import("../views/UnderConstruction.vue"),
  //   meta: {
  //     requiresAuth: true,
  //     title: "Coming Soon",
  //   },
  //   props: true,
  // },
  // {
  //   path: "/details",
  //   name: "Details",
  //   component: () => import("../views/InvoiceDetail.vue"),
  //   meta: {
  //     requiresAuth: true,
  //     title: "Details",
  //   },
  // },
  {
    path: "/settings",
    name: "Settings",
    component: () => import("../views/Settings.vue"),
    meta: {
      requiresAuth: true,
      title: "Settings",
    },
  },
  {
    path: "/:catchAll(.*)*",
    name: "404",
    component: () => import("../views/404.vue"),
  },
];

export interface RoverRouter extends Router {
  superInstall?: (app: App) => void;
}

const router: RoverRouter = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.superInstall = router.install;

router.install = function (app) {
  router.superInstall && router.superInstall(app);

  router.afterEach((to, from, failure) => {
    if (!failure) {
      nextTick(() => {
        app.config.globalProperties.$posthog.capture("$pageview", {
          path: to.fullPath,
        });
      });
    }
  });

  router.beforeResolve(async (to, from, next) => {
    const requiresAuth = to.meta.requiresAuth;
    let user: any;
    let name;
    let loginUrl;
    let theme;
    let subKey;
    let roverJwt;
    let informerJwtToken;
    let informerDefaultAssistant;

    if (to.query.session) {
      store.dispatch("session/calculateUserTimeZone");
      store.commit("session/SET_SESSION_START_DATESTAMP", new Date().getDate());
      const session = Array.isArray(to.query.session)
        ? ""
        : JSON.parse(atob(to.query.session.toString()));
      subKey = session.subKey;
      store.commit("session/SET_SUB_KEY", subKey);
      roverJwt = session.roverJwt;
      store.commit("session/SET_ROVER_JWT", roverJwt);
      user = session.user;
      if (!user.commands || user.commands.length === 0) {
        const loginService = new LoginService();
        const response = await loginService.fetchLoginData();
        user.commands = response.user.commands;
        user.commands_rights = response.user.commands_rights;
        store.commit("session/DO_LOGIN", user);
      } else {
        store.commit("session/DO_LOGIN", user);
      }
      store.dispatch("sidemenu/filterLinks");
      name = session.name;
      store.commit("session/SET_CLIENT_NAME", name);
      theme = session.theme;
      store.dispatch("theme/setTheme", theme);
      loginUrl = session.loginUrl;

      store.commit("session/SET_LOGIN_URL", loginUrl);
      app.config.globalProperties.$posthog.identify(
        `${user.user_id}@${session.subscriptionClient}`,
        { userId: user?.user_id, email: user?.email },
      );
      const greeting = store.getters["session/getUserTimeZone"];
      const notification = {
        message: `${greeting}, ${
          user.first_name || user.user_name || user.user_id || ""
        }`.trim(),
        type: "info",
      };
      store.dispatch("notification/add", notification);
    } else {
      user = store.getters["session/getUser"];
    }
    const url = store.getters["session/getLogin"];

    // If no user is logged in, route to login or 404
    if (!user) {
      if (url) {
        location.replace(url.toString());
      } else {
        next({ name: "404" });
      }
      // If the user is already logged in and trying to go to the login page, route to main menu
    } else if (to.path === "/" && user) {
      const filteredMenuItems = store.getters["sidemenu/getLinks"];
      if (filteredMenuItems.length > 0) {
        const defaultLandingPage = JSON.parse(
          store.getters["sidemenu/getDefaultLandingPage"],
        );
        const validLandingPage = filteredMenuItems.find((item: any) => {
          return item.to === defaultLandingPage?.to;
        });
        if (validLandingPage) {
          store.dispatch("title/changeTitle", validLandingPage.label);
          next({ path: validLandingPage.to });
        } else {
          store.dispatch("title/changeTitle", filteredMenuItems[0].label);
          next({ path: filteredMenuItems[0].to });
        }
      } else {
        next({ name: "Home" });
      }
    } else if (store.getters["session/getAccess"](to.path)) {
      next();
    }
    document.title =
      store.getters["session/getClient"] + " Hub - " + to.meta.title;
  });
};
export { routes };

export default router;

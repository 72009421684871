import ApiService from "./ApiService";

export interface ShortsRequest {
  part_no: string;
}

export default class ShortsService extends ApiService {
  constructor() {
    super();
  }

  async fetchShorts(shortsRequest: ShortsRequest) {
    const response = await this.Get("shorts", shortsRequest);
    return response;
  }

  async fetchShortsById(id: string, shortsRequest?: ShortsRequest) {
    const response = await this.Get(`shorts/${id}`, shortsRequest);
    return response;
  }
}

import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createSlots as _createSlots, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "grid m-0 accounting-tab-content p-0 px-3" }
const _hoisted_2 = { class: "grid p-0 m-0 mt-3 justify-content-center" }
const _hoisted_3 = { class: "col-12 xl:col-11 p-0 grid" }
const _hoisted_4 = { class: "col-12 p-0 m-0 mr-1 pb-2 flex justify-content-end" }
const _hoisted_5 = { class: "flex w-full justify-content-center" }
const _hoisted_6 = { class: "flex justify-content-end pr-0 sm:pr-2 xl:pr-5" }
const _hoisted_7 = { class: "flex justify-content-end pr-0 sm:pr-2 xl:pr-5" }
const _hoisted_8 = { class: "hidden md:flex grid p-0 m-0" }
const _hoisted_9 = { class: "col-2 lg:col-2 p-0 pr-3 xl:pr-7" }
const _hoisted_10 = { class: "col-12 md:hidden grid p-0 m-0 justify-content-end" }
const _hoisted_11 = { class: "col-6 sm:col-3 p-0 pr-2 md:pr-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_ProgressSpinner = _resolveComponent("ProgressSpinner")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Column = _resolveComponent("Column")!
  const _component_Calendar = _resolveComponent("Calendar")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _component_Card = _resolveComponent("Card")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Card, {
      class: "col-12 border-round-3xl tab-card-content p-0",
      style: { minHeight: '63vh' }
    }, {
      content: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_Button, {
                label: "Add Item",
                icon: "pi pi-plus",
                class: "p-button-sm py-2 px-4"
              })
            ]),
            _createElementVNode("div", _hoisted_5, [
              (_ctx.isLoading)
                ? (_openBlock(), _createBlock(_component_ProgressSpinner, {
                    key: 0,
                    size: "50"
                  }))
                : _createCommentVNode("", true)
            ]),
            (!_ctx.isLoading)
              ? (_openBlock(), _createBlock(_component_DataTable, {
                  key: 0,
                  value: _ctx.ap_items,
                  class: "p-datatable-sm w-full bordered-table with-footer",
                  removableSort: "",
                  rows: _ctx.itemsPerPage,
                  rowsPerPageOptions: [15, 25, 40],
                  paginator: true,
                  "paginator-template": "FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown",
                  responsiveLayout: "stack",
                  breakpoint: "745px",
                  filters: _ctx.filters,
                  "onUpdate:filters": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.filters) = $event)),
                  filterDisplay: "menu"
                }, _createSlots({
                  empty: _withCtx(() => [
                    _cache[10] || (_cache[10] = _createElementVNode("div", { class: "text-center" }, "No AP Items Found.", -1))
                  ]),
                  default: _withCtx(() => [
                    _createVNode(_component_Column, {
                      field: "ap_id",
                      header: "AP ID",
                      headerClass: "text-xs xl:text-base",
                      bodyClass: "text-center",
                      sortable: ""
                    }, {
                      filter: _withCtx(() => [
                        _createVNode(_component_InputText, {
                          type: "text",
                          modelValue: _ctx.filters['ap_id'].value,
                          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.filters['ap_id'].value) = $event)),
                          class: "p-column-filter",
                          placeholder: "Search by id",
                          onFocus: _cache[1] || (_cache[1] = ($event: any) => ($event.target.select()))
                        }, null, 8, ["modelValue"])
                      ]),
                      filterapply: _withCtx(() => _cache[11] || (_cache[11] = [])),
                      _: 1
                    }),
                    _createVNode(_component_Column, {
                      field: "inv_date",
                      header: "Date",
                      headerClass: "text-xs xl:text-base",
                      bodyClass: "text-center",
                      sortable: "",
                      sortField: "sortableDate",
                      filterField: "sortableDate",
                      dataType: "date"
                    }, {
                      body: _withCtx(({ data }) => [
                        _createTextVNode(_toDisplayString(data.inv_date && data.inv_date !== ""
                      ? _ctx.formatDate(data.inv_date)
                      : ""), 1)
                      ]),
                      filter: _withCtx(() => [
                        _createVNode(_component_Calendar, {
                          modelValue: _ctx.filters['sortableDate'].value,
                          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.filters['sortableDate'].value) = $event)),
                          class: "p-column-filter",
                          placeholder: "Search by date",
                          format: "MM-dd-yy",
                          manualInput: false,
                          showButtonBar: true,
                          showIcon: ""
                        }, null, 8, ["modelValue"])
                      ]),
                      filterapply: _withCtx(() => _cache[12] || (_cache[12] = [])),
                      _: 1
                    }),
                    _createVNode(_component_Column, {
                      field: "amt_to_pay",
                      header: "Payment Amount",
                      headerClass: "text-xs xl:text-base",
                      bodyClass: "justify-content-left md:justify-content-center",
                      sortable: ""
                    }, {
                      body: _withCtx(({ data }) => [
                        _createElementVNode("div", _hoisted_6, [
                          _createElementVNode("span", null, _toDisplayString(data.amt_to_pay ? _ctx.formatPrice(data.amt_to_pay) : "$0.00"), 1)
                        ])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_Column, {
                      field: "balance",
                      header: "Balance Due",
                      headerClass: "text-xs xl:text-base",
                      bodyClass: "justify-content-left md:justify-content-center",
                      sortable: ""
                    }, {
                      body: _withCtx(({ data }) => [
                        _createElementVNode("div", _hoisted_7, [
                          _createElementVNode("span", null, _toDisplayString(data.balance ? _ctx.formatPrice(data.balance) : "$0.00"), 1)
                        ])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_Column, {
                      field: "vendor",
                      header: "Vendor ID",
                      headerClass: "text-xs xl:text-base",
                      bodyClass: "text-right",
                      sortable: ""
                    }, {
                      filter: _withCtx(() => [
                        _createVNode(_component_InputText, {
                          type: "text",
                          modelValue: _ctx.filters['vendor'].value,
                          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.filters['vendor'].value) = $event)),
                          class: "p-column-filter",
                          placeholder: "Search by vendor id",
                          onFocus: _cache[4] || (_cache[4] = ($event: any) => ($event.target.select()))
                        }, null, 8, ["modelValue"])
                      ]),
                      filterapply: _withCtx(() => _cache[13] || (_cache[13] = [])),
                      _: 1
                    }),
                    _createVNode(_component_Column, {
                      field: "vendor_name",
                      header: "Vendor Name",
                      headerClass: "text-xs xl:text-base",
                      bodyClass: "text-center",
                      sortable: ""
                    }, {
                      filter: _withCtx(() => [
                        _createVNode(_component_InputText, {
                          type: "text",
                          modelValue: _ctx.filters['vendor_name'].value,
                          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.filters['vendor_name'].value) = $event)),
                          class: "p-column-filter",
                          placeholder: "Search by vendor name",
                          onFocus: _cache[6] || (_cache[6] = ($event: any) => ($event.target.select()))
                        }, null, 8, ["modelValue"])
                      ]),
                      filterapply: _withCtx(() => _cache[14] || (_cache[14] = [])),
                      _: 1
                    }),
                    _createVNode(_component_Column, {
                      field: "invoice",
                      header: "Invoice #",
                      headerClass: "text-xs xl:text-base",
                      bodyClass: "text-right",
                      sortable: ""
                    }, {
                      filter: _withCtx(() => [
                        _createVNode(_component_InputText, {
                          type: "text",
                          modelValue: _ctx.filters['invoice'].value,
                          "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.filters['invoice'].value) = $event)),
                          class: "p-column-filter",
                          placeholder: "Search by invoice",
                          onFocus: _cache[8] || (_cache[8] = ($event: any) => ($event.target.select()))
                        }, null, 8, ["modelValue"])
                      ]),
                      filterapply: _withCtx(() => _cache[15] || (_cache[15] = [])),
                      _: 1
                    })
                  ]),
                  _: 2
                }, [
                  (_ctx.ap_items.length > 0)
                    ? {
                        name: "footer",
                        fn: _withCtx(() => [
                          _createElementVNode("div", _hoisted_8, [
                            _cache[16] || (_cache[16] = _createElementVNode("div", { class: "col-3 p-0 pl-3 text-right" }, "Total", -1)),
                            _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.computedMiscTotal), 1)
                          ]),
                          _createElementVNode("div", _hoisted_10, [
                            _cache[17] || (_cache[17] = _createElementVNode("div", { class: "col-2 p-0 pl-3 text-left" }, "Total", -1)),
                            _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.computedMiscTotal), 1)
                          ])
                        ]),
                        key: "0"
                      }
                    : undefined
                ]), 1032, ["value", "rows", "filters"]))
              : _createCommentVNode("", true)
          ])
        ])
      ]),
      _: 1
    })
  ]))
}
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "sales-top-buttons flex justify-content-between flex-wrap" }
const _hoisted_2 = { class: "flex justify-content-end align-items-center flex-wrap w-full ml-3" }
const _hoisted_3 = { class: "flex justify-content-end align-items-center flex-wrap flex-1" }
const _hoisted_4 = { class: "right-buttons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Search = _resolveComponent("Search")!
  const _component_Button = _resolveComponent("Button")!
  const _component_PartsTable = _resolveComponent("PartsTable")!
  const _component_Card = _resolveComponent("Card")!
  const _component_ModalSpinner = _resolveComponent("ModalSpinner")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Card, null, {
      header: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _cache[0] || (_cache[0] = _createElementVNode("div", { class: "flex flex-column justify-content-center font-semibold" }, null, -1)),
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_Search, {
              id: "inventoryPartAutoComplete",
              ref: "inventoryPartAutoComplete",
              label: "name",
              currentView: "Inventory",
              placeholder: "Search for Parts",
              leftIcon: true,
              scannerMode: true,
              quickScanMode: false,
              class: "large-search-input",
              searchFunction: _ctx.searchPurchaseOrders
            }, null, 8, ["searchFunction"]),
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _withDirectives(_createVNode(_component_Button, {
                  onClick: _ctx.clearAllFilters,
                  class: "split-sales-order-btn",
                  icon: "pi pi-filter-slash",
                  outlined: ""
                }, null, 8, ["onClick"]), [
                  [_directive_tooltip, 'Clear All Filters']
                ]),
                (_ctx.userHasPartsAccess)
                  ? _withDirectives((_openBlock(), _createBlock(_component_Button, {
                      key: 0,
                      class: "split-sales-order-btn",
                      icon: "pi pi-plus",
                      label: "New Part",
                      onClick: _ctx.handleAddNewPart
                    }, null, 8, ["onClick"])), [
                      [_directive_tooltip, 'Create New Part']
                    ])
                  : _createCommentVNode("", true)
              ])
            ])
          ])
        ])
      ]),
      content: _withCtx(() => [
        _createVNode(_component_PartsTable, {
          ref: "partsTable",
          partNumber: _ctx.partNumber,
          onRowClick: _ctx.handlePartRowClick
        }, null, 8, ["partNumber", "onRowClick"])
      ]),
      _: 1
    }),
    _createVNode(_component_ModalSpinner, { visible: _ctx.isLoading }, null, 8, ["visible"])
  ], 64))
}
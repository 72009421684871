import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "mt-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_CheckBox = _resolveComponent("CheckBox")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createBlock(_component_Dialog, {
    visible: _ctx.showDialog,
    header: _ctx.heading,
    closable: false,
    modal: ""
  }, {
    footer: _withCtx(() => [
      _createVNode(_component_Button, {
        label: "Print",
        onClick: _ctx.handlePrint
      }, null, 8, ["onClick"]),
      _createVNode(_component_Button, {
        label: "Cancel",
        onClick: _ctx.cancel
      }, null, 8, ["onClick"])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", null, [
        _createVNode(_component_Dropdown, {
          modelValue: _ctx.selectedPrinter,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedPrinter) = $event)),
          options: _ctx.printerOptions,
          optionLabel: "svr_path_name",
          optionValue: "svr_path_name",
          placeholder: "Select Printer"
        }, null, 8, ["modelValue", "options"])
      ]),
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_CheckBox, {
          id: "setAsDefaultPrinter",
          name: "setAsDefaultPrinter",
          modelValue: _ctx.saveDefaultPrinter,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.saveDefaultPrinter) = $event)),
          binary: true,
          class: "text-sm mr-2"
        }, null, 8, ["modelValue"]),
        _cache[2] || (_cache[2] = _createElementVNode("label", {
          for: "setAsDefaultPrinter",
          class: "text-sm"
        }, "Set as default printer", -1))
      ])
    ]),
    _: 1
  }, 8, ["visible", "header"]))
}

import { defineComponent, PropType, ref } from "vue";
import { mapGetters } from "vuex";
import { FilterMatchMode } from "primevue/api";

import Button from "primevue/button";
import Dialog from "primevue/dialog";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import InputText from "primevue/inputtext";

import { ShipItem } from "@/types/customer";

import SalesOrder from "@/types/salesorder";
import { useLazyLoadPagination } from "@/composables/DataTable/useLazyLoadPagination";
import CustomerService from "@/services/CustomerService";

const customerService = new CustomerService();

export default defineComponent({
  name: "ShipToAddressesTable",
  components: {
    Dialog,
    Button,
    DataTable,
    Column,
    InputText,
  },
  lazyLoadPagination: {
    type: Boolean,
    default: false,
  },
  props: {
    shipToAddresses: {
      type: Array,
      required: true,
    },
    hideNewAddressButton: {
      type: Boolean,
      default: false,
    },
    showAllButton: {
      type: Boolean,
      default: true,
    },
    salesOrder: {
      type: SalesOrder,
      default: () => ({}),
    },
    customerId: { type: String, required: true },
  },
  emits: ["openAddAddressDialog", "onRowClick", "onClose"],
  computed: {
    ...mapGetters({
      hideUseNewAddressButton: "mrkControl/hideUseNewAddress",
      posLazyLoadShiptos: "mrkControl/posLazyLoadShiptos",
    }),
    showAddressButton(): boolean {
      return !this.hideNewAddressButton && !this.hideUseNewAddressButton;
    },

    lazyLoadShipTos(): boolean {
      return this.posLazyLoadShiptos;
    },

    displayRecords(): any[] {
      return this.records.slice(this.first, this.first + this.rowsPerPage);
    },
    tableClass(): string {
      return "p-datatable-sm ship-to-table";
    },
  },
  data() {
    return {
      showDialog: false,
      rowsPerPageOptions: [5, 10, 25, 50],
      lazyLoadColumns: [
        { field_name: "ship_seq", display_name: "Ship Seq" },
        { field_name: "ship_name", display_name: "Ship Name" },
        { field_name: "ship_to", display_name: "Street", type: "array" },
        { field_name: "ship_city", display_name: "City" },
        { field_name: "ship_state", display_name: "State" },
        { field_name: "ship_zip", display_name: "Zip" },
        { field_name: "ship_country", display_name: "Country" },
        { field_name: "ship_attn", display_name: "Attention" },
        { field_name: "ship_phone", display_name: "Phone" },
        { field_name: "ship_email", display_name: "Email" },
      ],
    };
  },

  setup(props) {
    const filters = ref<any>({});
    const sorts = ref<any>({});
    const hasFilters = ref<boolean>(false);
    const pageRangeInterval = ref<number>(100);
    const fetchShiptos = async (isReset = false) => {
      isLoading.value = true;
      var rangeStart = records.value.length + 1;
      var rangeEnd = first.value + pageRangeInterval.value;
      if (isReset) {
        rangeStart = 1;
        rangeEnd = pageRangeInterval.value;
        first.value = 0;
        records.value = [];
      }
      try {
        const shipToRequest = {} as any;
        shipToRequest.rangeStart = "" + rangeStart;
        shipToRequest.rangeEnd = "" + rangeEnd;
        (shipToRequest.VirtualFileRootLabel = "shipto_items"),
          (shipToRequest.VirtualFileFromAssociation = "true");
        shipToRequest.fieldnames =
          "ship_seq ship_city ship_name ship_country ship_email ship_phone ship_state ship_zip ship_to";

        const filterKeys = Object.keys(filters.value);
        if (filterKeys.length > 0) {
          filterKeys.forEach((key) => {
            if (filters.value[key].value) {
              if (!shipToRequest.filters) {
                shipToRequest.filters = {};
              }
              shipToRequest.filters[key] = filters.value[key];
            }
          });
        }

        const requestSorts = Object.keys(sorts.value);
        if (requestSorts.length > 0) {
          requestSorts.forEach((key) => {
            if (!shipToRequest.sorts) {
              shipToRequest.sorts = {};
            }
            shipToRequest.sorts[key] = sorts.value[key];
          });
        }

        const lookupData = await customerService.getCustomerShipTos(
          shipToRequest,
          "",
          props.customerId,
        );
        records.value.push(...lookupData.shipto_items);
        totalRecords.value = lookupData.total_records_found;
        hasFilters.value = true;
      } catch (error) {
        console.error(error);
      } finally {
        isLoading.value = false;
      }
    };

    const { records, totalRecords, isLoading, first, rowsPerPage, onPage } =
      useLazyLoadPagination<any>(() => fetchShiptos(), 10);

    return {
      filters,
      records,
      totalRecords,
      isLoading,
      first,
      rowsPerPage,
      onPage,
      sorts,
      fetchShiptos,
      hasFilters,
    };
  },
  async created() {
    this.resetFilters();
    await this.fetchShiptos();
  },
  methods: {
    handleCloseModeOptionsDialog() {
      this.showDialog = false;
      this.$emit("onClose");
      this.resetFilters();
    },
    handleUseNewAddress() {
      this.$emit("openAddAddressDialog");
    },
    resetFilters() {
      this.filters = {
        ship_seq: {
          value: "",
          matchMode: FilterMatchMode.CONTAINS,
        },
        ship_name: {
          value: "",
          matchMode: FilterMatchMode.CONTAINS,
        },
        ship_city: {
          value: "",
          matchMode: FilterMatchMode.CONTAINS,
        },
        ship_state: {
          value: "",
          matchMode: FilterMatchMode.CONTAINS,
        },
        ship_zip: {
          value: "",
          matchMode: FilterMatchMode.CONTAINS,
        },
        ship_country: {
          value: "",
          matchMode: FilterMatchMode.CONTAINS,
        },
        ship_attn: {
          value: "",
          matchMode: FilterMatchMode.CONTAINS,
        },
      };
    },
    isAddressSelected(data: ShipItem) {
      const shipAddress = this.salesOrder.shipItemAddress;
      return shipAddress.compareTo(data);
    },
    handleRowName(row: any): string {
      const isAddressSelected = this.isAddressSelected(row);
      return isAddressSelected ? "row-selected" : "";
    },
    handleRowClick(row: any) {
      this.$emit("onRowClick", row);
    },
    async handleFilter(event: any) {
      await this.fetchShiptos(true);
    },
    async sortData(event: any) {
      this.sorts = {
        [event.sortField]: event.sortOrder === 1 ? "asc" : "desc",
      };
      await this.fetchShiptos(true);
    },
  },
});

import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createBlock as _createBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "pt-1" }
const _hoisted_2 = {
  key: 0,
  class: "flex justify-content-center"
}
const _hoisted_3 = { class: "pr-0 xl:pr-6" }
const _hoisted_4 = { class: "pr-0 xl:pr-6" }
const _hoisted_5 = { class: "pr-0 xl:pr-6" }
const _hoisted_6 = { class: "flex p-0 m-0 justify-content-end" }
const _hoisted_7 = { class: "grid justify-content-center" }
const _hoisted_8 = { class: "col-12 flex pb-0" }
const _hoisted_9 = { class: "col-12 flex pb-0 pt-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProgressSpinner = _resolveComponent("ProgressSpinner")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Column = _resolveComponent("Column")!
  const _component_Calendar = _resolveComponent("Calendar")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _component_Card = _resolveComponent("Card")!
  const _component_MessageBox = _resolveComponent("MessageBox")!
  const _component_SendReceiptDialog = _resolveComponent("SendReceiptDialog")!
  const _component_Dialog = _resolveComponent("Dialog")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_Card, null, {
        content: _withCtx(() => [
          (_ctx.isLoadingCash)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createVNode(_component_ProgressSpinner)
              ]))
            : _createCommentVNode("", true),
          (!_ctx.isLoadingCash)
            ? (_openBlock(), _createBlock(_component_DataTable, {
                key: 1,
                value: _ctx.cash,
                class: "p-datatable-sm",
                breakpoint: "1100px",
                responsiveLayout: "stack",
                dataKey: "id",
                stateStorage: "session",
                paginator: true,
                rows: _ctx.rowsPerPage,
                rowsPerPageOptions: [5, 10, 20],
                first: _ctx.first,
                sortField: _ctx.sortField,
                sortOrder: _ctx.sortOrder,
                paginatorTemplate: "FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown",
                currentPageReportTemplate: "Showing {first} to {last} of {totalRecords} Receipts",
                filters: _ctx.filters,
                "onUpdate:filters": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.filters) = $event)),
                filterDisplay: "menu",
                onSort: _ctx.sortData,
                "removable-sort": "",
                onPage: _ctx.pageClick,
                onRowClick: _ctx.rowClicked
              }, {
                empty: _withCtx(() => _cache[15] || (_cache[15] = [
                  _createElementVNode("div", { class: "flex justify-content-center p-0 m-0" }, [
                    _createElementVNode("p", { class: "font-bold p-0 m-0 py-2" }, "No Receipts Found")
                  ], -1)
                ])),
                default: _withCtx(() => [
                  _createVNode(_component_Column, {
                    field: "cash_id",
                    header: "ID",
                    sortable: "",
                    bodyClass: "text-center"
                  }, {
                    filter: _withCtx(() => [
                      _createVNode(_component_InputText, {
                        type: "text",
                        modelValue: _ctx.cashIdFilter,
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.cashIdFilter) = $event)),
                        class: "p-column-filter",
                        placeholder: "Search by Id"
                      }, null, 8, ["modelValue"])
                    ]),
                    filterclear: _withCtx(() => [
                      _createVNode(_component_Button, {
                        type: "button",
                        label: "Clear",
                        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.handleClearFilter('cashIdFilter', '')))
                      })
                    ]),
                    filterapply: _withCtx(() => [
                      _createVNode(_component_Button, {
                        type: "button",
                        label: "Apply",
                        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.fetchFilteredCashDate(false)))
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_Column, {
                    field: "post_date",
                    header: "Date",
                    sortable: "",
                    "sort-field": "sortableDate",
                    bodyClass: "text-center"
                  }, {
                    body: _withCtx(({ data }) => [
                      _createTextVNode(_toDisplayString(data.post_date && data.post_date !== ""
                  ? _ctx.formatDate(data.post_date)
                  : ""), 1)
                    ]),
                    filter: _withCtx(() => [
                      _createVNode(_component_Calendar, {
                        modelValue: _ctx.dueDateFilter,
                        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.dueDateFilter) = $event)),
                        selectionMode: "range",
                        manualInput: false,
                        placeholder: "Select Date Range",
                        showButtonBar: true,
                        showIcon: true
                      }, null, 8, ["modelValue"])
                    ]),
                    filterclear: _withCtx(() => [
                      _createVNode(_component_Button, {
                        type: "button",
                        label: "Clear",
                        onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.handleClearFilter('dueDateFilter', null)))
                      })
                    ]),
                    filterapply: _withCtx(() => [
                      _createVNode(_component_Button, {
                        type: "button",
                        label: "Apply",
                        onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.fetchFilteredCashDate(false)))
                      })
                    ]),
                    _: 1
                  }),
                  (!_ctx.payoutView)
                    ? (_openBlock(), _createBlock(_component_Column, {
                        key: 0,
                        field: "payment_type",
                        header: "Payment Method",
                        sortable: "",
                        bodyClass: "text-center"
                      }, {
                        body: _withCtx(({ data }) => [
                          _createTextVNode(_toDisplayString(_ctx.getPaymentTypeTitle(data.payment_type, data.check)), 1)
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  (_ctx.payoutView)
                    ? (_openBlock(), _createBlock(_component_Column, {
                        key: 1,
                        field: "exp_date",
                        header: "Expiry Date",
                        headerClass: "text-right",
                        bodyClass: "text-right pr-2 ",
                        sortable: "",
                        style: { width: '13vw' }
                      }, {
                        body: _withCtx(({ data }) => [
                          _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.formatExpDate(data.creditcard_no_items?.[0]?.creditcard_exp)), 1)
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  (_ctx.payoutView)
                    ? (_openBlock(), _createBlock(_component_Column, {
                        key: 2,
                        field: "credit_card_id",
                        header: "Credit Card Number",
                        headerClass: "text-right",
                        bodyClass: "text-right pr-2 ",
                        sortable: "",
                        style: { width: '13vw' }
                      }, {
                        body: _withCtx(({ data }) => [
                          _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.formatCCNumber(data.creditcard_no_items?.[0]?.credit_card_id)), 1)
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  (!_ctx.payoutView)
                    ? (_openBlock(), _createBlock(_component_Column, {
                        key: 3,
                        field: "check_amount",
                        header: "Amount",
                        headerClass: "text-right",
                        bodyClass: "text-right pr-2 ",
                        sortable: "",
                        style: { width: '13vw' }
                      }, {
                        body: _withCtx(({ data }) => [
                          _createElementVNode("div", _hoisted_5, _toDisplayString(data.check_amount ? _ctx.formatPrice(data.check_amount) : "$0.00"), 1)
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  (!_ctx.payoutView)
                    ? (_openBlock(), _createBlock(_component_Column, {
                        key: 4,
                        header: "",
                        style: { width: '100px' }
                      }, {
                        body: _withCtx(({ data }) => [
                          _createElementVNode("div", _hoisted_6, [
                            (data.status == 'P')
                              ? _withDirectives((_openBlock(), _createBlock(_component_Button, {
                                  key: 0,
                                  icon: _ctx.getIconClass('voidTransaction', data.cash_id),
                                  class: "p-button-sm mx-1",
                                  onClick: ($event: any) => (_ctx.clickVoidTransaction(data))
                                }, null, 8, ["icon", "onClick"])), [
                                  [
                                    _directive_tooltip,
                                    `Void Transaction ${data.cash_id}`,
                                    void 0,
                                    { left: true }
                                  ]
                                ])
                              : _createCommentVNode("", true),
                            (this.getReg !== null)
                              ? _withDirectives((_openBlock(), _createBlock(_component_Button, {
                                  key: 1,
                                  icon: _ctx.getIconClass('print', data.cash_id),
                                  class: "p-button-sm mx-1",
                                  onClick: ($event: any) => (_ctx.handlePrintReceipt(data))
                                }, null, 8, ["icon", "onClick"])), [
                                  [
                                    _directive_tooltip,
                                    `Print Receipt ${data.cash_id}`,
                                    void 0,
                                    { left: true }
                                  ]
                                ])
                              : _withDirectives((_openBlock(), _createBlock(_component_Button, {
                                  key: 2,
                                  icon: _ctx.getIconClass('pdf', data.cash_id),
                                  class: "p-button-sm mx-1",
                                  onClick: ($event: any) => (_ctx.handleDownloadReceipt(data))
                                }, null, 8, ["icon", "onClick"])), [
                                  [
                                    _directive_tooltip,
                                    `Download Receipt ${data.cash_id}`,
                                    void 0,
                                    { left: true }
                                  ]
                                ]),
                            _withDirectives(_createVNode(_component_Button, {
                              icon: "pi pi-envelope",
                              class: "p-button-sm mx-1",
                              onClick: ($event: any) => {
                    _ctx.showEmailDialog = true;
                    _ctx.transactionToSend = data;
                  }
                            }, null, 8, ["onClick"]), [
                              [
                                _directive_tooltip,
                                `Email Receipt ${data.cash_id}`,
                                void 0,
                                { left: true }
                              ]
                            ])
                          ])
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }, 8, ["value", "rows", "first", "sortField", "sortOrder", "filters", "onSort", "onPage", "onRowClick"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    _createVNode(_component_MessageBox, {
      showDialog: _ctx.showConfirmVoid,
      header: "Confirm Void Transaction",
      icon: "WARNING",
      message: "This process will Post Void the selected transaction. Are you sure you wish to Post Void the transaction?",
      messageItems: [
      {
        ID: _ctx.transactionToVoid.cash_id,
        Amount: _ctx.formatPrice(_ctx.transactionToVoid.check_amount),
      },
    ],
      primaryButton: "Void",
      cancelButton: "Cancel",
      onPrimaryButtonClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.handleVoidTransaction(_ctx.transactionToVoid))),
      onCancelButtonClick: _cache[8] || (_cache[8] = ($event: any) => {
      _ctx.showConfirmVoid = false;
      _ctx.transactionToVoid = {} as Cash;
    })
    }, null, 8, ["showDialog", "messageItems"]),
    _createVNode(_component_SendReceiptDialog, {
      visible: _ctx.showEmailDialog,
      label: _ctx.transactionToSend.cash_id,
      "trans-id": _ctx.transactionToSend.cash_id,
      customerId: _ctx.customerId,
      onOnClose: _cache[9] || (_cache[9] = ($event: any) => {
      _ctx.showEmailDialog = false;
      _ctx.transactionToSend = {} as Cash;
    }),
      onOnSend: _cache[10] || (_cache[10] = ($event: any) => {
      _ctx.showEmailDialog = false;
      _ctx.showVoidTransactionActions = false;
      _ctx.transactionToSend = {} as Cash;
    })
    }, null, 8, ["visible", "label", "trans-id", "customerId"]),
    _createVNode(_component_Dialog, {
      visible: _ctx.showVoidTransactionActions,
      "onUpdate:visible": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.showVoidTransactionActions) = $event)),
      header: `Void Transaction ${_ctx.voidedTransaction.cash_id}`,
      modal: "",
      draggable: false,
      class: "w-7 sm:w-5 md:w-4 lg:w-3 xl:w-2",
      onHide: _cache[14] || (_cache[14] = ($event: any) => {
      _ctx.showVoidTransactionActions = false;
      _ctx.transactionToVoid = {} as Cash;
      _ctx.voidedTransaction = {} as Cash;
    })
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("div", _hoisted_8, [
            _withDirectives(_createVNode(_component_Button, {
              label: "Send",
              icon: "pi pi-envelope",
              class: "w-full h-3rem text-xl void-action-btn",
              onClick: _cache[11] || (_cache[11] = ($event: any) => {
            _ctx.showEmailDialog = true;
            _ctx.transactionToSend = JSON.parse(JSON.stringify(_ctx.voidedTransaction));
          })
            }, null, 512), [
              [
                _directive_tooltip,
                `Email Receipt ${_ctx.voidedTransaction.cash_id}`,
                void 0,
                { top: true }
              ]
            ])
          ]),
          _createElementVNode("div", _hoisted_9, [
            _withDirectives(_createVNode(_component_Button, {
              label: "Print",
              icon: "pi pi-print",
              class: "w-full h-3rem text-xl void-action-btn",
              disabled: _ctx.isPrintVoidReceiptBtnDisabled,
              onClick: _cache[12] || (_cache[12] = ($event: any) => (_ctx.handlePrintReceipt(_ctx.voidedTransaction)))
            }, null, 8, ["disabled"]), [
              [
                _directive_tooltip,
                `Print Receipt ${_ctx.voidedTransaction.cash_id}`,
                void 0,
                { top: true }
              ]
            ])
          ])
        ])
      ]),
      _: 1
    }, 8, ["visible", "header"])
  ], 64))
}
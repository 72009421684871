import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex justify-content-center" }
const _hoisted_2 = { class: "grid col-12 lg:col-10 xl:col-7 justify-content-center pb-5" }
const _hoisted_3 = { class: "grid col-12 justify-content-center text-6xl pb-2 pt-2 mt-4 mb-4" }
const _hoisted_4 = { class: "grid col-12 md:col-4 lg:col-4 justify-content-center" }
const _hoisted_5 = { class: "grid justify-content-center" }
const _hoisted_6 = { class: "grid col-12 md:col-4 lg:col-4 justify-content-center" }
const _hoisted_7 = { class: "grid col-12 md:col-4 lg:col-4 justify-content-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_EmailFileDialog = _resolveComponent("EmailFileDialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("span", _hoisted_3, [
          _cache[2] || (_cache[2] = _createElementVNode("i", {
            class: "pi pi-check-circle pr-3 pt-2 pl-2",
            style: {"font-size":"3rem"}
          }, null, -1)),
          _createTextVNode(" Field Service #" + _toDisplayString(_ctx.lastOrderId) + " Created ", 1)
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_Button, {
            class: "m-3 pt-3 confirmation-btn",
            style: { maxWidth: '16rem' },
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handleGetOrderPDF(_ctx.lastOrderId)))
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("i", {
                  class: _normalizeClass(_ctx.orderPDFIcon(_ctx.lastOrderId)),
                  style: {"font-size":"7rem"}
                }, null, 2),
                _cache[3] || (_cache[3] = _createElementVNode("span", { class: "col-12" }, " Download PDF", -1))
              ])
            ]),
            _: 1
          })
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createVNode(_component_Button, {
            class: "m-3 pt-3 confirmation-btn",
            style: { maxWidth: '16rem' },
            onClick: _ctx.handleEmailPDF
          }, {
            default: _withCtx(() => _cache[4] || (_cache[4] = [
              _createElementVNode("div", { class: "grid justify-content-center" }, [
                _createElementVNode("i", {
                  class: "pi pi-at col-12",
                  style: {"font-size":"7rem"}
                }),
                _createElementVNode("span", { class: "col-12" }, [
                  _createElementVNode("i", { class: "pi pi-file-pdf" }),
                  _createTextVNode(" Email PDF")
                ])
              ], -1)
            ])),
            _: 1
          }, 8, ["onClick"])
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createVNode(_component_Button, {
            class: "m-3 pt-3 confirmation-btn",
            style: { maxWidth: '16rem' },
            onClick: _ctx.handleContinue
          }, {
            default: _withCtx(() => _cache[5] || (_cache[5] = [
              _createElementVNode("div", { class: "grid justify-content-center" }, [
                _createElementVNode("i", {
                  class: "pi pi-arrow-right col-12",
                  style: {"font-size":"7rem"}
                }),
                _createElementVNode("span", { class: "col-12" }, "Continue")
              ], -1)
            ])),
            _: 1
          }, 8, ["onClick"])
        ])
      ])
    ]),
    _createVNode(_component_EmailFileDialog, {
      show: _ctx.showEmailPDFDialog,
      header: 'Email Field Service #' + _ctx.lastOrderId,
      fileId: _ctx.lastOrderId,
      fileName: _ctx.lastOrderId,
      contacts: _ctx.getLastFieldServiceOrder.customer.contact_id_items,
      onHide: _cache[1] || (_cache[1] = 
      (isHidden) => {
        _ctx.showEmailPDFDialog = isHidden;
      }
    ),
      onOnEmail: _ctx.sendEmail,
      onOnAddressAdded: _ctx.handleAddressAdded
    }, null, 8, ["show", "header", "fileId", "fileName", "contacts", "onOnEmail", "onOnAddressAdded"])
  ], 64))
}
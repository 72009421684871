import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "grid m-0 accounting-tab-content p-0 px-3" }
const _hoisted_2 = { class: "grid p-0 m-0 mt-3 justify-content-center" }
const _hoisted_3 = { class: "col-12 xl:col-11 p-0 grid" }
const _hoisted_4 = { class: "col-12 p-0 m-0 mr-1 pb-2 flex justify-content-end" }
const _hoisted_5 = { class: "flex w-full justify-content-center" }
const _hoisted_6 = { class: "flex flex-wrap justify-content-end" }
const _hoisted_7 = { class: "grid p-1 m-0 register-row" }
const _hoisted_8 = {
  class: "col p-0 m-0 px-1 py-1 md:py-0",
  style: { minWidth: '218px' }
}
const _hoisted_9 = { class: "p-inputgroup" }
const _hoisted_10 = {
  class: "col p-0 m-0 px-1 py-1 md:py-0",
  style: { minWidth: '218px' }
}
const _hoisted_11 = { class: "p-inputgroup" }
const _hoisted_12 = {
  class: "col p-0 m-0 px-1 py-1 md:py-0",
  style: { minWidth: '218px' }
}
const _hoisted_13 = { class: "p-inputgroup" }
const _hoisted_14 = { class: "grid justify-content-center p-2 m-2" }
const _hoisted_15 = { class: "flex justify-content-end pr-0 sm:pr-2 xl:pr-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_ProgressSpinner = _resolveComponent("ProgressSpinner")!
  const _component_Column = _resolveComponent("Column")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Calendar = _resolveComponent("Calendar")!
  const _component_InputNumber = _resolveComponent("InputNumber")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _component_Card = _resolveComponent("Card")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Card, {
      class: "col-12 border-round-3xl tab-card-content p-0",
      style: { minHeight: '59vh' }
    }, {
      content: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_Button, {
                label: "Add Item",
                icon: "pi pi-plus",
                class: "p-button-sm py-2 px-4"
              })
            ]),
            _createElementVNode("div", _hoisted_5, [
              (_ctx.isLoading)
                ? (_openBlock(), _createBlock(_component_ProgressSpinner, {
                    key: 0,
                    size: "50"
                  }))
                : _createCommentVNode("", true)
            ]),
            (!_ctx.isLoading)
              ? (_openBlock(), _createBlock(_component_DataTable, {
                  key: 0,
                  ref: "dt1",
                  value: _ctx.history_items,
                  dataKey: "reg_aging_name",
                  expandedRows: _ctx.expandedRows,
                  "onUpdate:expandedRows": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.expandedRows) = $event)),
                  removableSort: "",
                  class: "p-datatable-sm w-full bordered-table with-header"
                }, {
                  header: _withCtx(() => [
                    _createElementVNode("div", _hoisted_6, [
                      _createVNode(_component_Button, {
                        icon: _ctx.getExpandAllBtnIcon(),
                        label: _ctx.getExpandAllBtnLabel(),
                        class: "p-button-sm mx-1",
                        style: { minWidth: '122px' },
                        onClick: _ctx.toggleExpandAll
                      }, null, 8, ["icon", "label", "onClick"])
                    ])
                  ]),
                  empty: _withCtx(() => _cache[2] || (_cache[2] = [
                    _createElementVNode("div", { class: "text-center" }, "No Items Found.", -1)
                  ])),
                  expansion: _withCtx(({ data }) => [
                    _createElementVNode("div", _hoisted_14, [
                      (!_ctx.isLoading)
                        ? (_openBlock(), _createBlock(_component_DataTable, {
                            key: 0,
                            value: data.items,
                            dataKey: "reg_aging_name",
                            expandedRows: _ctx.expandedRows,
                            "onUpdate:expandedRows": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.expandedRows) = $event)),
                            class: "p-datatable-sm w-full register-history-inner-table",
                            responsiveLayout: "stack",
                            breakpoint: "600px",
                            removableSort: ""
                          }, {
                            empty: _withCtx(() => _cache[6] || (_cache[6] = [
                              _createElementVNode("div", { class: "text-center" }, "No Register items Found.", -1)
                            ])),
                            default: _withCtx(() => [
                              _createVNode(_component_Column, {
                                field: "apreg_id",
                                header: "Number",
                                "body-class": "text-center",
                                sortable: ""
                              }),
                              _createVNode(_component_Column, {
                                field: "date",
                                header: "Date",
                                "body-class": "text-center",
                                "data-type": "date",
                                sortable: "",
                                sortField: "sortableDate"
                              }),
                              _createVNode(_component_Column, {
                                field: "account",
                                header: "Account",
                                "body-class": "text-center",
                                sortable: ""
                              }),
                              _createVNode(_component_Column, {
                                field: "amount",
                                header: "Amount",
                                "body-class": "text-center",
                                sortable: ""
                              }, {
                                body: _withCtx(({ data }) => [
                                  _createElementVNode("div", _hoisted_15, [
                                    _createElementVNode("span", null, _toDisplayString(data.amount ? _ctx.formatPrice(data.amount) : "$0.00"), 1)
                                  ])
                                ]),
                                _: 2
                              }, 1024),
                              _createVNode(_component_Column, {
                                field: "procedure",
                                header: "Procedure",
                                "body-class": "text-center",
                                sortable: ""
                              })
                            ]),
                            _: 2
                          }, 1032, ["value", "expandedRows"]))
                        : _createCommentVNode("", true)
                    ])
                  ]),
                  default: _withCtx(() => [
                    _createVNode(_component_Column, {
                      expander: "",
                      style: {"width":"40px !important"}
                    }),
                    _createVNode(_component_Column, {
                      field: "reg_aging_name",
                      header: "Aging",
                      "body-class": "aging-summary-row",
                      sortable: ""
                    }, {
                      body: _withCtx(({ data }) => [
                        _createElementVNode("div", _hoisted_7, [
                          _createElementVNode("div", _hoisted_8, [
                            _createElementVNode("div", _hoisted_9, [
                              _cache[3] || (_cache[3] = _createElementVNode("small", { class: "p-inputgroup-addon medium-addon pr-0" }, "Aging Name", -1)),
                              _createVNode(_component_InputText, {
                                modelValue: data.reg_aging_name,
                                "onUpdate:modelValue": ($event: any) => ((data.reg_aging_name) = $event),
                                class: "text-sm",
                                inputClass: "text-left",
                                disabled: true
                              }, null, 8, ["modelValue", "onUpdate:modelValue"])
                            ])
                          ]),
                          _createElementVNode("div", _hoisted_10, [
                            _createElementVNode("div", _hoisted_11, [
                              _cache[4] || (_cache[4] = _createElementVNode("small", { class: "p-inputgroup-addon medium-addon" }, "Date", -1)),
                              _createVNode(_component_Calendar, {
                                modelValue: data.date,
                                "onUpdate:modelValue": ($event: any) => ((data.date) = $event),
                                disabled: true,
                                showIcon: true,
                                readonlyInput: true,
                                dateFormat: "mm-dd-yy"
                              }, null, 8, ["modelValue", "onUpdate:modelValue"])
                            ])
                          ]),
                          _createElementVNode("div", _hoisted_12, [
                            _createElementVNode("div", _hoisted_13, [
                              _cache[5] || (_cache[5] = _createElementVNode("small", { class: "p-inputgroup-addon medium-addon" }, "Amount", -1)),
                              _createVNode(_component_InputNumber, {
                                modelValue: data.amount,
                                "onUpdate:modelValue": ($event: any) => ((data.amount) = $event),
                                type: "number",
                                class: "text-sm",
                                inputClass: "text-left",
                                mode: "currency",
                                currency: "USD",
                                locale: "en-US",
                                disabled: true
                              }, null, 8, ["modelValue", "onUpdate:modelValue"])
                            ])
                          ])
                        ])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["value", "expandedRows"]))
              : _createCommentVNode("", true)
          ])
        ])
      ]),
      _: 1
    })
  ]))
}
import { resolveComponent as _resolveComponent, withKeys as _withKeys, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "grid flex justify-content-center mt-2" }
const _hoisted_2 = { class: "col-11 lg:col-11" }
const _hoisted_3 = { class: "grid align-items-center" }
const _hoisted_4 = { class: "confirmation-content" }
const _hoisted_5 = { style: {"margin":"0px"} }
const _hoisted_6 = { style: {"display":"block"} }
const _hoisted_7 = { style: {"display":"block"} }
const _hoisted_8 = { style: {"display":"block"} }
const _hoisted_9 = { style: {"display":"block"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Dialog = _resolveComponent("Dialog")!
  const _component_Divider = _resolveComponent("Divider")!
  const _component_InputNumber = _resolveComponent("InputNumber")!
  const _component_MessageBox = _resolveComponent("MessageBox")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.rowClass)
          }, [
            _createVNode(_component_InputText, {
              ref: "rep",
              class: "scan-input-text",
              modelValue: _ctx.rep,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.rep) = $event)),
              placeHolder: "Rep",
              onFocus: _cache[1] || (_cache[1] = ($event: any) => ($event.target.select())),
              onKeypress: _withKeys(_ctx.handleRep, ["enter"]),
              onBlur: _ctx.handleRepBlur
            }, null, 8, ["modelValue", "onKeypress", "onBlur"])
          ], 2),
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.rowClass)
          }, [
            _createVNode(_component_InputText, {
              ref: "notes",
              class: "scan-input-text",
              modelValue: _ctx.notes,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.notes) = $event)),
              onKeypress: _withKeys(_ctx.handleNotes, ["enter"]),
              placeHolder: "Notes"
            }, null, 8, ["modelValue", "onKeypress"])
          ], 2),
          (_ctx.showCustomer)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: _normalizeClass(_ctx.rowClass)
              }, [
                _createVNode(_component_InputText, {
                  ref: "customer",
                  class: "scan-input-text",
                  modelValue: _ctx.customer,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.customer) = $event)),
                  placeHolder: "Customer",
                  onFocus: _cache[4] || (_cache[4] = ($event: any) => ($event.target.select())),
                  onKeypress: _cache[5] || (_cache[5] = _withKeys(($event: any) => (_ctx.handleCustomer()), ["enter"]))
                }, null, 8, ["modelValue"])
              ], 2))
            : _createCommentVNode("", true),
          _createElementVNode("div", {
            class: _normalizeClass([_ctx.rowClass, "p-input-icon-right"])
          }, [
            _createVNode(_component_InputText, {
              ref: "part",
              class: "scan-input-text",
              modelValue: _ctx.part,
              "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.part) = $event)),
              placeHolder: "Part Number",
              onChange: _ctx.handlePart,
              onFocus: _cache[7] || (_cache[7] = ($event: any) => ($event.target.select()))
            }, null, 8, ["modelValue", "onChange"])
          ], 2),
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.rowClass)
          }, [
            _createVNode(_component_InputText, {
              ref: "quantity",
              modelValue: _ctx.quantity,
              "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.quantity) = $event)),
              class: "scan-input-text",
              buttonLayout: "horizontal",
              placeHolder: "Quantity",
              onFocus: _cache[9] || (_cache[9] = ($event: any) => ($event.target.select())),
              onChange: _ctx.handleQuantity
            }, null, 8, ["modelValue", "onChange"])
          ], 2),
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.rowClass)
          }, [
            _createVNode(_component_Button, {
              label: "Add Part",
              loading: _ctx.loadAddPart,
              onClick: _ctx.addPart,
              class: "scan-input-button"
            }, null, 8, ["loading", "onClick"])
          ], 2),
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.rowClass)
          }, [
            _createVNode(_component_Button, {
              label: `Preview Order (${_ctx.getStoreParts.length})`,
              onClick: _ctx.viewParts,
              class: "scan-input-button outlined"
            }, null, 8, ["label", "onClick"])
          ], 2),
          _createVNode(_component_Dialog, {
            visible: _ctx.displayQuantityConfirmation,
            "onUpdate:visible": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.displayQuantityConfirmation) = $event)),
            header: "Confirmation",
            breakpoints: { '960px': '75vw', '640px': '90vw' },
            style: { width: '350px' },
            modal: true
          }, {
            footer: _withCtx(() => [
              _createVNode(_component_Button, {
                label: "Yes",
                icon: "pi pi-check",
                onClick: _ctx.confirmQuantity,
                class: "p-button-text",
                autofocus: ""
              }, null, 8, ["onClick"]),
              _createVNode(_component_Button, {
                label: "No",
                icon: "pi pi-times",
                onClick: _ctx.cancelQuantity,
                class: "p-button-text"
              }, null, 8, ["onClick"])
            ]),
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_4, [
                _cache[13] || (_cache[13] = _createElementVNode("i", {
                  class: "pi pi-exclamation-triangle mr-3",
                  style: {"font-size":"2rem"}
                }, null, -1)),
                _createElementVNode("span", null, "Error detected. Are you sure you want to set quantity to " + _toDisplayString(_ctx.quantity) + "?", 1)
              ])
            ]),
            _: 1
          }, 8, ["visible"]),
          _createVNode(_component_Dialog, {
            visible: _ctx.displayConfirmation,
            "onUpdate:visible": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.displayConfirmation) = $event)),
            header: "Confirmation",
            breakpoints: { '960px': '75vw', '640px': '90vw' },
            style: { width: '350px' },
            modal: true
          }, {
            footer: _withCtx(() => [
              _createVNode(_component_Button, {
                label: "Yes",
                icon: "pi pi-check",
                onClick: _ctx.addConfirmation,
                class: "p-button-text",
                autofocus: ""
              }, null, 8, ["onClick"]),
              _createVNode(_component_Button, {
                label: "No",
                icon: "pi pi-times",
                onClick: _ctx.closeConfirmation,
                class: "p-button-text"
              }, null, 8, ["onClick"])
            ]),
            default: _withCtx(() => [
              _cache[14] || (_cache[14] = _createElementVNode("div", { class: "confirmation-content" }, [
                _createElementVNode("i", {
                  class: "pi pi-exclamation-triangle mr-3",
                  style: {"font-size":"2rem"}
                }),
                _createElementVNode("span", null, "Are you sure you want to create an order?")
              ], -1))
            ]),
            _: 1
          }, 8, ["visible"]),
          _createVNode(_component_Dialog, {
            visible: _ctx.displayViewParts,
            "onUpdate:visible": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.displayViewParts) = $event)),
            class: "displayParts",
            header: "View and Edit Order",
            modal: true,
            style: {"width":"100%"},
            closable: !_ctx.processingOrderCreation
          }, {
            footer: _withCtx(() => [
              _createVNode(_component_Button, {
                label: "Save",
                onClick: _ctx.saveChanges,
                disabled: _ctx.processingOrderCreation
              }, null, 8, ["onClick", "disabled"]),
              _createVNode(_component_Button, {
                label: "Create Order",
                loading: _ctx.loadCreateOrder || _ctx.processingOrderCreation,
                onClick: _ctx.openDialog
              }, null, 8, ["loading", "onClick"])
            ]),
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getStoreParts, (part) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: part.id,
                  class: "confirmation-content"
                }, [
                  _createVNode(_component_Divider),
                  _createVNode(_component_Button, {
                    "aria-label": "Remove Part",
                    severity: "warning",
                    icon: "pi pi-times",
                    style: {"background-color":"red","border-color":"red","color":"white","float":"right","display":"inline-flex"},
                    onClick: ($event: any) => (_ctx.removePart(part))
                  }, null, 8, ["onClick"]),
                  _createElementVNode("h3", _hoisted_5, "Part: " + _toDisplayString(part.part_id), 1),
                  _createElementVNode("span", _hoisted_6, "Description: " + _toDisplayString(part.desc), 1),
                  _createElementVNode("span", _hoisted_7, "AM PRICE: $" + _toDisplayString(parseFloat(part.am_price).toLocaleString("en-US", {
                  minimumFractionDigits: 4,
                })), 1),
                  _createElementVNode("span", _hoisted_8, "REP: " + _toDisplayString(part.rep_id), 1),
                  _createElementVNode("span", _hoisted_9, "NOTES: " + _toDisplayString(part.note), 1),
                  _createVNode(_component_InputNumber, {
                    modelValue: part.qty,
                    "onUpdate:modelValue": ($event: any) => ((part.qty) = $event),
                    inputId: "horizontal-buttons",
                    showButtons: "",
                    buttonLayout: "horizontal",
                    step: 1,
                    min: 1,
                    decrementButtonClass: "p-button-danger",
                    incrementButtonClass: "p-button-success",
                    incrementButtonIcon: "pi pi-plus",
                    decrementButtonIcon: "pi pi-minus",
                    style: {"width":"100%","max-width":"600px","padding-top":"4px"}
                  }, null, 8, ["modelValue", "onUpdate:modelValue"])
                ]))
              }), 128)),
              _createVNode(_component_Divider),
              _createElementVNode("div", null, "Total # of Parts: " + _toDisplayString(_ctx.partCount), 1),
              _createElementVNode("div", null, "Total Price of Parts: $" + _toDisplayString(_ctx.totalPrice.toFixed(4)), 1)
            ]),
            _: 1
          }, 8, ["visible", "closable"])
        ])
      ])
    ]),
    _createVNode(_component_MessageBox, {
      showDialog: _ctx.showErrorDialog,
      icon: "INFO",
      header: "Error",
      message: _ctx.errorMessage,
      primaryButton: "OK",
      onPrimaryButtonClick: _ctx.clickConfirmErrorDialog
    }, null, 8, ["showDialog", "message", "onPrimaryButtonClick"])
  ], 64))
}
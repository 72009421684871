
import { defineComponent } from "vue";
import Card from "primevue/card";
import Tooltip from "primevue/tooltip";
import { mapGetters } from "vuex";
export default defineComponent({
  components: {
    Card,
  },
  directives: {
    tooltip: Tooltip,
  },
  props: {
    selected: Boolean,
    title: String,
    subtitle: String,
    statInfo: {
      type: Object,
      default: () => {
        return {};
      },
    },
    getter: Function,
  },
  computed: {
    ...mapGetters({
      getCustomer: "customer/getCust",
    }),
  },
  created() {
    this.getter && this.getter();
  },
});

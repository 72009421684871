import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vShow as _vShow, withDirectives as _withDirectives, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "customer-tab" }
const _hoisted_2 = { class: "line-height-4 pr-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProgressSpinner = _resolveComponent("ProgressSpinner")!
  const _component_Column = _resolveComponent("Column")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _component_TabPanel = _resolveComponent("TabPanel")!
  const _component_LookupDataTable = _resolveComponent("LookupDataTable")!
  const _component_TabView = _resolveComponent("TabView")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createBlock(_component_Dialog, {
    visible: _ctx.visible,
    "onUpdate:visible": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.visible) = $event)),
    header: _ctx.getTitle,
    modal: true,
    draggable: false,
    closable: false,
    class: "p-fluid text-center w-11 lkp-part-dialog",
    style: {"height":"90vh","justify-content":"center"}
  }, {
    footer: _withCtx(() => [
      _withDirectives(_createVNode(_component_Button, {
        onClick: _ctx.handleInventoryModule,
        label: "Part Details"
      }, null, 8, ["onClick"]), [
        [_vShow, _ctx.hasInventoryAccess]
      ]),
      _createVNode(_component_Button, { onClick: _ctx.handleClose }, {
        default: _withCtx(() => _cache[3] || (_cache[3] = [
          _createTextVNode("Close")
        ])),
        _: 1
      }, 8, ["onClick"])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_TabView, {
        activeIndex: 0,
        scrollable: true,
        class: "account-tabview"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_TabPanel, null, {
            header: _withCtx(() => _cache[1] || (_cache[1] = [
              _createElementVNode("div", { class: "customer-tab" }, [
                _createElementVNode("span", { class: "line-height-4 pr-2" }, "Inventory")
              ], -1)
            ])),
            default: _withCtx(() => [
              (_ctx.isLoading)
                ? (_openBlock(), _createBlock(_component_ProgressSpinner, {
                    key: 0,
                    class: "col-12"
                  }))
                : (_openBlock(), _createBlock(_component_DataTable, {
                    key: 1,
                    sortOrder: 1,
                    value: _ctx.getTableInfo,
                    class: "p-datatable-sm w-12 text-sm font-semibold bordered-datatable",
                    stripedRows: "",
                    responsiveLayout: "stack",
                    breakpoint: "767px",
                    scrollable: "",
                    scrollHeight: "100%",
                    style: {"height":"100%","overflow":"auto"}
                  }, {
                    empty: _withCtx(() => _cache[2] || (_cache[2] = [
                      _createElementVNode("div", { class: "flex justify-content-center" }, [
                        _createElementVNode("span", { class: "text-center" }, "No Inventory Found")
                      ], -1)
                    ])),
                    default: _withCtx(() => [
                      _createVNode(_component_Column, {
                        field: "invloc",
                        header: "Invloc"
                      }),
                      _createVNode(_component_Column, {
                        field: "group",
                        header: "Group"
                      }),
                      _createVNode(_component_Column, {
                        field: "on_hand",
                        header: "On Hand",
                        "body-style": "text-align: right"
                      }),
                      _createVNode(_component_Column, {
                        field: "commited",
                        header: "Commited",
                        "body-style": "text-align: right"
                      }),
                      _createVNode(_component_Column, {
                        field: "allocated",
                        header: "Allocated",
                        "body-style": "text-align: right"
                      }),
                      _createVNode(_component_Column, {
                        field: "shortages",
                        header: "Shortages",
                        "body-style": "text-align: right"
                      }),
                      _createVNode(_component_Column, {
                        field: "available",
                        header: "Available",
                        "body-style": "text-align: right"
                      }),
                      _createVNode(_component_Column, {
                        field: "on_order",
                        header: "On Order",
                        "body-style": "text-align: right"
                      })
                    ]),
                    _: 1
                  }, 8, ["value"]))
            ]),
            _: 1
          }),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.lookups, (lookup, index) => {
            return (_openBlock(), _createBlock(_component_TabPanel, { key: index }, {
              header: _withCtx(() => [
                _createElementVNode("div", _hoisted_1, [
                  _createElementVNode("span", _hoisted_2, _toDisplayString(lookup.title), 1)
                ])
              ]),
              default: _withCtx(() => [
                (_ctx.isLoading)
                  ? (_openBlock(), _createBlock(_component_ProgressSpinner, {
                      key: 0,
                      class: "col-12"
                    }))
                  : (_openBlock(), _createBlock(_component_LookupDataTable, {
                      key: 1,
                      lookup: lookup,
                      params: _ctx.getLookupParameters,
                      class: "p-datatable-sm w-12 text-sm font-semibold bordered-datatable"
                    }, null, 8, ["lookup", "params"]))
              ]),
              _: 2
            }, 1024))
          }), 128))
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["visible", "header"]))
}
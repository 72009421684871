import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "1.5em",
  height: "1.5em",
  viewBox: "0 0 512 512"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "none",
      stroke: "#cd1818",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "32",
      d: "M93.72 183.25C49.49 198.05 16 233.1 16 288c0 66 54 112 120 112h184.37m147.45-22.26C485.24 363.3 496 341.61 496 312c0-59.82-53-85.76-96-88c-8.89-89.54-71-144-144-144c-26.16 0-48.79 6.93-67.6 18.14"
    }, null, -1),
    _createElementVNode("path", {
      fill: "none",
      stroke: "#cd1818",
      "stroke-linecap": "round",
      "stroke-miterlimit": "10",
      "stroke-width": "32",
      d: "M448 448L64 64"
    }, null, -1)
  ])))
}
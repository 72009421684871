import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "grid" }
const _hoisted_2 = { class: "p-inputgroup" }
const _hoisted_3 = ["for"]
const _hoisted_4 = {
  style: {"text-align":"left"},
  class: "print-hidden"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Checkbox = _resolveComponent("Checkbox")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Dialog = _resolveComponent("Dialog")!
  const _component_MultiSelect = _resolveComponent("MultiSelect")!
  const _component_Column = _resolveComponent("Column")!
  const _component_DataTable = _resolveComponent("DataTable")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Dialog, {
      modelValue: _ctx.showSelectionDialog,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.showSelectionDialog) = $event)),
      visible: _ctx.showSelectionDialog,
      header: "Select Columns",
      style: { width: '1000px' },
      modal: true,
      draggable: false,
      closable: false,
      class: "print-hidden"
    }, {
      footer: _withCtx(() => [
        _createVNode(_component_Button, { onClick: _ctx.toggleSelectAll }, {
          default: _withCtx(() => _cache[3] || (_cache[3] = [
            _createTextVNode("Select/Deselect All")
          ])),
          _: 1
        }, 8, ["onClick"]),
        _createVNode(_component_Button, {
          "data-test": "cancelDialogButton",
          ref: "cancelDialogButton",
          label: "Cancel",
          icon: "pi pi-times",
          onClick: _ctx.cancelAction
        }, null, 8, ["onClick"]),
        _createVNode(_component_Button, {
          "data-test": "submitDialogButton",
          onClick: _ctx.saveSelection,
          icon: "pi pi-check",
          label: _ctx.actionName
        }, null, 8, ["onClick", "label"])
      ]),
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sortedAvailableColumns, (item) => {
            return (_openBlock(), _createElementBlock("div", {
              key: item.field,
              class: "col-12 lg:col-3"
            }, [
              _createElementVNode("div", _hoisted_2, [
                _createVNode(_component_Checkbox, {
                  id: 'item' + item.field,
                  modelValue: _ctx.selectedItems,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedItems) = $event)),
                  value: item.field,
                  style: {"margin-right":"1rem"}
                }, null, 8, ["id", "modelValue", "value"]),
                _createElementVNode("label", {
                  for: 'item' + item.field
                }, _toDisplayString(item["header"]), 9, _hoisted_3)
              ])
            ]))
          }), 128))
        ])
      ]),
      _: 1
    }, 8, ["modelValue", "visible"]),
    (_ctx.dataTableVisible)
      ? (_openBlock(), _createBlock(_component_DataTable, {
          key: 0,
          value: _ctx.items,
          reorderableColumns: true,
          dataKey: "so_id",
          ref: "dtExport",
          "export-filename": _ctx.getExportName
        }, {
          header: _withCtx(() => [
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_MultiSelect, {
                placeholder: "Select columns to display",
                optionLabel: "header",
                modelValue: _ctx.availableColumns,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.availableColumns) = $event)),
                options: _ctx.sortedAvailableColumns,
                display: "chip",
                multiple: true,
                class: "w-full md:w-20rem"
              }, null, 8, ["modelValue", "options"]),
              _createVNode(_component_Button, {
                "data-test": "submitDialogButton",
                onClick: _ctx.printDataTable,
                icon: "pi pi-check",
                label: "Print",
                style: {"margin-right":"1em","margin-left":"1em"}
              }, null, 8, ["onClick"]),
              _createVNode(_component_Button, {
                "data-test": "submitDialogButton",
                onClick: _ctx.exportDataTable,
                icon: "pi pi-check",
                label: "Export"
              }, null, 8, ["onClick"])
            ])
          ]),
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.availableColumns, (column) => {
              return (_openBlock(), _createBlock(_component_Column, {
                key: column.field,
                field: column.field,
                header: column.header
              }, null, 8, ["field", "header"]))
            }), 128))
          ]),
          _: 1
        }, 8, ["value", "export-filename"]))
      : _createCommentVNode("", true)
  ], 64))
}
export default class AccountsPayable {
  ap_id?: string;
  vendor?: string;
  vendor_name?: string;
  batch_number?: string;
  po?: string;
  pack_slip?: string;
  invoice?: string;
  inv_date?: string;
  inv_amount?: number;
  ap_acc?: string;
  terms_code?: string;
  due_date?: string;
  status?: string;
  type?: string;
  notes?: string;
  1099: string;
  stamp_date?: string;
  stamp_id?: string;
  rec_date?: string;
  balance?: string;
  accrual_amt?: string;
  co_code?: string;
  check_items?: Array<CheckItems>;
  li_items?: Array<LiItems>;
  accrual_reg_id_items?: Array<AccrualRegIdItems>;
  reg_date_items?: Array<RegDateItems>;
  freight_amt?: string;
  freight_acct?: string;
  sale_tax_amt?: string;
  sale_tax_acct?: string;
  hold?: string;
  hold_date?: string;
  misc_amount_items?: Array<any>;
  freight_items?: Array<any>;
  "1099_box"?: string;
  disc_amount?: string;
  disc_acct?: string;
  disc_pct?: string;
  ap_acct?: string;
  amt_to_pay?: string;
  date_to_pay?: string;

  getId(): string {
    return this.ap_id || "";
  }

  get isObject(): boolean {
    // really don't like this but I need to know if the object has been initialized since loading from draft or edit does not automatically set the object with the defined methods
    return true;
  }
}

export interface CheckItems {
  check: string;
  chk_date: string;
  check_amt: string;
}

export interface LiItems {
  li: string;
  part: string;
  rec_qty: string;
  actual_cost: string;
  std_cost: string;
  um: string;
  orig_cost: string;
  std_lot_size: string;
  cost_group: string;
  description_items: Array<DescriptionItems>;
  amount_items: Array<AmmountItems>;
}

export interface AccrualRegIdItems {
  accrual_reg_id: string;
}

export interface DescriptionItems {
  description: string;
}

export interface RegDateItems {
  reg_date: string;
  reg_invoice_amt: string;
  reg_id: string;
}

export interface AmmountItems {
  amount: string;
  expense_acct: string;
}

export interface Response {
  ap_items: AccountsPayable;
}

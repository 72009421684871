import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "col-12 p-1 m-0" }
const _hoisted_2 = { class: "p-inputgroup mt-2" }
const _hoisted_3 = { class: "col-12 p-1 m-0 field" }
const _hoisted_4 = { class: "p-inputgroup" }
const _hoisted_5 = { class: "col-12 p-1 m-0 field" }
const _hoisted_6 = { class: "p-inputgroup" }
const _hoisted_7 = { class: "col-12 p-1 m-0 field" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Calendar = _resolveComponent("Calendar")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createBlock(_component_Dialog, {
    header: "Post Unposted GL Transactions",
    visible: this.hidden,
    "onUpdate:visible": _cache[3] || (_cache[3] = ($event: any) => ((this.hidden) = $event)),
    onHide: _ctx.hideDialog,
    style: { width: '1000px' },
    modal: true,
    draggable: false
  }, {
    footer: _withCtx(() => [
      _createVNode(_component_Button, {
        ref: "dismiss",
        label: "Cancel",
        icon: "pi pi-times",
        class: "p-button-text",
        onClick: _ctx.hideDialog
      }, null, 8, ["onClick"]),
      _createVNode(_component_Button, {
        ref: "save",
        label: "Save",
        icon: "pi pi-check",
        class: "p-button-text",
        onClick: _ctx.postGL
      }, null, 8, ["onClick"])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _cache[4] || (_cache[4] = _createElementVNode("small", { class: "p-inputgroup-addon font-semibold general-label-color" }, "Start Date", -1)),
          _createVNode(_component_Calendar, {
            modelValue: _ctx.startDate,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.startDate) = $event)),
            showIcon: true,
            "show-button-bar": "",
            dateFormat: "mm-dd-yy",
            inputClass: "text-sm"
          }, null, 8, ["modelValue"])
        ])
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _cache[5] || (_cache[5] = _createElementVNode("small", { class: "p-inputgroup-addon font-semibold general-label-color" }, "End Date", -1)),
          _createVNode(_component_Calendar, {
            modelValue: _ctx.endDate,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.endDate) = $event)),
            showIcon: true,
            "show-button-bar": "",
            dateFormat: "mm-dd-yy"
          }, null, 8, ["modelValue"])
        ])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          _cache[6] || (_cache[6] = _createElementVNode("small", { class: "p-inputgroup-addon font-semibold general-label-color" }, "Journal Types", -1)),
          _createVNode(_component_Dropdown, {
            modelValue: _ctx.journalTypes,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.journalTypes) = $event)),
            options: _ctx.journalTypesOptions,
            placeholder: "Select Journal Type"
          }, null, 8, ["modelValue", "options"])
        ])
      ]),
      _createElementVNode("div", _hoisted_7, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.glTransList, (item, index) => {
          return (_openBlock(), _createElementBlock("div", {
            key: item.value,
            class: "p-inputgroup"
          }, [
            _cache[7] || (_cache[7] = _createElementVNode("small", { class: "p-inputgroup-addon font-semibold general-label-color" }, "GL Transactions", -1)),
            _createVNode(_component_InputText, {
              type: "text",
              id: "GL Transaction",
              modelValue: item.value,
              "onUpdate:modelValue": ($event: any) => ((item.value) = $event),
              class: "text-sm"
            }, null, 8, ["modelValue", "onUpdate:modelValue"]),
            _createVNode(_component_Button, {
              title: "Add GL Transaction",
              icon: "pi pi-plus",
              class: "text-sm font-bold",
              onClick: ($event: any) => (_ctx.addGLTransaction(index))
            }, null, 8, ["onClick"]),
            _createVNode(_component_Button, {
              icon: "pi pi-trash",
              title: "Remove GL Transaction",
              class: "text-sm font-bold",
              onClick: ($event: any) => (_ctx.deleteGLTransaction(index))
            }, null, 8, ["onClick"])
          ]))
        }), 128))
      ])
    ]),
    _: 1
  }, 8, ["visible", "onHide"]))
}
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "formgrid grid" }
const _hoisted_2 = { class: "col-11 field" }
const _hoisted_3 = { class: "col-11 field" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { class: "p-error" }
const _hoisted_6 = { class: "col-11 field" }
const _hoisted_7 = { class: "col-11 field" }
const _hoisted_8 = { class: "col-11 field" }
const _hoisted_9 = { class: "field" }
const _hoisted_10 = { class: "field" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Chips = _resolveComponent("Chips")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Textarea = _resolveComponent("Textarea")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createBlock(_component_Dialog, {
    visible: _ctx.showEmailModal,
    "onUpdate:visible": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.showEmailModal) = $event)),
    header: "Email Attachment",
    style: { width: '1000px' },
    modal: true,
    draggable: false,
    class: "p-fluid",
    onHide: _cache[9] || (_cache[9] = 
      () => {
        this.hideDialog();
        _ctx.$emit('hide', _ctx.showEmailModal);
      }
    )
  }, {
    footer: _withCtx(() => [
      _createVNode(_component_Button, {
        label: "Cancel",
        icon: "pi pi-times",
        class: "p-button-text",
        onClick: _cache[6] || (_cache[6] = 
          () => {
            _ctx.$emit('hide', false);
          }
        )
      }),
      _createVNode(_component_Button, {
        label: "Send",
        icon: "pi pi-send",
        class: "p-button-text",
        loading: _ctx.isSendingEmail,
        onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.email()))
      }, null, 8, ["loading"])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _cache[10] || (_cache[10] = _createElementVNode("div", { class: "col-1 field text-center" }, [
          _createElementVNode("label", { for: "from" }, "From")
        ], -1)),
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_Chips, {
            id: "from",
            max: 1,
            separator: ",",
            addOnBlur: true,
            modelValue: _ctx.from,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.from) = $event))
          }, null, 8, ["modelValue"])
        ]),
        _cache[11] || (_cache[11] = _createElementVNode("div", { class: "col-1 field text-center" }, [
          _createElementVNode("label", { for: "to" }, "To")
        ], -1)),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_Chips, {
            id: "to",
            required: "true",
            modelValue: _ctx.to,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.to) = $event)),
            autofocus: "",
            addOnBlur: true,
            separator: ",",
            class: _normalizeClass({
            'p-invalid': _ctx.isSubmitted && _ctx.v$.to.required.$invalid,
          })
          }, null, 8, ["modelValue", "class"]),
          (_ctx.isSubmitted && _ctx.v$.to.required.$invalid)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createElementVNode("small", _hoisted_5, _toDisplayString(_ctx.v$.to.required.$message), 1)
              ]))
            : _createCommentVNode("", true)
        ]),
        _cache[12] || (_cache[12] = _createElementVNode("div", { class: "col-1 field text-center" }, [
          _createElementVNode("label", { for: "cc" }, "Cc")
        ], -1)),
        _createElementVNode("div", _hoisted_6, [
          _createVNode(_component_Chips, {
            id: "cc",
            modelValue: _ctx.cc,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.cc) = $event)),
            addOnBlur: true,
            separator: ",",
            required: "false"
          }, null, 8, ["modelValue"])
        ]),
        _cache[13] || (_cache[13] = _createElementVNode("div", { class: "col-1 field text-center" }, [
          _createElementVNode("label", { for: "bcc" }, "Bcc")
        ], -1)),
        _createElementVNode("div", _hoisted_7, [
          _createVNode(_component_Chips, {
            id: "bcc",
            modelValue: _ctx.bcc,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.bcc) = $event)),
            addOnBlur: true,
            separator: ",",
            required: "false"
          }, null, 8, ["modelValue"])
        ]),
        _cache[14] || (_cache[14] = _createElementVNode("div", { class: "col-1 field text-center" }, [
          _createElementVNode("label", { for: "subject" }, "Subject")
        ], -1)),
        _createElementVNode("div", _hoisted_8, [
          _createVNode(_component_InputText, {
            id: "subject",
            modelValue: _ctx.subject,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.subject) = $event)),
            required: "false"
          }, null, 8, ["modelValue"])
        ])
      ]),
      _createElementVNode("div", _hoisted_9, [
        _createVNode(_component_Textarea, {
          id: "body",
          modelValue: _ctx.body,
          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.body) = $event)),
          placeholder: "Insert Email Text...",
          required: "false",
          rows: "10",
          cols: "50"
        }, null, 8, ["modelValue"])
      ]),
      _createElementVNode("div", _hoisted_10, [
        _cache[15] || (_cache[15] = _createElementVNode("label", { for: "att" }, "Attachment  ", -1)),
        _createVNode(_component_InputText, {
          id: "att",
          required: "false",
          modelValue: _ctx.modalFile,
          disabled: ""
        }, null, 8, ["modelValue"])
      ])
    ]),
    _: 1
  }, 8, ["visible"]))
}
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  class: "w-full grid m-0 p-0 surface-200 font-bold flex-column-reverse lg:flex-row",
  style: {"min-height":"45px","border-radius":"5px"}
}
const _hoisted_2 = { class: "grid col-12 lg:col-4 p-0 m-0 my-2 lg:my-0" }
const _hoisted_3 = { class: "col-6 flex align-items-center m-0 p-0" }
const _hoisted_4 = { class: "grid col-12 lg:col-6 p-0 m-0 my-2 lg:my-0 flex justify-content-end align-items-center" }
const _hoisted_5 = { class: "col-12 m-0 p-0 flex flex-wrap md:flex-nowrap" }
const _hoisted_6 = {
  class: "p-inputgroup m-0 p-0 pr-1 my-2 lg:my-0 pl-1 lg:pl-0",
  style: {"width":"revert"}
}
const _hoisted_7 = {
  class: "p-inputgroup m-0 p-0 pr-1 my-2 lg:my-0 pl-1 md:pl-0",
  style: {"width":"revert"}
}
const _hoisted_8 = { class: "col-12 lg:col-2 my-2 lg:my-0 m-0 p-0 flex justify-content-end align-items-center" }
const _hoisted_9 = { class: "overflow-auto flex w-full" }
const _hoisted_10 = { class: "gantt-row-container" }
const _hoisted_11 = { class: "flex align-items-center" }
const _hoisted_12 = { class: "font-semibold" }
const _hoisted_13 = { class: "overflow-auto w-full" }
const _hoisted_14 = ["draggable"]
const _hoisted_15 = {
  key: 0,
  class: "pi pi-pencil mr-2 p-1",
  style: { color: '#BBB' }
}
const _hoisted_16 = {
  key: 0,
  class: "flex justify-content-center gap-2 p-3"
}
const _hoisted_17 = {
  class: "ml-2",
  style: { fontSize: '10px', fontWeight: '500' }
}
const _hoisted_18 = {
  key: 1,
  class: "flex justify-content-center gap-2 p-3"
}
const _hoisted_19 = { class: "flex justify-content-center gap-2 p-3" }
const _hoisted_20 = {
  class: "ml-2",
  style: { fontSize: '10px', fontWeight: '500' }
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_Calendar = _resolveComponent("Calendar")!
  const _component_g_gantt_row = _resolveComponent("g-gantt-row")!
  const _component_g_gantt_chart = _resolveComponent("g-gantt-chart")!
  const _component_BarOverlayForm = _resolveComponent("BarOverlayForm")!
  const _component_OverlayPanel = _resolveComponent("OverlayPanel")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _cache[4] || (_cache[4] = _createElementVNode("span", { class: "col-6 flex align-items-center m-0 p-0 pl-3" }, "Line", -1)),
        _createElementVNode("span", _hoisted_3, "Time Axis " + _toDisplayString(_ctx.timeAxis), 1)
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _cache[5] || (_cache[5] = _createElementVNode("small", {
              class: "p-inputgroup-addon font-semibold general-label-color",
              style: { width: '60px', minWidth: '60px' }
            }, " Start ", -1)),
            _createVNode(_component_Calendar, {
              ref: "fromDateCalendar",
              modelValue: _ctx.getSchedulingFromDate,
              "onUpdate:modelValue": [
                _cache[1] || (_cache[1] = ($event: any) => ((_ctx.getSchedulingFromDate) = $event)),
                _ctx.setSchedulingFromDate
              ],
              dateFormat: "mm-dd-yy",
              inputClass: "text-sm",
              showTime: "",
              hourFormat: "24",
              showIcon: true,
              "input-style": { maxWidth: '130px' },
              showOnFocus: false
            }, {
              footer: _withCtx(() => [
                _createVNode(_component_Button, {
                  icon: "pi pi-search",
                  label: "Search",
                  class: "my-3 w-full",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => {
                    _ctx.handleClickScheduleSearch();
                    (_ctx.$refs.fromDateCalendar as any).overlayVisible = false;
                  }),
                  loading: _ctx.loadingScheduleSearch
                }, null, 8, ["loading"])
              ]),
              _: 1
            }, 8, ["modelValue", "onUpdate:modelValue"])
          ]),
          _createElementVNode("div", _hoisted_7, [
            _cache[6] || (_cache[6] = _createElementVNode("small", {
              class: "p-inputgroup-addon font-semibold general-label-color",
              style: { width: '60px', minWidth: '60px' }
            }, " End ", -1)),
            _createVNode(_component_Calendar, {
              ref: "toDateCalendar",
              modelValue: _ctx.getSchedulingToDate,
              "onUpdate:modelValue": [
                _cache[3] || (_cache[3] = ($event: any) => ((_ctx.getSchedulingToDate) = $event)),
                _ctx.setSchedulingToDate
              ],
              dateFormat: "mm-dd-yy",
              inputClass: "text-sm",
              showTime: "",
              hourFormat: "24",
              showIcon: true,
              "input-style": { maxWidth: '130px' },
              showOnFocus: false
            }, {
              footer: _withCtx(() => [
                _createVNode(_component_Button, {
                  icon: "pi pi-search",
                  label: "Search",
                  class: "my-3 w-full",
                  onClick: _cache[2] || (_cache[2] = ($event: any) => {
                    _ctx.handleClickScheduleSearch();
                    (_ctx.$refs.toDateCalendar as any).overlayVisible = false;
                  }),
                  loading: _ctx.loadingScheduleSearch
                }, null, 8, ["loading"])
              ]),
              _: 1
            }, 8, ["modelValue", "onUpdate:modelValue"])
          ]),
          _createVNode(_component_Button, {
            icon: "pi pi-search",
            class: "m-0 p-0 pr-1 my-2 lg:my-0 pl-1 lg:pl-0",
            onClick: _ctx.handleClickScheduleSearch,
            loading: _ctx.loadingScheduleSearch
          }, null, 8, ["onClick", "loading"])
        ])
      ]),
      _createElementVNode("div", _hoisted_8, [
        _withDirectives(_createVNode(_component_Button, {
          label: "Reset",
          class: "mr-1",
          onClick: _ctx.clearWorkOrdersInProgress,
          badge: _ctx.getSelectedWorkOrders.length.toString()
        }, null, 8, ["onClick", "badge"]), [
          [_vShow, _ctx.getSelectedWorkOrders.length]
        ]),
        (_ctx.hasChangeAccess)
          ? (_openBlock(), _createBlock(_component_Button, {
              key: 0,
              label: "Save",
              class: "mr-3",
              onClick: _ctx.saveChartData,
              loading: _ctx.loadingScheduleSave,
              disabled: _ctx.disableSaveButton
            }, null, 8, ["onClick", "loading", "disabled"]))
          : _createCommentVNode("", true)
      ])
    ]),
    _createElementVNode("div", _hoisted_9, [
      _createElementVNode("div", _hoisted_10, [
        _cache[7] || (_cache[7] = _createElementVNode("div", {
          class: "",
          style: {"height":"8vh","min-height":"75px"}
        }, null, -1)),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.chartData, (row) => {
          return (_openBlock(), _createElementBlock("div", {
            key: row.work_center,
            class: "gantt-row-data pr-3"
          }, [
            _createElementVNode("div", _hoisted_11, [
              _createElementVNode("div", {
                style: _normalizeStyle(`height: 40px;  width: 8px; min-width: 8px; border-radius: 2px;background-color: ${row.rowColor}`),
                class: "mr-1 mb-1"
              }, null, 4),
              _createElementVNode("span", _hoisted_12, _toDisplayString(row.workCenter.description), 1)
            ])
          ]))
        }), 128))
      ]),
      _createElementVNode("div", _hoisted_13, [
        _createVNode(_component_g_gantt_chart, {
          "chart-start": _ctx.formatDateSelectedToChart(_ctx.getSchedulingFromDate),
          "chart-end": _ctx.formatDateSelectedToChart(_ctx.getSchedulingToDate),
          precision: "hour",
          "bar-start": "startDate",
          "bar-end": "endDate",
          style: _normalizeStyle({ width: _ctx.tableSize, minWidth: '34rem' }),
          "push-on-overlap": true,
          onMousedownBar: _ctx.onMouseDownBar,
          onClickBar: _ctx.onMouseUpBar,
          onDragendBar: _ctx.dragEndBar,
          grid: true,
          "row-height": _ctx.rowHeight
        }, {
          "bar-tooltip": _withCtx(({ bar }) => [
            _createElementVNode("div", {
              class: _normalizeClass(["w-full flex justify-content-start align-items-center", { 'justify-content-center': !bar.status }])
            }, [
              _createElementVNode("span", null, "(" + _toDisplayString(_ctx.formatDateTime(bar.startDate)) + " - " + _toDisplayString(_ctx.formatDateTime(bar.endDate)) + ") " + _toDisplayString(bar.ganttBarConfig.label), 1)
            ], 2)
          ]),
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.chartData, (row) => {
              return (_openBlock(), _createBlock(_component_g_gantt_row, {
                key: row.workCenter.work_center,
                label: row.workCenter.description,
                bars: row.bars,
                onDrop: ($event: any) => (_ctx.addItemToChart($event, row.workCenter)),
                "row-height": 80
              }, {
                "bar-label": _withCtx((data) => [
                  _createElementVNode("div", {
                    class: _normalizeClass(["w-full flex justify-content-start align-items-center", { 'justify-content-center': data.bar.isGeneric }]),
                    draggable: _ctx.hasChangeAccess
                  }, [
                    (data.bar.modified)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_15))
                      : _createCommentVNode("", true),
                    _createElementVNode("span", null, _toDisplayString(data.bar.ganttBarConfig.label), 1)
                  ], 10, _hoisted_14)
                ]),
                _: 2
              }, 1032, ["label", "bars", "onDrop"]))
            }), 128))
          ]),
          _: 1
        }, 8, ["chart-start", "chart-end", "style", "onMousedownBar", "onClickBar", "onDragendBar", "row-height"])
      ])
    ]),
    (_ctx.getGanttStatusItems[0].gantt_hex)
      ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getGanttStatusItems, (hex) => {
            return (_openBlock(), _createElementBlock("div", {
              key: hex.gantt_status,
              class: "dot-container",
              style: {
          marginRight: '15px',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          textAlign: 'left',
        }
            }, [
              _createElementVNode("div", {
                class: "dot",
                style: _normalizeStyle({
            backgroundColor: hex.gantt_hex,
          })
              }, null, 4),
              _createElementVNode("span", _hoisted_17, _toDisplayString(_ctx.getGanttStatusLabel(hex.gantt_status)), 1)
            ]))
          }), 128))
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_18, [
          _createElementVNode("div", _hoisted_19, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getGanttStatusItems, (hex, index) => {
              return (_openBlock(), _createElementBlock("div", {
                key: hex.gantt_status,
                class: "dot-container",
                style: {
            marginRight: '15px',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            textAlign: 'left',
          }
              }, [
                _createElementVNode("div", {
                  class: "dot",
                  style: _normalizeStyle({
              backgroundColor: Object.values(this.chartData)[
                index % Object.keys(this.chartData).length
              ]?.rowColor,
            })
                }, null, 4),
                _createElementVNode("span", _hoisted_20, _toDisplayString(_ctx.getGanttStatusLabel(hex.gantt_status)), 1)
              ]))
            }), 128))
          ])
        ])),
    _createVNode(_component_OverlayPanel, { ref: "barpopover" }, {
      default: _withCtx(() => [
        _createVNode(_component_BarOverlayForm, {
          barSelected: _ctx.barSelected.bar,
          isGenericBar: _ctx.barSelected.bar.isGeneric,
          workOrder: _ctx.barSelected.workOrder,
          workCenterList: _ctx.workCenterList,
          onSaveBarData: _ctx.onSaveBarData,
          onRemoveBar: _ctx.onRemoveBar,
          onAddOperation: _ctx.onAddOperation,
          onUndoChanges: _ctx.onUndoChanges
        }, null, 8, ["barSelected", "isGenericBar", "workOrder", "workCenterList", "onSaveBarData", "onRemoveBar", "onAddOperation", "onUndoChanges"])
      ]),
      _: 1
    }, 512)
  ]))
}

import { defineComponent } from "vue";
import { mapGetters } from "vuex";
import { mapActions, mapState } from "pinia";

import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import Button from "primevue/button";
import TopKPICards from "@/components/Inventory/component/TopKPICards.vue";
import { AccessRights } from "@/types/accessControl/AbstractAccessGetter";

import { useInventoryStore } from "@/store/ui/inventoryModule";

import { useInventoryControlStore } from "@/store/data/controls/inv";

export default defineComponent({
  components: {
    TopKPICards,
    TabView,
    TabPanel,
    Button,
  },
  computed: {
    ...mapGetters({
      getAccess: "session/getAccess",
      getAccessRights: "session/getAccessRights",
      getDefaultCodeItem: "coControl/getDefaultCodeItem",
    }),
    ...mapState(useInventoryStore, {
      getActiveTab: "getActiveTab",
      getInventory: "getInventory",
    }),
    filteredInventoryItems(): Record<
      string,
      { label: string; to: string; statInfo: any }
    > {
      let filteredItems: Record<
        string,
        { label: string; to: string; statInfo: any }
      > = {};
      for (const item in this.inventoryItems) {
        if (this.getAccess(this.inventoryItems[item].to)) {
          filteredItems = {
            ...filteredItems,
            [item]: this.inventoryItems[item],
          };
        }
      }
      return filteredItems;
    },
  },
  async created() {
    await this.fetchInventoryControl();
    this.updateRoute();
  },
  mounted() {
    window.addEventListener("resize", this.handleWindowResize);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.handleWindowResize);
  },
  data: () => ({
    active: 0,
    activeInnerTab: 0,
    windowWidth: window.innerWidth,
    inventoryItems: {
      parts: {
        label: "Parts",
        to: "/inventory/parts",
      },
      purchaseOrders: {
        label: "Purchase Orders",
        to: "/inventory/purchase-orders",
      },
    } as any,
  }),
  methods: {
    ...mapActions(useInventoryStore, [
      "removeInventoryById",
      "setActiveTab",
      "setActiveTabById",
    ]),
    ...mapActions(useInventoryControlStore, ["fetchInventoryControl"]),
    handleWindowResize() {
      this.windowWidth = window.innerWidth;
    },
    updateRoute() {
      const inventoryItem = this.getInventory.find(
        (item) => item.getRoute === this.$route.path,
      );

      if (inventoryItem) {
        this.setActiveTabById(inventoryItem.id);
      }
    },
    handleRemoveInventory(id: string) {
      this.removeInventoryById(id);
      if (this.getInventory.length > 0) {
        this.$router.push(this.getInventory[0].getRoute);
      } else {
        this.setActiveTab(0);
        this.$router.push("/inventory");
      }
    },
    handleMainTabChange(e: any) {
      this.setActiveTab(e.index !== undefined ? e.index : 0);
    },
  },
  provide() {
    return {
      getAccessRights: this.getAccessRights("/inventory") as AccessRights,
      companyCode: this.getDefaultCodeItem,
    };
  },
  watch: {
    $route() {
      this.updateRoute();
    },
  },
});

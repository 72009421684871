import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { style: {"display":"flex"} }
const _hoisted_4 = {
  key: 2,
  style: { paddingLeft: '50px !important', listStyleType: 'none' }
}
const _hoisted_5 = { class: "font-medium text-bluegray-600" }
const _hoisted_6 = {
  key: 3,
  class: "p-inputgroup mt-2"
}
const _hoisted_7 = { class: "p-inputgroup-addon font-semibold general-label-color" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Calendar = _resolveComponent("Calendar")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createBlock(_component_Dialog, {
    breakpoints: { '960px': '75vw', '640px': '90vw' },
    style: { width: '450px' },
    modal: "",
    draggable: false,
    visible: _ctx.showDialog,
    header: _ctx.header,
    closable: false
  }, {
    footer: _withCtx(() => [
      (_ctx.primaryButton)
        ? (_openBlock(), _createBlock(_component_Button, {
            key: 0,
            label: _ctx.primaryButton,
            onClick: _ctx.primaryButtonClick,
            autofocus: ""
          }, null, 8, ["label", "onClick"]))
        : _createCommentVNode("", true),
      (_ctx.cancelButton)
        ? (_openBlock(), _createBlock(_component_Button, {
            key: 1,
            label: _ctx.cancelButton,
            onClick: _ctx.cancelButtonClick
          }, null, 8, ["label", "onClick"]))
        : _createCommentVNode("", true)
    ]),
    default: _withCtx(() => [
      (_ctx.messageLines)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.messageLines, (msg, i) => {
              return (_openBlock(), _createElementBlock("p", {
                style: {"display":"flex","padding":"0px","margin":"0px"},
                key: i
              }, [
                (i === 0 && _ctx.icon)
                  ? (_openBlock(), _createElementBlock("i", {
                      key: 0,
                      class: _normalizeClass(_ctx.iconClass),
                      style: {"font-size":"2rem","margin-bottom":"10px"}
                    }, null, 2))
                  : _createCommentVNode("", true),
                _createElementVNode("span", {
                  style: _normalizeStyle({
            fontWeight:
              i === 0 || msg.includes('has been') || msg.includes('have been')
                ? 'bold'
                : 'normal',
          })
                }, _toDisplayString(msg), 5)
              ]))
            }), 128))
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createElementVNode("p", _hoisted_3, [
              (_ctx.icon)
                ? (_openBlock(), _createElementBlock("i", {
                    key: 0,
                    class: _normalizeClass(_ctx.iconClass),
                    style: {"font-size":"2rem"}
                  }, null, 2))
                : _createCommentVNode("", true),
              _createTextVNode(" " + _toDisplayString(_ctx.message), 1)
            ])
          ])),
      (_ctx.messageItems)
        ? (_openBlock(), _createElementBlock("ul", _hoisted_4, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.messageItems, (item, i) => {
              return (_openBlock(), _createElementBlock("li", { key: i }, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Object.keys(item), (key) => {
                  return (_openBlock(), _createElementBlock("span", {
                    key: key + i
                  }, [
                    _createElementVNode("span", _hoisted_5, _toDisplayString(key) + ": " + _toDisplayString(item[key]), 1),
                    _cache[3] || (_cache[3] = _createElementVNode("br", null, null, -1))
                  ]))
                }), 128)),
                _cache[4] || (_cache[4] = _createElementVNode("br", null, null, -1))
              ]))
            }), 128))
          ]))
        : _createCommentVNode("", true),
      (_ctx.inputType)
        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
            _createElementVNode("small", _hoisted_7, _toDisplayString(_ctx.inputLabel), 1),
            (_ctx.isInputDropdown)
              ? (_openBlock(), _createBlock(_component_Dropdown, {
                  key: 0,
                  modelValue: _ctx.input,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.input) = $event)),
                  options: _ctx.options,
                  optionLabel: _ctx.optionLabel,
                  optionValue: _ctx.optionValue
                }, null, 8, ["modelValue", "options", "optionLabel", "optionValue"]))
              : _createCommentVNode("", true),
            (_ctx.isInputText)
              ? (_openBlock(), _createBlock(_component_InputText, {
                  key: 1,
                  modelValue: _ctx.input,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.input) = $event))
                }, null, 8, ["modelValue"]))
              : _createCommentVNode("", true),
            (_ctx.isInputDate)
              ? (_openBlock(), _createBlock(_component_Calendar, {
                  key: 2,
                  modelValue: _ctx.input,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.input) = $event)),
                  showIcon: ""
                }, null, 8, ["modelValue"]))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["visible", "header"]))
}
import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "part-number blue-text block" }
const _hoisted_2 = { class: "part-manufactured-description block" }
const _hoisted_3 = { class: "flex p-0 m-0 justify-content-center" }
const _hoisted_4 = { class: "p-0" }
const _hoisted_5 = { style: {"margin":"1px"} }
const _hoisted_6 = { style: {"margin":"1px"} }
const _hoisted_7 = { style: {"margin":"1px"} }
const _hoisted_8 = { key: 0 }
const _hoisted_9 = { style: {"margin":"1px"} }
const _hoisted_10 = { style: {"margin":"1px"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Column = _resolveComponent("Column")!
  const _component_InputNumber = _resolveComponent("InputNumber")!
  const _component_Button = _resolveComponent("Button")!
  const _component_DataTable = _resolveComponent("DataTable")!

  return (_openBlock(), _createBlock(_component_DataTable, {
    expandedRows: _ctx.expandedRows,
    "onUpdate:expandedRows": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.expandedRows) = $event)),
    scrollable: "",
    scrollHeight: "50vh",
    class: "p-datatable-sm",
    value: _ctx.openRacks,
    dataKey: "part_id",
    filterDisplay: "row"
  }, {
    expansion: _withCtx((slotProps) => [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("h5", _hoisted_5, "Account: " + _toDisplayString(slotProps.data.account), 1),
        _createElementVNode("h5", _hoisted_6, "Base Rate: " + _toDisplayString(slotProps.data.base_rate), 1),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(slotProps.data.rack_rate_items, (rack_rate, i) => {
          return (_openBlock(), _createElementBlock("div", { key: i }, [
            _createElementVNode("h5", _hoisted_7, [
              _createTextVNode(" Rack Rate " + _toDisplayString(i + 1) + " - " + _toDisplayString(rack_rate.rack_rate) + " cents/rack ", 1),
              (rack_rate.rack_rate_num)
                ? (_openBlock(), _createElementBlock("span", _hoisted_8, "up to - " + _toDisplayString(rack_rate.rack_rate_num) + " Rack(s)", 1))
                : _createCommentVNode("", true)
            ])
          ]))
        }), 128)),
        _createElementVNode("h5", _hoisted_9, " Billing Desc: " + _toDisplayString(slotProps.data.billing_desc), 1),
        _createElementVNode("h5", _hoisted_10, "Number Free: " + _toDisplayString(slotProps.data.num_free), 1),
        _createVNode(_component_DataTable, {
          value: slotProps.data.so_date_items,
          sortField: "so_date",
          sortOrder: -1,
          paginator: "",
          rows: 5
        }, {
          default: _withCtx(() => [
            _createVNode(_component_Column, {
              field: "so_date",
              header: "Date",
              sortable: ""
            }, {
              body: _withCtx((slotProps) => [
                _createTextVNode(_toDisplayString(_ctx.formatDate(slotProps.data.so_date)), 1)
              ]),
              _: 2
            }, 1024),
            _createVNode(_component_Column, {
              field: "so_id",
              header: "SO ID"
            }),
            _createVNode(_component_Column, {
              field: "so_racks",
              header: "Count",
              sortable: ""
            }, {
              body: _withCtx((slotProps) => [
                _createTextVNode(_toDisplayString(_ctx.formatNumber(slotProps.data.so_racks)), 1)
              ]),
              _: 2
            }, 1024)
          ]),
          _: 2
        }, 1032, ["value"])
      ])
    ]),
    empty: _withCtx(() => _cache[1] || (_cache[1] = [
      _createElementVNode("div", null, "No records found", -1)
    ])),
    default: _withCtx(() => [
      _createVNode(_component_Column, {
        expander: "",
        style: {"width":"5rem"}
      }),
      _createVNode(_component_Column, {
        field: "part_number",
        class: "flex flex-column align-items-baseline kit-description-wrapper"
      }, {
        body: _withCtx((slotProps) => [
          _createElementVNode("span", _hoisted_1, _toDisplayString(slotProps.data.desc || slotProps.data.part_id), 1),
          _createElementVNode("span", _hoisted_2, "Part #: " + _toDisplayString(slotProps.data.part_id), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_Column, {
        class: "blue-text font-bold code-price-wrapper",
        field: "price"
      }, {
        body: _withCtx((slotProps) => [
          _createElementVNode("div", null, [
            _createElementVNode("span", null, "Last Service: " + _toDisplayString(_ctx.getMostRecentDate(slotProps.data)), 1)
          ]),
          _createElementVNode("div", null, [
            _createElementVNode("span", null, "Last Count: " + _toDisplayString(_ctx.getMostRecentCount(slotProps.data)), 1)
          ])
        ]),
        _: 1
      }),
      _createVNode(_component_Column, {
        field: "quantity",
        class: "quantity-wrapper"
      }, {
        body: _withCtx((slotProps) => [
          _createElementVNode("div", null, [
            _createVNode(_component_InputNumber, {
              class: "quantity-input",
              style: {"width":"100%","min-width":"80px"},
              modelValue: slotProps.data.current_count,
              "onUpdate:modelValue": ($event: any) => ((slotProps.data.current_count) = $event),
              min: 1
            }, null, 8, ["modelValue", "onUpdate:modelValue"])
          ])
        ]),
        _: 1
      }),
      _createVNode(_component_Column, { class: "cart-wrapper" }, {
        body: _withCtx((slotProps) => [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_Button, {
              class: "add-to-cart-btn pl-2 pr-2 lg:pl-3 lg:pr-3",
              label: _ctx.buttonLabel(slotProps.data),
              onClick: ($event: any) => (_ctx.addPart(slotProps.data)),
              loading: slotProps.data.loading
            }, null, 8, ["label", "onClick", "loading"])
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["expandedRows", "value"]))
}
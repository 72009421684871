import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withKeys as _withKeys, createVNode as _createVNode, normalizeClass as _normalizeClass, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "grid flex justify-content-center mt-2 mx-1" }
const _hoisted_2 = { class: "col-12" }
const _hoisted_3 = { class: "grid align-items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputText = _resolveComponent("InputText")!
  const _component_TextArea = _resolveComponent("TextArea")!
  const _component_Button = _resolveComponent("Button")!
  const _component_MessageBox = _resolveComponent("MessageBox")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.rowClass)
          }, [
            _cache[23] || (_cache[23] = _createElementVNode("span", { class: "p-inputgroup-addon" }, " Part Number ", -1)),
            _createVNode(_component_InputText, {
              ref: "part",
              class: "scan-input-text",
              modelValue: _ctx.payload.part_no,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.payload.part_no) = $event)),
              onFocus: _cache[1] || (_cache[1] = ($event: any) => ($event.target.select())),
              onKeypress: _withKeys(_ctx.handlePartNumber, ["enter"]),
              onKeydown: _withKeys(_ctx.handlePartNumber, ["down"])
            }, null, 8, ["modelValue", "onKeypress", "onKeydown"])
          ], 2),
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.rowClass)
          }, [
            _cache[24] || (_cache[24] = _createElementVNode("span", { class: "p-inputgroup-addon" }, " Part Desc ", -1)),
            _createVNode(_component_TextArea, {
              ref: "notes",
              class: "scan-input-text",
              modelValue: _ctx.part_desc,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.part_desc) = $event)),
              placeHolder: "Part Description",
              readonly: ""
            }, null, 8, ["modelValue"])
          ], 2),
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.rowClass)
          }, [
            _cache[25] || (_cache[25] = _createElementVNode("span", { class: "p-inputgroup-addon" }, " UM ", -1)),
            _createVNode(_component_InputText, {
              class: "scan-input-text",
              modelValue: _ctx.um,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.um) = $event)),
              readonly: "",
              placeHolder: "Unit of Measure"
            }, null, 8, ["modelValue"])
          ], 2),
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.rowClass)
          }, [
            _cache[26] || (_cache[26] = _createElementVNode("span", { class: "p-inputgroup-addon" }, " Quantity ", -1)),
            _createVNode(_component_InputText, {
              ref: "qty",
              class: "scan-input-text",
              type: "number",
              pattern: "[0-9]*",
              inputmode: "decimal",
              modelValue: _ctx.payload.qty,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.payload.qty) = $event)),
              onFocus: _cache[5] || (_cache[5] = ($event: any) => ($event.target.select())),
              onKeypress: _cache[6] || (_cache[6] = _withKeys(($event: any) => (_ctx.focusInput('from_loc')), ["enter"])),
              onKeydown: _cache[7] || (_cache[7] = _withKeys(($event: any) => (_ctx.focusInput('from_loc')), ["down"]))
            }, null, 8, ["modelValue"])
          ], 2),
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.rowClass)
          }, [
            _cache[27] || (_cache[27] = _createElementVNode("span", { class: "p-inputgroup-addon" }, " From Location ", -1)),
            _createVNode(_component_InputText, {
              ref: "from_loc",
              class: "scan-input-text",
              modelValue: _ctx.payload.from_loc,
              "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.payload.from_loc) = $event)),
              onFocus: _cache[9] || (_cache[9] = ($event: any) => ($event.target.select())),
              onKeypress: _withKeys(_ctx.handleFromLocation, ["enter"]),
              onKeydown: _withKeys(_ctx.handleFromLocation, ["down"])
            }, null, 8, ["modelValue", "onKeypress", "onKeydown"])
          ], 2),
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.rowClass)
          }, [
            _cache[28] || (_cache[28] = _createElementVNode("span", { class: "p-inputgroup-addon" }, " From Lot # ", -1)),
            _createVNode(_component_InputText, {
              ref: "from_lot",
              class: "scan-input-text",
              modelValue: _ctx.payload.from_lot,
              "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.payload.from_lot) = $event)),
              onFocus: _cache[11] || (_cache[11] = ($event: any) => ($event.target.select())),
              onKeypress: _withKeys(_ctx.handleFromLot, ["enter"]),
              onKeydown: _withKeys(_ctx.handleFromLot, ["down"])
            }, null, 8, ["modelValue", "onKeypress", "onKeydown"])
          ], 2),
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.rowClass)
          }, [
            _cache[29] || (_cache[29] = _createElementVNode("span", { class: "p-inputgroup-addon" }, " From Bin # ", -1)),
            _createVNode(_component_InputText, {
              ref: "from_bin",
              class: "scan-input-text",
              modelValue: _ctx.payload.from_bin,
              "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.payload.from_bin) = $event)),
              onFocus: _cache[13] || (_cache[13] = ($event: any) => ($event.target.select())),
              onKeypress: _withKeys(_ctx.handleFromBin, ["enter"]),
              onKeydown: _withKeys(_ctx.handleFromBin, ["down"])
            }, null, 8, ["modelValue", "onKeypress", "onKeydown"])
          ], 2),
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.rowClass)
          }, [
            _cache[30] || (_cache[30] = _createElementVNode("span", { class: "p-inputgroup-addon" }, " To Location ", -1)),
            _createVNode(_component_InputText, {
              ref: "to_loc",
              class: "scan-input-text",
              modelValue: _ctx.payload.to_loc,
              "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.payload.to_loc) = $event)),
              onFocus: _cache[15] || (_cache[15] = ($event: any) => ($event.target.select())),
              onKeypress: _withKeys(_ctx.handleToLocation, ["enter"]),
              onKeydown: _withKeys(_ctx.handleToLocation, ["down"])
            }, null, 8, ["modelValue", "onKeypress", "onKeydown"])
          ], 2),
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.rowClass)
          }, [
            _cache[31] || (_cache[31] = _createElementVNode("span", { class: "p-inputgroup-addon" }, " To Lot # ", -1)),
            _createVNode(_component_InputText, {
              ref: "to_lot",
              class: "scan-input-text",
              modelValue: _ctx.payload.to_lot,
              "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.payload.to_lot) = $event)),
              onFocus: _cache[17] || (_cache[17] = ($event: any) => ($event.target.select())),
              onKeypress: _withKeys(_ctx.handleToLot, ["enter"]),
              onKeydown: _withKeys(_ctx.handleToLot, ["down"])
            }, null, 8, ["modelValue", "onKeypress", "onKeydown"])
          ], 2),
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.rowClass)
          }, [
            _cache[32] || (_cache[32] = _createElementVNode("span", { class: "p-inputgroup-addon" }, " To Bin # ", -1)),
            _createVNode(_component_InputText, {
              ref: "to_bin",
              class: "scan-input-text",
              modelValue: _ctx.payload.to_bin,
              "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.payload.to_bin) = $event)),
              onFocus: _cache[19] || (_cache[19] = ($event: any) => ($event.target.select())),
              onKeypress: _cache[20] || (_cache[20] = _withKeys(($event: any) => (_ctx.focusInput('notes')), ["enter"])),
              onKeydown: _cache[21] || (_cache[21] = _withKeys(($event: any) => (_ctx.focusInput('notes')), ["down"]))
            }, null, 8, ["modelValue"])
          ], 2),
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.rowClass)
          }, [
            _cache[33] || (_cache[33] = _createElementVNode("span", { class: "p-inputgroup-addon" }, " Notes ", -1)),
            _createVNode(_component_TextArea, {
              ref: "notes",
              class: "scan-input-text",
              modelValue: _ctx.payload.notes,
              "onUpdate:modelValue": _cache[22] || (_cache[22] = ($event: any) => ((_ctx.payload.notes) = $event))
            }, null, 8, ["modelValue"])
          ], 2),
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.rowClass)
          }, [
            _createVNode(_component_Button, {
              icon: "pi pi-check",
              label: "Submit",
              loading: _ctx.loadSubmit,
              "data-test": "submitTransactionButton",
              onClick: _ctx.submitTransfer,
              class: "scan-input-button"
            }, null, 8, ["loading", "onClick"])
          ], 2)
        ])
      ])
    ]),
    _createVNode(_component_MessageBox, {
      showDialog: _ctx.showErrorDialog,
      icon: "INFO",
      header: "Error",
      message: _ctx.errorMessage,
      primaryButton: "OK",
      onPrimaryButtonClick: _ctx.clickConfirmErrorDialog
    }, null, 8, ["showDialog", "message", "onPrimaryButtonClick"])
  ], 64))
}
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "col-12 mt-2" }
const _hoisted_2 = { class: "flex col-12 justify-content-between align-items-center" }
const _hoisted_3 = {
  key: 0,
  class: "p-0"
}
const _hoisted_4 = {
  key: 1,
  class: "col-12 mr-1"
}
const _hoisted_5 = { key: 1 }
const _hoisted_6 = { class: "flex p-0 m-0 justify-content-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ConfirmChangesDialog = _resolveComponent("ConfirmChangesDialog")!
  const _component_Button = _resolveComponent("Button")!
  const _component_LoadingSpinner = _resolveComponent("LoadingSpinner")!
  const _component_Column = _resolveComponent("Column")!
  const _component_Datatable = _resolveComponent("Datatable")!
  const _component_AddAttachmentDialog = _resolveComponent("AddAttachmentDialog")!
  const _component_EmailAttachmentDialog = _resolveComponent("EmailAttachmentDialog")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ConfirmChangesDialog, { ref: "confirmChanges" }, null, 512),
    _createElementVNode("div", null, [
      _createElementVNode("div", _hoisted_2, [
        (_ctx.hostFile !== 'CUST' && _ctx.showTotalAttachments)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _cache[6] || (_cache[6] = _createElementVNode("i", { class: "pi pi-folder" }, null, -1)),
              (!this.loadingAttachments)
                ? (_openBlock(), _createElementBlock("span", {
                    key: 0,
                    class: "col-12 mr-1 link",
                    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.viewAttachmentsLog && _ctx.viewAttachmentsLog(...args)))
                  }, "Total Attachments: " + _toDisplayString(_ctx.totalAttachments), 1))
                : _createCommentVNode("", true),
              (this.loadingAttachments)
                ? (_openBlock(), _createElementBlock("span", _hoisted_4, _cache[5] || (_cache[5] = [
                    _createTextVNode("Total Attachments: "),
                    _createElementVNode("span", {
                      class: "pi pi-spin pi-spinner",
                      style: {"font-size":"1rem"}
                    }, null, -1)
                  ])))
                : _createCommentVNode("", true)
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_5, " ")),
        _createElementVNode("div", null, [
          _createVNode(_component_Button, {
            label: "Add",
            icon: "pi pi-plus",
            class: "mb-2 h-2rem text-sm",
            id: "newAttachment",
            onClick: _cache[1] || (_cache[1] = 
              () => {
                _ctx.showAddTicketAttachmentDialog = true;
              }
            )
          })
        ])
      ])
    ]),
    _createVNode(_component_LoadingSpinner, {
      loading: _ctx.loadingAttachments && !_ctx.isAttachmentLogVisible
    }, null, 8, ["loading"]),
    (!_ctx.loadingAttachments)
      ? (_openBlock(), _createBlock(_component_Datatable, {
          key: 0,
          id: "attachmentsTable",
          value: _ctx.attachmentRecords,
          hidden: _ctx.isAttachmentLogVisible,
          paginator: true,
          rows: _ctx.rows,
          first: _ctx.first,
          onPage: _ctx.handlePage,
          paginatorTemplate: "FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown",
          currentPageReportTemplate: "Showing {first} to {last} of {totalRecords} entries",
          rowsPerPageOptions: [5, 10, 25, 50],
          class: "p-datatable-sm bordered-table",
          responsiveLayout: "stack",
          breakpoint: "991px"
        }, {
          empty: _withCtx(() => _cache[7] || (_cache[7] = [
            _createTextVNode(" No attachments to display. ")
          ])),
          default: _withCtx(() => [
            _createVNode(_component_Column, {
              field: "file_name",
              header: "Document",
              bodyClass: "text-left"
            }),
            _createVNode(_component_Column, {
              field: "created_by",
              header: "Created By",
              bodyClass: "text-left"
            }),
            _createVNode(_component_Column, {
              field: "date_created",
              header: "Date",
              bodyClass: "text-center"
            }, {
              body: _withCtx((slotProps) => [
                _createTextVNode(_toDisplayString(_ctx.getDate(slotProps.data)), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_Column, {
              field: "time_created",
              header: "Time",
              bodyClass: "text-center"
            }),
            _createVNode(_component_Column, {
              header: "Description",
              style: {"line-break":"anywhere","word-break":"keep-all"},
              bodyClass: "text-left"
            }, {
              body: _withCtx((slotProps) => [
                _createTextVNode(_toDisplayString(_ctx.getDescription(slotProps.data.id)), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_Column, {
              header: "Actions",
              bodyClass: "text-center",
              bodyStyle: "width: 150px"
            }, {
              body: _withCtx((slotProps) => [
                _createElementVNode("div", _hoisted_6, [
                  _createVNode(_component_Button, {
                    id: "downloadButton",
                    icon: _ctx.downloadIcon(slotProps.data.id),
                    title: "Download",
                    onClick: ($event: any) => (_ctx.download(slotProps.data))
                  }, null, 8, ["icon", "onClick"]),
                  _cache[8] || (_cache[8] = _createTextVNode("   ")),
                  (_ctx.showMail)
                    ? (_openBlock(), _createBlock(_component_Button, {
                        key: 0,
                        id: "emailButton",
                        icon: "pi pi-envelope",
                        title: "Email",
                        onClick: ($event: any) => (_ctx.showEmailModal(slotProps.data))
                      }, null, 8, ["onClick"]))
                    : _createCommentVNode("", true),
                  _cache[9] || (_cache[9] = _createTextVNode("   ")),
                  _createVNode(_component_Button, {
                    id: "deleteButton",
                    icon: _ctx.deleteIcon(slotProps.data.id),
                    title: "Delete",
                    class: "remove",
                    onClick: ($event: any) => (_ctx.confirmDelete(slotProps.data.id))
                  }, null, 8, ["icon", "onClick"])
                ])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["value", "hidden", "rows", "first", "onPage"]))
      : _createCommentVNode("", true),
    _createVNode(_component_AddAttachmentDialog, {
      isLoadingAttachment: _ctx.isUploading,
      show: _ctx.showAddTicketAttachmentDialog,
      onHide: _cache[2] || (_cache[2] = 
        (isHidden) => {
          _ctx.showAddTicketAttachmentDialog = isHidden;
        }
      ),
      onOnUploadAttachment: _cache[3] || (_cache[3] = 
        (data) => {
          _ctx.save(data);
        }
      )
    }, null, 8, ["isLoadingAttachment", "show"]),
    (_ctx.showMail != null && _ctx.showMail)
      ? (_openBlock(), _createBlock(_component_EmailAttachmentDialog, {
          key: 1,
          show: _ctx.showEmailAttachmentDialog,
          onHide: _cache[4] || (_cache[4] = 
        (isHidden) => {
          _ctx.showEmailAttachmentDialog = isHidden;
        }
      ),
          attachmentId: _ctx.currentAttachmentId,
          fileName: _ctx.fileName
        }, null, 8, ["show", "attachmentId", "fileName"]))
      : _createCommentVNode("", true)
  ]))
}

import { defineComponent } from "vue";
import { mapState } from "vuex";
import { mapActions } from "pinia";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Commit from "@/types/commit";
import Button from "primevue/button";
import { useInventoryStore } from "@/store/ui/inventoryModule";

export default defineComponent({
  props: {
    partNumber: {
      type: String,
      default: "",
    },
  },
  components: {
    DataTable,
    Column,
    Button,
  },
  computed: {
    ...mapState({
      getClient: "session/getClient",
    }),
  },
  data() {
    return {
      commitsInfo: {} as Commit,
      dateInfo: [] as any[],
      isLoadingCommits: false,
      rowsPerPage: 10,
      page: 1,
      first: 0,
      sortBy: "formatted_date",
      sortOrder: -1,
    };
  },
  async mounted() {
    await this.fetchCommits();
  },
  methods: {
    ...mapActions(useInventoryStore, ["fetchCommitmentsById"]),
    async fetchCommits(refresh = false) {
      if (this.partNumber === "") return;
      this.isLoadingCommits = true;
      const resp = await this.fetchCommitmentsById({
        id: this.partNumber,
        client: this.getClient,
        refresh,
      });

      if (resp.success && resp.commit) {
        this.commitsInfo = resp.commit;
        this.dateInfo = this.commitsInfo.date_items;
        this.dateInfo.map((item: any) => {
          item.formatted_date = new Date(item.date);
        });
      }
      this.isLoadingCommits = false;
    },
  },
  watch: {
    partNumber() {
      this.fetchCommits();
    },
  },
});

import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"
import _imports_0 from '@/assets/images/warning-yellow.svg'


const _hoisted_1 = { class: "flex justify-content-between" }
const _hoisted_2 = { class: "flex flex-column w-11 overflow-hidden" }
const _hoisted_3 = { class: "block item-customer font-semibold text-overflow-ellipsis overflow-hidden" }
const _hoisted_4 = { class: "item-date-id" }
const _hoisted_5 = {
  key: 0,
  class: "w-1rem h-1rem",
  src: _imports_0
}
const _hoisted_6 = { class: "flex justify-content-between align-items-end item-date-id" }
const _hoisted_7 = { class: "item-date" }
const _hoisted_8 = {
  key: 0,
  class: "block text-xl text-blue-700 font-semibold item-amount text-overflow-ellipsis overflow-hidden text-right"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("div", {
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onClickEdit && _ctx.onClickEdit(...args))),
    class: "flex flex-column justify-content-between item-wrapper grabbable"
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.cardData.custName), 1),
        _createElementVNode("span", _hoisted_4, "#" + _toDisplayString(_ctx.cardData.id), 1)
      ]),
      false
        ? (_openBlock(), _createElementBlock("img", _hoisted_5))
        : _createCommentVNode("", true),
      (_ctx.cardData.assignee)
        ? _withDirectives((_openBlock(), _createElementBlock("i", {
            key: 1,
            class: _normalizeClass(["pi pi-user user-icon border-circle", { 'text-white bg-blue-600': _ctx.userId === _ctx.cardData.assignee }])
          }, null, 2)), [
            [_directive_tooltip, _ctx.cardData.assigneeText + _ctx.cardData.assignee]
          ])
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.cardData.date), 1),
      (_ctx.cardData.amount)
        ? (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(_ctx.cardData.amount), 1))
        : _createCommentVNode("", true)
    ])
  ]))
}
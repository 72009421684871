import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "grid m-0 accounting-tab-content p-0 px-3" }
const _hoisted_2 = { class: "grid justify-content-center p-0 m-0 mt-2" }
const _hoisted_3 = { class: "col-12 lg:col-11 grid justify-content-center m-0 p-0" }
const _hoisted_4 = { class: "col-12 lg:col-4 grid p-0 pr-0 m-0 align-content-start" }
const _hoisted_5 = { class: "col-12 grid p-0 m-0 sm:hidden md:hidden lg:flex" }
const _hoisted_6 = { class: "col-12 sm:col-6 lg:col-12 p-1 m-0" }
const _hoisted_7 = { class: "p-inputgroup" }
const _hoisted_8 = { class: "col-12 sm:col-6 lg:col-12 p-1 m-0" }
const _hoisted_9 = { class: "p-inputgroup" }
const _hoisted_10 = { class: "col-12 sm:col-6 lg:col-12 p-1 m-0" }
const _hoisted_11 = {
  class: "p-inputgroup",
  style: { maxHeight: '127px !important' }
}
const _hoisted_12 = { class: "col-12 sm:col-6 lg:col-12 p-1 m-0" }
const _hoisted_13 = { class: "p-inputgroup" }
const _hoisted_14 = { class: "col-12 hidden grid p-0 m-0 sm:flex md:flex lg:hidden" }
const _hoisted_15 = { class: "col-6 p-0 m-0" }
const _hoisted_16 = { class: "col-12 p-1 m-0" }
const _hoisted_17 = { class: "p-inputgroup" }
const _hoisted_18 = { class: "col-12 p-1 m-0" }
const _hoisted_19 = { class: "p-inputgroup" }
const _hoisted_20 = { class: "col-12 p-1 m-0" }
const _hoisted_21 = { class: "p-inputgroup" }
const _hoisted_22 = { class: "col-6 p-0 m-0" }
const _hoisted_23 = { class: "col-12 p-1 m-0" }
const _hoisted_24 = {
  class: "p-inputgroup",
  style: { maxHeight: '127px !important' }
}
const _hoisted_25 = { class: "col-12 lg:col-4 grid p-0 pr-0 m-0 align-content-start" }
const _hoisted_26 = { class: "col-12 sm:col-6 lg:col-12 p-1 m-0" }
const _hoisted_27 = { class: "p-inputgroup" }
const _hoisted_28 = { class: "col-12 sm:col-6 lg:col-12 p-1 m-0" }
const _hoisted_29 = { class: "p-inputgroup" }
const _hoisted_30 = { class: "col-12 sm:col-6 lg:col-12 p-1 m-0" }
const _hoisted_31 = { class: "p-inputgroup" }
const _hoisted_32 = { class: "col-12 sm:col-6 lg:col-12 p-1 m-0" }
const _hoisted_33 = { class: "p-inputgroup" }
const _hoisted_34 = { class: "col-12 sm:col-6 lg:col-12 p-1 m-0" }
const _hoisted_35 = { class: "p-inputgroup" }
const _hoisted_36 = { class: "col-12 sm:col-6 lg:col-12 p-1 m-0" }
const _hoisted_37 = { class: "p-inputgroup" }
const _hoisted_38 = { class: "col-12 lg:col-4 grid p-0 pr-0 m-0 align-content-start" }
const _hoisted_39 = { class: "col-12 sm:col-6 lg:col-12 p-1 m-0" }
const _hoisted_40 = { class: "p-inputgroup" }
const _hoisted_41 = { class: "col-12 sm:col-6 lg:col-12 p-1 m-0" }
const _hoisted_42 = { class: "p-inputgroup" }
const _hoisted_43 = { class: "col-12 sm:col-6 lg:col-12 p-1 m-0" }
const _hoisted_44 = { class: "p-inputgroup" }
const _hoisted_45 = { class: "col-12 sm:col-6 lg:col-12 p-1 m-0" }
const _hoisted_46 = { class: "p-inputgroup" }
const _hoisted_47 = { class: "col-12 sm:col-6 lg:col-12 p-1 m-0" }
const _hoisted_48 = { class: "p-inputgroup" }
const _hoisted_49 = { class: "col-12 sm:col-6 lg:col-12 p-1 m-0" }
const _hoisted_50 = { class: "p-inputgroup" }
const _hoisted_51 = { class: "col-12 grid p-0 pr-0 m-0 align-content-start" }
const _hoisted_52 = { class: "col-12 lg:col-5 grid p-0 m-0" }
const _hoisted_53 = { class: "col-12 p-0 m-0" }
const _hoisted_54 = { class: "col-12 sm:hidden lg:flex p-1 m-0" }
const _hoisted_55 = { class: "p-inputgroup" }
const _hoisted_56 = { class: "col-12 hidden sm:flex lg:hidden p-1 m-0" }
const _hoisted_57 = { class: "p-inputgroup" }
const _hoisted_58 = { class: "col-12 lg:col-7 grid p-1 m-0" }
const _hoisted_59 = { class: "hidden sm:flex grid p-0 m-0" }
const _hoisted_60 = { class: "col-4 p-0" }
const _hoisted_61 = { class: "col-12 sm:hidden grid p-0 m-0 justify-content-end" }
const _hoisted_62 = { class: "col-4 p-0 pr-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputText = _resolveComponent("InputText")!
  const _component_TextArea = _resolveComponent("TextArea")!
  const _component_InputNumber = _resolveComponent("InputNumber")!
  const _component_Column = _resolveComponent("Column")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _component_Card = _resolveComponent("Card")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Card, { class: "col-12 border-round-3xl tab-card-content p-0 mt-3" }, {
      content: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("div", _hoisted_7, [
                    _cache[22] || (_cache[22] = _createElementVNode("small", { class: "p-inputgroup-addon" }, "Line Item", -1)),
                    _createVNode(_component_InputText, {
                      modelValue: _ctx.item.li,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.item.li) = $event)),
                      type: "text",
                      class: "text-sm",
                      disabled: _ctx.isReadOnly
                    }, null, 8, ["modelValue", "disabled"])
                  ])
                ]),
                _createElementVNode("div", _hoisted_8, [
                  _createElementVNode("div", _hoisted_9, [
                    _cache[23] || (_cache[23] = _createElementVNode("small", { class: "p-inputgroup-addon" }, "Part Number", -1)),
                    _createVNode(_component_InputText, {
                      modelValue: _ctx.item.part,
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.item.part) = $event)),
                      type: "text",
                      class: "text-sm",
                      disabled: _ctx.isReadOnly
                    }, null, 8, ["modelValue", "disabled"])
                  ])
                ]),
                _createElementVNode("div", _hoisted_10, [
                  _createElementVNode("div", _hoisted_11, [
                    _cache[24] || (_cache[24] = _createElementVNode("small", { class: "p-inputgroup-addon" }, "Description", -1)),
                    _createVNode(_component_TextArea, {
                      modelValue: _ctx.description,
                      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.description) = $event)),
                      rows: "6",
                      class: "textarea text-sm text-left",
                      disabled: _ctx.isReadOnly
                    }, null, 8, ["modelValue", "disabled"])
                  ])
                ]),
                _createElementVNode("div", _hoisted_12, [
                  _createElementVNode("div", _hoisted_13, [
                    _cache[25] || (_cache[25] = _createElementVNode("small", { class: "p-inputgroup-addon" }, "UM", -1)),
                    _createVNode(_component_InputText, {
                      modelValue: _ctx.item.um,
                      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.item.um) = $event)),
                      type: "text",
                      class: "text-sm",
                      disabled: _ctx.isReadOnly
                    }, null, 8, ["modelValue", "disabled"])
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_14, [
                _createElementVNode("div", _hoisted_15, [
                  _createElementVNode("div", _hoisted_16, [
                    _createElementVNode("div", _hoisted_17, [
                      _cache[26] || (_cache[26] = _createElementVNode("small", { class: "p-inputgroup-addon" }, "Line Item", -1)),
                      _createVNode(_component_InputText, {
                        modelValue: _ctx.item.li,
                        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.item.li) = $event)),
                        type: "text",
                        class: "text-sm",
                        disabled: _ctx.isReadOnly
                      }, null, 8, ["modelValue", "disabled"])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_18, [
                    _createElementVNode("div", _hoisted_19, [
                      _cache[27] || (_cache[27] = _createElementVNode("small", { class: "p-inputgroup-addon" }, "Part Number", -1)),
                      _createVNode(_component_InputText, {
                        modelValue: _ctx.item.part_number,
                        "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.item.part_number) = $event)),
                        type: "text",
                        class: "text-sm",
                        disabled: _ctx.isReadOnly
                      }, null, 8, ["modelValue", "disabled"])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_20, [
                    _createElementVNode("div", _hoisted_21, [
                      _cache[28] || (_cache[28] = _createElementVNode("small", { class: "p-inputgroup-addon" }, "UM", -1)),
                      _createVNode(_component_InputText, {
                        modelValue: _ctx.item.um,
                        "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.item.um) = $event)),
                        type: "text",
                        class: "text-sm",
                        disabled: _ctx.isReadOnly
                      }, null, 8, ["modelValue", "disabled"])
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_22, [
                  _createElementVNode("div", _hoisted_23, [
                    _createElementVNode("div", _hoisted_24, [
                      _cache[29] || (_cache[29] = _createElementVNode("small", { class: "p-inputgroup-addon" }, "Description", -1)),
                      _createVNode(_component_TextArea, {
                        modelValue: _ctx.description,
                        "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.description) = $event)),
                        rows: "6",
                        class: "textarea text-sm text-left",
                        disabled: _ctx.isReadOnly
                      }, null, 8, ["modelValue", "disabled"])
                    ])
                  ])
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_25, [
              _createElementVNode("div", _hoisted_26, [
                _createElementVNode("div", _hoisted_27, [
                  _cache[30] || (_cache[30] = _createElementVNode("small", { class: "p-inputgroup-addon" }, "Project", -1)),
                  _createVNode(_component_InputText, {
                    modelValue: _ctx.item.project,
                    "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.item.project) = $event)),
                    type: "text",
                    class: "text-sm",
                    disabled: _ctx.isReadOnly
                  }, null, 8, ["modelValue", "disabled"])
                ])
              ]),
              _createElementVNode("div", _hoisted_28, [
                _createElementVNode("div", _hoisted_29, [
                  _cache[31] || (_cache[31] = _createElementVNode("small", { class: "p-inputgroup-addon" }, "Task", -1)),
                  _createVNode(_component_InputText, {
                    modelValue: _ctx.item.task,
                    "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.item.task) = $event)),
                    type: "text",
                    class: "text-sm",
                    disabled: _ctx.isReadOnly
                  }, null, 8, ["modelValue", "disabled"])
                ])
              ]),
              _createElementVNode("div", _hoisted_30, [
                _createElementVNode("div", _hoisted_31, [
                  _cache[32] || (_cache[32] = _createElementVNode("small", { class: "p-inputgroup-addon" }, "Convert Factor", -1)),
                  _createVNode(_component_InputText, {
                    modelValue: _ctx.item.convert_factor,
                    "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.item.convert_factor) = $event)),
                    type: "text",
                    class: "text-sm",
                    disabled: _ctx.isReadOnly
                  }, null, 8, ["modelValue", "disabled"])
                ])
              ]),
              _createElementVNode("div", _hoisted_32, [
                _createElementVNode("div", _hoisted_33, [
                  _cache[33] || (_cache[33] = _createElementVNode("small", { class: "p-inputgroup-addon" }, "Standard Cost", -1)),
                  _createVNode(_component_InputNumber, {
                    modelValue: _ctx.item.standard_cost,
                    "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.item.standard_cost) = $event)),
                    type: "number",
                    class: "text-sm",
                    inputClass: "text-left",
                    mode: "currency",
                    currency: "USD",
                    locale: "en-US",
                    disabled: _ctx.isReadOnly
                  }, null, 8, ["modelValue", "disabled"])
                ])
              ]),
              _createElementVNode("div", _hoisted_34, [
                _createElementVNode("div", _hoisted_35, [
                  _cache[34] || (_cache[34] = _createElementVNode("small", { class: "p-inputgroup-addon" }, "Std Lot Size", -1)),
                  _createVNode(_component_InputNumber, {
                    modelValue: _ctx.item.std_lot_size,
                    "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.item.std_lot_size) = $event)),
                    type: "number",
                    class: "text-sm",
                    inputClass: "text-left",
                    mode: "currency",
                    currency: "USD",
                    locale: "en-US",
                    disabled: _ctx.isReadOnly
                  }, null, 8, ["modelValue", "disabled"])
                ])
              ]),
              _createElementVNode("div", _hoisted_36, [
                _createElementVNode("div", _hoisted_37, [
                  _cache[35] || (_cache[35] = _createElementVNode("small", { class: "p-inputgroup-addon" }, "Original Cost", -1)),
                  _createVNode(_component_InputNumber, {
                    modelValue: _ctx.item.orig_cost,
                    "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.item.orig_cost) = $event)),
                    type: "number",
                    class: "text-sm",
                    inputClass: "text-left",
                    mode: "currency",
                    currency: "USD",
                    locale: "en-US",
                    disabled: _ctx.isReadOnly
                  }, null, 8, ["modelValue", "disabled"])
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_38, [
              _createElementVNode("div", _hoisted_39, [
                _createElementVNode("div", _hoisted_40, [
                  _cache[36] || (_cache[36] = _createElementVNode("small", { class: "p-inputgroup-addon" }, "Cost Group", -1)),
                  _createVNode(_component_InputText, {
                    modelValue: _ctx.item.cost_group,
                    "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.item.cost_group) = $event)),
                    type: "text",
                    class: "text-sm",
                    disabled: _ctx.isReadOnly
                  }, null, 8, ["modelValue", "disabled"])
                ])
              ]),
              _createElementVNode("div", _hoisted_41, [
                _createElementVNode("div", _hoisted_42, [
                  _cache[37] || (_cache[37] = _createElementVNode("small", { class: "p-inputgroup-addon" }, "Receipt Qty", -1)),
                  _createVNode(_component_InputNumber, {
                    modelValue: _ctx.item.rec_qty,
                    "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.item.rec_qty) = $event)),
                    type: "number",
                    class: "text-sm",
                    inputClass: "text-left",
                    disabled: _ctx.isReadOnly
                  }, null, 8, ["modelValue", "disabled"])
                ])
              ]),
              _createElementVNode("div", _hoisted_43, [
                _createElementVNode("div", _hoisted_44, [
                  _cache[38] || (_cache[38] = _createElementVNode("small", { class: "p-inputgroup-addon" }, "Price Per", -1)),
                  _createVNode(_component_InputNumber, {
                    modelValue: _ctx.item.price_per,
                    "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.item.price_per) = $event)),
                    type: "number",
                    class: "text-sm",
                    inputClass: "text-left",
                    mode: "currency",
                    currency: "USD",
                    locale: "en-US",
                    disabled: _ctx.isReadOnly
                  }, null, 8, ["modelValue", "disabled"])
                ])
              ]),
              _createElementVNode("div", _hoisted_45, [
                _createElementVNode("div", _hoisted_46, [
                  _cache[39] || (_cache[39] = _createElementVNode("small", { class: "p-inputgroup-addon" }, "Actual Cost", -1)),
                  _createVNode(_component_InputNumber, {
                    modelValue: _ctx.item.actual_cost,
                    "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.item.actual_cost) = $event)),
                    type: "number",
                    class: "text-sm",
                    inputClass: "text-left",
                    mode: "currency",
                    currency: "USD",
                    locale: "en-US",
                    disabled: _ctx.isReadOnly
                  }, null, 8, ["modelValue", "disabled"])
                ])
              ]),
              _createElementVNode("div", _hoisted_47, [
                _createElementVNode("div", _hoisted_48, [
                  _cache[40] || (_cache[40] = _createElementVNode("small", { class: "p-inputgroup-addon" }, "Foreign Price", -1)),
                  _createVNode(_component_InputNumber, {
                    modelValue: _ctx.item.foreign_price,
                    "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.item.foreign_price) = $event)),
                    type: "number",
                    class: "text-sm",
                    inputClass: "text-left",
                    mode: "currency",
                    currency: "USD",
                    locale: "en-US",
                    disabled: _ctx.isReadOnly
                  }, null, 8, ["modelValue", "disabled"])
                ])
              ]),
              _createElementVNode("div", _hoisted_49, [
                _createElementVNode("div", _hoisted_50, [
                  _cache[41] || (_cache[41] = _createElementVNode("small", { class: "p-inputgroup-addon" }, "Total Cost", -1)),
                  _createVNode(_component_InputNumber, {
                    modelValue: _ctx.item.amount_items[0].amount,
                    "onUpdate:modelValue": _cache[19] || (_cache[19] = ($event: any) => ((_ctx.item.amount_items[0].amount) = $event)),
                    type: "number",
                    class: "text-sm",
                    inputClass: "text-left",
                    mode: "currency",
                    currency: "USD",
                    locale: "en-US",
                    disabled: _ctx.isReadOnly
                  }, null, 8, ["modelValue", "disabled"])
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_51, [
              _createElementVNode("div", _hoisted_52, [
                _createElementVNode("div", _hoisted_53, [
                  _createElementVNode("div", _hoisted_54, [
                    _createElementVNode("div", _hoisted_55, [
                      _cache[42] || (_cache[42] = _createElementVNode("small", { class: "p-inputgroup-addon" }, "Notes", -1)),
                      _createVNode(_component_TextArea, {
                        modelValue: _ctx.item.notes,
                        "onUpdate:modelValue": _cache[20] || (_cache[20] = ($event: any) => ((_ctx.item.notes) = $event)),
                        rows: "9",
                        class: "textarea text-sm text-left",
                        disabled: _ctx.isReadOnly
                      }, null, 8, ["modelValue", "disabled"])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_56, [
                    _createElementVNode("div", _hoisted_57, [
                      _cache[43] || (_cache[43] = _createElementVNode("small", { class: "p-inputgroup-addon" }, "Notes", -1)),
                      _createVNode(_component_TextArea, {
                        modelValue: _ctx.item.notes,
                        "onUpdate:modelValue": _cache[21] || (_cache[21] = ($event: any) => ((_ctx.item.notes) = $event)),
                        rows: "4",
                        class: "textarea text-sm text-left",
                        disabled: _ctx.isReadOnly
                      }, null, 8, ["modelValue", "disabled"])
                    ])
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_58, [
                _createVNode(_component_DataTable, {
                  value: _ctx.item.dist_items,
                  dataKey: "account",
                  class: "p-datatable-sm w-full bordered-table with-footer with-header",
                  "paginator-template": "FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown",
                  rows: 3,
                  paginator: true,
                  removableSort: "",
                  breakpoint: "575px",
                  responsiveLayout: "stack"
                }, {
                  empty: _withCtx(() => _cache[44] || (_cache[44] = [
                    _createElementVNode("div", { class: "text-center" }, "No Items Found.", -1)
                  ])),
                  header: _withCtx(() => _cache[45] || (_cache[45] = [
                    _createElementVNode("div", { class: "table-header p-0 m-0 flex justify-content-between" }, [
                      _createElementVNode("div"),
                      _createElementVNode("h3", { class: "p-0 m-0 text-center inline-flex" }, " Distribution "),
                      _createElementVNode("div")
                    ], -1)
                  ])),
                  footer: _withCtx(() => [
                    _createElementVNode("div", _hoisted_59, [
                      _createElementVNode("div", _hoisted_60, _toDisplayString(_ctx.computedDiscTotal()), 1),
                      _cache[46] || (_cache[46] = _createElementVNode("div", { class: "col-3 p-0 pl-3 text-left" }, "Total", -1))
                    ]),
                    _createElementVNode("div", _hoisted_61, [
                      _cache[47] || (_cache[47] = _createElementVNode("div", { class: "col-3 p-0 pl-3 text-l eft" }, "Total", -1)),
                      _createElementVNode("div", _hoisted_62, _toDisplayString(_ctx.computedDiscTotal()), 1)
                    ])
                  ]),
                  default: _withCtx(() => [
                    _createVNode(_component_Column, {
                      field: "amount",
                      header: "Amount",
                      sortable: true,
                      "header-style": "min-width: 130px !important;",
                      "body-style": "min-width: 130px !important",
                      bodyClass: "text-right misc-amount"
                    }, {
                      body: _withCtx(({ data }) => [
                        _createElementVNode("span", null, _toDisplayString(_ctx.formatPrice(data.amount)), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_Column, {
                      field: "acct",
                      header: "Account",
                      "header-style": "min-width: 130px !important;",
                      "body-style": "min-width: 130px !important;",
                      sortable: true,
                      "body-class": "text-center"
                    }),
                    _createVNode(_component_Column, {
                      field: "desc",
                      header: "Description",
                      sortable: true
                    })
                  ]),
                  _: 1
                }, 8, ["value"])
              ])
            ])
          ])
        ])
      ]),
      _: 1
    })
  ]))
}
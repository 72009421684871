
import { defineComponent } from "vue";
import { mapState } from "vuex";
import store from "@/store";
export default defineComponent({
  name: "Dashboard",
  computed: {
    ...mapState(["session", "dashboard"]),
  },
  beforeCreate() {
    if (store.getters["dashboard/getUrl"] === "") {
      store.dispatch("dashboard/getReportUrl", {
        client: store.getters["session/getClient"],
      });
    }
  },
});

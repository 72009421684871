import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withKeys as _withKeys, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "grid flex justify-content-center mt-2" }
const _hoisted_2 = { class: "col-11 lg:col-11" }
const _hoisted_3 = { class: "grid align-items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputEmployeeID = _resolveComponent("InputEmployeeID")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Button = _resolveComponent("Button")!
  const _component_MessageBox = _resolveComponent("MessageBox")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_InputEmployeeID, {
            ref: "employee_id",
            employeeId: _ctx.workOrderCompletionPayload.employee_id,
            "onUpdate:employeeId": _cache[0] || (_cache[0] = ($event: any) => (_ctx.workOrderCompletionPayload.employee_id = $event)),
            nextInput: "work_order",
            handleErrorAndFocus: _ctx.handleErrorAndFocus,
            focusInput: _ctx.focusInput,
            rowClass: _ctx.rowClass
          }, null, 8, ["employeeId", "handleErrorAndFocus", "focusInput", "rowClass"]),
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.rowClass)
          }, [
            _cache[42] || (_cache[42] = _createElementVNode("span", { class: "p-inputgroup-addon" }, " Work Order ID ", -1)),
            _createVNode(_component_InputText, {
              ref: "work_order",
              class: "scan-input-text",
              modelValue: _ctx.workOrderCompletionPayload.work_order,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.workOrderCompletionPayload.work_order) = $event)),
              onFocus: _cache[2] || (_cache[2] = ($event: any) => ($event.target.select())),
              onKeypress: _withKeys(_ctx.handleWorkOrder, ["enter"]),
              onKeydown: [
                _withKeys(_ctx.handleWorkOrder, ["down"]),
                _cache[3] || (_cache[3] = _withKeys(($event: any) => (_ctx.focusInput('employee_id')), ["up"]))
              ]
            }, null, 8, ["modelValue", "onKeypress", "onKeydown"])
          ], 2),
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.rowClass)
          }, [
            _cache[43] || (_cache[43] = _createElementVNode("span", { class: "p-inputgroup-addon" }, " Assembly Number ", -1)),
            _createVNode(_component_InputText, {
              ref: "assembly_number",
              class: "scan-input-text",
              modelValue: _ctx.workOrder.part_no,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.workOrder.part_no) = $event)),
              onFocus: _cache[5] || (_cache[5] = ($event: any) => (_ctx.focusInput('quantity'))),
              onKeydown: _cache[6] || (_cache[6] = _withKeys(($event: any) => (_ctx.focusInput('work_order')), ["up"])),
              readonly: ""
            }, null, 8, ["modelValue"])
          ], 2),
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.rowClass)
          }, [
            _cache[44] || (_cache[44] = _createElementVNode("span", { class: "p-inputgroup-addon" }, " Model Number ", -1)),
            _createVNode(_component_InputText, {
              ref: "model_number",
              class: "scan-input-text",
              modelValue: _ctx.workOrder.model_number,
              "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.workOrder.model_number) = $event)),
              onFocus: _cache[8] || (_cache[8] = ($event: any) => (_ctx.focusInput('quantity'))),
              onKeydown: _cache[9] || (_cache[9] = _withKeys(($event: any) => (_ctx.focusInput('work_order')), ["up"])),
              readonly: ""
            }, null, 8, ["modelValue"])
          ], 2),
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.rowClass)
          }, [
            _cache[45] || (_cache[45] = _createElementVNode("span", { class: "p-inputgroup-addon" }, " Description ", -1)),
            _createVNode(_component_InputText, {
              ref: "description",
              class: "scan-input-text",
              modelValue: _ctx.workOrder.description,
              "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.workOrder.description) = $event)),
              onFocus: _cache[11] || (_cache[11] = ($event: any) => (_ctx.focusInput('quantity'))),
              onKeydown: _cache[12] || (_cache[12] = _withKeys(($event: any) => (_ctx.focusInput('work_order')), ["up"])),
              readonly: ""
            }, null, 8, ["modelValue"])
          ], 2),
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.rowClass)
          }, [
            _cache[46] || (_cache[46] = _createElementVNode("span", { class: "p-inputgroup-addon" }, " Quantity ", -1)),
            _createVNode(_component_InputText, {
              ref: "quantity",
              class: "scan-input-text",
              modelValue: _ctx.workOrderCompletionPayload.quantity,
              "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.workOrderCompletionPayload.quantity) = $event)),
              onFocus: _cache[14] || (_cache[14] = ($event: any) => ($event.target.select())),
              onKeypress: _cache[15] || (_cache[15] = _withKeys(($event: any) => (_ctx.focusInput('operation')), ["enter"])),
              onKeydown: [
                _cache[16] || (_cache[16] = _withKeys(($event: any) => (_ctx.focusInput('operation')), ["down"])),
                _cache[17] || (_cache[17] = _withKeys(($event: any) => (_ctx.focusInput('work_order')), ["up"]))
              ]
            }, null, 8, ["modelValue"])
          ], 2),
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.rowClass)
          }, [
            _cache[47] || (_cache[47] = _createElementVNode("span", { class: "p-inputgroup-addon" }, " U/M ", -1)),
            _createVNode(_component_InputText, {
              ref: "unit_of_measure",
              class: "scan-input-text",
              modelValue: _ctx.part.um,
              "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.part.um) = $event)),
              onFocus: _cache[19] || (_cache[19] = ($event: any) => (_ctx.focusInput('operation'))),
              onKeydown: _cache[20] || (_cache[20] = _withKeys(($event: any) => (_ctx.focusInput('quantity')), ["up"])),
              readonly: ""
            }, null, 8, ["modelValue"])
          ], 2),
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.rowClass)
          }, [
            _cache[48] || (_cache[48] = _createElementVNode("span", { class: "p-inputgroup-addon" }, " Operation ", -1)),
            _createVNode(_component_InputText, {
              ref: "operation",
              class: "scan-input-text",
              modelValue: _ctx.workOrderCompletionPayload.operation,
              "onUpdate:modelValue": _cache[21] || (_cache[21] = ($event: any) => ((_ctx.workOrderCompletionPayload.operation) = $event)),
              onFocus: _cache[22] || (_cache[22] = ($event: any) => ($event.target.select())),
              onKeypress: _withKeys(_ctx.handleOperation, ["enter"]),
              onKeydown: [
                _withKeys(_ctx.handleOperation, ["down"]),
                _cache[23] || (_cache[23] = _withKeys(($event: any) => (_ctx.focusInput('quantity')), ["up"]))
              ]
            }, null, 8, ["modelValue", "onKeypress", "onKeydown"])
          ], 2),
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.rowClass)
          }, [
            _cache[49] || (_cache[49] = _createElementVNode("span", { class: "p-inputgroup-addon" }, " Work Center ", -1)),
            _createVNode(_component_InputText, {
              ref: "work_center",
              class: "scan-input-text",
              modelValue: _ctx.work_center,
              "onUpdate:modelValue": _cache[24] || (_cache[24] = ($event: any) => ((_ctx.work_center) = $event)),
              onFocus: _cache[25] || (_cache[25] = ($event: any) => ($event.target.select())),
              onKeydown: _cache[26] || (_cache[26] = _withKeys(($event: any) => (_ctx.focusInput('to_inventory')), ["up"])),
              readonly: ""
            }, null, 8, ["modelValue"])
          ], 2),
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.rowClass)
          }, [
            _cache[50] || (_cache[50] = _createElementVNode("span", { class: "p-inputgroup-addon" }, " To Inventory ", -1)),
            _createVNode(_component_InputText, {
              ref: "to_inventory",
              class: "scan-input-text",
              modelValue: _ctx.workOrderCompletionPayload.to_inventory,
              "onUpdate:modelValue": _cache[27] || (_cache[27] = ($event: any) => ((_ctx.workOrderCompletionPayload.to_inventory) = $event)),
              onFocus: _cache[28] || (_cache[28] = ($event: any) => ($event.target.select())),
              onKeypress: _withKeys(_ctx.handleInventory, ["enter"]),
              onKeydown: [
                _withKeys(_ctx.handleInventory, ["down"]),
                _cache[29] || (_cache[29] = _withKeys(($event: any) => (_ctx.focusInput('work_center')), ["up"]))
              ]
            }, null, 8, ["modelValue", "onKeypress", "onKeydown"])
          ], 2),
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.rowClass)
          }, [
            _cache[51] || (_cache[51] = _createElementVNode("span", { class: "p-inputgroup-addon" }, " To Description ", -1)),
            _createVNode(_component_InputText, {
              ref: "to_description",
              class: "scan-input-text",
              modelValue: _ctx.toDescription,
              "onUpdate:modelValue": _cache[30] || (_cache[30] = ($event: any) => ((_ctx.toDescription) = $event)),
              onFocus: _cache[31] || (_cache[31] = ($event: any) => ($event.target.select())),
              onKeydown: _cache[32] || (_cache[32] = _withKeys(($event: any) => (_ctx.focusInput('to_inventory')), ["up"])),
              readonly: ""
            }, null, 8, ["modelValue"])
          ], 2),
          (_ctx.isInventoryLocationWOType)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: _normalizeClass(_ctx.rowClass)
              }, [
                _cache[52] || (_cache[52] = _createElementVNode("span", { class: "p-inputgroup-addon" }, " To Work Order ", -1)),
                _createVNode(_component_InputText, {
                  ref: "to_work_order",
                  class: "scan-input-text",
                  modelValue: _ctx.workOrderCompletionPayload.to_work_order,
                  "onUpdate:modelValue": _cache[33] || (_cache[33] = ($event: any) => ((_ctx.workOrderCompletionPayload.to_work_order) = $event)),
                  onFocus: _cache[34] || (_cache[34] = ($event: any) => ($event.target.select())),
                  onKeypress: _withKeys(_ctx.handleToWorkOrder, ["enter"]),
                  onKeydown: [
                    _withKeys(_ctx.handleToWorkOrder, ["down"]),
                    _cache[35] || (_cache[35] = _withKeys(($event: any) => (_ctx.focusInput('to_description')), ["up"]))
                  ]
                }, null, 8, ["modelValue", "onKeypress", "onKeydown"])
              ], 2))
            : _createCommentVNode("", true),
          (_ctx.lotControl)
            ? (_openBlock(), _createElementBlock("div", {
                key: 1,
                class: _normalizeClass(_ctx.rowClass)
              }, [
                _cache[53] || (_cache[53] = _createElementVNode("span", { class: "p-inputgroup-addon" }, " Lot ID ", -1)),
                _createVNode(_component_InputText, {
                  ref: "lot_id",
                  class: "scan-input-text",
                  modelValue: _ctx.workOrderCompletionPayload.lot_id,
                  "onUpdate:modelValue": _cache[36] || (_cache[36] = ($event: any) => ((_ctx.workOrderCompletionPayload.lot_id) = $event)),
                  onFocus: _cache[37] || (_cache[37] = ($event: any) => ($event.target.select())),
                  onKeydown: _cache[38] || (_cache[38] = _withKeys(($event: any) => (_ctx.focusInput('to_work_order')), ["up"]))
                }, null, 8, ["modelValue"])
              ], 2))
            : _createCommentVNode("", true),
          (_ctx.binControl)
            ? (_openBlock(), _createElementBlock("div", {
                key: 2,
                class: _normalizeClass(_ctx.rowClass)
              }, [
                _cache[54] || (_cache[54] = _createElementVNode("span", { class: "p-inputgroup-addon" }, " Bin Number ", -1)),
                _createVNode(_component_InputText, {
                  ref: "bin_number",
                  class: "scan-input-text",
                  modelValue: _ctx.workOrderCompletionPayload.bin_number,
                  "onUpdate:modelValue": _cache[39] || (_cache[39] = ($event: any) => ((_ctx.workOrderCompletionPayload.bin_number) = $event)),
                  onFocus: _cache[40] || (_cache[40] = ($event: any) => ($event.target.select())),
                  onKeydown: _cache[41] || (_cache[41] = _withKeys(($event: any) => (_ctx.focusInput('lot_id')), ["up"]))
                }, null, 8, ["modelValue"])
              ], 2))
            : _createCommentVNode("", true),
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.rowClass)
          }, [
            _createVNode(_component_Button, {
              label: "Save Completion Entry",
              loading: _ctx.isCompletionEntryLoading,
              onClick: _ctx.processShopTransaction,
              class: "scan-input-button"
            }, null, 8, ["loading", "onClick"])
          ], 2)
        ])
      ])
    ]),
    _createVNode(_component_MessageBox, {
      showDialog: _ctx.showErrorDialog,
      icon: "INFO",
      header: "Error",
      message: _ctx.errorMessage,
      primaryButton: "OK",
      onPrimaryButtonClick: _ctx.clickConfirmErrorDialog
    }, null, 8, ["showDialog", "message", "onPrimaryButtonClick"])
  ], 64))
}
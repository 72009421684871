import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  style: {"min-height":"360px"}
}
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_Button = _resolveComponent("Button")!
  const _component_LoadingSpinner = _resolveComponent("LoadingSpinner")!
  const _component_Chart = _resolveComponent("Chart")!
  const _component_Card = _resolveComponent("Card")!
  const _component_Column = _resolveComponent("Column")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Card, null, {
      content: _withCtx(() => [
        _createVNode(_component_Dropdown, {
          "model-value": _ctx.yearSelected,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.yearSelected) = $event)),
          options: _ctx.yearOptions,
          style: {"min-width":"8rem"}
        }, null, 8, ["model-value", "options"]),
        _createVNode(_component_Button, {
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showChartData = true)),
          class: "ml-1"
        }, {
          default: _withCtx(() => _cache[4] || (_cache[4] = [
            _createTextVNode("View Data")
          ])),
          _: 1
        }),
        _createVNode(_component_Button, {
          style: {"float":"right"},
          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.fetchUsageData(true))),
          icon: "pi pi-refresh",
          label: "Refresh"
        }),
        (_ctx.loading)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _createVNode(_component_LoadingSpinner, { loading: "" })
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _cache[5] || (_cache[5] = _createElementVNode("span", { style: {"font-size":"1.5rem","font-weight":"400"} }, "History", -1)),
              _createVNode(_component_Chart, {
                type: "bar",
                data: _ctx.chartData,
                options: _ctx.chartOptions,
                style: {"min-height":"360px"}
              }, null, 8, ["data", "options"])
            ]))
      ]),
      _: 1
    }),
    _createVNode(_component_Dialog, {
      visible: _ctx.showChartData,
      "onUpdate:visible": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.showChartData) = $event)),
      modal: "",
      style: {"min-width":"480px"}
    }, {
      default: _withCtx(() => [
        _createVNode(_component_DataTable, {
          value: _ctx.chartHistory,
          rows: 10,
          paginator: "",
          sortField: _ctx.sortField,
          sortOrder: _ctx.sortOrder
        }, {
          default: _withCtx(() => [
            _createVNode(_component_Column, {
              field: "date",
              header: "Date",
              sortable: ""
            }, {
              body: _withCtx(({ data }) => [
                _createTextVNode(_toDisplayString((data.date as Date).toLocaleDateString()), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_Column, {
              field: "qty",
              header: "Quantity",
              sortable: "",
              "body-style": "text-align: right"
            })
          ]),
          _: 1
        }, 8, ["value", "sortField", "sortOrder"])
      ]),
      _: 1
    }, 8, ["visible"])
  ], 64))
}
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LoadingSpinner = _resolveComponent("LoadingSpinner")!
  const _component_Column = _resolveComponent("Column")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _component_ConfirmDialog = _resolveComponent("ConfirmDialog")!
  const _component_Card = _resolveComponent("Card")!

  return (_openBlock(), _createBlock(_component_Card, null, {
    content: _withCtx(() => [
      _createVNode(_component_LoadingSpinner, { loading: _ctx.loading }, null, 8, ["loading"]),
      (!_ctx.loading)
        ? (_openBlock(), _createBlock(_component_DataTable, {
            key: 0,
            value: _ctx.RMAs,
            scrollable: false,
            class: "p-datatable-sm",
            paginator: "",
            rows: 10,
            rowsPerPageOptions: [10, 20, 50]
          }, {
            empty: _withCtx(() => _cache[0] || (_cache[0] = [
              _createTextVNode(" No RMA's to display. ")
            ])),
            default: _withCtx(() => [
              _createVNode(_component_Column, {
                field: "rma_no",
                header: "ID",
                style: {"min-width":"6rem"}
              }),
              _createVNode(_component_Column, {
                field: "status",
                header: "Status",
                style: {"min-width":"6rem"}
              }, {
                body: _withCtx((slotProps) => [
                  _createTextVNode(_toDisplayString(_ctx.formatStatus(slotProps.data.status)), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_Column, {
                field: "date_assigned",
                header: "Date Assigned",
                style: {"min-width":"6rem"}
              }),
              _createVNode(_component_Column, {
                field: "type",
                header: "Type",
                style: {"min-width":"3rem"}
              })
            ]),
            _: 1
          }, 8, ["value"]))
        : _createCommentVNode("", true),
      _createVNode(_component_ConfirmDialog)
    ]),
    _: 1
  }))
}
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, toDisplayString as _toDisplayString, createBlock as _createBlock, createSlots as _createSlots, vModelText as _vModelText, withDirectives as _withDirectives, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "grid m-0 accounting-tab-content p-0 px-3" }
const _hoisted_2 = {
  key: 0,
  class: "grid p-0 m-0 justify-content-center md:justify-content-end pb-2"
}
const _hoisted_3 = { class: "col-12 md:col-5 lg:col-3 p-0 m-0 mr-1 flex justify-content-end" }
const _hoisted_4 = { class: "grid justify-content-center px-2 mt-2" }
const _hoisted_5 = { class: "col-12 lg:col-11 grid justify-content-center m-0 p-0 mt-1" }
const _hoisted_6 = { class: "col-12 sm:col-6 md:col-4 lg:col-4 xl:col-4 p-1 m-0 field" }
const _hoisted_7 = { class: "p-inputgroup" }
const _hoisted_8 = { class: "col-12 sm:col-6 md:col-4 lg:col-4 xl:col-4 p-1 m-0 field" }
const _hoisted_9 = { class: "p-inputgroup" }
const _hoisted_10 = { class: "col-12 sm:col-6 md:col-4 lg:col-4 xl:col-4 p-1 m-0" }
const _hoisted_11 = { class: "p-inputgroup" }
const _hoisted_12 = { class: "col-12 sm:col-6 md:col-4 lg:col-4 xl:col-4 p-1 m-0" }
const _hoisted_13 = { class: "p-inputgroup" }
const _hoisted_14 = { class: "col-12 md:col-8 lg:col-8 xl:col-8 p-1 m-0" }
const _hoisted_15 = { class: "p-inputgroup" }
const _hoisted_16 = { class: "grid justify-content-center p-0 m-0" }
const _hoisted_17 = { class: "col-12 lg:col-11 grid justify-content-center m-0 p-0" }
const _hoisted_18 = { class: "col-12 lg:col-4 grid p-0 pr-0 m-0 align-content-start" }
const _hoisted_19 = { class: "p-inputgroup" }
const _hoisted_20 = { class: "p-inputgroup" }
const _hoisted_21 = { class: "p-inputgroup" }
const _hoisted_22 = { class: "p-inputgroup" }
const _hoisted_23 = { class: "p-inputgroup" }
const _hoisted_24 = { class: "p-inputgroup" }
const _hoisted_25 = { class: "p-inputgroup" }
const _hoisted_26 = { class: "hidden sm:flex sm:col-6 lg:hidden p-1 m-0" }
const _hoisted_27 = { class: "p-inputgroup" }
const _hoisted_28 = { class: "col-12 lg:col-4 grid p-0 pr-0 md:pr-0 m-0 align-content-start" }
const _hoisted_29 = { class: "p-inputgroup" }
const _hoisted_30 = { class: "col-12 sm:hidden lg:flex lg:col-12 p-1 m-0" }
const _hoisted_31 = { class: "p-inputgroup" }
const _hoisted_32 = { class: "p-inputgroup" }
const _hoisted_33 = { class: "p-inputgroup" }
const _hoisted_34 = { class: "p-inputgroup" }
const _hoisted_35 = { class: "p-inputgroup" }
const _hoisted_36 = { class: "p-inputgroup" }
const _hoisted_37 = { class: "col-12 lg:col-4 grid p-0 pr-0 md:pr-0 lg:pr-0 m-0 align-content-start" }
const _hoisted_38 = { class: "p-inputgroup" }
const _hoisted_39 = { class: "p-inputgroup" }
const _hoisted_40 = { class: "p-inputgroup" }
const _hoisted_41 = { class: "p-inputgroup" }
const _hoisted_42 = { class: "grid justify-content-center p-0 m-0" }
const _hoisted_43 = { class: "col-12 lg:col-11 grid justify-content-center m-0 p-0" }
const _hoisted_44 = { class: "text-center" }
const _hoisted_45 = { class: "flex justify-content-between lg:justify-content-center" }
const _hoisted_46 = { class: "grid justify-content-center p-0 m-0" }
const _hoisted_47 = { class: "col-12 lg:col-11 grid m-0 p-0" }
const _hoisted_48 = { class: "col-12 lg:col-4 grid p-0 pr-0 m-0" }
const _hoisted_49 = { class: "p-inputgroup" }
const _hoisted_50 = { class: "p-inputgroup" }
const _hoisted_51 = { class: "table-header flex p-0 m-0 justify-content-end" }
const _hoisted_52 = { class: "col-12 grid justify-content-left m-0 p-0" }
const _hoisted_53 = { class: "col-12 grid lg:col-6 flex p-0 pr-0 m-0 align-content-start" }
const _hoisted_54 = { class: "col-12 p-0 lg:p-1 pt-0 pb-0 mt-0 mb-1" }
const _hoisted_55 = { class: "p-inputgroup" }
const _hoisted_56 = { class: "p-inputgroup" }
const _hoisted_57 = { class: "p-inputgroup" }
const _hoisted_58 = { class: "p-inputgroup" }
const _hoisted_59 = { class: "field-checkbox mb-0" }
const _hoisted_60 = { class: "p-inputgroup" }
const _hoisted_61 = { class: "col-12 grid lg:col-6 flex p-0 pr-0 m-0 align-content-start lg:pl-2 pt-2 lg:pt-0" }
const _hoisted_62 = { class: "table-header p-0 m-0 flex justify-content-between" }
const _hoisted_63 = { class: "hidden md:flex grid p-0 m-0" }
const _hoisted_64 = { class: "col-4 p-0" }
const _hoisted_65 = { class: "col-12 md:hidden grid p-0 m-0 justify-content-end" }
const _hoisted_66 = { class: "col-4 p-0" }
const _hoisted_67 = { class: "flex justify-content-end pr-0 sm:pr-5 lg:pr-5 xl:pr-7" }
const _hoisted_68 = { class: "field" }
const _hoisted_69 = { class: "field" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_VendorAutocomplete = _resolveComponent("VendorAutocomplete")!
  const _component_Divider = _resolveComponent("Divider")!
  const _component_Calendar = _resolveComponent("Calendar")!
  const _component_InputNumber = _resolveComponent("InputNumber")!
  const _component_AccordionTab = _resolveComponent("AccordionTab")!
  const _component_Column = _resolveComponent("Column")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _component_TextArea = _resolveComponent("TextArea")!
  const _component_CheckBox = _resolveComponent("CheckBox")!
  const _component_CustomAccordion = _resolveComponent("CustomAccordion")!
  const _component_Card = _resolveComponent("Card")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_Card, { class: "col-12 border-round-3xl tab-card-content p-0" }, {
        content: _withCtx(() => [
          (!_ctx.isReadOnly)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createElementVNode("div", _hoisted_3, [
                  _createVNode(_component_Button, {
                    label: "Save",
                    icon: "pi pi-save",
                    onClick: _ctx.handleSavePayable
                  }, null, 8, ["onClick"])
                ])
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("div", _hoisted_7, [
                  _cache[42] || (_cache[42] = _createElementVNode("small", { class: "p-inputgroup-addon medium-addon" }, "Payable ID", -1)),
                  _createVNode(_component_InputText, {
                    modelValue: _ctx.payable.ap_id,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.payable.ap_id) = $event)),
                    type: "text",
                    class: "text-sm",
                    disabled: true
                  }, null, 8, ["modelValue"])
                ])
              ]),
              _createElementVNode("div", _hoisted_8, [
                _createElementVNode("div", _hoisted_9, [
                  _cache[43] || (_cache[43] = _createElementVNode("small", { class: "p-inputgroup-addon medium-addon" }, "Co Code", -1)),
                  _createVNode(_component_Dropdown, {
                    modelValue: _ctx.payable.co_code,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.payable.co_code) = $event)),
                    options: _ctx.getCompanyCodes,
                    optionLabel: "name",
                    optionValue: "code",
                    disabled: _ctx.isReadOnly
                  }, null, 8, ["modelValue", "options", "disabled"])
                ])
              ]),
              _createElementVNode("div", _hoisted_10, [
                _createElementVNode("div", _hoisted_11, [
                  _cache[44] || (_cache[44] = _createElementVNode("small", { class: "p-inputgroup-addon medium-addon" }, "Batch #", -1)),
                  _createVNode(_component_InputText, {
                    modelValue: _ctx.payable.batch_number,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.payable.batch_number) = $event)),
                    type: "text",
                    class: "text-sm",
                    disabled: _ctx.isReadOnly
                  }, null, 8, ["modelValue", "disabled"])
                ])
              ]),
              _createElementVNode("div", _hoisted_12, [
                _createElementVNode("div", _hoisted_13, [
                  _cache[45] || (_cache[45] = _createElementVNode("small", { class: "p-inputgroup-addon medium-addon" }, "Vendor ID", -1)),
                  _createVNode(_component_VendorAutocomplete, {
                    selectedId: _ctx.payable.vendor,
                    modelValue: _ctx.payable.vendor,
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.payable.vendor) = $event)),
                    onItemSelect: _cache[4] || (_cache[4] = ($event: any) => (_ctx.handleVendorSelected($event))),
                    disabled: _ctx.isReadOnly
                  }, null, 8, ["selectedId", "modelValue", "disabled"])
                ])
              ]),
              _createElementVNode("div", _hoisted_14, [
                _createElementVNode("div", _hoisted_15, [
                  _createVNode(_component_InputText, {
                    modelValue: _ctx.payable.vendor_name,
                    "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.payable.vendor_name) = $event)),
                    type: "text",
                    class: "text-sm single-field",
                    disabled: true
                  }, null, 8, ["modelValue"])
                ])
              ])
            ])
          ]),
          _createVNode(_component_CustomAccordion, {
            activeIndex: _ctx.currentId.includes('New') ? [0, 1] : [0, 2]
          }, {
            "accordion-content": _withCtx(() => [
              _createVNode(_component_AccordionTab, { header: "Invoice Info" }, {
                header: _withCtx(() => [
                  _createVNode(_component_Divider, {
                    type: "dashed",
                    class: "ml-3"
                  })
                ]),
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_16, [
                    _createElementVNode("div", _hoisted_17, [
                      _createElementVNode("div", _hoisted_18, [
                        _createElementVNode("div", {
                          class: _normalizeClass({ [_ctx.getBaseDivClass()]: true })
                        }, [
                          _createElementVNode("div", _hoisted_19, [
                            _cache[46] || (_cache[46] = _createElementVNode("small", { class: "p-inputgroup-addon" }, "Invoice #", -1)),
                            _createVNode(_component_InputText, {
                              modelValue: _ctx.payable.invoice,
                              "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.payable.invoice) = $event)),
                              type: "text",
                              class: "text-sm",
                              disabled: _ctx.isReadOnly
                            }, null, 8, ["modelValue", "disabled"])
                          ])
                        ], 2),
                        _createElementVNode("div", {
                          class: _normalizeClass({ [_ctx.getBaseDivClass()]: true })
                        }, [
                          _createElementVNode("div", _hoisted_20, [
                            _cache[47] || (_cache[47] = _createElementVNode("small", { class: "p-inputgroup-addon" }, "Invoice Date", -1)),
                            _createVNode(_component_Calendar, {
                              modelValue: _ctx.payable.inv_date,
                              "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.payable.inv_date) = $event)),
                              disabled: _ctx.isReadOnly,
                              showIcon: true,
                              readonlyInput: true,
                              dateFormat: "mm-dd-yy"
                            }, null, 8, ["modelValue", "disabled"])
                          ])
                        ], 2),
                        _createElementVNode("div", {
                          class: _normalizeClass({ [_ctx.getBaseDivClass()]: true })
                        }, [
                          _createElementVNode("div", _hoisted_21, [
                            _cache[48] || (_cache[48] = _createElementVNode("small", { class: "p-inputgroup-addon" }, "Register Date", -1)),
                            _createVNode(_component_Calendar, {
                              modelValue: _ctx.reg_date,
                              "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.reg_date) = $event)),
                              disabled: _ctx.isReadOnly,
                              showIcon: true,
                              readonlyInput: true,
                              dateFormat: "mm-dd-yy"
                            }, null, 8, ["modelValue", "disabled"])
                          ])
                        ], 2),
                        _createElementVNode("div", {
                          class: _normalizeClass({ [_ctx.getBaseDivClass()]: true })
                        }, [
                          _createElementVNode("div", _hoisted_22, [
                            _cache[49] || (_cache[49] = _createElementVNode("small", { class: "p-inputgroup-addon" }, "Invoice Amount", -1)),
                            _createVNode(_component_InputNumber, {
                              modelValue: _ctx.payable.inv_amount,
                              "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.payable.inv_amount) = $event)),
                              type: "number",
                              class: "text-sm",
                              inputClass: "text-left",
                              mode: "currency",
                              currency: "USD",
                              locale: "en-US",
                              disabled: _ctx.isReadOnly
                            }, null, 8, ["modelValue", "disabled"])
                          ])
                        ], 2),
                        _createElementVNode("div", {
                          class: _normalizeClass({ [_ctx.getBaseDivClass()]: true })
                        }, [
                          _createElementVNode("div", _hoisted_23, [
                            _cache[50] || (_cache[50] = _createElementVNode("small", { class: "p-inputgroup-addon" }, "PO Number", -1)),
                            _createVNode(_component_InputText, {
                              modelValue: _ctx.payable.po,
                              "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.payable.po) = $event)),
                              type: "text",
                              class: "text-sm",
                              disabled: _ctx.isReadOnly
                            }, null, 8, ["modelValue", "disabled"])
                          ])
                        ], 2),
                        _createElementVNode("div", {
                          class: _normalizeClass({ [_ctx.getBaseDivClass()]: true })
                        }, [
                          _createElementVNode("div", _hoisted_24, [
                            _cache[51] || (_cache[51] = _createElementVNode("small", { class: "p-inputgroup-addon" }, "Packing Slip", -1)),
                            _createVNode(_component_InputText, {
                              modelValue: _ctx.payable.pack_slip,
                              "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.payable.pack_slip) = $event)),
                              type: "text",
                              class: "text-sm",
                              disabled: _ctx.isReadOnly
                            }, null, 8, ["modelValue", "disabled"])
                          ])
                        ], 2),
                        _createElementVNode("div", {
                          class: _normalizeClass({ [_ctx.getBaseDivClass()]: true })
                        }, [
                          _createElementVNode("div", _hoisted_25, [
                            _cache[52] || (_cache[52] = _createElementVNode("small", { class: "p-inputgroup-addon" }, "AP Account", -1)),
                            _createVNode(_component_InputText, {
                              modelValue: _ctx.payable.ap_acct,
                              "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.payable.ap_acct) = $event)),
                              type: "text",
                              class: "text-sm",
                              disabled: _ctx.isReadOnly
                            }, null, 8, ["modelValue", "disabled"])
                          ])
                        ], 2),
                        _createElementVNode("div", _hoisted_26, [
                          _createElementVNode("div", _hoisted_27, [
                            _cache[53] || (_cache[53] = _createElementVNode("small", { class: "p-inputgroup-addon" }, "Due Date", -1)),
                            _createVNode(_component_Calendar, {
                              modelValue: _ctx.payable.due_date,
                              "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.payable.due_date) = $event)),
                              disabled: _ctx.isReadOnly,
                              showIcon: true,
                              readonlyInput: true,
                              dateFormat: "mm-dd-yy"
                            }, null, 8, ["modelValue", "disabled"])
                          ])
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_28, [
                        _createElementVNode("div", {
                          class: _normalizeClass({ [_ctx.getBaseDivClass()]: true })
                        }, [
                          _createElementVNode("div", _hoisted_29, [
                            _cache[54] || (_cache[54] = _createElementVNode("small", { class: "p-inputgroup-addon" }, "Terms Code", -1)),
                            _createVNode(_component_Dropdown, {
                              modelValue: _ctx.payable.terms_code,
                              "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.payable.terms_code) = $event)),
                              optionLabel: "terms_id",
                              optionValue: "terms_id",
                              options: _ctx.terms,
                              filter: "",
                              showClear: 
                            _ctx.payable.terms_code && !_ctx.isReadOnly ? true : false
                          ,
                              disabled: _ctx.isReadOnly
                            }, null, 8, ["modelValue", "options", "showClear", "disabled"])
                          ])
                        ], 2),
                        _createElementVNode("div", _hoisted_30, [
                          _createElementVNode("div", _hoisted_31, [
                            _cache[55] || (_cache[55] = _createElementVNode("small", { class: "p-inputgroup-addon" }, "Due Date", -1)),
                            _createVNode(_component_Calendar, {
                              modelValue: _ctx.payable.due_date,
                              "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.payable.due_date) = $event)),
                              disabled: _ctx.isReadOnly,
                              showIcon: true,
                              readonlyInput: true,
                              dateFormat: "mm-dd-yy"
                            }, null, 8, ["modelValue", "disabled"])
                          ])
                        ]),
                        _createElementVNode("div", {
                          class: _normalizeClass({ [_ctx.getBaseDivClass()]: true })
                        }, [
                          _createElementVNode("div", _hoisted_32, [
                            _cache[56] || (_cache[56] = _createElementVNode("small", { class: "p-inputgroup-addon" }, "Disc Pct", -1)),
                            _createVNode(_component_InputNumber, {
                              modelValue: _ctx.payable.disc_pct,
                              "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.payable.disc_pct) = $event)),
                              type: "number",
                              class: "text-sm",
                              inputClass: "text-left",
                              suffix: "%",
                              onInput: _cache[17] || (_cache[17] = ($event: any) => (_ctx.handleDiscPctChange($event))),
                              disabled: _ctx.isReadOnly
                            }, null, 8, ["modelValue", "disabled"])
                          ])
                        ], 2),
                        _createElementVNode("div", {
                          class: _normalizeClass({ [_ctx.getBaseDivClass()]: true })
                        }, [
                          _createElementVNode("div", _hoisted_33, [
                            _cache[57] || (_cache[57] = _createElementVNode("small", { class: "p-inputgroup-addon" }, "Disc Amt", -1)),
                            _createVNode(_component_InputNumber, {
                              modelValue: _ctx.payable.disc_amount,
                              "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.payable.disc_amount) = $event)),
                              type: "number",
                              class: "text-sm",
                              inputClass: "text-left",
                              mode: "currency",
                              currency: "USD",
                              locale: "en-US",
                              disabled: _ctx.isReadOnly
                            }, null, 8, ["modelValue", "disabled"])
                          ])
                        ], 2),
                        _createElementVNode("div", {
                          class: _normalizeClass({ [_ctx.getBaseDivClass()]: true })
                        }, [
                          _createElementVNode("div", _hoisted_34, [
                            _cache[58] || (_cache[58] = _createElementVNode("small", { class: "p-inputgroup-addon" }, "Disc Acct", -1)),
                            _createVNode(_component_InputText, {
                              modelValue: _ctx.payable.disc_acct,
                              "onUpdate:modelValue": _cache[19] || (_cache[19] = ($event: any) => ((_ctx.payable.disc_acct) = $event)),
                              type: "text",
                              class: "text-sm",
                              disabled: _ctx.isReadOnly
                            }, null, 8, ["modelValue", "disabled"])
                          ])
                        ], 2),
                        _createElementVNode("div", {
                          class: _normalizeClass({ [_ctx.getBaseDivClass()]: true })
                        }, [
                          _createElementVNode("div", _hoisted_35, [
                            _cache[59] || (_cache[59] = _createElementVNode("small", { class: "p-inputgroup-addon" }, "Currency Code", -1)),
                            _createVNode(_component_Dropdown, {
                              modelValue: _ctx.currencyCode,
                              "onUpdate:modelValue": _cache[20] || (_cache[20] = ($event: any) => ((_ctx.currencyCode) = $event)),
                              optionLabel: "description",
                              options: _ctx.currencyCodesOptions,
                              filter: "",
                              showClear: 
                            _ctx.currencyCode && !_ctx.isReadOnly ? true : false
                          ,
                              disabled: _ctx.isReadOnly
                            }, null, 8, ["modelValue", "options", "showClear", "disabled"])
                          ])
                        ], 2),
                        _createElementVNode("div", {
                          class: _normalizeClass({ [_ctx.getBaseDivClass()]: true })
                        }, [
                          _createElementVNode("div", _hoisted_36, [
                            _cache[60] || (_cache[60] = _createElementVNode("small", { class: "p-inputgroup-addon" }, "Exchange Rate", -1)),
                            _createVNode(_component_InputText, {
                              modelValue: _ctx.currencyCode.exchange_rate,
                              "onUpdate:modelValue": _cache[21] || (_cache[21] = ($event: any) => ((_ctx.currencyCode.exchange_rate) = $event)),
                              class: "text-sm",
                              inputClass: "text-left",
                              disabled: true
                            }, null, 8, ["modelValue"])
                          ])
                        ], 2)
                      ]),
                      _createElementVNode("div", _hoisted_37, [
                        _createElementVNode("div", {
                          class: _normalizeClass({ [_ctx.getBaseDivClass()]: true })
                        }, [
                          _createElementVNode("div", _hoisted_38, [
                            _cache[61] || (_cache[61] = _createElementVNode("small", { class: "p-inputgroup-addon" }, "Status", -1)),
                            _createVNode(_component_Dropdown, {
                              modelValue: _ctx.payable.status,
                              "onUpdate:modelValue": _cache[22] || (_cache[22] = ($event: any) => ((_ctx.payable.status) = $event)),
                              optionLabel: "status",
                              optionValue: "initial",
                              options: _ctx.status,
                              filter: "",
                              showClear: 
                            _ctx.payable.status && !_ctx.isReadOnly ? true : false
                          ,
                              disabled: _ctx.isReadOnly
                            }, null, 8, ["modelValue", "options", "showClear", "disabled"])
                          ])
                        ], 2),
                        _createElementVNode("div", {
                          class: _normalizeClass({ [_ctx.getBaseDivClass()]: true })
                        }, [
                          _createElementVNode("div", _hoisted_39, [
                            _cache[62] || (_cache[62] = _createElementVNode("small", { class: "p-inputgroup-addon" }, "Balance Due", -1)),
                            _createVNode(_component_InputNumber, {
                              modelValue: _ctx.payable.balance,
                              "onUpdate:modelValue": _cache[23] || (_cache[23] = ($event: any) => ((_ctx.payable.balance) = $event)),
                              type: "number",
                              class: "text-sm",
                              inputClass: "text-left",
                              mode: "currency",
                              currency: "USD",
                              locale: "en-US",
                              disabled: true
                            }, null, 8, ["modelValue"])
                          ])
                        ], 2),
                        _createElementVNode("div", {
                          class: _normalizeClass({ [_ctx.getBaseDivClass()]: true })
                        }, [
                          _createElementVNode("div", _hoisted_40, [
                            _cache[63] || (_cache[63] = _createElementVNode("small", { class: "p-inputgroup-addon" }, "Amount To Pay", -1)),
                            _createVNode(_component_InputNumber, {
                              modelValue: _ctx.payable.amt_to_pay,
                              "onUpdate:modelValue": _cache[24] || (_cache[24] = ($event: any) => ((_ctx.payable.amt_to_pay) = $event)),
                              type: "number",
                              class: "text-sm",
                              inputClass: "text-left",
                              mode: "currency",
                              currency: "USD",
                              locale: "en-US",
                              onInput: _cache[25] || (_cache[25] = ($event: any) => (_ctx.handleAmountToPayChange($event))),
                              disabled: _ctx.isReadOnly
                            }, null, 8, ["modelValue", "disabled"])
                          ])
                        ], 2),
                        _createElementVNode("div", {
                          class: _normalizeClass({ [_ctx.getBaseDivClass()]: true })
                        }, [
                          _createElementVNode("div", _hoisted_41, [
                            _cache[64] || (_cache[64] = _createElementVNode("small", { class: "p-inputgroup-addon" }, "Date To Pay", -1)),
                            _createVNode(_component_Calendar, {
                              modelValue: _ctx.payable.date_to_pay,
                              "onUpdate:modelValue": _cache[26] || (_cache[26] = ($event: any) => ((_ctx.payable.date_to_pay) = $event)),
                              disabled: _ctx.isReadOnly,
                              showIcon: true,
                              readonlyInput: true,
                              dateFormat: "mm-dd-yy"
                            }, null, 8, ["modelValue", "disabled"])
                          ])
                        ], 2)
                      ])
                    ])
                  ])
                ]),
                _: 1
              }),
              (!_ctx.currentId.includes('New'))
                ? (_openBlock(), _createBlock(_component_AccordionTab, {
                    key: 0,
                    header: "Line Items"
                  }, {
                    header: _withCtx(() => [
                      _createVNode(_component_Divider, {
                        type: "dashed",
                        class: "ml-3"
                      })
                    ]),
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_42, [
                        _createElementVNode("div", _hoisted_43, [
                          _createVNode(_component_DataTable, {
                            value: _ctx.payable.li_items,
                            dataKey: "li",
                            class: "p-datatable-sm w-full bordered-table line-items-table",
                            "paginator-template": "FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown",
                            rows: _ctx.itemsPerPage,
                            rowsPerPageOptions: [5, 10, 20],
                            paginator: true,
                            removableSort: "",
                            breakpoint: "768px",
                            responsiveLayout: "stack"
                          }, {
                            empty: _withCtx(() => _cache[65] || (_cache[65] = [
                              _createElementVNode("div", { class: "text-center" }, "No Line Items Found.", -1)
                            ])),
                            default: _withCtx(() => [
                              _createVNode(_component_Column, {
                                field: "li",
                                header: "Line Item",
                                "body-class": "text-center"
                              }),
                              _createVNode(_component_Column, {
                                field: "part",
                                header: "Part",
                                "body-class": "text-center"
                              }),
                              _createVNode(_component_Column, { header: "Description" }, {
                                body: _withCtx(({ data }) => [
                                  _createElementVNode("div", _hoisted_44, _toDisplayString(_ctx.getDescription(data.description_items)), 1)
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_Column, {
                                field: "um",
                                header: "UM",
                                bodyClass: "text-center"
                              }),
                              _createVNode(_component_Column, {
                                field: "rec_qty",
                                header: "Qty",
                                bodyClass: "text-center"
                              }),
                              _createVNode(_component_Column, {
                                header: "Price",
                                headerClass: "text-right right-header",
                                bodyClass: "text-right"
                              }, {
                                body: _withCtx(({ data }) => [
                                  _createElementVNode("span", null, _toDisplayString(_ctx.formatPrice(data.actual_cost)), 1)
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_Column, {
                                header: "Amount",
                                headerClass: "text-right right-header",
                                bodyClass: "text-right"
                              }, {
                                body: _withCtx(({ data }) => [
                                  _createElementVNode("span", null, _toDisplayString(_ctx.formatPrice(data.amount_items[0].amount ?? "0")), 1)
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_Column, null, {
                                body: _withCtx(({ data }) => [
                                  _createElementVNode("div", _hoisted_45, [
                                    _createVNode(_component_Button, {
                                      icon: "pi pi-search",
                                      title: 'Open Line Item ' + data.li,
                                      class: "p-button-sm py-1 px-2",
                                      onClick: ($event: any) => (_ctx.handleLineItemClick(data, _ctx.payable.ap_id))
                                    }, null, 8, ["title", "onClick"])
                                  ])
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }, 8, ["value", "rows"])
                        ])
                      ])
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              _createVNode(_component_AccordionTab, { header: "Freight Info" }, {
                header: _withCtx(() => [
                  _createVNode(_component_Divider, {
                    type: "dashed",
                    class: "ml-3"
                  })
                ]),
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_46, [
                    _createElementVNode("div", _hoisted_47, [
                      _createElementVNode("div", _hoisted_48, [
                        _createElementVNode("div", {
                          class: _normalizeClass({
                        [_ctx.getBaseDivClass()]: true,
                        [_ctx.getTwoColumnDivClass()]: false,
                        'sm:pr-1 lg:pl-1 lg:pb-1 lg:pt-0': true,
                      })
                        }, [
                          _createElementVNode("div", _hoisted_49, [
                            _cache[66] || (_cache[66] = _createElementVNode("small", { class: "p-inputgroup-addon" }, "Freight Amount", -1)),
                            _createVNode(_component_InputNumber, {
                              modelValue: _ctx.payable.freight_amt,
                              "onUpdate:modelValue": _cache[27] || (_cache[27] = ($event: any) => ((_ctx.payable.freight_amt) = $event)),
                              type: "number",
                              class: "text-sm",
                              inputClass: "text-left",
                              mode: "currency",
                              currency: "USD",
                              locale: "en-US",
                              disabled: _ctx.isReadOnly
                            }, null, 8, ["modelValue", "disabled"])
                          ])
                        ], 2),
                        _createElementVNode("div", {
                          class: _normalizeClass({
                        [_ctx.getBaseDivClass()]: true,
                        [_ctx.getTwoColumnDivClass()]: false,
                        'sm:pl-1 lg:pr-1 xl:pt-0': true,
                      })
                        }, [
                          _createElementVNode("div", _hoisted_50, [
                            _cache[67] || (_cache[67] = _createElementVNode("small", { class: "p-inputgroup-addon" }, "Freight Account", -1)),
                            _createVNode(_component_InputText, {
                              modelValue: _ctx.payable.freight_acct,
                              "onUpdate:modelValue": _cache[28] || (_cache[28] = ($event: any) => ((_ctx.payable.freight_acct) = $event)),
                              class: "text-sm",
                              inputClass: "text-left",
                              disabled: true
                            }, null, 8, ["modelValue"])
                          ])
                        ], 2)
                      ]),
                      _createElementVNode("div", {
                        class: _normalizeClass({ [_ctx.getFreightDivClass()]: true })
                      }, [
                        _createVNode(_component_DataTable, {
                          value: _ctx.freightReceipts,
                          dataKey: "id",
                          paginator: _ctx.freightReceipts.length > 3,
                          rows: 3,
                          "paginator-template": "PrevPageLink CurrentPageReport NextPageLink",
                          class: "p-datatable-sm w-full bordered-table with-header",
                          removableSort: ""
                        }, {
                          empty: _withCtx(() => _cache[68] || (_cache[68] = [
                            _createElementVNode("div", { class: "text-center" }, " No Freight Receipts Found. ", -1)
                          ])),
                          header: _withCtx(() => [
                            _createElementVNode("div", _hoisted_51, [
                              _createVNode(_component_Button, {
                                label: "Add",
                                icon: "pi pi-plus",
                                class: "p-button-sm py-1 px-2",
                                disabled: _ctx.isReadOnly,
                                onClick: _cache[29] || (_cache[29] = ($event: any) => (_ctx.handleShowFreightReceipt()))
                              }, null, 8, ["disabled"])
                            ])
                          ]),
                          default: _withCtx(() => [
                            _createVNode(_component_Column, {
                              field: "freightReceipt",
                              header: "Freight Receipts",
                              sortable: true,
                              "body-class": "text-center"
                            })
                          ]),
                          _: 1
                        }, 8, ["value", "paginator"])
                      ], 2)
                    ])
                  ])
                ]),
                _: 1
              }),
              _createVNode(_component_AccordionTab, { header: "Additional Information" }, {
                header: _withCtx(() => [
                  _createVNode(_component_Divider, {
                    type: "dashed",
                    class: "ml-3"
                  })
                ]),
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_52, [
                    _createElementVNode("div", _hoisted_53, [
                      _createElementVNode("div", _hoisted_54, [
                        _cache[69] || (_cache[69] = _createElementVNode("div", { class: "p-inputgroup mb-1 pl-2 font-semibold" }, " Notes ", -1)),
                        _createVNode(_component_TextArea, {
                          rows: "8",
                          value: _ctx.payable.notes,
                          placeholder: "Enter a note here...",
                          class: "textarea mt-2 text-sm single-field text-left",
                          disabled: _ctx.isReadOnly
                        }, null, 8, ["value", "disabled"])
                      ]),
                      _cache[76] || (_cache[76] = _createElementVNode("div", { class: "p-inputgroup mb-1 pl-2 font-semibold" }, " Sales Tax ", -1)),
                      _createElementVNode("div", {
                        class: _normalizeClass({
                      [_ctx.getBaseDivClass()]: true,
                      [_ctx.getTwoColumnDivClass()]: true,
                      'sm:pr-1 lg:pl-1': true,
                    })
                      }, [
                        _createElementVNode("div", _hoisted_55, [
                          _cache[70] || (_cache[70] = _createElementVNode("small", { class: "p-inputgroup-addon" }, "Sale Tax Amt", -1)),
                          _createVNode(_component_InputNumber, {
                            modelValue: _ctx.payable.sale_tax_amt,
                            "onUpdate:modelValue": _cache[30] || (_cache[30] = ($event: any) => ((_ctx.payable.sale_tax_amt) = $event)),
                            type: "number",
                            class: "text-sm",
                            inputClass: "text-left",
                            mode: "currency",
                            currency: "USD",
                            locale: "en-US",
                            disabled: _ctx.isReadOnly
                          }, null, 8, ["modelValue", "disabled"])
                        ])
                      ], 2),
                      _createElementVNode("div", {
                        class: _normalizeClass({
                      [_ctx.getBaseDivClass()]: true,
                      [_ctx.getTwoColumnDivClass()]: true,
                      'sm:pl-1 lg:pr-1': true,
                    })
                      }, [
                        _createElementVNode("div", _hoisted_56, [
                          _cache[71] || (_cache[71] = _createElementVNode("small", { class: "p-inputgroup-addon" }, "Sale Tax Acct", -1)),
                          _createVNode(_component_InputText, {
                            modelValue: _ctx.payable.sale_tax_acct,
                            "onUpdate:modelValue": _cache[31] || (_cache[31] = ($event: any) => ((_ctx.payable.sale_tax_acct) = $event)),
                            class: "text-sm",
                            inputClass: "text-left",
                            disabled: true
                          }, null, 8, ["modelValue"])
                        ])
                      ], 2),
                      _cache[77] || (_cache[77] = _createElementVNode("div", { class: "p-inputgroup mb-1 pl-2 font-semibold" }, "Hold", -1)),
                      _createElementVNode("div", {
                        class: _normalizeClass({
                      [_ctx.getBaseDivClass()]: true,
                      [_ctx.getTwoColumnDivClass()]: true,
                      'sm:pr-1 lg:pl-1': true,
                    })
                      }, [
                        _createElementVNode("div", _hoisted_57, [
                          _cache[72] || (_cache[72] = _createElementVNode("small", { class: "p-inputgroup-addon" }, "Hold Flag", -1)),
                          _createVNode(_component_InputText, {
                            modelValue: _ctx.payable.hold,
                            "onUpdate:modelValue": _cache[32] || (_cache[32] = ($event: any) => ((_ctx.payable.hold) = $event)),
                            class: "text-sm",
                            inputClass: "text-left",
                            disabled: true
                          }, null, 8, ["modelValue"])
                        ])
                      ], 2),
                      _createElementVNode("div", {
                        class: _normalizeClass({
                      [_ctx.getBaseDivClass()]: true,
                      [_ctx.getTwoColumnDivClass()]: true,
                      'sm:pl-1 lg:pr-1': true,
                    })
                      }, [
                        _createElementVNode("div", _hoisted_58, [
                          _cache[73] || (_cache[73] = _createElementVNode("small", { class: "p-inputgroup-addon" }, "Hold Date", -1)),
                          _createVNode(_component_Calendar, {
                            modelValue: _ctx.payable.hold_date,
                            "onUpdate:modelValue": _cache[33] || (_cache[33] = ($event: any) => ((_ctx.payable.hold_date) = $event)),
                            disabled: _ctx.isReadOnly,
                            showIcon: true,
                            readonlyInput: true,
                            dateFormat: "mm-dd-yy"
                          }, null, 8, ["modelValue", "disabled"])
                        ])
                      ], 2),
                      _cache[78] || (_cache[78] = _createElementVNode("div", { class: "p-inputgroup mb-1 pl-2 font-semibold" }, " 1099 Info ", -1)),
                      _createElementVNode("div", {
                        class: _normalizeClass({
                      [_ctx.getBaseDivClass()]: true,
                      [_ctx.getTwoColumnDivClass()]: true,
                      'sm:pr-1 lg:pl-1': true,
                    })
                      }, [
                        _createElementVNode("div", _hoisted_59, [
                          _cache[74] || (_cache[74] = _createElementVNode("small", {
                            for: "flag-1099",
                            class: "p-inputgroup-addon text-sm mr-3 single-addon text-left"
                          }, "1099 Flag", -1)),
                          _createVNode(_component_CheckBox, {
                            id: "flag-1099",
                            modelValue: _ctx.payable['1099'],
                            "onUpdate:modelValue": _cache[34] || (_cache[34] = ($event: any) => ((_ctx.payable['1099']) = $event)),
                            name: "Flag 1099",
                            trueValue: "Y",
                            falseValue: null,
                            binary: true,
                            class: "field-checkbox pl-2 pt-3 w-full",
                            disabled: _ctx.isReadOnly
                          }, null, 8, ["modelValue", "disabled"])
                        ])
                      ], 2),
                      _createElementVNode("div", {
                        class: _normalizeClass({
                      [_ctx.getBaseDivClass()]: true,
                      [_ctx.getTwoColumnDivClass()]: true,
                      'sm:pl-1 lg:pr-1': true,
                    })
                      }, [
                        _createElementVNode("div", _hoisted_60, [
                          _cache[75] || (_cache[75] = _createElementVNode("small", { class: "p-inputgroup-addon" }, "1099 Box", -1)),
                          _createVNode(_component_InputText, {
                            modelValue: _ctx.payable['1099_box'],
                            "onUpdate:modelValue": _cache[35] || (_cache[35] = ($event: any) => ((_ctx.payable['1099_box']) = $event)),
                            class: "text-sm",
                            inputClass: "text-left",
                            disabled: true
                          }, null, 8, ["modelValue"])
                        ])
                      ], 2)
                    ]),
                    _createElementVNode("div", _hoisted_61, [
                      _createVNode(_component_DataTable, {
                        value: _ctx.miscCharges,
                        dataKey: "misc_acct",
                        class: _normalizeClass(["p-datatable-sm w-full bordered-table with-header", {
                      'with-footer': _ctx.miscCharges && _ctx.miscCharges.length > 0,
                    }]),
                        "paginator-template": "FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown",
                        rows: 5,
                        paginator: true,
                        removableSort: "",
                        breakpoint: "600px",
                        responsiveLayout: "stack"
                      }, _createSlots({
                        empty: _withCtx(() => [
                          _cache[79] || (_cache[79] = _createElementVNode("div", { class: "text-center" }, "No Charges Found.", -1))
                        ]),
                        header: _withCtx(() => [
                          _createElementVNode("div", _hoisted_62, [
                            _cache[80] || (_cache[80] = _createElementVNode("div", null, null, -1)),
                            _cache[81] || (_cache[81] = _createElementVNode("h3", { class: "p-0 m-0 text-center inline-flex" }, " Miscellaneous Charges ", -1)),
                            _createVNode(_component_Button, {
                              label: "Add",
                              icon: "pi pi-plus",
                              class: "p-button-sm py-1 px-2",
                              disabled: _ctx.isReadOnly,
                              onClick: _ctx.handleShowMiscDialog
                            }, null, 8, ["disabled", "onClick"])
                          ])
                        ]),
                        default: _withCtx(() => [
                          _createVNode(_component_Column, {
                            field: "miscAmount",
                            header: "Amount",
                            sortable: true,
                            "header-style": "min-width: 130px !important;",
                            "body-style": "min-width: 130px !important",
                            bodyClass: "text-right misc-amount"
                          }, {
                            body: _withCtx(({ data }) => [
                              _createElementVNode("span", null, _toDisplayString(_ctx.formatPrice(data.miscAmount)), 1)
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_Column, {
                            field: "miscAccount",
                            header: "Account",
                            "header-style": "min-width: 130px !important;",
                            "body-style": "min-width: 130px !important;",
                            sortable: true,
                            "body-class": "text-center"
                          }),
                          _createVNode(_component_Column, {
                            field: "miscDescription",
                            header: "Description",
                            sortable: true
                          })
                        ]),
                        _: 2
                      }, [
                        (this.miscCharges && this.miscCharges.length > 0)
                          ? {
                              name: "footer",
                              fn: _withCtx(() => [
                                _createElementVNode("div", _hoisted_63, [
                                  _createElementVNode("div", _hoisted_64, _toDisplayString(_ctx.computedMiscTotal), 1),
                                  _cache[82] || (_cache[82] = _createElementVNode("div", { class: "col-3 p-0 pl-3 text-left" }, "Total", -1))
                                ]),
                                _createElementVNode("div", _hoisted_65, [
                                  _cache[83] || (_cache[83] = _createElementVNode("div", { class: "col-3 p-0 pl-3 text-left" }, "Total", -1)),
                                  _createElementVNode("div", _hoisted_66, _toDisplayString(_ctx.computedMiscTotal), 1)
                                ])
                              ]),
                              key: "0"
                            }
                          : undefined
                      ]), 1032, ["value", "class"]),
                      _createVNode(_component_DataTable, {
                        value: _ctx.payable.check_items,
                        dataKey: "misc_acct",
                        class: "p-datatable-sm w-full bordered-table with-header pt-4 lg:pt-5",
                        "paginator-template": "FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown",
                        rows: 5,
                        paginator: true,
                        removableSort: "",
                        breakpoint: "600px",
                        responsiveLayout: "stack"
                      }, {
                        empty: _withCtx(() => _cache[84] || (_cache[84] = [
                          _createElementVNode("div", { class: "text-center" }, "No Checks Found.", -1)
                        ])),
                        header: _withCtx(() => _cache[85] || (_cache[85] = [
                          _createElementVNode("div", { class: "table-header p-0 m-0 flex justify-content-center" }, [
                            _createElementVNode("h3", { class: "p-0 m-0 text-center inline-flex" }, "Checks")
                          ], -1)
                        ])),
                        default: _withCtx(() => [
                          _createVNode(_component_Column, {
                            field: "check",
                            header: "Check",
                            "header-style": "min-width: 130px !important;",
                            "body-style": "min-width: 130px !important;",
                            sortable: true,
                            "body-class": "text-center"
                          }),
                          _createVNode(_component_Column, {
                            field: "chk_date",
                            header: "Date",
                            sortable: true
                          }),
                          _createVNode(_component_Column, {
                            field: "check_amt",
                            header: "Amount",
                            sortable: true,
                            "header-style": "min-width: 130px !important;",
                            "body-style": "min-width: 130px !important",
                            bodyClass: "justify-content-left md:justify-content-center"
                          }, {
                            body: _withCtx(({ data }) => [
                              _createElementVNode("div", _hoisted_67, [
                                _createElementVNode("span", null, _toDisplayString(_ctx.formatPrice(data.check_amt)), 1)
                              ])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }, 8, ["value"])
                    ])
                  ])
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["activeIndex"])
        ]),
        _: 1
      })
    ]),
    _createVNode(_component_Dialog, {
      visible: _ctx.showFreightDialog,
      "onUpdate:visible": _cache[37] || (_cache[37] = ($event: any) => ((_ctx.showFreightDialog) = $event)),
      style: { width: '450px' },
      modal: true,
      class: "p-fluid"
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_68, [
          _withDirectives(_createElementVNode("input", {
            "onUpdate:modelValue": _cache[36] || (_cache[36] = ($event: any) => ((_ctx.freightReceipt) = $event)),
            type: "text",
            class: "p-inputtext p-component w-full",
            placeholder: "Enter Freight Receipt"
          }, null, 512), [
            [_vModelText, _ctx.freightReceipt]
          ]),
          _createVNode(_component_Button, {
            label: "Add",
            icon: "pi pi-save",
            style: {"margin-top":"5px"},
            onClick: _ctx.addFreightReceipt
          }, null, 8, ["onClick"])
        ])
      ]),
      _: 1
    }, 8, ["visible"]),
    _createVNode(_component_Dialog, {
      visible: _ctx.showMiscDialog,
      "onUpdate:visible": _cache[41] || (_cache[41] = ($event: any) => ((_ctx.showMiscDialog) = $event)),
      style: { width: '450px' },
      modal: true,
      class: "p-fluid"
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_69, [
          _withDirectives(_createElementVNode("input", {
            "onUpdate:modelValue": _cache[38] || (_cache[38] = ($event: any) => ((_ctx.miscAmount) = $event)),
            type: "text",
            class: "p-inputtext p-component w-full mb-2",
            placeholder: "Enter Miscellaneous Amount"
          }, null, 512), [
            [_vModelText, _ctx.miscAmount]
          ]),
          _withDirectives(_createElementVNode("input", {
            "onUpdate:modelValue": _cache[39] || (_cache[39] = ($event: any) => ((_ctx.miscAccount) = $event)),
            type: "text",
            class: "p-inputtext p-component w-full mb-2",
            placeholder: "Enter Miscellaneous Account"
          }, null, 512), [
            [_vModelText, _ctx.miscAccount]
          ]),
          _withDirectives(_createElementVNode("input", {
            "onUpdate:modelValue": _cache[40] || (_cache[40] = ($event: any) => ((_ctx.miscDescription) = $event)),
            type: "text",
            class: "p-inputtext p-component w-full mb-2",
            placeholder: "Enter Miscellaneous Description"
          }, null, 512), [
            [_vModelText, _ctx.miscDescription]
          ]),
          _createVNode(_component_Button, {
            label: "Add",
            icon: "pi pi-save",
            style: {"margin-top":"5px"},
            onClick: _ctx.addMiscCharge
          }, null, 8, ["onClick"])
        ])
      ]),
      _: 1
    }, 8, ["visible"])
  ], 64))
}
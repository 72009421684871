
import { defineComponent } from "vue";
import { mapActions, mapGetters, mapState } from "vuex";
import store from "@/store";
import DataTable from "primevue/datatable";
import Card from "primevue/card";
import Column from "primevue/column";
import ConfirmDialog from "primevue/confirmdialog";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import RMA from "@/types/rma";

export default defineComponent({
  name: "RMA",
  props: {
    index: Number,
  },
  components: {
    DataTable,
    Column,
    ConfirmDialog,
    LoadingSpinner,
    Card,
  },
  created() {
    if (this.getRMAs(this.index) == null) {
      this.loading = true;
      this.fetchRMAs({
        Client: store.getters["session/getClient"],
        index: this.index,
        custId: this.getCustomer(this.index).cust_id,
      })
        .then(() => {
          this.RMAs = this.getRMAs(this.index) as Array<RMA>;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
        });
    } else {
      this.RMAs = this.getRMAs(this.index) as Array<RMA>;
    }
  },
  computed: {
    ...mapState(["customerInquiry", "notification"]),
    ...mapGetters({
      getRMAs: "customerInquiry/getRMAs",
      getCustomer: "customerInquiry/getCustomer",
    }),
  },
  data() {
    return {
      RMAs: [] as Array<RMA>,
      loading: false,
    };
  },
  methods: {
    ...mapActions({ fetchRMAs: "customerInquiry/getRMAs" }),
    confirmDelete(item: string) {
      this.$confirm.require({
        message: `Are you sure you want to delete item ${item}?`,
        header: "Delete Confirmation",
        icon: "pi pi-info-circle",
        acceptClass: "p-button-danger",
        accept: () => {
          this.deleteItem(item);
        },
      });
    },
    formatStatus(status: string) {
      return status === "C" ? "Closed" : "Open";
    },
    deleteItem(item: any) {
      //console.log("DELETED ITEM: ", item);
    },
  },
});


import { defineComponent } from "vue";
import { mapGetters } from "vuex";
import TextChart from "./chart/TextChart.vue";

export default defineComponent({
  components: {
    TextChart,
  },
  props: {
    items: {
      type: Array as () => Array<any>,
      required: true,
    },
  },
  data() {
    return {
      activeIndex: 0,
      invoicesLoading: false,
      billsLoading: false,
      ordersLoading: false,
    };
  },
  computed: {
    ...mapGetters({
      ordersMonthlyData: "sales/getSoMonthlyData",
      invoicesMonthlyData: "invoice/getMonthlyData",
      ticketsMonthlyData: "billing/getMonthlyData",
      getWebAccess: "session/getWebAccess",
    }),
  },
  methods: {
    handleTabChange(e: any) {
      //this.setActiveTab(e);
    },
  },
});

import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withKeys as _withKeys, normalizeClass as _normalizeClass, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "grid flex justify-content-center mt-2" }
const _hoisted_2 = { class: "col-11 lg:col-11" }
const _hoisted_3 = { class: "grid align-items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputEmployeeID = _resolveComponent("InputEmployeeID")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Button = _resolveComponent("Button")!
  const _component_MessageBox = _resolveComponent("MessageBox")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_InputEmployeeID, {
            ref: "employee_id",
            employeeId: _ctx.startJobPayload.employee_id,
            "onUpdate:employeeId": _cache[0] || (_cache[0] = ($event: any) => (_ctx.startJobPayload.employee_id = $event)),
            onFetchEmployee: _ctx.handleFetchEmployee,
            nextInput: "function_input",
            handleErrorAndFocus: _ctx.handleErrorAndFocus,
            focusInput: _ctx.focusInput,
            rowClass: _ctx.rowClass
          }, null, 8, ["employeeId", "onFetchEmployee", "handleErrorAndFocus", "focusInput", "rowClass"]),
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.rowClass)
          }, [
            _cache[18] || (_cache[18] = _createElementVNode("span", { class: "p-inputgroup-addon" }, " Function ", -1)),
            _createVNode(_component_InputText, {
              ref: "function_input",
              class: "scan-input-text",
              modelValue: _ctx.startJobPayload.function_input,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.startJobPayload.function_input) = $event)),
              onFocus: _cache[2] || (_cache[2] = ($event: any) => ($event.target.select())),
              onKeypress: _withKeys(_ctx.handleFunction, ["enter"]),
              onKeydown: [
                _withKeys(_ctx.handleFunction, ["down"]),
                _cache[3] || (_cache[3] = _withKeys(($event: any) => (_ctx.focusInput('employee_id')), ["up"]))
              ]
            }, null, 8, ["modelValue", "onKeypress", "onKeydown"])
          ], 2),
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.rowClass)
          }, [
            _cache[19] || (_cache[19] = _createElementVNode("span", { class: "p-inputgroup-addon" }, " Function Desc ", -1)),
            _createVNode(_component_InputText, {
              ref: "functionDesc",
              class: "scan-input-text",
              modelValue: _ctx.functionDesc,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.functionDesc) = $event)),
              onFocus: _cache[5] || (_cache[5] = ($event: any) => ($event.target.select())),
              readonly: "",
              onKeypress: _cache[6] || (_cache[6] = _withKeys(($event: any) => (_ctx.focusInput('work_order')), ["enter"])),
              onKeydown: [
                _cache[7] || (_cache[7] = _withKeys(($event: any) => (_ctx.focusInput('work_order')), ["down"])),
                _cache[8] || (_cache[8] = _withKeys(($event: any) => (_ctx.focusInput('function_input')), ["up"]))
              ]
            }, null, 8, ["modelValue"])
          ], 2),
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.rowClass)
          }, [
            _cache[20] || (_cache[20] = _createElementVNode("span", { class: "p-inputgroup-addon" }, " Work Order ID ", -1)),
            _createVNode(_component_InputText, {
              ref: "work_order",
              class: "scan-input-text",
              modelValue: _ctx.startJobPayload.work_order,
              "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.startJobPayload.work_order) = $event)),
              onFocus: _cache[10] || (_cache[10] = ($event: any) => ($event.target.select())),
              onKeypress: _withKeys(_ctx.handleWorkOrder, ["enter"]),
              onKeydown: [
                _withKeys(_ctx.handleWorkOrder, ["down"]),
                _cache[11] || (_cache[11] = _withKeys(($event: any) => (_ctx.focusInput('functionDesc')), ["up"]))
              ]
            }, null, 8, ["modelValue", "onKeypress", "onKeydown"])
          ], 2),
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.rowClass)
          }, [
            _cache[21] || (_cache[21] = _createElementVNode("span", { class: "p-inputgroup-addon" }, " Operation ", -1)),
            _createVNode(_component_InputText, {
              ref: "operation",
              class: "scan-input-text",
              modelValue: _ctx.startJobPayload.operation,
              "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.startJobPayload.operation) = $event)),
              onFocus: _cache[13] || (_cache[13] = ($event: any) => ($event.target.select())),
              onKeypress: _withKeys(_ctx.handleOperation, ["enter"]),
              onKeydown: [
                _withKeys(_ctx.handleOperation, ["down"]),
                _cache[14] || (_cache[14] = _withKeys(($event: any) => (_ctx.focusInput('work_order')), ["up"]))
              ]
            }, null, 8, ["modelValue", "onKeypress", "onKeydown"])
          ], 2),
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.rowClass)
          }, [
            _cache[22] || (_cache[22] = _createElementVNode("span", { class: "p-inputgroup-addon" }, " Work Center ", -1)),
            _createVNode(_component_InputText, {
              ref: "work_center",
              class: "scan-input-text",
              modelValue: _ctx.work_center,
              "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.work_center) = $event)),
              onFocus: _cache[16] || (_cache[16] = ($event: any) => ($event.target.select())),
              onKeydown: _cache[17] || (_cache[17] = _withKeys(($event: any) => (_ctx.focusInput('operation')), ["up"])),
              readonly: ""
            }, null, 8, ["modelValue"])
          ], 2),
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.rowClass)
          }, [
            _createVNode(_component_Button, {
              label: "Start Job",
              loading: _ctx.loadStartJob,
              onClick: _ctx.startJob,
              class: "scan-input-button"
            }, null, 8, ["loading", "onClick"])
          ], 2)
        ])
      ])
    ]),
    _createVNode(_component_MessageBox, {
      showDialog: _ctx.showErrorDialog,
      icon: "INFO",
      header: "Error",
      message: _ctx.errorMessage,
      primaryButton: "OK",
      onPrimaryButtonClick: _ctx.clickConfirmErrorDialog
    }, null, 8, ["showDialog", "message", "onPrimaryButtonClick"])
  ], 64))
}
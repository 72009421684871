import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withKeys as _withKeys, createVNode as _createVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/images/triangle-right.svg'


const _hoisted_1 = { class: "sales-top-buttons flex justify-content-between flex-wrap" }
const _hoisted_2 = { class: "flex justify-content-end align-items-center flex-wrap w-full" }
const _hoisted_3 = { class: "flex flex-column justify-content-center font-semibold flex-1" }
const _hoisted_4 = { style: { width: '100%', marginLeft: '16px' } }
const _hoisted_5 = { class: "flex justify-content-end align-items-center flex-wrap flex-1" }
const _hoisted_6 = { class: "right-buttons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AutoComplete = _resolveComponent("AutoComplete")!
  const _component_Button = _resolveComponent("Button")!
  const _component_PurchaseOrdersTable = _resolveComponent("PurchaseOrdersTable")!
  const _component_Card = _resolveComponent("Card")!
  const _component_ModalSpinner = _resolveComponent("ModalSpinner")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Card, null, {
      header: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _cache[2] || (_cache[2] = _createElementVNode("div", { class: "flex flex-column justify-content-center font-semibold" }, null, -1)),
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _cache[1] || (_cache[1] = _createElementVNode("img", {
                  class: "search-triangle",
                  src: _imports_0
                }, null, -1)),
                _createVNode(_component_AutoComplete, {
                  ref: "inventoryPOAutoComplete",
                  modelValue: _ctx.searchPoNumber,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchPoNumber) = $event)),
                  optionLabel: "po_id",
                  suggestions: undefined,
                  delay: 1500,
                  placeholder: "Search PO Number",
                  onComplete: _ctx.searchPurchaseOrders,
                  onKeydown: _withKeys(_ctx.handlePurchaseOrdersEnter, ["enter"])
                }, null, 8, ["modelValue", "onComplete", "onKeydown"])
              ])
            ]),
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _withDirectives(_createVNode(_component_Button, {
                  onClick: _ctx.clearAllFilters,
                  class: "split-sales-order-btn",
                  icon: "pi pi-filter-slash",
                  outlined: ""
                }, null, 8, ["onClick"]), [
                  [_directive_tooltip, 'Clear All Filters']
                ]),
                (_ctx.userHasReceiptsAccess)
                  ? _withDirectives((_openBlock(), _createBlock(_component_Button, {
                      key: 0,
                      class: "split-sales-order-btn",
                      icon: "pi pi-plus",
                      label: "New Receipt",
                      onClick: _ctx.handleAddReceipts
                    }, null, 8, ["onClick"])), [
                      [_directive_tooltip, 'Create New Receipt']
                    ])
                  : _createCommentVNode("", true)
              ])
            ])
          ])
        ])
      ]),
      content: _withCtx(() => [
        _createVNode(_component_PurchaseOrdersTable, {
          ref: "purchaseOrdersTable",
          poNumber: _ctx.poNumber,
          onRowClick: _ctx.handlePurchaseOrder
        }, null, 8, ["poNumber", "onRowClick"])
      ]),
      _: 1
    }),
    _createVNode(_component_ModalSpinner, { visible: _ctx.isLoading }, null, 8, ["visible"])
  ], 64))
}
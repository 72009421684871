import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withKeys as _withKeys, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "grid flex justify-content-center mt-2" }
const _hoisted_2 = { class: "col-11 lg:col-11" }
const _hoisted_3 = { class: "grid align-items-center" }
const _hoisted_4 = { class: "scan-input-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputEmployeeID = _resolveComponent("InputEmployeeID")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_InputSwitch = _resolveComponent("InputSwitch")!
  const _component_Button = _resolveComponent("Button")!
  const _component_MessageBox = _resolveComponent("MessageBox")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_InputEmployeeID, {
            ref: "employee_id",
            employeeId: _ctx.endJobPayload.employee_id,
            "onUpdate:employeeId": _cache[0] || (_cache[0] = ($event: any) => (_ctx.endJobPayload.employee_id = $event)),
            onFetchEmployee: _ctx.handleFetchEmployee,
            nextInput: "function_input",
            handleErrorAndFocus: _ctx.handleErrorAndFocus,
            focusInput: _ctx.focusInput,
            rowClass: _ctx.rowClass
          }, null, 8, ["employeeId", "onFetchEmployee", "handleErrorAndFocus", "focusInput", "rowClass"]),
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.rowClass)
          }, [
            _cache[31] || (_cache[31] = _createElementVNode("span", { class: "p-inputgroup-addon" }, " Function ", -1)),
            _createVNode(_component_InputText, {
              ref: "function_input",
              class: "scan-input-text",
              modelValue: _ctx.endJobPayload.function_input,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.endJobPayload.function_input) = $event)),
              onFocus: _cache[2] || (_cache[2] = ($event: any) => ($event.target.select())),
              onKeypress: _withKeys(_ctx.handleFunction, ["enter"]),
              onKeydown: [
                _withKeys(_ctx.handleFunction, ["down"]),
                _cache[3] || (_cache[3] = _withKeys(($event: any) => (_ctx.focusInput('employee_id')), ["up"]))
              ]
            }, null, 8, ["modelValue", "onKeypress", "onKeydown"])
          ], 2),
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.rowClass)
          }, [
            _cache[32] || (_cache[32] = _createElementVNode("span", { class: "p-inputgroup-addon" }, " Function Desc ", -1)),
            _createVNode(_component_InputText, {
              ref: "functionDesc",
              class: "scan-input-text",
              modelValue: _ctx.functionDesc,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.functionDesc) = $event)),
              onFocus: _cache[5] || (_cache[5] = ($event: any) => ($event.target.select())),
              readonly: "",
              onKeypress: _cache[6] || (_cache[6] = _withKeys(($event: any) => (_ctx.focusInput('work_order')), ["enter"])),
              onKeydown: [
                _cache[7] || (_cache[7] = _withKeys(($event: any) => (_ctx.focusInput('work_order')), ["down"])),
                _cache[8] || (_cache[8] = _withKeys(($event: any) => (_ctx.focusInput('function_input')), ["up"]))
              ]
            }, null, 8, ["modelValue"])
          ], 2),
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.rowClass)
          }, [
            _cache[33] || (_cache[33] = _createElementVNode("span", { class: "p-inputgroup-addon" }, " Work Order ID ", -1)),
            _createVNode(_component_InputText, {
              ref: "work_order",
              class: "scan-input-text",
              modelValue: _ctx.endJobPayload.work_order,
              "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.endJobPayload.work_order) = $event)),
              onFocus: _cache[10] || (_cache[10] = ($event: any) => ($event.target.select())),
              onKeypress: _withKeys(_ctx.handleWorkOrder, ["enter"]),
              onKeydown: [
                _withKeys(_ctx.handleWorkOrder, ["down"]),
                _cache[11] || (_cache[11] = _withKeys(($event: any) => (_ctx.focusInput('functionDesc')), ["up"]))
              ]
            }, null, 8, ["modelValue", "onKeypress", "onKeydown"])
          ], 2),
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.rowClass)
          }, [
            _cache[34] || (_cache[34] = _createElementVNode("span", { class: "p-inputgroup-addon" }, " Operation ", -1)),
            _createVNode(_component_InputText, {
              ref: "operation",
              class: "scan-input-text",
              modelValue: _ctx.endJobPayload.operation,
              "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.endJobPayload.operation) = $event)),
              onFocus: _cache[13] || (_cache[13] = ($event: any) => ($event.target.select())),
              onKeypress: _withKeys(_ctx.handleOperation, ["enter"]),
              onKeydown: [
                _withKeys(_ctx.handleOperation, ["down"]),
                _cache[14] || (_cache[14] = _withKeys(($event: any) => (_ctx.focusInput('work_order')), ["up"]))
              ]
            }, null, 8, ["modelValue", "onKeypress", "onKeydown"])
          ], 2),
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.rowClass)
          }, [
            _cache[35] || (_cache[35] = _createElementVNode("span", { class: "p-inputgroup-addon" }, " Work Center ", -1)),
            _createVNode(_component_InputText, {
              ref: "work_center",
              class: "scan-input-text",
              modelValue: _ctx.work_center,
              "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.work_center) = $event)),
              onFocus: _cache[16] || (_cache[16] = ($event: any) => ($event.target.select())),
              onKeydown: _cache[17] || (_cache[17] = _withKeys(($event: any) => (_ctx.focusInput('operation')), ["up"])),
              readonly: ""
            }, null, 8, ["modelValue"])
          ], 2),
          (_ctx.showNextOperation)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: _normalizeClass(_ctx.rowClass)
              }, [
                _cache[36] || (_cache[36] = _createElementVNode("span", { class: "p-inputgroup-addon single-addon" }, " Move to Next Op ", -1)),
                _createElementVNode("span", _hoisted_4, [
                  _createVNode(_component_InputSwitch, {
                    ref: "move_to_next_operation",
                    inputId: "move_to_next_operation",
                    modelValue: _ctx.endJobPayload.move_to_next_operation,
                    "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.endJobPayload.move_to_next_operation) = $event)),
                    trueValue: "Y",
                    falseValue: "N",
                    onFocus: _cache[19] || (_cache[19] = ($event: any) => ($event.target.select())),
                    onKeydown: _cache[20] || (_cache[20] = _withKeys(($event: any) => (_ctx.focusInput('work_center')), ["up"]))
                  }, null, 8, ["modelValue"])
                ])
              ], 2))
            : _createCommentVNode("", true),
          (_ctx.showDestinationOperation)
            ? (_openBlock(), _createElementBlock("div", {
                key: 1,
                class: _normalizeClass(_ctx.rowClass)
              }, [
                _cache[37] || (_cache[37] = _createElementVNode("span", { class: "p-inputgroup-addon" }, " Destination Op ", -1)),
                _createVNode(_component_InputText, {
                  ref: "destination_operation",
                  class: "scan-input-text",
                  modelValue: _ctx.endJobPayload.destination_operation,
                  "onUpdate:modelValue": _cache[21] || (_cache[21] = ($event: any) => ((_ctx.endJobPayload.destination_operation) = $event)),
                  onFocus: _cache[22] || (_cache[22] = ($event: any) => ($event.target.select())),
                  onKeypress: _cache[23] || (_cache[23] = _withKeys(($event: any) => (_ctx.focusInput('quantity_to_move')), ["enter"])),
                  onKeydown: [
                    _cache[24] || (_cache[24] = _withKeys(($event: any) => (_ctx.focusInput('quantity_to_move')), ["down"])),
                    _cache[25] || (_cache[25] = _withKeys(($event: any) => (_ctx.focusInput('work_center')), ["up"]))
                  ]
                }, null, 8, ["modelValue"])
              ], 2))
            : _createCommentVNode("", true),
          (
            _ctx.showDestinationOperation ||
            (_ctx.showNextOperation && _ctx.endJobPayload.move_to_next_operation === 'Y')
          )
            ? (_openBlock(), _createElementBlock("div", {
                key: 2,
                class: _normalizeClass(_ctx.rowClass)
              }, [
                _cache[38] || (_cache[38] = _createElementVNode("span", { class: "p-inputgroup-addon" }, " Balance ", -1)),
                _createVNode(_component_InputText, {
                  ref: "operation_balance",
                  class: "scan-input-text",
                  modelValue: _ctx.operation_balance,
                  "onUpdate:modelValue": _cache[26] || (_cache[26] = ($event: any) => ((_ctx.operation_balance) = $event)),
                  onFocus: _cache[27] || (_cache[27] = ($event: any) => ($event.target.select())),
                  readonly: ""
                }, null, 8, ["modelValue"])
              ], 2))
            : _createCommentVNode("", true),
          (
            _ctx.showDestinationOperation ||
            (_ctx.showNextOperation && _ctx.endJobPayload.move_to_next_operation === 'Y')
          )
            ? (_openBlock(), _createElementBlock("div", {
                key: 3,
                class: _normalizeClass(_ctx.rowClass)
              }, [
                _cache[39] || (_cache[39] = _createElementVNode("span", { class: "p-inputgroup-addon" }, " Move Quantity ", -1)),
                _createVNode(_component_InputText, {
                  ref: "quantity_to_move",
                  class: "scan-input-text",
                  modelValue: _ctx.endJobPayload.quantity_to_move,
                  "onUpdate:modelValue": _cache[28] || (_cache[28] = ($event: any) => ((_ctx.endJobPayload.quantity_to_move) = $event)),
                  onFocus: _cache[29] || (_cache[29] = ($event: any) => ($event.target.select())),
                  onKeydown: _cache[30] || (_cache[30] = _withKeys(($event: any) => (
              _ctx.showDestinationOperation
                ? _ctx.focusInput('destination_operation')
                : _ctx.focusInput('move_to_next_operation')
            ), ["up"]))
                }, null, 8, ["modelValue"])
              ], 2))
            : _createCommentVNode("", true),
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.rowClass)
          }, [
            _createVNode(_component_Button, {
              label: "End Job",
              loading: _ctx.loadEndJob,
              onClick: _ctx.endJob,
              class: "scan-input-button"
            }, null, 8, ["loading", "onClick"])
          ], 2)
        ])
      ])
    ]),
    _createVNode(_component_MessageBox, {
      showDialog: _ctx.showErrorDialog,
      icon: "INFO",
      header: "Error",
      message: _ctx.errorMessage,
      primaryButton: "OK",
      onPrimaryButtonClick: _ctx.clickConfirmErrorDialog
    }, null, 8, ["showDialog", "message", "onPrimaryButtonClick"])
  ], 64))
}
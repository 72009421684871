import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "grid justify-content-end mt-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_Column = _resolveComponent("Column")!
  const _component_DataTable = _resolveComponent("DataTable")!

  return (_openBlock(), _createBlock(_component_DataTable, {
    loading: _ctx.isLoadingShorts,
    value: _ctx.workOrderInfo,
    breakpoint: "1267px",
    sortField: _ctx.sortField,
    sortOrder: _ctx.sortOrder,
    paginator: "",
    rows: _ctx.rowsPerPage,
    rowsPerPageOptions: [10, 25, 50],
    class: "p-datatable-sm w-12 text-sm bordered-datatable"
  }, {
    header: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_Button, {
          class: "ml-2",
          label: "Refresh",
          icon: "pi pi-refresh",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.fetchShortsData(true)))
        })
      ])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_Column, {
        field: "wo",
        header: "Work Order",
        headerClass: "column-header-left"
      }),
      _createVNode(_component_Column, {
        field: "",
        header: "Assembly Number",
        headerClass: "column-header-left"
      }),
      _createVNode(_component_Column, {
        field: "",
        header: "Assembly Description",
        headerClass: "column-header-left"
      }),
      _createVNode(_component_Column, {
        field: "qty",
        header: "Quantity",
        headerClass: "column-header-right",
        bodyStyle: "text-align:right"
      }),
      _createVNode(_component_Column, {
        field: "",
        header: "Customer",
        headerClass: "column-header-left"
      })
    ]),
    _: 1
  }, 8, ["loading", "value", "sortField", "sortOrder", "rows"]))
}
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "statcard-wrapper" }
const _hoisted_2 = {
  class: "statcard-content p-0 m-0",
  style: {"height":"auto"}
}
const _hoisted_3 = {
  class: "",
  style: {"width":"100%"}
}
const _hoisted_4 = { class: "receivable-container" }
const _hoisted_5 = { class: "flex receivable-title" }
const _hoisted_6 = { class: "p-0 my-2" }
const _hoisted_7 = {
  key: 1,
  class: "p-0 m-0 items-container"
}
const _hoisted_8 = { class: "mb-2" }
const _hoisted_9 = { class: "font-semibold ml-1 p-0 inline-block text-sm" }
const _hoisted_10 = { class: "font-semibold ml-1 p-0 inline-block text-sm" }
const _hoisted_11 = {
  key: 0,
  class: "col-12 m-0 p-0 grid"
}
const _hoisted_12 = {
  key: 0,
  class: "",
  style: {"display":"flex","flex-direction":"row","max-height":"45px"}
}
const _hoisted_13 = {
  key: 1,
  class: "font-semibold inline-block text-xs align-content-center justify-content-center",
  style: {"width":"48%","text-align":"center"}
}
const _hoisted_14 = {
  key: 1,
  class: "",
  style: {"display":"flex","flex-direction":"row","max-height":"45px"}
}
const _hoisted_15 = {
  class: "font-semibold inline-block text-xs align-content-center justify-content-center",
  style: {"width":"48%","text-align":"center"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LoadingSpinner = _resolveComponent("LoadingSpinner")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Card = _resolveComponent("Card")!
  const _component_RegisterDialog = _resolveComponent("RegisterDialog")!
  const _component_GLInterfaceDialog = _resolveComponent("GLInterfaceDialog")!
  const _component_GLPostDialog = _resolveComponent("GLPostDialog")!
  const _component_GLReportDialog = _resolveComponent("GLReportDialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_Card, {
        class: _normalizeClass({ 'statcard-selected': _ctx.selected }),
        style: {"max-height":"250px"}
      }, {
        content: _withCtx(() => [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("h3", _hoisted_6, _toDisplayString(_ctx.title), 1)
                ]),
                (_ctx.isLoading)
                  ? (_openBlock(), _createBlock(_component_LoadingSpinner, {
                      key: 0,
                      loading: _ctx.isLoading
                    }, null, 8, ["loading"]))
                  : (_openBlock(), _createElementBlock("div", _hoisted_7, [
                      _createElementVNode("div", _hoisted_8, [
                        _createElementVNode("div", null, [
                          _cache[3] || (_cache[3] = _createElementVNode("span", { class: "font-semibold m-0 p-0 inline-block text-sm" }, "Current Fiscal Year:", -1)),
                          _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.currentFiscalYear), 1)
                        ]),
                        _createElementVNode("div", null, [
                          _cache[4] || (_cache[4] = _createElementVNode("span", { class: "font-semibold m-0 p-0 inline-block text-sm" }, "Current Open Period:", -1)),
                          _createElementVNode("span", _hoisted_10, _toDisplayString(_ctx.currentOpenPeriod), 1)
                        ])
                      ]),
                      (!_ctx.isLoading)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.postingCategories, (postingType) => {
                              return (_openBlock(), _createElementBlock("div", {
                                key: postingType,
                                class: "col-6 p-0"
                              }, [
                                (postingType !== 'GLTRANS')
                                  ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                                      (postingType !== 'GLTRANS')
                                        ? (_openBlock(), _createBlock(_component_Button, {
                                            key: 0,
                                            label: "Post {{ postingType }}",
                                            onClick: ($event: any) => (_ctx.showInterfaceRegister(postingType)),
                                            class: "align-content-center justify-content-center text-xs m-1",
                                            style: {"width":"40%"}
                                          }, {
                                            default: _withCtx(() => [
                                              _createTextVNode("Post " + _toDisplayString(postingType.replace("REG", "")), 1)
                                            ]),
                                            _: 2
                                          }, 1032, ["onClick"]))
                                        : _createCommentVNode("", true),
                                      (postingType !== 'GLTRANS')
                                        ? (_openBlock(), _createElementBlock("span", _hoisted_13, _toDisplayString(+_ctx.postingProgress[postingType].total -
                          +_ctx.postingProgress[postingType].unposted) + " of " + _toDisplayString(_ctx.postingProgress[postingType].total) + " posted ", 1))
                                        : _createCommentVNode("", true),
                                      (postingType !== 'GLTRANS')
                                        ? (_openBlock(), _createBlock(_component_Button, {
                                            key: 2,
                                            onClick: ($event: any) => (_ctx.showRegisterReport(postingType)),
                                            class: "text-xs justify-content-left m-1",
                                            icon: "pi pi-search",
                                            style: {"width":"12%"}
                                          }, null, 8, ["onClick"]))
                                        : _createCommentVNode("", true)
                                    ]))
                                  : (_openBlock(), _createElementBlock("div", _hoisted_14, [
                                      _createVNode(_component_Button, {
                                        label: "Post GLTrans",
                                        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showGLPostDialog = true)),
                                        class: "align-content-center justify-content-center text-xs m-1",
                                        style: {"width":"40%"}
                                      }, {
                                        default: _withCtx(() => _cache[5] || (_cache[5] = [
                                          _createTextVNode("Post GL")
                                        ])),
                                        _: 1
                                      }),
                                      _createElementVNode("span", _hoisted_15, _toDisplayString(+_ctx.postingProgress["GLTRANS"].total -
                          +_ctx.postingProgress["GLTRANS"].unposted) + " of " + _toDisplayString(_ctx.postingProgress["GLTRANS"].total) + " posted ", 1),
                                      _createVNode(_component_Button, {
                                        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showGLReportdialog = true)),
                                        class: "text-xs m-1",
                                        icon: "pi pi-search",
                                        style: {"width":"12%"}
                                      })
                                    ]))
                              ]))
                            }), 128))
                          ]))
                        : _createCommentVNode("", true)
                    ]))
              ])
            ])
          ])
        ]),
        _: 1
      }, 8, ["class"])
    ]),
    _createVNode(_component_RegisterDialog, {
      title: _ctx.registerDialogTitle,
      show: _ctx.showRegisterDialog,
      file: _ctx.showRegisterFile,
      onHide: _cache[2] || (_cache[2] = ($event: any) => (_ctx.showRegisterDialog = false)),
      periodStartDate: _ctx.periodStartDate,
      periodEndDate: _ctx.periodEndDate
    }, null, 8, ["title", "show", "file", "periodStartDate", "periodEndDate"]),
    _createVNode(_component_GLInterfaceDialog, {
      title: `Create Journal Entries for ${_ctx.showRegisterFile} Register`,
      show: _ctx.showInterfaceRegisterDialog,
      file: _ctx.showRegisterFile,
      onHide: _ctx.hideInterfaceRegister,
      onSave: _ctx.updateRegisterData
    }, null, 8, ["title", "show", "file", "onHide", "onSave"]),
    _createVNode(_component_GLPostDialog, {
      show: _ctx.showGLPostDialog,
      onHide: _ctx.hideGLPostDialog,
      onSave: _ctx.updateRegisterData
    }, null, 8, ["show", "onHide", "onSave"]),
    _createVNode(_component_GLReportDialog, {
      show: _ctx.showGLReportdialog,
      periodStartDate: _ctx.periodStartDate,
      periodEndDate: _ctx.periodEndDate,
      onHide: _ctx.hideGLReportDialog
    }, null, 8, ["show", "periodStartDate", "periodEndDate", "onHide"])
  ], 64))
}
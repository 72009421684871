import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "grid m-0 order-tab-content tab-card-content" }
const _hoisted_2 = { class: "grid p-0 m-0 justify-content-center" }
const _hoisted_3 = { class: "grid col-12 xl:col-10 p-0 m-0" }
const _hoisted_4 = { class: "col-12 lg:col-6 xl:col-4 p-0 m-0 pt-0 pb-3 pl-2" }
const _hoisted_5 = { class: "col-12 p-0 m-0" }
const _hoisted_6 = { class: "p-inputgroup p-0 col-12" }
const _hoisted_7 = { class: "col-12 p-0 m-0" }
const _hoisted_8 = { class: "p-inputgroup p-0 mt-1 col-12" }
const _hoisted_9 = { class: "col-12 lg:col-8 p-1 pt-2 align-items-center" }
const _hoisted_10 = { class: "col-12 lg:col-6 xl:col-8 p-0 m-0 pt-0 pb-3 pl-4 lg:pl-1" }
const _hoisted_11 = { class: "grid w-full p-0 lg:pl-2 checklist-section pt-1" }
const _hoisted_12 = { class: "grid col-12 lg:col-10 xl:11 p-0 m-0 mr-1 lg:pt-1" }
const _hoisted_13 = { class: "p-inputgroup hidden lg:inline-flex col-12 p-0 m-0" }
const _hoisted_14 = { class: "p-inputgroup p-0 mt-1 col-12 lg:hidden" }
const _hoisted_15 = { class: "grid col p-0 m-0 pt-2 lg:pt-1" }
const _hoisted_16 = { class: "p-inputgroup hidden lg:inline-flex col-12 p-1 m-0 lg:justify-content-center lg:mt-1" }
const _hoisted_17 = { class: "p-inputgroup p-1 mt-0 col-12 lg:hidden" }
const _hoisted_18 = ["for"]
const _hoisted_19 = { class: "grid col-12 p-0 m-0 justify-content-center" }
const _hoisted_20 = { class: "col-12 xl:col-9 p-0 pt-1 pb-3 pl-2 xl:pl-1 justify-content-center" }
const _hoisted_21 = { class: "grid p-0 m-0" }
const _hoisted_22 = { class: "col-12 xl:col-10 p-0 m-0" }
const _hoisted_23 = { class: "grid w-full pl-2 export-part-section" }
const _hoisted_24 = { class: "grid col-12 md:col-6 lg:col-1 p-0 m-0 lg:pt-1" }
const _hoisted_25 = { class: "hidden lg:inline-flex col-12 p-0 m-0" }
const _hoisted_26 = { class: "p-inputgroup" }
const _hoisted_27 = { class: "p-0 mt-1 col-12 lg:hidden pr-0 md:pr-1" }
const _hoisted_28 = { class: "p-inputgroup" }
const _hoisted_29 = { class: "grid col-12 md:col-6 lg:col-6 p-0 m-0 lg:pt-1" }
const _hoisted_30 = { class: "hidden lg:inline-flex col-12 p-0 m-0 lg:pl-1" }
const _hoisted_31 = { class: "p-inputgroup" }
const _hoisted_32 = { class: "p-0 mt-1 col-12 lg:hidden" }
const _hoisted_33 = { class: "p-inputgroup" }
const _hoisted_34 = { class: "grid col-12 lg:col-5 p-0 m-0 lg:pt-1" }
const _hoisted_35 = { class: "hidden lg:inline-flex col-12 p-0 m-0 lg:pl-1" }
const _hoisted_36 = { class: "p-inputgroup" }
const _hoisted_37 = { class: "p-0 mt-1 col-12 lg:hidden" }
const _hoisted_38 = { class: "p-inputgroup" }
const _hoisted_39 = { class: "col flex justify-content-center align-items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Divider = _resolveComponent("Divider")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Checkbox = _resolveComponent("Checkbox")!
  const _component_AccordionTab = _resolveComponent("AccordionTab")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_Button = _resolveComponent("Button")!
  const _component_CustomAccordion = _resolveComponent("CustomAccordion")!
  const _component_Card = _resolveComponent("Card")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Card, { class: "col-12 border-round-3xl" }, {
      content: _withCtx(() => [
        _createVNode(_component_CustomAccordion, { activeIndex: [0, 1] }, {
          "accordion-content": _withCtx(() => [
            _createVNode(_component_AccordionTab, { header: "Information" }, {
              header: _withCtx(() => [
                _createVNode(_component_Divider, {
                  type: "dashed",
                  class: "ml-3"
                })
              ]),
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_2, [
                  _createElementVNode("div", _hoisted_3, [
                    _cache[7] || (_cache[7] = _createElementVNode("div", { class: "grid col-12 p-0 m-0" }, [
                      _createElementVNode("div", { class: "hidden lg:flex lg:col-6 xl:col-4 p-0 m-0" }),
                      _createElementVNode("div", { class: "grid col-12 lg:col-6 xl:col-8" }, [
                        _createElementVNode("div", { class: "grid col-12 hidden lg:inline-flex p-0 m-0 pr-3 pb-0 ml-3" }, [
                          _createElementVNode("div", { class: "grid col-12 lg:col-10 xl:11 p-0 m-0 mr-1 pt-1 pb-0" }, [
                            _createElementVNode("div", { class: "col font-semibold p-0 m-0 text-center text-sm" }, " List to Check ")
                          ]),
                          _createElementVNode("div", { class: "grid col p-0 m-0 pt-1 pb-1 pl-1" }, [
                            _createElementVNode("div", { class: "col font-semibold p-0 m-0 text-center text-sm" }, " Checked ")
                          ])
                        ])
                      ])
                    ], -1)),
                    _createElementVNode("div", _hoisted_4, [
                      _createElementVNode("div", _hoisted_5, [
                        _createElementVNode("div", _hoisted_6, [
                          _cache[3] || (_cache[3] = _createElementVNode("small", { class: "p-inputgroup-addon" }, "End User Country", -1)),
                          _createVNode(_component_InputText, {
                            type: "text",
                            class: "text-sm",
                            modelValue: _ctx.currentOrder.end_user_cntry,
                            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.currentOrder.end_user_cntry) = $event))
                          }, null, 8, ["modelValue"])
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_7, [
                        _createElementVNode("div", _hoisted_8, [
                          _cache[4] || (_cache[4] = _createElementVNode("small", { class: "p-inputgroup-addon" }, "End User", -1)),
                          _createVNode(_component_InputText, {
                            type: "text",
                            class: "text-sm",
                            modelValue: _ctx.currentOrder.end_user,
                            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.currentOrder.end_user) = $event))
                          }, null, 8, ["modelValue"])
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_9, [
                        _createVNode(_component_Checkbox, {
                          inputId: "attached",
                          name: "attached",
                          trueValue: "Y",
                          falseValue: "N",
                          modelValue: _ctx.currentOrder.end_user_stmt,
                          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.currentOrder.end_user_stmt) = $event)),
                          binary: ""
                        }, null, 8, ["modelValue"]),
                        _cache[5] || (_cache[5] = _createElementVNode("label", {
                          for: "attached",
                          class: "ml-2"
                        }, " End User Statement Attached ", -1))
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_10, [
                      _createElementVNode("div", _hoisted_11, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.currentOrder.export_list_items, (list, index) => {
                          return (_openBlock(), _createElementBlock("div", {
                            key: index,
                            class: "grid col-12 p-0 m-0"
                          }, [
                            _createElementVNode("div", _hoisted_12, [
                              _createElementVNode("div", _hoisted_13, [
                                _createVNode(_component_InputText, {
                                  type: "text",
                                  class: "text-sm single-field",
                                  modelValue: list.export_list,
                                  "onUpdate:modelValue": ($event: any) => ((list.export_list) = $event)
                                }, null, 8, ["modelValue", "onUpdate:modelValue"])
                              ]),
                              _createElementVNode("div", _hoisted_14, [
                                _cache[6] || (_cache[6] = _createElementVNode("small", { class: "p-inputgroup-addon" }, "List to Check", -1)),
                                _createVNode(_component_InputText, {
                                  type: "text",
                                  class: "text-sm",
                                  modelValue: list.export_list,
                                  "onUpdate:modelValue": ($event: any) => ((list.export_list) = $event)
                                }, null, 8, ["modelValue", "onUpdate:modelValue"])
                              ])
                            ]),
                            _createElementVNode("div", _hoisted_15, [
                              _createElementVNode("div", _hoisted_16, [
                                _createVNode(_component_Checkbox, {
                                  inputId: `checked-${index}`,
                                  name: `checked-${index}`,
                                  trueValue: "Y",
                                  falseValue: "N",
                                  modelValue: list.export_list_chk,
                                  "onUpdate:modelValue": ($event: any) => ((list.export_list_chk) = $event),
                                  binary: ""
                                }, null, 8, ["inputId", "name", "modelValue", "onUpdate:modelValue"])
                              ]),
                              _createElementVNode("div", _hoisted_17, [
                                _createVNode(_component_Checkbox, {
                                  inputId: `checked-${index}`,
                                  name: `checked-${index}`,
                                  trueValue: "Y",
                                  falseValue: "N",
                                  modelValue: list.export_list_chk,
                                  "onUpdate:modelValue": ($event: any) => ((list.export_list_chk) = $event),
                                  binary: ""
                                }, null, 8, ["inputId", "name", "modelValue", "onUpdate:modelValue"]),
                                _createElementVNode("label", {
                                  for: `checked-${index}`,
                                  class: "ml-2"
                                }, " Checked ", 8, _hoisted_18)
                              ])
                            ]),
                            _createVNode(_component_Divider, {
                              type: "dashed",
                              class: "lg:hidden mt-2 mb-1"
                            })
                          ]))
                        }), 128))
                      ])
                    ])
                  ])
                ])
              ]),
              _: 1
            }),
            _createVNode(_component_AccordionTab, { header: "Part Information" }, {
              header: _withCtx(() => [
                _createVNode(_component_Divider, {
                  type: "dashed",
                  class: "ml-3"
                })
              ]),
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_19, [
                  _createElementVNode("div", _hoisted_20, [
                    _createElementVNode("div", _hoisted_21, [
                      _createElementVNode("div", _hoisted_22, [
                        _cache[11] || (_cache[11] = _createElementVNode("div", { class: "grid col-12 hidden lg:inline-flex p-0 m-0 pr-1 pb-2" }, [
                          _createElementVNode("div", { class: "grid col-12 lg:col-1 p-0 m-0 mr-1 pt-1 pb-1" }, [
                            _createElementVNode("div", { class: "col font-semibold p-0 m-0 text-center text-sm" }, " Li ")
                          ]),
                          _createElementVNode("div", { class: "grid col p-0 m-0 mr-1 pt-1 pb-1" }, [
                            _createElementVNode("div", { class: "col font-semibold p-0 m-0 text-center text-sm" }, " Part Number ")
                          ]),
                          _createElementVNode("div", { class: "grid col-5 p-0 m-0 mr-1 pt-1 pb-1" }, [
                            _createElementVNode("div", { class: "col font-semibold p-0 m-0 text-center text-sm" }, " End Use ")
                          ])
                        ], -1)),
                        _createElementVNode("div", _hoisted_23, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.endUserInfo.partsInfo, (part) => {
                            return (_openBlock(), _createElementBlock("div", {
                              key: part.li,
                              class: "grid col-12 p-0 m-0"
                            }, [
                              _createElementVNode("div", _hoisted_24, [
                                _createElementVNode("div", _hoisted_25, [
                                  _createElementVNode("div", _hoisted_26, [
                                    _createVNode(_component_InputText, {
                                      type: "text",
                                      class: "text-sm single-field",
                                      modelValue: part.li,
                                      "onUpdate:modelValue": ($event: any) => ((part.li) = $event)
                                    }, null, 8, ["modelValue", "onUpdate:modelValue"])
                                  ])
                                ]),
                                _createElementVNode("div", _hoisted_27, [
                                  _createElementVNode("div", _hoisted_28, [
                                    _cache[8] || (_cache[8] = _createElementVNode("small", { class: "p-inputgroup-addon" }, "Li", -1)),
                                    _createVNode(_component_InputText, {
                                      type: "text",
                                      class: "text-sm",
                                      modelValue: part.li,
                                      "onUpdate:modelValue": ($event: any) => ((part.li) = $event)
                                    }, null, 8, ["modelValue", "onUpdate:modelValue"])
                                  ])
                                ])
                              ]),
                              _createElementVNode("div", _hoisted_29, [
                                _createElementVNode("div", _hoisted_30, [
                                  _createElementVNode("div", _hoisted_31, [
                                    _createVNode(_component_InputText, {
                                      type: "text",
                                      class: "text-sm single-field",
                                      modelValue: part.partNumber,
                                      "onUpdate:modelValue": ($event: any) => ((part.partNumber) = $event)
                                    }, null, 8, ["modelValue", "onUpdate:modelValue"])
                                  ])
                                ]),
                                _createElementVNode("div", _hoisted_32, [
                                  _createElementVNode("div", _hoisted_33, [
                                    _cache[9] || (_cache[9] = _createElementVNode("small", { class: "p-inputgroup-addon" }, "Part Number", -1)),
                                    _createVNode(_component_InputText, {
                                      type: "text",
                                      class: "text-sm",
                                      modelValue: part.partNumber,
                                      "onUpdate:modelValue": ($event: any) => ((part.partNumber) = $event)
                                    }, null, 8, ["modelValue", "onUpdate:modelValue"])
                                  ])
                                ])
                              ]),
                              _createElementVNode("div", _hoisted_34, [
                                _createElementVNode("div", _hoisted_35, [
                                  _createElementVNode("div", _hoisted_36, [
                                    _createVNode(_component_Dropdown, {
                                      options: _ctx.endUserInfo.partEndUseOptions,
                                      optionLabel: "name",
                                      optionValue: "code",
                                      modelValue: part.endUse,
                                      "onUpdate:modelValue": ($event: any) => ((part.endUse) = $event)
                                    }, null, 8, ["options", "modelValue", "onUpdate:modelValue"])
                                  ])
                                ]),
                                _createElementVNode("div", _hoisted_37, [
                                  _createElementVNode("div", _hoisted_38, [
                                    _cache[10] || (_cache[10] = _createElementVNode("small", { class: "p-inputgroup-addon" }, "End Use", -1)),
                                    _createVNode(_component_Dropdown, {
                                      options: _ctx.endUserInfo.partEndUseOptions,
                                      optionLabel: "name",
                                      optionValue: "code",
                                      modelValue: part.endUse,
                                      "onUpdate:modelValue": ($event: any) => ((part.endUse) = $event)
                                    }, null, 8, ["options", "modelValue", "onUpdate:modelValue"])
                                  ])
                                ])
                              ]),
                              _createVNode(_component_Divider, {
                                type: "dashed",
                                class: "lg:hidden p-0 mb-2 mt-2"
                              })
                            ]))
                          }), 128))
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_39, [
                        _createVNode(_component_Button, {
                          label: "Apply to All",
                          class: "h-3rem"
                        })
                      ])
                    ])
                  ])
                ])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card flex justify-content-center" }
const _hoisted_2 = { class: "grid col-12 p-0 m-0" }
const _hoisted_3 = { class: "p-inputgroup p-0 m-0 pt-1 lg:pb-1 col-12 pl-1" }
const _hoisted_4 = { class: "p-inputgroup p-0 m-0 pt-1 lg:pb-1 col-12 pl-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Button, {
      label: "Add",
      icon: "pi pi-plus",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.visible = true)),
      class: "text-sm font-bold"
    }),
    _createVNode(_component_Dialog, {
      visible: _ctx.visible,
      "onUpdate:visible": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.visible) = $event)),
      modal: "",
      header: "New Tax",
      class: "w-6 lg:w-4 xl:w-3"
    }, {
      footer: _withCtx(() => [
        _createVNode(_component_Button, {
          label: "Cancel",
          icon: "pi pi-times",
          onClick: _ctx.cancel,
          class: "p-button-text"
        }, null, 8, ["onClick"]),
        _createVNode(_component_Button, {
          label: "Add",
          icon: "pi pi-check",
          onClick: _ctx.addRecord,
          autofocus: ""
        }, null, 8, ["onClick"])
      ]),
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _cache[3] || (_cache[3] = _createElementVNode("small", { class: "p-inputgroup-addon medium-addon" }, "Tax Code", -1)),
            _createVNode(_component_Dropdown, {
              modelValue: _ctx.newTax.code,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.newTax.code) = $event)),
              options: _ctx.getSalesTaxCodes,
              optionLabel: "id",
              optionValue: "id",
              class: "text-sm",
              onChange: _ctx.onTaxCodeChange
            }, null, 8, ["modelValue", "options", "onChange"])
          ]),
          _createElementVNode("div", _hoisted_4, [
            _cache[4] || (_cache[4] = _createElementVNode("small", { class: "p-inputgroup-addon medium-addon" }, "Tax %", -1)),
            _createVNode(_component_InputText, {
              value: _ctx.newTax.rate,
              class: "text-sm",
              readonly: true,
              style: {"text-align":"right"}
            }, null, 8, ["value"])
          ])
        ])
      ]),
      _: 1
    }, 8, ["visible"])
  ]))
}
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  ref: "accountActivity",
  class: "flex flex-wrap align-items-center justify-content-between p-1 m-1"
}
const _hoisted_2 = { class: "text-center flex p-0 m-0 pl-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputSwitch = _resolveComponent("InputSwitch")!
  const _component_Column = _resolveComponent("Column")!
  const _component_DataTable = _resolveComponent("DataTable")!

  return (_openBlock(), _createBlock(_component_DataTable, {
    value: _ctx.transactions,
    loading: _ctx.loading,
    paginator: true,
    rows: 10,
    rowsPerPageOptions: [10, 25, 50]
  }, {
    header: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("h3", _hoisted_2, _toDisplayString(_ctx.tableTitle), 1),
        _createElementVNode("span", null, [
          _cache[1] || (_cache[1] = _createElementVNode("span", {
            class: "p-1 m-1",
            style: {"vertical-align":"super"}
          }, "Include Unposted Transactions", -1)),
          _createVNode(_component_InputSwitch, {
            "data-testid": "toggleincludeUnposted",
            modelValue: _ctx.includeUnposted,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.includeUnposted) = $event))
          }, null, 8, ["modelValue"])
        ])
      ], 512)
    ]),
    empty: _withCtx(() => _cache[2] || (_cache[2] = [
      _createTextVNode("No record found")
    ])),
    default: _withCtx(() => [
      _createVNode(_component_Column, {
        field: "transactionDescription",
        header: "Transaction Description",
        headerClass: "font-bold",
        bodyClass: "font-semibold text-left",
        sortable: ""
      }),
      _createVNode(_component_Column, {
        field: "transactionDate",
        header: "Transaction Date",
        headerClass: "font-bold",
        bodyClass: "font-normal font-semibold text-center",
        sortable: ""
      }),
      _createVNode(_component_Column, {
        field: "lineNotes",
        header: "Line Notes",
        headerClass: "font-bold",
        bodyClass: "font-semibold text-center",
        sortable: ""
      }),
      _createVNode(_component_Column, {
        field: "postDate",
        header: "Post Date",
        headerClass: "font-bold",
        bodyClass: "font-normal font-semibold text-right",
        sortable: ""
      }),
      _createVNode(_component_Column, {
        field: "credit",
        header: "Credit",
        headerClass: "font-bold",
        bodyClass: "font-normal font-semibold text-right",
        sortable: ""
      }),
      _createVNode(_component_Column, {
        field: "debit",
        header: "Debit",
        headerClass: "font-bold",
        bodyClass: "font-normal font-semibold text-right",
        sortable: ""
      })
    ]),
    _: 1
  }, 8, ["value", "loading"]))
}
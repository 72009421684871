
import { defineComponent } from "vue";
import { mapActions, mapGetters, mapState } from "vuex";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Card from "primevue/card";
import Calendar from "primevue/calendar";
import InputText from "primevue/inputtext";
import Button from "primevue/button";
import Dialog from "primevue/dialog";
import Textarea from "primevue/textarea";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import Dropdown from "primevue/dropdown";
import { MAX_EXPORT_ROWS } from "@/utility/global";

import Log from "@/types/logs";

import Utils from "@/utility/utils";

export default defineComponent({
  name: "ContactLog",
  props: {
    index: Number,
  },
  components: {
    DataTable,
    Column,
    InputText,
    Button,
    Dialog,
    Textarea,
    LoadingSpinner,
    Card,
    Dropdown,
    Calendar,
  },
  created() {
    this.getLogControl();
    this.columns = [
      { field: "id", header: "ID" },
      { field: "created_by", header: "Created By" },
      { field: "time_created", header: "Time" },
      { field: "date_created", header: "Date" },
      { field: "subject", header: "Subject" },
      { field: "type", header: "Type" },
    ];
    if (!this.getContactLog(this.index)) {
      this.loading = true;
      this.fetchContactLog({
        Client: this.getClient,
        index: this.index,
      })
        .then(() => {
          this.contactLogs = this.getContactLog(this.index) as Array<Log>;
          this.totalRecords = this.contactLogs.length;
        })
        .finally(() => {
          this.printExportItems = this.contactLogs;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    } else {
      this.contactLogs = this.getContactLog(this.index) as Array<Log>;
      this.printExportItems = this.contactLogs;
      this.totalRecords = this.contactLogs.length;

      this.loading = false;
    }
  },
  updated() {
    if (!this.cntLogInfoDialog) this.isEditing = false;
  },
  computed: {
    ...mapState(["customerInquiry", "session"]),
    ...mapGetters({
      getContactLog: "customerInquiry/getContactLog",
      getClient: "session/getClient",
      getCustomer: "customerInquiry/getCustomer",
      getUserId: "session/getUserId",
      getLogTypes: "logControl/getTypes",
    }),
    contactLogItems(): Array<Log> {
      const logs = JSON.parse(JSON.stringify(this.contactLogs));
      logs.forEach((item: any) => {
        item.date_created = new Date(item.date_created);
      });
      return logs;
    },
  },
  data() {
    return {
      contactLogs: Array<any>(),
      columns: [{ field: "", header: "" }],
      cntLog: Object() as Log,
      isSubmitted: false,
      cntLogInfoDialog: false,
      isEditing: false,
      currIndex: -1,
      loading: false,
      totalRecords: 0,
      printExportItems: [] as any,
      selectedDate: undefined as Date | undefined,
      printColumns: [
        { field: "id", header: "ID" },
        { field: "created_by", header: "Created By" },
        { field: "time_created", header: "Time" },
        { field: "date_created", header: "Date" },
        { field: "subject", header: "Subject" },
        { field: "type", header: "Type" },
      ],
    };
  },
  methods: {
    ...mapActions({
      fetchContactLog: "customerInquiry/getContactLog",
      addNotification: "notification/add",
      postLog: "customerInquiry/postLog",
      getLogControl: "logControl/getLogControl",
      clearPrintData: "printableDatatable/clearData",
      setPrintData: "printableDatatable/setData",
      setPrintDefaultColumns: "printableDatatable/setDefaultColumns",
      setPrintAvaialbleColumns: "printableDatatable/setAvaialbleColumns",
    }),
    async printExportData() {
      //block a print with no criteria applied
      if (this.totalRecords === 0) {
        const notification = {
          message: `No records to print or export.`,
          type: "error",
        };
        this.addNotification(notification);
        return;
      }
      if (this.totalRecords > MAX_EXPORT_ROWS) {
        const notification = {
          message: `Cannot print or export more than ${MAX_EXPORT_ROWS}, please filter the results to print.`,
          type: "error",
        };
        this.addNotification(notification);
      } else {
        this.clearPrintData();
        // get full dataset for the current criteria
        await this.fetchContacts();
        this.setPrintData(this.printExportItems);
        this.setPrintDefaultColumns(this.printColumns);
        this.setPrintAvaialbleColumns(this.printColumns);

        window.open("/printable-view?print=1&showSelection=1", "_blank");
      }
    },
    openNew() {
      this.cntLog = {} as Log;
      this.isSubmitted = false;
      this.cntLogInfoDialog = true;
    },
    hideDialog() {
      this.cntLogInfoDialog = false;
    },
    editContactLog(cntLog: any, fieldIndex: any) {
      this.cntLog = { ...cntLog };
      this.cntLogInfoDialog = true;
      this.isEditing = true;
      this.currIndex = fieldIndex;
    },
    saveLogInfo() {
      this.isSubmitted = true;
      // perform basic input validation - empty values
      if (!this.cntLog.subject?.trim()) {
        return;
      }

      this.formatDateObj();
      this.formatTimeObj();
      this.cntLog.created_by = this.getUserId;

      if (this.isEditing) {
        this.contactLogs[this.currIndex] = this.cntLog;
      } else {
        this.cntLog.assoc_file_items = [
          {
            assoc_file: "Customer",
            assoc_id: this.getCustomer(this.index)?.cust_id,
          },
        ];
      }

      this.postLog({
        client: this.getClient,
        log: this.cntLog,
      }).then(() => {
        this.fetchContacts();
        const notification = {
          type: "success",
          message: "Contact Log saved successfully",
        };
        this.addNotification(notification);
      });
      this.isEditing = false;
      this.cntLogInfoDialog = false;
    },
    formatDateObj() {
      if (this.cntLog.date_created === undefined) {
        this.cntLog.date_created = Utils.formatDate(new Date());
      } else {
        this.cntLog.date_created = Utils.formatDate(this.cntLog.date_created);
      }
    },
    formatTimeObj() {
      this.cntLog.time_created = Utils.formatTime(new Date());
    },
    fetchContacts() {
      this.loading = true;
      this.fetchContactLog({
        Client: this.getClient,
        index: this.index,
      })
        .then(() => {
          this.contactLogs = this.getContactLog(this.index) as Array<Log>;
          this.totalRecords = this.contactLogs.length;
        })
        .finally(() => {
          this.printExportItems = this.contactLogs;
          this.loading = false;
        });
    },
    formatDate(date: string) {
      return Utils.formatDate(date);
    },
  },
});

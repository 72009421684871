import { createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "w-17rem" }
const _hoisted_2 = { class: "p-inputgroup pb-2" }
const _hoisted_3 = {
  key: 0,
  class: "flex align-items-center"
}
const _hoisted_4 = { class: "flex align-items-center" }
const _hoisted_5 = { class: "p-inputgroup pb-2" }
const _hoisted_6 = { class: "p-inputgroup pb-2" }
const _hoisted_7 = { class: "p-inputgroup pb-2" }
const _hoisted_8 = { style: {"display":"flex","flex-direction":"column"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_Calendar = _resolveComponent("Calendar")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Textarea = _resolveComponent("Textarea")!
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[6] || (_cache[6] = _createElementVNode("small", {
        class: "p-inputgroup-addon font-semibold general-label-color",
        style: { width: '105px', minWidth: '105px' }
      }, " Work Center: ", -1)),
      _createVNode(_component_Dropdown, {
        modelValue: _ctx.schedulingData.workCenter,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.schedulingData.workCenter) = $event)),
        options: _ctx.workCenterList,
        optionLabel: "description",
        optionValue: "work_center"
      }, {
        value: _withCtx(({ value }) => [
          value
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createElementVNode("div", {
                  style: _normalizeStyle([{"height":"20px","width":"6px","border-radius":"2px"}, { backgroundColor: _ctx.dotColor(value) }]),
                  class: "mr-2"
                }, null, 4),
                _createElementVNode("span", null, _toDisplayString(_ctx.selectedWorkCenterLabel(value)), 1)
              ]))
            : _createCommentVNode("", true)
        ]),
        option: _withCtx(({ option }) => [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", {
              style: _normalizeStyle([{"height":"20px","width":"6px","border-radius":"2px"}, { backgroundColor: _ctx.dotColor(option.work_center) }]),
              class: "mr-2"
            }, null, 4),
            _createElementVNode("span", null, _toDisplayString(option.description), 1)
          ])
        ]),
        _: 1
      }, 8, ["modelValue", "options"])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _cache[7] || (_cache[7] = _createElementVNode("small", {
        class: "p-inputgroup-addon font-semibold general-label-color",
        style: { width: '105px', minWidth: '105px' }
      }, " Start Time: ", -1)),
      _createVNode(_component_Calendar, {
        modelValue: _ctx.schedulingData.startDate,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.schedulingData.startDate) = $event)),
        showIcon: true,
        inputClass: "text-sm",
        dateFormat: "mm-dd-yy",
        showTime: "",
        hourFormat: "24",
        "input-style": { minWidth: '93px' },
        onDateSelect: _cache[2] || (_cache[2] = ($event: any) => (_ctx.dateSelected($event, 'from'))),
        "step-minute": _ctx.getCalendarTimeIncrement,
        "show-on-focus": false
      }, null, 8, ["modelValue", "step-minute"])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _cache[8] || (_cache[8] = _createElementVNode("small", {
        class: "p-inputgroup-addon font-semibold general-label-color",
        style: { width: '105px', minWidth: '105px' }
      }, " End Time: ", -1)),
      _createVNode(_component_Calendar, {
        modelValue: _ctx.schedulingData.endDate,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.schedulingData.endDate) = $event)),
        showIcon: true,
        inputClass: "text-sm",
        dateFormat: "mm-dd-yy",
        showTime: "",
        hourFormat: "24",
        "input-style": { minWidth: '93px' },
        onDateSelect: _cache[4] || (_cache[4] = ($event: any) => (_ctx.dateSelected($event, 'to'))),
        "step-minute": _ctx.getCalendarTimeIncrement,
        "show-on-focus": false
      }, null, 8, ["modelValue", "step-minute"])
    ]),
    _createElementVNode("div", _hoisted_7, [
      _cache[9] || (_cache[9] = _createElementVNode("small", {
        class: "p-inputgroup-addon font-semibold general-label-color",
        style: { width: '105px', minWidth: '105px' }
      }, " Est. Run time: ", -1)),
      _createVNode(_component_InputText, {
        disabled: "",
        value: _ctx.estRunTime,
        type: "text",
        class: "text-sm w-full"
      }, null, 8, ["value"])
    ]),
    _createElementVNode("div", _hoisted_8, [
      _cache[10] || (_cache[10] = _createElementVNode("small", {
        class: "font-semibold general-label-color",
        style: { width: '105px', minWidth: '105px' }
      }, " Notes: ", -1)),
      _createVNode(_component_Textarea, {
        "model-value": _ctx.notes,
        "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.notes) = $event)),
        type: "text",
        class: "text-sm w-full mt-2 mb-2",
        rows: "5"
      }, null, 8, ["model-value"])
    ]),
    (_ctx.hasChangeAccess)
      ? (_openBlock(), _createBlock(_component_Button, {
          key: 0,
          label: "Add",
          onClick: _ctx.onScheduleOrder,
          disabled: !!_ctx.invalidFields.length
        }, null, 8, ["onClick", "disabled"]))
      : _createCommentVNode("", true),
    (_ctx.hasChangeAccess)
      ? (_openBlock(), _createBlock(_component_Button, {
          key: 1,
          disabled: _ctx.notesChange,
          label: "Save",
          class: "ml-2",
          onClick: _ctx.onSaveNotes
        }, null, 8, ["disabled", "onClick"]))
      : _createCommentVNode("", true)
  ]))
}
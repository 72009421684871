import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "chat-btn-container"
}
const _hoisted_2 = { class: "p-0 m-0" }
const _hoisted_3 = { class: "scrollable-card" }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { class: "bottom-button-container" }
const _hoisted_6 = { class: "field-checkbox sm:col-12 md:col-12 lg:col-12 align-items-start" }
const _hoisted_7 = { class: "field" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_AssistantChat = _resolveComponent("AssistantChat")!
  const _component_Informer = _resolveComponent("Informer")!
  const _component_AIChatMock = _resolveComponent("AIChatMock")!
  const _component_Card = _resolveComponent("Card")!
  const _component_Checkbox = _resolveComponent("Checkbox")!
  const _component_TextArea = _resolveComponent("TextArea")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.isAIavailable)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_Button, {
            class: "chat-btn pi pi-comments",
            onClick: _ctx.toggleSidebar,
            id: "chat-btn"
          }, null, 8, ["onClick"]),
          _createElementVNode("div", {
            class: _normalizeClass(["custom-chat-sidebar", { 'custom-chat-sidebar-hidden': _ctx.hiddenSidebar }]),
            ref: "chatSidebar"
          }, [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_Card, { class: "p-0 m-0" }, {
                content: _withCtx(() => [
                  _createElementVNode("div", _hoisted_3, [
                    (_ctx.getModules.length > 0 && _ctx.getModules.includes('AIChat'))
                      ? (_openBlock(), _createBlock(_component_Informer, {
                          key: 0,
                          class: "flex-column",
                          apiOpts: {
                  headers: {
                    'x-trace-id': 'vue',
                    'x-informer-jwt': _ctx.getInformerJwt,
                    'x-rover-jwt': _ctx.getRoverJwt,
                    subKey: _ctx.subkey,
                  },
                },
                          apiRoot: _ctx.aiAPIRoot
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_AssistantChat, {
                              systemMessage: _ctx.aiSystemMessage,
                              assistant: _ctx.getInformerDefaultAssistant,
                              suggestions: _ctx.prompts
                            }, null, 8, ["systemMessage", "assistant", "suggestions"])
                          ]),
                          _: 1
                        }, 8, ["apiOpts", "apiRoot"]))
                      : (
                  _ctx.getModules.length > 0 && _ctx.getModules.includes('AIMockChat')
                )
                        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                            _createVNode(_component_AIChatMock, { prompts: _ctx.prompts }, null, 8, ["prompts"])
                          ]))
                        : _createCommentVNode("", true)
                  ]),
                  _createElementVNode("div", _hoisted_5, [
                    _createElementVNode("div", null, [
                      _createVNode(_component_Button, {
                        icon: "pi pi-angle-double-right",
                        text: "",
                        rounded: "",
                        onClick: _ctx.toggleSidebar
                      }, null, 8, ["onClick"])
                    ]),
                    _createElementVNode("div", null, [
                      _createVNode(_component_Button, {
                        style: _normalizeStyle(_ctx.buttonStyles),
                        icon: "pi pi-lock",
                        text: "",
                        rounded: "",
                        onClick: _ctx.toggleLock
                      }, null, 8, ["style", "onClick"]),
                      _createVNode(_component_Button, {
                        style: {"margin-left":"1rem"},
                        icon: "pi pi-cog",
                        text: "",
                        rounded: "",
                        onClick: _ctx.toggleSettings
                      }, null, 8, ["onClick"])
                    ])
                  ])
                ]),
                _: 1
              })
            ])
          ], 2)
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_Dialog, {
      visible: _ctx.showSettings,
      "onUpdate:visible": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.showSettings) = $event)),
      style: { width: '350px' },
      header: "AI Settings",
      modal: true,
      draggable: false,
      class: "p-fluid text-center"
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_6, [
          _createVNode(_component_Checkbox, {
            id: "aiDebug",
            modelValue: _ctx.aiDebug,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.aiDebug) = $event)),
            binary: true,
            label: "AI Debug"
          }, null, 8, ["modelValue"]),
          _cache[3] || (_cache[3] = _createElementVNode("label", { for: "aiDebug" }, "Enable AI Debug Output:", -1))
        ]),
        _createElementVNode("div", _hoisted_7, [
          _cache[4] || (_cache[4] = _createElementVNode("label", { for: "aiSystemMessage" }, "AI System Messages:", -1)),
          _createVNode(_component_TextArea, {
            id: "body",
            modelValue: _ctx.aiSystemMessage,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.aiSystemMessage) = $event)),
            placeholder: "AI SystemMessage",
            required: "false",
            rows: "5",
            cols: "20"
          }, null, 8, ["modelValue"])
        ])
      ]),
      _: 1
    }, 8, ["visible"])
  ], 64))
}

import { defineComponent } from "vue";
import Chart from "primevue/chart";
import Dropdown from "primevue/dropdown";
import { mapActions } from "pinia";
import { useInventoryStore } from "@/store/ui/inventoryModule";
import Usage from "@/types/usage";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Button from "primevue/button";
import Dialog from "primevue/dialog";
import Card from "primevue/card";
import LoadingSpinner from "../LoadingSpinner.vue";
export default defineComponent({
  components: {
    Chart,
    Dropdown,
    DataTable,
    Column,
    Button,
    Dialog,
    Card,
    LoadingSpinner,
  },
  data() {
    return {
      chartOptions: this.setChartOptions(),
      yearSelected: "",
      usage: {} as Usage,
      sortField: "date",
      sortOrder: -1,
      showChartData: false,
      loading: false,
    };
  },
  props: {
    partNumber: {
      type: String,
      required: true,
    },
  },
  watch: {
    async partNumber() {
      await this.fetchUsageData();
    },
  },
  async mounted() {
    await this.fetchUsageData();
  },
  computed: {
    dates(): Date[] {
      return (
        this.usage?.year_mo_items?.map((data) => {
          const year = (data.year_mo as string).slice(0, 4);
          const month = (data.year_mo as string).slice(4);
          const date = new Date();
          date.setFullYear(+year, +month - 1);
          return date;
        }) || []
      );
    },
    yearOptions(): string[] {
      const years = [] as string[];
      this.dates.forEach((date) => {
        const y = date.getFullYear().toString();
        if (!years.includes(y)) {
          years.push(y);
        }
      });
      return years.reverse();
    },
    quantity(): number[] {
      const qty = [] as number[];
      this.usage?.year_mo_items?.forEach((data) => {
        const year = (data.year_mo as string).slice(0, 4);
        if (year === this.yearSelected) {
          qty.push(+(data.qty_used || "0"));
        }
      });

      return qty;
    },
    chartHistory(): any {
      return this.usage.year_mo_items?.map((data) => {
        const year = (data.year_mo as string).slice(0, 4);
        const month = (data.year_mo as string).slice(4);
        const date = new Date();
        date.setFullYear(+year, +month - 1);
        return {
          date: date,
          qty: +(data.qty_used || "0"),
        };
      });
    },
    chartData(): any {
      const documentStyle = getComputedStyle(document.documentElement);
      const color = documentStyle.getPropertyValue("--primary-color");

      const outputDates = [] as string[];
      const qty = [] as number[];

      this.usage?.year_mo_items?.forEach((data) => {
        const year = (data.year_mo as string).slice(0, 4);
        const month = (data.year_mo as string).slice(4);
        if (year === this.yearSelected) {
          outputDates.push(month);
          qty.push(+(data.qty_used || "0"));
        }
      });

      return {
        labels: outputDates,
        datasets: [
          {
            label: "Usage",
            data: qty,
            backgroundColor: color,
            borderColor: color,
            borderWidth: 1,
          },
        ],
      };
    },
  },
  methods: {
    ...mapActions(useInventoryStore, ["fetchUsageById"]),
    async fetchUsageData(refresh = false) {
      this.loading = true;
      const resp = await this.fetchUsageById(this.partNumber, refresh);
      if (resp.success && resp.usage) {
        this.usage = resp.usage;
      }

      if (this.yearOptions.length > 0) {
        this.yearSelected = this.yearOptions[0];
      }
      this.loading = false;
    },
    setChartOptions(): any {
      const documentStyle = getComputedStyle(document.documentElement);
      const textColor = documentStyle.getPropertyValue("--p-text-color");
      const textColorSecondary = documentStyle.getPropertyValue(
        "--p-text-muted-color",
      );
      const surfaceBorder = documentStyle.getPropertyValue(
        "--p-content-border-color",
      );

      return {
        maintainAspectRatio: false,
        plugins: {
          legend: {
            labels: {
              color: textColor,
            },
          },
        },
        scales: {
          x: {
            ticks: {
              color: textColorSecondary,
            },
            grid: {
              color: surfaceBorder,
            },
          },
          y: {
            beginAtZero: true,
            ticks: {
              color: textColorSecondary,
            },
            grid: {
              color: surfaceBorder,
            },
          },
        },
      };
    },
  },
});

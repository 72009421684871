import { resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, withCtx as _withCtx, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "grid mr-0 justify-content-center pt-3 top-statcards-wrapper" }
const _hoisted_2 = { class: "col-12 grid justify-content-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Skeleton = _resolveComponent("Skeleton")!
  const _component_KPICard = _resolveComponent("KPICard")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.env.modules.includes('Invoices') || _ctx.env.modules.length == 0)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: _normalizeClass(["xl:col-4 xl:block col-12", { block: _ctx.$route.fullPath?.toString().includes('invoices') }])
          }, [
            _withDirectives(_createElementVNode("div", null, [
              _createVNode(_component_Skeleton, {
                width: "100%",
                height: "13rem"
              })
            ], 512), [
              [_vShow, _ctx.invoicesLoading]
            ]),
            _withDirectives(_createElementVNode("div", null, [
              _createVNode(_component_router_link, {
                to: "/customers/ar",
                class: "no-underline",
                "data-testid": "customers-ar-kpi-card-link"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_KPICard, {
                    selected: _ctx.$route.fullPath?.toString().includes('/customers/ar'),
                    title: "Invoices",
                    subtitle: "Amount Due",
                    setter: "invoice/fetchMonthlyData",
                    amountArray: _ctx.invoicesMonthlyData,
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handleCardClick('/customers/ar'))),
                    onDoneLoading: _cache[1] || (_cache[1] = ($event: any) => (_ctx.invoicesLoading = false))
                  }, null, 8, ["selected", "amountArray"])
                ]),
                _: 1
              })
            ], 512), [
              [_vShow, !_ctx.invoicesLoading]
            ])
          ], 2))
        : _createCommentVNode("", true),
      (
          (_ctx.env.modules.includes('Tickets') || _ctx.env.modules.length == 0) &&
          _ctx.userHasTicketAccess
        )
        ? (_openBlock(), _createElementBlock("div", {
            key: 1,
            class: _normalizeClass(["xl:col-4 xl:block col-12", { block: _ctx.$route.fullPath?.toString().includes('tickets') }])
          }, [
            _withDirectives(_createElementVNode("div", null, [
              _createVNode(_component_Skeleton, {
                width: "100%",
                height: "13rem"
              })
            ], 512), [
              [_vShow, _ctx.billsLoading]
            ]),
            _withDirectives(_createElementVNode("div", null, [
              _createVNode(_component_router_link, {
                to: "/customers/billing",
                class: "no-underline",
                "data-testid": "customers-billing-kpi-card-link"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_KPICard, {
                    selected: 
                _ctx.$route.fullPath?.toString().includes('/customers/billing')
              ,
                    title: "Tickets",
                    subtitle: "Tickets Created",
                    setter: "billing/fetchMonthlyData",
                    amountArray: _ctx.ticketsMonthlyData,
                    onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.handleCardClick('/customers/billing'))),
                    onDoneLoading: _cache[3] || (_cache[3] = ($event: any) => (_ctx.billsLoading = false))
                  }, null, 8, ["selected", "amountArray"])
                ]),
                _: 1
              })
            ], 512), [
              [_vShow, !_ctx.billsLoading]
            ])
          ], 2))
        : _createCommentVNode("", true),
      (_ctx.env.modules.includes('Sales') || _ctx.env.modules.length == 0)
        ? (_openBlock(), _createElementBlock("div", {
            key: 2,
            class: _normalizeClass(["xl:col-4 xl:block col-12", { block: _ctx.$route.fullPath?.toString().includes('sales') }])
          }, [
            _withDirectives(_createElementVNode("div", null, [
              _createVNode(_component_Skeleton, {
                width: "100%",
                height: "13rem"
              })
            ], 512), [
              [_vShow, _ctx.ordersLoading]
            ]),
            _withDirectives(_createElementVNode("div", null, [
              _createVNode(_component_router_link, {
                to: "/customers/sales/orders",
                class: "no-underline",
                "data-testid": "customers-orders-kpi-card-link"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_KPICard, {
                    selected: 
                _ctx.$route.fullPath?.toString().includes('/customers/sales/orders')
              ,
                    title: "Orders",
                    subtitle: "Orders Placed",
                    setter: "sales/fetchSoMonthlyData",
                    amountArray: _ctx.ordersMonthlyData,
                    onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.handleCardClick('/customers/sales/orders'))),
                    onDoneLoading: _cache[5] || (_cache[5] = ($event: any) => (_ctx.ordersLoading = false))
                  }, null, 8, ["selected", "amountArray"])
                ]),
                _: 1
              })
            ], 512), [
              [_vShow, !_ctx.ordersLoading]
            ])
          ], 2))
        : _createCommentVNode("", true),
      _cache[6] || (_cache[6] = _createElementVNode("div", null, null, -1))
    ])
  ]))
}
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createBlock as _createBlock, withCtx as _withCtx, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  id: "fieldServiceGeneral",
  class: "grid m-1"
}
const _hoisted_2 = {
  key: 0,
  class: "col-12 grid justify-content-between align-items-center"
}
const _hoisted_3 = { class: "col-12 sm:col-6 p-0 m-0" }
const _hoisted_4 = {
  key: 0,
  class: "pl-4"
}
const _hoisted_5 = {
  key: 1,
  class: "pl-4"
}
const _hoisted_6 = { class: "col-12 sm:col-6 lg:col-4 xl:col-3 grid p-0 m-0 justify-content-center sm:justify-content-end" }
const _hoisted_7 = {
  class: "col-6",
  style: { maxWidth: '130px' }
}
const _hoisted_8 = {
  class: "col-6",
  style: { maxWidth: '135px' }
}
const _hoisted_9 = { class: "col-12 p-0 m-0 mt-1" }
const _hoisted_10 = { class: "grid mt-1" }
const _hoisted_11 = { class: "col-12 lg:col-4 md:col-6" }
const _hoisted_12 = { class: "grid p-0" }
const _hoisted_13 = { class: "col-12" }
const _hoisted_14 = { class: "p-inputgroup mt-2" }
const _hoisted_15 = { class: "p-inputgroup mt-2" }
const _hoisted_16 = { class: "p-inputgroup mt-2" }
const _hoisted_17 = { class: "p-inputgroup mt-2" }
const _hoisted_18 = { class: "col-12 lg:col-4 md:col-6" }
const _hoisted_19 = { class: "grid col-12 p-0 mt-1" }
const _hoisted_20 = { class: "formgrid grid ml-1" }
const _hoisted_21 = {
  key: 0,
  class: "col-12 lg:col-4 md:col-6"
}
const _hoisted_22 = { class: "grid p-0" }
const _hoisted_23 = { class: "col-12" }
const _hoisted_24 = { class: "p-inputgroup mt-2" }
const _hoisted_25 = { class: "p-inputgroup mt-2" }
const _hoisted_26 = { class: "p-inputgroup mt-2" }
const _hoisted_27 = { class: "p-inputgroup mt-2" }
const _hoisted_28 = { class: "p-inputgroup mt-2" }
const _hoisted_29 = { class: "p-inputgroup mt-2" }
const _hoisted_30 = { class: "col-12 p-0 m-0 mt-1" }
const _hoisted_31 = { class: "grid mt-1" }
const _hoisted_32 = { class: "col-12 lg:col-4 md:col-6" }
const _hoisted_33 = { class: "grid p-0" }
const _hoisted_34 = { class: "col-12" }
const _hoisted_35 = { class: "p-inputgroup mt-2" }
const _hoisted_36 = { class: "p-inputgroup mt-2" }
const _hoisted_37 = { class: "p-inputgroup mt-2" }
const _hoisted_38 = { class: "p-inputgroup mt-2" }
const _hoisted_39 = { class: "col-12 lg:col-4 md:col-6" }
const _hoisted_40 = { class: "grid p-0" }
const _hoisted_41 = { class: "col-12" }
const _hoisted_42 = { class: "p-inputgroup mt-2" }
const _hoisted_43 = { class: "p-inputgroup mt-2" }
const _hoisted_44 = { class: "p-inputgroup mt-2" }
const _hoisted_45 = { class: "mt-2" }
const _hoisted_46 = { class: "col-12 p-0 m-0 mt-1" }
const _hoisted_47 = { class: "grid mt-1" }
const _hoisted_48 = { class: "col-12" }
const _hoisted_49 = { class: "col-12 p-0 m-0 mt-1" }
const _hoisted_50 = { class: "grid mt-1" }
const _hoisted_51 = { class: "col-12" }
const _hoisted_52 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_SplitButton = _resolveComponent("SplitButton")!
  const _component_ProgressSpinner = _resolveComponent("ProgressSpinner")!
  const _component_SearchFile = _resolveComponent("SearchFile")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Card = _resolveComponent("Card")!
  const _component_Address = _resolveComponent("Address")!
  const _component_RepDataTable = _resolveComponent("RepDataTable")!
  const _component_Calendar = _resolveComponent("Calendar")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_CollapsibleSection = _resolveComponent("CollapsibleSection")!
  const _component_Textarea = _resolveComponent("Textarea")!
  const _component_WorkPerformed = _resolveComponent("WorkPerformed")!
  const _component_Attachments = _resolveComponent("Attachments")!
  const _component_MessageBox = _resolveComponent("MessageBox")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_Card, { class: "col-12 mx-2" }, {
        content: _withCtx(() => [
          (_ctx.$attrs.order_id)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createElementVNode("div", _hoisted_3, [
                  (
                _ctx.$attrs.order_id &&
                (_ctx.$attrs.order_id as string).toLocaleLowerCase().includes('new')
              )
                    ? (_openBlock(), _createElementBlock("h3", _hoisted_4, " New Service " + _toDisplayString(!_ctx.hasChanged ? "*" : ""), 1))
                    : (_openBlock(), _createElementBlock("h3", _hoisted_5, " Updating Service: " + _toDisplayString(_ctx.$attrs.order_id) + " " + _toDisplayString(!_ctx.hasChanged ? "*" : ""), 1))
                ]),
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("div", _hoisted_7, [
                    _createVNode(_component_Button, {
                      class: "w-full",
                      label: "Cancel",
                      icon: "pi pi-times",
                      iconPos: "left",
                      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.push('/customers/fieldservices')))
                    })
                  ]),
                  _createElementVNode("div", _hoisted_8, [
                    (_ctx.isNewFieldService)
                      ? (_openBlock(), _createBlock(_component_Button, {
                          key: 0,
                          class: "w-full",
                          label: "Submit",
                          icon: "pi pi-save",
                          iconPos: "left",
                          onClick: _ctx.handleSubmitFieldService
                        }, null, 8, ["onClick"]))
                      : _createCommentVNode("", true),
                    (!_ctx.isNewFieldService)
                      ? (_openBlock(), _createBlock(_component_SplitButton, {
                          key: 1,
                          label: "Submit",
                          icon: "pi pi-save",
                          iconPos: "left",
                          class: "text-sm font-bold align-self-center collapsible-mobile-view w-full",
                          onClick: _ctx.handleSubmitFieldService,
                          model: _ctx.splitButtonItems
                        }, null, 8, ["onClick", "model"]))
                      : _createCommentVNode("", true)
                  ])
                ])
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_9, [
            _createVNode(_component_CollapsibleSection, {
              isOpen: _ctx.isCustomerInformationIconDown,
              header: "Customer Information",
              "onUpdate:isOpen": _cache[13] || (_cache[13] = ($event: any) => (_ctx.toggleIcon('CustomerInformation')))
            }, {
              content: _withCtx(() => [
                _createElementVNode("div", _hoisted_10, [
                  _createElementVNode("div", _hoisted_11, [
                    _createVNode(_component_Card, null, {
                      content: _withCtx(() => [
                        _cache[30] || (_cache[30] = _createElementVNode("span", {
                          class: "font-bold",
                          id: "header-contact-info"
                        }, "Customer", -1)),
                        (_ctx.isLoadingCustomerInfo)
                          ? (_openBlock(), _createBlock(_component_ProgressSpinner, {
                              key: 0,
                              class: "col-12 h-1rem"
                            }))
                          : _createCommentVNode("", true),
                        _createElementVNode("div", _hoisted_12, [
                          _createElementVNode("div", _hoisted_13, [
                            _createElementVNode("div", _hoisted_14, [
                              _cache[26] || (_cache[26] = _createElementVNode("small", {
                                class: "p-inputgroup-addon font-semibold general-label-color",
                                style: { width: '104px' }
                              }, "Customer ID", -1)),
                              _createVNode(_component_SearchFile, {
                                inputValue: _ctx.fieldService.cust_no,
                                ref: "cust",
                                field_id: "cust_id",
                                file: "CUST",
                                placeHolder: _ctx.fieldService.cust_no,
                                onSelectOption: _ctx.updateCustomer,
                                showButton: true,
                                columns: [
                                { field: 'cust_id', header: 'Customer ID' },
                                { field: 'name', header: 'Customer Name' },
                              ]
                              }, null, 8, ["inputValue", "placeHolder", "onSelectOption"])
                            ]),
                            _createElementVNode("div", _hoisted_15, [
                              _cache[27] || (_cache[27] = _createElementVNode("small", {
                                class: "p-inputgroup-addon font-semibold general-label-color",
                                style: { width: '104px' }
                              }, "Name", -1)),
                              _createVNode(_component_InputText, {
                                type: "text",
                                modelValue: _ctx.customer.name,
                                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.customer.name) = $event)),
                                class: "text-sm"
                              }, null, 8, ["modelValue"])
                            ]),
                            _createElementVNode("div", _hoisted_16, [
                              _cache[28] || (_cache[28] = _createElementVNode("small", {
                                class: "p-inputgroup-addon font-semibold general-label-color",
                                style: { width: '104px' }
                              }, "Phone", -1)),
                              _createVNode(_component_InputText, {
                                type: "text",
                                modelValue: _ctx.fieldService.phone,
                                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.fieldService.phone) = $event)),
                                class: "text-sm"
                              }, null, 8, ["modelValue"])
                            ]),
                            _createElementVNode("div", _hoisted_17, [
                              _cache[29] || (_cache[29] = _createElementVNode("small", {
                                class: "p-inputgroup-addon font-semibold general-label-color",
                                style: { width: '104px' }
                              }, "Contact", -1)),
                              _createVNode(_component_InputText, {
                                type: "text",
                                modelValue: _ctx.fieldService.contact,
                                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.fieldService.contact) = $event)),
                                class: "text-sm"
                              }, null, 8, ["modelValue"])
                            ])
                          ])
                        ])
                      ]),
                      _: 1
                    })
                  ]),
                  _createElementVNode("div", _hoisted_18, [
                    _createVNode(_component_Card, null, {
                      content: _withCtx(() => [
                        _cache[31] || (_cache[31] = _createElementVNode("span", {
                          class: "font-bold",
                          id: "header-mailing-address"
                        }, "Address", -1)),
                        (_ctx.isLoadingCustomerInfo)
                          ? (_openBlock(), _createBlock(_component_ProgressSpinner, {
                              key: 0,
                              class: "col-12 h-1rem"
                            }))
                          : _createCommentVNode("", true),
                        _createElementVNode("div", _hoisted_19, [
                          _createElementVNode("div", _hoisted_20, [
                            _createVNode(_component_Address, {
                              address: _ctx.customerAddress(_ctx.customer)
                            }, null, 8, ["address"])
                          ])
                        ]),
                        (_ctx.showRepsSection)
                          ? (_openBlock(), _createBlock(_component_RepDataTable, {
                              key: 1,
                              reps: _ctx.fieldService.rep_items,
                              onUpdateReps: _ctx.updateReps
                            }, null, 8, ["reps", "onUpdateReps"]))
                          : _createCommentVNode("", true)
                      ]),
                      _: 1
                    })
                  ]),
                  (_ctx.showDatesSection)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_21, [
                        _createVNode(_component_Card, null, {
                          content: _withCtx(() => [
                            _createElementVNode("div", _hoisted_22, [
                              _createElementVNode("div", _hoisted_23, [
                                _createElementVNode("div", _hoisted_24, [
                                  _cache[32] || (_cache[32] = _createElementVNode("small", {
                                    class: "p-inputgroup-addon font-semibold general-label-color",
                                    style: { width: '104px' }
                                  }, "Vendor", -1)),
                                  _createVNode(_component_InputText, {
                                    type: "text",
                                    modelValue: _ctx.fieldService.vendor,
                                    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.fieldService.vendor) = $event)),
                                    class: "text-sm"
                                  }, null, 8, ["modelValue"])
                                ]),
                                _createElementVNode("div", _hoisted_25, [
                                  _cache[33] || (_cache[33] = _createElementVNode("small", {
                                    class: "p-inputgroup-addon font-semibold general-label-color",
                                    style: { width: '104px' }
                                  }, "Hold Date", -1)),
                                  _createVNode(_component_Calendar, {
                                    modelValue: _ctx.fieldService.hold_date,
                                    "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.fieldService.hold_date) = $event)),
                                    showIcon: true,
                                    dateFormat: "mm-dd-yy",
                                    inputClass: "text-sm",
                                    onDateSelect: _cache[6] || (_cache[6] = ($event: any) => (_ctx.formatDate($event, 'hold_date')))
                                  }, null, 8, ["modelValue"])
                                ]),
                                _createElementVNode("div", _hoisted_26, [
                                  _cache[34] || (_cache[34] = _createElementVNode("small", {
                                    class: "p-inputgroup-addon font-semibold general-label-color",
                                    style: { width: '104px' }
                                  }, "Status", -1)),
                                  _createVNode(_component_Dropdown, {
                                    type: "text",
                                    class: "text-sm single-field",
                                    modelValue: _ctx.fieldService.status,
                                    "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.fieldService.status) = $event)),
                                    options: _ctx.statusOptions,
                                    optionLabel: "status",
                                    optionValue: "initial"
                                  }, null, 8, ["modelValue", "options"])
                                ]),
                                _createElementVNode("div", _hoisted_27, [
                                  _cache[35] || (_cache[35] = _createElementVNode("small", {
                                    class: "p-inputgroup-addon font-semibold general-label-color",
                                    style: { width: '104px' }
                                  }, "Order Date", -1)),
                                  _createVNode(_component_Calendar, {
                                    modelValue: _ctx.fieldService.order_date,
                                    "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.fieldService.order_date) = $event)),
                                    showIcon: true,
                                    dateFormat: "mm-dd-yy",
                                    inputClass: "text-sm",
                                    onDateSelect: _cache[9] || (_cache[9] = ($event: any) => (_ctx.formatDate($event, 'order_date')))
                                  }, null, 8, ["modelValue"])
                                ]),
                                _createElementVNode("div", _hoisted_28, [
                                  _cache[36] || (_cache[36] = _createElementVNode("small", {
                                    class: "p-inputgroup-addon font-semibold general-label-color",
                                    style: { width: '104px' }
                                  }, "Schedule Date", -1)),
                                  _createVNode(_component_Calendar, {
                                    modelValue: _ctx.fieldService.sched_date,
                                    "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.fieldService.sched_date) = $event)),
                                    showIcon: true,
                                    dateFormat: "mm-dd-yy",
                                    inputClass: "text-sm",
                                    onDateSelect: _cache[11] || (_cache[11] = ($event: any) => (_ctx.formatDate($event, 'sched_date')))
                                  }, null, 8, ["modelValue"])
                                ]),
                                _createElementVNode("div", _hoisted_29, [
                                  _cache[37] || (_cache[37] = _createElementVNode("small", {
                                    class: "p-inputgroup-addon font-semibold general-label-color",
                                    style: { width: '104px' }
                                  }, "Posted", -1)),
                                  _createVNode(_component_InputText, {
                                    type: "text",
                                    modelValue: _ctx.fieldService.posted,
                                    "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.fieldService.posted) = $event)),
                                    class: "text-sm"
                                  }, null, 8, ["modelValue"])
                                ])
                              ])
                            ])
                          ]),
                          _: 1
                        })
                      ]))
                    : _createCommentVNode("", true)
                ])
              ]),
              _: 1
            }, 8, ["isOpen"])
          ]),
          _createElementVNode("div", _hoisted_30, [
            _createVNode(_component_CollapsibleSection, {
              isOpen: _ctx.isFieldServiceOrderIconDown,
              header: "Field Service Order",
              "onUpdate:isOpen": _cache[23] || (_cache[23] = ($event: any) => (_ctx.toggleIcon('FieldServiceOrder')))
            }, {
              content: _withCtx(() => [
                _createElementVNode("div", _hoisted_31, [
                  _createElementVNode("div", _hoisted_32, [
                    _createVNode(_component_Card, null, {
                      content: _withCtx(() => [
                        _createElementVNode("div", _hoisted_33, [
                          _createElementVNode("div", _hoisted_34, [
                            _createElementVNode("div", _hoisted_35, [
                              _cache[38] || (_cache[38] = _createElementVNode("small", {
                                class: "p-inputgroup-addon font-semibold general-label-color",
                                style: { width: '104px' }
                              }, "Serviced By", -1)),
                              _createVNode(_component_InputText, {
                                type: "text",
                                modelValue: _ctx.fieldService.service_unit,
                                "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.fieldService.service_unit) = $event)),
                                class: "text-sm"
                              }, null, 8, ["modelValue"])
                            ]),
                            _createElementVNode("div", _hoisted_36, [
                              _cache[39] || (_cache[39] = _createElementVNode("small", {
                                class: "p-inputgroup-addon font-semibold general-label-color",
                                style: { width: '104px' }
                              }, "Serviced Date", -1)),
                              _createVNode(_component_Calendar, {
                                modelValue: _ctx.fieldService.service_date,
                                "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.fieldService.service_date) = $event)),
                                showIcon: true,
                                dateFormat: "mm-dd-yy",
                                inputClass: "text-sm",
                                onDateSelect: _cache[16] || (_cache[16] = ($event: any) => (_ctx.formatDate($event, 'service_date')))
                              }, null, 8, ["modelValue"])
                            ]),
                            _createElementVNode("div", _hoisted_37, [
                              _cache[40] || (_cache[40] = _createElementVNode("small", {
                                class: "p-inputgroup-addon font-semibold general-label-color",
                                style: { width: '104px' }
                              }, "Start Time", -1)),
                              _createVNode(_component_Calendar, {
                                modelValue: _ctx.startTime,
                                "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.startTime) = $event)),
                                icon: "pi pi-clock",
                                timeOnly: "",
                                showIcon: "",
                                hourFormat: "12",
                                inputClass: "text-sm"
                              }, null, 8, ["modelValue"])
                            ]),
                            _createElementVNode("div", _hoisted_38, [
                              _cache[41] || (_cache[41] = _createElementVNode("small", {
                                class: "p-inputgroup-addon font-semibold general-label-color",
                                style: { width: '104px' }
                              }, "End Time", -1)),
                              _createVNode(_component_Calendar, {
                                modelValue: _ctx.endTime,
                                "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.endTime) = $event)),
                                icon: "pi pi-clock",
                                timeOnly: "",
                                showIcon: "",
                                iconDisplay: "input",
                                hourFormat: "12",
                                inputClass: "text-sm"
                              }, null, 8, ["modelValue"])
                            ])
                          ])
                        ])
                      ]),
                      _: 1
                    })
                  ]),
                  _createElementVNode("div", _hoisted_39, [
                    _createVNode(_component_Card, null, {
                      content: _withCtx(() => [
                        _cache[46] || (_cache[46] = _createElementVNode("span", {
                          class: "font-bold",
                          id: "header-contact-info"
                        }, "Equipment Information", -1)),
                        _createElementVNode("div", _hoisted_40, [
                          _createElementVNode("div", _hoisted_41, [
                            _createElementVNode("div", _hoisted_42, [
                              _cache[42] || (_cache[42] = _createElementVNode("small", {
                                class: "p-inputgroup-addon font-semibold general-label-color",
                                style: { width: '104px' }
                              }, "Serial Number", -1)),
                              _createVNode(_component_Dropdown, {
                                type: "text",
                                class: "text-sm single-field",
                                modelValue: _ctx.fieldService.serial_no,
                                "onUpdate:modelValue": _cache[19] || (_cache[19] = ($event: any) => ((_ctx.fieldService.serial_no) = $event)),
                                options: _ctx.serialOptions,
                                optionLabel: "serial_number",
                                optionValue: "serial_number",
                                editable: ""
                              }, null, 8, ["modelValue", "options"])
                            ]),
                            _createElementVNode("div", _hoisted_43, [
                              _cache[43] || (_cache[43] = _createElementVNode("small", {
                                class: "p-inputgroup-addon font-semibold general-label-color",
                                style: { width: '104px' }
                              }, "Part #", -1)),
                              _createVNode(_component_InputText, {
                                type: "text",
                                modelValue: _ctx.fieldService.equipment_part,
                                "onUpdate:modelValue": _cache[20] || (_cache[20] = ($event: any) => ((_ctx.fieldService.equipment_part) = $event)),
                                class: "text-sm"
                              }, null, 8, ["modelValue"])
                            ]),
                            _createElementVNode("div", _hoisted_44, [
                              _cache[44] || (_cache[44] = _createElementVNode("small", {
                                class: "p-inputgroup-addon font-semibold general-label-color",
                                style: { width: '104px' }
                              }, "Model", -1)),
                              _createVNode(_component_InputText, {
                                type: "text",
                                modelValue: _ctx.fieldService.equipment_model,
                                "onUpdate:modelValue": _cache[21] || (_cache[21] = ($event: any) => ((_ctx.fieldService.equipment_model) = $event)),
                                class: "text-sm"
                              }, null, 8, ["modelValue"])
                            ]),
                            _createElementVNode("div", _hoisted_45, [
                              _cache[45] || (_cache[45] = _createElementVNode("small", {
                                class: "font-semibold general-label-color",
                                style: { width: '104px' }
                              }, "Part Description", -1)),
                              _createVNode(_component_Textarea, {
                                type: "text",
                                modelValue: _ctx.equipment_notes,
                                "onUpdate:modelValue": _cache[22] || (_cache[22] = ($event: any) => ((_ctx.equipment_notes) = $event)),
                                rows: "3"
                              }, null, 8, ["modelValue"])
                            ])
                          ])
                        ])
                      ]),
                      _: 1
                    })
                  ])
                ])
              ]),
              _: 1
            }, 8, ["isOpen"])
          ]),
          _createElementVNode("div", _hoisted_46, [
            _createVNode(_component_CollapsibleSection, {
              isOpen: _ctx.isWorkPerformedIconDown,
              header: "Work Performed",
              "onUpdate:isOpen": _cache[24] || (_cache[24] = ($event: any) => (_ctx.isWorkPerformedIconDown = !_ctx.isWorkPerformedIconDown))
            }, {
              content: _withCtx(() => [
                _createElementVNode("div", _hoisted_47, [
                  _createElementVNode("div", _hoisted_48, [
                    _createVNode(_component_WorkPerformed, { defaultFieldService: _ctx.fieldService }, null, 8, ["defaultFieldService"])
                  ])
                ])
              ]),
              _: 1
            }, 8, ["isOpen"])
          ]),
          _createElementVNode("div", _hoisted_49, [
            _createVNode(_component_CollapsibleSection, {
              isOpen: _ctx.isAttachmentsIconDown,
              header: "Attachments",
              "onUpdate:isOpen": _cache[25] || (_cache[25] = ($event: any) => (_ctx.isAttachmentsIconDown = !_ctx.isAttachmentsIconDown))
            }, {
              content: _withCtx(() => [
                _createElementVNode("div", _hoisted_50, [
                  _createElementVNode("div", _hoisted_51, [
                    (_ctx.isNewFieldService)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_52, "Save the Field Service Order to add attachments."))
                      : (_openBlock(), _createBlock(_component_Attachments, {
                          key: 1,
                          hostFile: "FSO",
                          attachments: _ctx.fieldService.attachments_items,
                          recordId: _ctx.fieldService.order_no,
                          showMail: true
                        }, null, 8, ["attachments", "recordId"]))
                  ])
                ])
              ]),
              _: 1
            }, 8, ["isOpen"])
          ])
        ]),
        _: 1
      })
    ]),
    _createVNode(_component_MessageBox, {
      showDialog: _ctx.showConfirmDialog,
      icon: "WARNING",
      header: `Unsaved Changes in service ${_ctx.fieldService.order_no}`,
      message: "Are you sure you want to leave without saving the changes?",
      primaryButton: "Yes",
      cancelButton: "Cancel",
      onPrimaryButtonClick: _ctx.handleAcceptLeaveView,
      onCancelButtonClick: _ctx.handleCancelLeaveView
    }, null, 8, ["showDialog", "header", "onPrimaryButtonClick", "onCancelButtonClick"])
  ], 64))
}
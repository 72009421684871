import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, vShow as _vShow, withDirectives as _withDirectives, resolveDirective as _resolveDirective, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "pos-wrapper grid m-0 justify-content-center" }
const _hoisted_2 = { class: "col-12 mt-0 mr-0 ml-0 surface-100 flex justify-content-between flex-wrap" }
const _hoisted_3 = { class: "my-2" }
const _hoisted_4 = {
  key: 0,
  class: "inline-flex"
}
const _hoisted_5 = { class: "inline-flex" }
const _hoisted_6 = { class: "flex flex-wrap" }
const _hoisted_7 = { class: "col-12" }
const _hoisted_8 = { class: "grid" }
const _hoisted_9 = { class: "col-12 px-3 results-wrapper" }
const _hoisted_10 = { class: "grid" }
const _hoisted_11 = { class: "col-12 flex justify-content-between p-0 m-0 align-items-center" }
const _hoisted_12 = { style: {
                    borderRadius: '100%',

                    width: '27px',
                    height: '27px',
                    justifyContent: 'center',
                    alignItems: 'center',
                    display: 'flex',
                  } }
const _hoisted_13 = {
  class: "pi pi-chevron-down pos-collapsible-button",
  style: {"font-size":"1rem"}
}
const _hoisted_14 = {
  class: "pi pi-chevron-right pos-collapsible-button",
  style: {"font-size":"1rem"}
}
const _hoisted_15 = { key: 1 }
const _hoisted_16 = { class: "list-type-btn-wrapper" }
const _hoisted_17 = {
  key: 0,
  class: "search-results-label"
}
const _hoisted_18 = { class: "search-result-item blue-text mx-1" }
const _hoisted_19 = {
  key: 0,
  class: "col-12 h-5"
}
const _hoisted_20 = {
  key: 1,
  class: "col-12"
}
const _hoisted_21 = {
  key: 2,
  class: "col-12 pos-parts-display",
  style: {"padding-top":"0px"}
}
const _hoisted_22 = {
  key: 0,
  class: "grid col-12 p-0 m-0 flex-wrap align-items-baseline justify-content-between gap-2 pt-0 mt-1"
}
const _hoisted_23 = {
  key: 0,
  class: "card grid col lg:hidden p-0 pt-2 pr-2 justify-content-center"
}
const _hoisted_24 = { class: "pt-0 pb-1 px-3" }
const _hoisted_25 = { class: "grid col p-0 m-0 hidden flex justify-content-end" }
const _hoisted_26 = {
  key: 0,
  class: "col-4 card flex justify-content-center p-0 pr-1 mb-1"
}
const _hoisted_27 = { class: "pt-0 pb-1 px-3" }
const _hoisted_28 = {
  key: 1,
  class: "list-view"
}
const _hoisted_29 = { key: 2 }
const _hoisted_30 = {
  key: 0,
  class: "p-0 m-0"
}
const _hoisted_31 = { class: "flex flex-wrap align-items-right justify-content-between gap-2" }
const _hoisted_32 = { class: "p-input-icon-left" }
const _hoisted_33 = { class: "w-12" }
const _hoisted_34 = { class: "w-12 flex flex-wrap justify-content-between" }
const _hoisted_35 = { class: "category-buttons-wrapper" }
const _hoisted_36 = { class: "w-full h-full flex flex-column justify-content-around align-items-start" }
const _hoisted_37 = { class: "block" }
const _hoisted_38 = { class: "text-xs text-left text-100 block font-light font-italic" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Search = _resolveComponent("Search")!
  const _component_InputSwitch = _resolveComponent("InputSwitch")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Breadcrumb = _resolveComponent("Breadcrumb")!
  const _component_LoadingSpinner = _resolveComponent("LoadingSpinner")!
  const _component_Racks = _resolveComponent("Racks")!
  const _component_TreeSelect = _resolveComponent("TreeSelect")!
  const _component_MultiSelect = _resolveComponent("MultiSelect")!
  const _component_POSPartsTable = _resolveComponent("POSPartsTable")!
  const _component_Divider = _resolveComponent("Divider")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_ResultGridView = _resolveComponent("ResultGridView")!
  const _component_ProductDialog = _resolveComponent("ProductDialog")!
  const _component_Dialog = _resolveComponent("Dialog")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_Search, {
            id: "priceSearchText",
            ref: "priceSearchText",
            label: "name",
            file: "PRICE",
            currentView: "Pos",
            placeholder: "Search for Parts",
            leftIcon: true,
            scannerMode: true,
            quickScanMode: _ctx.getQuickScan && _ctx.getScanSettings.scanMode,
            class: _normalizeClass(_ctx.searchScanClass),
            onPriceSearch: _ctx.getPriceSearchResult,
            onIsSearching: _ctx.onIsSearchingParts,
            "search-options": _ctx.searchOptions,
            partStatus: _ctx.posPartStatus,
            searchFunction: _ctx.lookupPrice,
            disabled: _ctx.isPriceLocked
          }, null, 8, ["quickScanMode", "class", "onPriceSearch", "onIsSearching", "search-options", "partStatus", "searchFunction", "disabled"]),
          (_ctx.getScanSettings.scanMode)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _cache[16] || (_cache[16] = _createElementVNode("span", null, " Quick Scan: ", -1)),
                _createVNode(_component_InputSwitch, {
                  "data-testid": "toggleQuickScan",
                  modelValue: _ctx.getQuickScan,
                  "onUpdate:modelValue": [
                    _cache[0] || (_cache[0] = ($event: any) => ((_ctx.getQuickScan) = $event)),
                    _ctx.toggleQuickScan
                  ]
                }, null, 8, ["modelValue", "onUpdate:modelValue"])
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_5, [
            _cache[17] || (_cache[17] = _createElementVNode("span", null, " Auto Add Part: ", -1)),
            _createVNode(_component_InputSwitch, {
              "data-testid": "toggleAutoAddPart",
              modelValue: _ctx.autoAddParts,
              "onUpdate:modelValue": [
                _cache[1] || (_cache[1] = ($event: any) => ((_ctx.autoAddParts) = $event)),
                _ctx.toggleAutoAddParts
              ]
            }, null, 8, ["modelValue", "onUpdate:modelValue"])
          ])
        ]),
        _createElementVNode("div", _hoisted_6, [
          (_ctx.getCats.length)
            ? (_openBlock(), _createBlock(_component_Button, {
                key: 0,
                "data-testid": "pos-parts-category-button",
                onClick: _cache[2] || (_cache[2] = () => (_ctx.showCategoriesDialog = true)),
                title: "Select Category",
                class: "mr-2 my-2"
              }, {
                default: _withCtx(() => _cache[18] || (_cache[18] = [
                  _createTextVNode(" Categories ")
                ])),
                _: 1
              }))
            : _createCommentVNode("", true),
          (_ctx.showRacksButton)
            ? (_openBlock(), _createBlock(_component_Button, {
                key: 1,
                onClick: _cache[3] || (_cache[3] = () => (_ctx.displayRacks = true)),
                title: "Racks",
                class: "mr-2 my-2"
              }, {
                default: _withCtx(() => _cache[19] || (_cache[19] = [
                  _createTextVNode(" Racks ")
                ])),
                _: 1
              }))
            : _createCommentVNode("", true),
          _createVNode(_component_Button, {
            onClick: _ctx.getPricesByCode,
            title: "Prices by Code",
            class: "align-self-end my-2"
          }, {
            default: _withCtx(() => _cache[20] || (_cache[20] = [
              _createTextVNode(" Reset ")
            ])),
            _: 1
          }, 8, ["onClick"])
        ])
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("div", _hoisted_10, [
              _createElementVNode("div", _hoisted_11, [
                _createElementVNode("div", {
                  class: "flex flex-direction-row align-items-center",
                  style: {"width":"100%"},
                  onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.handleShowPos && _ctx.handleShowPos(...args)))
                }, [
                  _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_12, [
                    _withDirectives(_createElementVNode("i", _hoisted_13, null, 512), [
                      [_vShow, _ctx.showPos]
                    ]),
                    _withDirectives(_createElementVNode("i", _hoisted_14, null, 512), [
                      [_vShow, !_ctx.showPos]
                    ])
                  ])), [
                    [_vShow, _ctx.currentScreenSize === 'sm'],
                    [_directive_tooltip, 'Collapse Parts']
                  ]),
                  (_ctx.getCats.length)
                    ? (_openBlock(), _createBlock(_component_Breadcrumb, {
                        key: 0,
                        home: _ctx.breadCrumbHome,
                        model: _ctx.partSearch
                      }, null, 8, ["home", "model"]))
                    : (_openBlock(), _createElementBlock("div", _hoisted_15))
                ]),
                _withDirectives(_createElementVNode("div", _hoisted_16, [
                  _createVNode(_component_Button, {
                    onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.switchResultView(false))),
                    class: _normalizeClass(["bg-white list-type-btn", { unselected: _ctx.isResultsListView }])
                  }, {
                    default: _withCtx(() => _cache[21] || (_cache[21] = [
                      _createElementVNode("i", { class: "pi pi-th-large" }, null, -1)
                    ])),
                    _: 1
                  }, 8, ["class"]),
                  _createVNode(_component_Button, {
                    onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.switchResultView(true))),
                    class: _normalizeClass(["bg-white list-type-btn", { unselected: !_ctx.isResultsListView }])
                  }, {
                    default: _withCtx(() => _cache[22] || (_cache[22] = [
                      _createElementVNode("i", { class: "pi pi-list" }, null, -1)
                    ])),
                    _: 1
                  }, 8, ["class"])
                ], 512), [
                  [_vShow, _ctx.showPos]
                ])
              ]),
              _createElementVNode("span", null, [
                (_ctx.currentSearch)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_17, [
                      _cache[23] || (_cache[23] = _createTextVNode(" Search Results For ")),
                      _createElementVNode("span", _hoisted_18, _toDisplayString(_ctx.currentSearch), 1)
                    ]))
                  : _createCommentVNode("", true)
              ]),
              (_ctx.isSearchingParts)
                ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
                    _createVNode(_component_LoadingSpinner, { loading: "" })
                  ]))
                : (_ctx.displayRacks)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_20, [
                      _createVNode(_component_Racks, {
                        racks: _ctx.racks,
                        onAddPart: _ctx.addRackPart
                      }, null, 8, ["racks", "onAddPart"])
                    ]))
                  : (_ctx.showPos)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_21, [
                        (_ctx.showFilters)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_22, [
                              (_ctx.getFilters.length > 0)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_23, [
                                    _createVNode(_component_TreeSelect, {
                                      modelValue: _ctx.selectedSmallFilterOptions,
                                      "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.selectedSmallFilterOptions) = $event)),
                                      options: _ctx.smallFilterOptionsComputed,
                                      selectionMode: "checkbox",
                                      placeholder: "Select filter",
                                      class: "w-full small-parts-filter",
                                      display: "chip",
                                      ref: "nodes"
                                    }, {
                                      footer: _withCtx(() => [
                                        _createElementVNode("div", _hoisted_24, [
                                          _createVNode(_component_Button, {
                                            type: "button",
                                            label: "Apply Filter",
                                            class: "w-12 h-2rem",
                                            onClick: _ctx.getFilteredParts
                                          }, null, 8, ["onClick"])
                                        ])
                                      ]),
                                      _: 1
                                    }, 8, ["modelValue", "options"])
                                  ]))
                                : _createCommentVNode("", true),
                              _createElementVNode("div", _hoisted_25, [
                                (_ctx.getPosShowCategoryFilter)
                                  ? (_openBlock(), _createElementBlock("div", _hoisted_26, [
                                      _createVNode(_component_TreeSelect, {
                                        modelValue: _ctx.selectedTreeCategories,
                                        "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.selectedTreeCategories) = $event)),
                                        options: _ctx.treeSelectCategories,
                                        placeholder: "Select Category",
                                        class: "w-full",
                                        onChange: _ctx.handleCategoriesChange
                                      }, null, 8, ["modelValue", "options", "onChange"])
                                    ]))
                                  : _createCommentVNode("", true),
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getFilters, (filter, index) => {
                                  return (_openBlock(), _createElementBlock("div", {
                                    key: filter.label,
                                    class: "col-4 card flex justify-content-center p-0 pr-1 mb-1"
                                  }, [
                                    _createVNode(_component_MultiSelect, {
                                      "data-testid": "pos-filter-multiselect",
                                      modelValue: _ctx.getSelectedFiltersOptions[index].value,
                                      "onUpdate:modelValue": [
                                        ($event: any) => ((_ctx.getSelectedFiltersOptions[index].value) = $event),
                                        _cache[9] || (_cache[9] = ($event: any) => (
                        _ctx.setSelectedFiltersOptions(_ctx.getSelectedFiltersOptions)
                      ))
                                      ],
                                      display: "chip",
                                      options: filter.tags,
                                      optionLabel: "label",
                                      filter: "",
                                      placeholder: filter.label,
                                      class: "w-full",
                                      onChange: _ctx.removeMultiselectChip
                                    }, {
                                      footer: _withCtx(() => [
                                        _createElementVNode("div", _hoisted_27, [
                                          _createVNode(_component_Button, {
                                            type: "button",
                                            label: "Apply Filter",
                                            class: "w-12 h-2rem",
                                            onClick: _ctx.getFilteredParts
                                          }, null, 8, ["onClick"])
                                        ])
                                      ]),
                                      _: 2
                                    }, 1032, ["modelValue", "onUpdate:modelValue", "options", "placeholder", "onChange"])
                                  ]))
                                }), 128))
                              ]),
                              (_ctx.showFilters)
                                ? (_openBlock(), _createBlock(_component_Button, {
                                    key: 1,
                                    icon: "pi pi-filter-slash",
                                    onClick: _cache[10] || (_cache[10] = ($event: any) => (_ctx.clearFilters(true))),
                                    class: "col-1 m-0"
                                  }))
                                : _createCommentVNode("", true)
                            ]))
                          : _createCommentVNode("", true),
                        (_ctx.isResultsListView)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_28, [
                              _createVNode(_component_POSPartsTable, {
                                value: _ctx.parts,
                                first: _ctx.firstItem,
                                showPaginator: true,
                                rows: _ctx.rows,
                                itemsPerPageOptions: [10, 20, 50],
                                "onUpdate:rows": _cache[11] || (_cache[11] = ($event: any) => (_ctx.rows = $event)),
                                class: "parts-table",
                                editable: false,
                                removable: false,
                                editQtyDirectly: true,
                                showAddToCartBtn: true,
                                onRowClick: _ctx.onRowClick,
                                onAddToCart: _ctx.addItem,
                                onOnPage: _ctx.onPage,
                                partIdFields: _ctx.partIdFields,
                                focusQuantity: !_ctx.getScanSettings.scanMode,
                                onFocusPartSearch: _ctx.autoFocusInput
                              }, null, 8, ["value", "first", "rows", "onRowClick", "onAddToCart", "onOnPage", "partIdFields", "focusQuantity", "onFocusPartSearch"])
                            ]))
                          : (_openBlock(), _createElementBlock("div", _hoisted_29, [
                              (_ctx.parts.length > 0 && _ctx.getPosShowKeywordSearch)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_30, [
                                    _createVNode(_component_Divider, {
                                      type: "solid",
                                      class: "p-0 m-0 mb-2"
                                    }),
                                    _createElementVNode("div", _hoisted_31, [
                                      _createElementVNode("span", _hoisted_32, [
                                        _cache[24] || (_cache[24] = _createElementVNode("i", { class: "pi pi-search" }, null, -1)),
                                        _createVNode(_component_InputText, {
                                          modelValue: _ctx.filters['global'].value,
                                          "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.filters['global'].value) = $event)),
                                          placeholder: "Keyword Search"
                                        }, null, 8, ["modelValue"])
                                      ])
                                    ]),
                                    _createVNode(_component_Divider, {
                                      type: "solid",
                                      class: "p-0 m-0 mt-2"
                                    })
                                  ]))
                                : _createCommentVNode("", true),
                              _createVNode(_component_ResultGridView, {
                                productList: _ctx.parts,
                                showPaginator: true,
                                first: _ctx.firstItem,
                                rows: _ctx.rows,
                                itemsPerPageOptions: [25, 50, 75],
                                onOnPage: _ctx.onPage,
                                "onUpdate:rows": _cache[13] || (_cache[13] = ($event: any) => (_ctx.rows = $event)),
                                onAddPartToOrder: _ctx.addItem,
                                partIdFields: _ctx.partIdFields
                              }, null, 8, ["productList", "first", "rows", "onOnPage", "onAddPartToOrder", "partIdFields"])
                            ]))
                      ]))
                    : _createCommentVNode("", true)
            ])
          ])
        ])
      ])
    ]),
    _createVNode(_component_ProductDialog, {
      open: _ctx.showProductDetails,
      selectedProduct: _ctx.selectedProduct,
      customerId: _ctx.getCustomer?.cust_id,
      partIdFields: _ctx.partIdFields,
      onAddProductToCart: _ctx.addItem,
      onCloseProductDialog: _ctx.closeDialog
    }, null, 8, ["open", "selectedProduct", "customerId", "partIdFields", "onAddProductToCart", "onCloseProductDialog"]),
    _createVNode(_component_Dialog, {
      visible: _ctx.showCategoriesDialog,
      "onUpdate:visible": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.showCategoriesDialog) = $event)),
      modal: "",
      header: "Select Category",
      style: { width: '90%' }
    }, {
      header: _withCtx(() => [
        _createElementVNode("div", _hoisted_33, [
          _createElementVNode("div", _hoisted_34, [
            _cache[25] || (_cache[25] = _createElementVNode("span", { class: "inline-block text-lg font-semibold" }, "Select Category", -1)),
            _createVNode(_component_InputText, {
              modelValue: _ctx.filterCategories,
              "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.filterCategories) = $event)),
              id: "categories",
              class: "text-sm",
              placeholder: "Search Category"
            }, null, 8, ["modelValue"])
          ]),
          _createElementVNode("div", null, [
            _createVNode(_component_Breadcrumb, {
              home: _ctx.breadCrumbHome,
              model: _ctx.partSearch
            }, null, 8, ["home", "model"])
          ])
        ])
      ]),
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_35, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.buttonCategories, (data) => {
            return (_openBlock(), _createElementBlock("div", {
              key: data.category,
              class: "bottom-button h-6rem"
            }, [
              _createVNode(_component_Button, {
                class: "flex justift-content-between text-lg",
                onClick: ($event: any) => (_ctx.searchCategory(data))
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_36, [
                    _cache[26] || (_cache[26] = _createElementVNode("i", {
                      class: "pi pi-box",
                      style: {"font-size":"1rem"}
                    }, null, -1)),
                    _createElementVNode("div", null, [
                      _createElementVNode("span", _hoisted_37, _toDisplayString(data.description), 1),
                      _createElementVNode("span", _hoisted_38, _toDisplayString(data.category), 1)
                    ])
                  ])
                ]),
                _: 2
              }, 1032, ["onClick"])
            ]))
          }), 128))
        ])
      ]),
      _: 1
    }, 8, ["visible"])
  ], 64))
}
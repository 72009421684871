import ARService from "@/services/ARService";
import GetAccountsReceivableRequest from "@/types/services/accountsReceivable";
import store from "@/store";
import { ADD_ERROR_NOTIFICATION } from "@/types/notification";
import { ref, Ref } from "vue";

export function useLazyLoadPagination<RecordType>(
  fetchFunction: () => Promise<any>,
  defaultRowsPerPage = 5,
) {
  const records: Ref<RecordType[]> = ref([]);
  const totalRecords = ref(0);
  const isLoading = ref(false);
  const first = ref(0);
  const rowsPerPage = ref(defaultRowsPerPage);

  function onPage(event: any) {
    first.value = event.first;
    rowsPerPage.value = event.rows;

    if (
      (event.page === event.pageCount ||
        event.page === event.pageCount - 1 ||
        event.page === event.pageCount - 2 ||
        event.first + rowsPerPage.value >= records.value.length) &&
      records.value.length < totalRecords.value
    ) {
      fetchFunction();
    }
  }

  return { records, totalRecords, isLoading, first, rowsPerPage, onPage };
}


import { defineComponent } from "vue";
import { mapGetters, mapActions as vuexMapActions } from "vuex";
import { mapActions } from "pinia";
import Card from "primevue/card";
import Button from "primevue/button";
import Tooltip from "primevue/tooltip";
import AutoComplete from "primevue/autocomplete";
import ModalSpinner from "@/components/UI/ModalSpinner.vue";

import PO from "../../types/po";

import PurchaseOrdersTable from "./PurchaseOrdersTable.vue";

import { useInventoryStore } from "@/store/ui/inventoryModule";

export default defineComponent({
  name: "PurchaseOrders",
  components: {
    Card,
    PurchaseOrdersTable,
    Button,
    AutoComplete,
    ModalSpinner,
  },
  directives: {
    tooltip: Tooltip,
  },
  data() {
    return {
      isLoading: false,
      searchPoNumber: "",
      poNumber: "",
      poOptions: [],
      searchOptions: [],
    };
  },
  async created() {
    await this.fetchFdicts("PO");
  },
  mounted() {
    (this.$refs.inventoryPOAutoComplete as any).$el.firstChild.focus();
  },
  computed: {
    ...mapGetters({
      getField: "fdict/getField",
      getWebAccess: "session/getWebAccess",
    }),
    userHasReceiptsAccess(): boolean {
      return this.getWebAccess("RECEIPTS.E");
    },
  },
  watch: {
    searchPoNumber(newValue: string) {
      if (newValue === "") {
        this.poNumber = "";
      }
    },
  },
  methods: {
    ...vuexMapActions({
      fetchFdicts: "fdict/fetchFdict",
      addNotification: "notification/add",
    }),
    ...mapActions(useInventoryStore, [
      "addNewReceipt",
      "createReceiptsFromPurchaseOrder",
      "setActiveTabById",
    ]),
    clearAllFilters() {
      (this.$refs.purchaseOrdersTable as any).clearFilter();
    },
    searchPurchaseOrders(event: any) {
      this.poNumber = event.query;
      (this.$refs.inventoryPOAutoComplete as any).searching = false;
    },
    handlePurchaseOrdersEnter(event: any) {
      event.enterKeyPressed = true;
      this.poNumber = event.query;
      (this.$refs.inventoryPOAutoComplete as any).searching = false;
    },
    async handlePurchaseOrder(purchaseOrder: PO) {
      this.isLoading = true;
      if (this.userHasReceiptsAccess) {
        const resp = await this.createReceiptsFromPurchaseOrder({
          po: purchaseOrder,
        });

        if (resp.success && resp.id) {
          this.$router.push(`/inventory/receipts/new/${resp.id}`);
          this.setActiveTabById(resp.id);
        }
      }
      this.isLoading = false;
    },
    handleAddReceipts() {
      const { id } = this.addNewReceipt();
      this.$router.push(`/inventory/receipts/new/${id}`);
    },
  },
});

import posthog, { PostHog } from "posthog-js";
import { App } from "@vue/runtime-core";

declare module "@vue/runtime-core" {
  export interface ComponentCustomProperties {
    $posthog: PostHog;
  }
}

export default {
  install(app: App) {
    const phInstance = posthog.init(process.env.VUE_APP_POSTHOG_KEY, {
      api_host: "https://us.i.posthog.com",
      session_recording: {
        maskAllInputs: false,
        maskInputOptions: {
          password: true,
        },
      },
    });

    if (phInstance) {
      app.config.globalProperties.$posthog = phInstance;
    }
  },
};

import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withKeys as _withKeys, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx, createTextVNode as _createTextVNode, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { style: {"display":"flex","justify-content":"end","margin-bottom":"10px"} }
const _hoisted_2 = { class: "grid p-0" }
const _hoisted_3 = { class: "grid lg:col-9 md:col-12" }
const _hoisted_4 = { class: "col-12 sm:col-12 lg:col-6" }
const _hoisted_5 = { class: "p-inputgroup mt-2" }
const _hoisted_6 = { class: "col-12 sm:col-12 lg:col-6 order-1 sm:order-2" }
const _hoisted_7 = { class: "p-inputgroup mt-2" }
const _hoisted_8 = { class: "col-12 sm:col-12 lg:col-6" }
const _hoisted_9 = { class: "p-inputgroup mt-2" }
const _hoisted_10 = { class: "col-12 sm:col-12 lg:col-6" }
const _hoisted_11 = { class: "p-inputgroup mt-2" }
const _hoisted_12 = { class: "col-12 sm:col-12 lg:col-6" }
const _hoisted_13 = { class: "p-inputgroup mt-2" }
const _hoisted_14 = { class: "col-12 md:col-12 lg:col-6" }
const _hoisted_15 = { class: "p-inputgroup mt-2" }
const _hoisted_16 = { class: "xl:col-3 lg:col-3 col-12" }
const _hoisted_17 = { class: "col-12" }
const _hoisted_18 = { class: "grid p-0 m-0" }
const _hoisted_19 = {
  key: 1,
  class: "part-number blue-text block emphasized"
}
const _hoisted_20 = {
  class: "part-manufactured-description block col-12 p-0 m-0",
  style: { fontWeight: '500' }
}
const _hoisted_21 = { key: 1 }
const _hoisted_22 = { style: {
                      display: 'flex',
                      justifyContent: 'center',
                      flexDirection: 'row',
                    } }
const _hoisted_23 = { style: {"display":"flex","flex-direction":"row","margin-top":"8px"} }
const _hoisted_24 = { style: {"display":"flex","flex-direction":"column","width":"100%"} }
const _hoisted_25 = { style: {"display":"flex","flex-direction":"column","width":"100%","margin-left":"10px"} }
const _hoisted_26 = { class: "col-12" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_AutoComplete = _resolveComponent("AutoComplete")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_Calendar = _resolveComponent("Calendar")!
  const _component_Textarea = _resolveComponent("Textarea")!
  const _component_Column = _resolveComponent("Column")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _component_CollapsibleSection = _resolveComponent("CollapsibleSection")!
  const _component_Card = _resolveComponent("Card")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Card, null, {
      content: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_Button, {
            label: "Receive All",
            icon: "pi pi-check",
            onClick: _ctx.handleReceiveAll,
            style: {"margin-right":"10px"}
          }, null, 8, ["onClick"]),
          _createVNode(_component_Button, {
            label: "Clear All",
            icon: "pi pi-check",
            onClick: _ctx.handleClearAll
          }, null, 8, ["onClick"]),
          _createVNode(_component_Button, {
            label: "Save",
            icon: "pi pi-save",
            onClick: _ctx.handleSave,
            class: "ml-2"
          }, null, 8, ["onClick"])
        ]),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _cache[12] || (_cache[12] = _createElementVNode("small", {
                  class: "p-inputgroup-addon font-semibold general-label-color",
                  style: { width: '104px' }
                }, "Receipt", -1)),
                (_ctx.po?.po_id)
                  ? (_openBlock(), _createBlock(_component_InputText, {
                      key: 0,
                      type: "text",
                      modelValue: _ctx.record.receipts_id,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.record.receipts_id) = $event)),
                      class: "text-sm",
                      readonly: ""
                    }, null, 8, ["modelValue"]))
                  : (_openBlock(), _createBlock(_component_AutoComplete, {
                      key: 1,
                      ref: "receiptsAutoComplete",
                      modelValue: _ctx.record.receipts_id,
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.record.receipts_id) = $event)),
                      field: "receipts_id",
                      suggestions: _ctx.searchOptions,
                      onComplete: _ctx.searchReceipts,
                      onItemSelect: _ctx.handleReceipts,
                      onKeydown: _withKeys(_ctx.handleEnter, ["enter"]),
                      forceSelection: true,
                      minLength: 2,
                      loading: _ctx.loading
                    }, null, 8, ["modelValue", "suggestions", "onComplete", "onItemSelect", "onKeydown", "loading"]))
              ])
            ]),
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                _cache[13] || (_cache[13] = _createElementVNode("small", {
                  class: "p-inputgroup-addon font-semibold general-label-color",
                  style: { width: '104px' }
                }, "PO Number", -1)),
                (_ctx.po?.po_id)
                  ? (_openBlock(), _createBlock(_component_InputText, {
                      key: 0,
                      type: "text",
                      value: _ctx.po.po_id,
                      class: "text-sm",
                      readonly: ""
                    }, null, 8, ["value"]))
                  : (_openBlock(), _createBlock(_component_AutoComplete, {
                      key: 1,
                      modelValue: _ctx.searchPoNumber,
                      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.searchPoNumber) = $event)),
                      field: "po_id",
                      suggestions: _ctx.poOptions,
                      onComplete: _ctx.searchPurchaseOrders,
                      onItemSelect: _ctx.handlePurchaseOrders,
                      onKeydown: _withKeys(_ctx.handlePurchaseOrdersEnter, ["enter"]),
                      forceSelection: true,
                      minLength: 2,
                      loading: _ctx.loading
                    }, null, 8, ["modelValue", "suggestions", "onComplete", "onItemSelect", "onKeydown", "loading"]))
              ])
            ]),
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("div", _hoisted_9, [
                _cache[14] || (_cache[14] = _createElementVNode("small", {
                  class: "p-inputgroup-addon font-semibold general-label-color",
                  style: { width: '104px' }
                }, "Vendor", -1)),
                _createVNode(_component_InputText, {
                  type: "text",
                  modelValue: _ctx.record.vendor_name,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.record.vendor_name) = $event)),
                  class: "text-sm",
                  readonly: true
                }, null, 8, ["modelValue"])
              ])
            ]),
            _createElementVNode("div", _hoisted_10, [
              _createElementVNode("div", _hoisted_11, [
                _cache[15] || (_cache[15] = _createElementVNode("small", {
                  class: "p-inputgroup-addon font-semibold general-label-color",
                  style: { width: '104px' }
                }, "Status", -1)),
                _createVNode(_component_Dropdown, {
                  modelValue: _ctx.record.status,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.record.status) = $event)),
                  options: _ctx.statusOptions,
                  optionLabel: "valid_desc",
                  optionValue: "valid_value",
                  readonly: false
                }, null, 8, ["modelValue", "options"])
              ])
            ]),
            _createElementVNode("div", _hoisted_12, [
              _createElementVNode("div", _hoisted_13, [
                _cache[16] || (_cache[16] = _createElementVNode("small", {
                  class: "p-inputgroup-addon font-semibold general-label-color",
                  style: { width: '104px' }
                }, "Packing Slip", -1)),
                _createVNode(_component_InputText, {
                  type: "text",
                  modelValue: _ctx.record.packing_slip,
                  "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.record.packing_slip) = $event)),
                  class: "text-sm"
                }, null, 8, ["modelValue"])
              ])
            ]),
            _createElementVNode("div", _hoisted_14, [
              _createElementVNode("div", _hoisted_15, [
                _cache[17] || (_cache[17] = _createElementVNode("small", {
                  class: "p-inputgroup-addon font-semibold general-label-color",
                  style: { width: '104px' }
                }, "Receipt Date", -1)),
                _createVNode(_component_Calendar, {
                  modelValue: _ctx.record.rec_date,
                  "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.record.rec_date) = $event)),
                  showIcon: true,
                  dateFormat: "mm-dd-yy",
                  inputClass: "text-sm",
                  readonly: _ctx.readOnly
                }, null, 8, ["modelValue", "readonly"])
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_16, [
            _cache[18] || (_cache[18] = _createElementVNode("small", { class: "font-semibold general-label-color" }, "Purchase Order Notes", -1)),
            _createVNode(_component_Textarea, {
              value: _ctx.po?.notes,
              placeholder: "PO notes",
              rows: "2",
              style: { height: '80%' },
              class: "mb-1 mt-2 w-full",
              readonly: ""
            }, null, 8, ["value"])
          ])
        ]),
        _createElementVNode("div", _hoisted_17, [
          _createVNode(_component_DataTable, {
            value: _ctx.lineItems,
            selection: _ctx.selectedItems,
            "onUpdate:selection": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.selectedItems) = $event)),
            onRowSelect: _ctx.handleRowSelect,
            onRowUnselect: _ctx.handleRowUnSelect,
            onRowSelectAll: _ctx.handleReceiveAll,
            onRowUnselectAll: _ctx.handleClearAll,
            expandedRows: _ctx.expandedRows,
            "onUpdate:expandedRows": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.expandedRows) = $event)),
            dataKey: "li_no",
            rowStyle: _ctx.rowStyle
          }, {
            empty: _withCtx(() => _cache[19] || (_cache[19] = [
              _createElementVNode("div", { class: "p-col-12 text-center" }, [
                _createElementVNode("span", { class: "text-base font-bold" }, "No items.")
              ], -1)
            ])),
            expansion: _withCtx((slotProps) => [
              _createVNode(_component_DataTable, {
                dataKey: "it_id",
                value: slotProps.data.receipt_qty_items
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_Column, {
                    field: "receipt_qty",
                    header: "Quantity Received",
                    headerStyle: "text-align: center",
                    bodyStyle: "text-align: right"
                  }, {
                    body: _withCtx(({ data, index }) => [
                      _createVNode(_component_InputText, {
                        class: "custom-input-number",
                        type: "number",
                        modelValue: data.receipt_qty,
                        "onUpdate:modelValue": [($event: any) => ((data.receipt_qty) = $event), ($event: any) => (
                      _ctx.updateReceivedQuantityById(slotProps.data.li_no)
                    )],
                        onKeypress: _withKeys(($event: any) => (
                      _ctx.handleReceiptQtyNewRow(
                        slotProps.data.receipt_qty_items,
                        index,
                      )
                    ), ["enter"])
                      }, null, 8, ["modelValue", "onUpdate:modelValue", "onKeypress"])
                    ]),
                    _: 2
                  }, 1024),
                  _createVNode(_component_Column, {
                    field: "lot_no",
                    header: "Lot Number",
                    headerStyle: "text-align: center",
                    bodyStyle: "text-align: center"
                  }, {
                    body: _withCtx(({ data }) => [
                      _createVNode(_component_InputText, {
                        class: "custom-input",
                        type: "text",
                        modelValue: data.lot_no,
                        "onUpdate:modelValue": ($event: any) => ((data.lot_no) = $event)
                      }, null, 8, ["modelValue", "onUpdate:modelValue"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_Column, {
                    field: "vendor_lot_no",
                    header: "Vendor Lot Number",
                    headerStyle: "text-align: center",
                    bodyStyle: "text-align: center"
                  }, {
                    body: _withCtx(({ data }) => [
                      _createVNode(_component_InputText, {
                        class: "text-sm",
                        modelValue: data.vendor_lot_no,
                        "onUpdate:modelValue": ($event: any) => ((data.vendor_lot_no) = $event)
                      }, null, 8, ["modelValue", "onUpdate:modelValue"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_Column, {
                    field: "bin_number",
                    header: "Bin Number",
                    headerStyle: "text-align: center",
                    bodyStyle: "text-align: center"
                  }, {
                    body: _withCtx(({ data }) => [
                      _createVNode(_component_InputText, {
                        class: "text-sm",
                        modelValue: data.bin_number,
                        "onUpdate:modelValue": ($event: any) => ((data.bin_number) = $event)
                      }, null, 8, ["modelValue", "onUpdate:modelValue"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_Column, null, {
                    body: _withCtx(({ index }) => [
                      _createElementVNode("div", _hoisted_22, [
                        _createVNode(_component_Button, {
                          icon: "pi pi-plus",
                          class: "p-button-sm py-1 px-2 mr-1",
                          rounded: "",
                          text: "",
                          onClick: ($event: any) => (
                        _ctx.handleReceiptQtyNewRow(
                          slotProps.data.receipt_qty_items,
                          index,
                        )
                      )
                        }, null, 8, ["onClick"]),
                        _createVNode(_component_Button, {
                          icon: "pi pi-minus",
                          rounded: "",
                          text: "",
                          class: "p-button-sm py-1 px-2",
                          onClick: ($event: any) => (
                        _ctx.handleReceiptDeleteRow(
                          slotProps.data.receipt_qty_items,
                          index,
                        )
                      )
                        }, null, 8, ["onClick"])
                      ])
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _: 2
              }, 1032, ["value"]),
              _createElementVNode("div", _hoisted_23, [
                _createElementVNode("div", _hoisted_24, [
                  _cache[20] || (_cache[20] = _createElementVNode("small", { class: "font-semibold general-label-color" }, "Receipt Notes", -1)),
                  _createVNode(_component_Textarea, {
                    modelValue: slotProps.data.notes,
                    "onUpdate:modelValue": ($event: any) => ((slotProps.data.notes) = $event),
                    placeholder: "Receipt line item notes",
                    rows: "3",
                    class: "mb-1 mt-2 mr-1",
                    style: {"width":"100%"}
                  }, null, 8, ["modelValue", "onUpdate:modelValue"])
                ]),
                _createElementVNode("div", _hoisted_25, [
                  _cache[21] || (_cache[21] = _createElementVNode("small", { class: "font-semibold general-label-color" }, "PO Line Item Notes (View only)", -1)),
                  _createVNode(_component_Textarea, {
                    value: _ctx.getPOLiNotes(slotProps.data),
                    placeholder: "PO line item notes",
                    rows: "3",
                    class: "mb-1 mt-2",
                    style: {"width":"100%"},
                    readonly: ""
                  }, null, 8, ["value"])
                ])
              ])
            ]),
            default: _withCtx(() => [
              _createVNode(_component_Column, {
                expander: "",
                style: {"width":"2rem"},
                "body-style": "max-width: 2rem;"
              }),
              _createVNode(_component_Column, {
                selectionMode: "multiple",
                headerStyle: "width: 3rem"
              }),
              _createVNode(_component_Column, {
                field: "li_no",
                header: "Line Item #",
                headerStyle: "text-align: center",
                bodyStyle: "text-align: center"
              }),
              _createVNode(_component_Column, {
                header: "Part Number",
                headerStyle: "text-align: center"
              }, {
                body: _withCtx((slotProps) => [
                  _createElementVNode("div", _hoisted_18, [
                    (_ctx.partDescription(slotProps.data))
                      ? (_openBlock(), _createElementBlock("span", {
                          key: 0,
                          class: "part-number blue-text block col-12 p-0 m-0",
                          style: _normalizeStyle([{"word-wrap":"break-word"}, { fontWeight: '500' }])
                        }, _toDisplayString(_ctx.partDescription(slotProps.data)), 1))
                      : (_openBlock(), _createElementBlock("span", _hoisted_19, "No description available")),
                    _createElementVNode("span", _hoisted_20, " Part #" + _toDisplayString(slotProps.data.part_no), 1),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(slotProps.data.vend_part_no_items, (vendor) => {
                      return (_openBlock(), _createElementBlock("span", {
                        key: vendor.vend_part_no,
                        class: "part-manufactured-description block col-12 p-0 m-0"
                      }, " Vendor Part #" + _toDisplayString(vendor.vend_part_no), 1))
                    }), 128))
                  ])
                ]),
                _: 1
              }),
              (_ctx.hasFileBin)
                ? (_openBlock(), _createBlock(_component_Column, {
                    key: 0,
                    header: "Part Bin",
                    headerStyle: "text-align: center",
                    bodyStyle: "text-align: center"
                  }, {
                    body: _withCtx((slotProps) => [
                      _createTextVNode(_toDisplayString(_ctx.getFileBin(slotProps.data)), 1)
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              _createVNode(_component_Column, {
                field: "stock_loc",
                header: "Stock Location",
                headerStyle: "text-align: center",
                bodyStyle: "text-align: center"
              }, {
                body: _withCtx(({ data }) => [
                  _createTextVNode(_toDisplayString(data.stock_loc), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_Column, {
                field: "rec_loc",
                header: "Receipt Location",
                headerStyle: "text-align: center",
                bodyStyle: "text-align: center"
              }, {
                body: _withCtx(({ data }) => [
                  _createTextVNode(_toDisplayString(data.rec_loc), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_Column, {
                field: "tot_sched_qty",
                header: "Total Schedule Quantity",
                headerStyle: "text-align: center",
                bodyStyle: "text-align: right"
              }, {
                body: _withCtx(({ data }) => [
                  _createTextVNode(_toDisplayString(_ctx.getTotalScheduleQty(data)), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_Column, {
                field: "total_rec",
                header: "Prior Received Quantity",
                headerStyle: "text-align: center",
                bodyStyle: "text-align: right"
              }, {
                body: _withCtx(({ data }) => [
                  _createTextVNode(_toDisplayString(_ctx.getPriorReceivedQty(data)), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_Column, {
                header: "Total Open Quantity",
                headerStyle: "text-align: center",
                bodyStyle: "text-align: right"
              }, {
                body: _withCtx(({ data }) => [
                  _createTextVNode(_toDisplayString(_ctx.getTotalOpenQty(data)), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_Column, {
                header: "Quantity Received",
                headerStyle: "text-align: center",
                bodyStyle: "text-align: right"
              }, {
                body: _withCtx(({ data }) => [
                  (data.receipt_qty_items.length === 1)
                    ? (_openBlock(), _createBlock(_component_InputText, {
                        key: 0,
                        class: "custom-input-number",
                        type: "number",
                        modelValue: data.receipt_qty_items[0].receipt_qty,
                        "onUpdate:modelValue": [($event: any) => ((data.receipt_qty_items[0].receipt_qty) = $event), ($event: any) => (_ctx.updateReceivedQuantityById(data.li_no))]
                      }, null, 8, ["modelValue", "onUpdate:modelValue"]))
                    : (_openBlock(), _createElementBlock("div", _hoisted_21, [
                        _createElementVNode("span", null, _toDisplayString(_ctx.totalQuantityReceived(data)), 1)
                      ]))
                ]),
                _: 1
              }),
              _createVNode(_component_Column, null, {
                body: _withCtx(({ data }) => [
                  _createVNode(_component_Button, {
                    label: "Details",
                    onClick: ($event: any) => (_ctx.onViewDetailsClick(data))
                  }, null, 8, ["onClick"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["value", "selection", "onRowSelect", "onRowUnselect", "onRowSelectAll", "onRowUnselectAll", "expandedRows", "rowStyle"])
        ]),
        _withDirectives(_createElementVNode("div", _hoisted_26, [
          _createVNode(_component_CollapsibleSection, {
            isOpen: _ctx.phantomOpen,
            header: "Phantoms",
            "onUpdate:isOpen": _cache[10] || (_cache[10] = ($event: any) => (_ctx.phantomOpen = !_ctx.phantomOpen))
          }, {
            content: _withCtx(() => [
              _createVNode(_component_DataTable, {
                value: _ctx.record.phantom_li_items,
                dataKey: "phantom_li_no",
                expandedRows: _ctx.expandedPhantomRows,
                "onUpdate:expandedRows": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.expandedPhantomRows) = $event))
              }, {
                empty: _withCtx(() => _cache[22] || (_cache[22] = [
                  _createElementVNode("div", { class: "p-col-12 text-center" }, [
                    _createElementVNode("span", { class: "text-base font-bold" }, "No items.")
                  ], -1)
                ])),
                default: _withCtx(() => [
                  _createVNode(_component_Column, {
                    field: "phantom_li",
                    headerStyle: "text-align: center",
                    bodyStyle: "text-align: center",
                    style: {"width":"2rem"}
                  }),
                  _createVNode(_component_Column, null, {
                    body: _withCtx(({ data }) => [
                      _createVNode(_component_DataTable, {
                        dataKey: "comp_li",
                        value: data.comp_li_items
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_Column, {
                            field: "comp_li",
                            header: "Comp Line #",
                            headerStyle: "text-align: center",
                            bodyStyle: "text-align: right"
                          }),
                          _createVNode(_component_Column, {
                            field: "comp_qty",
                            header: "Quantity",
                            headerStyle: "text-align: center",
                            bodyStyle: "text-align: right"
                          }, {
                            body: _withCtx(({ data }) => [
                              _createVNode(_component_InputText, {
                                class: "custom-input-number",
                                type: "text",
                                modelValue: data.comp_qty,
                                "onUpdate:modelValue": ($event: any) => ((data.comp_qty) = $event)
                              }, null, 8, ["modelValue", "onUpdate:modelValue"])
                            ]),
                            _: 2
                          }, 1024),
                          _createVNode(_component_Column, {
                            field: "comp_bin",
                            header: "Bin",
                            headerStyle: "text-align: center",
                            bodyStyle: "text-align: right"
                          }, {
                            body: _withCtx(({ data }) => [
                              _createVNode(_component_InputText, {
                                class: "custom-input",
                                type: "text",
                                modelValue: data.comp_bin,
                                "onUpdate:modelValue": ($event: any) => ((data.comp_bin) = $event)
                              }, null, 8, ["modelValue", "onUpdate:modelValue"])
                            ]),
                            _: 2
                          }, 1024),
                          _createVNode(_component_Column, {
                            field: "comp_lot",
                            header: "Lot",
                            headerStyle: "text-align: center",
                            bodyStyle: "text-align: right"
                          }, {
                            body: _withCtx(({ data }) => [
                              _createVNode(_component_InputText, {
                                class: "custom-input",
                                type: "text",
                                modelValue: data.comp_lot,
                                "onUpdate:modelValue": ($event: any) => ((data.comp_lot) = $event)
                              }, null, 8, ["modelValue", "onUpdate:modelValue"])
                            ]),
                            _: 2
                          }, 1024),
                          _createVNode(_component_Column, {
                            field: "vend_lot",
                            header: "Vendor Lot",
                            headerStyle: "text-align: center",
                            bodyStyle: "text-align: right"
                          }, {
                            body: _withCtx(({ data }) => [
                              _createVNode(_component_InputText, {
                                class: "custom-input",
                                type: "text",
                                modelValue: data.vend_lot,
                                "onUpdate:modelValue": ($event: any) => ((data.vend_lot) = $event)
                              }, null, 8, ["modelValue", "onUpdate:modelValue"])
                            ]),
                            _: 2
                          }, 1024),
                          _createVNode(_component_Column, {
                            field: "comp_part",
                            header: "Part Number",
                            headerStyle: "text-align: center",
                            bodyStyle: "text-align: left"
                          }),
                          _createVNode(_component_Column, {
                            field: "comp_invloc",
                            header: "Received Location",
                            headerStyle: "text-align: center",
                            bodyStyle: "text-align: right"
                          }),
                          _createVNode(_component_Column, {
                            field: "comp_wo",
                            header: "Work Order #",
                            headerStyle: "text-align: center",
                            bodyStyle: "text-align: right"
                          })
                        ]),
                        _: 2
                      }, 1032, ["value"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["value", "expandedRows"])
            ]),
            _: 1
          }, 8, ["isOpen"])
        ], 512), [
          [_vShow, _ctx.record.phantom_li_items?.length > 0]
        ])
      ]),
      _: 1
    }),
    _createVNode(_component_Dialog, {
      visible: _ctx.showScheduleDialog,
      modal: "",
      style: { width: '80%' },
      closable: false
    }, {
      header: _withCtx(() => _cache[23] || (_cache[23] = [
        _createElementVNode("div", { style: {"display":"flex","justify-content":"space-between","width":"100%"} }, [
          _createElementVNode("span", { style: {"font-size":"medium","font-weight":"500"} }, " Purchase Order Schedule ")
        ], -1)
      ])),
      footer: _withCtx(() => [
        _createVNode(_component_Button, {
          label: "Close",
          icon: "pi pi-times",
          class: "p-button-text",
          onClick: _cache[11] || (_cache[11] = ($event: any) => (_ctx.showScheduleDialog = false))
        })
      ]),
      default: _withCtx(() => [
        _createVNode(_component_DataTable, { value: _ctx.selectedOrderSchedule }, {
          default: _withCtx(() => [
            _createVNode(_component_Column, {
              field: "date",
              header: "Date"
            }),
            _createVNode(_component_Column, {
              field: "quantity",
              header: "Quantity"
            }),
            _createVNode(_component_Column, {
              field: "rev",
              header: "Rev"
            }, {
              body: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.rev), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_Column, {
              field: "buy_um",
              header: "Buy UM"
            }, {
              body: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.buyUm), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_Column, {
              field: "buy_factor",
              header: "Buy Factor"
            }, {
              body: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.buyFactor), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_Column, {
              field: "wo_id",
              header: "WO ID"
            }, {
              body: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.woid), 1)
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["value"])
      ]),
      _: 1
    }, 8, ["visible"])
  ], 64))
}
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "flex" }
const _hoisted_2 = { class: "flex" }
const _hoisted_3 = {
  key: 1,
  class: "flex"
}
const _hoisted_4 = {
  for: "inputSwitch",
  class: "m-1"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_InputSwitch = _resolveComponent("InputSwitch")!
  const _component_InputNumber = _resolveComponent("InputNumber")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createBlock(_component_Dialog, {
    visible: _ctx.show,
    style: { width: '100%', maxWidth: '30rem' },
    header: "Add Operation",
    modal: true,
    draggable: false,
    class: "p-fluid text-center",
    closable: false
  }, {
    footer: _withCtx(() => [
      _createVNode(_component_Button, {
        label: "Add",
        icon: "pi pi-check",
        onClick: _ctx.addOperation
      }, null, 8, ["onClick"]),
      _createVNode(_component_Button, {
        label: "Cancel",
        icon: "pi pi-times",
        class: "p-button-text",
        onClick: _ctx.cancel
      }, null, 8, ["onClick"])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _cache[5] || (_cache[5] = _createElementVNode("small", {
          class: "p-inputgroup-addon font-semibold general-label-color",
          style: { width: '104px' }
        }, "Operation", -1)),
        _createVNode(_component_Dropdown, {
          type: "text",
          class: "text-sm",
          modelValue: _ctx.operation,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.operation) = $event)),
          options: _ctx.operations,
          optionLabel: "operation_description",
          optionValue: "operation",
          onChange: _ctx.handleOperationChange,
          style: {"width":"100%"}
        }, null, 8, ["modelValue", "options", "onChange"])
      ]),
      _createElementVNode("div", _hoisted_2, [
        _cache[6] || (_cache[6] = _createElementVNode("small", {
          class: "p-inputgroup-addon font-semibold general-label-color",
          style: { width: '104px' }
        }, "Value", -1)),
        (_ctx.getOperationData(_ctx.operation).input_type === 'Combo')
          ? (_openBlock(), _createBlock(_component_Dropdown, {
              key: 0,
              type: "text",
              class: "text-sm single-field",
              modelValue: _ctx.oper_notes,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.oper_notes) = $event)),
              options: _ctx.getOperationData(_ctx.operation).input_values_items,
              optionLabel: "input_values",
              optionValue: "input_values",
              style: {"width":"100%"},
              placeholder: 
          _ctx.getOperationData(_ctx.operation).input_values_items[0].input_values || ''
        
            }, null, 8, ["modelValue", "options", "placeholder"]))
          : (_ctx.getOperationData(_ctx.operation).input_type === 'CHECKBOX')
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createVNode(_component_InputSwitch, {
                  inputId: "inputSwitch",
                  type: "text",
                  class: "text-sm single-field",
                  modelValue: _ctx.oper_notes,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.oper_notes) = $event)),
                  "aria-label": _ctx.getOperationData(_ctx.operation).oper_label,
                  trueValue: "Y",
                  falseValue: "N",
                  style: {"margin-left":"0.5rem","margin-top":"0.25rem"}
                }, null, 8, ["modelValue", "aria-label"]),
                _createElementVNode("label", _hoisted_4, _toDisplayString(_ctx.getOperationData(_ctx.operation).oper_label), 1)
              ]))
            : (_ctx.getOperationData(_ctx.operation).input_type === 'NUM')
              ? (_openBlock(), _createBlock(_component_InputNumber, {
                  key: 2,
                  class: "text-sm",
                  modelValue: _ctx.oper_notes,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.oper_notes) = $event)),
                  style: {"width":"100%"},
                  maxFractionDigits: "4"
                }, null, 8, ["modelValue"]))
              : (_openBlock(), _createBlock(_component_InputText, {
                  key: 3,
                  type: "text",
                  class: "text-sm single-field",
                  modelValue: _ctx.oper_notes,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.oper_notes) = $event)),
                  style: {"width":"100%"}
                }, null, 8, ["modelValue"]))
      ])
    ]),
    _: 1
  }, 8, ["visible"]))
}
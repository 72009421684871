import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "1.5em",
  height: "1.5em",
  viewBox: "0 0 512 512"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "none",
      stroke: "#3b9315",
      "stroke-linejoin": "round",
      "stroke-width": "32",
      d: "M400 240c-8.89-89.54-71-144-144-144c-69 0-113.44 48.2-128 96c-60 6-112 43.59-112 112c0 66 54 112 120 112h260c55 0 100-27.44 100-88c0-59.82-53-85.76-96-88Z"
    }, null, -1),
    _createElementVNode("path", {
      fill: "none",
      stroke: "#3b9315",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "32",
      d: "M317 208L209.2 336L163 284.8"
    }, null, -1)
  ])))
}
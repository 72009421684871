import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "p-0 m-0" }
const _hoisted_2 = { class: "field" }
const _hoisted_3 = { class: "field" }
const _hoisted_4 = {
  key: 0,
  class: "p-error"
}
const _hoisted_5 = { class: "field" }
const _hoisted_6 = {
  key: 0,
  class: "p-error"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_TextArea = _resolveComponent("TextArea")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Button, {
      icon: "pi pi-plus",
      label: "Add Entry",
      iconPos: "left",
      class: "text-sm",
      loading: _ctx.isSaving,
      onClick: _ctx.openNewLogDialog
    }, null, 8, ["loading", "onClick"]),
    _createVNode(_component_Dialog, {
      visible: _ctx.isVisible,
      "onUpdate:visible": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.isVisible) = $event)),
      style: { width: '450px' },
      header: "Add Log Entry",
      modal: true,
      draggable: false,
      class: "p-fluid"
    }, {
      footer: _withCtx(() => [
        _createVNode(_component_Button, {
          label: "Cancel",
          icon: "pi pi-times",
          class: "p-button-text",
          onClick: _ctx.closeNewLogDialog
        }, null, 8, ["onClick"]),
        _createVNode(_component_Button, {
          label: "Add Log",
          icon: "pi pi-check",
          onClick: _ctx.saveLogInfo
        }, null, 8, ["onClick"])
      ]),
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _cache[4] || (_cache[4] = _createElementVNode("label", { for: "subject" }, "Type", -1)),
          _createVNode(_component_Dropdown, {
            id: "logType",
            autofocus: "",
            autoResize: true,
            type: "text",
            class: "text-sm single-field",
            modelValue: _ctx.newLog.type,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.newLog.type) = $event)),
            options: _ctx.getLogTypes,
            optionLabel: "types",
            optionValue: "types",
            style: {"width":"100%"}
          }, null, 8, ["modelValue", "options"])
        ]),
        _createElementVNode("div", _hoisted_3, [
          _cache[5] || (_cache[5] = _createElementVNode("label", { for: "subject" }, "Subject", -1)),
          _createVNode(_component_TextArea, {
            id: "subject",
            modelValue: _ctx.newLog.subject,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.newLog.subject) = $event)),
            autofocus: "",
            class: _normalizeClass({ 'p-invalid': _ctx.isSubmitted && !_ctx.newLog.subject }),
            autoResize: true,
            rows: "1",
            cols: "30"
          }, null, 8, ["modelValue", "class"]),
          (_ctx.isSubmitted && !_ctx.newLog.subject)
            ? (_openBlock(), _createElementBlock("small", _hoisted_4, "Subject is required."))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_5, [
          _cache[6] || (_cache[6] = _createElementVNode("label", { for: "subject" }, "Details", -1)),
          _createVNode(_component_TextArea, {
            id: "details",
            modelValue: _ctx.newLog.details,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.newLog.details) = $event)),
            autofocus: "",
            class: _normalizeClass({ 'p-invalid': _ctx.isSubmitted && !_ctx.newLog.details }),
            autoResize: true,
            rows: "8",
            cols: "30"
          }, null, 8, ["modelValue", "class"]),
          (_ctx.isSubmitted && !_ctx.newLog.details)
            ? (_openBlock(), _createElementBlock("small", _hoisted_6, "Details are required."))
            : _createCommentVNode("", true)
        ])
      ]),
      _: 1
    }, 8, ["visible"])
  ]))
}
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, vShow as _vShow, withDirectives as _withDirectives, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  id: "datatable-representative",
  class: "formgrid flex justify-content-between align-items-center"
}
const _hoisted_2 = { style: {"text-align":"right"} }
const _hoisted_3 = { class: "field grid" }
const _hoisted_4 = { class: "p-inputgroup mt-2" }
const _hoisted_5 = { class: "field grid" }
const _hoisted_6 = { class: "p-inputgroup mt-2" }
const _hoisted_7 = { class: "field grid" }
const _hoisted_8 = { class: "p-inputgroup mt-2" }
const _hoisted_9 = { class: "confirmation-content" }
const _hoisted_10 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_Column = _resolveComponent("Column")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _component_SearchFile = _resolveComponent("SearchFile")!
  const _component_InputNumber = _resolveComponent("InputNumber")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _cache[5] || (_cache[5] = _createElementVNode("h4", null, "Representative", -1)),
      _createVNode(_component_Button, {
        label: "Add",
        icon: "pi pi-plus",
        class: "text-sm font-bold",
        onClick: _ctx.addNewRep
      }, null, 8, ["onClick"])
    ]),
    _createVNode(_component_DataTable, {
      value: _ctx.reps,
      class: "p-datatable-sm text-sm font-semibold",
      breakpoint: "1400px",
      scrollable: "",
      style: {"max-height":"300px","width":"100%"}
    }, {
      empty: _withCtx(() => _cache[6] || (_cache[6] = [
        _createTextVNode(" No reps have been added.")
      ])),
      default: _withCtx(() => [
        _createVNode(_component_Column, {
          field: "rep",
          header: "Name",
          bodyClass: "font-bold",
          headerClass: "general-label-color"
        }),
        _createVNode(_component_Column, {
          field: "order_pct",
          header: "Order Percent",
          bodyClass: "font-bold",
          headerClass: "general-label-color",
          colspan: 2
        }),
        _createVNode(_component_Column, null, {
          body: _withCtx((slotProps) => [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_Button, {
                icon: "pi pi-pencil",
                title: "Edit",
                class: "mr-2 text-sm font-bold align-right",
                onClick: ($event: any) => (_ctx.editRep(slotProps.data))
              }, null, 8, ["onClick"]),
              _createVNode(_component_Button, {
                icon: "pi pi-trash",
                title: "Delete",
                class: "text-sm font-bold align-right",
                onClick: ($event: any) => (_ctx.confirmDeleteRep(slotProps.data))
              }, null, 8, ["onClick"])
            ])
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["value"]),
    _createVNode(_component_Dialog, {
      visible: _ctx.editRepDialog,
      "onUpdate:visible": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.editRepDialog) = $event)),
      style: { width: '450px' },
      header: "Rep Details",
      modal: true,
      draggable: false,
      class: "p-fluid"
    }, {
      footer: _withCtx(() => [
        _createVNode(_component_Button, {
          label: "Cancel",
          icon: "pi pi-times",
          onClick: _ctx.closeEditModal,
          class: "p-button-text"
        }, null, 8, ["onClick"]),
        _createVNode(_component_Button, {
          label: "Yes",
          icon: "pi pi-check",
          onClick: _ctx.saveRep,
          autofocus: ""
        }, null, 8, ["onClick"])
      ]),
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _cache[7] || (_cache[7] = _createElementVNode("small", {
              class: "p-inputgroup-addon font-semibold general-label-color",
              style: { width: '104px' }
            }, "ID", -1)),
            _createVNode(_component_SearchFile, {
              inputValue: _ctx.rep.rep,
              ref: "REP",
              field_id: "rep_id",
              file: "REP",
              placeHolder: "Rep ID or Name",
              onSelectOption: _ctx.updateRep,
              showButton: true,
              columns: [
            { field: 'rep_id', header: 'REP ID' },
            { field: 'name', header: 'Rep Name' },
            { field: 'email', header: 'Email' },
          ]
            }, null, 8, ["inputValue", "onSelectOption"])
          ])
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _cache[8] || (_cache[8] = _createElementVNode("small", {
              class: "p-inputgroup-addon font-semibold general-label-color",
              style: { width: '104px' }
            }, "Percent", -1)),
            _createVNode(_component_InputNumber, {
              type: "text",
              modelValue: _ctx.rep.order_pct,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.rep.order_pct) = $event)),
              class: "text-sm",
              maxFractionDigits: "2"
            }, null, 8, ["modelValue"])
          ])
        ]),
        _withDirectives(_createElementVNode("div", _hoisted_7, [
          _createElementVNode("div", _hoisted_8, [
            _cache[9] || (_cache[9] = _createElementVNode("small", {
              class: "p-inputgroup-addon font-semibold general-label-color",
              style: { width: '104px' }
            }, "Name", -1)),
            _createVNode(_component_InputText, {
              type: "text",
              modelValue: _ctx.rep.name,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.rep.name) = $event)),
              class: "text-sm",
              readOnly: ""
            }, null, 8, ["modelValue"])
          ])
        ], 512), [
          [_vShow, _ctx.rep.name]
        ])
      ]),
      _: 1
    }, 8, ["visible"]),
    _createVNode(_component_Dialog, {
      visible: _ctx.deleteRepDialog,
      "onUpdate:visible": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.deleteRepDialog) = $event)),
      style: { width: '450px' },
      header: "Confirm",
      modal: true,
      draggable: false
    }, {
      footer: _withCtx(() => [
        _createVNode(_component_Button, {
          label: "No",
          icon: "pi pi-times",
          class: "p-button-text",
          onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.deleteRepDialog = false))
        }),
        _createVNode(_component_Button, {
          label: "Yes",
          icon: "pi pi-check",
          class: "p-button-text",
          onClick: _ctx.deleteRep
        }, null, 8, ["onClick"])
      ]),
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_9, [
          _cache[12] || (_cache[12] = _createElementVNode("i", {
            class: "pi pi-exclamation-triangle mr-3",
            style: {"font-size":"2rem"}
          }, null, -1)),
          (_ctx.rep)
            ? (_openBlock(), _createElementBlock("span", _hoisted_10, [
                _cache[10] || (_cache[10] = _createTextVNode("Are you sure you want to delete REP: ")),
                _createElementVNode("strong", null, _toDisplayString(_ctx.rep.rep), 1),
                _cache[11] || (_cache[11] = _createTextVNode("?"))
              ]))
            : _createCommentVNode("", true)
        ])
      ]),
      _: 1
    }, 8, ["visible"])
  ], 64))
}
export default interface MrkControlState {
  promise: Promise<any> | null;
  valid_so_type_items: Array<SoTypeItem>;
  pos_order_code_items: Array<OrderCodeItem>;
  pos_part_status_items: Array<PartStatusItem>;
  pos_order_summary_items: Array<OrderSummaryItem>;
  pos_order_subtotal_items: Array<OrderSummarySubtotalItem>;
  pos_order_custom_subtotaling: string;
  pos_price_strategy: string;
  pos_cc_pin_pad: string;
  filter_inactive_customers: string;
  hide_pos_notes: string;
  hide_load_customer_button: string;
  hide_parts_tab: string;
  manually_load_orders: string;
  manually_load_invoices: string;
  disable_downloading_statements: string;
  disable_emailing_statements: string;
  hide_use_new_address: string;
  disable_updating_part_description: string;
  prompt_on_price_override: string;
  order_information_precedes_create_order: string;
  currentCode: string;
  post_invoice_on_ship: string;
  hide_po_no: string;
  search_using_parts: string;
  sales_acct: string;
  fg_location: string;
  cg_location: string;
  require_customer_parts_search: string;
  hide_drafted_orders: string;
  hide_order_buttons: string;
  show_pick_ticket_print: string;
  prohibit_open_po_shipments: string;
  apply_cm_to_open_order: string;
  pos_show_pay_terms: string;
  pos_show_tax_codes: string;
  pos_show_book_date: string;
  pos_show_ship_via: string;
  pos_show_freight_amount: string;
  pos_show_frt_serv_code: string;
  pos_show_order_status: string;
  pos_show_sales_rep: string;
  pos_allow_partial_shipments: string;
  pos_show_edit_order: string;
  allow_customer_creation: string;
  pos_show_category_filter: string;
  pos_show_keyword_search: string;
  allow_mixed_mode: string;
  pos_show_part_cost_column: string;
  pos_require_entry_user_authentication: string;
  pos_auto_validate: string;
  pos_show_order_confirmation_page: string;
  pos_show_only_ship_to: string;
  pos_maintain_cart_sort: string;
  pos_allow_payout: string;
  pos_terms_require_payment: string;
  pos_allow_shipto_override: string;
  pos_show_ship_id: string;
  pos_extra_info_items: Array<ExtraInfoItem>;
  pos_partial_ship_field_items: Array<PartialShipItem>;
  pos_host_receipt_print: string;
  pos_force_same_part_qty_increment: string;
  pos_inv_lookups_items: Array<POSInvLookupItem>;
  pos_allow_refund: string;
  pos_skip_calc_tax: string;
  pos_allow_negative_price: string;
  pos_disable_stax: string;
  pos_clear_category_on_search: string;
  hide_main_order_tab: string;
  hide_main_opportunities_tab: string;
  order_header_shipto_display: OrderHeaderShipToDisplayOptions;
  pos_hide_invoices_ar_kpi: string;
  pos_lazy_load_shiptos: string;
  accounting_orders_lookup: string;
  pos_register_selection_mode: string;
  posCustomerOrdersLookup: string;
  posSearchOrdersLookup: string;
  pos_show_invoice_print: string;
}

export interface ExtraInfoItem {
  pos_extra_info: string;
  pos_extra_info_required: string;
  pos_extra_info_read_only: string;
}

export interface PartialShipItem {
  pos_partial_ship_field: string;
}

export interface SoTypeItem {
  valid_so_type: string;
  so_type_code_description: string;
}

export interface OrderCodeItem {
  pos_order_code: string;
  pos_order_desc: string;
  signature_required: string;
  ship_via_code: string;
  default_selection: string;
}

export interface PartStatusItem {
  pos_part_status: string;
}

export interface OrderSummaryItem {
  pos_order_summary: string;
  pos_order_summary_label: string;
}
export interface OrderSummarySubtotalItem {
  pos_order_summary_subtotal: string;
  pos_order_summary_subtotal_label: string;
}

export interface POSInvLookupItem {
  pos_inv_lookups: string;
  inv_lookup_desc: string;
}

export enum CreditCardPinPanModes {
  HostSale = "H",
  Tokenize = "T",
}

export enum CreditCardPinPanMethods {
  Manual = "manual",
  Capture = "capture",
}

export enum OrderHeaderShipToDisplayOptions {
  Top = "Top",
  Bottom = "Bottom",
  None = "None",
}

export enum POSRegisterSelectionMode {
  Hardware = "H",
  User = "U",
}

import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withKeys as _withKeys, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "text-xl font-semibold" }
const _hoisted_2 = { class: "p-inputgroup mt-2" }
const _hoisted_3 = { class: "p-inputgroup mt-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Password = _resolveComponent("Password")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createBlock(_component_Dialog, {
    visible: _ctx.show,
    "onUpdate:visible": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.show) = $event)),
    closable: false,
    draggable: false,
    modal: "",
    onKeyup: _cache[4] || (_cache[4] = _withKeys(($event: any) => (_ctx.authenticate()), ["enter"]))
  }, {
    header: _withCtx(() => [
      _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.title), 1)
    ]),
    footer: _withCtx(() => [
      _createVNode(_component_Button, {
        label: "Authenticate",
        icon: "pi pi-check",
        loading: _ctx.isLoading,
        disabled: _ctx.isAuthenticateDisabled,
        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.authenticate()))
      }, null, 8, ["loading", "disabled"])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_2, [
        _cache[5] || (_cache[5] = _createElementVNode("small", {
          class: "p-inputgroup-addon font-semibold general-label-color",
          style: { width: '104px' }
        }, "Username", -1)),
        _createVNode(_component_InputText, {
          modelValue: _ctx.admin_user,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.admin_user) = $event)),
          id: "reg",
          class: "text-sm"
        }, null, 8, ["modelValue"])
      ]),
      _createElementVNode("div", _hoisted_3, [
        _cache[6] || (_cache[6] = _createElementVNode("small", {
          class: "p-inputgroup-addon font-semibold general-label-color",
          style: { width: '104px' }
        }, "Password", -1)),
        _createVNode(_component_Password, {
          modelValue: _ctx.admin_pwd,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.admin_pwd) = $event)),
          toggleMask: "",
          id: "reg",
          class: "text-sm"
        }, null, 8, ["modelValue"])
      ])
    ]),
    _: 1
  }, 8, ["visible"]))
}
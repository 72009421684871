import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "statcard-wrapper" }
const _hoisted_2 = {
  class: "grid p-0",
  style: {"height":"auto"}
}
const _hoisted_3 = { style: {"font-size":"1.5rem","font-weight":"400"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Card = _resolveComponent("Card")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Card, {
      class: _normalizeClass({ 'statcard-selected': _ctx.selected })
    }, {
      content: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.title), 1)
        ])
      ]),
      _: 1
    }, 8, ["class"])
  ]))
}
import { resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createVNode as _createVNode, withCtx as _withCtx, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  class: "flex",
  style: {
          width: '100% !important',
          display: 'flex !important',
          justifyContent: 'space-between',
          paddingLeft: '15px',
        }
}
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Column = _resolveComponent("Column")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _component_Dialog = _resolveComponent("Dialog")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.showAllButton)
      ? _withDirectives((_openBlock(), _createBlock(_component_Button, {
          key: 0,
          icon: "pi pi-search",
          iconPos: "left",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showDialog = true)),
          class: "text-center",
          outlined: "",
          style: { padding: '3px', marginLeft: '5px' }
        }, null, 512)), [
          [_directive_tooltip, 'Search Ship Addresses']
        ])
      : (_openBlock(), _createBlock(_component_Button, {
          key: 1,
          label: "Show All",
          icon: "pi pi-search",
          iconPos: "left",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showDialog = true)),
          class: "text-center",
          style: { width: '130px' }
        })),
    _createVNode(_component_Dialog, {
      visible: _ctx.showDialog,
      "onUpdate:visible": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.showDialog) = $event)),
      class: "addresses-dialog",
      style: { width: '90%' },
      modal: true,
      draggable: false,
      closable: false
    }, {
      header: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _cache[6] || (_cache[6] = _createElementVNode("h2", { class: "my-0" }, "Ship Addresses", -1)),
          (_ctx.showAddressButton)
            ? (_openBlock(), _createBlock(_component_Button, {
                key: 0,
                label: "Use New Address",
                icon: "pi pi-plus",
                iconPos: "left",
                onClick: _ctx.handleUseNewAddress
              }, null, 8, ["onClick"]))
            : _createCommentVNode("", true)
        ])
      ]),
      footer: _withCtx(() => [
        _createVNode(_component_Button, {
          label: "Close",
          onClick: _ctx.handleCloseModeOptionsDialog
        }, null, 8, ["onClick"])
      ]),
      default: _withCtx(() => [
        (_ctx.lazyLoadShipTos)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createVNode(_component_DataTable, {
                filters: _ctx.filters,
                "onUpdate:filters": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.filters) = $event)),
                globalFilterFields: Object.keys(_ctx.filters),
                filterDisplay: "menu",
                lazy: true,
                value: _ctx.displayRecords,
                totalRecords: _ctx.totalRecords,
                loading: _ctx.isLoading,
                paginator: "",
                rows: _ctx.rowsPerPage,
                rowsPerPageOptions: _ctx.rowsPerPageOptions,
                class: _normalizeClass(_ctx.tableClass),
                onSort: _cache[3] || (_cache[3] = ($event: any) => (_ctx.sortData($event))),
                onFilter: _ctx.handleFilter,
                onPage: _ctx.onPage,
                onRowClick: _ctx.handleRowClick
              }, {
                empty: _withCtx(() => _cache[8] || (_cache[8] = [
                  _createElementVNode("div", { class: "flex justify-content-center" }, [
                    _createElementVNode("span", { class: "text-center" }, "No Results Found")
                  ], -1)
                ])),
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.lazyLoadColumns, (column) => {
                    return (_openBlock(), _createBlock(_component_Column, {
                      key: column.field_name,
                      field: column.field_name,
                      header: column.display_name,
                      sortable: ""
                    }, {
                      body: _withCtx(({ data }) => [
                        (column.type == 'array')
                          ? (_openBlock(), _createElementBlock("span", _hoisted_3, [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(data.ship_to_items, (address) => {
                                return (_openBlock(), _createElementBlock("div", {
                                  key: address.ship_to
                                }, [
                                  _createTextVNode(_toDisplayString(address.ship_to) + " ", 1),
                                  _cache[7] || (_cache[7] = _createElementVNode("br", null, null, -1))
                                ]))
                              }), 128))
                            ]))
                          : (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(data[column.field_name]), 1))
                      ]),
                      filter: _withCtx(({ filterModel }) => [
                        _createVNode(_component_InputText, {
                          modelValue: filterModel.value,
                          "onUpdate:modelValue": ($event: any) => ((filterModel.value) = $event),
                          type: "text",
                          placeholder: "Enter filter text"
                        }, null, 8, ["modelValue", "onUpdate:modelValue"])
                      ]),
                      _: 2
                    }, 1032, ["field", "header"]))
                  }), 128))
                ]),
                _: 1
              }, 8, ["filters", "globalFilterFields", "value", "totalRecords", "loading", "rows", "rowsPerPageOptions", "class", "onFilter", "onPage", "onRowClick"])
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_5, [
              _createVNode(_component_DataTable, {
                value: _ctx.shipToAddresses,
                paginator: true,
                rows: _ctx.rowsPerPage,
                paginatorTemplate: "FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown",
                rowsPerPageOptions: _ctx.rowsPerPageOptions,
                currentPageReportTemplate: "Showing {first} to {last} of {totalRecords} addresses",
                class: _normalizeClass(_ctx.tableClass),
                responsiveLayout: "stack",
                breakpoint: "991px",
                "row-class": _ctx.handleRowName,
                onRowClick: _ctx.handleRowClick,
                "removable-sort": "",
                filterDisplay: "menu",
                filters: _ctx.filters,
                "onUpdate:filters": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.filters) = $event))
              }, {
                empty: _withCtx(() => _cache[9] || (_cache[9] = [
                  _createElementVNode("h4", { class: "text-center" }, "No addresses found.", -1)
                ])),
                default: _withCtx(() => [
                  _createVNode(_component_Column, {
                    field: "ship_seq",
                    header: "Ship Seq",
                    bodyClass: "text-center",
                    showFilterMatchModes: false,
                    showFilterOperator: false,
                    sortable: ""
                  }, {
                    filter: _withCtx(({ filterModel }) => [
                      _createVNode(_component_InputText, {
                        placeholder: "Search By Sequence",
                        modelValue: filterModel.value,
                        "onUpdate:modelValue": ($event: any) => ((filterModel.value) = $event)
                      }, null, 8, ["modelValue", "onUpdate:modelValue"])
                    ]),
                    filterapply: _withCtx(({ filterCallback }) => [
                      _createVNode(_component_Button, {
                        label: "Search",
                        onClick: ($event: any) => (filterCallback())
                      }, null, 8, ["onClick"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_Column, {
                    field: "ship_name",
                    header: "Ship name",
                    showFilterMatchModes: false,
                    showFilterOperator: false,
                    sortable: ""
                  }, {
                    filter: _withCtx(({ filterModel }) => [
                      _createVNode(_component_InputText, {
                        placeholder: "Search By Name",
                        modelValue: filterModel.value,
                        "onUpdate:modelValue": ($event: any) => ((filterModel.value) = $event)
                      }, null, 8, ["modelValue", "onUpdate:modelValue"])
                    ]),
                    filterapply: _withCtx(({ filterCallback }) => [
                      _createVNode(_component_Button, {
                        label: "Search",
                        onClick: ($event: any) => (filterCallback())
                      }, null, 8, ["onClick"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_Column, { header: "Street" }, {
                    body: _withCtx(({ data }) => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(data.ship_to_items, (address) => {
                        return (_openBlock(), _createElementBlock("div", {
                          key: address.ship_to
                        }, [
                          _createTextVNode(_toDisplayString(address.ship_to) + " ", 1),
                          _cache[10] || (_cache[10] = _createElementVNode("br", null, null, -1))
                        ]))
                      }), 128))
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_Column, {
                    field: "ship_city",
                    header: "City",
                    showFilterMatchModes: false,
                    showFilterOperator: false,
                    sortable: ""
                  }, {
                    filter: _withCtx(({ filterModel }) => [
                      _createVNode(_component_InputText, {
                        placeholder: "Search By City",
                        modelValue: filterModel.value,
                        "onUpdate:modelValue": ($event: any) => ((filterModel.value) = $event)
                      }, null, 8, ["modelValue", "onUpdate:modelValue"])
                    ]),
                    filterapply: _withCtx(({ filterCallback }) => [
                      _createVNode(_component_Button, {
                        label: "Search",
                        onClick: ($event: any) => (filterCallback())
                      }, null, 8, ["onClick"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_Column, {
                    field: "ship_state",
                    header: "State",
                    showFilterMatchModes: false,
                    showFilterOperator: false,
                    sortable: ""
                  }, {
                    filter: _withCtx(({ filterModel }) => [
                      _createVNode(_component_InputText, {
                        placeholder: "Search By State",
                        modelValue: filterModel.value,
                        "onUpdate:modelValue": ($event: any) => ((filterModel.value) = $event)
                      }, null, 8, ["modelValue", "onUpdate:modelValue"])
                    ]),
                    filterapply: _withCtx(({ filterCallback }) => [
                      _createVNode(_component_Button, {
                        label: "Search",
                        onClick: ($event: any) => (filterCallback())
                      }, null, 8, ["onClick"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_Column, {
                    field: "ship_zip",
                    header: "Zip",
                    showFilterMatchModes: false,
                    showFilterOperator: false,
                    sortable: ""
                  }, {
                    filter: _withCtx(({ filterModel }) => [
                      _createVNode(_component_InputText, {
                        placeholder: "Search By Zip",
                        modelValue: filterModel.value,
                        "onUpdate:modelValue": ($event: any) => ((filterModel.value) = $event)
                      }, null, 8, ["modelValue", "onUpdate:modelValue"])
                    ]),
                    filterapply: _withCtx(({ filterCallback }) => [
                      _createVNode(_component_Button, {
                        label: "Search",
                        onClick: ($event: any) => (filterCallback())
                      }, null, 8, ["onClick"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_Column, {
                    field: "ship_country",
                    header: "Country",
                    showFilterMatchModes: false,
                    showFilterOperator: false,
                    sortable: ""
                  }, {
                    filter: _withCtx(({ filterModel }) => [
                      _createVNode(_component_InputText, {
                        placeholder: "Search By Country",
                        modelValue: filterModel.value,
                        "onUpdate:modelValue": ($event: any) => ((filterModel.value) = $event)
                      }, null, 8, ["modelValue", "onUpdate:modelValue"])
                    ]),
                    filterapply: _withCtx(({ filterCallback }) => [
                      _createVNode(_component_Button, {
                        label: "Search",
                        onClick: ($event: any) => (filterCallback())
                      }, null, 8, ["onClick"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_Column, {
                    field: "ship_attn",
                    header: "Attention",
                    showFilterMatchModes: false,
                    showFilterOperator: false,
                    sortable: ""
                  }, {
                    filter: _withCtx(({ filterModel }) => [
                      _createVNode(_component_InputText, {
                        placeholder: "Search By Attention",
                        modelValue: filterModel.value,
                        "onUpdate:modelValue": ($event: any) => ((filterModel.value) = $event)
                      }, null, 8, ["modelValue", "onUpdate:modelValue"])
                    ]),
                    filterapply: _withCtx(({ filterCallback }) => [
                      _createVNode(_component_Button, {
                        label: "Search",
                        onClick: ($event: any) => (filterCallback())
                      }, null, 8, ["onClick"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_Column, {
                    field: "ship_phone",
                    header: "Phone"
                  }),
                  _createVNode(_component_Column, {
                    field: "ship_email",
                    header: "Email"
                  })
                ]),
                _: 1
              }, 8, ["value", "rows", "rowsPerPageOptions", "class", "row-class", "onRowClick", "filters"])
            ]))
      ]),
      _: 1
    }, 8, ["visible"])
  ], 64))
}
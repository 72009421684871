import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "grid m-0" }
const _hoisted_2 = { class: "flex col-12 justify-content-end align-items-center my-3" }
const _hoisted_3 = { class: "flex flex-wrap justify-content-end" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = ["href"]
const _hoisted_6 = { key: 0 }
const _hoisted_7 = ["href"]
const _hoisted_8 = { class: "field" }
const _hoisted_9 = { class: "field-checkbox" }
const _hoisted_10 = { class: "field" }
const _hoisted_11 = { class: "confirmation-content" }
const _hoisted_12 = { key: 0 }
const _hoisted_13 = { class: "confirmation-content" }
const _hoisted_14 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SplitButton = _resolveComponent("SplitButton")!
  const _component_LoadingSpinner = _resolveComponent("LoadingSpinner")!
  const _component_Column = _resolveComponent("Column")!
  const _component_Button = _resolveComponent("Button")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _component_Card = _resolveComponent("Card")!
  const _component_ContactDialog = _resolveComponent("ContactDialog")!
  const _component_ContactAutocomplete = _resolveComponent("ContactAutocomplete")!
  const _component_Checkbox = _resolveComponent("Checkbox")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_SplitButton, {
            label: "New",
            icon: "pi pi-plus",
            onClick: _ctx.createnewContact,
            model: _ctx.items
          }, null, 8, ["onClick", "model"])
        ])
      ])
    ]),
    _createVNode(_component_Card, null, {
      content: _withCtx(() => [
        _createVNode(_component_LoadingSpinner, { loading: _ctx.loading }, null, 8, ["loading"]),
        (!_ctx.loading)
          ? (_openBlock(), _createBlock(_component_DataTable, {
              key: 0,
              ref: "dt",
              value: _ctx.contacts?.contact_items,
              class: "p-datatable-sm",
              scrollable: false,
              filters: _ctx.filters,
              dataKey: "contact_id",
              rowHover: true,
              breakpoint: "1279px",
              paginator: "",
              rows: 10,
              rowsPerPageOptions: [10, 20, 50]
            }, {
              empty: _withCtx(() => _cache[9] || (_cache[9] = [
                _createTextVNode(" No contacts to display. ")
              ])),
              default: _withCtx(() => [
                _createVNode(_component_Column, {
                  field: "first_name",
                  header: "First Name",
                  style: {"min-width":"14rem"},
                  sortable: ""
                }),
                _createVNode(_component_Column, {
                  field: "last_name",
                  header: "Last Name",
                  style: {"min-width":"14rem"},
                  sortable: ""
                }),
                _createVNode(_component_Column, {
                  header: "Email",
                  style: {"min-width":"14rem"}
                }, {
                  body: _withCtx((slotProps) => [
                    (_ctx.validEmailAddress(slotProps.data))
                      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                          _createElementVNode("a", {
                            href: `mailto:${slotProps.data.email_address_items[0].email_address}`
                          }, _toDisplayString(slotProps.data.email_address_items[0].email_address), 9, _hoisted_5)
                        ]))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                }),
                _createVNode(_component_Column, {
                  field: "telephone_items[0].telephone",
                  header: "Phone",
                  style: { minWidth: 'fit-content' },
                  dataType: "string",
                  bodyStyle: "text-align: right"
                }, {
                  body: _withCtx((slotProps) => [
                    (
                  slotProps.data.telephone_items !== null &&
                  slotProps.data.telephone_items !== undefined &&
                  slotProps.data.telephone_items.length !== 0
                )
                      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                          _createElementVNode("a", {
                            href: `tel:${slotProps.data.telephone_items[0].telephone}`
                          }, _toDisplayString(slotProps.data.telephone_items[0].telephone), 9, _hoisted_7)
                        ]))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                }),
                _createVNode(_component_Column, {
                  field: "job_title",
                  header: "Title",
                  style: { minWidth: 'fit-content' }
                }),
                _createVNode(_component_Column, {
                  field: "Action",
                  exportable: false,
                  style: { minWidth: 'fit-content' }
                }, {
                  body: _withCtx((slotProps) => [
                    _createVNode(_component_Button, {
                      icon: "pi pi-pencil",
                      title: "Edit",
                      onClick: ($event: any) => (_ctx.editcontact(slotProps.data))
                    }, null, 8, ["onClick"]),
                    _cache[10] || (_cache[10] = _createTextVNode("   ")),
                    _createVNode(_component_Button, {
                      icon: "pi pi-trash",
                      title: "Delete",
                      onClick: ($event: any) => (_ctx.confirmDeletecontact(slotProps.data))
                    }, null, 8, ["onClick"]),
                    _cache[11] || (_cache[11] = _createTextVNode("   ")),
                    _createVNode(_component_Button, {
                      icon: "pi pi-undo",
                      title: "Undo",
                      onClick: ($event: any) => (_ctx.confirmResetPwd(slotProps.data))
                    }, null, 8, ["onClick"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["value", "filters"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createVNode(_component_ContactDialog, {
      visible: _ctx.contactDialog,
      isNewContact: _ctx.isNewContact,
      index: _ctx.index,
      contactsInfoToEdit: _ctx.contactsInfoToEdit,
      onOnDialogClose: _ctx.handleCloseContactDialog,
      onOnContactSave: _ctx.loadContacts
    }, null, 8, ["visible", "isNewContact", "index", "contactsInfoToEdit", "onOnDialogClose", "onOnContactSave"]),
    _createVNode(_component_Dialog, {
      visible: _ctx.existingContactDialog,
      "onUpdate:visible": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.existingContactDialog) = $event)),
      style: { width: '450px' },
      header: "Existing Contact Details",
      modal: true,
      class: "p-fluid"
    }, {
      footer: _withCtx(() => [
        _createVNode(_component_Button, {
          label: "Cancel",
          icon: "pi pi-times",
          class: "p-button-text",
          onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.existingContactDialog = false))
        }),
        _createVNode(_component_Button, {
          label: "Save",
          icon: "pi pi-check",
          class: "p-button-text",
          onClick: _ctx.saveExistingContact
        }, null, 8, ["onClick"])
      ]),
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_8, [
          _cache[12] || (_cache[12] = _createElementVNode("label", { for: "existing_contact" }, "Select Contact", -1)),
          _createVNode(_component_ContactAutocomplete, {
            "selected-id": _ctx.existingContact.contact_id,
            onItemSelect: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handleContactSelected($event)))
          }, null, 8, ["selected-id"])
        ]),
        _createElementVNode("div", _hoisted_9, [
          _createVNode(_component_Checkbox, {
            id: "contact_portal",
            binary: true,
            modelValue: _ctx.existingContact.contact_portal,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.existingContact.contact_portal) = $event)),
            ref: "checkbox"
          }, null, 8, ["modelValue"]),
          _cache[13] || (_cache[13] = _createElementVNode("label", { for: "contact_portal" }, "Portal", -1))
        ]),
        _createElementVNode("div", _hoisted_10, [
          _cache[14] || (_cache[14] = _createElementVNode("label", { for: "contact_type" }, "Type ", -1)),
          _createVNode(_component_Dropdown, {
            id: "contact_type",
            options: _ctx.contactTypes,
            optionLabel: "description",
            optionValue: "contact_id",
            modelValue: _ctx.existingContact.contact_type,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.existingContact.contact_type) = $event)),
            editable: true
          }, null, 8, ["options", "modelValue"])
        ])
      ]),
      _: 1
    }, 8, ["visible"]),
    _createVNode(_component_Dialog, {
      visible: _ctx.deletecontactDialog,
      "onUpdate:visible": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.deletecontactDialog) = $event)),
      style: { width: '450px' },
      header: "Confirm",
      modal: true,
      draggable: false
    }, {
      footer: _withCtx(() => [
        _createVNode(_component_Button, {
          label: "No",
          icon: "pi pi-times",
          class: "p-button-text",
          onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.deletecontactDialog = false))
        }),
        _createVNode(_component_Button, {
          label: "Yes",
          icon: "pi pi-check",
          class: "p-button-text",
          onClick: _ctx.deletecontact
        }, null, 8, ["onClick"])
      ]),
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_11, [
          _cache[15] || (_cache[15] = _createElementVNode("i", {
            class: "pi pi-exclamation-triangle mr-3",
            style: {"font-size":"2rem"}
          }, null, -1)),
          (_ctx.contactsinfo)
            ? (_openBlock(), _createElementBlock("span", _hoisted_12, "Are you sure you want to delete contact"))
            : _createCommentVNode("", true)
        ])
      ]),
      _: 1
    }, 8, ["visible"]),
    _createVNode(_component_Dialog, {
      visible: _ctx.resetPwdDialog,
      "onUpdate:visible": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.resetPwdDialog) = $event)),
      style: { width: '450px' },
      header: "Confirm",
      modal: true,
      draggable: false
    }, {
      footer: _withCtx(() => [
        _createVNode(_component_Button, {
          label: "No",
          icon: "pi pi-times",
          class: "p-button-text",
          onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.resetPwdDialog = false))
        }),
        _createVNode(_component_Button, {
          label: "Yes",
          icon: "pi pi-check",
          class: "p-button-text",
          onClick: _ctx.resetPwd
        }, null, 8, ["onClick"])
      ]),
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_13, [
          _cache[16] || (_cache[16] = _createElementVNode("i", {
            class: "pi pi-exclamation-triangle mr-3",
            style: {"font-size":"2rem"}
          }, null, -1)),
          (_ctx.contactsinfo)
            ? (_openBlock(), _createElementBlock("span", _hoisted_14, "Are you sure you want to reset this contact's password? "))
            : _createCommentVNode("", true)
        ])
      ]),
      _: 1
    }, 8, ["visible"])
  ]))
}
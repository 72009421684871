import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "grid mt-3" }
const _hoisted_2 = { class: "lg:col-6 col-12" }
const _hoisted_3 = { class: "p-inputgroup mt-2" }
const _hoisted_4 = { class: "lg:col-6 col-12" }
const _hoisted_5 = { class: "p-inputgroup mt-2" }
const _hoisted_6 = { class: "lg:col-6 col-12" }
const _hoisted_7 = { class: "p-inputgroup mt-2" }
const _hoisted_8 = { class: "lg:col-6 col-12" }
const _hoisted_9 = { class: "p-inputgroup mt-2" }
const _hoisted_10 = { class: "lg:col-6 col-12" }
const _hoisted_11 = { class: "p-inputgroup mt-2" }
const _hoisted_12 = { class: "lg:col-6 col-12" }
const _hoisted_13 = { class: "p-inputgroup mt-2" }
const _hoisted_14 = { class: "lg:col-6 col-12" }
const _hoisted_15 = { class: "p-inputgroup mt-2" }
const _hoisted_16 = { class: "lg:col-6 col-12" }
const _hoisted_17 = { class: "p-inputgroup mt-2" }
const _hoisted_18 = { class: "lg:col-6 col-12" }
const _hoisted_19 = { class: "p-inputgroup mt-2" }
const _hoisted_20 = { class: "lg:col-6 col-12" }
const _hoisted_21 = { class: "p-inputgroup mt-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Calendar = _resolveComponent("Calendar")!
  const _component_InputNumber = _resolveComponent("InputNumber")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_TextArea = _resolveComponent("TextArea")!
  const _component_Card = _resolveComponent("Card")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_Card, { class: "col-12" }, {
        content: _withCtx(() => [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _cache[10] || (_cache[10] = _createElementVNode("small", {
                class: "p-inputgroup-addon font-semibold general-label-color",
                style: { width: '134px' }
              }, "Part Number", -1)),
              _createVNode(_component_InputText, {
                disabled: "",
                modelValue: _ctx.orderToEdit.part_no,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.orderToEdit.part_no) = $event)),
                type: "text",
                class: "text-sm"
              }, null, 8, ["modelValue"])
            ])
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _cache[11] || (_cache[11] = _createElementVNode("small", {
                class: "p-inputgroup-addon font-semibold general-label-color",
                style: { width: '134px' }
              }, " Description ", -1)),
              _createVNode(_component_InputText, {
                disabled: "",
                modelValue: _ctx.orderToEdit.description,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.orderToEdit.description) = $event)),
                type: "text",
                class: "text-sm"
              }, null, 8, ["modelValue"])
            ])
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              _cache[12] || (_cache[12] = _createElementVNode("small", {
                class: "p-inputgroup-addon font-semibold general-label-color",
                style: { width: '134px' }
              }, " SO ID ", -1)),
              _createVNode(_component_InputText, {
                disabled: "",
                modelValue: _ctx.orderToEdit.so_id,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.orderToEdit.so_id) = $event)),
                type: "text",
                class: "text-sm"
              }, null, 8, ["modelValue"])
            ])
          ]),
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("div", _hoisted_9, [
              _cache[13] || (_cache[13] = _createElementVNode("small", {
                class: "p-inputgroup-addon font-semibold general-label-color",
                style: { width: '134px' }
              }, " SO Cust ", -1)),
              _createVNode(_component_InputText, {
                disabled: "",
                modelValue: _ctx.orderToEdit.customer,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.orderToEdit.customer) = $event)),
                type: "text",
                class: "text-sm"
              }, null, 8, ["modelValue"])
            ])
          ]),
          _createElementVNode("div", _hoisted_10, [
            _createElementVNode("div", _hoisted_11, [
              _cache[14] || (_cache[14] = _createElementVNode("small", {
                class: "p-inputgroup-addon font-semibold general-label-color",
                style: { width: '134px' }
              }, "Request Date", -1)),
              _createVNode(_component_Calendar, {
                disabled: "",
                modelValue: _ctx.orderToEdit.req_date,
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.orderToEdit.req_date) = $event)),
                showIcon: true,
                dateFormat: "mm/dd/yy",
                inputClass: "text-sm"
              }, null, 8, ["modelValue"])
            ])
          ]),
          _createElementVNode("div", _hoisted_12, [
            _createElementVNode("div", _hoisted_13, [
              _cache[15] || (_cache[15] = _createElementVNode("small", {
                class: "p-inputgroup-addon font-semibold general-label-color",
                style: { width: '134px' }
              }, "Confirm Ship Date", -1)),
              _createVNode(_component_Calendar, {
                disabled: "",
                modelValue: _ctx.orderToEdit.rel_date,
                "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.orderToEdit.rel_date) = $event)),
                showIcon: true,
                dateFormat: "mm/dd/yy",
                inputClass: "text-sm"
              }, null, 8, ["modelValue"])
            ])
          ]),
          _createElementVNode("div", _hoisted_14, [
            _createElementVNode("div", _hoisted_15, [
              _cache[16] || (_cache[16] = _createElementVNode("small", {
                class: "p-inputgroup-addon font-semibold general-label-color",
                style: { width: '134px' }
              }, " Quantity ", -1)),
              _createVNode(_component_InputNumber, {
                disabled: "",
                modelValue: _ctx.orderToEdit.wo_qty,
                "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.orderToEdit.wo_qty) = $event)),
                type: "text",
                class: "text-sm"
              }, null, 8, ["modelValue"])
            ])
          ]),
          _createElementVNode("div", _hoisted_16, [
            _createElementVNode("div", _hoisted_17, [
              _cache[17] || (_cache[17] = _createElementVNode("span", { class: "p-inputgroup-addon" }, "Status", -1)),
              _createVNode(_component_Dropdown, {
                disabled: "",
                modelValue: _ctx.orderToEdit.status,
                "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.orderToEdit.status) = $event)),
                options: _ctx.statusList,
                optionLabel: "label",
                optionValue: "id"
              }, null, 8, ["modelValue", "options"])
            ])
          ]),
          _createElementVNode("div", _hoisted_18, [
            _createElementVNode("div", _hoisted_19, [
              _cache[18] || (_cache[18] = _createElementVNode("span", { class: "p-inputgroup-addon" }, "Work Center Type", -1)),
              _createVNode(_component_Dropdown, {
                disabled: "",
                modelValue: _ctx.workCenter,
                "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.workCenter) = $event)),
                options: _ctx.workCenterList,
                optionLabel: "description",
                optionValue: "work_center",
                onChange: _ctx.setWorkCenter
              }, null, 8, ["modelValue", "options", "onChange"])
            ])
          ]),
          _createElementVNode("div", _hoisted_20, [
            _createElementVNode("div", _hoisted_21, [
              _cache[19] || (_cache[19] = _createElementVNode("span", { class: "p-inputgroup-addon" }, "Notes", -1)),
              _createVNode(_component_TextArea, {
                modelValue: _ctx.orderToEdit.notes,
                "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.orderToEdit.notes) = $event)),
                rows: "5"
              }, null, 8, ["modelValue"])
            ])
          ])
        ]),
        _: 1
      })
    ])
  ]))
}
import { resolveComponent as _resolveComponent, vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "grid" }
const _hoisted_2 = { class: "col-12" }
const _hoisted_3 = { class: "col-12" }
const _hoisted_4 = { class: "grid" }
const _hoisted_5 = { class: "col-12 lg:col-4" }
const _hoisted_6 = { class: "p-inputgroup mt-2" }
const _hoisted_7 = { key: 0 }
const _hoisted_8 = { class: "p-error" }
const _hoisted_9 = { class: "col-12 lg:col-4" }
const _hoisted_10 = { class: "p-inputgroup mt-2" }
const _hoisted_11 = { class: "col-12 lg:col-4" }
const _hoisted_12 = { class: "p-inputgroup mt-2" }
const _hoisted_13 = { class: "grid" }
const _hoisted_14 = { class: "col-12 lg:col-4" }
const _hoisted_15 = { class: "p-inputgroup mt-2" }
const _hoisted_16 = { class: "col-12 lg:col-4" }
const _hoisted_17 = { class: "p-inputgroup mt-2" }
const _hoisted_18 = { class: "col-12 lg:col-4" }
const _hoisted_19 = { class: "p-inputgroup mt-2" }
const _hoisted_20 = { class: "col-12 lg:col-4" }
const _hoisted_21 = { class: "p-inputgroup mt-2" }
const _hoisted_22 = { class: "col-12 lg:col-4" }
const _hoisted_23 = { class: "p-inputgroup mt-2" }
const _hoisted_24 = { class: "grid" }
const _hoisted_25 = { class: "col-12 lg:col-4" }
const _hoisted_26 = { class: "p-inputgroup mt-2" }
const _hoisted_27 = { class: "col-12 lg:col-4" }
const _hoisted_28 = { class: "p-inputgroup mt-2" }
const _hoisted_29 = { class: "col-12 lg:col-4" }
const _hoisted_30 = { class: "p-inputgroup mt-2" }
const _hoisted_31 = { class: "col-12 lg:col-4" }
const _hoisted_32 = { class: "p-inputgroup mt-2" }
const _hoisted_33 = { class: "col-12 lg:col-4" }
const _hoisted_34 = { class: "p-inputgroup mt-2" }
const _hoisted_35 = { class: "col-12 lg:col-12" }
const _hoisted_36 = { class: "grid" }
const _hoisted_37 = { class: "col-12 lg:col-4" }
const _hoisted_38 = { class: "p-inputgroup mt-2" }
const _hoisted_39 = { class: "col-12 lg:col-4" }
const _hoisted_40 = { class: "p-inputgroup mt-2" }
const _hoisted_41 = { class: "col-12 lg:col-4" }
const _hoisted_42 = { class: "p-inputgroup mt-2" }
const _hoisted_43 = { class: "col-12 lg:col-4" }
const _hoisted_44 = { class: "p-inputgroup mt-2" }
const _hoisted_45 = { class: "col-12 lg:col-4" }
const _hoisted_46 = { class: "p-inputgroup mt-2" }
const _hoisted_47 = { class: "col-12" }
const _hoisted_48 = { class: "flex justify-content-between align-items-center" }
const _hoisted_49 = { class: "flex align-items-center" }
const _hoisted_50 = {
  key: 0,
  class: "p-error"
}
const _hoisted_51 = { class: "" }
const _hoisted_52 = {
  key: 0,
  class: "blue-text font-bold product-name-label inline-block"
}
const _hoisted_53 = {
  key: 1,
  class: "blue-text font-bold product-name-label inline-block emphasized"
}
const _hoisted_54 = { class: "part-name-label inline-block" }
const _hoisted_55 = { class: "col-12" }
const _hoisted_56 = {
  key: 0,
  class: "col-3 mt-2"
}
const _hoisted_57 = { key: 1 }
const _hoisted_58 = { class: "grid" }
const _hoisted_59 = { class: "col-12 mt-2" }
const _hoisted_60 = {
  key: 0,
  class: "p-error"
}
const _hoisted_61 = { class: "col-12" }
const _hoisted_62 = { class: "p-inputgroup mt-2" }
const _hoisted_63 = { class: "col-12" }
const _hoisted_64 = { class: "p-inputgroup mt-2" }
const _hoisted_65 = { class: "field" }
const _hoisted_66 = {
  key: 0,
  class: "p-error"
}
const _hoisted_67 = {
  key: 1,
  class: "p-error"
}
const _hoisted_68 = { class: "field" }
const _hoisted_69 = {
  key: 0,
  class: "p-error"
}
const _hoisted_70 = {
  key: 1,
  class: "p-error"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_Search = _resolveComponent("Search")!
  const _component_ContactAutocomplete = _resolveComponent("ContactAutocomplete")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_AccordionTab = _resolveComponent("AccordionTab")!
  const _component_Calendar = _resolveComponent("Calendar")!
  const _component_TextArea = _resolveComponent("TextArea")!
  const _component_CustomFields = _resolveComponent("CustomFields")!
  const _component_Accordion = _resolveComponent("Accordion")!
  const _component_PartsPriceList = _resolveComponent("PartsPriceList")!
  const _component_Column = _resolveComponent("Column")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _component_Signature = _resolveComponent("Signature")!
  const _component_Dialog = _resolveComponent("Dialog")!
  const _component_InputNumber = _resolveComponent("InputNumber")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Dialog, {
      header: this.header,
      visible: this.hidden,
      "onUpdate:visible": _cache[26] || (_cache[26] = ($event: any) => ((this.hidden) = $event)),
      onHide: _cache[27] || (_cache[27] = ($event: any) => (_ctx.$emit('hide', this.hidden))),
      style: { width: '1000px' },
      modal: true,
      draggable: false
    }, {
      footer: _withCtx(() => [
        _createVNode(_component_Button, {
          "data-test": "cancelDialogButton",
          ref: "cancelDialogButton",
          label: "Cancel",
          icon: "pi pi-times",
          onClick: _cache[24] || (_cache[24] = 
          () => {
            _ctx.$emit('hide', false);
          }
        ),
          class: "p-button-text"
        }, null, 512),
        _createVNode(_component_Button, {
          "data-test": "submitDialogButton",
          ref: "submitDialogButton",
          label: "Save",
          icon: "pi pi-check",
          onClick: _cache[25] || (_cache[25] = ($event: any) => (this.submit())),
          loading: this.submitLoading,
          disabled: this.readOnly,
          autofocus: ""
        }, null, 8, ["loading", "disabled"])
      ]),
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _withDirectives(_createVNode(_component_Button, {
              icon: "pi pi-plus",
              label: "Load Quote",
              iconPos: "left",
              class: "m-2 ml-0",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openAddExistingQuoteDialog()))
            }, null, 512), [
              [_vShow, this.showLoadExistingQuoteBtn]
            ]),
            _withDirectives(_createVNode(_component_Button, {
              icon: "pi pi-plus",
              label: "Load Existing Order",
              iconPos: "left",
              class: "m-2 ml-0",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.openAddExistingOrderDialog()))
            }, null, 512), [
              [_vShow, this.showLoadExistingQuoteBtn]
            ])
          ]),
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_Accordion, {
              activeIndex: [0],
              multiple: true
            }, {
              default: _withCtx(() => [
                _createVNode(_component_AccordionTab, { header: "Customer Details" }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_4, [
                      _createElementVNode("div", _hoisted_5, [
                        _createElementVNode("div", _hoisted_6, [
                          _cache[48] || (_cache[48] = _createElementVNode("span", {
                            class: "p-inputgroup-addon",
                            style: { width: '103px' }
                          }, "Customer", -1)),
                          _createVNode(_component_Search, {
                            modelValue: this.newSalesOrderCust,
                            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((this.newSalesOrderCust) = $event)),
                            placeHolder: "Search for a Customer",
                            onFilterCust: _cache[3] || (_cache[3] = ($event: any) => (_ctx.setCust($event.value))),
                            file: "NEWSALES",
                            label: "name",
                            disabled: this.readOnly,
                            class: _normalizeClass({
                      'p-invalid':
                        _ctx.isFormSubmitted &&
                        _ctx.v$.newSalesOrderCust.required.$invalid,
                    })
                          }, null, 8, ["modelValue", "disabled", "class"])
                        ]),
                        (
                    _ctx.isFormSubmitted && _ctx.v$.newSalesOrderCust.required.$invalid
                  )
                          ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                              _createElementVNode("small", _hoisted_8, _toDisplayString(_ctx.v$.newSalesOrderCust.required.$message), 1)
                            ]))
                          : _createCommentVNode("", true)
                      ]),
                      _createElementVNode("div", _hoisted_9, [
                        _createElementVNode("div", _hoisted_10, [
                          _cache[49] || (_cache[49] = _createElementVNode("span", {
                            class: "p-inputgroup-addon",
                            style: { width: '103px' }
                          }, "Contact", -1)),
                          _createVNode(_component_ContactAutocomplete, {
                            "selected-id": _ctx.salesOrder.contact,
                            disabled: _ctx.readOnly,
                            onItemSelect: _cache[4] || (_cache[4] = ($event: any) => (_ctx.handleContactSelected($event)))
                          }, null, 8, ["selected-id", "disabled"])
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_11, [
                        _createElementVNode("div", _hoisted_12, [
                          _cache[50] || (_cache[50] = _createElementVNode("span", {
                            class: "p-inputgroup-addon",
                            style: { width: '103px' }
                          }, "Purchase Order #", -1)),
                          _createVNode(_component_InputText, {
                            modelValue: this.salesOrder.po_number,
                            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((this.salesOrder.po_number) = $event)),
                            disabled: this.readOnly
                          }, null, 8, ["modelValue", "disabled"])
                        ])
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_13, [
                      _createElementVNode("div", _hoisted_14, [
                        _createElementVNode("div", _hoisted_15, [
                          _cache[51] || (_cache[51] = _createElementVNode("span", {
                            class: "p-inputgroup-addon",
                            style: { width: '103px' }
                          }, "Email", -1)),
                          _createVNode(_component_InputText, {
                            modelValue: this.salesOrder.contact_email,
                            "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((this.salesOrder.contact_email) = $event)),
                            disabled: this.readOnly
                          }, null, 8, ["modelValue", "disabled"])
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_16, [
                        _createElementVNode("div", _hoisted_17, [
                          _cache[52] || (_cache[52] = _createElementVNode("span", {
                            class: "p-inputgroup-addon",
                            style: { width: '103px' }
                          }, "Phone", -1)),
                          _createVNode(_component_InputText, {
                            modelValue: this.salesOrder.phone,
                            "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((this.salesOrder.phone) = $event)),
                            disabled: this.readOnly
                          }, null, 8, ["modelValue", "disabled"])
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_18, [
                        _createElementVNode("div", _hoisted_19, [
                          _cache[53] || (_cache[53] = _createElementVNode("span", {
                            class: "p-inputgroup-addon",
                            style: { width: '103px' }
                          }, "Bill To", -1)),
                          _createVNode(_component_InputText, {
                            modelValue: this.salesOrder.bill_to,
                            "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((this.salesOrder.bill_to) = $event)),
                            disabled: this.readOnly
                          }, null, 8, ["modelValue", "disabled"])
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_20, [
                        _createElementVNode("div", _hoisted_21, [
                          _cache[54] || (_cache[54] = _createElementVNode("span", {
                            class: "p-inputgroup-addon",
                            style: { width: '103px' }
                          }, "Company", -1)),
                          _createVNode(_component_Dropdown, {
                            modelValue: this.salesOrder.co_code,
                            "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((this.salesOrder.co_code) = $event)),
                            options: _ctx.customerInquiry.coCodes,
                            optionLabel: "name",
                            optionValue: "code",
                            disabled: this.readOnly
                          }, null, 8, ["modelValue", "options", "disabled"])
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_22, [
                        _createElementVNode("div", _hoisted_23, [
                          _cache[55] || (_cache[55] = _createElementVNode("span", {
                            class: "p-inputgroup-addon",
                            style: { width: '103px' }
                          }, "Plan Group", -1)),
                          _createVNode(_component_Dropdown, {
                            modelValue: this.salesOrder.plan_group,
                            "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((this.salesOrder.plan_group) = $event)),
                            options: _ctx.customerInquiry.planGroupCodes,
                            optionLabel: "plan_group",
                            optionValue: "plan_group",
                            disabled: this.readOnly
                          }, null, 8, ["modelValue", "options", "disabled"])
                        ])
                      ])
                    ])
                  ]),
                  _: 1
                }),
                _createVNode(_component_AccordionTab, { header: "Order Details" }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_24, [
                      _createElementVNode("div", _hoisted_25, [
                        _createElementVNode("div", _hoisted_26, [
                          _cache[56] || (_cache[56] = _createElementVNode("span", {
                            class: "p-inputgroup-addon",
                            style: { width: '103px' }
                          }, "Type", -1)),
                          _createVNode(_component_Dropdown, {
                            modelValue: this.salesOrder.type,
                            "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((this.salesOrder.type) = $event)),
                            options: _ctx.sales.types,
                            optionLabel: "so_type_code_description",
                            optionValue: "valid_so_type",
                            disabled: this.readOnly
                          }, null, 8, ["modelValue", "options", "disabled"])
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_27, [
                        _createElementVNode("div", _hoisted_28, [
                          _cache[57] || (_cache[57] = _createElementVNode("span", {
                            class: "p-inputgroup-addon",
                            style: { width: '103px' }
                          }, "Hold", -1)),
                          _createVNode(_component_Dropdown, {
                            options: _ctx.customerInquiry.holdCodes,
                            optionLabel: "hold_code",
                            optionValue: "hold_code",
                            modelValue: this.salesOrder.hold,
                            "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((this.salesOrder.hold) = $event)),
                            disabled: this.readOnly
                          }, null, 8, ["options", "modelValue", "disabled"])
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_29, [
                        _createElementVNode("div", _hoisted_30, [
                          _cache[58] || (_cache[58] = _createElementVNode("span", {
                            class: "p-inputgroup-addon",
                            style: { width: '103px' }
                          }, "Hold Date", -1)),
                          _createVNode(_component_Calendar, {
                            showIcon: true,
                            modelValue: this.salesOrder.hold_date,
                            "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((this.salesOrder.hold_date) = $event)),
                            dateFormat: "mm-dd-yy",
                            manualInput: false,
                            onDateSelect: _cache[14] || (_cache[14] = ($event: any) => (_ctx.formatDate($event, 'HOLD_DATE'))),
                            disabled: this.readOnly
                          }, null, 8, ["modelValue", "disabled"])
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_31, [
                        _createElementVNode("div", _hoisted_32, [
                          _cache[59] || (_cache[59] = _createElementVNode("span", {
                            class: "p-inputgroup-addon",
                            style: { width: '103px' }
                          }, "Close Date", -1)),
                          _createVNode(_component_Calendar, {
                            showIcon: true,
                            modelValue: this.salesOrder.close_date,
                            "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((this.salesOrder.close_date) = $event)),
                            dateFormat: "mm-dd-yy",
                            manualInput: false,
                            onDateSelect: _cache[16] || (_cache[16] = ($event: any) => (_ctx.formatDate($event, 'CLOSE_DATE'))),
                            disabled: this.readOnly
                          }, null, 8, ["modelValue", "disabled"])
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_33, [
                        _createElementVNode("div", _hoisted_34, [
                          _cache[60] || (_cache[60] = _createElementVNode("span", {
                            class: "p-inputgroup-addon",
                            style: { width: '103px' }
                          }, "Status", -1)),
                          _createVNode(_component_Dropdown, {
                            modelValue: this.salesOrder.status,
                            "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => ((this.salesOrder.status) = $event)),
                            options: this.status,
                            optionLabel: "description",
                            optionValue: "value",
                            disabled: this.readOnly
                          }, null, 8, ["modelValue", "options", "disabled"])
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_35, [
                        _createVNode(_component_TextArea, {
                          class: "mt-2",
                          type: "text",
                          rows: "5",
                          placeholder: "Notes",
                          modelValue: _ctx.salesOrder.notes,
                          "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.salesOrder.notes) = $event)),
                          disabled: this.readOnly
                        }, null, 8, ["modelValue", "disabled"])
                      ])
                    ])
                  ]),
                  _: 1
                }),
                _createVNode(_component_AccordionTab, { header: "Pricing & Shipping Details" }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_36, [
                      _createElementVNode("div", _hoisted_37, [
                        _createElementVNode("div", _hoisted_38, [
                          _cache[61] || (_cache[61] = _createElementVNode("span", {
                            class: "p-inputgroup-addon",
                            style: { width: '103px' }
                          }, "Pay Terms", -1)),
                          _createVNode(_component_Dropdown, {
                            modelValue: this.salesOrder.terms_code,
                            "onUpdate:modelValue": _cache[19] || (_cache[19] = ($event: any) => ((this.salesOrder.terms_code) = $event)),
                            options: _ctx.customerInquiry.terms,
                            optionLabel: "desc",
                            optionValue: "terms_id",
                            disabled: this.readOnly
                          }, null, 8, ["modelValue", "options", "disabled"])
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_39, [
                        _createElementVNode("div", _hoisted_40, [
                          _cache[62] || (_cache[62] = _createElementVNode("span", {
                            class: "p-inputgroup-addon",
                            style: { width: '103px' }
                          }, "Price Code", -1)),
                          _createVNode(_component_Dropdown, {
                            modelValue: this.salesOrder.cust_code,
                            "onUpdate:modelValue": _cache[20] || (_cache[20] = ($event: any) => ((this.salesOrder.cust_code) = $event)),
                            options: _ctx.customerInquiry.pricingCodes,
                            optionLabel: "description",
                            optionValue: "code",
                            disabled: this.readOnly
                          }, null, 8, ["modelValue", "options", "disabled"])
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_41, [
                        _createElementVNode("div", _hoisted_42, [
                          _cache[63] || (_cache[63] = _createElementVNode("span", {
                            class: "p-inputgroup-addon",
                            style: { width: '103px' }
                          }, "Discount(%)", -1)),
                          _createVNode(_component_InputText, {
                            modelValue: this.discount,
                            "onUpdate:modelValue": _cache[21] || (_cache[21] = ($event: any) => ((this.discount) = $event)),
                            disabled: this.readOnly
                          }, null, 8, ["modelValue", "disabled"])
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_43, [
                        _createElementVNode("div", _hoisted_44, [
                          _cache[64] || (_cache[64] = _createElementVNode("span", {
                            class: "p-inputgroup-addon",
                            style: { width: '103px' }
                          }, "Resale ID", -1)),
                          _createVNode(_component_InputText, {
                            modelValue: this.salesOrder.resale_number,
                            "onUpdate:modelValue": _cache[22] || (_cache[22] = ($event: any) => ((this.salesOrder.resale_number) = $event)),
                            disabled: this.readOnly
                          }, null, 8, ["modelValue", "disabled"])
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_45, [
                        _createElementVNode("div", _hoisted_46, [
                          _cache[65] || (_cache[65] = _createElementVNode("span", {
                            class: "p-inputgroup-addon",
                            style: { width: '103px' }
                          }, "Ship Via", -1)),
                          _createVNode(_component_Dropdown, {
                            modelValue: _ctx.salesOrder.ship_via,
                            "onUpdate:modelValue": _cache[23] || (_cache[23] = ($event: any) => ((_ctx.salesOrder.ship_via) = $event)),
                            options: _ctx.shipViaItems,
                            optionLabel: "ship_via",
                            optionValue: "carrier_code",
                            disabled: this.readOnly
                          }, null, 8, ["modelValue", "options", "disabled"])
                        ])
                      ])
                    ])
                  ]),
                  _: 1
                }),
                (_ctx.customFields && _ctx.customFields.length > 0)
                  ? (_openBlock(), _createBlock(_component_AccordionTab, {
                      key: 0,
                      header: "Custom Fields"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_CustomFields, {
                          data: _ctx.salesOrder,
                          customFields: _ctx.customFields,
                          readOnly: _ctx.readOnly,
                          onUpdateObject: _ctx.updateObject
                        }, null, 8, ["data", "customFields", "readOnly", "onUpdateObject"])
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }),
            (!_ctx.readOnly)
              ? (_openBlock(), _createBlock(_component_Button, {
                  key: 0,
                  icon: "pi pi-search",
                  label: "View Parts By Price Code",
                  iconPos: "left",
                  onClick: _ctx.viewPartsByPriceCode,
                  disabled: !_ctx.newSalesOrderCust
                }, null, 8, ["onClick", "disabled"]))
              : _createCommentVNode("", true),
            (!_ctx.readOnly && _ctx.priceCodeParts.length > 0)
              ? (_openBlock(), _createBlock(_component_PartsPriceList, {
                  key: 1,
                  parts: _ctx.priceCodeParts,
                  onAddParts: _ctx.addSelectedParts
                }, null, 8, ["parts", "onAddParts"]))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_47, [
            _createElementVNode("div", null, [
              _createVNode(_component_DataTable, {
                value: this.salesOrder.lis_items
              }, {
                header: _withCtx(() => [
                  _createElementVNode("div", _hoisted_48, [
                    _cache[67] || (_cache[67] = _createElementVNode("h4", { class: "m-0" }, "Line Items", -1)),
                    _createElementVNode("div", _hoisted_49, [
                      (_ctx.isFormSubmitted && _ctx.v$.lis_items.required.$invalid)
                        ? (_openBlock(), _createElementBlock("small", _hoisted_50, _toDisplayString(_ctx.v$.lis_items.required.$message), 1))
                        : _createCommentVNode("", true),
                      _cache[66] || (_cache[66] = _createTextVNode("   ")),
                      _createVNode(_component_Button, {
                        icon: "pi pi-plus",
                        label: "Add Part",
                        iconPos: "left",
                        onClick: _ctx.validatePartReq,
                        disabled: this.readOnly
                      }, null, 8, ["onClick", "disabled"])
                    ])
                  ])
                ]),
                empty: _withCtx(() => _cache[68] || (_cache[68] = [
                  _createTextVNode(" No rows to show. ")
                ])),
                default: _withCtx(() => [
                  _createVNode(_component_Column, {
                    field: "li_parts",
                    header: "Part Number"
                  }, {
                    body: _withCtx((slotProps) => [
                      _createElementVNode("div", _hoisted_51, [
                        (slotProps.data.wrap_desc)
                          ? (_openBlock(), _createElementBlock("span", _hoisted_52, _toDisplayString(slotProps.data.wrap_desc), 1))
                          : (_openBlock(), _createElementBlock("span", _hoisted_53, "No description available ")),
                        _cache[69] || (_cache[69] = _createElementVNode("br", null, null, -1)),
                        _createElementVNode("span", _hoisted_54, "Part #: " + _toDisplayString(slotProps.data.li_parts), 1)
                      ])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_Column, {
                    field: "li_order_qtys",
                    header: "Quantity"
                  }),
                  _createVNode(_component_Column, {
                    field: "li_prices",
                    header: "Price"
                  }),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.customFieldsLineItems, (field) => {
                    return (_openBlock(), _createBlock(_component_Column, {
                      field: field.json_name,
                      header: field.display_name,
                      key: field.field_no
                    }, null, 8, ["field", "header"]))
                  }), 128))
                ]),
                _: 1
              }, 8, ["value"])
            ])
          ]),
          _createElementVNode("div", _hoisted_55, [
            (!_ctx.readOnly)
              ? (_openBlock(), _createElementBlock("div", _hoisted_56, [
                  _createVNode(_component_Button, {
                    style: { width: '100%' },
                    label: "Signature",
                    onClick: _ctx.showSignature
                  }, null, 8, ["onClick"])
                ]))
              : _createCommentVNode("", true),
            (_ctx.readOnly && _ctx.salesOrder.signature)
              ? (_openBlock(), _createElementBlock("div", _hoisted_57, [
                  _cache[70] || (_cache[70] = _createElementVNode("h3", null, "Signature", -1)),
                  _createVNode(_component_Signature, {
                    signature: _ctx.salesOrder.signature,
                    signatureType: _ctx.salesOrder.signature_type,
                    receivedBy: _ctx.salesOrder.signature_by,
                    receivedDate: _ctx.salesOrder.signature_date,
                    receivedDateTime: _ctx.salesOrder.signature_time,
                    readOnly: _ctx.readOnly
                  }, null, 8, ["signature", "signatureType", "receivedBy", "receivedDate", "receivedDateTime", "readOnly"])
                ]))
              : _createCommentVNode("", true)
          ])
        ])
      ]),
      _: 1
    }, 8, ["header", "visible"]),
    _createVNode(_component_Dialog, {
      visible: _ctx.showPartModal,
      "onUpdate:visible": _cache[34] || (_cache[34] = ($event: any) => ((_ctx.showPartModal) = $event)),
      header: "Add Part",
      modal: true,
      style: { width: '500px' }
    }, {
      footer: _withCtx(() => [
        _createVNode(_component_Button, {
          label: "Cancel",
          icon: "pi pi-times",
          onClick: _cache[33] || (_cache[33] = 
          () => {
            this.showPartModal = false;
          }
        ),
          class: "p-button-text"
        }),
        _createVNode(_component_Button, {
          label: "Submit",
          icon: "pi pi-check",
          onClick: _ctx.addPart,
          autofocus: "",
          loading: _ctx.partLoading
        }, null, 8, ["onClick", "loading"])
      ]),
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_58, [
          _createElementVNode("div", _hoisted_59, [
            _createVNode(_component_Search, {
              modelValue: this.part.number,
              "onUpdate:modelValue": _cache[28] || (_cache[28] = ($event: any) => ((this.part.number) = $event)),
              placeHolder: "Search for a Part",
              onFilterPart: _cache[29] || (_cache[29] = ($event: any) => (_ctx.setPart($event))),
              file: "PARTS",
              label: "part_no"
            }, null, 8, ["modelValue"]),
            _createElementVNode("div", null, [
              (_ctx.isFormSubmitted && _ctx.v$.part.number.required.$invalid)
                ? (_openBlock(), _createElementBlock("small", _hoisted_60, _toDisplayString(_ctx.v$.part.number.required.$message), 1))
                : _createCommentVNode("", true)
            ])
          ]),
          _createElementVNode("div", _hoisted_61, [
            _createElementVNode("div", _hoisted_62, [
              _cache[71] || (_cache[71] = _createElementVNode("span", {
                class: "p-inputgroup-addon",
                style: { width: '103px' }
              }, "Quantity", -1)),
              _createVNode(_component_InputNumber, {
                modelValue: this.part.quantity,
                "onUpdate:modelValue": _cache[30] || (_cache[30] = ($event: any) => ((this.part.quantity) = $event)),
                min: 1
              }, null, 8, ["modelValue"])
            ])
          ]),
          _createElementVNode("div", _hoisted_63, [
            _createElementVNode("div", _hoisted_64, [
              _cache[72] || (_cache[72] = _createElementVNode("span", {
                class: "p-inputgroup-addon",
                style: { width: '103px' }
              }, "Date", -1)),
              _createVNode(_component_Calendar, {
                modelValue: this.part.date,
                "onUpdate:modelValue": _cache[31] || (_cache[31] = ($event: any) => ((this.part.date) = $event)),
                selectionMode: "single",
                dateFormat: "mm-dd-yy",
                onDateSelect: _cache[32] || (_cache[32] = ($event: any) => (_ctx.formatDate($event, 'PART')))
              }, null, 8, ["modelValue"])
            ])
          ])
        ])
      ]),
      _: 1
    }, 8, ["visible"]),
    _createVNode(_component_Dialog, {
      visible: this.showAddExistingQuoteDialog,
      "onUpdate:visible": _cache[38] || (_cache[38] = ($event: any) => ((this.showAddExistingQuoteDialog) = $event)),
      header: "Search for Quote",
      modal: true,
      style: { width: '500px' },
      onHide: _cache[39] || (_cache[39] = 
      () => {
        this.resetFields(`QUOTE`);
      }
    ),
      onShow: _cache[40] || (_cache[40] = 
      () => {
        this.focusSOQuoteSearch();
      }
    )
    }, {
      footer: _withCtx(() => [
        _createVNode(_component_Button, {
          label: "Cancel",
          icon: "pi pi-times",
          onClick: _cache[36] || (_cache[36] = 
          () => {
            this.showAddExistingQuoteDialog = false;
          }
        ),
          class: "p-button-text"
        }),
        _createVNode(_component_Button, {
          label: "Populate Quote",
          icon: "pi pi-check",
          onClick: _cache[37] || (_cache[37] = ($event: any) => (_ctx.populateDialog())),
          loading: this.isLoadingQuote
        }, null, 8, ["loading"])
      ]),
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_65, [
          _createVNode(_component_InputText, {
            ref: "soquoteInputText",
            placeholder: "Search by Id",
            modelValue: this.existingSOQuote,
            "onUpdate:modelValue": _cache[35] || (_cache[35] = ($event: any) => ((this.existingSOQuote) = $event)),
            disabled: this.readOnly
          }, null, 8, ["modelValue", "disabled"]),
          _cache[73] || (_cache[73] = _createTextVNode("   ")),
          (_ctx.isOrderEmpty && !!this.statusMessage)
            ? (_openBlock(), _createElementBlock("small", _hoisted_66, _toDisplayString(this.statusMessage), 1))
            : _createCommentVNode("", true),
          (_ctx.isLoadQuoteFormSubmitted && _ctx.v$.existingSOQuote.required.$invalid)
            ? (_openBlock(), _createElementBlock("small", _hoisted_67, _toDisplayString(_ctx.v$.existingSOQuote.required.$message), 1))
            : _createCommentVNode("", true)
        ])
      ]),
      _: 1
    }, 8, ["visible"]),
    _createVNode(_component_Dialog, {
      visible: _ctx.showAddExistingOrderDialog,
      "onUpdate:visible": _cache[44] || (_cache[44] = ($event: any) => ((_ctx.showAddExistingOrderDialog) = $event)),
      header: "Search for Order",
      modal: true,
      style: { width: '500px' },
      onHide: _cache[45] || (_cache[45] = 
      () => {
        this.resetFields(`ORDER`);
      }
    ),
      onShow: _cache[46] || (_cache[46] = 
      () => {
        this.focusOrderSearch();
      }
    )
    }, {
      footer: _withCtx(() => [
        _createVNode(_component_Button, {
          label: "Cancel",
          icon: "pi pi-times",
          onClick: _cache[42] || (_cache[42] = 
          () => {
            this.showAddExistingOrderDialog = false;
          }
        ),
          class: "p-button-text"
        }),
        _createVNode(_component_Button, {
          label: "Populate Order",
          icon: "pi pi-check",
          onClick: _cache[43] || (_cache[43] = ($event: any) => (_ctx.populateDialogWithOrder())),
          loading: this.isLoadingOrder
        }, null, 8, ["loading"])
      ]),
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_68, [
          _createVNode(_component_InputText, {
            ref: "orderInputText",
            placeholder: "Search by Id",
            modelValue: this.existingOrder,
            "onUpdate:modelValue": _cache[41] || (_cache[41] = ($event: any) => ((this.existingOrder) = $event)),
            disabled: this.readOnly
          }, null, 8, ["modelValue", "disabled"]),
          _cache[74] || (_cache[74] = _createTextVNode("   ")),
          (_ctx.isOrderEmpty && !!this.statusMessage)
            ? (_openBlock(), _createElementBlock("small", _hoisted_69, _toDisplayString(this.statusMessage), 1))
            : _createCommentVNode("", true),
          (_ctx.isLoadOrderFormSubmitted && _ctx.v$.existingOrder.required.$invalid)
            ? (_openBlock(), _createElementBlock("small", _hoisted_70, _toDisplayString(_ctx.v$.existingOrder.required.$message), 1))
            : _createCommentVNode("", true)
        ])
      ]),
      _: 1
    }, 8, ["visible"]),
    _createVNode(_component_Dialog, {
      visible: _ctx.visibleSignatureDialog,
      "onUpdate:visible": _cache[47] || (_cache[47] = ($event: any) => ((_ctx.visibleSignatureDialog) = $event)),
      modal: "",
      header: "Signature",
      style: { width: '80vw' }
    }, {
      default: _withCtx(() => [
        _createVNode(_component_Signature, {
          signature: _ctx.salesOrder.signature,
          signatureType: _ctx.salesOrder.signature_type,
          receivedBy: _ctx.salesOrder.signature_by,
          receivedDate: _ctx.salesOrder.signature_date,
          receivedDateTime: _ctx.salesOrder.signature_time,
          readOnly: _ctx.readOnly,
          onSave: _ctx.saveSignature
        }, null, 8, ["signature", "signatureType", "receivedBy", "receivedDate", "receivedDateTime", "readOnly", "onSave"])
      ]),
      _: 1
    }, 8, ["visible"])
  ], 64))
}
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, createTextVNode as _createTextVNode, withCtx as _withCtx, vShow as _vShow, withDirectives as _withDirectives, normalizeStyle as _normalizeStyle, Fragment as _Fragment, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  class: "mr-0",
  style: {"height":"100%"}
}
const _hoisted_2 = { class: "grid m-0" }
const _hoisted_3 = { class: "grid justify-content-start m-0" }
const _hoisted_4 = { class: "my-title" }
const _hoisted_5 = { class: "my-title" }
const _hoisted_6 = { class: "grid justify-content-end m-0" }
const _hoisted_7 = {
  key: 1,
  class: "reg-title"
}
const _hoisted_8 = { class: "text-xl font-medium pr-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NotificationCard = _resolveComponent("NotificationCard")!
  const _component_Button = _resolveComponent("Button")!
  const _component_CacheOfflineDataButton = _resolveComponent("CacheOfflineDataButton")!
  const _component_TieredMenu = _resolveComponent("TieredMenu")!
  const _component_SideMenu = _resolveComponent("SideMenu")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_Footer = _resolveComponent("Footer")!
  const _component_InformerAIForm = _resolveComponent("InformerAIForm")!
  const _component_ShortcutsDialog = _resolveComponent("ShortcutsDialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_NotificationCard),
      _createElementVNode("div", _hoisted_2, [
        (_ctx.session.user)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              (!_ctx.printingView)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    id: "topbar",
                    class: "p-toolbar p-component grid justify-content-between align-items-center w-full col-12 pt-0 m-0 sm:sticky relative",
                    role: "toolbar",
                    style: _normalizeStyle([{"height":"3.25rem","z-index":"100","top":"0px"}, {
            color: _ctx.theme.primaryColor,
            transition: 'top 0.5s ease',
            paddingBottom: '0px',
          }])
                  }, [
                    _createElementVNode("div", _hoisted_3, [
                      _createVNode(_component_Button, {
                        icon: "pi pi-bars",
                        onClick: _ctx.toggleSideMenu,
                        "aria-haspopup": "true",
                        "aria-controls": "overlay_tmenu",
                        "data-testid": "toggleSideMenuButton"
                      }, null, 8, ["onClick"]),
                      _cache[3] || (_cache[3] = _createElementVNode("img", {
                        class: "app-logo hidden md:block",
                        src: "https://prodroverstorage.blob.core.windows.net/rover/Rover-Logo-2-High-1.png"
                      }, null, -1)),
                      (_ctx.title.subtitle)
                        ? (_openBlock(), _createElementBlock("div", {
                            key: 0,
                            class: "app-title",
                            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.navigateToPage && _ctx.navigateToPage(...args)))
                          }, [
                            _createElementVNode("span", _hoisted_4, _toDisplayString("/ " + _ctx.title.subtitle), 1)
                          ]))
                        : (_openBlock(), _createElementBlock("div", {
                            key: 1,
                            class: "app-title",
                            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.navigateToPage && _ctx.navigateToPage(...args)))
                          }, [
                            _createElementVNode("span", _hoisted_5, _toDisplayString("/ " + _ctx.title.title), 1)
                          ]))
                    ]),
                    _withDirectives(_createElementVNode("div", _hoisted_6, [
                      (_ctx.isPointOfSale)
                        ? (_openBlock(), _createBlock(_component_CacheOfflineDataButton, { key: 0 }))
                        : _createCommentVNode("", true),
                      (_ctx.pos.register !== null && _ctx.title.title === 'POS')
                        ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                            _createElementVNode("span", _hoisted_8, " Register " + _toDisplayString(_ctx.pos.register.reg_id), 1),
                            _cache[4] || (_cache[4] = _createTextVNode("   "))
                          ]))
                        : _createCommentVNode("", true),
                      _createVNode(_component_Button, {
                        class: "p-button p-component p-button-icon-only p-button-rounded",
                        type: "button",
                        onClick: _ctx.toggleProfileMenu
                      }, {
                        default: _withCtx(() => _cache[5] || (_cache[5] = [
                          _createElementVNode("span", { class: "pi pi-user p-button-icon" }, null, -1),
                          _createElementVNode("span", { class: "p-button-label" }, " ", -1)
                        ])),
                        _: 1
                      }, 8, ["onClick"])
                    ], 512), [
                      [_vShow, _ctx.showAccountIcon]
                    ])
                  ], 4))
                : _createCommentVNode("", true),
              (!_ctx.printingView)
                ? (_openBlock(), _createBlock(_component_TieredMenu, {
                    key: 1,
                    model: _ctx.menuitems,
                    popup: true,
                    ref: "menu",
                    class: "tiered pointer"
                  }, null, 8, ["model"]))
                : _createCommentVNode("", true)
            ], 64))
          : _createCommentVNode("", true),
        (!_ctx.printingView)
          ? (_openBlock(), _createElementBlock("div", {
              key: 1,
              class: _normalizeClass(["col-fixed p-0", { 'fixed z-5': _ctx.sidemenu.floatingSidebar || _ctx.windowWidth < 576 }]),
              style: _normalizeStyle({ width: _ctx.sidemenuWidth + 'px', height: '100%' })
            }, [
              _createVNode(_component_SideMenu, {
                items: _ctx.sidemenu.links,
                onClick: _ctx.toggleSideMenuOnMobile
              }, null, 8, ["items", "onClick"])
            ], 6))
          : _createCommentVNode("", true),
        (!_ctx.printingView)
          ? (_openBlock(), _createBlock(_component_router_view, {
              key: 2,
              class: _normalizeClass(["col ml-0 p-0", { 'xl:ml-6': _ctx.sidemenu.floatingSidebar }]),
              style: {"height":"fit-content","width":"50%","min-height":"calc(100vh - 3.25rem - 30px)"}
            }, null, 8, ["class"]))
          : (_openBlock(), _createBlock(_component_router_view, {
              key: 3,
              class: "col ml-0 p-0",
              style: {"height":"fit-content","width":"100%","min-height":"calc(100vh - 3.25rem)"}
            })),
        (!_ctx.printingView)
          ? (_openBlock(), _createBlock(_component_Footer, { key: 4 }))
          : _createCommentVNode("", true)
      ])
    ]),
    _createVNode(_component_InformerAIForm),
    _createVNode(_component_ShortcutsDialog, {
      visible: _ctx.showShortcutsDialog,
      onClose: _cache[2] || (_cache[2] = ($event: any) => (_ctx.showShortcutsDialog = false))
    }, null, 8, ["visible"])
  ], 64))
}
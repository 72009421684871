import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "col-12" }
const _hoisted_2 = { class: "mt-2 table-wrapper" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { key: 2 }
const _hoisted_6 = {
  key: 0,
  class: "mt-2 sales-quotes-board-wrapper"
}
const _hoisted_7 = { class: "formgrid grid" }
const _hoisted_8 = { class: "col-11 field" }
const _hoisted_9 = { class: "col-11 field" }
const _hoisted_10 = { key: 0 }
const _hoisted_11 = { class: "p-error" }
const _hoisted_12 = { class: "col-11 field" }
const _hoisted_13 = { class: "col-11 field" }
const _hoisted_14 = { class: "col-11 field" }
const _hoisted_15 = { class: "field" }
const _hoisted_16 = { class: "field" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LoadingSpinner = _resolveComponent("LoadingSpinner")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Column = _resolveComponent("Column")!
  const _component_MultiselectWithButton = _resolveComponent("MultiselectWithButton")!
  const _component_Calendar = _resolveComponent("Calendar")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _component_KanbanView = _resolveComponent("KanbanView")!
  const _component_Chips = _resolveComponent("Chips")!
  const _component_Textarea = _resolveComponent("Textarea")!
  const _component_Dialog = _resolveComponent("Dialog")!
  const _component_ConfirmDialog = _resolveComponent("ConfirmDialog")!
  const _component_SoQuoteDialog = _resolveComponent("SoQuoteDialog")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_LoadingSpinner, { loading: _ctx.isLoadingSoQuotes }, null, 8, ["loading"]),
    _createElementVNode("div", _hoisted_2, [
      (!_ctx.isLoadingSoQuotes)
        ? (_openBlock(), _createBlock(_component_DataTable, {
            key: 0,
            value: _ctx.soQuotes,
            breakpoint: "1267px",
            dataKey: "id",
            rows: _ctx.rows,
            first: _ctx.first,
            "onUpdate:first": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.first) = $event)),
            sortField: _ctx.sortField,
            sortOrder: _ctx.sortOrder,
            selectionMode: "single",
            paginator: true,
            responsiveLayout: "stack",
            rowsPerPageOptions: [10, 25, 50],
            onSort: _cache[19] || (_cache[19] = ($event: any) => (_ctx.sortData($event))),
            onFilter: _ctx.handleFilter,
            onPage: _ctx.pageClick,
            onRowClick: _ctx.rowClick,
            filters: _ctx.filters,
            "onUpdate:filters": _cache[20] || (_cache[20] = ($event: any) => ((_ctx.filters) = $event)),
            filterDisplay: "menu",
            class: _normalizeClass(["p-datatable-sm tablehead-br-no-color", {
          'datatable-only-header column-header-separator': !_ctx.isResultView,
        }])
          }, {
            empty: _withCtx(() => _cache[30] || (_cache[30] = [
              _createTextVNode(" No records found ")
            ])),
            default: _withCtx(() => [
              _createVNode(_component_Column, {
                field: "id",
                header: "ID",
                showAddButton: false,
                showFilterMatchModes: false,
                showFilterOperator: false,
                sortable: _ctx.isResultView
              }, {
                filter: _withCtx(() => [
                  _createVNode(_component_InputText, {
                    type: "text",
                    modelValue: _ctx.selectedFilterId,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedFilterId) = $event)),
                    class: "p-column-filter",
                    placeholder: "Search by Id"
                  }, null, 8, ["modelValue"])
                ]),
                filterclear: _withCtx(() => [
                  _createVNode(_component_Button, {
                    type: "button",
                    label: "Clear",
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.clearQuoteIdSearch()))
                  })
                ]),
                filterapply: _withCtx(() => [
                  _createVNode(_component_Button, {
                    type: "button",
                    label: "Apply",
                    onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.fetchQuotesData(false)))
                  })
                ]),
                _: 1
              }, 8, ["sortable"]),
              (_ctx.customerIndex == null)
                ? (_openBlock(), _createBlock(_component_Column, {
                    key: 0,
                    field: "name",
                    header: "Customer",
                    showAddButton: false,
                    showFilterMatchModes: false,
                    showFilterOperator: false,
                    sortable: _ctx.isResultView
                  }, {
                    filter: _withCtx(() => [
                      _createVNode(_component_MultiselectWithButton, {
                        value: _ctx.selectedFilterCusts,
                        options: _ctx.searchedFilterCusts,
                        filterFields: ['name', 'cust_id'],
                        filter: "",
                        optionLabel: "name",
                        placeHolder: "Enter Customer ID or Name",
                        maxSelectedLabels: 2,
                        onFilter: _cache[3] || (_cache[3] = ($event: any) => (_ctx.handleCustomerSelectFilter($event))),
                        style: { width: '200px' },
                        btnLabel: "Search",
                        onBtnCallback: _cache[4] || (_cache[4] = ($event: any) => (_ctx.filterCust())),
                        onUpdateFilterValue: _cache[5] || (_cache[5] = 
                (filterValue) => (_ctx.selectedFilterCusts = filterValue)
              )
                      }, null, 8, ["value", "options"])
                    ]),
                    filterclear: _withCtx(() => [
                      _createVNode(_component_Button, {
                        type: "button",
                        label: "Clear",
                        onClick: _ctx.clearCustomerSearch
                      }, null, 8, ["onClick"])
                    ]),
                    filterapply: _withCtx(() => [
                      _createVNode(_component_Button, {
                        type: "button",
                        label: "Search",
                        onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.filterCust()))
                      })
                    ]),
                    _: 1
                  }, 8, ["sortable"]))
                : _createCommentVNode("", true),
              _createVNode(_component_Column, {
                field: "status",
                header: "Status",
                showAddButton: false,
                showFilterMatchModes: false,
                showFilterOperator: false,
                sortable: _ctx.isResultView
              }, {
                filter: _withCtx(() => [
                  _createVNode(_component_MultiselectWithButton, {
                    value: _ctx.selectedFilterStatus,
                    options: _ctx.status,
                    optionLabel: "status",
                    optionValue: "initial",
                    dataKey: "initial",
                    placeHolder: "Filter by Status",
                    filter: true,
                    filterMatchMode: "startsWith",
                    style: { width: '200px' },
                    btnLabel: "Apply",
                    onBtnCallback: _cache[7] || (_cache[7] = ($event: any) => (_ctx.fetchQuotesData(false))),
                    onUpdateFilterValue: _cache[8] || (_cache[8] = 
                (filterValue) => (_ctx.selectedFilterStatus = filterValue)
              )
                  }, null, 8, ["value", "options"])
                ]),
                filterclear: _withCtx(() => [
                  _createVNode(_component_Button, {
                    type: "button",
                    label: "Clear",
                    onClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.clearStatusSearchBar()))
                  })
                ]),
                filterapply: _withCtx(() => [
                  _createVNode(_component_Button, {
                    type: "button",
                    label: "Apply",
                    onClick: _cache[10] || (_cache[10] = ($event: any) => (_ctx.fetchQuotesData(false)))
                  })
                ]),
                body: _withCtx(({ data }) => [
                  _withDirectives(_createElementVNode("div", { class: "status-column" }, _toDisplayString(_ctx.statusLabel[data.status.toUpperCase()]), 513), [
                    [_vShow, data.status]
                  ])
                ]),
                _: 1
              }, 8, ["sortable"]),
              _createVNode(_component_Column, {
                field: "date",
                header: "Date",
                showAddButton: false,
                showFilterMatchModes: true,
                showFilterOperator: false,
                sortable: _ctx.isResultView
              }, {
                body: _withCtx(({ data }) => [
                  _createTextVNode(_toDisplayString(this.formatStringDate(data.date)), 1)
                ]),
                filter: _withCtx(() => [
                  _createVNode(_component_Calendar, {
                    modelValue: _ctx.selectedFilterDate,
                    "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.selectedFilterDate) = $event)),
                    dateFormat: "mm-dd-yy",
                    class: "p-column-filter",
                    selectionMode: "range",
                    placeholder: "Select Date Range"
                  }, null, 8, ["modelValue"])
                ]),
                filterclear: _withCtx(() => [
                  _createVNode(_component_Button, {
                    type: "button",
                    label: "Clear",
                    onClick: _cache[12] || (_cache[12] = ($event: any) => (_ctx.clearDateFilter()))
                  })
                ]),
                filterapply: _withCtx(() => [
                  _createVNode(_component_Button, {
                    type: "button",
                    label: "Apply",
                    onClick: _cache[13] || (_cache[13] = ($event: any) => (_ctx.fetchQuotesData(false)))
                  })
                ]),
                _: 1
              }, 8, ["sortable"]),
              _createVNode(_component_Column, {
                field: "quoted_by",
                header: "Quoted By",
                showAddButton: false,
                showFilterMatchModes: false,
                showFilterOperator: false,
                sortable: _ctx.isResultView
              }, {
                filter: _withCtx(() => [
                  _createVNode(_component_MultiselectWithButton, {
                    value: _ctx.selectedFilterQuotedBy,
                    options: _ctx.getUsers,
                    optionLabel: "user_name",
                    optionValue: "user_id",
                    dataKey: "user_id",
                    placeHolder: "Filter by Quote By",
                    filter: true,
                    filterMatchMode: "startsWith",
                    style: { width: '200px' },
                    btnLabel: "Apply",
                    onBtnCallback: _cache[14] || (_cache[14] = ($event: any) => (_ctx.fetchQuotesData(false))),
                    onUpdateFilterValue: _cache[15] || (_cache[15] = 
                (filterValue) => (this.selectedFilterQuotedBy = filterValue)
              )
                  }, null, 8, ["value", "options"])
                ]),
                filterclear: _withCtx(() => [
                  _createVNode(_component_Button, {
                    type: "button",
                    label: "Clear",
                    onClick: _cache[16] || (_cache[16] = ($event: any) => (_ctx.clearQuoteByToFilter()))
                  })
                ]),
                filterapply: _withCtx(() => [
                  _createVNode(_component_Button, {
                    type: "button",
                    label: "Apply",
                    onClick: _cache[17] || (_cache[17] = ($event: any) => (_ctx.fetchQuotesData(false)))
                  })
                ]),
                _: 1
              }, 8, ["sortable"]),
              (_ctx.isResultView)
                ? (_openBlock(), _createBlock(_component_Column, {
                    key: 1,
                    field: "quote_amount",
                    header: "Quote Total",
                    showAddButton: false,
                    sortable: _ctx.isResultView,
                    bodyStyle: "text-align: right; padding-right: 1rem"
                  }, {
                    body: _withCtx(({ data }) => [
                      (data.quote_amount)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.formatCurrency(data.quote_amount)), 1))
                        : (!data.qoute_amount && data.li_items.length)
                          ? (_openBlock(), _createElementBlock("span", _hoisted_4, " View For More Details"))
                          : (!data.qoute_amount && !data.li_items.length)
                            ? (_openBlock(), _createElementBlock("span", _hoisted_5, " No Line Items "))
                            : _createCommentVNode("", true)
                    ]),
                    _: 1
                  }, 8, ["sortable"]))
                : _createCommentVNode("", true),
              (_ctx.isResultView)
                ? (_openBlock(), _createBlock(_component_Column, { key: 2 }, {
                    body: _withCtx((slotProps) => [
                      _cache[31] || (_cache[31] = _createTextVNode("   ")),
                      _createVNode(_component_Button, {
                        id: "downloadButton",
                        icon: _ctx.downloadIcon(slotProps.data.id),
                        title: "Download",
                        onClick: ($event: any) => (_ctx.download(slotProps.data.id))
                      }, null, 8, ["icon", "onClick"]),
                      _cache[32] || (_cache[32] = _createTextVNode("   "))
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["value", "rows", "first", "sortField", "sortOrder", "onFilter", "onPage", "onRowClick", "filters", "class"]))
        : _createCommentVNode("", true)
    ]),
    (!_ctx.isResultView && !_ctx.isLoadingSoQuotes)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          _createVNode(_component_KanbanView, {
            lists: _ctx.formatedLists,
            section: "quotes",
            loading: false,
            onOnClickEdit: _ctx.rowClick,
            onOnStatusChange: _ctx.onStatusChange
          }, null, 8, ["lists", "onOnClickEdit", "onOnStatusChange"])
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_Dialog, {
      visible: _ctx.showEmailModal,
      "onUpdate:visible": _cache[27] || (_cache[27] = ($event: any) => ((_ctx.showEmailModal) = $event)),
      header: "Email Attachment",
      style: { width: '1000px' },
      modal: true,
      draggable: false,
      class: "p-fluid",
      onHide: this.hideDialog
    }, {
      footer: _withCtx(() => [
        _createVNode(_component_Button, {
          label: "Cancel",
          icon: "pi pi-times",
          class: "p-button-text",
          onClick: _ctx.hideDialog
        }, null, 8, ["onClick"]),
        _createVNode(_component_Button, {
          label: "Send",
          icon: "pi pi-send",
          class: "p-button-text",
          onClick: _ctx.email
        }, null, 8, ["onClick"])
      ]),
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_7, [
          _cache[33] || (_cache[33] = _createElementVNode("div", { class: "col-1 field text-center" }, [
            _createElementVNode("label", { for: "from" }, "From")
          ], -1)),
          _createElementVNode("div", _hoisted_8, [
            _createVNode(_component_Chips, {
              id: "from",
              max: 1,
              separator: ",",
              addOnBlur: true,
              modelValue: _ctx.from,
              "onUpdate:modelValue": _cache[21] || (_cache[21] = ($event: any) => ((_ctx.from) = $event))
            }, null, 8, ["modelValue"])
          ]),
          _cache[34] || (_cache[34] = _createElementVNode("div", { class: "col-1 field text-center" }, [
            _createElementVNode("label", { for: "to" }, "To")
          ], -1)),
          _createElementVNode("div", _hoisted_9, [
            _createVNode(_component_Chips, {
              id: "to",
              required: "true",
              modelValue: _ctx.to,
              "onUpdate:modelValue": _cache[22] || (_cache[22] = ($event: any) => ((_ctx.to) = $event)),
              autofocus: "",
              addOnBlur: true,
              separator: ",",
              class: _normalizeClass({
              'p-invalid': _ctx.submitted && _ctx.v$.to.required.$invalid,
            })
            }, null, 8, ["modelValue", "class"]),
            (_ctx.submitted && _ctx.v$.to.required.$invalid)
              ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                  _createElementVNode("small", _hoisted_11, _toDisplayString(_ctx.v$.to.required.$message), 1)
                ]))
              : _createCommentVNode("", true)
          ]),
          _cache[35] || (_cache[35] = _createElementVNode("div", { class: "col-1 field text-center" }, [
            _createElementVNode("label", { for: "cc" }, "Cc")
          ], -1)),
          _createElementVNode("div", _hoisted_12, [
            _createVNode(_component_Chips, {
              id: "cc",
              modelValue: _ctx.cc,
              "onUpdate:modelValue": _cache[23] || (_cache[23] = ($event: any) => ((_ctx.cc) = $event)),
              addOnBlur: true,
              separator: ",",
              required: "false"
            }, null, 8, ["modelValue"])
          ]),
          _cache[36] || (_cache[36] = _createElementVNode("div", { class: "col-1 field text-center" }, [
            _createElementVNode("label", { for: "bcc" }, "Bcc")
          ], -1)),
          _createElementVNode("div", _hoisted_13, [
            _createVNode(_component_Chips, {
              id: "bcc",
              modelValue: _ctx.bcc,
              "onUpdate:modelValue": _cache[24] || (_cache[24] = ($event: any) => ((_ctx.bcc) = $event)),
              addOnBlur: true,
              separator: ",",
              required: "false"
            }, null, 8, ["modelValue"])
          ]),
          _cache[37] || (_cache[37] = _createElementVNode("div", { class: "col-1 field text-center" }, [
            _createElementVNode("label", { for: "subject" }, "Subject")
          ], -1)),
          _createElementVNode("div", _hoisted_14, [
            _createVNode(_component_InputText, {
              id: "subject",
              modelValue: _ctx.subject,
              "onUpdate:modelValue": _cache[25] || (_cache[25] = ($event: any) => ((_ctx.subject) = $event)),
              required: "false"
            }, null, 8, ["modelValue"])
          ])
        ]),
        _createElementVNode("div", _hoisted_15, [
          _createVNode(_component_Textarea, {
            id: "body",
            modelValue: _ctx.body,
            "onUpdate:modelValue": _cache[26] || (_cache[26] = ($event: any) => ((_ctx.body) = $event)),
            placeholder: "Insert Email Text...",
            required: "false",
            rows: "10",
            cols: "50"
          }, null, 8, ["modelValue"])
        ]),
        _createElementVNode("div", _hoisted_16, [
          _cache[38] || (_cache[38] = _createElementVNode("label", { for: "att" }, "Attachment  ", -1)),
          _createVNode(_component_InputText, {
            id: "att",
            required: "false",
            modelValue: _ctx.modalFile,
            disabled: ""
          }, null, 8, ["modelValue"])
        ])
      ]),
      _: 1
    }, 8, ["visible", "onHide"]),
    _createVNode(_component_ConfirmDialog, { draggable: false }),
    _createVNode(_component_SoQuoteDialog, {
      quote: this.soQuote,
      oldQuote: this.oldSoQuote,
      show: this.showSoQuoteDialog,
      isEditing: this.isEditing,
      singleOption: this.singleOption,
      id: this.id,
      newQuote: this.newQuote,
      onOnSave: _cache[28] || (_cache[28] = ($event: any) => (this.fetchQuotesData(false))),
      onHide: _cache[29] || (_cache[29] = 
        (hidden) => {
          this.soQuote = null;
          this.showSoQuoteDialog = hidden;
        }
      )
    }, null, 8, ["quote", "oldQuote", "show", "isEditing", "singleOption", "id", "newQuote"])
  ]))
}
import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "field",
  style: {"padding-top":"5px"}
}
const _hoisted_2 = {
  key: 1,
  class: "field-checkbox"
}
const _hoisted_3 = { class: "field" }
const _hoisted_4 = { class: "p-inputgroup" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { class: "p-error" }
const _hoisted_7 = { class: "field" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FileUpload = _resolveComponent("FileUpload")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_TextArea = _resolveComponent("TextArea")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createBlock(_component_Dialog, {
    id: "addAttachmentDialog",
    visible: _ctx.isHidden,
    "onUpdate:visible": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.isHidden) = $event)),
    header: "Add Attachment",
    modal: true,
    draggable: false,
    class: "p-fluid",
    onHide: _cache[7] || (_cache[7] = 
      () => {
        _ctx.clearDialog();
        _ctx.$emit('hide', _ctx.isHidden);
      }
    )
  }, {
    footer: _withCtx(() => [
      _createVNode(_component_Button, {
        id: "dismissDialog",
        label: "Cancel",
        icon: "pi pi-times",
        class: "p-button-text",
        onClick: _cache[4] || (_cache[4] = 
          () => {
            _ctx.$emit('hide', false);
          }
        )
      }),
      _createVNode(_component_Button, {
        id: "addAttachmentButton",
        label: "Add",
        icon: "pi pi-check",
        class: "p-button-text",
        loading: _ctx.isLoadingAttachment,
        onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.saveAttachment()))
      }, null, 8, ["loading"])
    ]),
    default: _withCtx(() => [
      (!_ctx.disableUpload)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_component_FileUpload, {
              ref: "fileUploader",
              mode: "basic",
              name: "attachment[]",
              customUpload: true,
              onUploader: _cache[0] || (_cache[0] = ($event: any) => (_ctx.uploadAttachment($event))),
              auto: true,
              disabled: _ctx.disableUpload
            }, null, 8, ["disabled"])
          ]))
        : _createCommentVNode("", true),
      (this.fileName != '')
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createElementVNode("label", null, _toDisplayString(this.fileName), 1),
            _createVNode(_component_Button, {
              icon: "pi pi-trash",
              class: "p-button-rounded p-button-text",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.clearDialog()))
            })
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _cache[8] || (_cache[8] = _createElementVNode("span", { class: "p-inputgroup-addon" }, "Folder", -1)),
          _createVNode(_component_Dropdown, {
            modelValue: _ctx.selectedFolder,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.selectedFolder) = $event)),
            options: _ctx.control.attachments.folder_name_items,
            optionLabel: "folder_name",
            optionValue: "folder_name"
          }, null, 8, ["modelValue", "options"])
        ]),
        (_ctx.v$.selectedFolder.required.$invalid)
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
              _createElementVNode("small", _hoisted_6, _toDisplayString(_ctx.v$.selectedFolder.required.$message), 1)
            ]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createVNode(_component_TextArea, {
          id: "description",
          modelValue: _ctx.description,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.description) = $event)),
          placeholder: "Insert Description...",
          required: false,
          rows: "2",
          cols: "30"
        }, null, 8, ["modelValue"])
      ])
    ]),
    _: 1
  }, 8, ["visible"]))
}
import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, createVNode as _createVNode, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "grid p-0 m-0 p-2 px-3 md:px-4 justify-content-center payable" }
const _hoisted_2 = { class: "col-12 justify-content-center m-0 p-0" }
const _hoisted_3 = { class: "pt-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_LookupDataTable = _resolveComponent("LookupDataTable")!
  const _component_Card = _resolveComponent("Card")!
  const _component_EmailFileDialog = _resolveComponent("EmailFileDialog")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_Card, { class: "col-12 font-semibold text-lg p-2 invoice-section" }, {
          content: _withCtx(() => [
            _cache[1] || (_cache[1] = _createElementVNode("div", { class: "grid p-0 m-0" }, [
              _createElementVNode("h3", { class: "col-8 p-0 m-0 pl-3 pt-2" }, "Orders on Credit Hold:"),
              _createElementVNode("div", { class: "col-4 flex p-0 m-0 pr-1 justify-content-end align-items-center" })
            ], -1)),
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_LookupDataTable, {
                lookup: _ctx.getLookup,
                params: _ctx.lookupParams,
                lazyLoadPagination: true,
                tableClass: "p-datatable-sm",
                columnHeaderClass: "font-bold text-base",
                onRowClick: _ctx.handleRowClick
              }, {
                actions: _withCtx(({ data }) => [
                  _withDirectives((_openBlock(), _createBlock(_component_Button, {
                    id: "downloadButton",
                    title: "Download",
                    onClick: ($event: any) => (_ctx.getSalesOrderPDF(data.so_id)),
                    style: {
                    width: '37px',
                    height: '37px',
                    justifyContent: 'center',
                    alignItems: 'center',
                    margin: '2px',
                    minWidth: '37px !important',
                  }
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("i", {
                        class: _normalizeClass(_ctx.salePDFIcon(data.so_id))
                      }, null, 2)
                    ]),
                    _: 2
                  }, 1032, ["onClick"])), [
                    [
                      _directive_tooltip,
                      'Generate PDF Sales Order ' + data.so_id,
                      void 0,
                      { left: true }
                    ]
                  ]),
                  _withDirectives(_createVNode(_component_Button, {
                    id: "emailButton",
                    icon: "pi pi-envelope",
                    title: "Email",
                    onClick: ($event: any) => (_ctx.handleEmailPDF(data)),
                    style: {
                    width: '37px',
                    height: '37px',
                    justifyContent: 'center',
                    alignItems: 'center',
                    margin: '2px',
                    minWidth: '37px !important',
                  }
                  }, null, 8, ["onClick"]), [
                    [
                      _directive_tooltip,
                      'Email PDF Sales Order ' + data.so_id,
                      void 0,
                      { left: true }
                    ]
                  ]),
                  (_ctx.editAccess)
                    ? _withDirectives((_openBlock(), _createBlock(_component_Button, {
                        key: 0,
                        id: "releaseButton",
                        icon: "pi pi-send",
                        title: "Release",
                        onClick: ($event: any) => (_ctx.releaseOrder($event, data.so_id)),
                        style: {
                    width: '37px',
                    height: '37px',
                    justifyContent: 'center',
                    alignItems: 'center',
                    margin: '2px',
                    minWidth: '37px !important',
                  },
                        loading: _ctx.loadingReleaseOrder[data.so_id]
                      }, null, 8, ["onClick", "loading"])), [
                        [
                          _directive_tooltip,
                          'Release Order ' + data.so_id,
                          void 0,
                          { left: true }
                        ]
                      ])
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }, 8, ["lookup", "params", "onRowClick"])
            ])
          ]),
          _: 1
        })
      ])
    ]),
    _createVNode(_component_EmailFileDialog, {
      show: _ctx.showEmailDialog,
      header: 'Email Sales Order ' + _ctx.orderIdToEmail,
      onHide: _cache[0] || (_cache[0] = 
      (isHidden) => {
        _ctx.showEmailDialog = isHidden;
      }
    ),
      fileId: _ctx.orderIdToEmail,
      fileName: _ctx.orderIdToEmail,
      onOnEmail: _ctx.sendEmail,
      contacts: _ctx.customerContacts
    }, null, 8, ["show", "header", "fileId", "fileName", "onOnEmail", "contacts"])
  ], 64))
}
import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex align-items-center cache-header" }
const _hoisted_2 = { class: "card p-0 m-0" }
const _hoisted_3 = { class: "text-base text-right" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IonCloudDoneOutline = _resolveComponent("IonCloudDoneOutline")!
  const _component_IonCloudDownloadOutline = _resolveComponent("IonCloudDownloadOutline")!
  const _component_IonCloudOfflineOutline = _resolveComponent("IonCloudOfflineOutline")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Column = _resolveComponent("Column")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _component_ProgressSpinner = _resolveComponent("ProgressSpinner")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_Button, {
        label: "Cache Offline Data",
        onClick: _ctx.openCachingDialog,
        class: "ml-2",
        "data-testid": "cache-offline-data-status-button"
      }, {
        default: _withCtx(() => [
          (_ctx.isOnline)
            ? (_openBlock(), _createBlock(_component_IonCloudDoneOutline, {
                key: 0,
                class: "p-button-icon"
              }))
            : _createCommentVNode("", true),
          (!_ctx.isOnline && _ctx.cacheStatistics.length > 0)
            ? (_openBlock(), _createBlock(_component_IonCloudDownloadOutline, {
                key: 1,
                class: "p-button-icon"
              }))
            : _createCommentVNode("", true),
          (!_ctx.isOnline && _ctx.cacheStatistics.length == 0)
            ? (_openBlock(), _createBlock(_component_IonCloudOfflineOutline, {
                key: 2,
                class: "p-button-icon"
              }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["onClick"])
    ]),
    _createVNode(_component_Dialog, {
      visible: _ctx.showCachingDialog,
      "onUpdate:visible": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.showCachingDialog) = $event)),
      header: "Offline Data Caching",
      modal: ""
    }, {
      footer: _withCtx(() => [
        _createVNode(_component_Button, {
          label: "Refresh Offline Data",
          onClick: _ctx.cacheOfflineData,
          disabled: !_ctx.isOnline,
          "data-testid": "cache-offline-data-button"
        }, {
          default: _withCtx(() => [
            _cache[1] || (_cache[1] = _createElementVNode("span", null, "Refresh Offline Data", -1)),
            _createElementVNode("div", null, [
              (_ctx.isCaching)
                ? (_openBlock(), _createBlock(_component_ProgressSpinner, {
                    key: 0,
                    "data-testid": "caching-loading-spinner",
                    class: "ml-1",
                    style: {"width":"15px","height":"15px"}
                  }))
                : _createCommentVNode("", true)
            ])
          ]),
          _: 1
        }, 8, ["onClick", "disabled"]),
        _createVNode(_component_Button, {
          label: "Cancel",
          onClick: _ctx.closeCachingDialog,
          "data-testid": "cache-offline-cancel-button"
        }, null, 8, ["onClick"])
      ]),
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_DataTable, { value: _ctx.cacheStatistics }, {
            default: _withCtx(() => [
              _createVNode(_component_Column, {
                field: "key",
                header: "Data"
              }),
              _createVNode(_component_Column, {
                field: "cacheDateTime",
                header: "Last Cached"
              }, {
                body: _withCtx(({ data }) => [
                  _createTextVNode(_toDisplayString(new Date(data.cacheDateTime).toLocaleString()), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_Column, {
                field: "cacheRecords",
                header: "Records Cached",
                class: "text-base text-right"
              }, {
                body: _withCtx(({ data }) => [
                  _createElementVNode("span", _hoisted_3, _toDisplayString(data.cacheRecords?.toLocaleString()), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["value"])
        ])
      ]),
      _: 1
    }, 8, ["visible"])
  ], 64))
}
import { FdictResponse } from "@/types/fdict";

export const FDICT_DEFAULT: FdictResponse = {
  total_records_found: "1",
  BlockSize: "50",
  fdict_items: [
    {
      file_name: "SO",
      allow_history: "Y",
      field_no_items: [
        {
          field_no: "0",
          conv: "MCU",
          dict_name: "SO.ID",
          desc_items: [
            {
              desc: "SO.Id",
            },
          ],
          just: "R",
          len: "6",
          reference_file: "SO",
          json_name: "",
        },
        {
          field_no: "1",
          conv: "D2-",
          dict_name: "DATE",
          desc_items: [
            {
              desc: "Date",
            },
          ],
          just: "R",
          index: "N",
          len: "8",
          required: "Y",
          json_name: "",
        },
        {
          field_no: "2",
          conv: "MCU",
          dict_name: "SOLD.TO",
          desc_items: [
            {
              desc: "Sold.to",
            },
          ],
          just: "L",
          index: "Y",
          len: "10",
          reference_file: "CUST",
          required: "Y",
          json_name: "",
        },
        {
          field_no: "3",
          conv: "D2-",
          dict_name: "BOOK.DATE",
          desc_items: [
            {
              desc: "Book",
            },
            {
              desc: "Date",
            },
          ],
          just: "R",
          ignore_change: "Y",
          len: "8",
          required: "N",
          json_name: "",
        },
        {
          field_no: "4",
          conv: "MCU",
          dict_name: "BILL.TO",
          desc_items: [
            {
              desc: "Bill.to",
            },
          ],
          just: "L",
          ignore_change: "N",
          len: "10",
          reference_file: "CUST",
          required: "N",
          json_name: "",
        },
        {
          field_no: "5",
          dict_name: "SHIP.ADDRESS",
          desc_items: [
            {
              desc: "Ship.address",
            },
          ],
          structure: "5",
          multi_line: "Y",
          just: "L",
          ignore_change: "N",
          len: "30",
          required: "N",
          json_name: "",
        },
        {
          field_no: "6",
          dict_name: "CONTACT",
          desc_items: [
            {
              desc: "Contact",
            },
          ],
          just: "L",
          ignore_change: "N",
          len: "25",
          required: "N",
          json_name: "",
        },
        {
          field_no: "7",
          dict_name: "PHONE",
          desc_items: [
            {
              desc: "Phone",
            },
          ],
          just: "L",
          ignore_change: "N",
          len: "16",
          required: "N",
          json_name: "",
        },
        {
          field_no: "8",
          dict_name: "PO.NUMBER",
          desc_items: [
            {
              desc: "PO.Number",
            },
          ],
          just: "L",
          index: "Y",
          ignore_change: "N",
          len: "15",
          required: "N",
          json_name: "",
        },
        {
          field_no: "9",
          dict_name: "SHIP.VIA",
          desc_items: [
            {
              desc: "Ship.Via",
            },
          ],
          just: "L",
          index: "N",
          ignore_change: "N",
          len: "30",
          required: "N",
          json_name: "",
        },
        {
          field_no: "10",
          conv: "MCU",
          dict_name: "REP",
          desc_items: [
            {
              desc: "Rep",
            },
          ],
          structure: "10ü11",
          just: "R",
          ignore_change: "N",
          len: "6",
          reference_file: "REP",
          required: "N",
          json_name: "",
        },
        {
          field_no: "11",
          control_field: "10",
          conv: "MD2",
          dict_name: "ORDER.PCT",
          desc_items: [
            {
              desc: "Order.Pct",
            },
          ],
          just: "R",
          ignore_change: "N",
          len: "6",
          required: "N",
          json_name: "",
        },
        {
          field_no: "12",
          dict_name: "HOLD",
          desc_items: [
            {
              desc: "Hold",
            },
          ],
          just: "R",
          ignore_change: "N",
          len: "1",
          required: "N",
          json_name: "",
        },
        {
          field_no: "13",
          conv: "D2-",
          dict_name: "HOLD.DATE",
          desc_items: [
            {
              desc: "Hold",
            },
            {
              desc: "Date",
            },
          ],
          just: "R",
          ignore_change: "N",
          len: "8",
          required: "N",
          json_name: "",
        },
        {
          field_no: "14",
          dict_name: "STATUS",
          desc_items: [
            {
              desc: "Status",
            },
          ],
          just: "L",
          ignore_change: "N",
          len: "1",
          required: "N",
          valid_value_items: [
            {
              valid_value: "N",
              valid_desc: "New",
            },
            {
              valid_value: "B",
              valid_desc: "Backorder",
            },
            {
              valid_value: "C",
              valid_desc: "Closed",
            },
          ],
          json_name: "",
        },
        {
          field_no: "15",
          conv: "MCU",
          dict_name: "TERMS.CODE",
          desc_items: [
            {
              desc: "Terms",
            },
            {
              desc: "Code",
            },
          ],
          just: "L",
          index: "N",
          ignore_change: "N",
          len: "10",
          reference_file: "TERMS",
          required: "N",
          json_name: "",
        },
        {
          field_no: "16",
          dict_name: "FOB",
          desc_items: [
            {
              desc: "FOB",
            },
          ],
          just: "L",
          ignore_change: "N",
          len: "15",
          required: "N",
          json_name: "",
        },
        {
          field_no: "17",
          dict_name: "CUST.CODE",
          desc_items: [
            {
              desc: "Cust",
            },
            {
              desc: "Code",
            },
          ],
          just: "L",
          ignore_change: "N",
          len: "10",
          required: "N",
          json_name: "",
        },
        {
          field_no: "18",
          conv: "MD4",
          dict_name: "TAX.RATE",
          desc_items: [
            {
              desc: "Tax.rate",
            },
          ],
          just: "R",
          index: "N",
          ignore_change: "N",
          len: "7",
          required: "N",
          json_name: "",
        },
        {
          field_no: "19",
          dict_name: "RESALE.NUMBER",
          desc_items: [
            {
              desc: "Resale",
            },
            {
              desc: "Number",
            },
          ],
          just: "L",
          index: "N",
          ignore_change: "N",
          len: "15",
          required: "N",
          json_name: "",
        },
        {
          field_no: "20",
          conv: "D2-",
          dict_name: "CLOSE.DATE",
          desc_items: [
            {
              desc: "Close",
            },
            {
              desc: "Date",
            },
          ],
          just: "R",
          index: "N",
          ignore_change: "N",
          len: "8",
          json_name: "",
        },
        {
          field_no: "21",
          dict_name: "LAST.SHIP.NO",
          desc_items: [
            {
              desc: "Last",
            },
            {
              desc: "Ship.No",
            },
          ],
          just: "L",
          index: "N",
          ignore_change: "Y",
          len: "9",
          json_name: "",
        },
        {
          field_no: "22",
          conv: "D2-",
          dict_name: "LAST.DATE",
          desc_items: [
            {
              desc: "Last.date",
            },
          ],
          just: "R",
          ignore_change: "Y",
          len: "8",
          json_name: "",
        },
        {
          field_no: "23",
          dict_name: "NOTES",
          desc_items: [
            {
              desc: "Notes",
            },
          ],
          just: "T",
          index: "N",
          ignore_change: "N",
          len: "25",
          json_name: "",
        },
        {
          field_no: "24",
          conv: "D2-",
          dict_name: "STAMP.DATE",
          desc_items: [
            {
              desc: "Stamp.date",
            },
          ],
          just: "R",
          ignore_change: "Y",
          len: "8",
          json_name: "",
        },
        {
          field_no: "25",
          dict_name: "STAMP.ID",
          desc_items: [
            {
              desc: "Stamp.id",
            },
          ],
          just: "L",
          index: "N",
          ignore_change: "Y",
          len: "15",
          json_name: "",
        },
        {
          field_no: "26",
          dict_name: "LIS",
          desc_items: [
            {
              desc: "Lis",
            },
          ],
          structure:
            "26ü27ü28ü29ü30ü31ü32ü33ü34ü35ü36ü37ü38ü39ü40ü41ü42ü43ü44ü45ü46ü47ü48ü53ü54ü55ü57ü60ü62ü63ü71ü72ü101ü102ü103ü104ü111ü129ü159ü179ü201ü209ü211",
          just: "R",
          ignore_change: "N",
          len: "3",
          json_name: "",
        },
        {
          field_no: "27",
          control_field: "26",
          conv: "MCU",
          dict_name: "LI.PARTS",
          desc_items: [
            {
              desc: "Li.Parts",
            },
          ],
          just: "L",
          index: "Y",
          ignore_change: "N",
          len: "20",
          reference_file: "PARTS",
          json_name: "",
        },
        {
          field_no: "28",
          control_field: "26",
          dict_name: "LI.DESCS",
          desc_items: [
            {
              desc: "Li.Descs",
            },
          ],
          structure: "28",
          multi_line: "Y",
          just: "L",
          index: "N",
          ignore_change: "N",
          len: "25",
          json_name: "",
        },
        {
          field_no: "29",
          control_field: "26",
          dict_name: "LI.REVS",
          desc_items: [
            {
              desc: "Li.Revs",
            },
          ],
          just: "L",
          ignore_change: "N",
          len: "3",
          json_name: "",
        },
        {
          field_no: "30",
          control_field: "26",
          conv: "D2-",
          dict_name: "LI.SCHED.DATES",
          desc_items: [
            {
              desc: "Li.Sched",
            },
            {
              desc: "Dates",
            },
          ],
          structure: "30ü31ü54ü57",
          just: "R",
          index: "Y",
          ignore_change: "N",
          len: "8",
          json_name: "",
        },
        {
          field_no: "31",
          control_field: "30",
          conv: "MD2",
          dict_name: "LI.SCHED.QTYS",
          desc_items: [
            {
              desc: "Li.Sched",
            },
            {
              desc: "Qtys",
            },
          ],
          just: "R",
          index: "N",
          ignore_change: "N",
          len: "10",
          json_name: "",
        },
        {
          field_no: "32",
          control_field: "26",
          conv: "MD2",
          dict_name: "LI.ORDER.QTYS",
          desc_items: [
            {
              desc: "Li.Order",
            },
            {
              desc: "Qtys",
            },
          ],
          just: "R",
          ignore_change: "N",
          len: "9",
          json_name: "",
        },
        {
          field_no: "33",
          control_field: "26",
          conv: "MD2",
          dict_name: "LI.OPEN.QTYS",
          desc_items: [
            {
              desc: "Li.Open",
            },
            {
              desc: "Qtys",
            },
          ],
          just: "R",
          index: "N",
          ignore_change: "N",
          len: "9",
          json_name: "",
        },
        {
          field_no: "34",
          control_field: "26",
          conv: "MD4",
          dict_name: "LI.PRICES",
          desc_items: [
            {
              desc: "Li.Prices",
            },
          ],
          just: "R",
          index: "N",
          ignore_change: "N",
          len: "13",
          json_name: "",
        },
        {
          field_no: "35",
          control_field: "26",
          conv: "MD2",
          dict_name: "LI.DISCS",
          desc_items: [
            {
              desc: "Li.Discs",
            },
          ],
          just: "R",
          ignore_change: "N",
          len: "6",
          json_name: "",
        },
        {
          field_no: "36",
          control_field: "26",
          conv: "MD4",
          dict_name: "LI.DISC.AMTS",
          desc_items: [
            {
              desc: "Li.Disc",
            },
            {
              desc: "Amts",
            },
          ],
          just: "R",
          index: "N",
          ignore_change: "N",
          len: "13",
          json_name: "",
        },
        {
          field_no: "37",
          control_field: "26",
          conv: "MCU",
          dict_name: "LI.FG.LOCS",
          desc_items: [
            {
              desc: "Li.Fg.locs",
            },
          ],
          just: "L",
          ignore_change: "N",
          len: "6",
          reference_file: "INVLOC",
          json_name: "",
        },
        {
          field_no: "38",
          control_field: "26",
          dict_name: "LI.NOTES",
          desc_items: [
            {
              desc: "Li.Notes",
            },
          ],
          just: "T",
          index: "N",
          ignore_change: "N",
          len: "25",
          json_name: "",
        },
        {
          field_no: "39",
          control_field: "26",
          boolean: "Y",
          dict_name: "LI.TAXABLES",
          desc_items: [
            {
              desc: "Li.Taxables",
            },
          ],
          just: "L",
          index: "N",
          ignore_change: "N",
          len: "1",
          json_name: "",
        },
        {
          field_no: "40",
          control_field: "26",
          conv: "MCU",
          dict_name: "LI.REPS",
          desc_items: [
            {
              desc: "Li.Reps",
            },
          ],
          structure: "40ü41",
          just: "R",
          ignore_change: "N",
          len: "6",
          reference_file: "REP",
          json_name: "",
        },
        {
          field_no: "41",
          control_field: "40",
          conv: "MD2",
          dict_name: "LI.COMMS",
          desc_items: [
            {
              desc: "Li.Comms",
            },
          ],
          just: "R",
          index: "N",
          ignore_change: "N",
          len: "6",
          json_name: "",
        },
        {
          field_no: "42",
          control_field: "26",
          dict_name: "LI.HOLDS",
          desc_items: [
            {
              desc: "Li.Holds",
            },
          ],
          just: "L",
          index: "N",
          ignore_change: "N",
          len: "1",
          json_name: "",
        },
        {
          field_no: "43",
          control_field: "26",
          conv: "D2-",
          dict_name: "LI.HOLD.DATES",
          desc_items: [
            {
              desc: "Li.Hold",
            },
            {
              desc: "Dates",
            },
          ],
          just: "R",
          index: "N",
          ignore_change: "N",
          len: "8",
          json_name: "",
        },
        {
          field_no: "44",
          control_field: "26",
          dict_name: "LI.SHIP.NOS",
          desc_items: [
            {
              desc: "Li.Ship",
            },
            {
              desc: "Nos",
            },
          ],
          structure: "44ü45ü46",
          just: "R",
          ignore_change: "N",
          len: "9",
          json_name: "",
        },
        {
          field_no: "45",
          control_field: "44",
          conv: "D2-",
          dict_name: "LI.SHIP.DATES",
          desc_items: [
            {
              desc: "Li.Ship",
            },
            {
              desc: "Dates",
            },
          ],
          just: "R",
          ignore_change: "N",
          len: "8",
          json_name: "",
        },
        {
          field_no: "46",
          control_field: "44",
          conv: "MD2",
          dict_name: "LI.SHIP.QTYS",
          desc_items: [
            {
              desc: "Li.Ship",
            },
            {
              desc: "Qtys",
            },
          ],
          just: "R",
          index: "N",
          ignore_change: "N",
          len: "9",
          json_name: "",
        },
        {
          field_no: "47",
          control_field: "26",
          conv: "MD2",
          dict_name: "LI.TOTAL.SHIP",
          desc_items: [
            {
              desc: "Li.Total",
            },
            {
              desc: "Ship",
            },
          ],
          just: "R",
          ignore_change: "N",
          len: "9",
          json_name: "",
        },
        {
          field_no: "48",
          control_field: "26",
          conv: "MCU",
          dict_name: "LI.SALES.ACCTS",
          desc_items: [
            {
              desc: "Li.Sales",
            },
            {
              desc: "Accts",
            },
          ],
          just: "R",
          index: "N",
          ignore_change: "N",
          len: "12",
          reference_file: "GLCHART",
          json_name: "",
        },
        {
          field_no: "49",
          dict_name: "LAST.RMA.NUMBER",
          desc_items: [
            {
              desc: "Last.Rma",
            },
            {
              desc: "Number",
            },
          ],
          just: "R",
          index: "N",
          ignore_change: "Y",
          len: "9",
          json_name: "",
        },
        {
          field_no: "50",
          conv: "D2-",
          dict_name: "LAST.RMA.DATE",
          desc_items: [
            {
              desc: "Last.RMA",
            },
            {
              desc: "Date",
            },
          ],
          just: "R",
          ignore_change: "Y",
          len: "8",
          json_name: "",
        },
        {
          field_no: "51",
          dict_name: "TYPE",
          desc_items: [
            {
              desc: "Type",
            },
          ],
          just: "L",
          index: "N",
          ignore_change: "N",
          len: "1",
          json_name: "",
        },
        {
          field_no: "52",
          dict_name: "FREIGHT.CODE",
          desc_items: [
            {
              desc: "Freight",
            },
            {
              desc: "Code",
            },
          ],
          just: "L",
          index: "N",
          ignore_change: "N",
          len: "1",
          json_name: "",
        },
        {
          field_no: "53",
          control_field: "26",
          conv: "MCU",
          dict_name: "LI.QUOTE.NO",
          desc_items: [
            {
              desc: "Li",
            },
            {
              desc: "Quote",
            },
          ],
          just: "R",
          ignore_change: "N",
          len: "6",
          reference_file: "SOQUOTE",
          required: "N",
          json_name: "",
        },
        {
          field_no: "54",
          control_field: "30",
          boolean: "Y",
          dict_name: "COMMITED",
          desc_items: [
            {
              desc: "Commited",
            },
          ],
          just: "L",
          index: "N",
          ignore_change: "Y",
          len: "1",
          json_name: "",
        },
        {
          field_no: "55",
          control_field: "26",
          conv: "MCU",
          dict_name: "LI.WOS",
          desc_items: [
            {
              desc: "Li.Work",
            },
            {
              desc: "Orders",
            },
          ],
          structure: "55",
          multi_line: "Y",
          just: "R",
          ignore_change: "N",
          len: "6",
          reference_file: "WO",
          json_name: "",
        },
        {
          field_no: "56",
          conv: "D2-",
          dict_name: "PRINT.DATE",
          desc_items: [
            {
              desc: "Print",
            },
            {
              desc: "Date",
            },
          ],
          just: "R",
          index: "N",
          ignore_change: "Y",
          len: "8",
          json_name: "",
        },
        {
          field_no: "57",
          control_field: "30",
          conv: "D2-",
          dict_name: "REQUIRED",
          desc_items: [
            {
              desc: "Required",
            },
            {
              desc: "Date",
            },
          ],
          just: "R",
          index: "N",
          ignore_change: "N",
          len: "8",
          json_name: "",
        },
        {
          field_no: "58",
          dict_name: "PARTIALS.OK",
          boolean: "Y",
          desc_items: [
            {
              desc: "Partials",
            },
            {
              desc: "Ok",
            },
          ],
          just: "L",
          ignore_change: "N",
          len: "1",
          json_name: "",
        },
        {
          field_no: "59",
          conv: "MCU",
          dict_name: "TAX.CODES",
          desc_items: [
            {
              desc: "Tax",
            },
            {
              desc: "Codes",
            },
          ],
          structure: "59ü174",
          just: "L",
          index: "N",
          ignore_change: "N",
          len: "20",
          reference_file: "STAX",
          json_name: "",
        },
        {
          field_no: "60",
          control_field: "26",
          conv: "MCU",
          dict_name: "CG.LOC",
          desc_items: [
            {
              desc: "CG.Loc",
            },
          ],
          just: "L",
          index: "N",
          ignore_change: "N",
          len: "6",
          reference_file: "INVLOC",
          json_name: "",
        },
        {
          field_no: "61",
          conv: "MCU",
          dict_name: "QUOTE.NO",
          desc_items: [
            {
              desc: "Quote.No",
            },
          ],
          just: "R",
          index: "N",
          ignore_change: "N",
          len: "6",
          reference_file: "SOQUOTE",
          json_name: "",
        },
        {
          field_no: "62",
          control_field: "26",
          dict_name: "LI.QUOTE.LI",
          desc_items: [
            {
              desc: "Li",
            },
            {
              desc: "Quote",
            },
            {
              desc: "Line",
            },
          ],
          just: "R",
          ignore_change: "N",
          len: "3",
          json_name: "",
        },
        {
          field_no: "63",
          control_field: "26",
          dict_name: "CUST.PART",
          desc_items: [
            {
              desc: "Cust.Part",
            },
          ],
          just: "L",
          index: "N",
          ignore_change: "N",
          len: "20",
          required: "N",
          json_name: "",
        },
        {
          field_no: "64",
          dict_name: "PLAN.GROUP",
          desc_items: [
            {
              desc: "Plan",
            },
            {
              desc: "Group",
            },
          ],
          just: "L",
          index: "N",
          ignore_change: "N",
          len: "6",
          required: "N",
          valid_value_items: [
            {
              valid_value: "1",
            },
            {
              valid_value: "2",
            },
          ],
          json_name: "",
        },
        {
          field_no: "65",
          dict_name: "EXT",
          desc_items: [
            {
              desc: "Ext",
            },
          ],
          just: "R",
          index: "N",
          ignore_change: "N",
          len: "6",
          json_name: "",
        },
        {
          field_no: "66",
          conv: "MCU",
          dict_name: "CO.CODE",
          desc_items: [
            {
              desc: "Co.Code",
            },
          ],
          just: "L",
          index: "N",
          ignore_change: "N",
          len: "10",
          valid_value_items: [
            {
              valid_value: "1",
              valid_desc: "Rover Data Systems",
            },
            {
              valid_value: "2",
              valid_desc: "test",
            },
          ],
          json_name: "",
        },
        {
          field_no: "67",
          dict_name: "SHIP.CITY",
          desc_items: [
            {
              desc: "Ship.City",
            },
          ],
          just: "L",
          index: "N",
          ignore_change: "N",
          len: "25",
          json_name: "",
        },
        {
          field_no: "68",
          dict_name: "SHIP.STATE",
          desc_items: [
            {
              desc: "Ship.State",
            },
          ],
          just: "L",
          index: "N",
          ignore_change: "N",
          len: "2",
          json_name: "",
        },
        {
          field_no: "69",
          dict_name: "SHIP.ZIP",
          desc_items: [
            {
              desc: "Ship.Zip",
            },
          ],
          just: "L",
          index: "N",
          ignore_change: "N",
          len: "10",
          json_name: "",
        },
        {
          field_no: "70",
          dict_name: "SHIP.GEOCODE",
          desc_items: [
            {
              desc: "Ship.Geocode",
            },
          ],
          just: "L",
          index: "N",
          ignore_change: "N",
          len: "9",
          json_name: "",
        },
        {
          field_no: "71",
          control_field: "26",
          conv: "MCU",
          dict_name: "PROJECT.ID",
          desc_items: [
            {
              desc: "Project.Id",
            },
          ],
          just: "L",
          ignore_change: "N",
          len: "10",
          reference_file: "PROJECT",
          json_name: "",
        },
        {
          field_no: "72",
          control_field: "26",
          dict_name: "TASK.ID",
          desc_items: [
            {
              desc: "Task.Id",
            },
          ],
          just: "L",
          index: "N",
          ignore_change: "N",
          len: "10",
          json_name: "",
        },
        {
          field_no: "73",
          dict_name: "SHIP.SEQ",
          desc_items: [
            {
              desc: "Ship.Seq",
            },
          ],
          just: "L",
          ignore_change: "N",
          len: "10",
          json_name: "",
        },
        {
          field_no: "101",
          control_field: "26",
          conv: "MCU",
          dict_name: "VENDOR",
          desc_items: [
            {
              desc: "Vendor",
            },
          ],
          just: "L",
          index: "N",
          ignore_change: "N",
          len: "10",
          reference_file: "VENDOR",
          json_name: "",
        },
        {
          field_no: "102",
          control_field: "26",
          conv: "MD4",
          dict_name: "PO.COST",
          desc_items: [
            {
              desc: "Po.Cost",
            },
          ],
          just: "R",
          index: "N",
          ignore_change: "N",
          len: "13",
          json_name: "",
        },
        {
          field_no: "103",
          control_field: "26",
          conv: "MCU",
          dict_name: "PO.ID",
          desc_items: [
            {
              desc: "Po.Id",
            },
          ],
          just: "R",
          ignore_change: "N",
          len: "10",
          reference_file: "PO",
          json_name: "",
        },
        {
          field_no: "104",
          control_field: "26",
          dict_name: "PO.LI",
          desc_items: [
            {
              desc: "Po.Li",
            },
          ],
          just: "R",
          index: "N",
          ignore_change: "N",
          len: "3",
          json_name: "",
        },
        {
          field_no: "105",
          dict_name: "DROP.SHIP",
          boolean: "Y",
          desc_items: [
            {
              desc: "Drop",
            },
            {
              desc: "Ship",
            },
          ],
          just: "L",
          index: "N",
          ignore_change: "N",
          len: "1",
          json_name: "",
        },
        {
          field_no: "106",
          dict_name: "SHIP.NAME",
          desc_items: [
            {
              desc: "Ship.Name",
            },
          ],
          just: "L",
          index: "N",
          ignore_change: "N",
          len: "30",
          json_name: "",
        },
        {
          field_no: "107",
          dict_name: "SHIP.COUNTRY",
          desc_items: [
            {
              desc: "Ship.Country",
            },
          ],
          just: "L",
          index: "N",
          ignore_change: "N",
          len: "50",
          json_name: "",
        },
        {
          field_no: "108",
          dict_name: "SHIP.ATTN",
          desc_items: [
            {
              desc: "Ship.Attn",
            },
          ],
          just: "L",
          index: "N",
          ignore_change: "N",
          len: "25",
          json_name: "",
        },
        {
          field_no: "109",
          dict_name: "SHIP.COMM",
          boolean: "Y",
          desc_items: [
            {
              desc: "Commercial?",
            },
          ],
          just: "L",
          index: "N",
          ignore_change: "N",
          len: "1",
          json_name: "",
        },
        {
          field_no: "110",
          dict_name: "SHIP.PHONE",
          desc_items: [
            {
              desc: "Ship.Phone",
            },
          ],
          just: "L",
          index: "N",
          ignore_change: "N",
          len: "16",
          json_name: "",
        },
        {
          field_no: "111",
          control_field: "26",
          conv: "MD0",
          dict_name: "PRICE.PER",
          desc_items: [
            {
              desc: "Price",
            },
            {
              desc: "Per",
            },
          ],
          just: "R",
          index: "N",
          ignore_change: "N",
          len: "6",
          json_name: "",
        },
        {
          field_no: "112",
          dict_name: "PENDING.SHIP",
          boolean: "Y",
          desc_items: [
            {
              desc: "Pending",
            },
            {
              desc: "Ship?",
            },
          ],
          just: "L",
          index: "N",
          ignore_change: "Y",
          len: "1",
          json_name: "",
        },
        {
          field_no: "113",
          dict_name: "CREDITCARD.NO",
          desc_items: [
            {
              desc: "Creditcard.No",
            },
          ],
          structure:
            "113ü114ü115ü116ü117ü118ü119ü120ü121ü143ü144ü145ü146ü147ü148ü173",
          just: "L",
          index: "N",
          ignore_change: "N",
          len: "16",
          json_name: "",
        },
        {
          field_no: "114",
          control_field: "113",
          dict_name: "CREDITCARD.TYPE",
          desc_items: [
            {
              desc: "Creditcard.Type",
            },
          ],
          just: "L",
          index: "N",
          ignore_change: "N",
          len: "7",
          json_name: "",
        },
        {
          field_no: "115",
          control_field: "113",
          dict_name: "CARDHOLDER.NAME",
          desc_items: [
            {
              desc: "Cardholder.Name",
            },
          ],
          just: "L",
          index: "N",
          ignore_change: "N",
          len: "25",
          json_name: "",
        },
        {
          field_no: "116",
          control_field: "113",
          dict_name: "CREDITCARD.EXP",
          desc_items: [
            {
              desc: "Creditcard.Exp",
            },
          ],
          just: "R",
          index: "N",
          ignore_change: "N",
          len: "4",
          json_name: "",
        },
        {
          field_no: "117",
          control_field: "113",
          dict_name: "CREDITCARD.CVV",
          desc_items: [
            {
              desc: "Creditcard.Cvv",
            },
          ],
          just: "R",
          index: "N",
          ignore_change: "N",
          len: "4",
          json_name: "",
        },
        {
          field_no: "118",
          control_field: "113",
          dict_name: "CREDITCARD.TRAN",
          desc_items: [
            {
              desc: "Creditcard.Tran",
            },
          ],
          just: "L",
          index: "N",
          ignore_change: "N",
          len: "50",
          json_name: "",
        },
        {
          field_no: "119",
          control_field: "113",
          conv: "MD2",
          dict_name: "CREDITCARD.AMT",
          desc_items: [
            {
              desc: "Creditcard.Amt",
            },
          ],
          just: "R",
          index: "N",
          ignore_change: "N",
          len: "9",
          json_name: "",
        },
        {
          field_no: "120",
          control_field: "113",
          boolean: "Y",
          dict_name: "CREDITCARD.POST",
          desc_items: [
            {
              desc: "Creditcard.Post",
            },
          ],
          just: "L",
          index: "N",
          ignore_change: "N",
          len: "1",
          json_name: "",
        },
        {
          field_no: "121",
          control_field: "113",
          conv: "MD2",
          dict_name: "CC.CONV.FEE",
          desc_items: [
            {
              desc: "Cc.Conv.Fee",
            },
          ],
          multi_line: "N",
          just: "R",
          index: "N",
          ignore_change: "N",
          len: "9",
          json_name: "",
        },
        {
          field_no: "122",
          dict_name: "SHIP.EMAIL",
          desc_items: [
            {
              desc: "Ship.Email",
            },
          ],
          just: "L",
          index: "N",
          ignore_change: "N",
          len: "50",
          json_name: "",
        },
        {
          field_no: "123",
          dict_name: "SHIP.FRT.ACCT",
          desc_items: [
            {
              desc: "Third.Party",
            },
            {
              desc: "Account.Number",
            },
          ],
          just: "L",
          index: "N",
          ignore_change: "N",
          len: "20",
          json_name: "",
        },
        {
          field_no: "124",
          dict_name: "CONTACT.EMAIL",
          desc_items: [
            {
              desc: "Contact.Email",
            },
          ],
          just: "L",
          ignore_change: "N",
          len: "50",
          json_name: "",
        },
        {
          field_no: "125",
          dict_name: "ORDER.SOURCE",
          desc_items: [
            {
              desc: "Order.Source",
            },
          ],
          just: "L",
          index: "N",
          ignore_change: "N",
          len: "1",
          json_name: "",
        },
        {
          field_no: "126",
          conv: "MD2",
          dict_name: "FREIGHT.AMOUNT",
          desc_items: [
            {
              desc: "Freight.Amount",
            },
          ],
          just: "R",
          index: "N",
          ignore_change: "N",
          len: "9",
          json_name: "",
        },
        {
          field_no: "127",
          dict_name: "FREIGHT.TYPE",
          desc_items: [
            {
              desc: "Freight.Type",
            },
          ],
          just: "L",
          index: "N",
          ignore_change: "N",
          len: "10",
          valid_value_items: [
            {
              valid_value: "Estimated",
            },
            {
              valid_value: "Actual",
            },
            {
              valid_value: "TBD",
            },
          ],
          json_name: "",
        },
        {
          field_no: "128",
          dict_name: "FREIGHT.LOCK",
          boolean: "Y",
          desc_items: [
            {
              desc: "Freight.Lock",
            },
          ],
          just: "L",
          index: "N",
          ignore_change: "N",
          len: "1",
          json_name: "",
        },
        {
          field_no: "129",
          control_field: "26",
          dict_name: "WRAP.DESC",
          desc_items: [
            {
              desc: "Wrap.Desc",
            },
          ],
          just: "T",
          index: "N",
          ignore_change: "N",
          len: "25",
          json_name: "",
        },
        {
          field_no: "130",
          conv: "MCU",
          dict_name: "COUPON.ID",
          desc_items: [
            {
              desc: "Coupon.Id",
            },
          ],
          structure: "130ü131ü132ü133ü134ü135ü136ü137ü138",
          just: "L",
          index: "N",
          ignore_change: "N",
          len: "10",
          reference_file: "COUPON",
          json_name: "",
        },
        {
          field_no: "131",
          control_field: "130",
          dict_name: "COUPON.TYPE",
          desc_items: [
            {
              desc: "Coupon.Type",
            },
          ],
          just: "L",
          index: "N",
          ignore_change: "N",
          len: "1",
          json_name: "",
        },
        {
          field_no: "132",
          control_field: "130",
          conv: "MD2",
          dict_name: "COUP.DISC.AMT",
          desc_items: [
            {
              desc: "Coup.Disc.Amt",
            },
          ],
          just: "R",
          index: "N",
          ignore_change: "N",
          len: "9",
          json_name: "",
        },
        {
          field_no: "133",
          control_field: "130",
          conv: "MD2",
          dict_name: "COUP.DISC.PCT",
          desc_items: [
            {
              desc: "Coup.Disc.Pct",
            },
          ],
          just: "R",
          ignore_change: "N",
          len: "6",
          json_name: "",
        },
        {
          field_no: "134",
          control_field: "130",
          conv: "MD2",
          dict_name: "COUP.MAX.DISC",
          desc_items: [
            {
              desc: "Coup.Max.Disc",
            },
          ],
          just: "R",
          ignore_change: "N",
          len: "9",
          json_name: "",
        },
        {
          field_no: "135",
          control_field: "130",
          conv: "MD2",
          dict_name: "COUP.MIN.ORD",
          desc_items: [
            {
              desc: "Coup.Min.Ord",
            },
          ],
          just: "R",
          ignore_change: "N",
          len: "9",
          json_name: "",
        },
        {
          field_no: "136",
          control_field: "130",
          dict_name: "COUP.LI",
          desc_items: [
            {
              desc: "Coup.Li",
            },
          ],
          structure: "136ü137ü138",
          just: "R",
          index: "N",
          ignore_change: "N",
          len: "3",
          json_name: "",
        },
        {
          field_no: "137",
          control_field: "136",
          conv: "MD2",
          dict_name: "COUP.LI.DISC",
          desc_items: [
            {
              desc: "Coup.Li.Disc",
            },
          ],
          just: "R",
          ignore_change: "N",
          len: "9",
          json_name: "",
        },
        {
          field_no: "138",
          control_field: "136",
          conv: "MD2",
          dict_name: "COUP.SHIP.AMT",
          desc_items: [
            {
              desc: "Coup.Ship.Amt",
            },
          ],
          just: "R",
          ignore_change: "N",
          len: "9",
          json_name: "",
        },
        {
          field_no: "139",
          dict_name: "EDI.ORDER",
          desc_items: [
            {
              desc: "Edi",
            },
            {
              desc: "Order",
            },
          ],
          just: "L",
          ignore_change: "N",
          len: "1",
          json_name: "",
        },
        {
          field_no: "140",
          conv: "MCU",
          dict_name: "CONTACT.ID",
          desc_items: [
            {
              desc: "Contact.Id",
            },
          ],
          just: "L",
          ignore_change: "N",
          len: "10",
          reference_file: "CONTACT",
          json_name: "",
        },
        {
          field_no: "141",
          conv: "MCU",
          dict_name: "CM.ID",
          desc_items: [
            {
              desc: "Credit.Memo",
            },
          ],
          structure: "141ü142",
          just: "R",
          ignore_change: "N",
          len: "9",
          reference_file: "AR",
          json_name: "",
        },
        {
          field_no: "142",
          control_field: "141",
          conv: "MD2",
          dict_name: "CM.AMOUNT",
          desc_items: [
            {
              desc: "CM.Amount",
            },
          ],
          just: "R",
          ignore_change: "N",
          len: "13",
          json_name: "",
        },
        {
          field_no: "143",
          control_field: "113",
          dict_name: "CC.ADDR",
          desc_items: [
            {
              desc: "Cc.Addr",
            },
          ],
          just: "L",
          ignore_change: "N",
          len: "30",
          json_name: "",
        },
        {
          field_no: "144",
          control_field: "113",
          dict_name: "CC.CITY",
          desc_items: [
            {
              desc: "Cc.City",
            },
          ],
          just: "L",
          ignore_change: "N",
          len: "25",
          json_name: "",
        },
        {
          field_no: "145",
          control_field: "113",
          dict_name: "CC.STATE",
          desc_items: [
            {
              desc: "Cc.State",
            },
          ],
          just: "L",
          index: "N",
          ignore_change: "N",
          len: "2",
          json_name: "",
        },
        {
          field_no: "146",
          control_field: "113",
          dict_name: "CC.ZIP",
          desc_items: [
            {
              desc: "Cc.Zip",
            },
          ],
          just: "L",
          ignore_change: "N",
          len: "10",
          json_name: "",
        },
        {
          field_no: "147",
          control_field: "113",
          dict_name: "CC.COUNTRY",
          desc_items: [
            {
              desc: "Cc.Country",
            },
          ],
          just: "L",
          ignore_change: "N",
          len: "50",
          json_name: "",
        },
        {
          field_no: "148",
          control_field: "113",
          dict_name: "CREDITCARD.ERR",
          desc_items: [
            {
              desc: "Creditcard.Err",
            },
          ],
          just: "T",
          ignore_change: "N",
          len: "25",
          json_name: "",
        },
        {
          field_no: "151",
          dict_name: "CURRENCY.CODE",
          desc_items: [
            {
              desc: "Currency",
            },
            {
              desc: "Code",
            },
          ],
          just: "L",
          ignore_change: "N",
          len: "8",
          valid_value_items: [
            {
              valid_value: "30",
              valid_desc: "60",
            },
          ],
          json_name: "",
        },
        {
          field_no: "152",
          conv: "MD4",
          dict_name: "EXCHANGE.RATE",
          desc_items: [
            {
              desc: "Exchange",
            },
            {
              desc: "Rate",
            },
          ],
          just: "R",
          ignore_change: "N",
          len: "11",
          json_name: "",
        },
        {
          field_no: "153",
          dict_name: "SAT.DELIVERY",
          boolean: "Y",
          desc_items: [
            {
              desc: "Saturday",
            },
            {
              desc: "Delivery",
            },
          ],
          just: "L",
          ignore_change: "N",
          len: "1",
          json_name: "",
        },
        {
          field_no: "154",
          dict_name: "SHIPPER.ACCOUNT",
          desc_items: [
            {
              desc: "Shipper",
            },
            {
              desc: "Account",
            },
          ],
          just: "L",
          index: "N",
          ignore_change: "N",
          len: "15",
          json_name: "",
        },
        {
          field_no: "155",
          dict_name: "ACCT.RELEASE.ID",
          desc_items: [
            {
              desc: "Acct.Release.ID",
            },
          ],
          just: "L",
          ignore_change: "N",
          len: "15",
          json_name: "",
        },
        {
          field_no: "156",
          dict_name: "END.USER.CNTRY",
          desc_items: [
            {
              desc: "End.User.Cntry",
            },
          ],
          just: "L",
          ignore_change: "N",
          len: "25",
          json_name: "",
        },
        {
          field_no: "157",
          dict_name: "END.USER",
          desc_items: [
            {
              desc: "End.User",
            },
          ],
          just: "L",
          ignore_change: "N",
          len: "30",
          json_name: "",
        },
        {
          field_no: "158",
          dict_name: "END.USER.STMT",
          boolean: "Y",
          desc_items: [
            {
              desc: "End.User.Stmt",
            },
          ],
          just: "L",
          ignore_change: "N",
          len: "1",
          json_name: "",
        },
        {
          field_no: "159",
          control_field: "26",
          dict_name: "LI.END.USE",
          desc_items: [
            {
              desc: "Li.End.Use",
            },
          ],
          just: "L",
          ignore_change: "N",
          len: "25",
          json_name: "",
        },
        {
          field_no: "160",
          dict_name: "EXPORT.LIST",
          desc_items: [
            {
              desc: "Export.List",
            },
          ],
          structure: "160ü161",
          just: "L",
          index: "N",
          ignore_change: "N",
          len: "35",
          json_name: "",
        },
        {
          field_no: "161",
          control_field: "160",
          boolean: "Y",
          dict_name: "EXPORT.LIST.CHK",
          desc_items: [
            {
              desc: "Export.List.Chk",
            },
          ],
          just: "L",
          ignore_change: "N",
          len: "1",
          json_name: "",
        },
        {
          field_no: "162",
          dict_name: "FIRST.SHIP.ONLY",
          boolean: "Y",
          desc_items: [
            {
              desc: "Freight First",
            },
            {
              desc: "Shipment Only",
            },
          ],
          just: "L",
          ignore_change: "N",
          len: "1",
          json_name: "",
        },
        {
          field_no: "163",
          dict_name: "FRT.SERV.CODE",
          desc_items: [
            {
              desc: "Frt.Serv.Code",
            },
          ],
          just: "L",
          index: "N",
          ignore_change: "N",
          len: "4",
          json_name: "",
        },
        {
          field_no: "164",
          dict_name: "PKG.TYPE.CODE",
          desc_items: [
            {
              desc: "Pkg.Type.Code",
            },
          ],
          structure: "164ü165ü166ü167ü168ü169ü170ü171",
          just: "L",
          ignore_change: "N",
          len: "4",
          json_name: "",
        },
        {
          field_no: "165",
          control_field: "164",
          dict_name: "PKG.WEIGHT",
          desc_items: [
            {
              desc: "Pkg.Weight",
            },
          ],
          just: "R",
          ignore_change: "N",
          len: "9",
          json_name: "",
        },
        {
          field_no: "166",
          control_field: "164",
          dict_name: "PKG.WEIGHT.UOM",
          desc_items: [
            {
              desc: "Pkg.Weight.Uom",
            },
          ],
          just: "L",
          ignore_change: "N",
          len: "3",
          json_name: "",
        },
        {
          field_no: "167",
          control_field: "164",
          dict_name: "PKG.LENGTH",
          desc_items: [
            {
              desc: "Pkg.Length",
            },
          ],
          just: "R",
          ignore_change: "N",
          len: "4",
          json_name: "",
        },
        {
          field_no: "168",
          control_field: "164",
          dict_name: "PKG.WIDTH",
          desc_items: [
            {
              desc: "Pkg.Width",
            },
          ],
          just: "R",
          ignore_change: "N",
          len: "4",
          json_name: "",
        },
        {
          field_no: "169",
          control_field: "164",
          dict_name: "PKG.HEIGHT",
          desc_items: [
            {
              desc: "Pkg.Height",
            },
          ],
          just: "R",
          ignore_change: "N",
          len: "4",
          json_name: "",
        },
        {
          field_no: "170",
          control_field: "164",
          dict_name: "PKG.DIM.UOM",
          desc_items: [
            {
              desc: "Pkg.Dim.Uom",
            },
          ],
          just: "L",
          ignore_change: "N",
          len: "2",
          json_name: "",
        },
        {
          field_no: "171",
          control_field: "164",
          conv: "MD2",
          dict_name: "PKG.VALUE",
          desc_items: [
            {
              desc: "Pkg.Value",
            },
          ],
          just: "R",
          ignore_change: "N",
          len: "9",
          json_name: "",
        },
        {
          field_no: "172",
          conv: "MD2",
          dict_name: "FRT.MARKUP.AMT",
          desc_items: [
            {
              desc: "Frt.Markup.Amt",
            },
          ],
          just: "R",
          index: "N",
          ignore_change: "N",
          len: "7",
          json_name: "",
        },
        {
          field_no: "173",
          control_field: "113",
          dict_name: "CREDIT.CARD.ID",
          desc_items: [
            {
              desc: "Credit.Card.Id",
            },
          ],
          just: "L",
          index: "N",
          ignore_change: "N",
          len: "50",
          json_name: "",
        },
        {
          field_no: "174",
          control_field: "59",
          conv: "MD4",
          dict_name: "TAX.CODE.RATE",
          desc_items: [
            {
              desc: "Tax.Code.Rate",
            },
          ],
          just: "R",
          index: "N",
          ignore_change: "N",
          len: "7",
          json_name: "",
        },
        {
          field_no: "175",
          dict_name: "TAX.FREIGHT",
          boolean: "Y",
          desc_items: [
            {
              desc: "Tax.Freight",
            },
          ],
          just: "L",
          index: "N",
          ignore_change: "N",
          len: "1",
          json_name: "",
        },
        {
          field_no: "176",
          dict_name: "PAYMENT.PLAN",
          boolean: "Y",
          desc_items: [
            {
              desc: "Payment.Plan",
            },
          ],
          just: "L",
          index: "N",
          ignore_change: "N",
          len: "1",
          json_name: "",
        },
        {
          field_no: "177",
          dict_name: "PREPAY",
          boolean: "Y",
          desc_items: [
            {
              desc: "Prepay",
            },
          ],
          just: "L",
          index: "N",
          ignore_change: "N",
          len: "1",
          json_name: "",
        },
        {
          field_no: "178",
          conv: "MD2",
          dict_name: "TAX.AMOUNT",
          desc_items: [
            {
              desc: "Tax.Amount",
            },
          ],
          multi_line: "N",
          just: "R",
          index: "N",
          ignore_change: "N",
          len: "9",
          json_name: "",
        },
        {
          field_no: "179",
          control_field: "26",
          dict_name: "MIVA.LI.NO",
          desc_items: [
            {
              desc: "Miva.Li.No",
            },
          ],
          multi_line: "N",
          just: "R",
          index: "N",
          ignore_change: "N",
          len: "4",
          json_name: "",
        },
        {
          field_no: "201",
          control_field: "26",
          conv: "MCU",
          dict_name: "LOT.NUMBER",
          desc_items: [
            {
              desc: "Lot.Number",
            },
          ],
          multi_line: "N",
          just: "R",
          index: "N",
          ignore_change: "N",
          len: "10",
          reference_file: "LOT",
          json_name: "",
        },
        {
          field_no: "202",
          dict_name: "SIGNATURE",
          desc_items: [
            {
              desc: "Signature",
            },
          ],
          just: "L",
          index: "N",
          ignore_change: "N",
          len: "256",
          json_name: "",
        },
        {
          field_no: "204",
          conv: "D2-",
          boolean: "N",
          dict_name: "SIGNATURE.DATE",
          desc_items: [
            {
              desc: "Signature.Date",
            },
          ],
          multi_line: "N",
          just: "R",
          index: "N",
          ignore_change: "N",
          len: "8",
          json_name: "",
        },
        {
          field_no: "205",
          conv: "MTS",
          boolean: "N",
          dict_name: "SIGNATURE.TIME",
          desc_items: [
            {
              desc: "Signature.Time",
            },
          ],
          multi_line: "N",
          just: "R",
          index: "N",
          ignore_change: "N",
          len: "8",
          json_name: "",
        },
        {
          field_no: "206",
          dict_name: "SIGNATURE.BY",
          boolean: "N",
          desc_items: [
            {
              desc: "Signature.By",
            },
          ],
          multi_line: "N",
          just: "L",
          index: "N",
          ignore_change: "N",
          len: "40",
          json_name: "",
        },
        {
          field_no: "207",
          dict_name: "SIGNATURE.TYPE",
          boolean: "N",
          desc_items: [
            {
              desc: "Signature.Type",
            },
          ],
          multi_line: "N",
          just: "L",
          len: "30",
          json_name: "",
        },
        {
          field_no: "208",
          dict_name: "SHOPIFY.ID",
          boolean: "N",
          desc_items: [
            {
              desc: "Shopify.Id",
            },
          ],
          just: "R",
          len: "10",
          json_name: "",
        },
        {
          field_no: "209",
          control_field: "26",
          dict_name: "PRICE.MOD.CODE",
          desc_items: [
            {
              desc: "Price.Mod.Code",
            },
          ],
          multi_line: "N",
          just: "L",
          len: "10",
          required: "N",
          valid_value_items: [
            {
              valid_value: "A",
              valid_desc: "Price Match",
            },
            {
              valid_value: "B",
              valid_desc: "Promo Discount",
            },
            {
              valid_value: "C",
              valid_desc: "Bulk Discount",
            },
          ],
          json_name: "",
        },
        {
          field_no: "210",
          dict_name: "POS.ORDER.CODE",
          desc_items: [
            {
              desc: "Pos.Order.Code",
            },
          ],
          just: "L",
          len: "1",
          json_name: "",
        },
        {
          field_no: "211",
          control_field: "26",
          conv: "MCU",
          dict_name: "SERIAL.ID",
          desc_items: [
            {
              desc: "Serial.Id",
            },
          ],
          multi_line: "N",
          just: "L",
          len: "35",
          required: "N",
          json_name: "",
        },
      ],
      correl_field_no_items: [
        {
          correl_field_no: "129",
          correl_desc: "FULL.DESC",
          corr_ctrl_fld: "26",
          correl_dict_id: "FULL.DESC",
          correlative: "CALL P$SOFULLDESC",
          correl_just: "L",
          correl_len: "40",
          correl_rw_ok: "Y",
        },
        {
          correl_field_no: "2",
          correl_desc: "Customer.Name",
          correl_dict_id: "CUST.NAME",
          correlative: "TCUST;X;;1",
          correl_just: "L",
          correl_len: "30",
          correl_rw_ok: "Y",
        },
        {
          correl_field_no: "2",
          correl_desc: "Cust.City",
          correl_dict_id: "CUST.CITY",
          correlative: "TCUST;X;;27",
          correl_just: "L",
          correl_len: "25",
          correl_rw_ok: "Y",
        },
        {
          correl_field_no: "2",
          correl_desc: "Cust.State",
          correl_dict_id: "CUST.STATE",
          correlative: "TCUST;X;;28",
          correl_just: "L",
          correl_len: "10",
          correl_rw_ok: "Y",
        },
        {
          correl_field_no: "2",
          correl_desc: "Cust.Country",
          correl_dict_id: "CUST.COUNTRY",
          correlative: "TCUST;X;;31ü",
          correl_just: "L",
          correl_len: "50",
          correl_rw_ok: "Y",
        },
        {
          correl_field_no: "2",
          correl_desc: "Cust.Discount",
          correl_conv: "MD2",
          correl_dict_id: "CUST.DISC",
          correlative: "TCUST;X;;14",
          correl_just: "R",
          correl_len: "10",
          correl_rw_ok: "Y",
        },
        {
          correl_field_no: "0",
          correl_desc: "Open.Amount",
          correl_conv: "MD2",
          correl_dict_id: "OPEN.AMOUNT",
          correlative: "CALL P$SOOPENAMT",
          correl_just: "R",
          correl_len: "12",
          correl_rw_ok: "Y",
        },
        {
          correl_field_no: "0",
          correl_desc: "OpenüOrder",
          correl_dict_id: "OPEN.ORDER",
          correlative:
            'IF F14 = "N" OR F14 = "B" OR F112 = "Y" THEN "Y" ELSE ""ü',
          correl_just: "L",
          correl_index: "Y",
          correl_len: "1",
          correl_rw_ok: "Y",
          correl_type: "I",
        },
        {
          correl_field_no: "0",
          correl_desc: "Order.Amount",
          correl_conv: "MD2",
          correl_dict_id: "ORDER.AMOUNT",
          correlative: "CALL P$SOAMOUNT",
          correl_just: "R",
          correl_len: "12",
          correl_rw_ok: "Y",
        },
        {
          correl_field_no: "0",
          correl_desc: "Mrk.Code",
          corr_ctrl_fld: "26",
          correl_dict_id: "MRK.CODE",
          correlative: "F27üTPARTS;X;;39",
          correl_just: "L",
          correl_len: "10",
          correl_rw_ok: "Y",
        },
        {
          correl_field_no: "0",
          correl_desc: "PartüStatus",
          corr_ctrl_fld: "26",
          correl_dict_id: "PART.STATUS",
          correlative: "F27üTPARTS;X;;15",
          correl_just: "L",
          correl_len: "1",
          correl_rw_ok: "Y",
        },
        {
          correl_field_no: "27",
          correl_desc: "Category",
          corr_ctrl_fld: "26",
          correl_conv: "MCU",
          correl_dict_id: "CATEGORY",
          correlative: "F27üTPARTS;X;;42",
          correl_just: "L",
          correl_len: "3",
          correl_rw_ok: "Y",
        },
        {
          correl_field_no: "0",
          correl_desc: "Part.Type",
          corr_ctrl_fld: "26",
          correl_dict_id: "PART.TYPE",
          correlative: "F27üTPARTS;X;;2",
          correl_just: "L",
          correl_len: "1",
          correl_rw_ok: "Y",
        },
        {
          correl_field_no: "4",
          correl_desc: "Bill.To.Name",
          correl_dict_id: "BILL.TO.NAME",
          correlative: "TCUST;X;;1",
          correl_just: "L",
          correl_len: "25",
          correl_rw_ok: "Y",
        },
        {
          correl_field_no: "2",
          correl_desc: "Cust.PriceüCode",
          correl_dict_id: "CUST.PRICE.CODE",
          correlative: "TCUST;X;;20",
          correl_just: "L",
          correl_len: "10",
          correl_rw_ok: "Y",
        },
        {
          correl_field_no: "0",
          correl_desc: "Credit.Balance",
          correl_conv: "MD2",
          correl_dict_id: "CREDIT.BALANCE",
          correlative: "CALL P$CREDITBALANCE",
          correl_just: "R",
          correl_len: "12",
        },
        {
          correl_field_no: "0",
          correl_desc: "Credit.Limit",
          correl_conv: "MD0",
          correl_dict_id: "CREDIT.LIMIT",
          correlative: "CALL P$CREDITLIMIT",
          correl_just: "R",
          correl_len: "9",
          correl_rw_ok: "Y",
        },
        {
          correl_field_no: "0",
          correl_desc: "AR.Balance",
          correl_conv: "MD2",
          correl_dict_id: "AR.BAL",
          correlative: "CALL P$ARBALANCE",
          correl_just: "R",
          correl_len: "12",
          correl_rw_ok: "Y",
        },
        {
          correl_field_no: "0",
          correl_desc: "Open.Orders",
          correl_conv: "MD2",
          correl_dict_id: "OPEN.ORDERS",
          correlative: "CALL P$OPENORDERS",
          correl_just: "R",
          correl_len: "12",
          correl_rw_ok: "Y",
        },
        {
          correl_field_no: "0",
          correl_desc: "WoüStatus",
          corr_ctrl_fld: "26",
          correl_dict_id: "WO.STATUS",
          correlative: "F55üTWO;X;;10",
          correl_just: "L",
          correl_len: "1",
          correl_rw_ok: "Y",
        },
        {
          correl_field_no: "0",
          correl_desc: "WO.Date",
          corr_ctrl_fld: "26",
          correl_conv: "D2-",
          correl_dict_id: "WO.DATE",
          correlative: "F55üTWO;X;;1ü",
          correl_just: "R",
          correl_len: "8",
          correl_rw_ok: "Y",
        },
        {
          correl_field_no: "0",
          correl_desc: "WO.Rel.Date",
          corr_ctrl_fld: "26",
          correl_conv: "D2-",
          correl_dict_id: "WO.REL.DATE",
          correlative: "F55üTWO;X;;14ü",
          correl_just: "R",
          correl_len: "8",
          correl_rw_ok: "Y",
        },
        {
          correl_field_no: "0",
          correl_desc: "WO.Comp.Date",
          corr_ctrl_fld: "26",
          correl_conv: "D2-",
          correl_dict_id: "WO.COMP.DATE",
          correlative: "F55üTWO;X;;15ü",
          correl_just: "R",
          correl_len: "8",
          correl_rw_ok: "Y",
        },
        {
          correl_field_no: "0",
          correl_desc: "CreditüCust",
          correl_conv: "MCU",
          correl_dict_id: "CREDIT.CUST",
          correlative: "CALL P$CREDITCUST",
          correl_just: "L",
          correl_len: "10",
          correl_rw_ok: "Y",
        },
        {
          correl_field_no: "0",
          correl_desc: "DateüEntered",
          correl_conv: "D2-",
          correl_dict_id: "DATE.ENTERED",
          correlative: "CALL P$SODATEENTERED",
          correl_just: "R",
          correl_len: "8",
          correl_rw_ok: "Y",
        },
        {
          correl_field_no: "0",
          correl_desc: "TimeüEntered",
          correl_conv: "MTS",
          correl_dict_id: "TIME.ENTERED",
          correlative: "CALL P$SOTIMEENTERED",
          correl_just: "R",
          correl_len: "8",
          correl_rw_ok: "Y",
        },
        {
          correl_field_no: "0",
          correl_desc: "Entered.By",
          correl_conv: "MCU",
          correl_dict_id: "ENTERED.BY",
          correlative: "CALL P$SOENTEREDBY",
          correl_just: "L",
          correl_len: "15",
          correl_rw_ok: "Y",
        },
        {
          correl_field_no: "2",
          correl_desc: "CustomerüResale.No",
          correl_dict_id: "CUST.RESALE",
          correlative: "TCUST;X;;13",
          correl_just: "L",
          correl_len: "15",
          correl_rw_ok: "Y",
        },
        {
          correl_field_no: "2",
          correl_desc: "Cust.Terms",
          correl_conv: "MCU",
          correl_dict_id: "CUST.TERMS",
          correlative: "TCUST;X;;10",
          correl_just: "L",
          correl_len: "10",
          correl_rw_ok: "Y",
        },
        {
          correl_field_no: "9",
          correl_desc: "InvalidüShip.Via",
          correl_conv: "MCU",
          correl_dict_id: "INVALID.SHIPVIA",
          correlative: "CALL P$SOINVALIDSHIPVIA",
          correl_just: "L",
          correl_len: "1",
          correl_rw_ok: "Y",
        },
        {
          correl_field_no: "0",
          correl_desc: "Li.Qty.Bal",
          corr_ctrl_fld: "26",
          correl_conv: "MD2",
          correl_dict_id: "LI.QTY.BAL",
          correlative: "F26üCALL P$SOLIQTYBAL",
          correl_just: "R",
          correl_len: "12",
          correl_rw_ok: "Y",
        },
        {
          correl_field_no: "0",
          correl_desc: "Li.Open.Amount",
          corr_ctrl_fld: "26",
          correl_conv: "MD2",
          correl_dict_id: "LI.OPEN.AMT",
          correlative: "F26üCALL P$SOLIOPENAMT",
          correl_just: "R",
          correl_len: "12",
          correl_rw_ok: "Y",
        },
        {
          correl_field_no: "0",
          correl_desc: "FirstüSched.Date",
          correl_conv: "D2-",
          correl_dict_id: "FIRST.SCH.DATE",
          correlative: "CALL P$SOFIRSTSCHDATE",
          correl_just: "R",
          correl_len: "8",
          correl_rw_ok: "Y",
        },
        {
          correl_field_no: "0",
          correl_desc: "First.OpenüSched.Date",
          correl_conv: "D2-",
          correl_dict_id: "FIRST.OPEN.DATE",
          correlative: "CALL P$SOEARLIESTOPENDATE",
          correl_just: "R",
          correl_len: "8",
          correl_rw_ok: "Y",
        },
        {
          correl_field_no: "0",
          correl_desc: "InvalidüCountry",
          correl_dict_id: "INVALID.COUNTRY",
          correlative: "CALL P$INVALIDCOUNTRY",
          correl_just: "L",
          correl_len: "1",
        },
        {
          correl_field_no: "0",
          correl_desc: "Li.Count",
          correl_dict_id: "LI.COUNT",
          correlative: "CALL P$SOLICOUNT",
          correl_just: "R",
          correl_len: "6",
          correl_rw_ok: "Y",
        },
        {
          correl_field_no: "26",
          correl_desc: "Lot.Part",
          corr_ctrl_fld: "26",
          correl_dict_id: "LOT.PART",
          correlative: "F27üTPARTS;X;;21",
          correl_just: "L",
          correl_len: "1",
          correl_rw_ok: "Y",
        },
        {
          correl_field_no: "26",
          correl_desc: "Std.Price",
          corr_ctrl_fld: "26",
          correl_conv: "MD4",
          correl_dict_id: "STD.PRICE",
          correlative: "F27üTPRICE;X1;;1",
          correl_just: "R",
          correl_len: "12",
          correl_rw_ok: "Y",
        },
        {
          correl_field_no: "10",
          correl_desc: "Rep.Name",
          corr_ctrl_fld: "10",
          correl_dict_id: "REP.NAME",
          correlative: "TREP;X;;1",
          correl_just: "L",
          correl_len: "25",
          correl_rw_ok: "Y",
        },
        {
          correl_field_no: "0",
          correl_desc: "Upc.Code",
          corr_ctrl_fld: "26",
          correl_dict_id: "UPC.CODE",
          correlative: "F27üTPARTS;X;;64",
          correl_just: "L",
          correl_len: "20",
          correl_rw_ok: "Y",
        },
        {
          correl_field_no: "0",
          correl_desc: "Li.Sch.Qty.Bal",
          corr_ctrl_fld: "30",
          correl_conv: "MD2",
          correl_dict_id: "LI.SCH.QTY.BAL",
          correlative: "F30üCALL P$SOLISCHQTYBAL",
          correl_just: "R",
          correl_len: "12",
          correl_rw_ok: "Y",
        },
        {
          correl_field_no: "26",
          correl_desc: "On.Hand",
          corr_ctrl_fld: "26",
          correl_conv: "MD2",
          correl_dict_id: "ON.HAND",
          correlative: "F27üCALL P$PARTSONHAND",
          correl_just: "R",
          correl_len: "12",
          correl_rw_ok: "Y",
        },
        {
          correl_field_no: "26",
          correl_desc: "FG.Ref.Bins",
          corr_ctrl_fld: "26",
          correl_dict_id: "FG.REF.BINS",
          correlative: "F27üCALL P$FGREFBINLIST",
          correl_just: "L",
          correl_len: "25",
          correl_rw_ok: "Y",
        },
        {
          correl_field_no: "26",
          correl_desc: "Ref.Bin.List",
          corr_ctrl_fld: "26",
          correl_dict_id: "REF.BIN.LIST",
          correlative: "CALL P$PARTSREFBINLISTü",
          correl_just: "L",
          correl_len: "25",
          correl_rw_ok: "Y",
        },
        {
          correl_field_no: "26",
          correl_desc: "Model",
          corr_ctrl_fld: "26",
          correl_dict_id: "MODEL",
          correlative: "F27üTPARTS;X;;41",
          correl_just: "L",
          correl_len: "20",
          correl_rw_ok: "Y",
        },
        {
          correl_field_no: "0",
          correl_desc: "SoüCount",
          correl_dict_id: "SO.CNT",
          correlative: 'A;"1"',
          correl_just: "R",
          correl_len: "1",
          correl_rw_ok: "Y",
        },
        {
          correl_field_no: "61",
          correl_desc: "RFQ",
          correl_dict_id: "RFQ",
          correlative: "TSOQUOTE;X;;48",
          correl_just: "L",
          correl_len: "20",
          correl_rw_ok: "Y",
        },
        {
          correl_field_no: "17",
          correl_desc: "Price.Code.Description",
          correl_dict_id: "PRICE.CODE.DESC",
          correlative: "CALL P$PRICECODEDESC",
          correl_just: "L",
          correl_len: "25",
          correl_rw_ok: "Y",
        },
        {
          correl_field_no: "0",
          correl_desc: "Type.Description",
          correl_dict_id: "TYPE.DESC",
          correlative: "CALL P$SOTYPEDESC",
          correl_just: "L",
          correl_len: "25",
          correl_rw_ok: "Y",
        },
        {
          correl_field_no: "0",
          correl_desc: "Hold.Description",
          correl_dict_id: "HOLD.CODE.DESC",
          correlative: "CALL P$SOHOLDDESCü",
          correl_just: "L",
          correl_len: "25",
          correl_rw_ok: "Y",
        },
        {
          correl_field_no: "27",
          correl_desc: "Curr On Hand",
          corr_ctrl_fld: "26",
          correl_conv: "MD2",
          correl_dict_id: "CURR.QOH",
          correlative: "CALL P$SOINVQOHü",
          correl_just: "R",
          correl_len: "12",
          correl_rw_ok: "Y",
        },
        {
          correl_field_no: "27",
          correl_desc: "Curr On Order",
          corr_ctrl_fld: "26",
          correl_conv: "MD2",
          correl_dict_id: "CURR.QPR",
          correlative: "CALL P$SOINVQPR",
          correl_just: "R",
          correl_len: "12",
          correl_rw_ok: "Y",
          correl_multi_li: "N",
        },
        {
          correl_field_no: "27",
          correl_desc: "Curr Committed",
          corr_ctrl_fld: "26",
          correl_conv: "MR2",
          correl_dict_id: "CURR.QPS",
          correlative: "CALL P$SOINVQPS",
          correl_just: "R",
          correl_len: "12",
          correl_rw_ok: "Y",
          correl_multi_li: "N",
        },
        {
          correl_field_no: "27",
          correl_desc: "Curr Allocated",
          corr_ctrl_fld: "26",
          correl_conv: "MR2",
          correl_dict_id: "CURR.QALLOC",
          correlative: "CALL P$SOINVQALLOCü",
          correl_just: "R",
          correl_index: "N",
          correl_len: "12",
          correl_rw_ok: "Y",
          correl_multi_li: "N",
        },
        {
          correl_field_no: "27",
          correl_desc: "Curr Available",
          corr_ctrl_fld: "26",
          correl_conv: "MR2",
          correl_dict_id: "CURR.AVAIL",
          correlative: "CALL P$SOINVQAVL",
          correl_just: "R",
          correl_index: "N",
          correl_len: "12",
          correl_rw_ok: "Y",
          correl_multi_li: "N",
        },
        {
          correl_field_no: "27",
          correl_desc: "3 Mos Usage",
          corr_ctrl_fld: "26",
          correl_conv: "MR2",
          correl_dict_id: "3MOS.USAGE",
          correlative: "CALL P$SOUSAGE3MOS",
          correl_just: "R",
          correl_index: "N",
          correl_len: "12",
          correl_rw_ok: "Y",
          correl_multi_li: "N",
        },
        {
          correl_field_no: "27",
          correl_desc: "6 Mos Usage",
          corr_ctrl_fld: "26",
          correl_conv: "MR2",
          correl_dict_id: "6MOS.USAGE",
          correlative: "CALL P$SOUSAGE6MOSü",
          correl_just: "R",
          correl_index: "N",
          correl_len: "12",
          correl_rw_ok: "Y",
          correl_multi_li: "N",
        },
        {
          correl_field_no: "0",
          correl_desc: "Wo.Present",
          corr_ctrl_fld: "26",
          correl_dict_id: "WO.PRESENT",
          correlative: "CALL P$SOWOPRESENTü",
          correl_just: "L",
          correl_index: "N",
          correl_len: "1",
          correl_rw_ok: "Y",
          correl_multi_li: "N",
        },
        {
          correl_field_no: "103",
          correl_desc: "Po.Status",
          corr_ctrl_fld: "26",
          correl_dict_id: "PO.STATUS",
          correlative: "F103üTPO;X;;8",
          correl_just: "L",
          correl_len: "1",
          correl_rw_ok: "Y",
        },
        {
          correl_field_no: "0",
          correl_desc: "Open.Wo",
          corr_ctrl_fld: "26",
          correl_dict_id: "OPEN.WO",
          correlative: "F26üCALL P$SOOPENWOü",
          correl_just: "L",
          correl_index: "N",
          correl_len: "30",
          correl_rw_ok: "Y",
          correl_multi_li: "N",
        },
        {
          correl_field_no: "0",
          correl_desc: "Attachments",
          correl_dict_id: "ATTACHMENTS",
          correlative: 'SUBR("ACE$GETATTACHMENTRECORDID","SO")',
          correl_just: "R",
          correl_index: "Y",
          correl_len: "10",
          correl_rw_ok: "Y",
          correl_multi_li: "Y",
          correl_type: "I",
        },
        {
          correl_field_no: "0",
          correl_desc: "Curr.Avl.Toship",
          corr_ctrl_fld: "26",
          correl_conv: "MR2",
          correl_dict_id: "CURR.AVL.TOSHIP",
          correlative: "CALL P$SOINVQAVLTOSHIP",
          correl_just: "R",
          correl_len: "12",
          correl_rw_ok: "Y",
          correl_multi_li: "N",
        },
      ],
      file_title: "Sales Order",
      lookup_name_items: [
        {
          lookup_name: "PO",
          lu_menu_caption: "PO Number Lookup",
          lu_enabled: "Y",
          lu_display_fld_items: [
            {
              lu_display_fld: "SO.ID",
            },
            {
              lu_display_fld: "CUST.NAME",
            },
            {
              lu_display_fld: "STATUS",
            },
            {
              lu_display_fld: "DATE",
            },
            {
              lu_display_fld: "LIS",
            },
            {
              lu_display_fld: "LI.PARTS",
            },
          ],
          lu_compare_fld_items: [
            {
              lu_compare_fld: "PO.NUMBER",
              lu_compare_oper: "Containing",
              lu_compare_val: "?Enter PO number",
            },
          ],
          lu_list_caption: "Purchase Order Lookup",
          lu_std_product: "Y",
          lu_sort_field_items: [
            {
              lu_sort_field: "DATE",
              lu_sort_seq: "Descending",
            },
          ],
        },
        {
          lookup_name: "CUST.OPEN",
          lu_menu_caption: "Customer Lookup for Open Orders",
          lu_enabled: "Y",
          lu_display_fld_items: [
            {
              lu_display_fld: "SO.ID",
            },
            {
              lu_display_fld: "DATE",
            },
            {
              lu_display_fld: "STATUS",
            },
            {
              lu_display_fld: "LIS",
            },
            {
              lu_display_fld: "LI.PARTS",
            },
            {
              lu_display_fld: "WRAP.DESC",
            },
            {
              lu_display_fld: "LI.SCHED.DATES",
            },
            {
              lu_display_fld: "LI.SCHED.QTYS",
            },
            {
              lu_display_fld: "LI.OPEN.QTYS",
            },
            {
              lu_display_fld: "PENDING.SHIP",
            },
          ],
          lu_compare_fld_items: [
            {
              lu_compare_fld: "SOLD.TO",
              lu_compare_oper: "Equal to",
              lu_compare_not: "N",
              lu_compare_val: "@ACE$LOOKUP,CUST,CUST.NAME",
            },
            {
              lu_compare_fld: "OPEN.ORDER",
              lu_compare_oper: "Equal to",
              lu_compare_not: "N",
              lu_compare_val: "Y",
            },
          ],
          lu_list_caption: "Customer Lookup",
          lu_std_product: "Y",
          lu_sort_field_items: [
            {
              lu_sort_field: "DATE",
              lu_sort_seq: "Descending",
            },
          ],
        },
        {
          lookup_name: "CUST.ALL",
          lu_menu_caption: "Customer Lookup for All Orders",
          lu_enabled: "Y",
          lu_display_fld_items: [
            {
              lu_display_fld: "SO.ID",
            },
            {
              lu_display_fld: "STATUS",
            },
            {
              lu_display_fld: "DATE",
            },
            {
              lu_display_fld: "LIS",
            },
            {
              lu_display_fld: "LI.PARTS",
            },
            {
              lu_display_fld: "LI.SCHED.DATES",
            },
            {
              lu_display_fld: "LI.SCHED.QTYS",
            },
            {
              lu_display_fld: "LI.OPEN.QTYS",
            },
          ],
          lu_compare_fld_items: [
            {
              lu_compare_fld: "SOLD.TO",
              lu_compare_oper: "Equal to",
              lu_compare_val: "@ACE$LOOKUP,CUST,CUST.NAME",
            },
          ],
          lu_list_caption: "Customer Lookup",
          lu_std_product: "Y",
          lu_sort_field_items: [
            {
              lu_sort_field: "DATE",
              lu_sort_seq: "Descending",
            },
          ],
        },
        {
          lookup_name: "PART.OPEN",
          lu_menu_caption: "Part Number Lookup for Open Orders",
          lu_enabled: "Y",
          lu_display_fld_items: [
            {
              lu_display_fld: "SO.ID",
            },
            {
              lu_display_fld: "CUST.NAME",
            },
            {
              lu_display_fld: "STATUS",
            },
            {
              lu_display_fld: "DATE",
            },
          ],
          lu_compare_fld_items: [
            {
              lu_compare_fld: "LI.PARTS",
              lu_compare_oper: "Equal to",
              lu_compare_val:
                "?Enter the part number to be used in the lookup.",
            },
            {
              lu_compare_fld: "STATUS",
              lu_compare_oper: "Equal to",
              lu_compare_not: "Y",
              lu_compare_val: "C",
            },
          ],
          lu_list_caption: "Part Number Lookup",
          lu_std_product: "Y",
          lu_sort_field_items: [
            {
              lu_sort_field: "DATE",
              lu_sort_seq: "Descending",
            },
          ],
        },
        {
          lookup_name: "PART.ALL",
          lu_menu_caption: "Part Number Lookup for All Orders",
          lu_enabled: "Y",
          lu_display_fld_items: [
            {
              lu_display_fld: "SO.ID",
            },
            {
              lu_display_fld: "CUST.NAME",
            },
            {
              lu_display_fld: "STATUS",
            },
            {
              lu_display_fld: "DATE",
            },
          ],
          lu_compare_fld_items: [
            {
              lu_compare_fld: "LI.PARTS",
              lu_compare_oper: "Equal to",
              lu_compare_val:
                "?Enter the part number to be used in the lookup.",
            },
          ],
          lu_list_caption: "Part Number Lookup",
          lu_std_product: "Y",
          lu_sort_field_items: [
            {
              lu_sort_field: "DATE",
              lu_sort_seq: "Descending",
            },
          ],
        },
        {
          lookup_name: "TYPE",
          lu_menu_caption: "Lookup by Type Code",
          lu_enabled: "Y",
          lu_display_fld_items: [
            {
              lu_display_fld: "TYPE",
            },
            {
              lu_display_fld: "SO.ID",
            },
            {
              lu_display_fld: "CUST.NAME",
            },
            {
              lu_display_fld: "STATUS",
            },
            {
              lu_display_fld: "DATE",
            },
            {
              lu_display_fld: "LIS",
            },
            {
              lu_display_fld: "LI.PARTS",
            },
          ],
          lu_compare_fld_items: [
            {
              lu_compare_fld: "TYPE",
              lu_compare_oper: "Equal to",
              lu_compare_val: "?Enter Type Code",
            },
          ],
          lu_list_caption: "Lookup by Type Code",
          lu_std_product: "Y",
          lu_sort_field_items: [
            {
              lu_sort_field: "DATE",
            },
          ],
        },
        {
          lookup_name: "SOQUOTE.LOOKUP",
          lu_menu_caption: "Lookup by Sales Quote",
          lu_enabled: "Y",
          lu_display_fld_items: [
            {
              lu_display_fld: "SO.ID",
            },
            {
              lu_display_fld: "DATE",
            },
            {
              lu_display_fld: "SOLD.TO",
            },
            {
              lu_display_fld: "CUST.NAME",
            },
            {
              lu_display_fld: "STATUS",
            },
            {
              lu_display_fld: "QUOTE.NO",
            },
          ],
          lu_compare_fld_items: [
            {
              lu_compare_fld: "QUOTE.NO",
              lu_compare_oper: "Equal to",
              lu_compare_val: "?Enter the Sales Quote you wish to lookup.",
            },
          ],
          lu_list_caption: "Lookup by Sales Quote",
          lu_std_product: "Y",
          lu_sort_field_items: [
            {
              lu_sort_field: "DATE",
            },
            {
              lu_sort_field: "SO.ID",
            },
          ],
        },
      ],
      file_view_name_items: [
        {
          file_view_name: "OPEN",
          fv_menu_caption: "Open Sales Orders",
          fv_enabled: "Y",
          fv_display_fld_items: [
            {
              fv_display_fld: "SO.ID",
            },
            {
              fv_display_fld: "CUST.NAME",
            },
            {
              fv_display_fld: "DATE",
            },
            {
              fv_display_fld: "LIS",
            },
            {
              fv_display_fld: "LI.PARTS",
            },
            {
              fv_display_fld: "LI.SCHED.DATES",
            },
            {
              fv_display_fld: "LI.SCHED.QTYS",
            },
            {
              fv_display_fld: "LI.OPEN.QTYS",
            },
          ],
          fv_compare_fld_items: [
            {
              fv_compare_fld: "STATUS",
              fv_compare_oper: "Equal to",
              fv_compare_not: "Y",
              fv_compare_val: "C",
            },
          ],
          fv_list_caption: "Open Sales Orders",
          fv_std_product: "Y",
          fv_sort_field_items: [
            {
              fv_sort_field: "DATE",
              fv_sort_seq: "Descending",
            },
          ],
        },
        {
          file_view_name: "ALL",
          fv_menu_caption: "All Sales Orders",
          fv_enabled: "Y",
          fv_display_fld_items: [
            {
              fv_display_fld: "SO.ID",
            },
            {
              fv_display_fld: "CUST.NAME",
            },
            {
              fv_display_fld: "DATE",
            },
            {
              fv_display_fld: "LIS",
            },
            {
              fv_display_fld: "LI.PARTS",
            },
            {
              fv_display_fld: "LI.SCHED.DATES",
            },
            {
              fv_display_fld: "LI.SCHED.QTYS",
            },
            {
              fv_display_fld: "LI.OPEN.QTYS",
            },
          ],
          fv_list_caption: " All Sales Orders",
          fv_std_product: "Y",
          fv_sort_field_items: [
            {
              fv_sort_field: "DATE",
              fv_sort_seq: "Descending",
            },
          ],
        },
      ],
    },
  ],
};

import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, withModifiers as _withModifiers, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "accounting-tab" }
const _hoisted_2 = { class: "sales-tab p-0" }
const _hoisted_3 = { class: "grid align-content-center" }
const _hoisted_4 = { class: "col-12 text-center p-0 text-sm h-1rem" }
const _hoisted_5 = { class: "col-12 text-center p-0 text-sm h-1rem" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_TabPanel = _resolveComponent("TabPanel")!
  const _component_Button = _resolveComponent("Button")!
  const _component_TabView = _resolveComponent("TabView")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_TabView, {
      activeIndex: _ctx.getActiveTab,
      onTabChange: _ctx.handleMainTabChange,
      scrollable: true
    }, {
      default: _withCtx(() => [
        _createVNode(_component_TabPanel, null, {
          header: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              _createVNode(_component_router_link, {
                to: '/customers/sales/opportunities',
                class: "line-height-4 accounting-tab-item",
                onClick: _cache[0] || (_cache[0] = () => _ctx.handleMainTabChange({ index: 0 }))
              }, {
                default: _withCtx(() => _cache[1] || (_cache[1] = [
                  _createElementVNode("span", { class: "align-self-center pr-3 pl-0" }, "All Opportunities", -1)
                ])),
                _: 1
              })
            ])
          ]),
          _: 1
        }),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getActiveCustomerOpenSalesOpps, (opp, index) => {
          return (_openBlock(), _createBlock(_component_TabPanel, {
            key: opp.id,
            class: "pt-0"
          }, {
            header: _withCtx(() => [
              _createElementVNode("div", _hoisted_2, [
                _createVNode(_component_router_link, {
                  to: `/customers/${_ctx.getActiveIndexCustomer?.customer?.cust_id}/sales/opportunities/${
                opp.record.id ? opp.record.id : `New Opp-${index + 1}`
              }`,
                  class: "line-height-4 sales-tab-item p-0 m-0",
                  onClick: () => _ctx.handleMainTabChange({ index: index + 1 })
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_3, [
                      _createElementVNode("span", _hoisted_4, _toDisplayString(opp.record.id.startsWith("New")
                      ? "New Opportunity "
                      : "Opportunity "), 1),
                      _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.formatTabId(opp.record)) + " " + _toDisplayString(_ctx.hasChanged(opp) ? "*" : ""), 1)
                    ])
                  ]),
                  _: 2
                }, 1032, ["to", "onClick"]),
                _createVNode(_component_Button, {
                  icon: "pi pi-times",
                  rounded: "",
                  class: "p-button-text p-0 m-0",
                  title: `Close Sale Opportunity`,
                  onClick: _withModifiers(($event: any) => (_ctx.handleRemoveSaleOpp(opp.id)), ["stop"])
                }, null, 8, ["onClick"])
              ])
            ]),
            _: 2
          }, 1024))
        }), 128))
      ]),
      _: 1
    }, 8, ["activeIndex", "onTabChange"])
  ]))
}
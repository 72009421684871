import { Module, GetterTree, MutationTree, ActionTree } from "vuex";
import { RootState } from "@/types/state";
import SessionState, { Scan } from "@/types/state/session";
import TokenService from "@/services/TokenService";
import SessionAccessGetterFactory from "@/types/accessControl/sessionAccessGetterFactory";

const tokenService = new TokenService(process.env.VUE_APP_AZURE_FUNC_URL);
const namespaced = true;

export const state: SessionState = {
  user: null,
  client: null,
  loginUrl: null,
  loading: false,
  token: null,
  subKey: null,
  tokenTimeStamp: null,
  sessionStartDateStamp: null,
  roverJwt: null,
  tokenFetchInProgress: null,
  userTimeZone: null,
};

export const getters: GetterTree<SessionState, RootState> = {
  getUser(state): any {
    return state.user;
  },
  getFilter: (state) => (file_name: string, field_name: string) => {
    if (state.user?.attributes) {
      let list = state.user?.attributes.filter(
        (item: any) =>
          item.file_name == file_name && item.field_name == field_name,
      );
      list = list.flatMap((item: any) => item.value.split("|"));
      list = list.map((item: any) => item.trim()).filter((item: any) => item);
      return list;
    }
    return [];
  },
  getUserTimeZone(state): any {
    return state.userTimeZone;
  },
  getWebAccess: (state) => (menu: string) => {
    return SessionAccessGetterFactory.getWebAccess(menu, state.user?.commands);
  },
  getUserId(state): string {
    return state.user?.user_id as string;
  },
  getLogin(state): any {
    return state.loginUrl;
  },
  getClient(state): any {
    return state.client;
  },
  getToken(state): any {
    return state.token;
  },
  getSubKey(state): any {
    return state.subKey;
  },
  getRoverJwt(state): any {
    return state.roverJwt;
  },
  getTokenTimeStamp(state): any {
    return state.tokenTimeStamp;
  },
  getSessionStartDateStamp(state): any {
    return state.sessionStartDateStamp;
  },
  getAccess: (state) => (path: string) => {
    return SessionAccessGetterFactory.getAccess(path, state.user?.commands);
  },
  getAccessRights: (state) => (path: string) => {
    return SessionAccessGetterFactory.getAccessRights(
      path,
      state.user?.commands_rights,
      state.user?.commands,
    );
  },
};

export const mutations: MutationTree<SessionState> = {
  //to change the state
  DO_LOGIN(state, user) {
    state.user = user;
  },
  DO_LOGOUT(state) {
    state.user = null;
    state.client = null;
    state.loading = false;
  },
  SET_CLIENT_NAME(state, client) {
    state.client = client;
  },
  SET_LOGIN_URL(state, url) {
    state.loginUrl = url;
  },
  SET_TOKEN(state, token) {
    state.token = token;
  },
  SET_SUB_KEY(state, subKey) {
    state.subKey = subKey;
  },
  SET_ROVER_JWT(state, roverJwt) {
    state.roverJwt = roverJwt;
  },
  SET_TOKEN_TIMESTAMP(state, timer) {
    state.tokenTimeStamp = timer;
  },
  SET_SESSION_START_DATESTAMP(state, date) {
    state.sessionStartDateStamp = date;
  },
  SET_TOKEN_FETCH_IN_PROGRESS(state, promise) {
    state.tokenFetchInProgress = promise;
  },
  CLEAR_TOKEN_FETCH_IN_PROGRESS(state) {
    state.tokenFetchInProgress = null;
  },
  SET_USER_TIMEZONE(state, timezone) {
    state.userTimeZone = timezone;
  },
};

export const actions: ActionTree<SessionState, RootState> = {
  logout({ state, commit, dispatch }) {
    dispatch("pos/clearRegister", null, { root: true });
    commit("DO_LOGOUT");
    window.sessionStorage.removeItem("vuex");

    if (state.loginUrl) {
      location.replace(state.loginUrl.toString());
    }
  },
  setSessionStartDate({ commit }, date) {
    commit("SET_SESSION_START_DATESTAMP", date);
  },
  calculateUserTimeZone({ state, commit }) {
    const userDate = new Date().toLocaleString("en-US");
    const userHour = new Date(userDate).getHours();

    let greeting = "";
    if (userHour >= 0 && userHour < 5) {
      greeting = "Nice to see you again";
    } else if (userHour < 12) {
      greeting = "Good Morning";
    } else if (userHour < 18) {
      greeting = "Good Afternoon";
    } else {
      greeting = "Good Evening";
    }

    commit("SET_USER_TIMEZONE", greeting);
  },
  async fetchToken({ state, commit, dispatch }) {
    const now = Math.round(Date.now() / 1000);
    const timeStamp = state.tokenTimeStamp || 0;
    const expiry = (state.token?.expires_in || 0) - 5 * 60;
    if (timeStamp + expiry < now) {
      try {
        // If token fetch is already in progress, just wait for it.
        if (state.tokenFetchInProgress) {
          try {
            const response = await state.tokenFetchInProgress;
            return response.access_token;
          } catch (error) {
            throw { success: false, error: error };
          }
        }

        const fetchInProgress = tokenService.fetchToken();
        commit("SET_TOKEN_FETCH_IN_PROGRESS", fetchInProgress);

        const response = await fetchInProgress;
        commit("CLEAR_TOKEN_FETCH_IN_PROGRESS");

        if (!response.error) {
          commit("SET_TOKEN", response);
          commit("SET_TOKEN_TIMESTAMP", now);
          return response.access_token;
        } else {
          dispatch(
            "notification/addError",
            "Authentication error. Please contact your administrator",
            { root: true },
          );
        }
      } catch (error) {
        dispatch("notification/addError", `An error has occured: ${error}`, {
          root: true,
        });
        throw { success: false, error: error };
      }
    } else {
      return state.token?.access_token;
    }
  },
};

export const session: Module<SessionState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};

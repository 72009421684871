import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "grid m-0 order-tab-content p-0" }
const _hoisted_2 = { class: "col-12 justify-content-between p-0 pl-2" }
const _hoisted_3 = { class: "grid col-12 p-0 m-0" }
const _hoisted_4 = { class: "p-0 mt-1 col-12 lg:col-3 lg:pr-1 lg:pb-1" }
const _hoisted_5 = { class: "p-inputgroup" }
const _hoisted_6 = { class: "p-0 mt-1 col-12 lg:col-3 lg:p-1 lg:pt-0" }
const _hoisted_7 = { class: "p-inputgroup" }
const _hoisted_8 = { class: "p-0 mt-1 col-12 lg:col-3 lg:p-1 lg:pt-0" }
const _hoisted_9 = { class: "p-0 mt-1 col-12 lg:col-3 lg:pl-1 lg:pb-1" }
const _hoisted_10 = { class: "p-inputgroup" }
const _hoisted_11 = { class: "p-0 mt-1 col-12 md:col-6 lg:col-3 lg:pr-1 lg:pb-1" }
const _hoisted_12 = { class: "p-inputgroup" }
const _hoisted_13 = { class: "p-0 mt-1 col-12 md:col-6 lg:col-3 lg:p-1 lg:pt-0 md:pl-1" }
const _hoisted_14 = { class: "p-inputgroup" }
const _hoisted_15 = { class: "p-0 mt-1 col-6 lg:col-3 lg:p-1 lg:pt-0" }
const _hoisted_16 = { class: "p-inputgroup" }
const _hoisted_17 = { class: "col-6 grid lg:col-3 justify-content-left align-content-center m-0 p-1 lg:p-0 lg:pl-1" }
const _hoisted_18 = { class: "field-checkbox pl-2 col-6 lg:col-3 pb-0 mb-2 lg:pr-1" }
const _hoisted_19 = { class: "field-checkbox pl-2 col-6 lg:col-3 pb-0 mb-2" }
const _hoisted_20 = { class: "p-0 mt-1 col-12 lg:col-3 lg:pr-1 lg:pb-1" }
const _hoisted_21 = { class: "p-inputgroup" }
const _hoisted_22 = { class: "p-0 mt-1 col-12 lg:col-3 lg:p-1 lg:pt-0" }
const _hoisted_23 = { class: "p-inputgroup" }
const _hoisted_24 = { class: "p-0 mt-1 col-12 lg:col-3 lg:p-1 lg:pt-0" }
const _hoisted_25 = { class: "p-inputgroup" }
const _hoisted_26 = { class: "p-0 mt-1 col-12 lg:col-3 lg:pl-1 lg:pb-1" }
const _hoisted_27 = { class: "p-inputgroup" }
const _hoisted_28 = { class: "grid col-12 p-0 pl-2" }
const _hoisted_29 = { class: "grid col-12 p-0 m-0 pl-2 pt-1" }
const _hoisted_30 = { class: "p-0 mt-1 col-12 lg:col-3 lg:pr-1 lg:pb-1" }
const _hoisted_31 = { class: "p-inputgroup" }
const _hoisted_32 = { class: "field-checkbox pl-2 col-12 lg:col-3 lg:p-1 lg:pt-0 mb-0" }
const _hoisted_33 = { class: "p-0 mt-1 col-12 lg:col-3 lg:p-1 lg:pt-0" }
const _hoisted_34 = { class: "p-inputgroup" }
const _hoisted_35 = { class: "p-0 mt-1 col-12 lg:col-3 lg:pl-1 lg:pb-1" }
const _hoisted_36 = { class: "p-inputgroup" }
const _hoisted_37 = { class: "p-0 mt-1 col-12 lg:col-3 lg:pr-1 lg:pb-1" }
const _hoisted_38 = { class: "p-inputgroup" }
const _hoisted_39 = { class: "p-0 mt-1 col-12 lg:col-3 lg:p-1 lg:pt-0" }
const _hoisted_40 = { class: "p-inputgroup" }
const _hoisted_41 = { class: "p-0 mt-1 col-12 lg:col-3 lg:p-1 lg:pt-0" }
const _hoisted_42 = { class: "p-inputgroup" }
const _hoisted_43 = { class: "field-checkbox pl-3 col-6 lg:col-1 pb-0 mb-0 align-items-center" }
const _hoisted_44 = { class: "field-checkbox pl-2 lg:pl-4 col-6 lg:col-1 pb-0 mb-0 align-items-center" }
const _hoisted_45 = { class: "p-0 mt-1 col-6 lg:col-3 lg:pr-1 lg:pb-1" }
const _hoisted_46 = { class: "field-checkbox pl-2 col-6 lg:col-3 lg:p-1 lg:pt-0 mb-0 lg:ml-1" }
const _hoisted_47 = { class: "grid col-12 p-0 pl-2 m-0" }
const _hoisted_48 = { class: "grid col-12 p-0 m-0 lg:col-5" }
const _hoisted_49 = { class: "flex justify-content-center" }
const _hoisted_50 = { class: "grid p-0 m-0 pt-2 pb-2 lg:pt-0 lg:pb-0 col-12 lg:col-12 lg:pr-1 justify-content-center" }
const _hoisted_51 = { class: "col-12 p-0 m-0 lg:col-7 lg:pl-1" }
const _hoisted_52 = { class: "flex justify-content-center" }
const _hoisted_53 = { class: "grid col-12 p-0 pl-2 lg:pl-1 m-0" }
const _hoisted_54 = { class: "credit-card-content" }
const _hoisted_55 = { class: "grid col-12 lg:col-5 p-0 m-0" }
const _hoisted_56 = { class: "p-0 mt-1 col-12 lg:p-1 lg:pt-0" }
const _hoisted_57 = { class: "p-inputgroup" }
const _hoisted_58 = { class: "p-0 mt-1 col-4 pr-0 lg:p-1 lg:pt-0" }
const _hoisted_59 = { class: "p-inputgroup" }
const _hoisted_60 = { class: "p-0 mt-1 col-4 pr-0 lg:p-1 lg:pt-0 pl-1" }
const _hoisted_61 = { class: "p-inputgroup" }
const _hoisted_62 = { class: "p-0 mt-1 col-4 pr-0 lg:p-1 lg:pt-0 pl-1" }
const _hoisted_63 = { class: "p-inputgroup" }
const _hoisted_64 = { class: "p-0 mt-1 col-12 lg:p-1 lg:pt-0" }
const _hoisted_65 = { class: "p-inputgroup" }
const _hoisted_66 = { class: "grid col-12 lg:col-7 p-0 m-0" }
const _hoisted_67 = { class: "p-0 mt-1 col-12 md:col-9 lg:col-10 lg:pt-0" }
const _hoisted_68 = { class: "p-inputgroup" }
const _hoisted_69 = { class: "field-checkbox pl-2 col-12 md:col-2 lg:p-1 lg:pt-0 mb-0" }
const _hoisted_70 = { class: "p-0 mt-1 col-12 md:col-6 xl:col-3 lg:p-1 lg:pt-0" }
const _hoisted_71 = { class: "p-inputgroup" }
const _hoisted_72 = { class: "p-0 mt-1 col-12 md:col-6 xl:col-3 lg:p-1 lg:pt-0 md:pl-1" }
const _hoisted_73 = { class: "p-inputgroup" }
const _hoisted_74 = { class: "p-0 mt-1 col-12 md:col-6 xl:col-3 lg:p-1 lg:pt-0" }
const _hoisted_75 = { class: "p-inputgroup" }
const _hoisted_76 = { class: "p-0 mt-1 col-12 md:col-6 xl:col-3 lg:p-1 lg:pt-0 md:pl-1" }
const _hoisted_77 = { class: "p-inputgroup" }
const _hoisted_78 = { class: "p-0 mt-1 col-12 md:col-6 lg:col-5 lg:p-1 lg:pt-0" }
const _hoisted_79 = { class: "p-inputgroup" }
const _hoisted_80 = { class: "p-0 mt-1 col-12 md:col-6 lg:col-5 lg:p-1 lg:pt-0 md:pl-1" }
const _hoisted_81 = { class: "p-inputgroup" }
const _hoisted_82 = { class: "field-checkbox pl-2 col-2 lg:p-1 lg:pt-0 mb-0" }
const _hoisted_83 = { class: "grid col-12 p-0 m-0 lg:pl-1 pr-0 justify-content-center" }
const _hoisted_84 = { class: "grid col-12 lg:col-5 pt-0 pr-0 lg:pr-1 pb-0 mt-0 mb-0 justify-content-center" }
const _hoisted_85 = { class: "grid col-12 xl:col-8 pr-0 mr-0" }
const _hoisted_86 = { class: "p-0 mt-1 col-6 pr-1 lg:pb-1" }
const _hoisted_87 = { class: "p-0 mt-1 col-6 lg:pb-1" }
const _hoisted_88 = { class: "grid col-12 lg:col-7 p-0 m-0" }
const _hoisted_89 = { class: "grid col-12 p-0 m-0 md:pr-1" }
const _hoisted_90 = { class: "field-checkbox pl-2 col-6 md:col-3 lg:p-1 lg:pt-0 mb-0" }
const _hoisted_91 = { class: "field-checkbox pl-2 col-6 md:col-3 lg:p-1 lg:pt-0 mb-0" }
const _hoisted_92 = { class: "p-0 m-0 mt-1 col-12 md:col-6 lg:pt-0" }
const _hoisted_93 = { class: "p-inputgroup" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Divider = _resolveComponent("Divider")!
  const _component_ShipToAddressesTable = _resolveComponent("ShipToAddressesTable")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_AddressItems = _resolveComponent("AddressItems")!
  const _component_Button = _resolveComponent("Button")!
  const _component_CheckBox = _resolveComponent("CheckBox")!
  const _component_AccordionTab = _resolveComponent("AccordionTab")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_Column = _resolveComponent("Column")!
  const _component_AddTaxDialog = _resolveComponent("AddTaxDialog")!
  const _component_Row = _resolveComponent("Row")!
  const _component_ColumnGroup = _resolveComponent("ColumnGroup")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _component_AddCreditMemoDialog = _resolveComponent("AddCreditMemoDialog")!
  const _component_InputNumber = _resolveComponent("InputNumber")!
  const _component_CustomAccordion = _resolveComponent("CustomAccordion")!
  const _component_Card = _resolveComponent("Card")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Card, { class: "col-12 border-round-3xl mb-0 p-0 pl-2 tab-card-content" }, {
      content: _withCtx(() => [
        _createVNode(_component_CustomAccordion, { activeIndex: [0, 1, 2, 3] }, {
          "accordion-content": _withCtx(() => [
            _createVNode(_component_AccordionTab, { header: "Shipping Information" }, {
              header: _withCtx(() => [
                _createVNode(_component_Divider, {
                  type: "dashed",
                  class: "ml-3"
                })
              ]),
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_2, [
                  _createVNode(_component_ShipToAddressesTable, {
                    showAllButton: false,
                    order: _ctx.currentOrder,
                    shipToAddresses: _ctx.currentOrder.customer.ship_seq_items,
                    hideNewAddressButton: true,
                    onOnRowClick: _ctx.handleRowClick
                  }, null, 8, ["order", "shipToAddresses", "onOnRowClick"]),
                  _createElementVNode("div", _hoisted_3, [
                    _createElementVNode("div", _hoisted_4, [
                      _createElementVNode("div", _hoisted_5, [
                        _cache[24] || (_cache[24] = _createElementVNode("small", { class: "p-inputgroup-addon" }, "Seq", -1)),
                        _createVNode(_component_InputText, {
                          type: "text",
                          class: "text-sm",
                          modelValue: _ctx.currentOrder.ship_seq,
                          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.currentOrder.ship_seq) = $event))
                        }, null, 8, ["modelValue"])
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_6, [
                      _createElementVNode("div", _hoisted_7, [
                        _cache[25] || (_cache[25] = _createElementVNode("small", { class: "p-inputgroup-addon" }, "Name", -1)),
                        _createVNode(_component_InputText, {
                          type: "text",
                          class: "text-sm",
                          modelValue: _ctx.currentOrder.ship_name,
                          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.currentOrder.ship_name) = $event))
                        }, null, 8, ["modelValue"])
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_8, [
                      _createVNode(_component_AddressItems, {
                        inline: true,
                        address: _ctx.currentOrder.ship_address_items,
                        addressName: "ship_address",
                        onUpdateAddress: _ctx.updateShipAddress
                      }, null, 8, ["address", "onUpdateAddress"])
                    ]),
                    _createElementVNode("div", _hoisted_9, [
                      _createElementVNode("div", _hoisted_10, [
                        _cache[26] || (_cache[26] = _createElementVNode("small", { class: "p-inputgroup-addon" }, "City", -1)),
                        _createVNode(_component_InputText, {
                          type: "text",
                          class: "text-sm",
                          modelValue: _ctx.currentOrder.ship_city,
                          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.currentOrder.ship_city) = $event))
                        }, null, 8, ["modelValue"])
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_11, [
                      _createElementVNode("div", _hoisted_12, [
                        _cache[27] || (_cache[27] = _createElementVNode("small", { class: "p-inputgroup-addon" }, "State", -1)),
                        _createVNode(_component_InputText, {
                          type: "text",
                          class: "text-sm",
                          modelValue: _ctx.currentOrder.ship_state,
                          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.currentOrder.ship_state) = $event))
                        }, null, 8, ["modelValue"])
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_13, [
                      _createElementVNode("div", _hoisted_14, [
                        _cache[28] || (_cache[28] = _createElementVNode("small", { class: "p-inputgroup-addon" }, "Zip", -1)),
                        _createVNode(_component_InputText, {
                          type: "text",
                          class: "text-sm",
                          modelValue: _ctx.currentOrder.ship_zip,
                          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.currentOrder.ship_zip) = $event))
                        }, null, 8, ["modelValue"])
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_15, [
                      _createElementVNode("div", _hoisted_16, [
                        _cache[29] || (_cache[29] = _createElementVNode("small", { class: "p-inputgroup-addon" }, "Country", -1)),
                        _createVNode(_component_InputText, {
                          type: "text",
                          class: "text-sm",
                          modelValue: _ctx.currentOrder.ship_country,
                          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.currentOrder.ship_country) = $event))
                        }, null, 8, ["modelValue"])
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_17, [
                      false
                        ? (_openBlock(), _createBlock(_component_Button, {
                            key: 0,
                            label: "Validate Address",
                            class: "p-2 m-0"
                          }))
                        : _createCommentVNode("", true)
                    ]),
                    _createElementVNode("div", _hoisted_18, [
                      _createVNode(_component_CheckBox, {
                        id: "commercial",
                        name: "commercial",
                        trueValue: "Y",
                        falseValue: null,
                        binary: true,
                        class: "field-checkbox m-1 mb-0",
                        modelValue: _ctx.currentOrder.ship_comm,
                        "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.currentOrder.ship_comm) = $event))
                      }, null, 8, ["modelValue"]),
                      _cache[30] || (_cache[30] = _createElementVNode("label", {
                        for: "commercial",
                        class: "text-sm pt-2 pb-2"
                      }, "Commercial", -1))
                    ]),
                    _createElementVNode("div", _hoisted_19, [
                      _createVNode(_component_CheckBox, {
                        id: "commercial",
                        name: "commercial",
                        trueValue: "Y",
                        falseValue: null,
                        binary: true,
                        class: "field-checkbox m-1 mb-0",
                        modelValue: _ctx.currentOrder.drop_ship,
                        "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.currentOrder.drop_ship) = $event))
                      }, null, 8, ["modelValue"]),
                      _cache[31] || (_cache[31] = _createElementVNode("label", {
                        for: "commercial",
                        class: "text-sm pt-2 pb-2"
                      }, "Drop Ship", -1))
                    ]),
                    _cache[36] || (_cache[36] = _createElementVNode("div", { class: "col-0 lg:col-6" }, null, -1)),
                    _createElementVNode("div", _hoisted_20, [
                      _createElementVNode("div", _hoisted_21, [
                        _cache[32] || (_cache[32] = _createElementVNode("small", { class: "p-inputgroup-addon" }, "FOB", -1)),
                        _createVNode(_component_InputText, {
                          type: "text",
                          class: "text-sm",
                          modelValue: _ctx.currentOrder.fob,
                          "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.currentOrder.fob) = $event))
                        }, null, 8, ["modelValue"])
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_22, [
                      _createElementVNode("div", _hoisted_23, [
                        _cache[33] || (_cache[33] = _createElementVNode("small", { class: "p-inputgroup-addon" }, "Attention", -1)),
                        _createVNode(_component_InputText, {
                          type: "text",
                          class: "text-sm",
                          modelValue: _ctx.currentOrder.ship_attn,
                          "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.currentOrder.ship_attn) = $event))
                        }, null, 8, ["modelValue"])
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_24, [
                      _createElementVNode("div", _hoisted_25, [
                        _cache[34] || (_cache[34] = _createElementVNode("small", { class: "p-inputgroup-addon" }, "Email", -1)),
                        _createVNode(_component_InputText, {
                          type: "text",
                          class: "text-sm",
                          modelValue: _ctx.currentOrder.ship_email,
                          "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.currentOrder.ship_email) = $event))
                        }, null, 8, ["modelValue"])
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_26, [
                      _createElementVNode("div", _hoisted_27, [
                        _cache[35] || (_cache[35] = _createElementVNode("small", { class: "p-inputgroup-addon" }, "Phone", -1)),
                        _createVNode(_component_InputText, {
                          type: "text",
                          class: "text-sm",
                          modelValue: _ctx.currentOrder.ship_phone,
                          "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.currentOrder.ship_phone) = $event))
                        }, null, 8, ["modelValue"])
                      ])
                    ])
                  ])
                ])
              ]),
              _: 1
            }),
            _createVNode(_component_AccordionTab, { header: "Carrier Information" }, {
              header: _withCtx(() => [
                _createVNode(_component_Divider, {
                  type: "dashed",
                  class: "ml-3"
                })
              ]),
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_28, [
                  _createElementVNode("div", _hoisted_29, [
                    _createElementVNode("div", _hoisted_30, [
                      _createElementVNode("div", _hoisted_31, [
                        _cache[37] || (_cache[37] = _createElementVNode("small", { class: "p-inputgroup-addon" }, "Ship Via", -1)),
                        _createVNode(_component_Dropdown, {
                          options: _ctx.shipViaItems,
                          optionLabel: "ship_via",
                          optionValue: "ship_via",
                          modelValue: _ctx.currentOrder.ship_via,
                          "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.currentOrder.ship_via) = $event))
                        }, null, 8, ["options", "modelValue"])
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_32, [
                      _createVNode(_component_CheckBox, {
                        id: "commercial",
                        name: "commercial",
                        trueValue: "Y",
                        falseValue: null,
                        binary: true,
                        class: "field-checkbox m-1 mb-0",
                        modelValue: _ctx.currentOrder.sat_delivery,
                        "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.currentOrder.sat_delivery) = $event))
                      }, null, 8, ["modelValue"]),
                      _cache[38] || (_cache[38] = _createElementVNode("label", {
                        for: "commercial",
                        class: "text-sm pt-2 pb-2"
                      }, "Sat Delivery", -1))
                    ]),
                    _createElementVNode("div", _hoisted_33, [
                      _createElementVNode("div", _hoisted_34, [
                        _cache[39] || (_cache[39] = _createElementVNode("small", { class: "p-inputgroup-addon" }, "Shipper Acct", -1)),
                        _createVNode(_component_InputText, {
                          type: "text",
                          class: "text-sm",
                          modelValue: _ctx.currentOrder.shipper_account,
                          "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.currentOrder.shipper_account) = $event))
                        }, null, 8, ["modelValue"])
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_35, [
                      _createElementVNode("div", _hoisted_36, [
                        _cache[40] || (_cache[40] = _createElementVNode("small", { class: "p-inputgroup-addon" }, "Freight Code", -1)),
                        _createVNode(_component_Dropdown, {
                          options: _ctx.freightCodeOptions,
                          modelValue: _ctx.currentOrder.freight_code,
                          "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.currentOrder.freight_code) = $event))
                        }, null, 8, ["options", "modelValue"])
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_37, [
                      _createElementVNode("div", _hoisted_38, [
                        _cache[41] || (_cache[41] = _createElementVNode("small", { class: "p-inputgroup-addon" }, "Third Party Acct", -1)),
                        _createVNode(_component_InputText, {
                          type: "text",
                          class: "text-sm",
                          modelValue: _ctx.currentOrder.ship_frt_acct,
                          "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.currentOrder.ship_frt_acct) = $event))
                        }, null, 8, ["modelValue"])
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_39, [
                      _createElementVNode("div", _hoisted_40, [
                        _cache[42] || (_cache[42] = _createElementVNode("small", { class: "p-inputgroup-addon" }, "Freight Type", -1)),
                        _createVNode(_component_Dropdown, {
                          options: _ctx.freightTypeOptions,
                          modelValue: _ctx.currentOrder.freight_type,
                          "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.currentOrder.freight_type) = $event))
                        }, null, 8, ["options", "modelValue"])
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_41, [
                      _createElementVNode("div", _hoisted_42, [
                        _cache[43] || (_cache[43] = _createElementVNode("small", { class: "p-inputgroup-addon" }, "Freight Amount", -1)),
                        _createVNode(_component_InputText, {
                          type: "text",
                          class: "text-sm",
                          modelValue: _ctx.currentOrder.freight_amount,
                          "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.currentOrder.freight_amount) = $event))
                        }, null, 8, ["modelValue"])
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_43, [
                      _createVNode(_component_CheckBox, {
                        id: "lock",
                        name: "lock",
                        trueValue: "Y",
                        falseValue: null,
                        binary: true,
                        class: "field-checkbox m-0",
                        modelValue: _ctx.currentOrder.freight_lock,
                        "onUpdate:modelValue": _cache[19] || (_cache[19] = ($event: any) => ((_ctx.currentOrder.freight_lock) = $event))
                      }, null, 8, ["modelValue"]),
                      _cache[44] || (_cache[44] = _createElementVNode("label", {
                        for: "lock",
                        class: "text-sm pl-1"
                      }, "Lock", -1))
                    ]),
                    _createElementVNode("div", _hoisted_44, [
                      _createVNode(_component_CheckBox, {
                        id: "tax",
                        name: "tax",
                        trueValue: "Y",
                        falseValue: null,
                        binary: true,
                        class: "field-checkbox m-0",
                        modelValue: _ctx.currentOrder.tax_freight,
                        "onUpdate:modelValue": _cache[20] || (_cache[20] = ($event: any) => ((_ctx.currentOrder.tax_freight) = $event))
                      }, null, 8, ["modelValue"]),
                      _cache[45] || (_cache[45] = _createElementVNode("label", {
                        for: "tax",
                        class: "text-sm pl-1"
                      }, "Tax", -1))
                    ]),
                    _createElementVNode("div", _hoisted_45, [
                      _createVNode(_component_Button, {
                        label: "Calc Freight",
                        class: "h-2rem w-12"
                      })
                    ]),
                    _createElementVNode("div", _hoisted_46, [
                      _createVNode(_component_CheckBox, {
                        id: "first-shipment-only",
                        name: "first-shipment-only",
                        trueValue: "Y",
                        falseValue: null,
                        binary: true,
                        class: "field-checkbox m-0",
                        modelValue: _ctx.currentOrder.first_ship_only,
                        "onUpdate:modelValue": _cache[21] || (_cache[21] = ($event: any) => ((_ctx.currentOrder.first_ship_only) = $event))
                      }, null, 8, ["modelValue"]),
                      _cache[46] || (_cache[46] = _createElementVNode("label", {
                        for: "first-shipment-only",
                        class: "text-sm pl-1"
                      }, "First Shipment Only", -1))
                    ])
                  ])
                ])
              ]),
              _: 1
            }),
            _createVNode(_component_AccordionTab, { header: "Taxes Information" }, {
              header: _withCtx(() => [
                _createVNode(_component_Divider, {
                  type: "dashed",
                  class: "ml-3"
                })
              ]),
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_47, [
                  _createElementVNode("div", _hoisted_48, [
                    _createVNode(_component_DataTable, {
                      value: _ctx.currentOrder.tax_codes_items,
                      class: "p-datatable-sm w-12 text-sm font-semibold bordered-datatable",
                      paginator: "",
                      rows: 3,
                      rowsPerPageOptions: [3, 5, 10],
                      breakpoint: "800px"
                    }, {
                      empty: _withCtx(() => _cache[47] || (_cache[47] = [
                        _createTextVNode(" No records found ")
                      ])),
                      default: _withCtx(() => [
                        _createVNode(_component_Column, {
                          field: "tax_codes",
                          header: "Tax Code",
                          bodyClass: "text-center",
                          headerClass: "general-label-color"
                        }),
                        _createVNode(_component_Column, {
                          field: "tax_code_rate",
                          header: "Percent",
                          bodyClass: "text-center",
                          headerClass: "general-label-color"
                        }),
                        _createVNode(_component_Column, null, {
                          header: _withCtx(() => [
                            _createVNode(_component_AddTaxDialog, { onAddTax: _ctx.addTax }, null, 8, ["onAddTax"])
                          ]),
                          body: _withCtx((slotProps) => [
                            _createElementVNode("div", _hoisted_49, [
                              _createVNode(_component_Button, {
                                icon: "pi pi-trash",
                                title: "Delete",
                                class: "mt-2 text-sm font-bold",
                                onClick: ($event: any) => (_ctx.deleteTax(slotProps.data.code))
                              }, null, 8, ["onClick"])
                            ])
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_ColumnGroup, { type: "footer" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_Row, null, {
                              default: _withCtx(() => [
                                _createVNode(_component_Column, {
                                  footer: "",
                                  footerStyle: "text-align:right"
                                }),
                                _createVNode(_component_Column, {
                                  footer: 'Total: ' + _ctx.totalTaxPercent,
                                  footerStyle: "text-align:center"
                                }, null, 8, ["footer"]),
                                (_ctx.totalTaxAmount)
                                  ? (_openBlock(), _createBlock(_component_Column, {
                                      key: 0,
                                      footer: 'Amount: $' + _ctx.totalTaxAmount,
                                      footerStyle: "text-align:center"
                                    }, null, 8, ["footer"]))
                                  : _createCommentVNode("", true)
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }, 8, ["value"]),
                    _createElementVNode("div", _hoisted_50, [
                      _createVNode(_component_Button, {
                        label: "Get Sales Tax",
                        class: "col-6 lg:m-3 lg:ml-0 h-2rem",
                        onClick: _ctx.calcTax,
                        loading: _ctx.loadingSalesTax
                      }, null, 8, ["onClick", "loading"])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_51, [
                    _createVNode(_component_DataTable, {
                      value: _ctx.currentOrder.cm_id_items,
                      class: "p-datatable-sm w-12 text-sm font-semibold bordered-datatable",
                      paginator: "",
                      rows: 3,
                      rowsPerPageOptions: [3, 5, 10],
                      breakpoint: "800px"
                    }, {
                      header: _withCtx(() => [
                        _createVNode(_component_AddCreditMemoDialog, { onAddCreditMemo: _ctx.addCreditMemo }, null, 8, ["onAddCreditMemo"])
                      ]),
                      empty: _withCtx(() => _cache[48] || (_cache[48] = [
                        _createTextVNode(" No records found ")
                      ])),
                      default: _withCtx(() => [
                        _createVNode(_component_Column, {
                          field: "cm_id",
                          header: "Credit Memo",
                          bodyClass: "text-center",
                          headerClass: "general-label-color"
                        }),
                        _createVNode(_component_Column, {
                          field: "cm_amount",
                          header: "Amount",
                          bodyClass: "text-center",
                          headerClass: "general-label-color"
                        }),
                        _createVNode(_component_Column, {
                          header: "Apply",
                          bodyClass: "text-center",
                          headerClass: "general-label-color"
                        }, {
                          body: _withCtx((slotProps) => [
                            _createVNode(_component_CheckBox, {
                              id: "apply",
                              name: "apply",
                              modelValue: slotProps.data.apply,
                              "onUpdate:modelValue": ($event: any) => ((slotProps.data.apply) = $event),
                              trueValue: "Y",
                              falseValue: null,
                              binary: true,
                              disabled: true
                            }, null, 8, ["modelValue", "onUpdate:modelValue"])
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_Column, null, {
                          body: _withCtx((slotProps) => [
                            _createElementVNode("div", _hoisted_52, [
                              _createVNode(_component_Button, {
                                icon: "pi pi-trash",
                                title: "Delete",
                                class: "mt-2 text-sm font-bold",
                                onClick: ($event: any) => (_ctx.deleteCreditMemo(slotProps.data.id))
                              }, null, 8, ["onClick"])
                            ])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }, 8, ["value"])
                  ])
                ])
              ]),
              _: 1
            }),
            false
              ? (_openBlock(), _createBlock(_component_AccordionTab, {
                  key: 0,
                  header: "Credit Information"
                }, {
                  header: _withCtx(() => [
                    _createVNode(_component_Divider, {
                      type: "dashed",
                      class: "ml-3"
                    })
                  ]),
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_53, [
                      _createElementVNode("div", _hoisted_54, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.currentOrder.creditcard_no_items, (card) => {
                          return (_openBlock(), _createElementBlock("div", {
                            key: card.creditcard_no,
                            class: "grid col-12 p-0 m-0 credit-card-content"
                          }, [
                            _createElementVNode("div", _hoisted_55, [
                              _createElementVNode("div", _hoisted_56, [
                                _createElementVNode("div", _hoisted_57, [
                                  _cache[49] || (_cache[49] = _createElementVNode("small", { class: "p-inputgroup-addon" }, "Credit Card #", -1)),
                                  _createVNode(_component_InputText, {
                                    type: "text",
                                    class: "text-sm",
                                    modelValue: card.creditcard_no,
                                    "onUpdate:modelValue": ($event: any) => ((card.creditcard_no) = $event)
                                  }, null, 8, ["modelValue", "onUpdate:modelValue"])
                                ])
                              ]),
                              _createElementVNode("div", _hoisted_58, [
                                _createElementVNode("div", _hoisted_59, [
                                  _cache[50] || (_cache[50] = _createElementVNode("small", { class: "p-inputgroup-addon short-addon" }, "Type", -1)),
                                  _createVNode(_component_InputText, {
                                    type: "text",
                                    class: "text-sm",
                                    modelValue: card.creditcard_type,
                                    "onUpdate:modelValue": ($event: any) => ((card.creditcard_type) = $event)
                                  }, null, 8, ["modelValue", "onUpdate:modelValue"])
                                ])
                              ]),
                              _createElementVNode("div", _hoisted_60, [
                                _createElementVNode("div", _hoisted_61, [
                                  _cache[51] || (_cache[51] = _createElementVNode("small", { class: "p-inputgroup-addon short-addon" }, "Exp", -1)),
                                  _createVNode(_component_InputText, {
                                    type: "text",
                                    class: "text-sm",
                                    modelValue: card.creditcard_exp,
                                    "onUpdate:modelValue": ($event: any) => ((card.creditcard_exp) = $event)
                                  }, null, 8, ["modelValue", "onUpdate:modelValue"])
                                ])
                              ]),
                              _createElementVNode("div", _hoisted_62, [
                                _createElementVNode("div", _hoisted_63, [
                                  _cache[52] || (_cache[52] = _createElementVNode("small", { class: "p-inputgroup-addon short-addon" }, "CVV", -1)),
                                  _createVNode(_component_InputText, {
                                    type: "text",
                                    class: "text-sm",
                                    modelValue: card.creditcard_cvv,
                                    "onUpdate:modelValue": ($event: any) => ((card.creditcard_cvv) = $event)
                                  }, null, 8, ["modelValue", "onUpdate:modelValue"])
                                ])
                              ]),
                              _createElementVNode("div", _hoisted_64, [
                                _createElementVNode("div", _hoisted_65, [
                                  _cache[53] || (_cache[53] = _createElementVNode("small", { class: "p-inputgroup-addon" }, "Holder Name", -1)),
                                  _createVNode(_component_InputText, {
                                    type: "text",
                                    class: "text-sm",
                                    modelValue: card.cardholder_name,
                                    "onUpdate:modelValue": ($event: any) => ((card.cardholder_name) = $event)
                                  }, null, 8, ["modelValue", "onUpdate:modelValue"])
                                ])
                              ]),
                              _cache[54] || (_cache[54] = _createElementVNode("div", { class: "col-0 lg:col-12 xl:col-0 h-3rem xl:hidden" }, null, -1))
                            ]),
                            _createElementVNode("div", _hoisted_66, [
                              _createElementVNode("div", _hoisted_67, [
                                _createElementVNode("div", _hoisted_68, [
                                  _cache[55] || (_cache[55] = _createElementVNode("small", { class: "p-inputgroup-addon" }, "Address", -1)),
                                  _createVNode(_component_InputText, {
                                    type: "text",
                                    class: "text-sm",
                                    modelValue: card.cc_addr,
                                    "onUpdate:modelValue": ($event: any) => ((card.cc_addr) = $event)
                                  }, null, 8, ["modelValue", "onUpdate:modelValue"])
                                ])
                              ]),
                              _createElementVNode("div", _hoisted_69, [
                                _createVNode(_component_CheckBox, {
                                  id: "posted",
                                  name: "posted",
                                  trueValue: "Y",
                                  falseValue: null,
                                  binary: true,
                                  class: "field-checkbox m-1 mb-0",
                                  modelValue: card.creditcard_post,
                                  "onUpdate:modelValue": ($event: any) => ((card.creditcard_post) = $event)
                                }, null, 8, ["modelValue", "onUpdate:modelValue"]),
                                _cache[56] || (_cache[56] = _createElementVNode("label", {
                                  for: "posted",
                                  class: "text-sm pt-2 pb-2"
                                }, "Posted", -1))
                              ]),
                              _createElementVNode("div", _hoisted_70, [
                                _createElementVNode("div", _hoisted_71, [
                                  _cache[57] || (_cache[57] = _createElementVNode("small", { class: "p-inputgroup-addon" }, "City", -1)),
                                  _createVNode(_component_InputText, {
                                    type: "text",
                                    class: "text-sm",
                                    modelValue: card.cc_city,
                                    "onUpdate:modelValue": ($event: any) => ((card.cc_city) = $event)
                                  }, null, 8, ["modelValue", "onUpdate:modelValue"])
                                ])
                              ]),
                              _createElementVNode("div", _hoisted_72, [
                                _createElementVNode("div", _hoisted_73, [
                                  _cache[58] || (_cache[58] = _createElementVNode("small", { class: "p-inputgroup-addon" }, "State", -1)),
                                  _createVNode(_component_InputText, {
                                    type: "text",
                                    class: "text-sm",
                                    modelValue: card.cc_state,
                                    "onUpdate:modelValue": ($event: any) => ((card.cc_state) = $event)
                                  }, null, 8, ["modelValue", "onUpdate:modelValue"])
                                ])
                              ]),
                              _createElementVNode("div", _hoisted_74, [
                                _createElementVNode("div", _hoisted_75, [
                                  _cache[59] || (_cache[59] = _createElementVNode("small", { class: "p-inputgroup-addon" }, "Zip", -1)),
                                  _createVNode(_component_InputText, {
                                    type: "text",
                                    class: "text-sm",
                                    modelValue: card.cc_zip,
                                    "onUpdate:modelValue": ($event: any) => ((card.cc_zip) = $event)
                                  }, null, 8, ["modelValue", "onUpdate:modelValue"])
                                ])
                              ]),
                              _createElementVNode("div", _hoisted_76, [
                                _createElementVNode("div", _hoisted_77, [
                                  _cache[60] || (_cache[60] = _createElementVNode("small", { class: "p-inputgroup-addon" }, "Country", -1)),
                                  _createVNode(_component_InputText, {
                                    type: "text",
                                    class: "text-sm",
                                    modelValue: card.cc_country,
                                    "onUpdate:modelValue": ($event: any) => ((card.cc_country) = $event)
                                  }, null, 8, ["modelValue", "onUpdate:modelValue"])
                                ])
                              ]),
                              _createElementVNode("div", _hoisted_78, [
                                _createElementVNode("div", _hoisted_79, [
                                  _cache[61] || (_cache[61] = _createElementVNode("small", { class: "p-inputgroup-addon" }, "Amount", -1)),
                                  _createVNode(_component_InputNumber, {
                                    class: "text-sm",
                                    modelValue: card.creditcard_amt,
                                    "onUpdate:modelValue": ($event: any) => ((card.creditcard_amt) = $event),
                                    "min-fraction-digits": 2
                                  }, null, 8, ["modelValue", "onUpdate:modelValue"])
                                ])
                              ]),
                              _createElementVNode("div", _hoisted_80, [
                                _createElementVNode("div", _hoisted_81, [
                                  _cache[62] || (_cache[62] = _createElementVNode("small", { class: "p-inputgroup-addon" }, "Trans ID", -1)),
                                  _createVNode(_component_InputText, {
                                    type: "text",
                                    class: "text-sm",
                                    modelValue: card.creditcard_tran,
                                    "onUpdate:modelValue": ($event: any) => ((card.creditcard_tran) = $event)
                                  }, null, 8, ["modelValue", "onUpdate:modelValue"])
                                ])
                              ]),
                              _createElementVNode("div", _hoisted_82, [
                                _createVNode(_component_CheckBox, {
                                  id: "error",
                                  name: "error",
                                  trueValue: "Y",
                                  falseValue: null,
                                  binary: true,
                                  class: "field-checkbox m-1 mb-0",
                                  modelValue: card.error,
                                  "onUpdate:modelValue": ($event: any) => ((card.error) = $event)
                                }, null, 8, ["modelValue", "onUpdate:modelValue"]),
                                _cache[63] || (_cache[63] = _createElementVNode("label", {
                                  for: "error",
                                  class: "text-sm pt-2 pb-2"
                                }, "Error", -1))
                              ])
                            ]),
                            _createVNode(_component_Divider, { type: "dashed" })
                          ]))
                        }), 128))
                      ]),
                      _createElementVNode("div", _hoisted_83, [
                        _createElementVNode("div", _hoisted_84, [
                          _createElementVNode("div", _hoisted_85, [
                            _createElementVNode("div", _hoisted_86, [
                              _createVNode(_component_Button, {
                                label: "View Card",
                                class: "col-12"
                              })
                            ]),
                            _createElementVNode("div", _hoisted_87, [
                              _createVNode(_component_Button, {
                                label: "View Error",
                                class: "col-12"
                              })
                            ])
                          ])
                        ]),
                        _createElementVNode("div", _hoisted_88, [
                          _createElementVNode("div", _hoisted_89, [
                            _createElementVNode("div", _hoisted_90, [
                              _createVNode(_component_CheckBox, {
                                id: "payment-plan",
                                name: "payment-plan",
                                trueValue: "Y",
                                falseValue: null,
                                binary: true,
                                class: "field-checkbox m-1 mb-0",
                                modelValue: _ctx.currentOrder.paymentPlan,
                                "onUpdate:modelValue": _cache[22] || (_cache[22] = ($event: any) => ((_ctx.currentOrder.paymentPlan) = $event))
                              }, null, 8, ["modelValue"]),
                              _cache[64] || (_cache[64] = _createElementVNode("label", {
                                for: "payment-plan",
                                class: "text-sm pt-2 pb-2"
                              }, "Payment Plan", -1))
                            ]),
                            _createElementVNode("div", _hoisted_91, [
                              _createVNode(_component_CheckBox, {
                                id: "prepay",
                                name: "prepay",
                                trueValue: "Y",
                                falseValue: null,
                                binary: true,
                                class: "field-checkbox m-1 mb-0",
                                modelValue: _ctx.currentOrder.prepay,
                                "onUpdate:modelValue": _cache[23] || (_cache[23] = ($event: any) => ((_ctx.currentOrder.prepay) = $event))
                              }, null, 8, ["modelValue"]),
                              _cache[65] || (_cache[65] = _createElementVNode("label", {
                                for: "prepay",
                                class: "text-sm pt-2 pb-2"
                              }, "Prepay", -1))
                            ]),
                            _createElementVNode("div", _hoisted_92, [
                              _createElementVNode("div", _hoisted_93, [
                                _cache[66] || (_cache[66] = _createElementVNode("small", { class: "p-inputgroup-addon" }, "Total", -1)),
                                _createVNode(_component_InputText, {
                                  type: "text",
                                  class: "text-sm",
                                  value: _ctx.ccTotal,
                                  disabled: true
                                }, null, 8, ["value"])
                              ])
                            ])
                          ])
                        ])
                      ])
                    ])
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true)
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}
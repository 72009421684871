export default interface Notification {
  id: number;
  message: string;
  type: string;
  dialog: boolean;
  closeLink: string;
  confirmButtonLabel: string;
  closeButtonLabel: string;
  validateMetaData: any;
}

export enum NotificationType {
  ERROR = "error",
  SUCCESS = "success",
  INFO = "info",
  WARN = "warn",
  CONFIRM_VALIDATE_OVERRIDE = "confirmValidateOverride",
}

export const ADD_NOTIFICATION = "notification/add";
export const ADD_ERROR_NOTIFICATION = "notification/addError";
export const ADD_SUCCESS_NOTIFICATION = "notification/addSuccess";

import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withKeys as _withKeys, createVNode as _createVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "mt-2"
}
const _hoisted_2 = { class: "formgrid grid" }
const _hoisted_3 = { class: "col-12 p-0 m-0" }
const _hoisted_4 = { class: "p-inputgroup" }
const _hoisted_5 = {
  key: 0,
  class: "p-inputgroup-addon"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!_ctx.inline)
      ? (_openBlock(), _createElementBlock("span", _hoisted_1, _cache[0] || (_cache[0] = [
          _createElementVNode("strong", null, "Address", -1)
        ])))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.addressHeaderClass)
    }, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.address_items, (item, index) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "col-12 item",
              key: index
            }, [
              _createElementVNode("div", _hoisted_4, [
                (_ctx.inline)
                  ? (_openBlock(), _createElementBlock("small", _hoisted_5, "Address " + _toDisplayString(index + 1), 1))
                  : _createCommentVNode("", true),
                _createVNode(_component_InputText, {
                  type: "text",
                  modelValue: item[_ctx.addressName],
                  "onUpdate:modelValue": ($event: any) => ((item[_ctx.addressName]) = $event),
                  ref_for: true,
                  ref: 'addressInput-' + index,
                  class: "text-sm",
                  style: {"width":"100%"},
                  onChange: _ctx.updateAddress,
                  onKeypress: _withKeys(($event: any) => (_ctx.moveToNextLine(index)), ["enter"])
                }, null, 8, ["modelValue", "onUpdate:modelValue", "onChange", "onKeypress"]),
                _createVNode(_component_Button, {
                  title: "Add Address Line",
                  icon: "pi pi-plus",
                  class: "text-sm font-bold",
                  style: {"min-width":"1.5rem"},
                  onClick: ($event: any) => (_ctx.addAddressLine(index))
                }, null, 8, ["onClick"]),
                _createVNode(_component_Button, {
                  icon: "pi pi-trash",
                  title: "Remove Address Line",
                  class: "text-sm font-bold",
                  style: {"min-width":"1.5rem"},
                  onClick: ($event: any) => (_ctx.deleteAddressLine(index))
                }, null, 8, ["onClick"])
              ])
            ]))
          }), 128))
        ])
      ])
    ], 2)
  ], 64))
}
import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createVNode as _createVNode, withCtx as _withCtx, renderSlot as _renderSlot, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex p-0 m-0 justify-content-center" }
const _hoisted_2 = { class: "flex justify-content-between align-items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProgressSpinner = _resolveComponent("ProgressSpinner")!
  const _component_AddLogEntryDialog = _resolveComponent("AddLogEntryDialog")!
  const _component_Column = _resolveComponent("Column")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_DataTable = _resolveComponent("DataTable")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.isLoadingLogEntry)
        ? (_openBlock(), _createBlock(_component_ProgressSpinner, {
            key: 0,
            class: "h-4rem"
          }))
        : _createCommentVNode("", true)
    ]),
    (!_ctx.isLoadingLogEntry)
      ? (_openBlock(), _createBlock(_component_DataTable, {
          key: 0,
          value: _ctx.internalLogs,
          responsiveLayout: "stack",
          breakpoint: "960px",
          class: "col-12 p-0 m-0 logs-table",
          filters: _ctx.filters,
          "onUpdate:filters": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.filters) = $event)),
          filterDisplay: "menu",
          paginator: true,
          rows: 5,
          rowsPerPageOptions: [5, 10, 25],
          paginatorTemplate: "FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown",
          currentPageReportTemplate: "Showing {first} to {last} of {totalRecords} Logs"
        }, {
          header: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              _cache[1] || (_cache[1] = _createElementVNode("h4", { class: "m-0" }, null, -1)),
              _createVNode(_component_AddLogEntryDialog, {
                isSaving: _ctx.isSaving,
                onAddLog: _ctx.addLog
              }, null, 8, ["isSaving", "onAddLog"])
            ])
          ]),
          empty: _withCtx(() => _cache[2] || (_cache[2] = [
            _createElementVNode("h4", { class: "m-0 text-center font-normal" }, "No log entries to show.", -1)
          ])),
          default: _withCtx(() => [
            _createVNode(_component_Column, {
              field: "id",
              header: "ID",
              bodyClass: "text-center",
              style: {"width":"5vw"}
            }),
            _createVNode(_component_Column, {
              field: "created_by",
              header: "Created By",
              filterField: "created_by",
              bodyClass: "text-center",
              style: {"width":"9vw"},
              sortable: true
            }, {
              filter: _withCtx(({ filterModel }) => [
                _createVNode(_component_InputText, {
                  type: "text",
                  modelValue: filterModel.value,
                  "onUpdate:modelValue": ($event: any) => ((filterModel.value) = $event),
                  class: "p-column-filter",
                  placeholder: "Search by name"
                }, null, 8, ["modelValue", "onUpdate:modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_Column, {
              field: "date_created",
              header: "Date",
              filterField: "date_created",
              bodyClass: "text-center",
              style: {"width":"9vw"},
              sortable: true
            }, {
              filter: _withCtx(({ filterModel }) => [
                _createVNode(_component_InputText, {
                  type: "text",
                  modelValue: filterModel.value,
                  "onUpdate:modelValue": ($event: any) => ((filterModel.value) = $event),
                  class: "p-column-filter",
                  placeholder: "Search by date"
                }, null, 8, ["modelValue", "onUpdate:modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_Column, {
              field: "time_created",
              header: "Time",
              bodyClass: "text-center",
              style: {"width":"9vw"}
            }),
            _createVNode(_component_Column, {
              field: "type",
              header: "Type",
              filterField: "type",
              bodyClass: "text-justify",
              style: {"width":"9vw"},
              sortable: true
            }, {
              filter: _withCtx(({ filterModel }) => [
                _createVNode(_component_InputText, {
                  type: "text",
                  modelValue: filterModel.value,
                  "onUpdate:modelValue": ($event: any) => ((filterModel.value) = $event),
                  class: "p-column-filter",
                  placeholder: "Search by type"
                }, null, 8, ["modelValue", "onUpdate:modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_Column, {
              field: "subject",
              header: "Subject",
              filterField: "subject",
              bodyClass: "text-justify",
              style: {"width":"9vw"},
              sortable: true
            }, {
              filter: _withCtx(({ filterModel }) => [
                _createVNode(_component_InputText, {
                  type: "text",
                  modelValue: filterModel.value,
                  "onUpdate:modelValue": ($event: any) => ((filterModel.value) = $event),
                  class: "p-column-filter",
                  placeholder: "Search by subject"
                }, null, 8, ["modelValue", "onUpdate:modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_Column, {
              field: "details",
              header: "Details",
              bodyClass: "text-justify"
            }),
            (_ctx.showActions)
              ? (_openBlock(), _createBlock(_component_Column, {
                  key: 0,
                  header: "Actions",
                  bodyClass: "flex justify-content-center"
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("template", null, [
                      _renderSlot(_ctx.$slots, "actions")
                    ])
                  ]),
                  _: 3
                }))
              : _createCommentVNode("", true)
          ]),
          _: 3
        }, 8, ["value", "filters"]))
      : _createCommentVNode("", true)
  ], 64))
}
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "grid mr-0 justify-content-center" }
const _hoisted_2 = { class: "col-11 p-0 pt-3 pl-3" }
const _hoisted_3 = { class: "flex justify-content-between align-items-center pr-3" }
const _hoisted_4 = { class: "col-11" }
const _hoisted_5 = { class: "col-11" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_AccountsGLTable = _resolveComponent("AccountsGLTable")!
  const _component_Card = _resolveComponent("Card")!
  const _component_AccordionTab = _resolveComponent("AccordionTab")!
  const _component_Accordion = _resolveComponent("Accordion")!
  const _component_AccountActivity = _resolveComponent("AccountActivity")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _cache[0] || (_cache[0] = _createElementVNode("h2", { class: "m-0 text-800" }, "Trial Balance", -1)),
        _createVNode(_component_Button, {
          label: "New GL Entry",
          icon: "pi pi-plus",
          "icon-pos": "left",
          class: "text-sm font-semibold",
          onClick: _ctx.handleNewEntry
        }, null, 8, ["onClick"])
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_Accordion, {
        activeIndex: [0],
        multiple: "",
        class: "custom-accordion"
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Object.entries(_ctx.trialBalance), (tab) => {
            return (_openBlock(), _createBlock(_component_AccordionTab, {
              key: tab[0],
              header: tab[0]
            }, {
              default: _withCtx(() => [
                _createVNode(_component_Card, null, {
                  content: _withCtx(() => [
                    _createVNode(_component_AccountsGLTable, {
                      tableData: tab[1],
                      onAccountRowClick: _ctx.handleTrialBalanceRowClick
                    }, null, 8, ["tableData", "onAccountRowClick"])
                  ]),
                  _: 2
                }, 1024)
              ]),
              _: 2
            }, 1032, ["header"]))
          }), 128))
        ]),
        _: 1
      })
    ]),
    _cache[1] || (_cache[1] = _createElementVNode("div", { class: "col-11 p-0 pt-2 pl-3" }, [
      _createElementVNode("div", { class: "flex justify-content-between align-items-center" }, [
        _createElementVNode("h2", { class: "m-0 text-800" }, "Account Activity")
      ])
    ], -1)),
    _createElementVNode("div", _hoisted_5, [
      _createVNode(_component_Card, null, {
        content: _withCtx(() => [
          _createVNode(_component_AccountActivity, { account: _ctx.account }, null, 8, ["account"])
        ]),
        _: 1
      })
    ])
  ]))
}
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, withModifiers as _withModifiers, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "grid mr-0 mt-0 ml-0 justify-content-center" }
const _hoisted_2 = { class: "sm:col-12 md:col-12 lg:col-12 col-12 p-0" }
const _hoisted_3 = { class: "sales-tab" }
const _hoisted_4 = { class: "menutab-item" }
const _hoisted_5 = { class: "sm:col-12 md:col-12 lg:col-12 m-0 p-0" }
const _hoisted_6 = { class: "sales-tab p-0" }
const _hoisted_7 = { class: "grid align-content-center" }
const _hoisted_8 = { class: "col-12 text-center p-0 text-sm h-1rem" }
const _hoisted_9 = { class: "col-12 text-center p-0 text-sm h-1rem" }
const _hoisted_10 = {
  key: 0,
  class: "col-12 m-0 p-0"
}
const _hoisted_11 = { class: "sales-tab pt-0 mt-0" }
const _hoisted_12 = { class: "sales-tab p-0" }
const _hoisted_13 = { class: "align-self-center" }
const _hoisted_14 = {
  key: 1,
  class: "col-12 md:col-12 lg:col-12 p-0"
}
const _hoisted_15 = { class: "formgrid grid lg:flex align-items-center" }
const _hoisted_16 = { class: "menutab-item" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_Button = _resolveComponent("Button")!
  const _component_TabPanel = _resolveComponent("TabPanel")!
  const _component_TabView = _resolveComponent("TabView")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_MessageBox = _resolveComponent("MessageBox")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", null, [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_TabView, {
            "active-index": _ctx.getActiveTab,
            onTabChange: _ctx.handleTabChange,
            scrollable: "",
            class: "sales-list-tabview"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_TabPanel, null, {
                header: _withCtx(() => [
                  _createElementVNode("div", _hoisted_3, [
                    _createVNode(_component_router_link, {
                      to: '/sales/',
                      class: "line-height-4 sales-tab-item",
                      onClick: _ctx.handleTabChange
                    }, {
                      default: _withCtx(() => _cache[0] || (_cache[0] = [
                        _createElementVNode("span", { class: "align-self-center pr-3 pl-3" }, "Sales", -1)
                      ])),
                      _: 1
                    }, 8, ["onClick"])
                  ])
                ]),
                default: _withCtx(() => [
                  _createVNode(_component_TabView, {
                    scrollable: true,
                    class: "customers-menu-tabview sales-menu-tabview tabview-panels-hidden p-0 sm:col-12 md:col-12 lg:col-12 p-0 border-noround border-none ml-3",
                    "active-index": _ctx.active
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.visibleItems, (tab) => {
                        return (_openBlock(), _createBlock(_component_TabPanel, {
                          key: tab.label
                        }, {
                          header: _withCtx(() => [
                            _createElementVNode("div", _hoisted_4, [
                              _createVNode(_component_router_link, {
                                to: tab.to,
                                class: "menutab-item-link"
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_Button, {
                                    label: tab.label,
                                    class: "menutab-button"
                                  }, null, 8, ["label"])
                                ]),
                                _: 2
                              }, 1032, ["to"])
                            ])
                          ]),
                          _: 2
                        }, 1024))
                      }), 128))
                    ]),
                    _: 1
                  }, 8, ["active-index"]),
                  _createElementVNode("div", _hoisted_5, [
                    _createVNode(_component_router_view)
                  ])
                ]),
                _: 1
              }),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.salesInquiry.SalesInquiryMap, (tab, index) => {
                return (_openBlock(), _createBlock(_component_TabPanel, {
                  key: index,
                  class: "pt-0"
                }, {
                  header: _withCtx(() => [
                    _createElementVNode("div", _hoisted_6, [
                      _createVNode(_component_router_link, {
                        to: `/sales/${tab.saleType}/${
                    tab.saleType === 'orders'
                      ? tab.so_id.replace('#', '-')
                      : tab.id.replace('#', '-')
                  }`,
                        class: "line-height-4 sales-tab-item"
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_7, [
                            _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.getTabTitle(tab)), 1),
                            _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.formatTabId(tab)) + " " + _toDisplayString(_ctx.hasChanged(tab) ? "*" : ""), 1)
                          ])
                        ]),
                        _: 2
                      }, 1032, ["to"]),
                      _createVNode(_component_Button, {
                        icon: "pi pi-times",
                        class: "p-button-text p-0 m-0 pb-1",
                        onClick: _withModifiers(($event: any) => (_ctx.handleRemoveOrderTab(tab)), ["stop"])
                      }, null, 8, ["onClick"])
                    ])
                  ]),
                  default: _withCtx(() => [
                    (tab.saleType === 'orders')
                      ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                          _createVNode(_component_TabView, {
                            "active-index": _ctx.getActiveLineItemsTab(tab.so_id),
                            onTabChange: (event) => _ctx.handleLineItemTabChange(event, tab),
                            scrollable: true,
                            class: "customers-list-tabview"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_TabPanel, null, {
                                header: _withCtx(() => [
                                  _createElementVNode("div", _hoisted_11, [
                                    _createVNode(_component_router_link, {
                                      to: '/sales/orders/' + tab.so_id,
                                      class: "line-height-4 sales-tab-item"
                                    }, {
                                      default: _withCtx(() => _cache[1] || (_cache[1] = [
                                        _createElementVNode("span", { class: "align-self-center" }, "Information", -1)
                                      ])),
                                      _: 2
                                    }, 1032, ["to"])
                                  ])
                                ]),
                                _: 2
                              }, 1024),
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getLineItemsMap(tab.so_id), (item) => {
                                return (_openBlock(), _createBlock(_component_TabPanel, {
                                  key: item.lis,
                                  class: "pt-0"
                                }, {
                                  header: _withCtx(() => [
                                    _createElementVNode("div", _hoisted_12, [
                                      _createVNode(_component_router_link, {
                                        to: 
                          '/sales/orders/' +
                          tab.so_id +
                          '/line-item/' +
                          item.lis
                        ,
                                        class: "line-height-4 sales-tab-item"
                                      }, {
                                        default: _withCtx(() => [
                                          _createElementVNode("span", _hoisted_13, "Line #" + _toDisplayString(item.lis), 1)
                                        ]),
                                        _: 2
                                      }, 1032, ["to"]),
                                      _createVNode(_component_Button, {
                                        icon: "pi pi-times",
                                        class: "p-button-text p-0 m-0 pb-1",
                                        onClick: _withModifiers(($event: any) => (
                          _ctx.handleRemoveLineTab({ ...item }, tab.so_id)
                        ), ["stop"])
                                      }, null, 8, ["onClick"])
                                    ])
                                  ]),
                                  _: 2
                                }, 1024))
                              }), 128))
                            ]),
                            _: 2
                          }, 1032, ["active-index", "onTabChange"])
                        ]))
                      : _createCommentVNode("", true),
                    (
                _ctx.getActiveLineItemsTab(tab.so_id) === 0 &&
                tab.saleType === 'orders'
              )
                      ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                          _createElementVNode("div", _hoisted_15, [
                            _createElementVNode("div", {
                              class: _normalizeClass(["", {
                    'col-10 xl:col-11': _ctx.isSaveButtonVisible,
                    'xl:col-12': !_ctx.isSaveButtonVisible,
                  }])
                            }, [
                              _createVNode(_component_TabView, {
                                scrollable: true,
                                class: "customers-menu-tabview sales-menu-tabview tabview-panels-hidden p-0 sm:col-12 md:col-12 lg:col-12 p-0 border-round border-none ml-0",
                                "active-index": _ctx.getActiveInnerTab(tab.so_id),
                                onTabChange: (event) => _ctx.handleInnerTabChange(event, tab)
                              }, {
                                default: _withCtx(() => [
                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.innerTabs, (option) => {
                                    return (_openBlock(), _createBlock(_component_TabPanel, {
                                      key: option.label
                                    }, {
                                      header: _withCtx(() => [
                                        _createElementVNode("div", _hoisted_16, [
                                          _createVNode(_component_router_link, {
                                            class: "menutab-item-link",
                                            to: `${option.prefix}/${tab.so_id}/${option.suffix}`
                                          }, {
                                            default: _withCtx(() => [
                                              _createVNode(_component_Button, {
                                                label: option.label,
                                                class: "menutab-button inner-tab"
                                              }, null, 8, ["label"])
                                            ]),
                                            _: 2
                                          }, 1032, ["to"])
                                        ])
                                      ]),
                                      _: 2
                                    }, 1024))
                                  }), 128))
                                ]),
                                _: 2
                              }, 1032, ["active-index", "onTabChange"])
                            ], 2)
                          ])
                        ]))
                      : _createCommentVNode("", true),
                    _createVNode(_component_router_view, {
                      readOnly: _ctx.isReadOnly(tab),
                      tab: tab
                    }, null, 8, ["readOnly", "tab"])
                  ]),
                  _: 2
                }, 1024))
              }), 128))
            ]),
            _: 1
          }, 8, ["active-index", "onTabChange"])
        ])
      ])
    ]),
    _createVNode(_component_MessageBox, {
      showDialog: _ctx.showConfirmDeleteTab,
      icon: "WARNING",
      header: `Unsaved Changes in ${
      _ctx.tabToDelete.saleType === 'orders'
        ? 'Order'
        : _ctx.tabToDelete.saleType === 'opportunities'
          ? 'Opp'
          : 'Quote'
    } #${_ctx.tabToDelete.so_id || _ctx.tabToDelete.id}`,
      message: "Are you sure you want to close the tab without saving the changes?",
      primaryButton: "Yes",
      cancelButton: "Cancel",
      onPrimaryButtonClick: _ctx.handleCloseSaveFieldServiceTab,
      onCancelButtonClick: _ctx.handleCancelSaveFieldServiceTab
    }, null, 8, ["showDialog", "header", "onPrimaryButtonClick", "onCancelButtonClick"])
  ], 64))
}
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createBlock as _createBlock, normalizeStyle as _normalizeStyle, resolveDirective as _resolveDirective, withDirectives as _withDirectives, withCtx as _withCtx, createVNode as _createVNode } from "vue"
import _imports_0 from '@/assets/images/green-stonks.svg'
import _imports_1 from '@/assets/images/red-stonks.svg'


const _hoisted_1 = { class: "statcard-wrapper" }
const _hoisted_2 = { class: "statcard-content grid" }
const _hoisted_3 = { class: "col-6 flex flex-column justify-content-between" }
const _hoisted_4 = { class: "m-0" }
const _hoisted_5 = { class: "subtitle" }
const _hoisted_6 = { class: "day-information flex flex-column justify-content-end" }
const _hoisted_7 = { class: "m-0 card-amount" }
const _hoisted_8 = { class: "flex align-items-center" }
const _hoisted_9 = {
  key: 0,
  class: "stonks-icon",
  src: _imports_0
}
const _hoisted_10 = {
  key: 1,
  class: "stonks-icon",
  src: _imports_1
}
const _hoisted_11 = { class: "col-6 flex flex-column justify-content-between" }
const _hoisted_12 = { class: "date-wrapper flex align-items-center justify-content-end" }
const _hoisted_13 = { class: "date" }
const _hoisted_14 = { class: "graph-container justify-content-end md:justify-content-center xl:justify-content-end" }
const _hoisted_15 = ["onClick"]
const _hoisted_16 = {
  key: 0,
  class: "top-outer-circle"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Badge = _resolveComponent("Badge")!
  const _component_Card = _resolveComponent("Card")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Card, {
      class: _normalizeClass({ 'statcard-selected': _ctx.selected })
    }, {
      content: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", null, [
              _createElementVNode("h2", _hoisted_4, _toDisplayString(_ctx.title), 1),
              _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.subtitle), 1)
            ]),
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("h1", _hoisted_7, _toDisplayString(_ctx.LastFormattedAmount), 1),
              _createElementVNode("span", _hoisted_8, [
                (_ctx.lastPercentageDiff >= 0)
                  ? (_openBlock(), _createElementBlock("img", _hoisted_9))
                  : (_openBlock(), _createElementBlock("img", _hoisted_10)),
                _createElementVNode("span", {
                  class: _normalizeClass(["information", {
                    'green-text': _ctx.lastPercentageDiff >= 0,
                    'red-text': _ctx.lastPercentageDiff < 0,
                  }])
                }, _toDisplayString(_ctx.title === "Tickets"
                      ? parseFloat(
                          _ctx.formatPrice(_ctx.lastAmountDiff).replace("$", ""),
                        ).toFixed(0)
                      : _ctx.formatPrice(_ctx.lastAmountDiff)), 3)
              ]),
              _cache[0] || (_cache[0] = _createElementVNode("span", { class: "flex align-items-center" }, "vs previous month", -1)),
              _cache[1] || (_cache[1] = _createElementVNode("small", { class: "flex align-items-center" }, [
                _createElementVNode("div", { class: "top-outer-circle" }, [
                  _createElementVNode("div", { class: "top-inner-circle" })
                ]),
                _createTextVNode(" = current month ")
              ], -1))
            ])
          ]),
          _createElementVNode("div", _hoisted_11, [
            _createElementVNode("div", _hoisted_12, [
              _createElementVNode("span", _hoisted_13, _toDisplayString(_ctx.formattedDateRange), 1)
            ]),
            _createElementVNode("div", _hoisted_14, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.graphAmountArray, (month, index) => {
                return _withDirectives((_openBlock(), _createElementBlock("div", {
                  key: month.amount,
                  class: "graph-item-wrapper",
                  onClick: ($event: any) => (_ctx.$emit('row:click', { year: _ctx.currentYear, ...month }))
                }, [
                  _createElementVNode("div", {
                    class: _normalizeClass(["graph-item", {
                    'graph-last-item': index === _ctx.graphAmountArray.length - 1,
                    'green-bg': _ctx.lastPercentageDiff >= 0,
                    'red-bg': _ctx.lastPercentageDiff < 0,
                  }]),
                    style: _normalizeStyle({
                    height: `${month.percentageFromMax}%`,
                    'min-height': '3px',
                  })
                  }, [
                    (
                      month.year === _ctx.currentYear && month.month === _ctx.currentMonth
                    )
                      ? (_openBlock(), _createElementBlock("div", _hoisted_16, _cache[2] || (_cache[2] = [
                          _createElementVNode("div", { class: "top-inner-circle" }, null, -1)
                        ])))
                      : _createCommentVNode("", true),
                    (index === _ctx.graphAmountArray.length - 1)
                      ? (_openBlock(), _createBlock(_component_Badge, {
                          key: 1,
                          class: _normalizeClass(["top-badge", {
                      'green-bg': _ctx.lastPercentageDiff >= 0,
                      'red-bg': _ctx.lastPercentageDiff < 0,
                    }]),
                          value: _ctx.getBadgeValue(_ctx.lastAmountDiff)
                        }, null, 8, ["class", "value"]))
                      : _createCommentVNode("", true)
                  ], 6)
                ], 8, _hoisted_15)), [
                  [
                    _directive_tooltip,
                    _ctx.getTooltip(index),
                    void 0,
                    { left: true }
                  ]
                ])
              }), 128))
            ])
          ])
        ])
      ]),
      _: 1
    }, 8, ["class"])
  ]))
}
<template>
  <Payment
    :showAddressInput="showAddressInput"
    :getIframeUrl="getIframeUrl"
    :creditCardData="creditCards"
    @creditCardChanged="handleCreditCardChanged"
    @save-payment="save"
    @delete-item="deleteItem"
  />
</template>

<script>
import { defineComponent } from "vue";
import { mapActions, mapGetters } from "vuex";
import iframeResize from "iframe-resizer/js/iframeResizer";
import Payment from "../UI/Table/Payment.vue";
import CustomerService from "@/services/CustomerService";
const customerService = new CustomerService();

export default defineComponent({
  name: "CreditCards",
  props: {
    index: Number,
  },
  components: {
    Payment,
  },
  directives: {
    resize: {
      beforeMount: function (el, { value = {} }) {
        el.addEventListener("load", () => iframeResize(value, el));
      },
    },
  },
  mounted() {
    window.addEventListener("message", this.receiveToken);
    this.handleCreditCardData();
  },
  computed: {
    ...mapGetters({
      getCustCreditCards: "customerInquiry/getCreditCards",
      getCustomer: "customerInquiry/getCustomer",
      getClient: "session/getClient",
      getIframeUrl: "payment/getIframeUrl",
      getUser: "session/getUser",
    }),
  },
  data() {
    return {
      creditCard: {},
      deleteItemDialog: false,
      token: "",
      success: false,
      displayIframe: false,
      tokenMessage: "",
      creditCards: [],
      showAddressInput: true,
    };
  },
  methods: {
    ...mapActions({
      fetchPaymentInfo: "payment/fetchPaymentInfo",
      updateCustomer: "customerInquiry/updateCustomer",
      addNotification: "notification/add",
      setCust: "pos/setCustomer",
      updateField: "customerInquiry/updateField",
      syncCustomer: "customerInquiry/syncCustomer",
    }),
    handleCreditCardChanged(credit) {
      this.creditCard = credit;
    },
    handleCreditCardData() {
      if (this.getIframeUrl == null || this.getIframeUrl == "") {
        this.fetchPaymentInfo({
          option: "IFRAMEURL ACHFLAG CCADRFLAG",
          Client: this.getClient,
        });
      }

      if (this.getCustCreditCards(this.index) != null) {
        this.creditCards = this.getCustCreditCards(this.index);
      }
    },
    deleteItem() {
      const custId = this.getCustomer(this.index).cust_id;
      const oldCust = {
        cust_id: custId,
        credit_card_no_items: this.creditCards,
      };

      const newCreditCards = this.creditCards.filter((item) => {
        return item.credit_card_id != this.creditCard.credit_card_id;
      });
      const newCust = {
        cust_id: custId,
        credit_card_no_items: newCreditCards,
      };

      this.updateCustomer({
        custId: this.getCustomer(this.index).cust_id,
        oldCust,
        newCust,
        Client: this.getClient,
      }).then((response) => {
        this.creditCards = newCreditCards;
        this.updateField({
          field: "CREDIT",
          data: newCust.credit_card_no_items,
          index: this.index,
        });
        this.syncCustomer(this.getCustomer(this.index).cust_id);
      });
    },
    isRequiredDataFilled() {
      return (
        this.creditCard.cardholder_name &&
        this.creditCard.cc_addr &&
        this.creditCard.cc_zip
      );
    },
    save(creditCard) {
      if (!this.isRequiredDataFilled()) {
        const notification = {
          message: `Missing input data or did not validate card. Please fill in all required fields.`,
          type: "error",
        };

        this.addNotification(notification);
        return;
      }
      const oldCust = {
        cust_id: this.getCustomer(this.index).cust_id,
        credit_card_no_items: this.getCustCreditCards(this.index),
      };
      const newCust = {
        cust_id: this.getCustomer(this.index).cust_id,
        credit_card_no_items: [
          ...this.getCustCreditCards(this.index),
          { ...creditCard },
        ],
      };

      customerService
        .updateCustomer(
          this.getCustomer(this.index).cust_id,
          oldCust,
          newCust,
          this.getClient,
          this.getUser.user_id,
        )
        .then((response) => {
          if (response.response.status === "success") {
            this.creditCards = newCust.credit_card_no_items;
            const custs = { cust_items: [newCust] };
            this.setCust(custs);

            this.updateField({
              field: "CREDIT",
              data: newCust.credit_card_no_items,
              index: this.index,
            });
            this.syncCustomer(this.getCustomer(this.index).cust_id);
          } else {
            const notification = {
              message: `Could not remove payment method. ${response.response.error}.`,
              type: "error",
            };
            this.addNotification(notification);
          }
        })
        .catch((error) => {
          const notification = {
            message: `Could not add payment method.`,
            type: "error",
          };
          this.addNotification(notification);
        });
    },
  },
});
</script>

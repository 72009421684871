
import { defineComponent } from "vue";
import DataTable from "primevue/datatable";
import InputText from "primevue/inputtext";
import Button from "primevue/button";
import Column from "primevue/column";
import Dialog from "primevue/dialog";
import ConfirmChangesDialog from "@/components/ConfirmChangesDialog.vue";
import Calendar from "primevue/calendar";
import store from "@/store";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import { mapGetters, mapActions } from "vuex";
import SalesOrder from "@/types/salesorder";
import _debounce from "lodash/debounce";
import _uniq from "lodash/uniq";
import StageDialog from "@/components/Manufacturing/StageDialog.vue";
import SalesHeaderButtons from "@/components/Sales/SalesHeaderButtons.vue";
import SalesService from "@/services/SalesService";
import { FilterMatchMode, FilterService } from "primevue/api";
import Filter, { FilterConstraints } from "@/types/filter";
import Utils from "@/utility/utils";
import CustomerService from "@/services/CustomerService";
import RadioButton from "primevue/radiobutton";
import { isNull } from "lodash";

export default defineComponent({
  name: "ProductionStage",
  components: {
    DataTable,
    Button,
    InputText,
    Column,
    StageDialog,
    Calendar,
    ConfirmChangesDialog,
    SalesHeaderButtons,
    Dialog,
    RadioButton,
  },
  computed: {
    ...mapGetters({
      getUser: "session/getUser",
    }),
    getExportFileName(): string {
      const currentDate = new Date();
      const year = currentDate.getFullYear().toString();
      const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
      const day = currentDate.getDate().toString().padStart(2, "0");
      const hours = currentDate.getHours().toString().padStart(2, "0");
      const minutes = currentDate.getMinutes().toString().padStart(2, "0");
      const seconds = currentDate.getSeconds().toString().padStart(2, "0");
      const currentDateTimeString = `${year}${month}${day}_${hours}${minutes}${seconds}`;

      return this.pageTitle + "_" + currentDateTimeString;
    },
  },

  created() {
    this.getActiveTab();
    this.setProductionStageBasedValues();
    this.fetchOrdersRecords();
    let currentUserDetails = this.getUser;
    this.multiSortMeta = [];
  },
  props: {
    currentView: String,
    productionStage: {
      type: Array,
      default: () => [""],
    },
    pageTitle: String,
    commandName: String,
    dynamicColumns: {
      type: Array,
      required: true,
    },
    dialogReadOnly: {
      type: Boolean,
    },
    showSplitButton: {
      type: Boolean,
      default: false,
    },
    showDeleteButton: {
      type: Boolean,
      default: false,
    },
    showExportButton: {
      type: Boolean,
      default: true,
    },
    showNewButton: {
      type: Boolean,
      default: false,
    },
    showCopyButton: {
      type: Boolean,
      default: true,
    },
    copyButtonText: {
      type: String,
      default: "Move",
    },
    showArchiveButton: {
      type: Boolean,
      default: false,
    },
    showPrintButton: {
      type: Boolean,
      default: true,
    },

    newButtonText: String,
  },
  data() {
    return {
      items: [] as Array<SalesOrder>,
      printExportItems: [] as Array<SalesOrder>,
      bulkItems: [] as Array<SalesOrder>,
      selectedSalesID: "",
      selectedStatus: "",
      selectedCustomer: null,
      customerIndex: null,
      range: 10,
      id: "",
      dateStart: "",
      dateEnd: "",
      selectedSale: null,
      selectedRow: [] as any[],
      selectedDetailRow: [] as any[],
      selectedBulkDialogRow: [] as any[],
      order: false,
      orderRecordsBy: "",
      selectedSalesStatus: null,
      readOnly: true,
      sortOrder: 1,
      multiSortMeta: [] as any[],
      sortBy: "",
      sortField: "",
      first: 0,
      rows: 10,
      page: 1,
      totalRecords: 0,
      expandedRows: [] as any[],
      isLoadingSales: false,
      lazyLoad: true,
      maxExportRows: 2000,
      status: [
        { status: "New", initial: "N" },
        { status: "Back Order", initial: "B" },
        { status: "Closed", initial: "C" },
      ],
      statusLabel: {
        N: "New",
        B: "Back Order",
        C: "Closed",
      },

      filters: {
        SRT_FORMULA_PART_NO: {
          value: "" as any,
          matchMode: FilterMatchMode.CONTAINS,
        },
        cust_name: {
          value: "" as any,
          matchMode: FilterMatchMode.CONTAINS,
        },
        so_id: {
          value: "" as any,
          matchMode: FilterMatchMode.EQUALS,
        },
        status: {
          value: "" as any,
          matchMode: FilterMatchMode.CONTAINS,
        },
        date: {
          value: "" as any,
          matchMode: FilterMatchMode.CONTAINS,
        },
        COSWAY_PART: {
          value: "" as any,
          matchMode: FilterMatchMode.CONTAINS,
        },
        cust_code: {
          value: "" as any,
          matchMode: FilterMatchMode.CONTAINS,
        },
        PO_QTY: {
          value: "" as any,
          matchMode: FilterMatchMode.CONTAINS,
        },
        PO_NUMBER: {
          value: "" as any,
          matchMode: FilterMatchMode.CONTAINS,
        },
        PLACEHOLDER_DATE: {
          value: "" as any,
          matchMode: FilterMatchMode.CONTAINS,
        },
        FORMULA_PART_NO: {
          value: "" as any,
          matchMode: FilterMatchMode.CONTAINS,
        },
        FORMULA_PART_VIEW: {
          value: "" as any,
          matchMode: FilterMatchMode.CONTAINS,
        },
        BATCH_SIZE: {
          value: "" as any,
          matchMode: FilterMatchMode.CONTAINS,
        },
        ORIGINAL_ID: {
          value: "" as any,
          matchMode: FilterMatchMode.CONTAINS,
        },
        SOH_REQ_DATE: {
          value: [] as any[],
          matchMode: FilterMatchMode.IN,
        },
        SCHEDULE_DATE: {
          value: [] as any[],
          matchMode: FilterMatchMode.BETWEEN,
        },
        DATE_REL: {
          value: [] as any[],
          matchMode: FilterMatchMode.BETWEEN,
        },
        BULK_EXP_DATE: {
          value: [] as any[],
          matchMode: FilterMatchMode.BETWEEN,
        },
        CUST_CODE: {
          value: "" as any,
          matchMode: FilterMatchMode.CONTAINS,
        },
        CUST_PART_NO_VIEW: {
          value: "" as any,
          matchMode: FilterMatchMode.CONTAINS,
        },
        UNIT_SIZE: {
          value: "" as any,
          matchMode: FilterMatchMode.CONTAINS,
        },
        PROD_DESC_VIEW: {
          value: "" as any,
          matchMode: FilterMatchMode.CONTAINS,
        },
        FG_WO_NO: {
          value: "" as any,
          matchMode: FilterMatchMode.CONTAINS,
        },
        FORMULA_WO_NO: {
          value: [] as any,
          matchMode: FilterMatchMode.CONTAINS,
        },
        BULK_GALLONS: {
          value: "" as any,
          matchMode: FilterMatchMode.CONTAINS,
        },
        BULK_BATCH: {
          value: "" as any,
          matchMode: FilterMatchMode.CONTAINS,
        },
        SPECIAL_INSTRUCTIONS: {
          value: "" as any,
          matchMode: FilterMatchMode.CONTAINS,
        },
        APPROVED_DATE: {
          value: undefined as any,
          matchMode: FilterMatchMode.BETWEEN,
        },
        BATCH_CODE: {
          value: undefined as any,
          matchMode: FilterMatchMode.CONTAINS,
        },
        SOH_ORD_QTY: {
          value: undefined as any,
          matchMode: FilterMatchMode.CONTAINS,
        },
        ORIGINAL_ID_VIEW: {
          value: undefined as any,
          matchMode: FilterMatchMode.CONTAINS,
        },
        PROD_SCHED: {
          value: undefined as any,
          matchMode: FilterMatchMode.BETWEEN,
        },
        QTY_PROD: {
          value: undefined as any,
          matchMode: FilterMatchMode.CONTAINS,
        },
        QTY_SHIP: {
          value: undefined as any,
          matchMode: FilterMatchMode.CONTAINS,
        },
        SOH_STATUS: {
          value: undefined as any,
          matchMode: FilterMatchMode.CONTAINS,
        },
        SHIP_TO: {
          value: undefined as any,
          matchMode: FilterMatchMode.CONTAINS,
        },
        BULK_LAST_USED_DATE: {
          value: undefined as any,
          matchMode: FilterMatchMode.BETWEEN,
        },
        DATE_REQ: {
          value: undefined as any,
          matchMode: FilterMatchMode.BETWEEN,
        },
        WEB_STATUS_VIEW: {
          value: undefined as any,
          matchMode: FilterMatchMode.STARTS_WITH,
        },
        SOD_DLV_REF: {
          value: undefined as any,
          matchMode: FilterMatchMode.CONTAINS,
        },
        CUST_FORMULA_NUMBER: {
          value: undefined as any,
          matchMode: FilterMatchMode.CONTAINS,
        },
      },
      dynamicFilters: {
        WEB_STATUS_VIEW: {
          value: undefined as any,
          matchMode: FilterMatchMode.STARTS_WITH,
        },
        BULK_LAST_USED_DATE: {
          value: undefined as any,
          matchMode: FilterMatchMode.BETWEEN,
        },
        SHIP_TO: {
          value: undefined as any,
          matchMode: FilterMatchMode.CONTAINS,
        },
        SOH_STATUS: {
          value: undefined as any,
          matchMode: FilterMatchMode.CONTAINS,
        },
        QTY_SHIP: {
          value: undefined as any,
          matchMode: FilterMatchMode.CONTAINS,
        },
        QTY_PROD: {
          value: undefined as any,
          matchMode: FilterMatchMode.CONTAINS,
        },
        PROD_SCHED: {
          value: undefined as any,
          matchMode: FilterMatchMode.BETWEEN,
        },
        ORIGINAL_ID_VIEW: {
          value: undefined as any,
          matchMode: FilterMatchMode.CONTAINS,
        },
        SPECIAL_INSTRUCTIONS: {
          value: "" as any,
          matchMode: FilterMatchMode.CONTAINS,
        },
        SRT_FORMULA_PART_NO: {
          value: [] as any,
          matchMode: FilterMatchMode.STARTS_WITH,
        },
        BULK_BATCH: {
          value: [] as any,
          matchMode: FilterMatchMode.CONTAINS,
        },
        BULK_GALLONS: {
          value: [] as any,
          matchMode: FilterMatchMode.CONTAINS,
        },
        FORMULA_WO_NO: {
          value: [] as any,
          matchMode: FilterMatchMode.CONTAINS,
        },
        UNIT_SIZE: {
          value: [] as any,
          matchMode: FilterMatchMode.CONTAINS,
        },
        CUST_PART_NO_VIEW: {
          value: [] as any,
          matchMode: FilterMatchMode.CONTAINS,
        },
        SOH_REQ_DATE: {
          value: undefined as any,
          matchMode: FilterMatchMode.BETWEEN,
        },
        DATE_REL: {
          value: [] as any[],
          matchMode: FilterMatchMode.BETWEEN,
        },
        BULK_EXP_DATE: {
          value: [] as any[],
          matchMode: FilterMatchMode.BETWEEN,
        },
        BATCH_SIZE: {
          value: [] as any,
          matchMode: FilterMatchMode.CONTAINS,
        },
        COSWAY_PART: {
          value: [] as any,
          matchMode: FilterMatchMode.CONTAINS,
        },
        cust_name: {
          value: [] as any,
          matchMode: FilterMatchMode.CONTAINS,
        },
        CUST_CODE: {
          value: [] as any,
          matchMode: FilterMatchMode.CONTAINS,
        },
        PO_QTY: {
          value: [] as any,
          matchMode: FilterMatchMode.CONTAINS,
        },
        PO_NUMBER: {
          value: [] as any,
          matchMode: FilterMatchMode.CONTAINS,
        },
        PLACEHOLDER_DATE: {
          value: [] as any,
          matchMode: FilterMatchMode.CONTAINS,
        },
        FORMULA_PART_NO: {
          value: [] as any,
          matchMode: FilterMatchMode.CONTAINS,
        },
        FORMULA_PART_VIEW: {
          value: [] as any,
          matchMode: FilterMatchMode.CONTAINS,
        },
        ORIGINAL_ID: {
          value: [] as any,
          matchMode: FilterMatchMode.CONTAINS,
        },
        so_id: {
          value: [] as any,
          matchMode: FilterMatchMode.CONTAINS,
        },
        PROD_DESC_VIEW: {
          value: [] as any,
          matchMode: FilterMatchMode.CONTAINS,
        },
        FG_WO_NO: {
          value: [] as any,
          matchMode: FilterMatchMode.CONTAINS,
        },
        SCHEDULE_DATE: {
          value: undefined as any,
          matchMode: FilterMatchMode.BETWEEN,
        },
        APPROVED_DATE: {
          value: undefined as any,
          matchMode: FilterMatchMode.BETWEEN,
        },
        DATE_REQ: {
          value: undefined as any,
          matchMode: FilterMatchMode.BETWEEN,
        },
        BATCH_CODE: {
          value: undefined as any,
          matchMode: FilterMatchMode.CONTAINS,
        },
        SOH_ORD_QTY: {
          value: undefined as any,
          matchMode: FilterMatchMode.CONTAINS,
        },
        SOD_DLV_REF: {
          value: undefined as any,
          matchMode: FilterMatchMode.CONTAINS,
        },
        CUST_FORMULA_NUMBER: {
          value: undefined as any,
          matchMode: FilterMatchMode.CONTAINS,
        },
      },

      detailColumns: [
        { field: "custom_fields.LINE_NO", header: "Line #", input: true },
        { field: "custom_fields.SO_ID", header: "SO #", input: true },
        {
          field: "custom_fields.PART_NO",
          header: "Cosway Part No",
          input: true,
        },
        {
          field: "custom_fields.SOD_FORMULA_PART_NO",
          header: "Formula Part No",
          input: true,
        },
        { field: "custom_fields.ORD_QTY", header: "PO Qty", input: true },
        { field: "custom_fields.SOD_PROM_DT", header: "Req Date", input: true },
        { field: "custom_fields.SOD_SHIP_TO", header: "Ship To", input: true },
      ],
      bulkDialogColumns: [
        { field: "so_id", header: "Id", input: true },
        { field: "FORMULA_PART_VIEW", header: "Formula Part No", input: true },
        { field: "BULK_GALLONS", header: "Gal", input: true },
        { field: "PROD_DESC", header: "Product Description", input: true },
        {
          field: "PLACEHOLDER_DATE",
          header: "Last Day Used",
          input: false,
          calendar: true,
        },
        {
          field: "PLACEHOLDER_DATE",
          header: "Exp Date",
          input: false,
          calendar: true,
        },
        { field: "BULK_BATCH", header: "Batch Code", input: true },
      ],
      allExportColumns: [
        { field: "CUST_CODE", header: "Cust Acct#", input: true },
        { field: "PO_NUMBER", header: "Cust PO No", input: true },
        { field: "CUST_PART_NO_VIEW", header: "Customer Part", input: true },
        { field: "FORMULA_PART_VIEW", header: "Formula Part", input: true },
        { field: "COSWAY_PART", header: "Cosway Part", input: true },
        { field: "ORIGINAL_ID_VIEW", header: "Sales Order", input: true },
        { field: "SOH_REQ_DATE", header: "Req Date", input: true },
        { field: "QTY_SCHED", header: "Qty Sched", input: true },
        { field: "PROD_SCHED", header: "Prod Scheduled", input: true },
        { field: "SOH_ORD_QTY", header: "PO Qty", input: true },
        { field: "SOH_UNIT_PRICE", header: "Unit price", input: true },
        { field: "QTY_PROD", header: "Qty Prod", input: true },
        { field: "QTY_SHIP", header: "Qty Ship", input: true },
        { field: "SHIP_TO", header: "Ship To", input: true },
        { field: "PROD_DESC_VIEW", header: "Product Description", input: true },
        { field: "SOH_STATUS", header: "Status", input: true },

        { field: "BULK_GALLONS", header: "Bulk Gallons", input: true },
        { field: "BULK_LAST_USED_DATE", header: "Bulk Last Used", input: true },
        { field: "BULK_EXP_DATE", header: "Bulk Exp Date", input: true },
        { field: "BULK_BATCH", header: "Bulk Batch", input: true },

        { field: "BATCH_SIZE", header: "Batch Size", input: true },
        { field: "BATCH_CODE", header: "Batch Code", input: true },
        {
          field: "SPECIAL_INSTRUCTIONS",
          header: "Special Instructions",
          input: true,
        },
        { field: "UNIT_SIZE", header: "Unit Size", input: true },
        { field: "FG_WO_NO", header: "FG WO No", input: true },
        { field: "FORMULA_WO_NO", header: "Formula Wo No", input: true },
        { field: "LOCATION", header: "Location", input: true },
        { field: "DATE_REL", header: "Release Date", input: true },
        { field: "SCHEDULE_DATE", header: "Schedule Date", input: true },
        { field: "ORIG_PO_DATE", header: "Orig Po Date", input: true },
        { field: "APPROVED_DATE", header: "Aproved Date", input: true },
        { field: "WEB_STATUS_VIEW", header: "Web Status", input: true },
        { field: "SOD_DLV_REF", header: "Delv Ref", input: true },
        { field: "CUST_FORMULA_NBR", header: "Cust Formula", input: true },
      ],
      tabItems: [
        {
          label: "Original Orders",
          to: "/manufacturing/original-orders",
          command: "CW.MAN.ORIG",
          index: 0,
        },
        {
          label: "Open Order Status",
          to: "/manufacturing/orders",
          command: "CW.MAN.SALES",
          index: 1,
        },
        {
          label: "Work In Progress",
          to: "/manufacturing/WIP",
          command: "CW.MAN.WIP",
          index: 2,
        },
        {
          label: "Not Released",
          to: "/manufacturing/not-released",
          command: "CW.MAN.NR",
          index: 3,
        },
        {
          label: "Released",
          to: "/manufacturing/released",
          command: "CW.MAN.REL",
          index: 4,
        },
        {
          label: "Bulk Left Over",
          to: "/manufacturing/bulk",
          command: "CW.MAN.BULK",
          index: 5,
        },
        {
          label: "Archive",
          to: "/manufacturing/archive",
          command: "CW.MAN.ARCHIVE",
          index: 6,
        },
        {
          label: "Deleted",
          to: "/manufacturing/deleted",
          command: "CW.MAN.DELETED",
          index: 7,
        },
      ],
      selectedFilterCusts: [] as any[],
      searchedFilterCusts: [] as any[],
      selectedFilterId: "",
      selectedFilterStatus: [] as any[],
      selectedFilterDate: undefined as any,
      showSalesDialog: false,
      showSelectionCheckbox: true,
      salesOrder: null as unknown,
      isResultView: true as boolean,
      lastUpdated: Date.now(),
      filename: "",
      correls:
        "DELETED_USERID*H DELETED_DATE*H DELETED_TIME*H ORIGINAL_ID_VIEW*H SPECIAL_INSTRUCTIONS*H FORMULA_PART_VIEW*H UNIT_SIZE*H BULK_LAST_USED_DATE*H BULK_EXP_DATE*H FG_WO_NO*H FORMULA_WO_NO*H BULK_GALLONS*H BULK_BATCH*H PROD_DESC_VIEW*H cust_name*H rep_name*H cust_code*H date*H PO_NUMBER*H COSWAY_PART*H PO_QTY*H WEB_STATUS*H PLACEHOLDER*H PLACEHOLDER_DATE*H SCHEDULE_DATE*H FORMULA_PART_VIEW*H BATCH_SIZE*H ORIGINAL_ID*H FORMULA_PART_NO*H SOH_ORD_QTY*H QTY_SCHED*H SOH_REQ_DATE*H DATE_REL*H PROD_SCHED*H SOH_UNIT_PRICE*H QTY_PROD*H QTY_SHIP*H SHIP_TO*H SOH_STATUS*H SOH_PO_VALUE*H SOD_FORMULA_PART_NO*D SOD_COSWAY_PART*D SOD_PROM_DT*D SOD_SHIP_TO*D CUST_PART_NO_VIEW*H ORIG_PO_DATE*H LOCATION*H BATCH_CODE*H APPROVED_DATE*H WEB_STATUS_VIEW*H SOD_DLV_REF*H SOD_DLV_REF*D CUST_FORMULA_NBR*H CUST_FORMULA_NBR*D",
      rangeStart: 1,
      rangeEnd: 10,
      firstRow: 0,
      salesService: new SalesService(),
      custService: new CustomerService(),
      splitQty: 0,
      showSplitDialog: false,
      showBulkDialog: false,
      isBulkView: false,
      selectAll: false,
      showCopyMoveSelectionDialog: false,
      selectedCopyMoveValue: "",
      availableCopyMoveDestinations: [] as any[],
      continueCopyMove: false,
      copyMoveHeader: "",
    };
  },
  methods: {
    ...mapActions({
      addNotification: "notification/add",
      clearPrintData: "printableDatatable/clearData",
      setPrintData: "printableDatatable/setData",
      setPrintDefaultColumns: "printableDatatable/setDefaultColumns",
      setPrintAvaialbleColumns: "printableDatatable/setAvaialbleColumns",
    }),
    CheckTabRights(command: string) {
      let currentUserDetails = this.getUser;
      let commandName = command as string;

      if (
        Object.prototype.hasOwnProperty.call(
          currentUserDetails.commands_rights,
          commandName,
        )
      ) {
        let commandView = currentUserDetails.commands_rights[commandName].view;
        return commandView;
      } else {
        return false;
      }
    },
    getActiveTab() {
      const currentTab = this.findCurrentTab();
      if (currentTab && !this.CheckTabRights(currentTab.command)) {
        const accessibleTab = this.findFirstAccessibleTab();
        if (accessibleTab) {
          this.$router.push(accessibleTab.to);
        }
      }
    },

    findCurrentTab() {
      for (let item of this.tabItems) {
        if (this.$route.path.includes(item.to)) {
          return item;
        }
      }
      return null;
    },

    findFirstAccessibleTab() {
      for (let item of this.tabItems) {
        if (this.CheckTabRights(item.command)) {
          return item;
        }
      }
      return null;
    },

    openSales(edit: boolean) {
      this.showSalesDialog = true;
      this.readOnly = edit;
    },
    showExpand() {
      if (this.commandName == "CW.MAN.ORIG") {
        return true;
      } else {
        return false;
      }
    },
    dismissFilterInput() {
      const clickEvent = new MouseEvent("click", {
        bubbles: true,
        cancelable: true,
        view: window,
      });
      const parent = this.$refs["dt"] as any;
      if (parent && parent.$el) {
        parent.$el.dispatchEvent(clickEvent);
      } else if (parent instanceof HTMLElement) {
        // In case "dt" is a direct reference to a DOM element and not a Vue component
        parent.dispatchEvent(clickEvent);
      }
    },
    setProductionStageBasedValues() {
      switch (this.productionStage[0]) {
        case "":
          this.filename = "SO";
          break;
        case "O":
          this.filename = "SOH.WEB";
          break;
        case "WIP":
          this.filename = "SOH.WEB";
          break;
        case "ARCHIVE":
          this.filename = "SOH.WEB";
          break;
        case "D":
          this.filename = "SOH.WEB";
          break;
        case "NR":
          this.filename = "SOH.WEB";
          break;
        case "R":
          this.filename = "SOH.WEB";
          break;
        case "BULK":
          this.filename = "SOH.WEB";
          this.isBulkView = true;
          break;
        case "ALL":
          this.filename = "SOH.WEB";
          break;
        case '"R""NR"':
          this.filename = "SOH.WEB";
          break;

        default:
          break;
      }
    },
    getColumnStyle(align: string) {
      if (align == "right") {
        return "text-align: right; padding-right: 1rem;";
      } else {
        return "text-align: left;";
      }
    },
    getCustomersFilter() {
      return this.selectedFilterCusts.map((cust) => {
        return cust.cust_id;
      });
    },

    async fetchOrdersRecords(forPrintExport = false): Promise<any> {
      this.isLoadingSales = true;
      this.orderRecordsBy = this.sortOrder == -1 ? "DEC" : "";
      let rangeStart = this.rangeStart;
      let rangeEnd = this.rangeEnd;
      if (forPrintExport) {
        rangeStart = 1;
        rangeEnd = this.maxExportRows;
      }

      return this.salesService
        .getOrdersRecords(
          this.getCustomersFilter(),
          this.selectedSalesID,
          this.productionStage,
          rangeStart,
          rangeEnd,
          this.sortField,
          this.orderRecordsBy,
          this.dateStart,
          this.dateEnd,
          this.correls,
          this.filename,
          this.dynamicFilters,
        )
        .then((response: any) => {
          const so_items = response.so_items;
          for (let i = 0; i < so_items.length; i++) {
            so_items[i].date =
              so_items[i].date != null
                ? (new Date(so_items[i].date) as unknown as string)
                : "";
          }
          if (forPrintExport) {
            this.printExportItems = so_items;
          } else {
            this.items = so_items;
          }
          for (const key in this.dynamicFilters) {
            if (Object.prototype.hasOwnProperty.call(this.filters, key)) {
              if ((this.dynamicFilters as any)[key].value != "") {
                if (
                  (this.dynamicFilters as any)[key].matchMode ==
                  FilterMatchMode.BETWEEN
                ) {
                  this.selectedFilterDate = (this.dynamicFilters as any)[
                    key
                  ].value;
                  if (this.selectedFilterDate) {
                    let dateRangeStart = "";
                    let dateRangeEnd = "";
                    if (this.selectedFilterDate[0] != null)
                      dateRangeStart = this.selectedFilterDate[0];
                    if (this.selectedFilterDate[1] != null)
                      dateRangeEnd = this.selectedFilterDate[1];

                    dateRangeStart = this.formatStringDate(dateRangeStart);
                    dateRangeEnd = this.formatStringDate(dateRangeEnd);

                    if (!(this.filters as any)[key].value) {
                      (this.filters as any)[key].value = [];
                    }
                  }
                } else {
                  (this.filters as any)[key].value = (
                    this.dynamicFilters as any
                  )[key].value;
                }
              } else {
                (this.filters as any)[key].value = "";
              }
            }
          }
          this.totalRecords = response.totalRecords;
          this.isLoadingSales = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    fetchBulkRecords(PartNo: string) {
      let bulkFilters = {
        FORMULA_PART_VIEW: {
          value: PartNo as any,
          matchMode: FilterMatchMode.CONTAINS,
        },
      };
      return this.salesService
        .getOrdersRecords(
          this.getCustomersFilter(),
          this.selectedSalesID,
          ["BULK"],
          this.rangeStart,
          this.rangeEnd,
          this.sortField,
          this.sortOrder.toString(),
          this.dateStart,
          this.dateEnd,
          this.correls,
          "SOH.WEB",
          bulkFilters,
        )
        .then((response: any) => {
          const bulk_items = response.so_items;
          for (let i = 0; i < bulk_items.length; i++) {
            // for proper datatable sort by date, date strings are converted to date objects
            bulk_items[i].date =
              bulk_items[i].date != null
                ? (new Date(bulk_items[i].date) as unknown as string)
                : "";
          }
          this.bulkItems = bulk_items;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    formatCurrency: (value: string) => {
      return parseInt(value, 10).toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      });
    },
    formatStringDate(dueDate: string) {
      if (!dueDate || dueDate == null) return "";
      const reformatedDueDate = Utils.formatDate(dueDate);

      return reformatedDueDate;
    },
    clearStatusSearchBar() {
      this.selectedFilterStatus = [];
      this.fetchOrdersRecords();
    },

    getReadOnly() {
      // return "write" from user commands_rights for active command
      let currentUserDetails = this.getUser;
      let commandName = this.commandName as string;
      if (
        Object.prototype.hasOwnProperty.call(
          currentUserDetails.commands_rights,
          commandName,
        )
      ) {
        let commandWrite =
          currentUserDetails.commands_rights[commandName].change;
        return !commandWrite;
      } else {
        return true;
      }
    },
    getRights(rightName: string, buttonName: string) {
      let currentUserDetails = this.getUser;
      let commandName = this.commandName as string;

      if (
        Object.prototype.hasOwnProperty.call(
          currentUserDetails.commands_rights,
          commandName,
        )
      ) {
        let allowRight =
          currentUserDetails.commands_rights[commandName][rightName];
        switch (buttonName) {
          case "orignalOrdersCopyButton":
            return allowRight;
          case "copyButton":
            if (this.showCopyButton) {
              return allowRight;
            } else {
              return false;
            }

          case "deleteButton":
            if (this.showDeleteButton) {
              return allowRight;
            } else {
              return false;
            }

          case "addButton":
            if (this.showNewButton) {
              return allowRight;
            } else {
              return false;
            }
          case "splitButton":
            if (this.showSplitButton) {
              return allowRight;
            } else {
              return false;
            }
          case "archiveButton":
            if (this.showArchiveButton) {
              return allowRight;
            } else {
              return false;
            }
        }
        return allowRight;
      } else {
        return false;
      }
    },

    clearCustomerSearch() {
      this.selectedFilterCusts = [];
      this.fetchOrdersRecords();
    },
    clearHeaderFilter() {
      this.dynamicFilters.SRT_FORMULA_PART_NO.value = [];
      // also clear main filters
      this.clearAllFilters();
      this.fetchOrdersRecords();
    },
    clearAllFilters() {
      for (const key in this.dynamicFilters) {
        if (Object.prototype.hasOwnProperty.call(this.filters, key)) {
          if ((this.dynamicFilters as any)[key].value != "") {
            (this.filters as any)[key].value = "";
            (this.dynamicFilters as any)[key].value = "";
          }
        }
      }
      // reset to default sort
      this.sortField = "";
      this.sortBy = this.sortField;
      this.sortOrder = 1;
      this.multiSortMeta = [];
      this.selectAll = false;
      this.selectedRow = [];
      this.fetchOrdersRecords();
    },
    setFirstRow(row: any) {
      this.firstRow = row;
    },
    handleFilter(event: any) {
      this.selectedRow = [];
      this.fetchOrdersRecords();
      return false;
    },

    handleTextFilter(event: any) {
      return false;
    },

    showRow(event: any) {
      this.salesOrder = event;
      this.openSales(true);
      this.id = event.so_id;
    },
    rowClick(event: any) {
      // pop detail except for Orignal Orders
      if (!this.showExpand()) {
        if (
          !event.originalEvent.srcElement.classList.contains(
            "p-checkbox-icon",
          ) &&
          !event.originalEvent.srcElement.classList.contains(
            "p-selection-column",
          ) &&
          !event.originalEvent.srcElement.classList.contains(
            "p-row-toggler-icon",
          ) &&
          event?.originalEvent?.srcElement?.className == ""
        ) {
          let itemToEdit = event;
          if (event.data) {
            itemToEdit = event.data;
          }
          this.readOnly = this.dialogReadOnly;
          this.showRow(itemToEdit);
        }
      }
    },
    pageClick(event: any) {
      if (this.lazyLoad == true) {
        this.setFirstRow(event.first);
        this.rangeStart = event.first + 1;
        this.rangeEnd = event.first + event.rows;
        this.fetchOrdersRecords();
      }
    },
    handleCustomerSelectFilter: _debounce(async function (event) {
      // @ts-expect-error becuas of exploit this
      const customers = await this.custService.getAllCustomers({
        selection: event.value,
      });
      // @ts-expect-error becuas of exploit this
      this.searchedFilterCusts = _uniq([
        ...customers.cust_items,
        // @ts-expect-error becuas of exploit this
        ...this.selectedFilterCusts,
      ]);
    }, 1000),

    isResultViewChange(event: boolean) {
      this.isResultView = event;
    },

    sortData(event: any) {
      if (event.multiSortMeta.length > 0) {
        this.sortField = event.multiSortMeta[0].field;
      } else {
        this.sortField = "";
      }
      this.sortBy = this.sortField;
      this.order = this.order !== true;
      if (this.order == true) {
        this.sortOrder = 1;
        //blank string will cause api to sort by ascending order
        this.orderRecordsBy = "";
      } else {
        this.orderRecordsBy = "DEC";
        this.sortOrder = -1;
      }
      this.fetchOrdersRecords();
    },

    checkCriteriaPopulatedWhenRequired(): boolean {
      // require a filter for Original Orders, Open Order Status, and Released, otherwise return true
      if (
        this.productionStage[0] == "WIP" ||
        this.productionStage[0] == "NR" ||
        this.productionStage[0] == "BULK"
      ) {
        return true;
      }
      for (const key in this.dynamicFilters) {
        if (Object.prototype.hasOwnProperty.call(this.dynamicFilters, key)) {
          const filterValue = (this.dynamicFilters as any)[key].value;
          // Check if the value is not undefined and not an empty array
          if (
            filterValue !== undefined &&
            filterValue !== "" &&
            !(Array.isArray(filterValue) && filterValue.length === 0)
          ) {
            return true;
          }
        }
      }
      return false;
    },

    clearDynamicSearch(columnName: string) {
      if (
        Object.prototype.hasOwnProperty.call(this.dynamicFilters, columnName)
      ) {
        // Need to clear differently for between search
        if (
          (this.dynamicFilters as any)[columnName].matchMode ==
          FilterMatchMode.BETWEEN
        ) {
          (this.dynamicFilters as any)[columnName].value = undefined;
          if (Object.prototype.hasOwnProperty.call(this.filters, columnName)) {
            (this.filters as any)[columnName].value = undefined;
          }
        } else {
          (this.dynamicFilters as any)[columnName].value = [];
        }
      }
    },
    refreshAfterSave() {
      setTimeout(() => {
        this.fetchOrdersRecords();
      }, 500);
    },

    addNew() {
      // add a new item with a default value
      let newOrder = {} as SalesOrder;
      newOrder.custom_fields = {};
      newOrder.custom_fields.WEB_ACTION = "NEW";
      newOrder.custom_fields.WEB_STATUS = this.productionStage[0];
      this.showRow(newOrder);
    },
    setAvaialableCopyMoveDestinations(currentStage: any) {
      this.availableCopyMoveDestinations = [];
      switch (currentStage) {
        case "D":
          this.selectedCopyMoveValue = "O";
          this.copyMoveHeader = "Confirm Copy Destination";
          this.availableCopyMoveDestinations.push({
            label: "Open Order Status",
            value: "O",
          });
          this.availableCopyMoveDestinations.push({
            label: "Work In Progress",
            value: "WIP",
          });
          this.availableCopyMoveDestinations.push({
            label: "Released",
            value: "R",
          });
          this.availableCopyMoveDestinations.push({
            label: "Not Released",
            value: "NR",
          });
          break;
        case "ARCHIVE":
          this.copyMoveHeader = "Confirm Move Destination";
          this.selectedCopyMoveValue = "O";
          this.availableCopyMoveDestinations.push({
            label: "Open Order Status",
            value: "O",
          });
          this.availableCopyMoveDestinations.push({
            label: "Work In Progress",
            value: "WIP",
          });
          this.availableCopyMoveDestinations.push({
            label: "Released",
            value: "R",
          });
          this.availableCopyMoveDestinations.push({
            label: "Not Released",
            value: "NR",
          });
          break;
        case "O":
          this.copyMoveHeader = "Confirm Copy Destination";
          this.selectedCopyMoveValue = "WIP";
          this.availableCopyMoveDestinations.push({
            label: "WIP",
            value: "WIP",
          });
          this.availableCopyMoveDestinations.push({
            label: "Released",
            value: "R",
          });
          this.availableCopyMoveDestinations.push({
            label: "Not Released",
            value: "NR",
          });
          break;
        case "WIP":
          this.copyMoveHeader = "Confirm Move Destination";
          this.selectedCopyMoveValue = "NR";
          this.availableCopyMoveDestinations.push({
            label: "Not Released",
            value: "NR",
          });
          this.availableCopyMoveDestinations.push({
            label: "Released",
            value: "R",
          });
          break;
        case "R":
          this.copyMoveHeader = "Confirm Move Destination";
          this.selectedCopyMoveValue = "NR";
          this.availableCopyMoveDestinations.push({
            label: "Not Released",
            value: "NR",
          });
          this.availableCopyMoveDestinations.push({
            label: "WIP",
            value: "WIP",
          });
          break;
        case "NR":
          this.copyMoveHeader = "Confirm Move Destination";
          this.selectedCopyMoveValue = "R";
          this.availableCopyMoveDestinations.push({
            label: "Released",
            value: "R",
          });
          this.availableCopyMoveDestinations.push({
            label: "WIP",
            value: "WIP",
          });
          break;
        default:
          break;
      }
    },
    async copySelectedItems() {
      if (this.selectedRow && this.selectedRow.length > 0) {
        // prompt for a destination
        this.setAvaialableCopyMoveDestinations(this.productionStage[0]);
        this.continueCopyMove = false;
        await this.handleCopyMoveDialog();

        if (!this.continueCopyMove) {
          return;
        }

        for (const element of this.selectedRow) {
          // set the destinaton stage
          element.custom_fields.DESTINATION_STAGE = this.selectedCopyMoveValue;
          element.custom_fields.WEB_ACTION = "COPY";
          // copy these items into the SOH.WEB file
          // if this is a copy of an "O" item, we need to prompt for bulk
          if (element.custom_fields.WEB_STATUS == "O") {
            // Check for bulk product
            this.bulkItems = [];
            await this.fetchBulkRecords(element.FORMULA_PART_VIEW);

            if (this.bulkItems.length > 0) {
              for (let item of this.bulkItems) {
                if (!item.custom_fields) {
                  item.custom_fields = {};
                }

                item.custom_fields.ASSOCIATED_SO_ID = element.ORIGINAL_ID;
              }

              await this.handleBulkDialog();
            }
          }

          this.salesService.postOrder(element, "SOH.WEB").catch((error) => {
            console.log(error);
          });
        }
        this.selectedRow = [];
        const notification = {
          message: `Records Copied`,
          type: "success",
        };
        store.dispatch("notification/add", notification);
        setTimeout(() => {
          this.fetchOrdersRecords();
        }, 500);
      } else {
        const notification = {
          message: `Select items to ` + this.copyButtonText + `.`,
          type: "error",
        };
        store.dispatch("notification/add", notification);
      }
    },

    copySelectedDetailItems() {
      if (this.selectedDetailRow) {
        for (const element of this.selectedDetailRow) {
          // copy these items into the SOH.WEB file
          let copyOrder = element as unknown as SalesOrder;

          copyOrder.custom_fields.WEB_ACTION = "COPYLINE";
          this.salesService.postOrder(copyOrder, "SOH.WEB").catch((error) => {
            console.log(error);
          });
        }
        this.selectedDetailRow = [];
        const notification = {
          message: `Records Copied`,
          type: "success",
        };
        store.dispatch("notification/add", notification);
      }
    },

    async archiveSelectedItems() {
      if (this.selectedRow) {
        this.selectedRow.forEach((element: { so_id: any }) => {
          let deleteOrder = element as unknown as SalesOrder;
          deleteOrder.custom_fields.WEB_ACTION = "ARCHIVE";
          this.salesService.postOrder(element, "SOH.WEB").catch((error) => {
            console.log(error);
          });
        });
        this.selectedRow = [];
        const notification = {
          message: `Records Archived`,
          type: "success",
        };
        store.dispatch("notification/add", notification);
        setTimeout(() => {
          this.fetchOrdersRecords();
        }, 2000);
      }
    },
    async deleteSelectedItems() {
      console.log("delete", this.selectedRow);
      if (this.selectedRow && this.selectedRow.length > 0) {
        const res: boolean = await (this.$refs["confirmChanges"] as any).open();

        if (res) {
          let currentUserDetails = this.getUser;
          this.selectedRow.forEach((element: { so_id: any }) => {
            let deleteOrder = element as unknown as SalesOrder;
            deleteOrder.custom_fields.WEB_ACTION = "DELETE";
            deleteOrder.custom_fields.DELETE_USER = currentUserDetails.user_id;
            this.salesService.postOrder(element, "SOH.WEB").catch((error) => {
              console.log(error);
            });
          });
          this.selectedRow = [];
          const notification = {
            message: `Records Deleted`,
            type: "success",
          };
          store.dispatch("notification/add", notification);
          setTimeout(() => {
            this.fetchOrdersRecords();
          }, 2000);
        }
      } else {
        const notification = {
          message: `Select items to delete.`,
          type: "error",
        };
        store.dispatch("notification/add", notification);
      }
    },
    selectAllChange(event: any) {
      this.selectAll = event.checked;
      if (this.selectAll) {
        this.selectedRow = this.items;
      } else {
        this.selectedRow = [];
      }
    },

    onRowSelect(event: any) {
      this.selectAll =
        this.selectedRow.length === this.totalRecords ? true : false;
    },
    onRowUnselect(event: any) {
      this.selectAll = false;
    },
    splitSelectedItems() {
      if (this.selectedRow && this.selectedRow.length > 0) {
        // allow a single row to be split
        if (this.selectedRow.length > 1) {
          const notification = {
            message: `Select a single row to split`,
            type: "warning",
          };
          store.dispatch("notification/add", notification);
        } else {
          // display a dialog to prompt for split count
          this.showSplitDialog = true;
        }
      } else {
        const notification = {
          message: `Select items to split.`,
          type: "error",
        };
        store.dispatch("notification/add", notification);
      }
    },
    submitSplitQty() {
      let splitOrder = this.selectedRow[0] as unknown as SalesOrder;
      splitOrder.custom_fields.WEB_ACTION = "SPLIT";
      splitOrder.custom_fields.SPLIT_QTY = this.splitQty;

      this.salesService.postOrder(splitOrder, "SOH.WEB").then((response) => {
        const notification = {
          message: `Row Split`,
          type: "success",
        };
        store.dispatch("notification/add", notification);
        this.fetchOrdersRecords();
      });
      this.showSplitDialog = false;
    },
    async handleBulkDialog() {
      return new Promise((resolve, reject) => {
        // Set the dialog to be shown
        this.showBulkDialog = true;

        // Listen for dialog to be closed
        const unwatch = this.$watch(
          "showBulkDialog",
          (newValue: boolean, oldValue: boolean) => {
            if (!newValue) {
              resolve(true);
              unwatch();
            }
          },
        );
      });
    },

    async handleCopyMoveDialog() {
      return new Promise((resolve, reject) => {
        // Set the dialog to be shown
        this.showCopyMoveSelectionDialog = true;

        // Listen for dialog to be closed
        const unwatch = this.$watch(
          "showCopyMoveSelectionDialog",
          (newValue: boolean, oldValue: boolean) => {
            if (!newValue) {
              resolve(true);
              unwatch();
            }
          },
        );
      });
    },

    copyBulkItem() {
      if (this.selectedBulkDialogRow) {
        let counter = 0;
        this.selectedBulkDialogRow.forEach((element: { so_id: any }) => {
          counter += 1;
          let bulkItem = element as unknown as SalesOrder;
          bulkItem.custom_fields.WEB_ACTION = "RELIEVEBULK";
          bulkItem.custom_fields.BULK_COUNTER = counter;
          this.salesService.postOrder(element, "SOH.WEB").catch((error) => {
            console.log(error);
          });
        });
        const notification = {
          message: `Bulk Applied`,
          type: "success",
        };
        this.fetchOrdersRecords();
      }
      this.showBulkDialog = false;
    },

    async printTable() {
      //block a print with no criteria applied
      if (this.totalRecords === 0) {
        const notification = {
          message: `No records to print or export.`,
          type: "error",
        };
        this.addNotification(notification);
        return;
      }
      if (
        !this.checkCriteriaPopulatedWhenRequired() ||
        this.totalRecords > this.maxExportRows
      ) {
        const notification = {
          message: `Please filter the results before printing.`,
          type: "error",
        };
        this.addNotification(notification);
      } else {
        this.clearPrintData();
        // get full dataset for the current criteria
        await this.fetchOrdersRecords(true);
        this.setPrintData(this.printExportItems);
        this.setPrintDefaultColumns(this.dynamicColumns);
        this.setPrintAvaialbleColumns(this.allExportColumns);

        window.open("/printable-view?print=1&showSelection=1", "_blank");
      }
    },
  },
});

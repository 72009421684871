import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "flex justify-content-right" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RoverInput = _resolveComponent("RoverInput")!
  const _component_Column = _resolveComponent("Column")!
  const _component_Button = _resolveComponent("Button")!
  const _component_DataTable = _resolveComponent("DataTable")!

  return (_openBlock(), _createBlock(_component_DataTable, {
    value: _ctx.input,
    paginator: true,
    rows: 10,
    rowsPerPageOptions: [10, 25, 50],
    class: "p-datatable-sm w-12 text-sm bordered-datatable"
  }, {
    empty: _withCtx(() => _cache[0] || (_cache[0] = [
      _createElementVNode("div", { class: "flex justify-content-center" }, [
        _createElementVNode("span", { class: "text-center" }, "No Results Found")
      ], -1)
    ])),
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.fields, (column) => {
        return (_openBlock(), _createBlock(_component_Column, {
          key: column.dict_name,
          field: column.dict_name,
          header: column.display_name,
          "body-style": "padding: 4px; margin: 4px;"
        }, {
          body: _withCtx(({ data }) => [
            _createVNode(_component_RoverInput, {
              field: column,
              disabled: !_ctx.editable,
              modelValue: data[column.json_name],
              "onUpdate:modelValue": ($event: any) => ((data[column.json_name]) = $event),
              "input-style": "width: 100%"
            }, null, 8, ["field", "disabled", "modelValue", "onUpdate:modelValue"])
          ]),
          _: 2
        }, 1032, ["field", "header"]))
      }), 128)),
      (_ctx.editable)
        ? (_openBlock(), _createBlock(_component_Column, {
            key: 0,
            "body-style": "padding: 0px; margin: 0px;",
            class: "button-column"
          }, {
            body: _withCtx(({ index }) => [
              _createElementVNode("div", _hoisted_1, [
                _createVNode(_component_Button, {
                  class: "mr-1",
                  icon: "pi pi-save",
                  disabled: !_ctx.compareObject(index),
                  onClick: _ctx.saveData
                }, null, 8, ["disabled", "onClick"]),
                _createVNode(_component_Button, {
                  class: "mr-1",
                  icon: "pi pi-plus",
                  onClick: ($event: any) => (_ctx.addRow(index))
                }, null, 8, ["onClick"]),
                _createVNode(_component_Button, {
                  class: "mr-1",
                  icon: "pi pi-trash",
                  onClick: ($event: any) => (_ctx.deleteRow(index))
                }, null, 8, ["onClick"])
              ])
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["value"]))
}
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "grid m-0 order-tab-content p-0" }
const _hoisted_2 = { class: "flex p-0 my-1" }
const _hoisted_3 = {
  key: 0,
  class: "p-error"
}
const _hoisted_4 = { class: "mt-2" }
const _hoisted_5 = { class: "vertical-align-middle ml-2 font-bold line-height-3" }
const _hoisted_6 = { key: 1 }
const _hoisted_7 = {
  key: 0,
  class: "flex flex-column"
}
const _hoisted_8 = {
  for: "inputCombo",
  class: "m-1"
}
const _hoisted_9 = {
  key: 0,
  class: "p-error m-0 p-0"
}
const _hoisted_10 = {
  key: 1,
  class: "flex flex-column"
}
const _hoisted_11 = {
  for: "inputSwitch",
  class: "m-1"
}
const _hoisted_12 = {
  key: 0,
  class: "p-error m-0 p-0"
}
const _hoisted_13 = {
  key: 2,
  class: "flex flex-column"
}
const _hoisted_14 = {
  for: "inputNumber",
  class: "m-1"
}
const _hoisted_15 = {
  key: 0,
  class: "p-error m-0 p-0"
}
const _hoisted_16 = {
  key: 3,
  class: "flex flex-column"
}
const _hoisted_17 = {
  for: "inputText",
  class: "m-1"
}
const _hoisted_18 = {
  key: 0,
  class: "p-error m-0 p-0"
}
const _hoisted_19 = {
  key: 0,
  class: "flex justify-content-center p-0 mt-3"
}
const _hoisted_20 = { class: "flex p-0 mt-3" }
const _hoisted_21 = { class: "flex p-0 mt-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_Button = _resolveComponent("Button")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Column = _resolveComponent("Column")!
  const _component_InputSwitch = _resolveComponent("InputSwitch")!
  const _component_InputNumber = _resolveComponent("InputNumber")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _component_Textarea = _resolveComponent("Textarea")!
  const _component_Signature = _resolveComponent("Signature")!
  const _component_Card = _resolveComponent("Card")!
  const _component_Dialog = _resolveComponent("Dialog")!
  const _component_AddOpperationDialog = _resolveComponent("AddOpperationDialog")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Card, { class: "col-12 border-round-3xl pl-2" }, {
      content: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_Dropdown, {
            modelValue: _ctx.fieldService.order_type,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.fieldService.order_type) = $event)),
            options: _ctx.reportTypes,
            optionLabel: "report_type_name",
            optionValue: "report_type_code",
            placeholder: "Select Report Type",
            style: {"min-width":"10rem","margin-right":"2px"},
            required: true
          }, null, 8, ["modelValue", "options"]),
          _createVNode(_component_Button, {
            label: "Add Operations by Type",
            onClick: _ctx.addOperations
          }, null, 8, ["onClick"])
        ]),
        _createElementVNode("div", null, [
          (!_ctx.fieldService.order_type)
            ? (_openBlock(), _createElementBlock("p", _hoisted_3, " Report type is required "))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_DataTable, {
            value: _ctx.fieldService.oper_li_items,
            dataKey: "oper_li",
            groupRowsBy: "web_group",
            rowGroupMode: "subheader",
            class: "p-datatable-sm",
            selectionMode: "none"
          }, {
            empty: _withCtx(() => _cache[5] || (_cache[5] = [
              _createTextVNode(" No records found ")
            ])),
            groupheader: _withCtx((slotProps) => [
              _createElementVNode("span", _hoisted_5, _toDisplayString(slotProps.data.web_group), 1)
            ]),
            default: _withCtx(() => [
              (_ctx.allowEditOperations)
                ? (_openBlock(), _createBlock(_component_Column, {
                    key: 0,
                    field: "oper_li",
                    header: "Line Item",
                    style: {"max-width":"3rem"}
                  }, {
                    body: _withCtx(({ data }) => [
                      _createVNode(_component_InputText, {
                        type: "text",
                        class: "text-sm",
                        modelValue: data.oper_li,
                        "onUpdate:modelValue": ($event: any) => ((data.oper_li) = $event),
                        style: {"max-width":"5rem"}
                      }, null, 8, ["modelValue", "onUpdate:modelValue"])
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              _createVNode(_component_Column, {
                field: "operation",
                header: "Operation"
              }, {
                body: _withCtx(({ data }) => [
                  (_ctx.allowEditOperations)
                    ? (_openBlock(), _createBlock(_component_Dropdown, {
                        key: 0,
                        type: "text",
                        class: "text-sm",
                        modelValue: data.operation,
                        "onUpdate:modelValue": ($event: any) => ((data.operation) = $event),
                        options: _ctx.operations,
                        optionLabel: "operation_description",
                        optionValue: "operation",
                        onChange: _ctx.onOperationChange,
                        style: {"width":"100%"}
                      }, null, 8, ["modelValue", "onUpdate:modelValue", "options", "onChange"]))
                    : (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(_ctx.operationDesc(data.operation)), 1))
                ]),
                _: 1
              }),
              _createVNode(_component_Column, {
                field: "notes",
                header: "Values"
              }, {
                body: _withCtx(({ data }) => [
                  (_ctx.getOperationData(data.operation).input_type === 'Combo')
                    ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                        _createVNode(_component_Dropdown, {
                          inputId: "inputCombo",
                          type: "text",
                          class: "text-sm",
                          modelValue: data.oper_notes,
                          "onUpdate:modelValue": ($event: any) => ((data.oper_notes) = $event),
                          options: 
                      _ctx.getOperationData(data.operation).input_values_items
                    ,
                          optionLabel: "input_values",
                          optionValue: "input_values",
                          style: {"width":"100%"}
                        }, null, 8, ["modelValue", "onUpdate:modelValue", "options"]),
                        _createElementVNode("label", _hoisted_8, _toDisplayString(_ctx.getOperationData(data.operation).oper_label), 1),
                        _createElementVNode("div", null, [
                          (
                        !data.oper_notes &&
                        _ctx.getOperationData(data.operation).oper_required === 'Y'
                      )
                            ? (_openBlock(), _createElementBlock("p", _hoisted_9, " Operation required "))
                            : _createCommentVNode("", true)
                        ])
                      ]))
                    : (
                    _ctx.getOperationData(data.operation).input_type === 'CHECKBOX'
                  )
                      ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                          _createVNode(_component_InputSwitch, {
                            inputId: "inputSwitch",
                            type: "text",
                            class: "text-sm",
                            modelValue: data.oper_notes,
                            "onUpdate:modelValue": ($event: any) => ((data.oper_notes) = $event),
                            "aria-label": data.oper_label,
                            trueValue: "Y",
                            falseValue: "N",
                            style: {"margin-left":"0.5rem","margin-top":"0.25rem"}
                          }, null, 8, ["modelValue", "onUpdate:modelValue", "aria-label"]),
                          _createElementVNode("label", _hoisted_11, _toDisplayString(_ctx.getOperationData(data.operation).oper_label), 1),
                          _createElementVNode("div", null, [
                            (
                        !data.oper_notes &&
                        _ctx.getOperationData(data.operation).oper_required === 'Y'
                      )
                              ? (_openBlock(), _createElementBlock("p", _hoisted_12, " Operation required "))
                              : _createCommentVNode("", true)
                          ])
                        ]))
                      : (
                    _ctx.getOperationData(data.operation).input_type === 'NUM'
                  )
                        ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                            _createVNode(_component_InputNumber, {
                              inputId: "inputNumber",
                              class: "text-sm",
                              modelValue: data.oper_notes,
                              "onUpdate:modelValue": ($event: any) => ((data.oper_notes) = $event),
                              style: {"width":"100%"},
                              maxFractionDigits: "4",
                              onFocus: _cache[1] || (_cache[1] = ($event: any) => ($event.target?.select()))
                            }, null, 8, ["modelValue", "onUpdate:modelValue"]),
                            _createElementVNode("label", _hoisted_14, _toDisplayString(_ctx.getOperationData(data.operation).oper_label), 1),
                            _createElementVNode("div", null, [
                              (
                        !data.oper_notes &&
                        _ctx.getOperationData(data.operation).oper_required === 'Y'
                      )
                                ? (_openBlock(), _createElementBlock("p", _hoisted_15, " Operation required "))
                                : _createCommentVNode("", true)
                            ])
                          ]))
                        : (_openBlock(), _createElementBlock("div", _hoisted_16, [
                            _createVNode(_component_InputText, {
                              inputId: "inputText",
                              type: "text",
                              class: "text-sm",
                              modelValue: data.oper_notes,
                              "onUpdate:modelValue": ($event: any) => ((data.oper_notes) = $event),
                              style: {"width":"100%"}
                            }, null, 8, ["modelValue", "onUpdate:modelValue"]),
                            _createElementVNode("label", _hoisted_17, _toDisplayString(_ctx.getOperationData(data.operation).oper_label), 1),
                            _createElementVNode("div", null, [
                              (
                        !data.oper_notes &&
                        _ctx.getOperationData(data.operation).oper_required === 'Y'
                      )
                                ? (_openBlock(), _createElementBlock("p", _hoisted_18, " Operation required "))
                                : _createCommentVNode("", true)
                            ])
                          ]))
                ]),
                _: 1
              }),
              _createVNode(_component_Column, { style: {"max-width":"2rem"} }, {
                body: _withCtx(({ data }) => [
                  _createTextVNode(_toDisplayString(_ctx.getOperationData(data.operation).uom_label), 1)
                ]),
                _: 1
              }),
              (_ctx.allowEditOperations)
                ? (_openBlock(), _createBlock(_component_Column, {
                    key: 1,
                    style: {"width":"4rem"}
                  }, {
                    body: _withCtx(({ data }) => [
                      _createVNode(_component_Button, {
                        icon: "pi pi-trash",
                        class: "single-field w-full",
                        "aria-label": "Remove",
                        style: {"font-size":"1.5rem"},
                        onClick: ($event: any) => (_ctx.removeOperation(data))
                      }, null, 8, ["onClick"])
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["value"])
        ]),
        (_ctx.allowEditOperations)
          ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
              _createVNode(_component_Button, {
                icon: "pi pi-plus",
                class: "p-button-rounded p-button-success p-button-outlined",
                label: "Add Operation",
                onClick: _ctx.addOperation
              }, null, 8, ["onClick"])
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_20, [
          _createVNode(_component_Textarea, {
            modelValue: _ctx.notes,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.notes) = $event)),
            "aria-label": "Notes",
            rows: "8",
            placeholder: "Notes"
          }, null, 8, ["modelValue"])
        ]),
        _createElementVNode("div", _hoisted_21, [
          (_ctx.isNewFieldService)
            ? (_openBlock(), _createBlock(_component_Button, {
                key: 0,
                label: _ctx.signatureButtonLabel,
                onClick: _cache[3] || (_cache[3] = () => _ctx.showSignatureDialog())
              }, null, 8, ["label"]))
            : (_openBlock(), _createBlock(_component_Signature, {
                key: 1,
                signature: _ctx.fieldService.signature,
                receivedBy: _ctx.fieldService.signature_by,
                signatureType: _ctx.fieldService.signature_type,
                receivedDate: _ctx.fieldService.signature_date,
                receivedDateTime: _ctx.fieldService.signature_time,
                readOnly: true
              }, null, 8, ["signature", "receivedBy", "signatureType", "receivedDate", "receivedDateTime"]))
        ])
      ]),
      _: 1
    }),
    _createVNode(_component_Dialog, {
      visible: _ctx.visibleSignatureDialog,
      "onUpdate:visible": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.visibleSignatureDialog) = $event)),
      modal: "",
      header: "Signature",
      style: { width: '80vw' }
    }, {
      default: _withCtx(() => [
        _createVNode(_component_Signature, {
          signature: _ctx.fieldService.signature,
          receivedBy: _ctx.fieldService.signature_by,
          signatureType: _ctx.fieldService.signature_type,
          receivedDate: _ctx.fieldService.signature_date,
          receivedDateTime: _ctx.fieldService.signature_time,
          onSave: _ctx.saveSignature
        }, null, 8, ["signature", "receivedBy", "signatureType", "receivedDate", "receivedDateTime", "onSave"])
      ]),
      _: 1
    }, 8, ["visible"]),
    _createVNode(_component_AddOpperationDialog, {
      show: _ctx.showAddOperationDialog,
      onAddOperation: _ctx.handleAddOperation
    }, null, 8, ["show", "onAddOperation"])
  ]))
}
import axios from "axios";
import store from "../store";
import qs from "qs";
import Utils from "@/utility/utils";
import { ADD_NOTIFICATION, NotificationType } from "@/types/notification";
import { FETCH_TOKEN, GET_ROVER_JWT, GET_SUB_KEY } from "@/types/state/session";

const API_URL = process.env.VUE_APP_ABSTRACTION_API;
const SUBSCRIPTION_KEY_HEADER_NAME = "Ocp-Apim-Subscription-Key";
const JWT_HEADER_NAME = "X-Rover-Jwt";

export const DEFAULT_CLIENT_SUCCESS_HANDLER = (resp: any) => {
  ClientAPI.displayNotification(
    Utils.getNotificationType(resp) || NotificationType.WARN,
    Utils.parseErrorMessage(resp),
    Utils.getDisplayFormat(resp),
    Utils.getCloseLink(resp),
  );
};

export const DEFAULT_CLIENT_FAILURE_HANDLER = (error: any) => {
  ClientAPI.displayNotification(
    Utils.getNotificationType(error) || NotificationType.ERROR,
    Utils.parseErrorMessage(error),
    Utils.getDisplayFormat(error),
    Utils.getCloseLink(error),
  );
};

export default class ClientAPI {
  static client = axios.create({
    baseURL: API_URL,
    withCredentials: false,
  });

  public static async Get(
    endpoint: string,
    params: any,
    successHandler?: (resp: any) => void,
    failureHandler?: (error: any) => void,
  ) {
    try {
      const resp = await this.client.get(endpoint, {
        params,
        paramsSerializer: (params) => {
          return qs.stringify(params);
        },
        headers: await this.getDefaultHeaders(),
      });

      successHandler
        ? successHandler(resp)
        : DEFAULT_CLIENT_SUCCESS_HANDLER(resp);
      return resp.data;
    } catch (error) {
      failureHandler
        ? failureHandler(error)
        : DEFAULT_CLIENT_FAILURE_HANDLER(error);
      throw error;
    }
  }

  public static async Put(
    endpoint: string,
    payload: any,
    params?: any,
    successHandler?: (resp: any) => void,
    failureHandler?: (error: any) => void,
  ) {
    try {
      const resp = await this.client.put(endpoint, payload, {
        params,
        headers: await this.getDefaultHeaders(),
      });

      successHandler
        ? successHandler(resp)
        : DEFAULT_CLIENT_SUCCESS_HANDLER(resp);
      return resp.data;
    } catch (error) {
      failureHandler
        ? failureHandler(error)
        : DEFAULT_CLIENT_FAILURE_HANDLER(error);
      throw error;
    }
  }

  public static async Post(
    endpoint: string,
    payload: any,
    params?: any,
    successHandler?: (resp: any) => void,
    failureHandler?: (error: any) => void,
  ) {
    try {
      const resp = await this.client.post(endpoint, payload, {
        params,
        headers: await this.getDefaultHeaders(),
      });

      successHandler
        ? successHandler(resp)
        : DEFAULT_CLIENT_SUCCESS_HANDLER(resp);
      return resp.data;
    } catch (error) {
      failureHandler
        ? failureHandler(error)
        : DEFAULT_CLIENT_FAILURE_HANDLER(error);
      throw error;
    }
  }

  public static displayErrorNotification(message?: string | string[]) {
    this.displayNotification(NotificationType.ERROR, message);
  }
  public static displaySuccessNotification(message?: string | string[]) {
    this.displayNotification(NotificationType.SUCCESS, message);
  }

  public static displayNotification(
    type: NotificationType,
    message?: string | string[],
    dialog?: boolean,
    closeLink?: string,
  ) {
    if (Array.isArray(message)) {
      for (let i = 0; i < message.length; i++) {
        store.dispatch(ADD_NOTIFICATION, {
          message: message[i],
          type,
          dialog,
          closeLink: i == 0 ? closeLink : null,
        });
      }
    }
    else if (message) {
      store.dispatch(ADD_NOTIFICATION, {
        message,
        type,
        dialog,
      });
    }
  }

  private static async getDefaultHeaders() {
    return {
      [SUBSCRIPTION_KEY_HEADER_NAME]: store.getters[GET_SUB_KEY],
      [JWT_HEADER_NAME]: store.getters[GET_ROVER_JWT],
      Authorization: await this.fetchToken(),
    };
  }

  private static async fetchToken() {
    return `Bearer ${await store.dispatch(FETCH_TOKEN)}`;
  }
}

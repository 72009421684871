import { PiniaLocalStorageKeys } from "@/utility/PiniaLocalStorageKeys";
import { useInventoryStore } from "../store/ui/inventoryModule";
import { map } from "lodash";

export function piniaHandleLogout() {
  const inventoryStore = useInventoryStore();

  inventoryStore.unloadStore();

  map(PiniaLocalStorageKeys, (key) => localStorage.removeItem(key));
}


import { defineComponent } from "vue";
import { mapGetters } from "vuex";
import { mapActions } from "pinia";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Button from "primevue/button";
import Transaction from "@/types/transaction";

import { useInventoryStore } from "@/store/ui/inventoryModule";
import { AccessRights } from "@/types/accessControl/AbstractAccessGetter";

export default defineComponent({
  props: {
    partNumber: String,
  },
  components: {
    DataTable,
    Column,
    Button,
  },
  emits: ["add-transaction"],
  computed: {
    ...mapGetters({
      getClient: "session/getClient",
      getAccessRights: "session/getAccessRights",
    }),
    allowAddTransactions(): boolean {
      return (this.getAccessRights("/inventory/transactions") as AccessRights)
        .add;
    },
  },
  created() {
    this.fetchTransactionData();
  },

  methods: {
    ...mapActions(useInventoryStore, ["fetchTransactionsById"]),
    async fetchTransactionData(refresh = false) {
      if (!this.partNumber) return;

      this.isLoadingTransactions = true;

      const resp = await this.fetchTransactionsById({
        id: this.partNumber,
        client: this.getClient,
        refresh,
      });

      if (resp.success && resp.transactions) {
        this.transactionInfo = resp.transactions.map((item: any) => {
          return {
            ...item,
            date: new Date(item.date_stamp),
          };
        });
      }

      this.isLoadingTransactions = false;
    },
  },
  watch: {
    partNumber: {
      handler: "fetchTransactionData",
      immediate: true,
    },
  },
  data() {
    return {
      transactionInfo: [] as Array<Transaction>,
      isLoadingTransactions: false,
      sortField: "date",
      sortOrder: -1,
      rowsPerPage: 10,
    };
  },
});

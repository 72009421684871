import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "grid p-0 m-0" }
const _hoisted_2 = {
  key: 0,
  class: "part-number blue-text block col-12 p-0 m-0"
}
const _hoisted_3 = {
  key: 1,
  class: "part-number blue-text block emphasized"
}
const _hoisted_4 = { class: "part-manufactured-description block col-12 p-0 m-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Image = _resolveComponent("Image")!
  const _component_Column = _resolveComponent("Column")!
  const _component_DataTable = _resolveComponent("DataTable")!

  return (_openBlock(), _createBlock(_component_DataTable, {
    ref: "partsTable",
    loading: _ctx.isLoading,
    value: _ctx.parts,
    paginator: true,
    filterDisplay: "menu",
    filters: _ctx.filters,
    "onUpdate:filters": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.filters) = $event)),
    first: _ctx.first,
    "onUpdate:first": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.first) = $event)),
    rows: _ctx.rowsPerPage,
    rowsPerPageOptions: [10, 25, 50],
    sortField: _ctx.sortBy,
    sortOrder: _ctx.sortOrder,
    selectionMode: "single",
    onPage: _ctx.onPage,
    onSort: _ctx.sortData,
    onFilter: _ctx.handleFilter,
    paginatorTemplate: "FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown",
    currentPageReportTemplate: "Showing {first} to {last} of {totalRecords} Items",
    breakpoint: "700px",
    "total-records": _ctx.totalRecords,
    lazy: true,
    responsiveLayout: "stack",
    dataKey: "part_no",
    onRowClick: _ctx.handleRowClick,
    class: "p-datatable-sm w-12 text-sm bordered-datatable"
  }, {
    empty: _withCtx(() => _cache[3] || (_cache[3] = [
      _createTextVNode("No record found")
    ])),
    default: _withCtx(() => [
      _createVNode(_component_Column, {
        class: "product-image-column",
        "header-style": "width: 4rem"
      }, {
        body: _withCtx((slotProps) => [
          (slotProps.data.image)
            ? (_openBlock(), _createBlock(_component_Image, {
                key: 0,
                class: "product-image",
                src: slotProps.data.image,
                alt: slotProps.data.desc,
                preview: "",
                style: {"max-width":"4rem"},
                onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop"]))
              }, null, 8, ["src", "alt"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }),
      _createVNode(_component_Column, {
        field: "part_no",
        header: "Part #",
        headerClass: "column-header-left",
        class: "text-base",
        bodyClass: "font-normal text-left",
        showFilterMatchModes: false,
        showAddButton: false,
        showFilterOperator: false,
        sortable: ""
      }, {
        body: _withCtx((slotProps) => [
          _createElementVNode("div", _hoisted_1, [
            (slotProps.data.wrap_desc)
              ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(slotProps.data.wrap_desc), 1))
              : (_openBlock(), _createElementBlock("span", _hoisted_3, "No description available")),
            _createElementVNode("span", _hoisted_4, " Part #" + _toDisplayString(slotProps.data.part_no), 1)
          ])
        ]),
        _: 1
      }),
      _createVNode(_component_Column, {
        header: "Location",
        headerClass: "font-bold text-base column-header-left",
        class: "text-base",
        showFilterMatchModes: false,
        showFilterOperator: false,
        "body-class": "font-normal text-left"
      }, {
        body: _withCtx(({ data }) => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(data.plan_group_items, (plan) => {
            return (_openBlock(), _createElementBlock("div", {
              key: plan.plan_group
            }, _toDisplayString(plan.invloc), 1))
          }), 128))
        ]),
        _: 1
      }),
      _createVNode(_component_Column, {
        field: "on_hand",
        header: "On Hand",
        headerClass: "font-bold text-base column-header-right",
        class: "text-base",
        sortable: "",
        showFilterMatchModes: false,
        showFilterOperator: false,
        "body-class": "font-normal text-right"
      }),
      _createVNode(_component_Column, {
        field: "commit_qty",
        header: "Committed",
        headerClass: "font-bold text-base column-header-right",
        class: "text-base",
        sortable: "",
        showFilterMatchModes: false,
        showFilterOperator: false,
        "body-class": "font-normal text-right"
      }),
      _createVNode(_component_Column, {
        field: "alloc_qty",
        header: "Allocated",
        headerClass: "font-bold text-base column-header-right",
        class: "text-base",
        sortable: "",
        showFilterMatchModes: false,
        showFilterOperator: false,
        "body-class": "font-normal text-right"
      }),
      _createVNode(_component_Column, {
        field: "qty_short",
        header: "Shortages",
        headerClass: "font-bold text-base column-header-right",
        class: "text-base",
        sortable: "",
        showFilterMatchModes: false,
        showFilterOperator: false,
        "body-class": "font-normal text-right"
      }),
      _createVNode(_component_Column, {
        field: "avail_qty",
        header: "Available",
        headerClass: "font-bold text-base column-header-right",
        class: "text-base",
        sortable: "",
        showFilterMatchModes: false,
        showFilterOperator: false,
        "body-class": "font-normal text-right"
      }),
      _createVNode(_component_Column, {
        field: "on_order",
        header: "On Order",
        headerClass: "font-bold text-base column-header-right",
        class: "text-base",
        sortable: "",
        showFilterMatchModes: false,
        showFilterOperator: false,
        "body-class": "font-normal text-right"
      })
    ]),
    _: 1
  }, 8, ["loading", "value", "filters", "first", "rows", "sortField", "sortOrder", "onPage", "onSort", "onFilter", "total-records", "onRowClick"]))
}
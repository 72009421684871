import { Module, MutationTree, ActionTree, GetterTree } from "vuex";
import { RootState } from "@/types/state";

const namespaced = true;

export interface AccountingOrdersState {
  mainActiveTab: number;
}

export const state: AccountingOrdersState = {
  mainActiveTab: 0,
};

export const getters: GetterTree<AccountingOrdersState, RootState> = {
  getMainActiveTab: (state) => {
    return state.mainActiveTab;
  },
};

export const mutations: MutationTree<AccountingOrdersState> = {
  SET_MAIN_ACTIVE_TAB(state, index) {
    state.mainActiveTab = index;
  },
};

export const actions: ActionTree<AccountingOrdersState, RootState> = {
  changeMainActiveTab({ commit }, tabIndex: number) {
    commit("SET_MAIN_ACTIVE_TAB", tabIndex);
  },
};

export const accountingOrders: Module<AccountingOrdersState, RootState> = {
  namespaced,
  state,
  getters,
  mutations,
  actions,
};

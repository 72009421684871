<template>
  <div>
    <Card>
      <template #content>
        <AttachmentRecords
          hostFile="CUST"
          :record="customer"
          :customerArrayIndex="index"
          :showMail="true"
        />
      </template>
    </Card>
  </div>
</template>

<script>
import Card from "primevue/card";
import { defineComponent } from "vue";
import { mapGetters } from "vuex";

import AttachmentRecords from "@/components/Attachments/AttachmentRecords.vue";

export default defineComponent({
  name: "Attachments",
  components: {
    AttachmentRecords,
    Card,
  },
  props: {
    index: Number,
  },
  computed: {
    ...mapGetters({
      getCustomer: "customerInquiry/getCustomer",
    }),
    customer() {
      return this.getCustomer(this.index);
    },
  },
});
</script>

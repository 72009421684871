import store from "@/store";
import { useInventoryStore } from "@/store/ui/inventoryModule";
import {
  isReceiptsObject,
  ReceiptsObject,
} from "@/types/state/inventoryModule";

const inventoryRoutes = {
  path: "/inventory",
  name: "Inventory Main",
  redirect: getRedirectPath,
  component: () => import("../views/Inventory.vue"),
  children: [
    {
      name: "Purchase Orders",
      path: "/inventory/purchase-orders",
      component: () => import("../components/Inventory/PurchaseOrders.vue"),
      props: true,
    },
    {
      name: "Parts View",
      path: "/inventory/parts",
      component: (): Promise<unknown> =>
        import("@/components/Inventory/Parts.vue"),
      props: true,
    },
    {
      name: "Parts By Id",
      path: "/inventory/parts/:partsId",
      component: (): Promise<unknown> =>
        import("@/components/Inventory/PartsForm.vue"),
      props: true,
      beforeEnter: async (to: any, from: any, next: any) => {
        const id = to.params.partsId as string;
        const store = useInventoryStore();
        store.loadInventoryMap();

        const resp = await store.ensurePartExists(id);

        if (resp.success) {
          if (resp.id) {
            store.setActiveTabById(resp.id);
          }
          next();
        } else {
          store.setActiveTab(0);
          next("/inventory/parts");
        }
      },
    },
    {
      name: "New Parts",
      path: "/inventory/parts/new/:guid",
      component: (): Promise<unknown> =>
        import("@/components/Inventory/PartsForm.vue"),
      props: true,
      beforeEnter: async (to: any, from: any, next: any) => {
        const id = to.params.guid as string;
        const store = useInventoryStore();
        store.loadInventoryMap();

        const resp = store.inventoryMap.find((map) => map.id === id);

        if (resp) {
          store.setActiveTabById(id);
          next();
        } else {
          store.setActiveTab(0);
          next("/inventory/parts");
        }
      },
    },
    {
      name: "Receipts By ID",
      path: "/inventory/receipts/:receiptsId",
      component: (): Promise<unknown> =>
        import("@/components/Inventory/Receipts.vue"),
      props: true,
      beforeEnter: async (to: any, from: any, next: any) => {
        const id = to.params.receiptsId as string;
        const store = useInventoryStore();
        store.loadInventoryMap();

        const receipts = store.inventoryMap.filter(
          isReceiptsObject,
        ) as ReceiptsObject[];

        const receipt = receipts.find(
          (receipt) => receipt.record.receipts_id === id,
        );

        if (receipt) {
          store.setActiveTabById(receipt.id);
          next();
        } else {
          store.setActiveTab(0);
          next("/inventory/purchase-orders");
        }
      },
    },
    {
      name: "New Receipts",
      path: "/inventory/receipts/new/:guid",
      component: (): Promise<unknown> =>
        import("@/components/Inventory/Receipts.vue"),
      props: true,
      beforeEnter: async (to: any, from: any, next: any) => {
        const id = to.params.guid as string;
        const store = useInventoryStore();
        store.loadInventoryMap();

        const resp = store.inventoryMap.find((map) => map.id === id);

        if (resp) {
          store.setActiveTabById(id);
          next();
        } else {
          store.setActiveTab(0);
          next("/inventory/purchase-orders");
        }
      },
    },
  ],
  meta: {
    requiresAuth: true,
    title: "Inventory",
  },
};

function getRedirectPath() {
  const redirectPaths = ["/inventory/parts", "/inventory/purchase-orders"];

  return (
    redirectPaths.find((p) => store.getters["session/getAccess"](p)) ?? "/404"
  );
}

export default inventoryRoutes;

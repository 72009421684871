import AccountsPayable, { LiItems } from "@/types/accountsPayable";
import { GeneralLedgerEntry } from "./generalLedger";
import SalesOrder from "../salesorder";

export default interface AccountingInquiryState {
  AccountingInquiryMap: Array<AccountingObject>;
  activeTab: number;
  FiscalYears: Array<FiscalYear>;
  Accounts: Array<Account>;
}

export interface FiscalYear {
  fiscal_year: string;
  period_number_items: Array<PeriodNumberItem>;
}

export interface PeriodNumberItem {
  period_numbers: string;
  period_names: string;
  period_starts: string;
  period_ends: string;
  quarters: string;
}

export interface Account {
  acct: string;
  desc: string;
  type: string;
  bal_sheet: string;
  inactive: string;
}

export class GenericAccountingObject<
  T extends AccountsPayable | GeneralLedgerEntry | SalesOrder,
> {
  constructor(
    public old_record: T,
    public record: T,
    public accountType:
      | "accounts-payable"
      | "accounts-receivable"
      | "gl"
      | "orders",
    public lineItemsMap: Array<LiItems>,
    public lineItemsActiveTab: number,
    public activeInnerTab: number,
  ) {}
}

export class AccountingObject extends GenericAccountingObject<
  AccountsPayable | GeneralLedgerEntry | SalesOrder
> {
  getRecordId(): string {
    return this.record.getId();
  }

  getHumanReadableType(): string {
    switch (this.accountType) {
      case "accounts-payable":
        return "Payable";
      case "accounts-receivable":
        return "Receivable";
      case "gl":
        return "GL";
      case "orders":
        return "Order";
    }
  }

  get isObject(): boolean {
    // really don't like this but I need to know if the object has been initialized since loading from draft or edit does not automatically set the object with the defined methods
    return true;
  }

  static hydrateRecord(
    record: AccountsPayable | GeneralLedgerEntry | SalesOrder,
    accountType: "accounts-payable" | "accounts-receivable" | "gl" | "orders",
  ): AccountsPayable | GeneralLedgerEntry | SalesOrder {
    if (record.isObject) {
      return record;
    }

    let hydratedRecord;
    switch (accountType) {
      case "accounts-payable":
        hydratedRecord = new AccountsPayable();
        break;
      case "accounts-receivable":
        hydratedRecord = new AccountsPayable();
        break;
      case "gl":
        hydratedRecord = new GeneralLedgerEntry();
        break;
      case "orders":
        hydratedRecord = new SalesOrder();
        break;
    }
    Object.assign(hydratedRecord, record);
    return hydratedRecord;
  }

  static hydrate(object?: AccountingObject) {
    if (!object) {
      return null;
    }
    if (object.isObject && object.record.isObject) {
      return object;
    }

    const {
      old_record,
      record,
      accountType,
      lineItemsMap,
      lineItemsActiveTab,
      activeInnerTab,
    } = object;

    const hydratedRecord = this.hydrateRecord(record, accountType);

    return new AccountingObject(
      old_record,
      hydratedRecord,
      accountType,
      lineItemsMap,
      lineItemsActiveTab,
      activeInnerTab,
    );
  }
}


import { defineComponent } from "vue";
import { mapActions, mapGetters } from "vuex";
import Customer from "@/types/customer";
import OrderSummaryItemWrapper from "@/components/Pos/OrderSummaryItemWrapper.vue";
import Divider from "primevue/divider";
import Utils from "@/utility/utils";
import { FDICT_PARTS } from "@/utility/fdicts/parts";
import SalesOrder from "@/types/salesorder";

export default defineComponent({
  components: {
    OrderSummaryItemWrapper,
    Divider,
  },
  methods: {
    ...mapActions({
      removeFromOrder: "pos/removeFromOrder",
    }),
    formatPrice(amount: number | string) {
      return Utils.formatPrice(amount);
    },
    removePart(event: any, index: any) {
      if (event) {
        event.stopPropagation();
      }
      this.removeFromOrder(index);
    },
    openCartDialog(index: number) {
      this.$emit("cart-dialog-opened", index);
    },
  },
  computed: {
    ...mapGetters({
      getCurrentActivity: "pos/getCurrentActivity",
      getTaxAmount: "pos/getTaxAmount",
      getFieldLabel: "fdict/getFieldLabel",
      controlFieldValues: "fdict/controlFieldValues",
    }),
    partNumberLabel(): string {
      return this.getFieldLabel("PARTS", FDICT_PARTS.PART_NO, "Part #");
    },
    checkAccountingRoute(): boolean {
      return this.$route.path.includes("/accounting/orders");
    },
    showPartsInformation(): boolean {
      return (
        this.getCurrentActivity === "PARTS" ||
        this.$route.path.includes("/accounting/orders")
      );
    },
  },
  data() {
    return {
      customer: {} as Customer,
      discount: "",
    };
  },
  props: {
    order: {
      type: Object as () => SalesOrder,
      required: true,
    },
  },
});

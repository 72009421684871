import { Module, GetterTree, MutationTree, ActionTree } from "vuex";
import { RootState } from "@/types/state";
import MrkControlState, {
  OrderHeaderShipToDisplayOptions,
  POSRegisterSelectionMode,
} from "@/types/controls/mrk";
import ControlService from "@/services/ControlService";

const namespaced = true;
const controlService = new ControlService();

export const state: MrkControlState = {
  promise: null,
  valid_so_type_items: [],
  pos_order_code_items: [],
  pos_part_status_items: [],
  pos_order_summary_items: [],
  pos_order_subtotal_items: [],
  pos_order_custom_subtotaling: "",
  pos_extra_info_items: [],
  pos_partial_ship_field_items: [],
  pos_price_strategy: "",
  pos_cc_pin_pad: "",
  filter_inactive_customers: "",
  hide_pos_notes: "",
  hide_load_customer_button: "",
  hide_parts_tab: "",
  manually_load_orders: "",
  manually_load_invoices: "",
  disable_downloading_statements: "",
  disable_emailing_statements: "",
  hide_use_new_address: "",
  disable_updating_part_description: "",
  prompt_on_price_override: "",
  order_information_precedes_create_order: "",
  currentCode: "",
  post_invoice_on_ship: "",
  hide_po_no: "",
  search_using_parts: "",
  sales_acct: "",
  fg_location: "",
  cg_location: "",
  require_customer_parts_search: "",
  hide_drafted_orders: "",
  hide_order_buttons: "",
  prohibit_open_po_shipments: "",
  apply_cm_to_open_order: "",
  pos_show_pay_terms: "",
  pos_show_tax_codes: "",
  pos_show_book_date: "",
  pos_show_ship_via: "",
  pos_show_freight_amount: "",
  pos_show_frt_serv_code: "",
  pos_show_sales_rep: "",
  pos_show_order_status: "",
  pos_show_edit_order: "",
  show_pick_ticket_print: "",
  pos_allow_partial_shipments: "",
  allow_customer_creation: "",
  pos_show_category_filter: "",
  pos_show_keyword_search: "",
  allow_mixed_mode: "",
  pos_show_part_cost_column: "",
  pos_require_entry_user_authentication: "",
  pos_auto_validate: "",
  pos_show_order_confirmation_page: "",
  pos_show_only_ship_to: "",
  pos_maintain_cart_sort: "",
  pos_allow_payout: "",
  pos_terms_require_payment: "",
  pos_allow_shipto_override: "",
  pos_show_ship_id: "",
  pos_host_receipt_print: "",
  pos_force_same_part_qty_increment: "",
  pos_inv_lookups_items: [],
  pos_allow_refund: "",
  pos_skip_calc_tax: "",
  pos_allow_negative_price: "",
  pos_disable_stax: "",
  pos_clear_category_on_search: "",
  hide_main_order_tab: "",
  hide_main_opportunities_tab: "",
  order_header_shipto_display: OrderHeaderShipToDisplayOptions.Top,
  pos_hide_invoices_ar_kpi: "",
  accounting_orders_lookup: "",
  posCustomerOrdersLookup: "",
  posSearchOrdersLookup: "",
  pos_lazy_load_shiptos: "",
  pos_register_selection_mode: POSRegisterSelectionMode.Hardware,
  pos_show_invoice_print: "",
};

export const getters: GetterTree<MrkControlState, RootState> = {
  salesOrderTypes: (state) => {
    return state.valid_so_type_items;
  },
  posOrderCodes: (state) => {
    return state.pos_order_code_items;
  },
  posPartStatusItems: (state) => {
    return state.pos_part_status_items;
  },
  posPartStatusList: (state) => {
    return (
      state.pos_part_status_items.flatMap((item) => item.pos_part_status) || []
    );
  },
  posPriceStrategy: (state) => {
    return state.pos_price_strategy;
  },
  posCcPinPadMode: (state) => {
    return state.pos_cc_pin_pad;
  },
  filterInactiveCustomers: (state) => {
    return state.filter_inactive_customers === "Y";
  },
  showPosNotes: (state) => {
    return state.hide_pos_notes != "Y";
  },
  hideLoadCustomerButton: (state) => {
    return state.hide_load_customer_button == "Y";
  },
  hidePartsTab: (state) => {
    return state.hide_parts_tab == "Y";
  },
  manuallyLoadOrders: (state) => {
    return state.manually_load_orders == "Y";
  },
  manuallyLoadInvoices: (state) => {
    return state.manually_load_invoices == "Y";
  },
  disableDownloadingStatements: (state) => {
    return state.disable_downloading_statements == "Y";
  },
  disableEmailingStatements: (state) => {
    return state.disable_emailing_statements == "Y";
  },
  hideUseNewAddress: (state) => {
    return state.hide_use_new_address == "Y";
  },
  allowAddressOverride: (state) => {
    return state.pos_allow_shipto_override === "Y";
  },
  showShipId: (state) => {
    return state.pos_show_ship_id == "Y";
  },
  disableUpdatingPartDescription: (state) => {
    return state.disable_updating_part_description == "Y";
  },
  promptOnPriceOverride: (state) => {
    return state.prompt_on_price_override == "Y";
  },
  orderInformationPrecedesCreateOrder: (state) => {
    return state.order_information_precedes_create_order == "Y";
  },
  posOrderSummaryItems: (state) => {
    return state.pos_order_summary_items;
  },
  posOrderCustomSubTotaling: (state) => {
    return state.pos_order_custom_subtotaling == "Y";
  },
  posOrderSubTotalItems: (state) => {
    return state.pos_order_subtotal_items;
  },
  posExtraInfoItems: (state) => {
    return state.pos_extra_info_items;
  },
  posPartialShipFieldItems: (state) => {
    return state.pos_partial_ship_field_items;
  },
  postInvoiceOnShip: (state) => {
    return state.post_invoice_on_ship == "Y";
  },
  hideInvoicesPoNo: (state) => {
    return state.hide_po_no == "Y";
  },
  searchUsingParts: (state) => {
    return state.search_using_parts === "Y";
  },
  requireCustomerPartsSearch: (state) => {
    return state.require_customer_parts_search === "Y";
  },
  salesAcct: (state) => {
    return state.sales_acct;
  },
  cgLoc: (state) => {
    return state.cg_location;
  },
  fgLoc: (state) => {
    return state.fg_location;
  },
  hideDraftedOrders: (state) => {
    return state.hide_drafted_orders === "Y";
  },
  hideOrderButtons: (state) => {
    return state.hide_order_buttons === "Y";
  },
  prohibitOpenPoShipments: (state) => {
    return state.prohibit_open_po_shipments === "Y";
  },
  applyCmToOpenOrder: (state) => {
    return state.apply_cm_to_open_order === "Y";
  },
  posShowPayTerms: (state) => {
    return state.pos_show_pay_terms === "Y";
  },
  posShowTaxCodes: (state) => {
    return state.pos_show_tax_codes === "Y";
  },
  posShowSalesRep: (state) => {
    return state.pos_show_sales_rep === "Y";
  },
  posShowBookDate: (state) => {
    return state.pos_show_book_date === "Y";
  },
  posShowShipVia: (state) => {
    return state.pos_show_ship_via === "Y";
  },
  posShowFreightAmount: (state) => {
    return state.pos_show_freight_amount === "Y";
  },
  posShowFrtServCode: (state) => {
    return state.pos_show_frt_serv_code === "Y";
  },
  posShowOrderStatus: (state) => {
    return state.pos_show_order_status === "Y";
  },
  posShowEditOrder: (state) => {
    return state.pos_show_edit_order === "Y";
  },
  showPickTicketPrint: (state) => {
    return state.show_pick_ticket_print === "Y";
  },
  posAllowPartialShipments: (state) => {
    return state.pos_allow_partial_shipments === "Y";
  },
  allowCustomerCreation: (state) => {
    return state.allow_customer_creation === "Y";
  },
  posShowCategoryFilter: (state) => {
    return state.pos_show_category_filter === "Y";
  },
  posShowKeywordSearch: (state) => {
    return state.pos_show_keyword_search === "Y";
  },
  allowMixedMode: (state) => {
    return state.allow_mixed_mode === "Y";
  },
  posShowPartCostColumn: (state) => {
    return state.pos_show_part_cost_column === "Y";
  },
  posRequireEntryUserAuthentication: (state) => {
    return state.pos_require_entry_user_authentication === "Y";
  },
  posAutoValidate: (state) => {
    return state.pos_auto_validate === "Y";
  },
  posShowOrderConfirmationPage: (state) => {
    return state.pos_show_order_confirmation_page === "Y";
  },
  posShowOnlyShipTo: (state) => {
    return state.pos_show_only_ship_to === "Y";
  },
  posMaintainCartSort: (state) => {
    return state.pos_maintain_cart_sort === "Y";
  },
  posAllowPayout: (state) => {
    return state.pos_allow_payout === "Y";
  },
  posHostReceiptPrint: (state) => {
    return state.pos_host_receipt_print === "Y";
  },
  posTermsRequirePayment: (state) => {
    return state.pos_terms_require_payment === "Y";
  },
  posForceSamePartQtyIncrement: (state) => {
    return state.pos_force_same_part_qty_increment === "Y";
  },
  posInvLookupsItems: (state) => {
    return state.pos_inv_lookups_items;
  },
  posAllowRefunds: (state) => {
    return state.pos_allow_refund === "Y";
  },
  posSkipCalcTax: (state) => {
    return state.pos_skip_calc_tax === "Y";
  },
  posAllowNegativePrice: (state) => {
    return state.pos_allow_negative_price === "Y";
  },
  posDisableStax: (state) => {
    return state.pos_disable_stax === "Y";
  },
  posClearCategoryOnSearch: (state) => {
    return state.pos_clear_category_on_search === "Y";
  },
  posHideMainOrderTable: (state) => {
    return state.hide_main_order_tab === "Y";
  },
  posHideMainOpportunitiesTable: (state) => {
    return state.hide_main_opportunities_tab === "Y";
  },
  posOrderHeaderShipToDisplay: (state) => {
    return state.order_header_shipto_display;
  },
  posHideInvoicesArKpi: (state) => {
    return state.pos_hide_invoices_ar_kpi === "Y";
  },
  getAccountingOrdersLookup: (state) => {
    return state.accounting_orders_lookup;
  },
  getPosCustomerOrdersLookup: (state) => {
    return state.posCustomerOrdersLookup;
  },
  getPosSearchOrdersLookup: (state) => {
    return state.posSearchOrdersLookup;
  },
  posLazyLoadShiptos: (state) => {
    return state.pos_lazy_load_shiptos === "Y";
  },
  posRegisterSelectionMode: (state) => {
    return state.pos_register_selection_mode;
  },
  posShowInvoicePrint: (state) => {
    return state.pos_show_invoice_print === "Y";
  },
};

export const mutations: MutationTree<MrkControlState> = {
  SET_CO_CODE(state, code) {
    state.currentCode = code;
  },
  SET_MRK_CONTROL(
    state,
    {
      valid_so_type_items,
      pos_order_code_items,
      pos_part_status_items,
      pos_price_strategy,
      pos_cc_pin_pad,
      filter_inactive_customers,
      hide_pos_notes,
      hide_load_customer_button,
      hide_parts_tab,
      manually_load_orders,
      manually_load_invoices,
      disable_downloading_statements,
      disable_emailing_statements,
      hide_use_new_address,
      disable_updating_part_description,
      prompt_on_price_override,
      order_information_precedes_create_order,
      pos_order_summary_items,
      post_invoice_on_ship,
      hide_po_no,
      search_using_parts,
      sales_acct,
      fg_location,
      cg_location,
      require_customer_parts_search,
      hide_drafted_orders,
      hide_order_buttons,
      show_pick_ticket_print,
      prohibit_open_po_shipments,
      apply_cm_to_open_order,
      pos_show_pay_terms,
      pos_show_tax_codes,
      pos_show_book_date,
      pos_show_ship_via,
      pos_show_freight_amount,
      pos_show_frt_serv_code,
      pos_show_order_status,
      pos_show_sales_rep,
      pos_allow_partial_shipments,
      pos_show_edit_order,
      allow_customer_creation,
      pos_show_category_filter,
      pos_show_keyword_search,
      allow_mixed_mode,
      pos_show_part_cost_column,
      pos_require_entry_user_authentication,
      pos_auto_validate,
      pos_show_order_confirmation_page,
      pos_show_only_ship_to,
      pos_maintain_cart_sort,
      pos_allow_payout,
      pos_host_receipt_print,
      pos_extra_info_items,
      pos_partial_ship_field_items,
      pos_terms_require_payment,
      pos_allow_shipto_override,
      pos_show_ship_id,
      pos_force_same_part_qty_increment,
      pos_inv_lookups_items,
      pos_allow_refund,
      pos_skip_calc_tax,
      pos_allow_negative_price,
      pos_disable_stax,
      pos_clear_category_on_search,
      hide_main_order_tab,
      hide_main_opportunities_tab,
      order_header_shipto_display,
      pos_hide_invoices_ar_kpi,
      pos_order_custom_subtotaling,
      pos_order_subtotal_items,
      accounting_orders_lookup,
      posCustomerOrdersLookup,
      posSearchOrdersLookup,
      pos_lazy_load_shiptos,
      pos_register_selection_mode,
      pos_show_invoice_print,
    },
  ) {
    state.valid_so_type_items = valid_so_type_items;
    state.pos_order_code_items = pos_order_code_items;
    state.pos_part_status_items = pos_part_status_items;
    state.pos_price_strategy = pos_price_strategy;
    state.pos_cc_pin_pad = pos_cc_pin_pad;
    state.filter_inactive_customers = filter_inactive_customers;
    state.hide_pos_notes = hide_pos_notes;
    state.hide_load_customer_button = hide_load_customer_button;
    state.hide_parts_tab = hide_parts_tab;
    state.manually_load_orders = manually_load_orders;
    state.manually_load_invoices = manually_load_invoices;
    state.disable_downloading_statements = disable_downloading_statements;
    state.disable_emailing_statements = disable_emailing_statements;
    state.hide_use_new_address = hide_use_new_address;
    state.disable_updating_part_description = disable_updating_part_description;
    state.prompt_on_price_override = prompt_on_price_override;
    state.order_information_precedes_create_order =
      order_information_precedes_create_order;
    state.pos_order_summary_items = pos_order_summary_items;
    state.post_invoice_on_ship = post_invoice_on_ship;
    state.pos_order_custom_subtotaling = pos_order_custom_subtotaling;
    state.pos_order_subtotal_items = pos_order_subtotal_items;
    state.hide_po_no = hide_po_no;
    state.search_using_parts = search_using_parts;
    state.sales_acct = sales_acct;
    state.cg_location = cg_location;
    state.fg_location = fg_location;
    state.require_customer_parts_search = require_customer_parts_search;
    state.hide_drafted_orders = hide_drafted_orders;
    state.hide_order_buttons = hide_order_buttons;
    state.prohibit_open_po_shipments = prohibit_open_po_shipments;
    state.apply_cm_to_open_order = apply_cm_to_open_order;
    state.pos_show_pay_terms = pos_show_pay_terms;
    state.pos_show_tax_codes = pos_show_tax_codes;
    state.pos_show_book_date = pos_show_book_date;
    state.pos_show_ship_via = pos_show_ship_via;
    state.pos_show_freight_amount = pos_show_freight_amount;
    state.pos_show_frt_serv_code = pos_show_frt_serv_code;
    state.pos_show_order_status = pos_show_order_status;
    state.show_pick_ticket_print = show_pick_ticket_print;
    state.pos_show_sales_rep = pos_show_sales_rep;
    state.pos_allow_partial_shipments = pos_allow_partial_shipments;
    state.pos_show_edit_order = pos_show_edit_order;
    state.allow_customer_creation = allow_customer_creation;
    state.pos_show_category_filter = pos_show_category_filter;
    state.pos_show_keyword_search = pos_show_keyword_search;
    state.allow_mixed_mode = allow_mixed_mode;
    state.pos_show_part_cost_column = pos_show_part_cost_column;
    state.pos_require_entry_user_authentication =
      pos_require_entry_user_authentication;
    state.pos_auto_validate = pos_auto_validate;
    state.pos_show_order_confirmation_page = pos_show_order_confirmation_page;
    state.pos_show_only_ship_to = pos_show_only_ship_to;
    state.pos_maintain_cart_sort = pos_maintain_cart_sort;
    state.pos_allow_payout = pos_allow_payout;
    state.pos_host_receipt_print = pos_host_receipt_print;
    state.pos_extra_info_items = pos_extra_info_items;
    state.pos_partial_ship_field_items = pos_partial_ship_field_items;
    state.pos_terms_require_payment = pos_terms_require_payment;
    state.pos_allow_shipto_override = pos_allow_shipto_override;
    state.pos_show_ship_id = pos_show_ship_id;
    state.pos_force_same_part_qty_increment = pos_force_same_part_qty_increment;
    state.pos_inv_lookups_items = pos_inv_lookups_items;
    state.pos_allow_refund = pos_allow_refund;
    state.pos_skip_calc_tax = pos_skip_calc_tax;
    state.pos_allow_negative_price = pos_allow_negative_price;
    state.pos_disable_stax = pos_disable_stax;
    state.pos_clear_category_on_search = pos_clear_category_on_search;
    state.hide_main_order_tab = hide_main_order_tab;
    state.hide_main_opportunities_tab = hide_main_opportunities_tab;
    state.order_header_shipto_display = order_header_shipto_display;
    state.pos_hide_invoices_ar_kpi = pos_hide_invoices_ar_kpi;
    state.accounting_orders_lookup = accounting_orders_lookup;
    state.posCustomerOrdersLookup = posCustomerOrdersLookup;
    state.posSearchOrdersLookup = posSearchOrdersLookup;
    state.pos_lazy_load_shiptos = pos_lazy_load_shiptos;
    state.pos_register_selection_mode = pos_register_selection_mode;
    state.pos_show_invoice_print = pos_show_invoice_print;
  },
  SET_PROMISE(state, promise) {
    state.promise = promise;
  },
};

export const actions: ActionTree<MrkControlState, RootState> = {
  getMrkControl({ commit, state }, { client, selectedCode, refresh }) {
    if (state.promise && !refresh) {
      return state.promise;
    }

    const code = selectedCode == null ? "1" : selectedCode;
    const promise = new Promise((resolve, reject) => {
      if (state.currentCode == code && !refresh) {
        resolve(state);
      } else {
        controlService
          .getControl("MRK" + code, "MRK.CONTROL", "CONTROL", undefined, client)
          .then((resp: any) => {
            if (resp && resp.length > 0) {
              if (resp.length > 0) {
                commit("SET_MRK_CONTROL", {
                  valid_so_type_items: resp[0].valid_so_type_items || [],
                  pos_order_code_items: resp[0].pos_order_code_items || [],
                  pos_part_status_items: resp[0].pos_part_status_items || [],
                  pos_order_summary_items:
                    resp[0].pos_order_summary_items || [],
                  pos_extra_info_items: resp[0].pos_extra_info_items || [],
                  pos_partial_ship_field_items:
                    resp[0].pos_partial_ship_field_items || [],
                  pos_inv_lookups_items: resp[0].pos_inv_lookups_items || [],
                  pos_price_strategy: resp[0].pos_price_strategy || "",
                  pos_cc_pin_pad: resp[0].pos_cc_pin_pad || "",
                  filter_inactive_customers:
                    resp[0].filter_inactive_customers || "",
                  hide_pos_notes: resp[0].hide_pos_notes || "",
                  hide_load_customer_button:
                    resp[0].hide_load_customer_button || "",
                  hide_parts_tab: resp[0].hide_parts_tab || "",
                  manually_load_orders: resp[0].manually_load_orders || "",
                  manually_load_invoices: resp[0].manually_load_invoices || "",
                  disable_downloading_statements:
                    resp[0].disable_downloading_statements || "",
                  disable_emailing_statements:
                    resp[0].disable_emailing_statements || "",
                  hide_use_new_address: resp[0].hide_use_new_address || "",
                  disable_updating_part_description:
                    resp[0].disable_updating_part_description || "",
                  prompt_on_price_override:
                    resp[0].prompt_on_price_override || "",
                  order_information_precedes_create_order:
                    resp[0].order_information_precedes_create_order || "",
                  post_invoice_on_ship: resp[0].post_invoice_on_ship || "",
                  pos_order_custom_subtotaling:
                    resp[0].pos_order_custom_subtotaling || "",
                  pos_order_subtotal_items:
                    resp[0].pos_order_subtotal_items || [],
                  hide_po_no: resp[0].hide_po_no || "",
                  search_using_parts: resp[0].search_using_parts || "",
                  sales_acct: resp[0]["sales_acct#"] || "",
                  cg_location: resp[0].cg_location || "",
                  fg_location: resp[0].fg_location || "",
                  require_customer_parts_search:
                    resp[0].require_customer_parts_search || "",
                  hide_drafted_orders: resp[0].hide_drafted_orders || "",
                  hide_order_buttons: resp[0].hide_order_buttons || "",
                  prohibit_open_po_shipments:
                    resp[0].prohibit_open_po_shipments || "",
                  apply_cm_to_open_order: resp[0].apply_cm_to_open_order || "",
                  pos_show_pay_terms: resp[0].pos_show_pay_terms || "",
                  pos_show_tax_codes: resp[0].pos_show_tax_codes || "",
                  pos_show_book_date: resp[0].pos_show_book_date || "",
                  pos_show_ship_via: resp[0].pos_show_ship_via || "",
                  pos_show_freight_amount:
                    resp[0].pos_show_freight_amount || "",
                  pos_show_frt_serv_code: resp[0].pos_show_frt_serv_code || "",
                  pos_show_order_status: resp[0].pos_show_order_status || "",
                  pos_show_edit_order: resp[0].pos_show_edit_order || "",
                  show_pick_ticket_print: resp[0].show_pick_ticket_print || "",
                  pos_show_sales_rep: resp[0].pos_show_sales_rep || "",
                  pos_allow_partial_shipments:
                    resp[0].pos_allow_partial_shipments || "",
                  allow_customer_creation:
                    resp[0].allow_customer_creation || "",
                  pos_show_category_filter:
                    resp[0].pos_show_category_filter || "",
                  pos_show_keyword_search:
                    resp[0].pos_show_keyword_search || "",
                  allow_mixed_mode: resp[0].allow_mixed_mode || "",
                  pos_show_part_cost_column:
                    resp[0].pos_show_part_cost_column || "",
                  pos_require_entry_user_authentication:
                    resp[0].pos_require_entry_user_authentication || "",
                  pos_auto_validate: resp[0].pos_auto_validate || "",
                  pos_show_order_confirmation_page:
                    resp[0].pos_show_order_confirmation_page || "",
                  pos_show_only_ship_to: resp[0].pos_show_only_ship_to || "",
                  pos_maintain_cart_sort: resp[0].pos_maintain_cart_sort || "",
                  pos_allow_payout: resp[0].pos_allow_payout || "",
                  pos_host_receipt_print: resp[0].pos_host_receipt_print || "",
                  pos_terms_require_payment:
                    resp[0].pos_terms_require_payment || "",
                  pos_allow_shipto_override:
                    resp[0].pos_allow_shipto_override || "",
                  pos_show_ship_id: resp[0].pos_show_ship_id || "",
                  pos_force_same_part_qty_increment:
                    resp[0].pos_force_same_part_qty_increment || "",
                  pos_allow_refund: resp[0].pos_allow_refund || "",
                  pos_skip_calc_tax: resp[0].pos_skip_calc_tax || "",
                  pos_allow_negative_price:
                    resp[0].pos_allow_negative_price || "",
                  pos_disable_stax: resp[0].pos_disable_stax || "",
                  pos_clear_category_on_search:
                    resp[0].pos_clear_category_on_search || "",
                  hide_main_order_tab: resp[0].hide_main_order_tab || "",
                  hide_main_opportunities_tab:
                    resp[0].hide_main_opportunities_tab || "",
                  order_header_shipto_display:
                    resp[0].order_header_shipto_display ||
                    OrderHeaderShipToDisplayOptions.Top,
                  pos_hide_invoices_ar_kpi:
                    resp[0].pos_hide_invoices_ar_kpi || "",
                  accounting_orders_lookup:
                    resp[0].accounting_orders_lookup || "",
                  posCustomerOrdersLookup:
                    resp[0].pos_customer_orders_lookup || "",
                  posSearchOrdersLookup: resp[0].pos_search_orders_lookup || "",
                  pos_lazy_load_shiptos: resp[0].pos_lazy_load_shiptos || "",
                  pos_register_selection_mode:
                    resp[0].pos_register_selection_mode ||
                    POSRegisterSelectionMode.Hardware,
                  pos_show_invoice_print: resp[0].pos_show_invoice_print || "",
                });
                commit("SET_CO_CODE", code);
                resolve(resp[0]);
              } else {
                reject("No data returned");
              }
            }
          })
          .catch((error) => {
            reject(error);
          });
      }
    }).finally(() => {
      commit("SET_PROMISE", null);
    });

    commit("SET_PROMISE", promise);

    return promise;
  },
};

export const mrkControl: Module<MrkControlState, RootState> = {
  namespaced,
  getters,
  state,
  mutations,
  actions,
};

import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, withKeys as _withKeys, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, toDisplayString as _toDisplayString, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "grid flex justify-content-center mt-2"
}
const _hoisted_2 = { class: "col-11" }
const _hoisted_3 = { class: "grid align-items-center" }
const _hoisted_4 = {
  key: 0,
  class: "p-inputgroup-addon",
  style: {"minwidth":"5.5rem"}
}
const _hoisted_5 = { class: "confirmation-content" }
const _hoisted_6 = {
  key: 1,
  class: "grid flex justify-content-center mt-10"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_ctx.validCount)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", {
              class: _normalizeClass(_ctx.rowClass)
            }, [
              _cache[19] || (_cache[19] = _createElementVNode("span", {
                class: "p-inputgroup-addon",
                style: {"minwidth":"5.5rem"}
              }, " Last Part", -1)),
              _createVNode(_component_InputText, {
                class: "scan-input-text",
                modelValue: _ctx.lastPart,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.lastPart) = $event)),
                placeHolder: "Last Part",
                readonly: ""
              }, null, 8, ["modelValue"])
            ], 2),
            _createElementVNode("div", {
              class: _normalizeClass(_ctx.rowClass)
            }, [
              _cache[20] || (_cache[20] = _createElementVNode("span", {
                class: "p-inputgroup-addon",
                style: {"minwidth":"5.5rem"}
              }, "Username", -1)),
              _createVNode(_component_InputText, {
                class: "scan-input-text",
                modelValue: _ctx.selectedUser,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedUser) = $event)),
                placeHolder: "Username",
                readonly: ""
              }, null, 8, ["modelValue"])
            ], 2),
            _createElementVNode("div", {
              class: _normalizeClass(_ctx.rowClass)
            }, [
              _cache[21] || (_cache[21] = _createElementVNode("span", {
                class: "p-inputgroup-addon",
                style: {"minwidth":"5.5rem"}
              }, "Location", -1)),
              _createVNode(_component_InputText, {
                ref: "location",
                class: _normalizeClass(["scan-input-text", {
              'p-invalid': _ctx.isLocationSubmitted && _ctx.v$.location.required.$invalid,
            }]),
                modelValue: _ctx.location,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.location) = $event)),
                placeHolder: "Location",
                onKeypress: _cache[3] || (_cache[3] = _withKeys(($event: any) => (_ctx.handleLocation()), ["enter"]))
              }, null, 8, ["modelValue", "class"])
            ], 2),
            _createElementVNode("div", {
              class: _normalizeClass(_ctx.rowClass)
            }, [
              _cache[22] || (_cache[22] = _createElementVNode("span", {
                class: "p-inputgroup-addon",
                style: {"minwidth":"5.5rem"}
              }, "Bin", -1)),
              _createVNode(_component_InputText, {
                ref: "bin",
                class: "scan-input-text",
                modelValue: _ctx.bin,
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.bin) = $event)),
                placeHolder: "Bin",
                onKeypress: _cache[5] || (_cache[5] = _withKeys(($event: any) => (_ctx.handleBin()), ["enter"]))
              }, null, 8, ["modelValue"])
            ], 2),
            _createElementVNode("div", {
              class: _normalizeClass(_ctx.rowClass)
            }, [
              _cache[23] || (_cache[23] = _createElementVNode("span", {
                class: "p-inputgroup-addon",
                style: {"minwidth":"5.5rem"}
              }, "Part", -1)),
              _createVNode(_component_InputText, {
                ref: "part",
                class: _normalizeClass(["scan-input-text", {
              'p-invalid': _ctx.isPartSubmitted && _ctx.v$.selectedPart.required.$invalid,
            }]),
                modelValue: _ctx.selectedPart,
                "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.selectedPart) = $event)),
                placeHolder: "Part Number",
                "data-test": "fetchPartDetails",
                onKeypress: _cache[7] || (_cache[7] = _withKeys(($event: any) => (_ctx.fetchPartFromPartStore()), ["enter"])),
                onFocus: _cache[8] || (_cache[8] = ($event: any) => ($event.target.select()))
              }, null, 8, ["modelValue", "class"])
            ], 2),
            _createElementVNode("div", {
              class: _normalizeClass(_ctx.rowClass)
            }, [
              _cache[24] || (_cache[24] = _createElementVNode("span", {
                class: "p-inputgroup-addon",
                style: {"minwidth":"5.5rem"}
              }, "Part Name", -1)),
              _createVNode(_component_InputText, {
                ref: "partname",
                class: "scan-input-text",
                modelValue: _ctx.description,
                "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.description) = $event)),
                placeHolder: "",
                readonly: "true"
              }, null, 8, ["modelValue"])
            ], 2),
            (_ctx.hasLot)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  class: _normalizeClass(_ctx.rowClass)
                }, [
                  _cache[25] || (_cache[25] = _createElementVNode("span", {
                    class: "p-inputgroup-addon",
                    style: {"minwidth":"5.5rem"}
                  }, "Lot", -1)),
                  _createVNode(_component_InputText, {
                    ref: "lot",
                    class: "scan-input-text",
                    modelValue: _ctx.lot,
                    "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.lot) = $event)),
                    placeHolder: "Lot Number",
                    onKeypress: _cache[11] || (_cache[11] = _withKeys(($event: any) => (_ctx.handleLot()), ["enter"])),
                    onFocus: _cache[12] || (_cache[12] = ($event: any) => (($event.target as any).select()))
                  }, null, 8, ["modelValue"])
                ], 2))
              : _createCommentVNode("", true),
            _createElementVNode("div", {
              class: _normalizeClass(_ctx.rowClass)
            }, [
              _cache[26] || (_cache[26] = _createElementVNode("span", {
                class: "p-inputgroup-addon",
                style: {"minwidth":"5.5rem"}
              }, "INV Qty", -1)),
              _createVNode(_component_InputText, {
                ref: "invquantity",
                class: _normalizeClass(_ctx.haspacksize ? 'scan-input-text-half' : 'scan-input-text'),
                modelValue: _ctx.invquantity,
                "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.invquantity) = $event)),
                placeHolder: "",
                readonly: "true"
              }, null, 8, ["class", "modelValue"]),
              (_ctx.haspacksize)
                ? (_openBlock(), _createElementBlock("span", _hoisted_4, "Pack Size"))
                : _createCommentVNode("", true),
              (_ctx.haspacksize)
                ? (_openBlock(), _createBlock(_component_InputText, {
                    key: 1,
                    ref: "packsize",
                    class: "scan-input-text-half",
                    modelValue: _ctx.packsize,
                    "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.packsize) = $event)),
                    placeHolder: "",
                    readonly: "true"
                  }, null, 8, ["modelValue"]))
                : _createCommentVNode("", true)
            ], 2),
            _createElementVNode("div", {
              class: _normalizeClass(_ctx.rowClass)
            }, [
              _cache[27] || (_cache[27] = _createElementVNode("span", {
                class: "p-inputgroup-addon",
                style: {"minwidth":"5.5rem"}
              }, "Qty", -1)),
              _createVNode(_component_InputText, {
                ref: "quantity",
                class: "scan-input-text",
                modelValue: _ctx.quantity,
                "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.quantity) = $event)),
                onFocus: _cache[16] || (_cache[16] = ($event: any) => (($event.target as any).select())),
                placeHolder: "Quantity"
              }, null, 8, ["modelValue"])
            ], 2),
            _createElementVNode("div", {
              class: _normalizeClass(_ctx.rowClass)
            }, [
              _createVNode(_component_Button, {
                icon: "pi pi-check",
                label: "Submit",
                loading: _ctx.loadSubmit,
                onClick: _cache[17] || (_cache[17] = ($event: any) => (_ctx.submit())),
                class: "scan-input-button"
              }, null, 8, ["loading"])
            ], 2),
            _createVNode(_component_Dialog, {
              visible: _ctx.displayConfirmation,
              "onUpdate:visible": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.displayConfirmation) = $event)),
              header: "Confirmation",
              breakpoints: { '960px': '75vw', '640px': '90vw' },
              style: { width: '350px' },
              modal: true
            }, {
              footer: _withCtx(() => [
                _createVNode(_component_Button, {
                  label: "Yes",
                  icon: "pi pi-check",
                  onClick: _ctx.addConfirmation,
                  class: "p-button-text",
                  autofocus: ""
                }, null, 8, ["onClick"]),
                _createVNode(_component_Button, {
                  label: "Rep",
                  icon: "pi pi-account",
                  onClick: _ctx.addConfirmation,
                  class: "p-button-text"
                }, null, 8, ["onClick"]),
                _createVNode(_component_Button, {
                  label: "No",
                  icon: "pi pi-times",
                  onClick: _ctx.closeConfirmation,
                  class: "p-button-text"
                }, null, 8, ["onClick"])
              ]),
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_5, [
                  _cache[28] || (_cache[28] = _createElementVNode("i", {
                    class: "pi pi-exclamation-triangle mr-3",
                    style: {"font-size":"2rem"}
                  }, null, -1)),
                  _createElementVNode("span", null, _toDisplayString(_ctx.selectedPart) + " has been already counted.", 1),
                  _createElementVNode("div", null, "Location: " + _toDisplayString(_ctx.location), 1),
                  _createElementVNode("div", null, "Bin: " + _toDisplayString(_ctx.bin), 1),
                  _cache[29] || (_cache[29] = _createElementVNode("span", null, "Do you want to continue?", -1))
                ])
              ]),
              _: 1
            }, 8, ["visible"])
          ])
        ])
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_6, _cache[30] || (_cache[30] = [
        _createElementVNode("h1", null, "A Physical Inventory is not in progress.", -1)
      ])))
}
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, withModifiers as _withModifiers, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "grid mr-0 mt-0 ml-0 justify-content-center" }
const _hoisted_2 = { class: "col-12 p-0" }
const _hoisted_3 = { class: "accounting-tab" }
const _hoisted_4 = { class: "menutab-item" }
const _hoisted_5 = { class: "sales-tab p-0" }
const _hoisted_6 = { class: "align-self-center p-0 m-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_Button = _resolveComponent("Button")!
  const _component_TabPanel = _resolveComponent("TabPanel")!
  const _component_TabView = _resolveComponent("TabView")!
  const _component_TopKPICards = _resolveComponent("TopKPICards")!
  const _component_router_view = _resolveComponent("router-view")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_TabView, {
          activeIndex: _ctx.getActiveTab,
          onTabChange: _ctx.handleMainTabChange,
          scrollable: true,
          class: "account-tabview"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_TabPanel, null, {
              header: _withCtx(() => [
                _createElementVNode("div", _hoisted_3, [
                  _createVNode(_component_router_link, {
                    to: "/inventory",
                    class: "line-height-4 accounting-tab-item"
                  }, {
                    default: _withCtx(() => _cache[0] || (_cache[0] = [
                      _createElementVNode("span", { class: "align-self-center pr-3 pl-0" }, "Main", -1)
                    ])),
                    _: 1
                  })
                ])
              ]),
              default: _withCtx(() => [
                _createVNode(_component_TabView, {
                  scrollable: true,
                  class: "customers-menu-tabview sales-menu-tabview tabview-panels-hidden top-navigation-tabview xl:hidden block",
                  activeIndex: _ctx.activeInnerTab,
                  onTabChange: _ctx.handleMainTabChange
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredInventoryItems, (tab) => {
                      return (_openBlock(), _createBlock(_component_TabPanel, {
                        key: tab.label
                      }, {
                        header: _withCtx(() => [
                          _createElementVNode("div", _hoisted_4, [
                            _createVNode(_component_router_link, {
                              to: tab.to,
                              class: "menutab-item-link"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_Button, {
                                  label: tab.label,
                                  class: "menutab-button"
                                }, null, 8, ["label"])
                              ]),
                              _: 2
                            }, 1032, ["to"])
                          ])
                        ]),
                        _: 2
                      }, 1024))
                    }), 128))
                  ]),
                  _: 1
                }, 8, ["activeIndex", "onTabChange"]),
                _createVNode(_component_TopKPICards, {
                  items: 
              Object.entries(_ctx.filteredInventoryItems).map((item) => item[1])
            
                }, null, 8, ["items"])
              ]),
              _: 1
            }),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getInventory, (inventory) => {
              return (_openBlock(), _createBlock(_component_TabPanel, {
                key: inventory.id,
                class: "pt-0"
              }, {
                header: _withCtx(() => [
                  _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_5, [
                    _createVNode(_component_router_link, {
                      to: inventory.getRoute,
                      class: "line-height-4 sales-tab-item"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("span", _hoisted_6, _toDisplayString(inventory.getTab), 1)
                      ]),
                      _: 2
                    }, 1032, ["to"]),
                    _createVNode(_component_Button, {
                      icon: "pi pi-times",
                      class: "p-button-text p-0 m-0 pb-1",
                      title: `Close Receipt`,
                      onClick: _withModifiers(($event: any) => (_ctx.handleRemoveInventory(inventory.id)), ["stop"])
                    }, null, 8, ["onClick"])
                  ])), [
                    [_directive_tooltip, inventory.getTab]
                  ])
                ]),
                _: 2
              }, 1024))
            }), 128))
          ]),
          _: 1
        }, 8, ["activeIndex", "onTabChange"])
      ])
    ]),
    _createVNode(_component_router_view)
  ], 64))
}
import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, withCtx as _withCtx, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "grid mr-0 justify-content-center ml-2 mr-2" }
const _hoisted_2 = { class: "col-12" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Column = _resolveComponent("Column")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _component_Card = _resolveComponent("Card")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_Card, null, {
        content: _withCtx(() => [
          _createVNode(_component_DataTable, {
            dataKey: "cust_id",
            value: _ctx.getAllCustomers.cust_items,
            paginator: true,
            rowsPerPageOptions: [50, 100, 150],
            rows: _ctx.rows,
            selectionMode: "single",
            breakpoint: "1267px",
            onRowClick: _ctx.handleClick,
            onPage: _ctx.pageClick,
            class: "p-datatable-sm"
          }, {
            empty: _withCtx(() => _cache[0] || (_cache[0] = [
              _createTextVNode(" No rows to show. ")
            ])),
            default: _withCtx(() => [
              _createVNode(_component_Column, {
                field: "cust_id",
                header: "ID"
              }),
              _createVNode(_component_Column, {
                field: "name",
                header: "Customer Name"
              }),
              (_ctx.showServiceRep)
                ? (_openBlock(), _createBlock(_component_Column, {
                    key: 0,
                    field: "service_rep",
                    header: "Service Rep"
                  }))
                : (_openBlock(), _createBlock(_component_Column, {
                    key: 1,
                    field: "rep_items",
                    header: "Reps"
                  }, {
                    body: _withCtx((slotProps) => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(slotProps.data.rep_items, (rep, index) => {
                        return (_openBlock(), _createElementBlock("span", { key: index }, [
                          _createTextVNode(_toDisplayString(rep.rep_name) + " ", 1),
                          (index < slotProps.data.rep_items.length - 1)
                            ? (_openBlock(), _createElementBlock("br", _hoisted_3))
                            : _createCommentVNode("", true)
                        ]))
                      }), 128))
                    ]),
                    _: 1
                  })),
              _createVNode(_component_Column, { header: "Shipping Address" }, {
                body: _withCtx((slotProps) => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.shippingAddress(slotProps.data), (address, i) => {
                    return (_openBlock(), _createElementBlock("span", { key: i }, [
                      _createTextVNode(_toDisplayString(address) + " ", 1),
                      _cache[1] || (_cache[1] = _createElementVNode("br", null, null, -1))
                    ]))
                  }), 128))
                ]),
                _: 1
              }),
              _createVNode(_component_Column, {
                field: "phone",
                header: "Phone #",
                bodyStyle: "text-align: right"
              }, {
                body: _withCtx((slotProps) => [
                  _createElementVNode("a", {
                    href: 'tel:' + slotProps.data.phone
                  }, _toDisplayString(slotProps.data.phone), 9, _hoisted_4)
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["value", "rows", "onRowClick", "onPage"])
        ]),
        _: 1
      })
    ])
  ]))
}
import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "grid m-0" }
const _hoisted_2 = { class: "flex col-12 justify-content-end align-items-center my-3" }
const _hoisted_3 = {
  key: 0,
  class: "col-12"
}
const _hoisted_4 = { class: "grid" }
const _hoisted_5 = { class: "formgrid grid m-0" }
const _hoisted_6 = { class: "col-12 lg:col-3" }
const _hoisted_7 = { class: "p-inputgroup mt-2" }
const _hoisted_8 = { class: "col-12 lg:col-3" }
const _hoisted_9 = { class: "p-inputgroup mt-2" }
const _hoisted_10 = { class: "col-6" }
const _hoisted_11 = { class: "col-12 lg:col-3" }
const _hoisted_12 = { class: "p-inputgroup mt-2" }
const _hoisted_13 = { class: "col-12 lg:col-3" }
const _hoisted_14 = { class: "p-inputgroup mt-2" }
const _hoisted_15 = { class: "col-12 lg:col-3" }
const _hoisted_16 = { class: "p-inputgroup mt-2" }
const _hoisted_17 = { class: "col-12 lg:col-3" }
const _hoisted_18 = { class: "p-inputgroup mt-2" }
const _hoisted_19 = { class: "col-12 lg:col-3 mt-2" }
const _hoisted_20 = { class: "col-12 lg:col-3" }
const _hoisted_21 = { class: "p-inputgroup mt-2" }
const _hoisted_22 = { class: "col-12 lg:col-3" }
const _hoisted_23 = { class: "p-inputgroup mt-2" }
const _hoisted_24 = { class: "col-12 lg:col-3" }
const _hoisted_25 = { class: "p-inputgroup mt-2" }
const _hoisted_26 = { class: "col-12 lg:col-3" }
const _hoisted_27 = { class: "p-inputgroup mt-2" }
const _hoisted_28 = { class: "col-12" }
const _hoisted_29 = { class: "grid" }
const _hoisted_30 = { class: "col-12 lg:col-6" }
const _hoisted_31 = {
  class: "flex justify-content-between align-items-center",
  style: {"max-width":"750px"}
}
const _hoisted_32 = { class: "p-inputgroup" }
const _hoisted_33 = { class: "col-12 lg:col-6" }
const _hoisted_34 = { class: "p-inputgroup" }
const _hoisted_35 = { class: "flex justify-content-end flex-end" }
const _hoisted_36 = {
  key: 1,
  class: "card col-12 md:col-3"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_AddressItems = _resolveComponent("AddressItems")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_Checkbox = _resolveComponent("Checkbox")!
  const _component_MultiSelect = _resolveComponent("MultiSelect")!
  const _component_Divider = _resolveComponent("Divider")!
  const _component_Card = _resolveComponent("Card")!
  const _component_AddShippingAddressDialog = _resolveComponent("AddShippingAddressDialog")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", null, [
        _createVNode(_component_Button, {
          label: "New",
          icon: "pi pi-plus",
          iconPos: "left",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.addAddress()))
        }),
        (this.shipToInfo?.length > 0)
          ? (_openBlock(), _createBlock(_component_Button, {
              key: 0,
              loading: _ctx.isSubmitting,
              label: "Save",
              icon: "pi pi-check",
              iconPos: "left",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.saveAddressInfo(false))),
              class: "ml-2"
            }, null, 8, ["loading"]))
          : _createCommentVNode("", true)
      ])
    ]),
    (_ctx.showShipAddress)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(this.shipToInfo, (item, index) => {
              return (_openBlock(), _createBlock(_component_Card, {
                key: index,
                class: "mb-3"
              }, {
                content: _withCtx(() => [
                  _createElementVNode("div", _hoisted_5, [
                    _cache[19] || (_cache[19] = _createElementVNode("div", { class: "flex col-12 justify-content-between align-items-center" }, [
                      _createElementVNode("h3", null, "Address"),
                      _createElementVNode("div")
                    ], -1)),
                    _createElementVNode("div", _hoisted_6, [
                      _createElementVNode("div", _hoisted_7, [
                        _cache[4] || (_cache[4] = _createElementVNode("span", { class: "p-inputgroup-addon" }, "Seq", -1)),
                        _createVNode(_component_InputText, {
                          type: "text",
                          modelValue: item.ship_seq,
                          "onUpdate:modelValue": ($event: any) => ((item.ship_seq) = $event)
                        }, null, 8, ["modelValue", "onUpdate:modelValue"])
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_8, [
                      _createElementVNode("div", _hoisted_9, [
                        _cache[5] || (_cache[5] = _createElementVNode("span", { class: "p-inputgroup-addon" }, "Name", -1)),
                        _createVNode(_component_InputText, {
                          type: "text",
                          modelValue: item.ship_name,
                          "onUpdate:modelValue": ($event: any) => ((item.ship_name) = $event)
                        }, null, 8, ["modelValue", "onUpdate:modelValue"])
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_10, [
                      _createVNode(_component_AddressItems, {
                        address: item.ship_to_items,
                        addressName: "ship_to",
                        onUpdateAddress: ($event: any) => (_ctx.updateShipAddress($event, item)),
                        inline: true
                      }, null, 8, ["address", "onUpdateAddress"])
                    ]),
                    _createElementVNode("div", _hoisted_11, [
                      _createElementVNode("div", _hoisted_12, [
                        _cache[6] || (_cache[6] = _createElementVNode("span", { class: "p-inputgroup-addon" }, "City", -1)),
                        _createVNode(_component_InputText, {
                          type: "text",
                          modelValue: item.ship_city,
                          "onUpdate:modelValue": ($event: any) => ((item.ship_city) = $event)
                        }, null, 8, ["modelValue", "onUpdate:modelValue"])
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_13, [
                      _createElementVNode("div", _hoisted_14, [
                        _cache[7] || (_cache[7] = _createElementVNode("span", { class: "p-inputgroup-addon" }, "State", -1)),
                        _createVNode(_component_InputText, {
                          type: "text",
                          modelValue: item.ship_state,
                          "onUpdate:modelValue": ($event: any) => ((item.ship_state) = $event)
                        }, null, 8, ["modelValue", "onUpdate:modelValue"])
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_15, [
                      _createElementVNode("div", _hoisted_16, [
                        _cache[8] || (_cache[8] = _createElementVNode("span", { class: "p-inputgroup-addon" }, "Zip", -1)),
                        _createVNode(_component_InputText, {
                          type: "text",
                          modelValue: item.ship_zip,
                          "onUpdate:modelValue": ($event: any) => ((item.ship_zip) = $event)
                        }, null, 8, ["modelValue", "onUpdate:modelValue"])
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_17, [
                      _createElementVNode("div", _hoisted_18, [
                        _cache[9] || (_cache[9] = _createElementVNode("span", { class: "p-inputgroup-addon" }, "Country", -1)),
                        _createVNode(_component_Dropdown, {
                          type: "text",
                          modelValue: item.ship_country,
                          "onUpdate:modelValue": ($event: any) => ((item.ship_country) = $event),
                          options: _ctx.getCountries(),
                          onSelect: ($event: any) => (_ctx.getCountryCode(item.ship_country))
                        }, null, 8, ["modelValue", "onUpdate:modelValue", "options", "onSelect"])
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_19, [
                      _createVNode(_component_Checkbox, {
                        id: "binary",
                        modelValue: item.ship_comm,
                        "onUpdate:modelValue": ($event: any) => ((item.ship_comm) = $event),
                        trueValue: "Y",
                        falseValue: null,
                        binary: true
                      }, null, 8, ["modelValue", "onUpdate:modelValue"]),
                      _cache[10] || (_cache[10] = _createElementVNode("label", { for: "binary" }, " Commercial ", -1))
                    ]),
                    _createElementVNode("div", _hoisted_20, [
                      _createElementVNode("div", _hoisted_21, [
                        _cache[11] || (_cache[11] = _createElementVNode("span", { class: "p-inputgroup-addon" }, "Attention", -1)),
                        _createVNode(_component_InputText, {
                          type: "text",
                          modelValue: item.ship_attn,
                          "onUpdate:modelValue": ($event: any) => ((item.ship_attn) = $event)
                        }, null, 8, ["modelValue", "onUpdate:modelValue"])
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_22, [
                      _createElementVNode("div", _hoisted_23, [
                        _cache[12] || (_cache[12] = _createElementVNode("span", { class: "p-inputgroup-addon" }, "Email", -1)),
                        _createVNode(_component_InputText, {
                          type: "text",
                          modelValue: item.ship_email,
                          "onUpdate:modelValue": ($event: any) => ((item.ship_email) = $event)
                        }, null, 8, ["modelValue", "onUpdate:modelValue"])
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_24, [
                      _createElementVNode("div", _hoisted_25, [
                        _cache[13] || (_cache[13] = _createElementVNode("span", { class: "p-inputgroup-addon" }, "Phone", -1)),
                        _createVNode(_component_InputText, {
                          type: "text",
                          modelValue: item.ship_phone,
                          "onUpdate:modelValue": ($event: any) => ((item.ship_phone) = $event)
                        }, null, 8, ["modelValue", "onUpdate:modelValue"])
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_26, [
                      _createElementVNode("div", _hoisted_27, [
                        _cache[14] || (_cache[14] = _createElementVNode("span", { class: "p-inputgroup-addon" }, "Fax", -1)),
                        _createVNode(_component_InputText, {
                          type: "text",
                          modelValue: item.ship_fax,
                          "onUpdate:modelValue": ($event: any) => ((item.ship_fax) = $event)
                        }, null, 8, ["modelValue", "onUpdate:modelValue"])
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_28, [
                      _createElementVNode("div", _hoisted_29, [
                        _createElementVNode("div", _hoisted_30, [
                          _cache[16] || (_cache[16] = _createElementVNode("h4", null, "Tax Code", -1)),
                          _createElementVNode("div", _hoisted_31, [
                            _createElementVNode("div", _hoisted_32, [
                              _cache[15] || (_cache[15] = _createElementVNode("span", { class: "p-inputgroup-addon" }, "Select Tax Code", -1)),
                              _createVNode(_component_MultiSelect, {
                                modelValue: item.ship_to_tax_items,
                                "onUpdate:modelValue": ($event: any) => ((item.ship_to_tax_items) = $event),
                                options: _ctx.getTaxCodeOptions(),
                                optionLabel: "ship_to_tax",
                                display: "chip"
                              }, null, 8, ["modelValue", "onUpdate:modelValue", "options"])
                            ])
                          ])
                        ]),
                        _createElementVNode("div", _hoisted_33, [
                          _cache[18] || (_cache[18] = _createElementVNode("h4", null, "Third Party Billing Data", -1)),
                          _createElementVNode("div", _hoisted_34, [
                            _cache[17] || (_cache[17] = _createElementVNode("span", { class: "p-inputgroup-addon" }, "Carrier", -1)),
                            _createVNode(_component_MultiSelect, {
                              modelValue: item.ship_acct_no_items,
                              "onUpdate:modelValue": ($event: any) => ((item.ship_acct_no_items) = $event),
                              options: _ctx.getCarrierOptions(),
                              optionLabel: "ship_acct_no",
                              display: "chip"
                            }, {
                              option: _withCtx((slotProps) => [
                                _createTextVNode(_toDisplayString(slotProps.option.ship_carrier +
                            ": " +
                            slotProps.option.ship_acct_no), 1)
                              ]),
                              _: 2
                            }, 1032, ["modelValue", "onUpdate:modelValue", "options"])
                          ])
                        ]),
                        _createVNode(_component_Divider)
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_35, [
                      _createVNode(_component_Button, {
                        label: "Delete",
                        onClick: ($event: any) => (_ctx.confirmDeleteAddress(item.ship_seq))
                      }, null, 8, ["onClick"])
                    ])
                  ])
                ]),
                _: 2
              }, 1024))
            }), 128))
          ])
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_36, _cache[20] || (_cache[20] = [
          _createElementVNode("p", null, "No Ship-To items to display.", -1)
        ]))),
    _createVNode(_component_AddShippingAddressDialog, {
      header: "New Address",
      showDialog: _ctx.showDialog,
      customerId: _ctx.getCustomerId,
      onCloseDialog: _ctx.closeDialog,
      onSaveAddress: _cache[2] || (_cache[2] = ($event: any) => (_ctx.saveAddressInfo(true, $event)))
    }, null, 8, ["showDialog", "customerId", "onCloseDialog"]),
    _createVNode(_component_Dialog, {
      visible: _ctx.deleteAddressDialog,
      "onUpdate:visible": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.deleteAddressDialog) = $event)),
      style: { width: '450px' },
      header: "Confirm Action",
      modal: true,
      draggable: false
    }, {
      footer: _withCtx(() => [
        _createVNode(_component_Button, {
          label: "No",
          icon: "pi pi-times",
          class: "p-button-text",
          onClick: _ctx.closeDialog
        }, null, 8, ["onClick"]),
        _createVNode(_component_Button, {
          label: "Yes",
          icon: "pi pi-check",
          class: "p-button-text",
          onClick: _ctx.deleteAddress
        }, null, 8, ["onClick"])
      ]),
      default: _withCtx(() => [
        _cache[21] || (_cache[21] = _createElementVNode("div", { class: "confirmation-content" }, [
          _createElementVNode("i", {
            class: "pi pi-exclamation-triangle mr-3",
            style: {"font-size":"2rem"}
          }),
          _createElementVNode("span", null, "Are you sure you would like to delete this record?")
        ], -1))
      ]),
      _: 1
    }, 8, ["visible"])
  ]))
}
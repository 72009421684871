
import { defineComponent } from "@vue/runtime-core";
import store from "@/store";
import TextArea from "primevue/textarea";
import Button from "primevue/button";
import useVuelidate from "@vuelidate/core";
import InputText from "primevue/inputtext";
import { mapActions, mapGetters, mapState } from "vuex";
import MessageBox from "@/components/MessageBox.vue";

import LocationService from "@/services/LocationService";
import InventoryService from "@/services/inventory";
import Utils from "@/utility/utils";

const locationService = new LocationService(
  process.env.VUE_APP_ABSTRACTION_API,
);
const inventoryService = new InventoryService(
  process.env.VUE_APP_ABSTRACTION_API,
);

export default defineComponent({
  name: "Details",
  components: {
    TextArea,
    Button,
    InputText,
    MessageBox,
  },
  props: {
    part_no: String,
  },
  data() {
    return {
      um: "",
      placeholder: "",
      part_desc: "",
      loadSubmit: false,
      errorMessage: "",
      showErrorDialog: false,
      focusRefName: "",
      from_loc_bin_control: false,
      to_loc_bin_control: false,
      lot_control: false,
      rowClass:
        "p-field p-col col-12 m-0 p-0 pb-1 flex justify-content-center r-mono",
      payload: {
        part_no: "",
        from_loc: "",
        from_wo: "",
        from_lot: "",
        from_bin: "",
        to_loc: "",
        to_wo: "",
        to_lot: "",
        to_bin: "",
        to_ref_bin: "",
        shortage: "",
        qty: 0,
        notes: "",
        user: "",
      },
    };
  },
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  methods: {
    ...mapActions({
      fetchControl: "control/fetchControl",
      addNotification: "notification/add",
      fetchPartFromInventoryStore: "inventory/fetchPart",
    }),
    clearInput() {
      this.um = "";
      (this.placeholder = ""),
        (this.part_desc = ""),
        (this.payload = {
          part_no: "",
          from_loc: "",
          from_wo: "",
          from_lot: "",
          from_bin: "",
          to_loc: "",
          to_wo: "",
          to_lot: "",
          to_bin: "",
          to_ref_bin: "",
          shortage: "",
          qty: 0,
          notes: "",
          user: this.getUser.user_id,
        });
    },
    focusInput(refName: string) {
      if (refName) {
        (this.$refs[refName] as any).$el.focus();
        (this.$refs[refName] as any).$el.select();
      }
    },
    handleToLocation() {
      if (this.payload.to_loc) {
        const [to_loc, bin] = Utils.splitStringInTwo(this.payload.to_loc, "-");
        this.payload.to_loc = to_loc;
        this.payload.to_bin = bin;

        locationService
          .getLocationById(
            this.getClient,
            this.payload.to_loc,
            "invloc_id bin_control type phys neg_ok",
          )
          .then((resp: any) => {
            if (resp.invloc_items && resp.invloc_items[0]) {
              const location = resp.invloc_items[0];
              this.payload.to_loc = location.invloc_id;

              this.to_loc_bin_control = location.bin_control === "Y";

              if (this.to_loc_bin_control) {
                this.payload.to_bin = bin;
              }

              if (this.lot_control) {
                this.focusInput("to_lot");
                return;
              }

              if (this.to_loc_bin_control && !this.payload.to_bin) {
                this.focusInput("to_bin");
                return;
              }

              this.focusInput("notes");
            } else {
              throw new Error(to_loc + " is not a valid inventory location");
            }
          })
          .catch((err) => {
            this.payload.to_loc = "";
            this.payload.to_bin = "";
            this.handleErrorAndFocus(
              err.toString() || "Error with To Location.",
              "to_loc",
            );
          });
      }
    },
    handleFromBin() {
      if (!this.from_loc_bin_control) {
        const message =
          this.payload.from_loc + " is not a bin controlled location.";
        this.handleErrorAndFocus(message, "from_bin");
        this.payload.from_bin = "";
      } else if (!this.payload.from_bin) {
        const message = this.payload.from_loc + "Bin number required.";
        this.handleErrorAndFocus(message, "from_bin");
        this.payload.from_bin = "";
      } else {
        this.focusInput("to_loc");
      }
    },
    handleFromLocation() {
      if (this.payload.from_loc) {
        const [from_loc, bin] = Utils.splitStringInTwo(
          this.payload.from_loc,
          "-",
        );
        this.payload.from_loc = from_loc;
        this.payload.from_bin = bin;

        locationService
          .getLocationById(
            this.getClient,
            this.payload.from_loc,
            "invloc_id bin_control type phys neg_ok",
          )
          .then((resp: any) => {
            if (resp.invloc_items && resp.invloc_items[0]) {
              const location = resp.invloc_items[0];
              this.payload.from_loc = location.invloc_id;

              this.from_loc_bin_control = location.bin_control === "Y";

              if (this.from_loc_bin_control) {
                this.payload.from_bin = bin;
              }

              if (this.lot_control) {
                this.focusInput("from_lot");
                return;
              }

              if (this.from_loc_bin_control && !this.payload.from_bin) {
                this.focusInput("from_bin");
                return;
              }

              this.focusInput("to_loc");
            } else {
              throw new Error(from_loc + " is not a valid inventory location");
            }
          })
          .catch((err) => {
            this.payload.from_loc = "";
            this.payload.from_bin = "";
            this.handleErrorAndFocus(
              err.toString() || "Error with From Location.",
              "from_loc",
            );
          });
      }
    },
    handleFromLot() {
      if (!this.lot_control) {
        this.payload.from_lot = "";
        this.handleErrorAndFocus(
          "Lot control is not enabled for this part.",
          "from_lot",
        );
      } else if (this.from_loc_bin_control) {
        this.focusInput("from_bin");
      } else {
        this.focusInput("to_loc");
      }
    },
    handleToLot() {
      if (!this.lot_control) {
        this.payload.from_lot = "";
        this.handleErrorAndFocus(
          "Lot control is not enabled for this part.",
          "from_lot",
        );
      } else if (this.to_loc_bin_control) {
        this.focusInput("to_bin");
      } else {
        this.focusInput("notes");
      }
    },
    handleQty() {
      this.focusInput("from_loc");
    },
    handleErrorAndFocus(message: string, ref: string) {
      this.errorMessage = message;
      this.showErrorDialog = true;
      this.focusRefName = ref;
    },
    clickConfirmErrorDialog() {
      this.showErrorDialog = false;
      this.focusInput(this.focusRefName);
    },
    handlePartNumber() {
      if (this.payload.part_no) {
        this.fetchPartFromInventoryStore({
          client: store.getters["session/getClient"],
          id: this.payload.part_no,
          correls: "um",
        })
          .then(() => {
            this.payload.part_no = this.getPartFromInventoryStore.part_no;
            this.part_desc =
              this.getPartFromInventoryStore.wrap_desc.replaceAll("^", "\r\n");
            this.um = this.getPartFromInventoryStore.um;
            const part = this.getPartFromInventoryStore;
            this.lot_control = part.lot_control === "Y";

            this.focusInput("qty");
          })
          .catch(() => {
            this.handleErrorAndFocus("Failed to load part details.", "part");
          });
      }
    },
    async submitTransfer() {
      const client = store.getters["session/getClient"];
      this.payload.notes = this.payload.notes.replaceAll("\r\n", "^");
      try {
        const response = await inventoryService.postItTransaction(
          client,
          this.payload,
        );

        const resp = response.response;

        if (!resp) {
          throw new Error("Failed to create transfer.");
        }

        let notification = {
          message: resp.message || "Successfully created transfer.",
          type: "success",
          dialog: true,
        };

        if (!resp.error) {
          if (resp.metaData && "message" in resp.metaData) {
            notification.message = resp.metaData["message"];
          }

          this.addNotification(notification, { root: true });
          this.clearInput();
          this.focusInput("part");
        } else {
          throw new Error(resp.message || "Failed to create transfer.");
        }
      } catch (e: any) {
        const message = Utils.parseErrorMessage(e) as string;
        this.handleErrorAndFocus(message, "part");
      } finally {
        this.loadSubmit = false;
      }
    },
  },
  computed: {
    ...mapState(["inventory"]),
    ...mapGetters({
      getLocations: "inventory/getLocations",
      getPartFromInventoryStore: "inventory/getPart",
      getLocation: "inventory/getLocation",
      getPartLocation: "inventory/getPartLocation",
      getClient: "session/getClient",
      getUser: "session/getUser",
    }),
  },
  mounted() {
    if (this.part_no) {
      this.payload.part_no = this.part_no;
      this.handlePartNumber();
    } else {
      this.focusInput("part");
    }
    this.payload.user = this.getUser.user_id;
  },
});


import { defineComponent } from "vue";
import cloneDeep from "lodash/cloneDeep";

import { Field } from "../../types/fdict";

import DataTable from "primevue/datatable";
import Column from "primevue/column";
import RoverInput from "./RoverInput.vue";
import Button from "primevue/button";
import { isEqual, isNil, isNull, omitBy } from "lodash";
export default defineComponent({
  components: {
    DataTable,
    Column,
    RoverInput,
    Button,
  },
  emits: ["updateArray"],
  props: {
    fields: {
      required: true,
      type: Array as () => Field[],
    },
    dataArray: {
      type: Array as () => any[],
    },
    editable: {
      default: false,
    },
  },
  data() {
    return {
      input: [] as any[],
    };
  },
  mounted() {
    this.input = cloneDeep(this.dataArray) || [];
    if (this.input.length === 0) {
      const obj = {} as any;
      this.fields.forEach((field) => {
        obj[field.json_name] = "";
      });
      this.input = [obj];
      this.$emit("updateArray", this.input);
    }
  },
  methods: {
    addRow(index: number) {
      const obj = {} as any;
      this.fields.forEach((field) => {
        obj[field.json_name] = "";
      });
      this.input.splice(index + 1, 0, obj);
      this.$emit("updateArray", this.input);
    },
    deleteRow(index: number) {
      if (this.input.length <= 1) {
        const obj = {} as any;
        this.fields.forEach((field) => {
          obj[field.json_name] = "";
        });
        this.input = [obj];
      } else {
        this.input.splice(index, 1);
      }
      this.$emit("updateArray", this.input);
    },
    saveData() {
      this.$emit("updateArray", this.input);
    },
    compareObject(index: number): boolean {
      if (!this.dataArray) {
        return false;
      }

      if (this.input.length != this.dataArray?.length) {
        return false;
      }

      if (this.dataArray && this.dataArray.length < index) {
        return false;
      }

      const objOne = this.input[index];
      const objTwo = this.dataArray[index];
      return !isEqual(omitBy(objOne, isNil), omitBy(objTwo, isNil));
    },
  },
  watch: {
    dataArray: {
      handler(val) {
        this.input = cloneDeep(val) || [];
        if (this.input.length === 0) {
          const obj = {} as any;
          this.fields.forEach((field) => {
            obj[field.json_name] = "";
          });
          this.input = [obj];
          this.$emit("updateArray", this.input);
        }
      },
    },
  },
});

import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "grid justify-content-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_Column = _resolveComponent("Column")!
  const _component_DataTable = _resolveComponent("DataTable")!

  return (_openBlock(), _createBlock(_component_DataTable, {
    loading: _ctx.isLoadingLotInfo,
    value: _ctx.formattedLotInfo,
    breakpoint: "960px",
    responsiveLayout: "stack",
    paginator: "",
    rows: _ctx.rowsPerPage,
    rowsPerPageOptions: [10, 25, 50]
  }, {
    header: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_Button, {
          class: "ml-2",
          label: "Refresh",
          icon: "pi pi-refresh",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handleFetchLot(true)))
        })
      ])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_Column, {
        field: "lot_number",
        header: "Lot Number",
        sortable: "",
        headerClass: "column-header-left"
      }),
      _createVNode(_component_Column, {
        field: "revision",
        header: "Revision",
        sortable: ""
      }),
      _createVNode(_component_Column, {
        field: "hold",
        header: "Hold",
        sortable: "",
        headerClass: "column-header-left"
      }),
      _createVNode(_component_Column, {
        field: "inv_loc",
        header: "Inventory Location",
        sortable: "",
        headerClass: "column-header-left"
      }),
      _createVNode(_component_Column, {
        field: "on_hand",
        header: "On Hand",
        bodyStyle: "text-align: right",
        headerClass: "column-header-right",
        sortable: ""
      }),
      _createVNode(_component_Column, {
        field: "allocated",
        header: "Allocated",
        bodyStyle: "text-align: right",
        headerClass: "column-header-right",
        sortable: ""
      }),
      _createVNode(_component_Column, {
        field: "available",
        header: "Available",
        sortable: "",
        bodyStyle: "text-align: right",
        headerClass: "column-header-right"
      }),
      _createVNode(_component_Column, {
        field: "bin",
        header: "Bin",
        sortable: "",
        headerClass: "column-header-left"
      }),
      _createVNode(_component_Column, {
        field: "bin_qty",
        header: "Bin Quantity",
        sortable: "",
        bodyStyle: "text-align: right",
        headerClass: "column-header-right"
      })
    ]),
    _: 1
  }, 8, ["loading", "value", "rows"]))
}
import { createTextVNode as _createTextVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, vShow as _vShow, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "grid w-full p-0 m-0 mt-3" }
const _hoisted_2 = { class: "flex align-items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Column = _resolveComponent("Column")!
  const _component_Calendar = _resolveComponent("Calendar")!
  const _component_Button = _resolveComponent("Button")!
  const _component_MultiSelect = _resolveComponent("MultiSelect")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _component_Card = _resolveComponent("Card")!
  const _component_WorkOrderDetailsModal = _resolveComponent("WorkOrderDetailsModal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Card, { class: "col-12" }, {
      content: _withCtx(() => [
        _createVNode(_component_DataTable, {
          value: _ctx.getWorkOrders,
          loading: _ctx.isLoadingOrders,
          class: "p-datatable-sm",
          breakpoint: "960px",
          responsiveLayout: "stack",
          paginator: true,
          rows: _ctx.rowsPerPage,
          rowsPerPageOptions: [10, 25, 50, 100],
          filterDisplay: "menu",
          filters: _ctx.filters,
          "onUpdate:filters": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.filters) = $event)),
          sortOrder: _ctx.getSortOrderValue,
          sortField: _ctx.getSortByValue,
          onSort: _cache[7] || (_cache[7] = ($event: any) => (_ctx.sortData($event))),
          "onUpdate:rows": _cache[8] || (_cache[8] = ($event: any) => (_ctx.rowsPerPage = $event)),
          onRowClick: _ctx.rowClick,
          onPage: _ctx.onPage,
          onFilter: _ctx.handleFilter,
          lazy: true,
          totalRecords: _ctx.totalRecords,
          paginatorTemplate: "FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink  RowsPerPageDropdown CurrentPageReport",
          currentPageReportTemplate: "Total Records: {totalRecords}"
        }, {
          empty: _withCtx(() => _cache[9] || (_cache[9] = [
            _createTextVNode("No Items to display")
          ])),
          default: _withCtx(() => [
            _createVNode(_component_Column, {
              sortable: "",
              field: "wo_id",
              header: "WO Number"
            }, {
              body: _withCtx(({ data }) => [
                _createElementVNode("div", _hoisted_2, [
                  _createElementVNode("div", {
                    class: "dot mr-2",
                    style: _normalizeStyle({ 'background-color': _ctx.dotColor(data) })
                  }, null, 4),
                  _createElementVNode("span", null, _toDisplayString(data.wo_id), 1)
                ])
              ]),
              filter: _withCtx(({ filterModel }) => [
                _createVNode(_component_InputText, {
                  modelValue: filterModel.value,
                  "onUpdate:modelValue": ($event: any) => ((filterModel.value) = $event),
                  type: "text",
                  class: "p-column-filter",
                  placeholder: "Search by name"
                }, null, 8, ["modelValue", "onUpdate:modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_Column, {
              sortable: "",
              field: "part_no",
              header: "Part Number"
            }, {
              filter: _withCtx(({ filterModel }) => [
                _createVNode(_component_InputText, {
                  modelValue: filterModel.value,
                  "onUpdate:modelValue": ($event: any) => ((filterModel.value) = $event),
                  type: "text",
                  class: "p-column-filter",
                  placeholder: "Search by part number"
                }, null, 8, ["modelValue", "onUpdate:modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_Column, {
              sortable: "",
              field: "description",
              header: "Description",
              class: "font-semibold"
            }, {
              filter: _withCtx(({ filterModel }) => [
                _createVNode(_component_InputText, {
                  modelValue: filterModel.value,
                  "onUpdate:modelValue": ($event: any) => ((filterModel.value) = $event),
                  type: "text",
                  class: "p-column-filter",
                  placeholder: "Search by description"
                }, null, 8, ["modelValue", "onUpdate:modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_Column, {
              sortable: "",
              field: "req_date",
              header: "Request Date",
              class: "font-semibold",
              showFilterMatchModes: true,
              showFilterOperator: false
            }, {
              filter: _withCtx(() => [
                _createVNode(_component_Calendar, {
                  modelValue: _ctx.reqDateFilterValue,
                  onDateSelect: _cache[0] || (_cache[0] = ($event: any) => (_ctx.dateFilterChange($event, 'reqDate'))),
                  dateFormat: "mm/dd/yy",
                  showIcon: ""
                }, null, 8, ["modelValue"])
              ]),
              filterclear: _withCtx(() => [
                _createVNode(_component_Button, {
                  type: "button",
                  label: "Clear",
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.clearDateFilter('reqDate')))
                })
              ]),
              filterapply: _withCtx(() => [
                _createVNode(_component_Button, {
                  type: "button",
                  label: "Apply",
                  onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.applyDateFilter('reqDate')))
                })
              ]),
              _: 1
            }),
            _createVNode(_component_Column, {
              sortable: "",
              field: "rel_date",
              header: "Confirm Ship Date",
              class: "font-semibold",
              showFilterMatchModes: true,
              showFilterOperator: false
            }, {
              filter: _withCtx(() => [
                _createVNode(_component_Calendar, {
                  modelValue: _ctx.confirmDateFilterValue,
                  dateFormat: "mm-dd-yy",
                  onDateSelect: _cache[3] || (_cache[3] = ($event: any) => (_ctx.dateFilterChange($event, 'confirmDate'))),
                  showIcon: ""
                }, null, 8, ["modelValue"])
              ]),
              filterclear: _withCtx(() => [
                _createVNode(_component_Button, {
                  type: "button",
                  label: "Clear",
                  onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.clearDateFilter('confirmDate')))
                })
              ]),
              filterapply: _withCtx(() => [
                _createVNode(_component_Button, {
                  type: "button",
                  label: "Apply",
                  onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.applyDateFilter('confirmDate')))
                })
              ]),
              _: 1
            }),
            _createVNode(_component_Column, {
              sortable: "",
              field: "wo_qty",
              header: "Quantity",
              class: "font-semibold"
            }),
            _createVNode(_component_Column, {
              sortable: "",
              field: "status",
              header: "Status"
            }, {
              body: _withCtx(({ data }) => [
                _withDirectives(_createElementVNode("div", {
                  class: "font-semibold status-column",
                  style: _normalizeStyle({
                  color: _ctx.statusColor(data.status),
                  border: `1px solid ${_ctx.statusColor(data.status)}`,
                })
                }, _toDisplayString(_ctx.getWorkOrderStatusItems[data.status]), 5), [
                  [_vShow, data.status]
                ])
              ]),
              filter: _withCtx(({ filterModel }) => [
                _createVNode(_component_MultiSelect, {
                  modelValue: filterModel.value,
                  "onUpdate:modelValue": ($event: any) => ((filterModel.value) = $event),
                  options: _ctx.workOrderStatusListFormated,
                  optionValue: "id",
                  optionLabel: "label",
                  placeholder: "Status",
                  class: "p-column-filter"
                }, null, 8, ["modelValue", "onUpdate:modelValue", "options"])
              ]),
              _: 1
            }),
            _createVNode(_component_Column, {
              field: "workCenterType",
              header: "Work Center Type",
              class: "font-semibold"
            }, {
              body: _withCtx(({ data }) => [
                _createElementVNode("span", null, _toDisplayString(_ctx.getWorkCenter(data)), 1)
              ]),
              _: 1
            }),
            (_ctx.showSubassembly)
              ? (_openBlock(), _createBlock(_component_Column, {
                  key: 0,
                  field: "subassembly",
                  header: "Subassembly",
                  class: "font-semibold"
                }, {
                  body: _withCtx(({ data }) => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(data.subassembly, (id) => {
                      return (_openBlock(), _createElementBlock("span", { key: id }, [
                        _createTextVNode(_toDisplayString(id) + " ", 1),
                        _cache[10] || (_cache[10] = _createElementVNode("br", null, null, -1))
                      ]))
                    }), 128))
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["value", "loading", "rows", "filters", "sortOrder", "sortField", "onRowClick", "onPage", "onFilter", "totalRecords"])
      ]),
      _: 1
    }),
    _createVNode(_component_WorkOrderDetailsModal, {
      open: _ctx.showWorkOrderDetailsModal,
      selectedOrder: _ctx.selectedOrder,
      workCenterList: _ctx.workCenterList,
      onCloseModal: _ctx.closeWorkOrderDetailsModal,
      onSaveWorkOrder: _ctx.saveWorkOrder
    }, null, 8, ["open", "selectedOrder", "workCenterList", "onCloseModal", "onSaveWorkOrder"])
  ]))
}
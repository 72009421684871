import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, withCtx as _withCtx, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, withKeys as _withKeys, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "grid m-0 order-tab-content p-0" }
const _hoisted_2 = { class: "grid p-0 m-0 justify-content-end split-btn-container" }
const _hoisted_3 = {
  key: 0,
  class: "col-12 md:col-6 grid p-0 m-0 justify-content-left"
}
const _hoisted_4 = { class: "col-12 md:col-6 grid p-0 m-0 justify-content-center md:justify-content-end" }
const _hoisted_5 = { class: "col-12 md:col-5 lg:col-3 p-0 m-0 mr-1 flex justify-content-end" }
const _hoisted_6 = { class: "col-12 grid justify-content-center m-0 p-0" }
const _hoisted_7 = { class: "col-12 xl:col-5 grid p-0 pr-0 md:pr-1 m-0" }
const _hoisted_8 = { class: "col-12 md:col-6 lg:col-4 xl:col-6 p-1 m-0" }
const _hoisted_9 = { class: "p-inputgroup" }
const _hoisted_10 = { class: "col-12 md:col-6 lg:col-4 xl:col-6 p-1 m-0" }
const _hoisted_11 = { class: "p-inputgroup" }
const _hoisted_12 = { class: "col-12 md:col-6 lg:col-4 xl:col-6 p-1 m-0" }
const _hoisted_13 = { class: "p-inputgroup" }
const _hoisted_14 = { class: "col-12 grid xl:col-7 grid p-0 pr-0 md:pr-1 m-0" }
const _hoisted_15 = { class: "col-12 md:col-6 lg:col-4 p-1 m-0" }
const _hoisted_16 = { class: "p-inputgroup" }
const _hoisted_17 = { class: "col-12 md:col-6 lg:col-4 p-1 m-0" }
const _hoisted_18 = { class: "p-inputgroup" }
const _hoisted_19 = { class: "col-12 md:col-6 lg:col-4 p-1 m-0" }
const _hoisted_20 = { class: "p-inputgroup" }
const _hoisted_21 = { class: "col-12 md:col-6 lg:col-4 p-1 m-0" }
const _hoisted_22 = { class: "p-inputgroup" }
const _hoisted_23 = { class: "col-12 md:col-6 lg:col-4 p-1 m-0" }
const _hoisted_24 = { class: "p-inputgroup" }
const _hoisted_25 = { class: "col-12 md:col-6 lg:col-4 p-1 m-0" }
const _hoisted_26 = { class: "p-inputgroup" }
const _hoisted_27 = { class: "col-12 grid m-0 p-0" }
const _hoisted_28 = { class: "col-12 xl:col-5 grid p-0 pr-0 md:pr-1 m-0" }
const _hoisted_29 = { class: "col-12 md:col-6 lg:col-5 p-1 m-0 pb-0" }
const _hoisted_30 = { class: "p-inputgroup" }
const _hoisted_31 = { class: "col-12 md:col-6 lg:col-7 p-1 m-0 pb-0" }
const _hoisted_32 = { class: "field-checkbox" }
const _hoisted_33 = { class: "col-12 md:col-6 lg:col-5 p-1 m-0" }
const _hoisted_34 = { class: "p-inputgroup" }
const _hoisted_35 = { class: "col-12 md:col-6 lg:col-7 p-1 m-0 pb-0" }
const _hoisted_36 = { class: "p-inputgroup" }
const _hoisted_37 = { class: "p-input-icon-left" }
const _hoisted_38 = {
  key: 0,
  class: "pi pi-spin pi-spinner"
}
const _hoisted_39 = { class: "col-12 md:col-6 lg:col-5 p-1 m-0" }
const _hoisted_40 = { class: "p-inputgroup" }
const _hoisted_41 = { class: "col-12 md:col-6 lg:col-7 p-1 m-0 pb-0" }
const _hoisted_42 = { class: "p-inputgroup" }
const _hoisted_43 = { class: "p-input-icon-left" }
const _hoisted_44 = {
  key: 0,
  class: "pi pi-spin pi-spinner"
}
const _hoisted_45 = { class: "col-12 xl:col-5 grid p-0 pr-0 md:pr-1 m-0" }
const _hoisted_46 = { class: "col-12 md:col-6 lg:col-5 p-1 m-0 pb-0" }
const _hoisted_47 = { class: "p-inputgroup" }
const _hoisted_48 = { class: "col-12 md:col-6 lg:col-7 p-1 m-0 pr-0" }
const _hoisted_49 = { class: "p-inputgroup" }
const _hoisted_50 = { class: "p-input-icon-left" }
const _hoisted_51 = {
  key: 0,
  class: "pi pi-spin pi-spinner"
}
const _hoisted_52 = { class: "col-12 md:col-6 xl:col-5 p-1 m-0" }
const _hoisted_53 = { class: "p-inputgroup" }
const _hoisted_54 = { class: "col-12 md:col-6 xl:col-7 p-1 m-0 pb-0 pr-0" }
const _hoisted_55 = { class: "p-inputgroup" }
const _hoisted_56 = { class: "col-12 lg:col-6 xl:col-12 p-1 m-0 pr-0 lg:pr-1 xl:pr-0" }
const _hoisted_57 = { class: "p-inputgroup" }
const _hoisted_58 = { class: "col-12" }
const _hoisted_59 = { class: "col-12" }
const _hoisted_60 = { class: "grid" }
const _hoisted_61 = { class: "col-12 lg:col-3 pt-0 pb-0 mt-0 mb-0" }
const _hoisted_62 = { class: "p-inputgroup mt-2" }
const _hoisted_63 = { class: "p-inputgroup mt-2" }
const _hoisted_64 = { class: "p-inputgroup mt-2" }
const _hoisted_65 = { class: "p-inputgroup mt-2" }
const _hoisted_66 = { class: "p-inputgroup mt-2" }
const _hoisted_67 = { class: "col-12 lg:col-3 pt-0 pb-0 mt-0 mb-0" }
const _hoisted_68 = { class: "p-inputgroup mt-2" }
const _hoisted_69 = { class: "p-inputgroup mt-2" }
const _hoisted_70 = { class: "p-inputgroup mt-2" }
const _hoisted_71 = { class: "p-inputgroup mt-2" }
const _hoisted_72 = { class: "field-checkbox mt-2" }
const _hoisted_73 = { class: "p-inputgroup mt-2" }
const _hoisted_74 = { class: "p-inputgroup mt-2" }
const _hoisted_75 = { class: "col-12 lg:col-4 pt-0 pb-0 mt-0 mb-0" }
const _hoisted_76 = { class: "grid col-12" }
const _hoisted_77 = {
  key: 0,
  class: "col-6 lg:col-4"
}
const _hoisted_78 = { class: "p-inputgroup mt-2 pl-1" }
const _hoisted_79 = {
  key: 0,
  class: "p-0 m-0 pl-2",
  style: { color: 'red' }
}
const _hoisted_80 = {
  key: 1,
  class: "p-0 m-0 pl-2",
  style: { color: 'red' }
}
const _hoisted_81 = { class: "grid col-12" }
const _hoisted_82 = { class: "grid col-12" }
const _hoisted_83 = { class: "col-12 grid p-0 m-0" }
const _hoisted_84 = { class: "hidden lg:inline-flex col-1 p-0 m-0 pr-1" }
const _hoisted_85 = { class: "p-inputgroup pt-0 pb-0 mt-1 col-12 lg:hidden" }
const _hoisted_86 = { class: "hidden lg:inline-flex col-2 p-0 m-0 pl-1 pr-1" }
const _hoisted_87 = { class: "p-inputgroup pt-0 pb-0 mt-1 col-12 lg:hidden" }
const _hoisted_88 = { class: "hidden lg:inline-flex col-2 p-0 m-0 pl-1 pr-1" }
const _hoisted_89 = { class: "p-inputgroup pt-0 pb-0 mt-1 col-12 lg:hidden" }
const _hoisted_90 = { class: "hidden lg:inline-flex col-2 p-0 m-0 pl-1 pr-1" }
const _hoisted_91 = { class: "p-inputgroup pt-0 pb-0 mt-1 col-12 lg:hidden" }
const _hoisted_92 = { class: "hidden lg:inline-flex col-2 p-0 m-0 pl-1 pr-1" }
const _hoisted_93 = { class: "p-inputgroup pt-0 pb-0 mt-1 col-12 lg:hidden" }
const _hoisted_94 = { class: "hidden lg:inline-flex col-2 p-0 m-0 pl-1 pr-1" }
const _hoisted_95 = { class: "p-inputgroup pt-0 pb-0 mt-1 col-12 lg:hidden" }
const _hoisted_96 = { class: "hidden lg:inline-flex col-1 p-0 m-0 pl-1" }
const _hoisted_97 = { class: "p-inputgroup pt-0 pb-0 mt-1 col-12 lg:hidden" }
const _hoisted_98 = { class: "col-6" }
const _hoisted_99 = { key: 0 }
const _hoisted_100 = { class: "field" }
const _hoisted_101 = {
  key: 0,
  class: "p-error"
}
const _hoisted_102 = {
  key: 1,
  class: "p-error"
}
const _hoisted_103 = { class: "field-checkbox mt-2 mb-0" }
const _hoisted_104 = { class: "field" }
const _hoisted_105 = {
  key: 0,
  class: "p-error"
}
const _hoisted_106 = {
  key: 1,
  class: "p-error"
}
const _hoisted_107 = { class: "field-checkbox mt-2 mb-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputSwitch = _resolveComponent("InputSwitch")!
  const _component_SplitButton = _resolveComponent("SplitButton")!
  const _component_Divider = _resolveComponent("Divider")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Calendar = _resolveComponent("Calendar")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_AccordionTab = _resolveComponent("AccordionTab")!
  const _component_CheckBox = _resolveComponent("CheckBox")!
  const _component_SearchCustomer = _resolveComponent("SearchCustomer")!
  const _component_ContactAutocomplete = _resolveComponent("ContactAutocomplete")!
  const _component_CustomFields = _resolveComponent("CustomFields")!
  const _component_RepDataTable = _resolveComponent("RepDataTable")!
  const _component_Textarea = _resolveComponent("Textarea")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Column = _resolveComponent("Column")!
  const _component_MultiSelect = _resolveComponent("MultiSelect")!
  const _component_InputNumber = _resolveComponent("InputNumber")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _component_Signature = _resolveComponent("Signature")!
  const _component_CustomAccordion = _resolveComponent("CustomAccordion")!
  const _component_Card = _resolveComponent("Card")!
  const _component_Dialog = _resolveComponent("Dialog")!
  const _component_SearchPartDialog = _resolveComponent("SearchPartDialog")!
  const _component_EmailFileDialog = _resolveComponent("EmailFileDialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_Card, { class: "col-12 pt-0 border-round-3xl tab-card-content" }, {
        content: _withCtx(() => [
          _createElementVNode("div", _hoisted_2, [
            (!_ctx.productionEnvironment)
              ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                  _createVNode(_component_InputSwitch, {
                    modelValue: _ctx.allowValidation,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.allowValidation) = $event)),
                    inputId: "validationSwitch"
                  }, null, 8, ["modelValue"]),
                  _cache[73] || (_cache[73] = _createElementVNode("label", {
                    for: "validationSwitch",
                    style: {"padding":"4px"}
                  }, [
                    _createElementVNode("strong", null, "Validate")
                  ], -1))
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                (_ctx.readOnly)
                  ? (_openBlock(), _createBlock(_component_SplitButton, {
                      key: 0,
                      label: 
                  _ctx.getPDFBtnTitle(
                    _ctx.currentOrder !== null && _ctx.currentOrder.so_id
                      ? _ctx.currentOrder.so_id
                      : '',
                  )
                ,
                      icon: 
                  _ctx.handlePDFIcons(
                    _ctx.currentOrder !== null && _ctx.currentOrder.so_id
                      ? _ctx.currentOrder.so_id
                      : '',
                  )
                ,
                      loading: _ctx.submitLoading,
                      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.getSaleOrderPDF())),
                      model: [
                  {
                    icon: 'pi pi-envelope',
                    label: 'Email PDF',
                    command: () => _ctx.handleEmailPDF(),
                  },
                ]
                    }, null, 8, ["label", "icon", "loading", "model"]))
                  : (_openBlock(), _createBlock(_component_SplitButton, {
                      key: 1,
                      label: "Save",
                      icon: "pi pi-check",
                      loading: _ctx.submitLoading,
                      onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.submit())),
                      model: [
                  {
                    icon: 'pi pi-copy',
                    label: 'Copy Quote',
                    command: () => _ctx.openAddExistingQuoteDialog(),
                  },
                  {
                    icon: 'pi pi-copy',
                    label: 'Copy Order',
                    command: () => _ctx.openAddExistingOrderDialog(),
                  },
                ]
                    }, null, 8, ["loading", "model"]))
              ])
            ])
          ]),
          _createVNode(_component_CustomAccordion, { activeIndex: [0, 1, 2, 3, 4] }, {
            "accordion-content": _withCtx(() => [
              _createVNode(_component_AccordionTab, { header: "Order" }, {
                header: _withCtx(() => [
                  _createVNode(_component_Divider, {
                    type: "dashed",
                    class: "ml-3"
                  })
                ]),
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_6, [
                    _createElementVNode("div", _hoisted_7, [
                      _createElementVNode("div", _hoisted_8, [
                        _createElementVNode("div", _hoisted_9, [
                          _cache[74] || (_cache[74] = _createElementVNode("small", { class: "p-inputgroup-addon" }, "Order ID", -1)),
                          _createVNode(_component_InputText, {
                            modelValue: _ctx.currentId,
                            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.currentId) = $event)),
                            type: "text",
                            class: "text-sm",
                            disabled: true
                          }, null, 8, ["modelValue"])
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_10, [
                        _createElementVNode("div", _hoisted_11, [
                          _cache[75] || (_cache[75] = _createElementVNode("small", { class: "p-inputgroup-addon" }, "Order Date", -1)),
                          _createVNode(_component_Calendar, {
                            modelValue: _ctx.currentOrder.date,
                            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.currentOrder.date) = $event)),
                            showIcon: true,
                            dateFormat: "mm-dd-yy",
                            inputClass: "text-sm",
                            onDateSelect: _cache[5] || (_cache[5] = ($event: any) => (_ctx.formatDate($event, 'ORDER_DATE'))),
                            disabled: _ctx.readOnly,
                            showButtonBar: true
                          }, null, 8, ["modelValue", "disabled"])
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_12, [
                        _createElementVNode("div", _hoisted_13, [
                          _cache[76] || (_cache[76] = _createElementVNode("small", { class: "p-inputgroup-addon" }, "Load", -1)),
                          _createVNode(_component_InputText, {
                            modelValue: _ctx.currentOrder.load,
                            "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.currentOrder.load) = $event)),
                            type: "text",
                            class: "text_-sm",
                            disabled: _ctx.readOnly
                          }, null, 8, ["modelValue", "disabled"])
                        ])
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_14, [
                      _createElementVNode("div", _hoisted_15, [
                        _createElementVNode("div", _hoisted_16, [
                          _cache[77] || (_cache[77] = _createElementVNode("small", { class: "p-inputgroup-addon" }, "Company", -1)),
                          _createVNode(_component_Dropdown, {
                            modelValue: _ctx.currentOrder.co_code,
                            "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.currentOrder.co_code) = $event)),
                            options: _ctx.customerInquiry.coCodes,
                            optionLabel: "name",
                            optionValue: "code",
                            disabled: _ctx.readOnly,
                            showClear: !_ctx.readOnly,
                            onChange: _cache[8] || (_cache[8] = ($event: any) => (_ctx.getTypeControls($event.value)))
                          }, null, 8, ["modelValue", "options", "disabled", "showClear"])
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_17, [
                        _createElementVNode("div", _hoisted_18, [
                          _cache[78] || (_cache[78] = _createElementVNode("small", { class: "p-inputgroup-addon" }, "Type", -1)),
                          _createVNode(_component_Dropdown, {
                            modelValue: _ctx.currentOrder.type,
                            "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.currentOrder.type) = $event)),
                            options: _ctx.getTypes,
                            optionLabel: "so_type_code_description",
                            optionValue: "valid_so_type",
                            disabled: _ctx.readOnly,
                            showClear: !_ctx.readOnly
                          }, null, 8, ["modelValue", "options", "disabled", "showClear"])
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_19, [
                        _createElementVNode("div", _hoisted_20, [
                          _cache[79] || (_cache[79] = _createElementVNode("small", { class: "p-inputgroup-addon" }, "Book Date", -1)),
                          _createVNode(_component_Calendar, {
                            modelValue: _ctx.currentOrder.book_date,
                            "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.currentOrder.book_date) = $event)),
                            showIcon: true,
                            dateFormat: "mm-dd-yy",
                            inputClass: "text-sm",
                            onDateSelect: _cache[11] || (_cache[11] = ($event: any) => (_ctx.formatDate($event, 'BOOK_DATE'))),
                            disabled: _ctx.readOnly,
                            showButtonBar: true
                          }, null, 8, ["modelValue", "disabled"])
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_21, [
                        _createElementVNode("div", _hoisted_22, [
                          _cache[80] || (_cache[80] = _createElementVNode("small", { class: "p-inputgroup-addon" }, "Plan Group", -1)),
                          _createVNode(_component_Dropdown, {
                            modelValue: _ctx.currentOrder.plan_group,
                            "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.currentOrder.plan_group) = $event)),
                            options: _ctx.customerInquiry.planGroupCodes,
                            optionLabel: "plan_group",
                            optionValue: "plan_group",
                            disabled: _ctx.readOnly,
                            showClear: !_ctx.readOnly
                          }, null, 8, ["modelValue", "options", "disabled", "showClear"])
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_23, [
                        _createElementVNode("div", _hoisted_24, [
                          _cache[81] || (_cache[81] = _createElementVNode("small", { class: "p-inputgroup-addon" }, "Quote", -1)),
                          _createVNode(_component_InputText, {
                            type: "text",
                            class: "text-sm",
                            modelValue: _ctx.currentOrder.quote_no,
                            "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.currentOrder.quote_no) = $event)),
                            disabled: _ctx.readOnly
                          }, null, 8, ["modelValue", "disabled"])
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_25, [
                        _createElementVNode("div", _hoisted_26, [
                          _cache[82] || (_cache[82] = _createElementVNode("small", { class: "p-inputgroup-addon" }, "Prev Date", -1)),
                          _createVNode(_component_Calendar, {
                            modelValue: this.currentOrder.last_date,
                            "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((this.currentOrder.last_date) = $event)),
                            showIcon: true,
                            dateFormat: "mm-dd-yy",
                            inputClass: "text-sm",
                            onDateSelect: _cache[15] || (_cache[15] = ($event: any) => (_ctx.formatDate($event, 'LAST_DATE'))),
                            disabled: _ctx.readOnly,
                            showButtonBar: true
                          }, null, 8, ["modelValue", "disabled"])
                        ])
                      ])
                    ])
                  ])
                ]),
                _: 1
              }),
              _createVNode(_component_AccordionTab, { header: "PO" }, {
                header: _withCtx(() => [
                  _createVNode(_component_Divider, {
                    type: "dashed",
                    class: "ml-3"
                  })
                ]),
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_27, [
                    _createElementVNode("div", _hoisted_28, [
                      _createElementVNode("div", _hoisted_29, [
                        _createElementVNode("div", _hoisted_30, [
                          _cache[83] || (_cache[83] = _createElementVNode("small", { class: "p-inputgroup-addon" }, "PO Number", -1)),
                          _createVNode(_component_InputText, {
                            type: "text",
                            class: "text-sm",
                            modelValue: _ctx.currentOrder.po_number,
                            "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.currentOrder.po_number) = $event)),
                            disabled: _ctx.readOnly
                          }, null, 8, ["modelValue", "disabled"])
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_31, [
                        _createElementVNode("div", _hoisted_32, [
                          _createVNode(_component_CheckBox, {
                            id: "partial-shipments",
                            modelValue: _ctx.currentOrder.partials_ok,
                            "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.currentOrder.partials_ok) = $event)),
                            name: "Partial Shipments ok",
                            trueValue: "Y",
                            falseValue: null,
                            binary: true,
                            class: "field-checkbox pl-2 pt-3 w-1",
                            disabled: _ctx.readOnly
                          }, null, 8, ["modelValue", "disabled"]),
                          _cache[84] || (_cache[84] = _createElementVNode("label", {
                            for: "partial-shipments",
                            class: "text-sm pl-2 pt-1"
                          }, "Partial Shipments OK", -1))
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_33, [
                        _createElementVNode("div", _hoisted_34, [
                          _cache[85] || (_cache[85] = _createElementVNode("small", { class: "p-inputgroup-addon" }, "Customer", -1)),
                          _createVNode(_component_SearchCustomer, {
                            inputValue: _ctx.currentOrder.sold_to,
                            onItemSelect: _ctx.setSoldTo
                          }, null, 8, ["inputValue", "onItemSelect"])
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_35, [
                        _createElementVNode("div", _hoisted_36, [
                          _createElementVNode("span", _hoisted_37, [
                            (_ctx.isLoadingCustomer)
                              ? (_openBlock(), _createElementBlock("i", _hoisted_38))
                              : _createCommentVNode("", true)
                          ]),
                          _createVNode(_component_InputText, {
                            modelValue: _ctx.currentOrder.cust_name,
                            "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.currentOrder.cust_name) = $event)),
                            type: "text",
                            class: "text-sm",
                            disabled: true
                          }, null, 8, ["modelValue"])
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_39, [
                        _createElementVNode("div", _hoisted_40, [
                          _cache[86] || (_cache[86] = _createElementVNode("small", { class: "p-inputgroup-addon" }, "Bill To", -1)),
                          _createVNode(_component_SearchCustomer, {
                            inputValue: _ctx.currentOrder.bill_to,
                            onItemSelect: _ctx.setBillTo
                          }, null, 8, ["inputValue", "onItemSelect"])
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_41, [
                        _createElementVNode("div", _hoisted_42, [
                          _createElementVNode("span", _hoisted_43, [
                            (_ctx.isBillToLoading)
                              ? (_openBlock(), _createElementBlock("i", _hoisted_44))
                              : _createCommentVNode("", true)
                          ]),
                          _createVNode(_component_InputText, {
                            modelValue: _ctx.currentOrder.bill_to_name,
                            "onUpdate:modelValue": _cache[19] || (_cache[19] = ($event: any) => ((_ctx.currentOrder.bill_to_name) = $event)),
                            type: "text",
                            class: "text-sm",
                            disabled: true
                          }, null, 8, ["modelValue"])
                        ])
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_45, [
                      _createElementVNode("div", _hoisted_46, [
                        _createElementVNode("div", _hoisted_47, [
                          _cache[87] || (_cache[87] = _createElementVNode("small", { class: "p-inputgroup-addon" }, "Contact", -1)),
                          _createVNode(_component_ContactAutocomplete, {
                            "selected-id": _ctx.currentOrder.contact_id,
                            modelValue: _ctx.currentOrder.contact_id,
                            "onUpdate:modelValue": _cache[20] || (_cache[20] = ($event: any) => ((_ctx.currentOrder.contact_id) = $event)),
                            disabled: _ctx.readOnly,
                            onItemSelect: _cache[21] || (_cache[21] = ($event: any) => (_ctx.handleContactSelected($event)))
                          }, null, 8, ["selected-id", "modelValue", "disabled"])
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_48, [
                        _createElementVNode("div", _hoisted_49, [
                          _createElementVNode("span", _hoisted_50, [
                            (_ctx.isContactLoading)
                              ? (_openBlock(), _createElementBlock("i", _hoisted_51))
                              : _createCommentVNode("", true)
                          ]),
                          _createVNode(_component_InputText, {
                            modelValue: _ctx.currentOrder.contact,
                            "onUpdate:modelValue": _cache[22] || (_cache[22] = ($event: any) => ((_ctx.currentOrder.contact) = $event)),
                            type: "text",
                            class: "text-sm",
                            disabled: _ctx.readOnly
                          }, null, 8, ["modelValue", "disabled"])
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_52, [
                        _createElementVNode("div", _hoisted_53, [
                          _cache[88] || (_cache[88] = _createElementVNode("small", { class: "p-inputgroup-addon" }, "Telephone", -1)),
                          _createVNode(_component_InputText, {
                            type: "text",
                            class: "text-sm",
                            modelValue: _ctx.currentOrder.phone,
                            "onUpdate:modelValue": _cache[23] || (_cache[23] = ($event: any) => ((_ctx.currentOrder.phone) = $event)),
                            disabled: _ctx.readOnly
                          }, null, 8, ["modelValue", "disabled"])
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_54, [
                        _createElementVNode("div", _hoisted_55, [
                          _cache[89] || (_cache[89] = _createElementVNode("small", { class: "p-inputgroup-addon" }, "Extension", -1)),
                          _createVNode(_component_InputText, {
                            type: "text",
                            class: "text-sm",
                            modelValue: _ctx.currentOrder.ext,
                            "onUpdate:modelValue": _cache[24] || (_cache[24] = ($event: any) => ((_ctx.currentOrder.ext) = $event)),
                            disabled: _ctx.readOnly
                          }, null, 8, ["modelValue", "disabled"])
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_56, [
                        _createElementVNode("div", _hoisted_57, [
                          _cache[90] || (_cache[90] = _createElementVNode("small", { class: "p-inputgroup-addon" }, "Email", -1)),
                          _createVNode(_component_InputText, {
                            type: "text",
                            class: "text-sm",
                            modelValue: _ctx.currentOrder.contact_email,
                            "onUpdate:modelValue": _cache[25] || (_cache[25] = ($event: any) => ((_ctx.currentOrder.contact_email) = $event)),
                            disabled: _ctx.readOnly
                          }, null, 8, ["modelValue", "disabled"])
                        ])
                      ])
                    ])
                  ])
                ]),
                _: 1
              }),
              (_ctx.customFields && _ctx.customFields.length > 0)
                ? (_openBlock(), _createBlock(_component_AccordionTab, {
                    key: 0,
                    header: "Custom Fields"
                  }, {
                    header: _withCtx(() => [
                      _createVNode(_component_Divider, {
                        type: "dashed",
                        class: "ml-3"
                      })
                    ]),
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_58, [
                        _createVNode(_component_CustomFields, {
                          data: _ctx.currentOrder,
                          customFields: _ctx.customFields,
                          readOnly: _ctx.readOnly,
                          onUpdateCustomFields: _ctx.updateCustomFields
                        }, null, 8, ["data", "customFields", "readOnly", "onUpdateCustomFields"])
                      ])
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              _createVNode(_component_AccordionTab, { header: "Price" }, {
                header: _withCtx(() => [
                  _createVNode(_component_Divider, {
                    type: "dashed",
                    class: "ml-3"
                  })
                ]),
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_59, [
                    _createElementVNode("div", _hoisted_60, [
                      _createElementVNode("div", _hoisted_61, [
                        _createElementVNode("div", _hoisted_62, [
                          _cache[91] || (_cache[91] = _createElementVNode("small", { class: "p-inputgroup-addon" }, "Price Code", -1)),
                          _createVNode(_component_Dropdown, {
                            modelValue: _ctx.currentOrder.cust_code,
                            "onUpdate:modelValue": _cache[26] || (_cache[26] = ($event: any) => ((_ctx.currentOrder.cust_code) = $event)),
                            options: _ctx.customerInquiry.pricingCodes,
                            optionLabel: "description",
                            optionValue: "code",
                            disabled: _ctx.readOnly,
                            showClear: !_ctx.readOnly
                          }, null, 8, ["modelValue", "options", "disabled", "showClear"])
                        ]),
                        _createElementVNode("div", _hoisted_63, [
                          _cache[92] || (_cache[92] = _createElementVNode("small", { class: "p-inputgroup-addon" }, "Pay Terms", -1)),
                          _createVNode(_component_Dropdown, {
                            modelValue: _ctx.currentOrder.terms_code,
                            "onUpdate:modelValue": _cache[27] || (_cache[27] = ($event: any) => ((_ctx.currentOrder.terms_code) = $event)),
                            options: _ctx.customerInquiry.terms,
                            optionLabel: "desc",
                            optionValue: "terms_id",
                            disabled: _ctx.readOnly,
                            showClear: !_ctx.readOnly
                          }, null, 8, ["modelValue", "options", "disabled", "showClear"])
                        ]),
                        _createElementVNode("div", _hoisted_64, [
                          _cache[93] || (_cache[93] = _createElementVNode("small", { class: "p-inputgroup-addon" }, "Resale ID", -1)),
                          _createVNode(_component_InputText, {
                            type: "text",
                            class: "text-sm",
                            modelValue: _ctx.currentOrder.resale_number,
                            "onUpdate:modelValue": _cache[28] || (_cache[28] = ($event: any) => ((_ctx.currentOrder.resale_number) = $event)),
                            disabled: _ctx.readOnly
                          }, null, 8, ["modelValue", "disabled"])
                        ]),
                        _createElementVNode("div", _hoisted_65, [
                          _cache[94] || (_cache[94] = _createElementVNode("small", { class: "p-inputgroup-addon" }, "Discount %", -1)),
                          _createVNode(_component_InputText, {
                            type: "text",
                            class: "text-sm",
                            modelValue: _ctx.discount,
                            "onUpdate:modelValue": _cache[29] || (_cache[29] = ($event: any) => ((_ctx.discount) = $event)),
                            disabled: _ctx.readOnly
                          }, null, 8, ["modelValue", "disabled"])
                        ]),
                        _createElementVNode("div", _hoisted_66, [
                          _cache[95] || (_cache[95] = _createElementVNode("small", { class: "p-inputgroup-addon" }, "Schedule", -1)),
                          _createVNode(_component_Calendar, {
                            showIcon: true,
                            modelValue: _ctx.scheduleDate,
                            "onUpdate:modelValue": _cache[30] || (_cache[30] = ($event: any) => ((_ctx.scheduleDate) = $event)),
                            dateFormat: "mm-dd-yy",
                            manualInput: false,
                            onDateSelect: _cache[31] || (_cache[31] = ($event: any) => (_ctx.formatDate($event, 'SCHEDULE_DATE'))),
                            disabled: _ctx.readOnly
                          }, null, 8, ["modelValue", "disabled"])
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_67, [
                        _createElementVNode("div", _hoisted_68, [
                          _cache[96] || (_cache[96] = _createElementVNode("small", { class: "p-inputgroup-addon" }, "Hold", -1)),
                          _createVNode(_component_Dropdown, {
                            options: _ctx.customerInquiry.holdCodes,
                            optionLabel: "hold_code_description",
                            optionValue: "hold_code",
                            modelValue: _ctx.currentOrder.hold,
                            "onUpdate:modelValue": _cache[32] || (_cache[32] = ($event: any) => ((_ctx.currentOrder.hold) = $event)),
                            disabled: _ctx.readOnly,
                            showClear: !_ctx.readOnly
                          }, null, 8, ["options", "modelValue", "disabled", "showClear"])
                        ]),
                        _createElementVNode("div", _hoisted_69, [
                          _cache[97] || (_cache[97] = _createElementVNode("small", { class: "p-inputgroup-addon" }, "Hold Date", -1)),
                          _createVNode(_component_Calendar, {
                            showIcon: true,
                            modelValue: _ctx.currentOrder.hold_date,
                            "onUpdate:modelValue": _cache[33] || (_cache[33] = ($event: any) => ((_ctx.currentOrder.hold_date) = $event)),
                            dateFormat: "mm-dd-yy",
                            manualInput: false,
                            onDateSelect: _cache[34] || (_cache[34] = ($event: any) => (_ctx.formatDate($event, 'HOLD_DATE'))),
                            disabled: _ctx.readOnly
                          }, null, 8, ["modelValue", "disabled"])
                        ]),
                        _createElementVNode("div", _hoisted_70, [
                          _cache[98] || (_cache[98] = _createElementVNode("small", { class: "p-inputgroup-addon" }, "Status", -1)),
                          _createVNode(_component_Dropdown, {
                            modelValue: _ctx.currentOrder.status,
                            "onUpdate:modelValue": _cache[35] || (_cache[35] = ($event: any) => ((_ctx.currentOrder.status) = $event)),
                            options: _ctx.status,
                            optionLabel: "description",
                            optionValue: "value",
                            disabled: _ctx.readOnly,
                            showClear: !_ctx.readOnly
                          }, null, 8, ["modelValue", "options", "disabled", "showClear"])
                        ]),
                        _createElementVNode("div", _hoisted_71, [
                          _cache[99] || (_cache[99] = _createElementVNode("small", { class: "p-inputgroup-addon" }, "Close Date", -1)),
                          _createVNode(_component_Calendar, {
                            showIcon: true,
                            modelValue: _ctx.currentOrder.close_date,
                            "onUpdate:modelValue": _cache[36] || (_cache[36] = ($event: any) => ((_ctx.currentOrder.close_date) = $event)),
                            dateFormat: "mm-dd-yy",
                            manualInput: false,
                            onDateSelect: _cache[37] || (_cache[37] = ($event: any) => (_ctx.formatDate($event, 'CLOSE_DATE'))),
                            disabled: _ctx.readOnly
                          }, null, 8, ["modelValue", "disabled"])
                        ]),
                        _createElementVNode("div", _hoisted_72, [
                          _createVNode(_component_CheckBox, {
                            id: "pending-ship",
                            modelValue: _ctx.currentOrder.pending_ship,
                            "onUpdate:modelValue": _cache[38] || (_cache[38] = ($event: any) => ((_ctx.currentOrder.pending_ship) = $event)),
                            name: "Pending ship",
                            trueValue: "Y",
                            falseValue: null,
                            binary: true,
                            class: "field-checkbox m-2 mb-0"
                          }, null, 8, ["modelValue"]),
                          _cache[100] || (_cache[100] = _createElementVNode("label", {
                            for: "pending-ship",
                            class: "text-sm pt-2 pl-1"
                          }, "Pending Ship", -1))
                        ]),
                        _createElementVNode("div", _hoisted_73, [
                          _cache[101] || (_cache[101] = _createElementVNode("small", { class: "p-inputgroup-addon" }, "Curr Code", -1)),
                          _createVNode(_component_InputText, {
                            type: "text",
                            class: "text-sm",
                            modelValue: _ctx.currentOrder.currency_code,
                            "onUpdate:modelValue": _cache[39] || (_cache[39] = ($event: any) => ((_ctx.currentOrder.currency_code) = $event)),
                            disabled: _ctx.readOnly
                          }, null, 8, ["modelValue", "disabled"])
                        ]),
                        _createElementVNode("div", _hoisted_74, [
                          _cache[102] || (_cache[102] = _createElementVNode("small", { class: "p-inputgroup-addon" }, "Exch Rate", -1)),
                          _createVNode(_component_InputText, {
                            type: "text",
                            class: "text-sm",
                            modelValue: _ctx.currentOrder.exchange_rate,
                            "onUpdate:modelValue": _cache[40] || (_cache[40] = ($event: any) => ((_ctx.currentOrder.exchange_rate) = $event)),
                            disabled: _ctx.readOnly
                          }, null, 8, ["modelValue", "disabled"])
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_75, [
                        _createVNode(_component_RepDataTable, {
                          reps: _ctx.currentOrder.rep_items,
                          onUpdateReps: _ctx.updateReps
                        }, null, 8, ["reps", "onUpdateReps"]),
                        _cache[103] || (_cache[103] = _createElementVNode("div", { class: "p-inputgroup mt-3 mb-2 font-semibold" }, " Notes ", -1)),
                        _createVNode(_component_Textarea, {
                          rows: "8",
                          modelValue: _ctx.currentOrder.notes,
                          "onUpdate:modelValue": _cache[41] || (_cache[41] = ($event: any) => ((_ctx.currentOrder.notes) = $event)),
                          placeholder: "Enter a note here...",
                          class: "textarea mt-2 text-sm",
                          disabled: _ctx.readOnly
                        }, null, 8, ["modelValue", "disabled"])
                      ])
                    ])
                  ])
                ]),
                _: 1
              }),
              _createVNode(_component_AccordionTab, { header: "Line" }, {
                header: _withCtx(() => [
                  _createVNode(_component_Divider, {
                    type: "dashed",
                    class: "ml-3"
                  })
                ]),
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_76, [
                    (!_ctx.readOnly)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_77, [
                          _createElementVNode("div", _hoisted_78, [
                            _createVNode(_component_Button, {
                              disabled: _ctx.disablePartSearch,
                              label: "Search Parts",
                              icon: "pi pi-search",
                              onClick: _cache[42] || (_cache[42] = () => (_ctx.showSearchPartDialog = true))
                            }, null, 8, ["disabled"])
                          ]),
                          (!_ctx.newSalesOrderCust && !_ctx.currentOrder.co_code)
                            ? (_openBlock(), _createElementBlock("small", _hoisted_79, "Select a company code to enable search"))
                            : (
                      !_ctx.newSalesOrderCust &&
                      _ctx.requireCustomerPartsSearch &&
                      !_ctx.currentOrder.sold_to
                    )
                              ? (_openBlock(), _createElementBlock("small", _hoisted_80, " Select a customer to enable search "))
                              : _createCommentVNode("", true)
                        ]))
                      : _createCommentVNode("", true)
                  ]),
                  _createElementVNode("div", _hoisted_81, [
                    _createVNode(_component_DataTable, {
                      scrollable: "",
                      value: _ctx.currentOrder.lis_items
                    }, {
                      empty: _withCtx(() => _cache[104] || (_cache[104] = [
                        _createTextVNode(" No line items to show. ")
                      ])),
                      default: _withCtx(() => [
                        _createVNode(_component_Column, {
                          field: "lis",
                          header: "Line",
                          "body-style": "min-width: 2rem; max-width: 2rem; padding: 4px;"
                        }, {
                          body: _withCtx((slotProps) => [
                            _createVNode(_component_InputText, {
                              type: "text",
                              class: "text-sm w-full",
                              modelValue: slotProps.data.lis,
                              "onUpdate:modelValue": ($event: any) => ((slotProps.data.lis) = $event),
                              disabled: _ctx.readOnly
                            }, null, 8, ["modelValue", "onUpdate:modelValue", "disabled"])
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_Column, {
                          header: "Part Number",
                          "body-style": "padding: 4px; min-width: 10rem;"
                        }, {
                          body: _withCtx((slotProps) => [
                            _createVNode(_component_InputText, {
                              type: "text",
                              class: "text-sm w-full",
                              modelValue: slotProps.data.li_parts,
                              "onUpdate:modelValue": ($event: any) => ((slotProps.data.li_parts) = $event),
                              disabled: _ctx.readOnly
                            }, null, 8, ["modelValue", "onUpdate:modelValue", "disabled"])
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_Column, {
                          header: "Description",
                          "body-style": "padding: 4px; min-width: 20rem;"
                        }, {
                          body: _withCtx((slotProps) => [
                            _createVNode(_component_InputText, {
                              type: "text",
                              class: "text-sm w-full",
                              modelValue: slotProps.data.wrap_desc,
                              "onUpdate:modelValue": ($event: any) => ((slotProps.data.wrap_desc) = $event),
                              disabled: _ctx.readOnly
                            }, null, 8, ["modelValue", "onUpdate:modelValue", "disabled"])
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_Column, {
                          header: "Date",
                          "body-style": "min-width: 7rem; max-width: 7rem; padding: 4px;"
                        }, {
                          body: _withCtx((slotProps) => [
                            _createVNode(_component_Calendar, {
                              showIcon: true,
                              dateFormat: "mm-dd-yy",
                              inputClass: "text-sm single-calendar-field w-full",
                              disabled: _ctx.readOnly,
                              modelValue: _ctx.getLineItemDate(slotProps.data),
                              onDateSelect: ($event: any) => (_ctx.setLineItemDate($event, slotProps.data))
                            }, null, 8, ["disabled", "modelValue", "onDateSelect"])
                          ]),
                          _: 1
                        }),
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.customFieldsLineItems, (field) => {
                          return (_openBlock(), _createBlock(_component_Column, {
                            field: field.json_name,
                            header: field.display_name,
                            "body-style": "padding: 4px;",
                            key: field.field_no
                          }, {
                            body: _withCtx((slotProps) => [
                              (field.conv && field.conv[0] === 'D')
                                ? (_openBlock(), _createBlock(_component_Calendar, {
                                    key: 0,
                                    modelValue: slotProps.data.custom_fields[field.json_name],
                                    "onUpdate:modelValue": ($event: any) => ((slotProps.data.custom_fields[field.json_name]) = $event),
                                    showIcon: true,
                                    dateFormat: "mm-dd-yy",
                                    inputClass: "text-lg",
                                    onDateSelect: ($event: any) => (
                          _ctx.setCustomFieldData($event, slotProps.data, field)
                        )
                                  }, null, 8, ["modelValue", "onUpdate:modelValue", "onDateSelect"]))
                                : (
                          field.valid_value_items &&
                          field.valid_value_items.length > 0 &&
                          field.multi_line === 'Y'
                        )
                                  ? (_openBlock(), _createBlock(_component_MultiSelect, {
                                      key: 1,
                                      modelValue: slotProps.data.custom_fields[field.json_name],
                                      "onUpdate:modelValue": [($event: any) => ((slotProps.data.custom_fields[field.json_name]) = $event), ($event: any) => (
                          _ctx.setCustomFieldData($event, slotProps.data, field)
                        )],
                                      options: field.valid_value_items,
                                      optionLabel: "valid_desc",
                                      optionValue: "valid_value",
                                      placeholder: `Select ${field.display_name}`
                                    }, null, 8, ["modelValue", "onUpdate:modelValue", "options", "placeholder"]))
                                  : (
                          field.valid_value_items &&
                          field.valid_value_items.length > 0
                        )
                                    ? (_openBlock(), _createBlock(_component_Dropdown, {
                                        key: 2,
                                        modelValue: slotProps.data.custom_fields[field.json_name],
                                        "onUpdate:modelValue": [($event: any) => ((slotProps.data.custom_fields[field.json_name]) = $event), ($event: any) => (
                          _ctx.setCustomFieldData($event, slotProps.data, field)
                        )],
                                        options: field.valid_value_items,
                                        optionLabel: "valid_desc",
                                        style: {"width":"100%"},
                                        optionValue: "valid_value",
                                        placeholder: `Select ${field.display_name}`
                                      }, null, 8, ["modelValue", "onUpdate:modelValue", "options", "placeholder"]))
                                    : (
                          field.conv &&
                          field.conv[0] === 'M' &&
                          field.conv[1] === 'D'
                        )
                                      ? (_openBlock(), _createBlock(_component_InputNumber, {
                                          key: 3,
                                          modelValue: slotProps.data.custom_fields[field.json_name],
                                          "onUpdate:modelValue": [($event: any) => ((slotProps.data.custom_fields[field.json_name]) = $event), ($event: any) => (
                          _ctx.setCustomFieldData($event, slotProps.data, field)
                        )]
                                        }, null, 8, ["modelValue", "onUpdate:modelValue"]))
                                      : (_openBlock(), _createBlock(_component_InputText, {
                                          key: 4,
                                          type: "text",
                                          modelValue: slotProps.data.custom_fields[field.json_name],
                                          "onUpdate:modelValue": [($event: any) => ((slotProps.data.custom_fields[field.json_name]) = $event), ($event: any) => (
                          _ctx.setCustomFieldData($event, slotProps.data, field)
                        )]
                                        }, null, 8, ["modelValue", "onUpdate:modelValue"]))
                            ]),
                            _: 2
                          }, 1032, ["field", "header"]))
                        }), 128)),
                        _createVNode(_component_Column, {
                          field: "li_order_qtys",
                          header: "Quantity",
                          "body-style": "min-width: 4rem; max-width: 4rem; padding: 4px;"
                        }, {
                          body: _withCtx((slotProps) => [
                            _createVNode(_component_InputNumber, {
                              min: 0,
                              step: 1,
                              class: "text-sm w-full",
                              modelValue: slotProps.data.li_order_qtys,
                              "onUpdate:modelValue": [($event: any) => ((slotProps.data.li_order_qtys) = $event), ($event: any) => (
                          _ctx.setNumberData(slotProps.data, 'li_order_qtys')
                        )],
                              disabled: _ctx.readOnly,
                              inputClass: "",
                              onChange: ($event: any) => (
                          slotProps.data.li_order_qtys =
                            slotProps.data.li_order_qtys.toString()
                        ),
                              onKeypress: [
                                _withKeys(_ctx.validateSalesOrder, ["enter"]),
                                _withKeys(_ctx.validateSalesOrder, ["tab"])
                              ]
                            }, null, 8, ["modelValue", "onUpdate:modelValue", "disabled", "onChange", "onKeypress"])
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_Column, {
                          field: "li_prices",
                          header: "Price",
                          "body-style": "min-width: 6rem; max-width: 6rem; padding: 4px;"
                        }, {
                          body: _withCtx((slotProps) => [
                            _createVNode(_component_InputText, {
                              type: "number",
                              class: "text-sm w-full",
                              modelValue: slotProps.data.li_prices,
                              "onUpdate:modelValue": ($event: any) => ((slotProps.data.li_prices) = $event),
                              disabled: _ctx.readOnly,
                              style: {"text-align":"right"}
                            }, null, 8, ["modelValue", "onUpdate:modelValue", "disabled"])
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_Column, {
                          header: "Ext Price",
                          "body-style": "min-width: 6rem; max-width: 6rem; padding: 4px; text-align: right;"
                        }, {
                          body: _withCtx((slotProps) => [
                            _createTextVNode(_toDisplayString(_ctx.calculateExtendedPrice(slotProps.data)), 1)
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_Column, {
                          header: "",
                          "body-style": "min-width: 6rem; max-width: 6rem; padding: 2px;"
                        }, {
                          body: _withCtx((slotProps) => [
                            _createVNode(_component_Button, {
                              icon: "pi pi-search",
                              onClick: ($event: any) => (
                          _ctx.handleDetailItemButton({
                            orderId: _ctx.currentOrder.so_id,
                            lineItem: {
                              lis: slotProps.data.lis,
                              index: slotProps.index,
                            },
                          })
                        )
                            }, null, 8, ["onClick"]),
                            _cache[105] || (_cache[105] = _createTextVNode("   ")),
                            (!_ctx.readOnly)
                              ? (_openBlock(), _createBlock(_component_Button, {
                                  key: 0,
                                  icon: "pi pi-trash",
                                  onClick: ($event: any) => (
                          _ctx.handleRemoveDetailItem({
                            orderId: _ctx.currentOrder.so_id,
                            lineItem: { ...slotProps.data },
                          })
                        )
                                }, null, 8, ["onClick"]))
                              : _createCommentVNode("", true)
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }, 8, ["value"])
                  ]),
                  _createVNode(_component_Divider, { class: "ml-3" }),
                  _createElementVNode("div", _hoisted_82, [
                    _cache[114] || (_cache[114] = _createElementVNode("div", { class: "col-12 grid hidden lg:inline-flex" }, [
                      _createElementVNode("div", { class: "col-1 font-semibold pb-0 mb-0 justify-content-center text-sm text-center" }, " Last Ship # "),
                      _createElementVNode("div", { class: "col-2 font-semibold pb-0 mb-0 justify-content-center text-sm text-center" }, " Last Ship Date "),
                      _createElementVNode("div", { class: "col-2 font-semibold pb-0 mb-0 justify-content-center text-sm text-center" }, " Last Return # "),
                      _createElementVNode("div", { class: "col-2 font-semibold pb-0 mb-0 justify-content-center text-sm text-center" }, " Last Return Date "),
                      _createElementVNode("div", { class: "col-2 font-semibold pb-0 mb-0 justify-content-center text-sm text-center" }, " Order Total "),
                      _createElementVNode("div", { class: "col-2 font-semibold pb-0 mb-0 justify-content-center text-sm text-center" }, " Pending "),
                      _createElementVNode("div", { class: "col-1 font-semibold pb-0 mb-0 pl-3 justify-content-center text-sm text-center" }, " Balance Due ")
                    ], -1)),
                    _createElementVNode("div", _hoisted_83, [
                      _createElementVNode("div", _hoisted_84, [
                        _createVNode(_component_InputText, {
                          type: "text",
                          class: "text-sm w-full",
                          modelValue: _ctx.currentOrder.last_ship_no,
                          "onUpdate:modelValue": _cache[43] || (_cache[43] = ($event: any) => ((_ctx.currentOrder.last_ship_no) = $event)),
                          disabled: true
                        }, null, 8, ["modelValue"])
                      ]),
                      _createElementVNode("div", _hoisted_85, [
                        _cache[106] || (_cache[106] = _createElementVNode("small", { class: "p-inputgroup-addon last-ship-label" }, "Last Ship #", -1)),
                        _createVNode(_component_InputText, {
                          type: "text",
                          class: "text-sm",
                          modelValue: _ctx.currentOrder.last_ship_no,
                          "onUpdate:modelValue": _cache[44] || (_cache[44] = ($event: any) => ((_ctx.currentOrder.last_ship_no) = $event)),
                          disabled: true
                        }, null, 8, ["modelValue"])
                      ]),
                      _createElementVNode("div", _hoisted_86, [
                        _createVNode(_component_InputText, {
                          type: "text",
                          class: "text-sm w-full",
                          modelValue: _ctx.currentOrder.last_date,
                          "onUpdate:modelValue": _cache[45] || (_cache[45] = ($event: any) => ((_ctx.currentOrder.last_date) = $event)),
                          disabled: true
                        }, null, 8, ["modelValue"])
                      ]),
                      _createElementVNode("div", _hoisted_87, [
                        _cache[107] || (_cache[107] = _createElementVNode("small", { class: "p-inputgroup-addon last-ship-label" }, "Last Date", -1)),
                        _createVNode(_component_InputText, {
                          type: "text",
                          class: "text-sm",
                          modelValue: _ctx.currentOrder.last_date,
                          "onUpdate:modelValue": _cache[46] || (_cache[46] = ($event: any) => ((_ctx.currentOrder.last_date) = $event)),
                          disabled: true
                        }, null, 8, ["modelValue"])
                      ]),
                      _createElementVNode("div", _hoisted_88, [
                        _createVNode(_component_InputText, {
                          type: "text",
                          class: "text-sm w-full",
                          modelValue: _ctx.currentOrder.last_rma_number,
                          "onUpdate:modelValue": _cache[47] || (_cache[47] = ($event: any) => ((_ctx.currentOrder.last_rma_number) = $event)),
                          disabled: true
                        }, null, 8, ["modelValue"])
                      ]),
                      _createElementVNode("div", _hoisted_89, [
                        _cache[108] || (_cache[108] = _createElementVNode("small", { class: "p-inputgroup-addon last-ship-label" }, "Last Return #", -1)),
                        _createVNode(_component_InputText, {
                          type: "text",
                          class: "text-sm",
                          modelValue: _ctx.currentOrder.last_rma_number,
                          "onUpdate:modelValue": _cache[48] || (_cache[48] = ($event: any) => ((_ctx.currentOrder.last_rma_number) = $event)),
                          disabled: true
                        }, null, 8, ["modelValue"])
                      ]),
                      _createElementVNode("div", _hoisted_90, [
                        _createVNode(_component_InputText, {
                          type: "text",
                          class: "text-sm w-full",
                          modelValue: _ctx.currentOrder.last_rma_date,
                          "onUpdate:modelValue": _cache[49] || (_cache[49] = ($event: any) => ((_ctx.currentOrder.last_rma_date) = $event)),
                          disabled: true
                        }, null, 8, ["modelValue"])
                      ]),
                      _createElementVNode("div", _hoisted_91, [
                        _cache[109] || (_cache[109] = _createElementVNode("small", { class: "p-inputgroup-addon last-ship-label" }, "Last Return Date", -1)),
                        _createVNode(_component_InputText, {
                          type: "text",
                          class: "text-sm",
                          modelValue: _ctx.currentOrder.last_rma_date,
                          "onUpdate:modelValue": _cache[50] || (_cache[50] = ($event: any) => ((_ctx.currentOrder.last_rma_date) = $event)),
                          disabled: true
                        }, null, 8, ["modelValue"])
                      ]),
                      _createElementVNode("div", _hoisted_92, [
                        _createVNode(_component_InputNumber, {
                          modelValue: _ctx.currentOrder.order_total_sum,
                          "onUpdate:modelValue": _cache[51] || (_cache[51] = ($event: any) => ((_ctx.currentOrder.order_total_sum) = $event)),
                          class: "text-sm w-full",
                          disabled: true,
                          mode: "currency",
                          currency: "USD",
                          locale: "en-US",
                          inputClass: "text-center  "
                        }, null, 8, ["modelValue"])
                      ]),
                      _createElementVNode("div", _hoisted_93, [
                        _cache[110] || (_cache[110] = _createElementVNode("small", { class: "p-inputgroup-addon last-ship-label" }, "Order Total", -1)),
                        _createVNode(_component_InputNumber, {
                          modelValue: _ctx.currentOrder.order_total_sum,
                          "onUpdate:modelValue": _cache[52] || (_cache[52] = ($event: any) => ((_ctx.currentOrder.order_total_sum) = $event)),
                          class: "text-sm",
                          disabled: true,
                          mode: "currency",
                          currency: "USD",
                          locale: "en-US",
                          inputClass: "text-left"
                        }, null, 8, ["modelValue"])
                      ]),
                      _createElementVNode("div", _hoisted_94, [
                        _createVNode(_component_InputText, {
                          type: "text",
                          class: "text-sm w-full",
                          modelValue: _ctx.currentOrder.pending_ship,
                          "onUpdate:modelValue": _cache[53] || (_cache[53] = ($event: any) => ((_ctx.currentOrder.pending_ship) = $event)),
                          disabled: true
                        }, null, 8, ["modelValue"])
                      ]),
                      _createElementVNode("div", _hoisted_95, [
                        _cache[111] || (_cache[111] = _createElementVNode("small", { class: "p-inputgroup-addon last-ship-label" }, "Pending", -1)),
                        _createVNode(_component_InputText, {
                          type: "text",
                          class: "text-sm",
                          modelValue: _ctx.currentOrder.pending_ship,
                          "onUpdate:modelValue": _cache[54] || (_cache[54] = ($event: any) => ((_ctx.currentOrder.pending_ship) = $event)),
                          disabled: true
                        }, null, 8, ["modelValue"])
                      ]),
                      _createElementVNode("div", _hoisted_96, [
                        _createVNode(_component_InputNumber, {
                          modelValue: _ctx.currentOrder.balance_due,
                          "onUpdate:modelValue": _cache[55] || (_cache[55] = ($event: any) => ((_ctx.currentOrder.balance_due) = $event)),
                          class: "text-sm w-full",
                          disabled: true,
                          mode: "currency",
                          currency: "USD",
                          locale: "en-US",
                          inputClass: "text-center ",
                          placeholder: 
                        _ctx.currentOrder.balance_due
                          ? _ctx.currentOrder.balance_due
                          : '$0.00'
                      
                        }, null, 8, ["modelValue", "placeholder"])
                      ]),
                      _createElementVNode("div", _hoisted_97, [
                        _cache[112] || (_cache[112] = _createElementVNode("small", { class: "p-inputgroup-addon last-ship-label" }, "Balance Due", -1)),
                        _createVNode(_component_InputNumber, {
                          modelValue: _ctx.currentOrder.balance_due,
                          "onUpdate:modelValue": _cache[56] || (_cache[56] = ($event: any) => ((_ctx.currentOrder.balance_due) = $event)),
                          class: "text-sm w-full",
                          disabled: true,
                          mode: "currency",
                          currency: "USD",
                          locale: "en-US",
                          inputClass: "text-left ",
                          placeholder: 
                        _ctx.currentOrder.balance_due
                          ? _ctx.currentOrder.balance_due
                          : '$0.00'
                      
                        }, null, 8, ["modelValue", "placeholder"])
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_98, [
                      (_ctx.currentOrder.signature)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_99, [
                            _cache[113] || (_cache[113] = _createElementVNode("h3", null, "Signature", -1)),
                            _createVNode(_component_Signature, {
                              signature: _ctx.currentOrder.signature,
                              signatureType: _ctx.currentOrder.signature_type,
                              receivedBy: _ctx.currentOrder.signature_by,
                              receivedDate: _ctx.currentOrder.signature_date,
                              receivedDateTime: _ctx.currentOrder.signature_time,
                              readOnly: true
                            }, null, 8, ["signature", "signatureType", "receivedBy", "receivedDate", "receivedDateTime"])
                          ]))
                        : _createCommentVNode("", true)
                    ])
                  ])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _createVNode(_component_Dialog, {
      visible: _ctx.showAddExistingQuoteDialog,
      "onUpdate:visible": _cache[61] || (_cache[61] = ($event: any) => ((_ctx.showAddExistingQuoteDialog) = $event)),
      header: "Search for Quote",
      modal: true,
      style: { width: '500px' },
      onHide: _cache[62] || (_cache[62] = 
      () => {
        _ctx.resetFields(`QUOTE`);
      }
    ),
      onShow: _cache[63] || (_cache[63] = 
      () => {
        _ctx.focusSOQuoteSearch();
        _ctx.copyOnlyQuoteParts = 'Y';
      }
    )
    }, {
      footer: _withCtx(() => [
        _createVNode(_component_Button, {
          label: "Cancel",
          icon: "pi pi-times",
          onClick: _cache[59] || (_cache[59] = 
          () => {
            _ctx.showAddExistingQuoteDialog = false;
          }
        ),
          class: "p-button-text"
        }),
        _createVNode(_component_Button, {
          label: "Populate Quote",
          icon: "pi pi-check",
          onClick: _cache[60] || (_cache[60] = ($event: any) => (_ctx.populateDialog())),
          loading: _ctx.isLoadingQuote
        }, null, 8, ["loading"])
      ]),
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_100, [
          _createVNode(_component_InputText, {
            ref: "soquoteInputText",
            placeholder: "Search by Id",
            modelValue: _ctx.existingSOQuote,
            "onUpdate:modelValue": _cache[57] || (_cache[57] = ($event: any) => ((_ctx.existingSOQuote) = $event)),
            disabled: _ctx.readOnly
          }, null, 8, ["modelValue", "disabled"]),
          _cache[116] || (_cache[116] = _createTextVNode("   ")),
          (_ctx.isOrderEmpty && !!_ctx.statusMessage)
            ? (_openBlock(), _createElementBlock("small", _hoisted_101, _toDisplayString(_ctx.statusMessage), 1))
            : _createCommentVNode("", true),
          (_ctx.isLoadQuoteFormSubmitted && _ctx.v$.existingSOQuote.required.$invalid)
            ? (_openBlock(), _createElementBlock("small", _hoisted_102, _toDisplayString(_ctx.v$.existingSOQuote.required.$message), 1))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_103, [
            _createVNode(_component_CheckBox, {
              id: "copy-quote-parts",
              modelValue: _ctx.copyOnlyQuoteParts,
              "onUpdate:modelValue": _cache[58] || (_cache[58] = ($event: any) => ((_ctx.copyOnlyQuoteParts) = $event)),
              name: "copy-quote-parts",
              trueValue: "Y",
              falseValue: "N",
              binary: true,
              class: "field-checkbox m-2 mb-0"
            }, null, 8, ["modelValue"]),
            _cache[115] || (_cache[115] = _createElementVNode("label", {
              for: "copy-quote-parts",
              class: "text-sm ml-0 pt-2 pl-1"
            }, "Copy only parts", -1))
          ])
        ])
      ]),
      _: 1
    }, 8, ["visible"]),
    _createVNode(_component_Dialog, {
      visible: _ctx.showAddExistingOrderDialog,
      "onUpdate:visible": _cache[68] || (_cache[68] = ($event: any) => ((_ctx.showAddExistingOrderDialog) = $event)),
      header: "Search for Order",
      modal: true,
      style: { width: '500px' },
      onHide: _cache[69] || (_cache[69] = 
      () => {
        _ctx.resetFields(`ORDER`);
      }
    ),
      onShow: _cache[70] || (_cache[70] = 
      () => {
        _ctx.focusOrderSearch();
        _ctx.copyOnlyOrderParts = 'Y';
      }
    )
    }, {
      footer: _withCtx(() => [
        _createVNode(_component_Button, {
          label: "Cancel",
          icon: "pi pi-times",
          onClick: _cache[66] || (_cache[66] = 
          () => {
            _ctx.showAddExistingOrderDialog = false;
          }
        ),
          class: "p-button-text"
        }),
        _createVNode(_component_Button, {
          label: "Populate Order",
          icon: "pi pi-check",
          onClick: _cache[67] || (_cache[67] = ($event: any) => (_ctx.populateDialogWithOrder())),
          loading: _ctx.isLoadingOrder
        }, null, 8, ["loading"])
      ]),
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_104, [
          _createVNode(_component_InputText, {
            ref: "orderInputText",
            placeholder: "Search by Id",
            modelValue: _ctx.existingOrder,
            "onUpdate:modelValue": _cache[64] || (_cache[64] = ($event: any) => ((_ctx.existingOrder) = $event)),
            disabled: _ctx.readOnly
          }, null, 8, ["modelValue", "disabled"]),
          _cache[118] || (_cache[118] = _createTextVNode("   ")),
          (_ctx.isOrderEmpty && !!_ctx.statusMessage)
            ? (_openBlock(), _createElementBlock("small", _hoisted_105, _toDisplayString(_ctx.statusMessage), 1))
            : _createCommentVNode("", true),
          (_ctx.isLoadOrderFormSubmitted && _ctx.v$.existingOrder.required.$invalid)
            ? (_openBlock(), _createElementBlock("small", _hoisted_106, _toDisplayString(_ctx.v$.existingOrder.required.$message), 1))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_107, [
            _createVNode(_component_CheckBox, {
              id: "copy-order-parts",
              modelValue: _ctx.copyOnlyOrderParts,
              "onUpdate:modelValue": _cache[65] || (_cache[65] = ($event: any) => ((_ctx.copyOnlyOrderParts) = $event)),
              name: "copy-order-parts",
              trueValue: "Y",
              falseValue: "N",
              binary: true,
              class: "field-checkbox m-2 mb-0"
            }, null, 8, ["modelValue"]),
            _cache[117] || (_cache[117] = _createElementVNode("label", {
              for: "copy-order-parts",
              class: "text-sm ml-0 pt-2 pl-1"
            }, "Copy only parts", -1))
          ])
        ])
      ]),
      _: 1
    }, 8, ["visible"]),
    _createVNode(_component_SearchPartDialog, {
      show: _ctx.showSearchPartDialog,
      companyCode: _ctx.currentOrder.co_code,
      onCloseDialog: _ctx.closeSearchPartDialog,
      onAddToCart: _ctx.addItem
    }, null, 8, ["show", "companyCode", "onCloseDialog", "onAddToCart"]),
    _createVNode(_component_EmailFileDialog, {
      show: _ctx.showEmailDialog,
      header: `Email Sales Order ${
      _ctx.currentOrder !== null && _ctx.currentOrder.so_id ? _ctx.currentOrder.so_id : ''
    }`,
      onHide: _cache[71] || (_cache[71] = 
      (isHidden) => {
        _ctx.showEmailDialog = isHidden;
      }
    ),
      fileId: 
      _ctx.currentOrder !== null && _ctx.currentOrder.so_id ? _ctx.currentOrder.so_id : ''
    ,
      fileName: 
      _ctx.currentOrder !== null && _ctx.currentOrder.so_id ? _ctx.currentOrder.so_id : ''
    ,
      onOnEmail: _cache[72] || (_cache[72] = (data) => _ctx.handleSendEmail(data))
    }, null, 8, ["show", "header", "fileId", "fileName"])
  ], 64))
}
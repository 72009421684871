import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, normalizeClass as _normalizeClass, withKeys as _withKeys, createBlock as _createBlock, createSlots as _createSlots, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "grid m-0 accounting-tab-content p-0 px-3" }
const _hoisted_2 = {
  key: 0,
  class: "grid p-0 m-0 justify-content-center md:justify-content-end pb-2"
}
const _hoisted_3 = { class: "col-12 md:col-5 lg:col-3 p-0 m-0 mr-1 flex justify-content-end" }
const _hoisted_4 = { class: "grid justify-content-center px-2 mt-0" }
const _hoisted_5 = { class: "col-12 xl:col-11 grid justify-content-between m-0 p-0" }
const _hoisted_6 = { class: "col-12 sm:col-6 md:col-4 p-1 m-0 field pr-1 lg:pr-8" }
const _hoisted_7 = { class: "p-inputgroup" }
const _hoisted_8 = { class: "col-12 sm:col-6 md:col-4 p-1 m-0 field pr-1 pl-1 lg:pl-6 lg:pr-5" }
const _hoisted_9 = { class: "p-inputgroup" }
const _hoisted_10 = { class: "col-12 sm:col-6 md:col-4 p-1 m-0 field pr-1 lg:pl-8" }
const _hoisted_11 = { class: "p-inputgroup" }
const _hoisted_12 = { class: "col-12 sm:col-6 md:col-6 lg:col-3 p-0 m-0" }
const _hoisted_13 = { class: "col-12 p-1 m-0 field" }
const _hoisted_14 = { class: "p-inputgroup" }
const _hoisted_15 = { class: "col-12 p-1 m-0 field" }
const _hoisted_16 = { class: "p-inputgroup" }
const _hoisted_17 = { class: "col-12 p-1 m-0 field" }
const _hoisted_18 = { class: "p-inputgroup" }
const _hoisted_19 = { class: "col-12 p-1 m-0 field" }
const _hoisted_20 = { class: "p-inputgroup" }
const _hoisted_21 = { class: "col-12 p-1 m-0 field" }
const _hoisted_22 = { class: "p-inputgroup" }
const _hoisted_23 = { class: "col-12 md:col-12 lg:col-8 p-0 m-0" }
const _hoisted_24 = { class: "col-12 p-1 m-0 field" }
const _hoisted_25 = { class: "p-inputgroup" }
const _hoisted_26 = { class: "col-12 xl:col-11 grid justify-content-between m-0 p-1 mt-2" }
const _hoisted_27 = { class: "table-header p-0 m-0 flex justify-content-between" }
const _hoisted_28 = { class: "pr-2 lg:pr-0" }
const _hoisted_29 = { class: "text-sm pr-2 lg:pr-0" }
const _hoisted_30 = {
  class: "flex p-0 m-0 justify-content-center",
  style: {
                      width: '50px !important',
                      maxWidth: '55px !important',
                    }
}
const _hoisted_31 = { class: "grid p-0 m-0 justify-content-end" }
const _hoisted_32 = { class: "col-12 grid p-0 m-0 justify-content-end align-items-start" }
const _hoisted_33 = {
  class: "col-3 sm:col-2 lg:col-3 justify-content-end p-0 flex align-items-center pr-0 lg:pr-2",
  style: { marginTop: '2px' }
}
const _hoisted_34 = {
  key: 0,
  style: {
                          color: 'orange',
                          fontSize: '10px',
                          textAlign: 'center',
                        }
}
const _hoisted_35 = { class: "col-5 sm:col-2 md:col-2 lg:col-2 p-0 pr-2 md:pr-0" }
const _hoisted_36 = {
  class: "col-5 sm:col-2 md:col-2 lg:col-2 xl:col-2 p-0 pr-2 lg:pr-3 xl:pr-0",
  style: {
                        width: '12%',
                        paddingRight: '4px !important',
                        minWidth: '135px',
                      }
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Divider = _resolveComponent("Divider")!
  const _component_Calendar = _resolveComponent("Calendar")!
  const _component_TextArea = _resolveComponent("TextArea")!
  const _component_Column = _resolveComponent("Column")!
  const _component_AccountAutocomplete = _resolveComponent("AccountAutocomplete")!
  const _component_InputNumber = _resolveComponent("InputNumber")!
  const _component_icon = _resolveComponent("icon")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _component_Card = _resolveComponent("Card")!
  const _component_MessageBox = _resolveComponent("MessageBox")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_Card, { class: "col-12 border-round-3xl tab-card-content p-0" }, {
        content: _withCtx(() => [
          (!_ctx.isReadOnly)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createElementVNode("div", _hoisted_3, [
                  _createVNode(_component_Button, {
                    label: "Save",
                    icon: "pi pi-save",
                    onClick: _ctx.handleSaveEntry
                  }, null, 8, ["onClick"])
                ])
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("div", _hoisted_7, [
                  _cache[17] || (_cache[17] = _createElementVNode("small", { class: "p-inputgroup-addon medium-addon" }, "Trans #", -1)),
                  _createVNode(_component_InputText, {
                    modelValue: _ctx.transaction.transaction_id,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.transaction.transaction_id) = $event)),
                    type: "text",
                    class: "text-sm",
                    disabled: true
                  }, null, 8, ["modelValue"])
                ])
              ]),
              _createElementVNode("div", _hoisted_8, [
                _createElementVNode("div", _hoisted_9, [
                  _cache[18] || (_cache[18] = _createElementVNode("small", { class: "p-inputgroup-addon medium-addon" }, "Load", -1)),
                  _createVNode(_component_InputText, {
                    modelValue: _ctx.loadFrom,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.loadFrom) = $event)),
                    type: "text",
                    class: "text-sm",
                    onBlur: _ctx.handleLoadFrom
                  }, null, 8, ["modelValue", "onBlur"])
                ])
              ]),
              _createElementVNode("div", _hoisted_10, [
                _createElementVNode("div", _hoisted_11, [
                  _cache[19] || (_cache[19] = _createElementVNode("small", { class: "p-inputgroup-addon medium-addon" }, "Post Date", -1)),
                  _createVNode(_component_InputText, {
                    modelValue: _ctx.transaction.postDate,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.transaction.postDate) = $event)),
                    type: "text",
                    class: "text-sm",
                    disabled: true
                  }, null, 8, ["modelValue"])
                ])
              ]),
              _createVNode(_component_Divider, { class: "col-12 p-2 m-0" }),
              _createElementVNode("div", _hoisted_12, [
                _createElementVNode("div", _hoisted_13, [
                  _createElementVNode("div", _hoisted_14, [
                    _cache[20] || (_cache[20] = _createElementVNode("small", { class: "p-inputgroup-addon medium-addon" }, "Fiscal Year", -1)),
                    _createVNode(_component_Calendar, {
                      modelValue: _ctx.transaction.fiscalYear,
                      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.transaction.fiscalYear) = $event)),
                      class: "text-sm calendar-without-icon",
                      view: "year",
                      dateFormat: "yy",
                      minDate: new Date('' + (+_ctx.minYear + 1)),
                      maxDate: new Date('' + (+_ctx.maxYear + 1)),
                      disabled: _ctx.isReadOnly,
                      onDateSelect: _ctx.handleSelectedYearChange
                    }, null, 8, ["modelValue", "minDate", "maxDate", "disabled", "onDateSelect"])
                  ])
                ]),
                _createElementVNode("div", _hoisted_15, [
                  _createElementVNode("div", _hoisted_16, [
                    _cache[21] || (_cache[21] = _createElementVNode("small", { class: "p-inputgroup-addon medium-addon" }, "Trans Date", -1)),
                    _createVNode(_component_Calendar, {
                      modelValue: _ctx.transaction.date,
                      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.transaction.date) = $event)),
                      disabled: _ctx.isReadOnly,
                      showIcon: "",
                      readonlyInput: true,
                      minDate: _ctx.minDate,
                      maxDate: _ctx.maxDate,
                      "show-button-bar": "",
                      dateFormat: "mm-dd-yy",
                      onDateSelect: _ctx.handleTransactionDate
                    }, null, 8, ["modelValue", "disabled", "minDate", "maxDate", "onDateSelect"])
                  ])
                ]),
                _createElementVNode("div", _hoisted_17, [
                  _createElementVNode("div", _hoisted_18, [
                    _cache[22] || (_cache[22] = _createElementVNode("small", { class: "p-inputgroup-addon medium-addon" }, "Ref Journal", -1)),
                    _createVNode(_component_InputText, {
                      modelValue: _ctx.transaction.refJournal,
                      "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.transaction.refJournal) = $event)),
                      type: "text",
                      class: "text-sm",
                      disabled: _ctx.isReadOnly
                    }, null, 8, ["modelValue", "disabled"])
                  ])
                ]),
                _createElementVNode("div", _hoisted_19, [
                  _createElementVNode("div", _hoisted_20, [
                    _cache[23] || (_cache[23] = _createElementVNode("small", { class: "p-inputgroup-addon medium-addon" }, "Year To Rev", -1)),
                    _createVNode(_component_Calendar, {
                      modelValue: _ctx.transaction.yearToReverse,
                      "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.transaction.yearToReverse) = $event)),
                      class: "text-sm calendar-without-icon",
                      view: "year",
                      dateFormat: "yy",
                      minDate: new Date('' + (+_ctx.minYear + 1)),
                      maxDate: new Date('' + (+_ctx.maxYear + 1)),
                      disabled: _ctx.isReadOnly,
                      onDateSelect: _ctx.handleSelectedReverseYearChange
                    }, null, 8, ["modelValue", "minDate", "maxDate", "disabled", "onDateSelect"])
                  ])
                ]),
                _createElementVNode("div", _hoisted_21, [
                  _createElementVNode("div", _hoisted_22, [
                    _cache[24] || (_cache[24] = _createElementVNode("small", { class: "p-inputgroup-addon medium-addon" }, "Date To Rev", -1)),
                    _createVNode(_component_Calendar, {
                      modelValue: _ctx.transaction.dateToReverse,
                      "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.transaction.dateToReverse) = $event)),
                      disabled: _ctx.isReadOnly || !_ctx.transaction.yearToReverse,
                      showIcon: true,
                      readonlyInput: true,
                      "show-button-bar": "",
                      minDate: _ctx.minReverseDate,
                      maxDate: _ctx.maxReverseDate,
                      dateFormat: "mm-dd-yy",
                      onDateSelect: _ctx.handleSelectedDateToReverse
                    }, null, 8, ["modelValue", "disabled", "minDate", "maxDate", "onDateSelect"])
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_23, [
                _createElementVNode("div", _hoisted_24, [
                  _cache[25] || (_cache[25] = _createElementVNode("div", { class: "p-inputgroup mb-2 font-semibold" }, "Notes", -1)),
                  _createElementVNode("div", _hoisted_25, [
                    _createVNode(_component_TextArea, {
                      rows: "5",
                      modelValue: _ctx.transaction.desc,
                      "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.transaction.desc) = $event)),
                      placeholder: "Enter a note here...",
                      class: "textarea text-sm text-left",
                      disabled: _ctx.isReadOnly,
                      style: { minHeight: '197px' }
                    }, null, 8, ["modelValue", "disabled"])
                  ])
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_26, [
              _createVNode(_component_DataTable, {
                value: _ctx.transaction.lineItems,
                "data-key": "li",
                id: "trial-balance-table",
                class: _normalizeClass(["p-datatable-sm bordered-table with-header w-full", {
                'with-footer':
                  _ctx.transaction.lineItems && _ctx.transaction.lineItems.length > 0,
              }]),
                scrollHeight: "65vh",
                "paginator-template": "FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown",
                "removable-sort": "",
                breakpoint: "991px",
                responsiveLayout: "stack"
              }, _createSlots({
                empty: _withCtx(() => [
                  _cache[26] || (_cache[26] = _createElementVNode("div", { class: "flex p-0 m-0 justify-content-center" }, [
                    _createElementVNode("span", { class: "text-center font-semibold" }, "Click Add to add a new entry.")
                  ], -1))
                ]),
                header: _withCtx(() => [
                  _createElementVNode("div", _hoisted_27, [
                    _cache[27] || (_cache[27] = _createElementVNode("div", null, null, -1)),
                    _cache[28] || (_cache[28] = _createElementVNode("h3", { class: "p-0 m-0 text-center inline-flex" }, null, -1)),
                    _createVNode(_component_Button, {
                      label: "Add",
                      icon: "pi pi-plus",
                      class: "p-button-sm py-1 px-2",
                      disabled: _ctx.isReadOnly,
                      onClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.addNewLineItem()))
                    }, null, 8, ["disabled"])
                  ])
                ]),
                default: _withCtx(() => [
                  _createVNode(_component_Column, {
                    field: "li",
                    header: "Li #",
                    bodyClass: "text-center font-semibold",
                    style: { width: '55px' }
                  }, {
                    body: _withCtx(({ data }) => [
                      _createElementVNode("span", _hoisted_28, _toDisplayString(data.li), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_Column, {
                    field: "account",
                    header: "Account",
                    class: "",
                    bodyClass: "text-right w-1"
                  }, {
                    body: _withCtx(({ data }) => [
                      _createVNode(_component_AccountAutocomplete, {
                        selectedId: data.account,
                        class: _normalizeClass(["text-sm single-field column-input-text", { 'invalid-autocomplete': _ctx.isRowInError(data.li) }]),
                        inputClass: "text-right lg:text-left",
                        title: "Enter account number or description...",
                        onFocus: _cache[10] || (_cache[10] = ($event: any) => ($event.target.select())),
                        onItemSelect: ($event: any) => (_ctx.handleSelectAccount($event, data)),
                        onOnLeave: ($event: any) => (_ctx.handleOnBlur($event, data))
                      }, null, 8, ["selectedId", "class", "onItemSelect", "onOnLeave"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_Column, {
                    field: "accountDescription",
                    header: "Account Description",
                    headerClass: "column-header-left",
                    bodyClass: "text-left lg:w-3"
                  }, {
                    body: _withCtx(({ data }) => [
                      _createElementVNode("span", _hoisted_29, _toDisplayString(data.accountDescription), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_Column, {
                    field: "notes",
                    header: "Notes",
                    headerClass: "column-header-left",
                    class: "lg:w-4"
                  }, {
                    body: _withCtx(({ data }) => [
                      _createVNode(_component_InputText, {
                        modelValue: data.notes,
                        "onUpdate:modelValue": ($event: any) => ((data.notes) = $event),
                        placeholder: "Enter a note here...",
                        class: "text-sm single-field text-right lg:text-left w-full column-input-text",
                        onFocus: _cache[11] || (_cache[11] = ($event: any) => ($event.target.select()))
                      }, null, 8, ["modelValue", "onUpdate:modelValue"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_Column, {
                    field: "debit",
                    header: "Debit",
                    class: "lg:w-7rem"
                  }, {
                    body: _withCtx(({ data }) => [
                      _createVNode(_component_InputNumber, {
                        modelValue: data.debit,
                        "onUpdate:modelValue": ($event: any) => ((data.debit) = $event),
                        mode: "currency",
                        currency: "USD",
                        class: "text-sm w-full column-input-text",
                        inputClass: "single-field text-right",
                        onFocus: _cache[12] || (_cache[12] = ($event: any) => ($event.target.select())),
                        onInput: ($event: any) => (_ctx.handleDebitChange($event, data, 'debit')),
                        onBlur: ($event: any) => (_ctx.handleExitDebitOrCredit($event, data, 'debit'))
                      }, null, 8, ["modelValue", "onUpdate:modelValue", "onInput", "onBlur"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_Column, {
                    field: "credit",
                    header: "Credit",
                    headerClass: "column-header-right",
                    class: "lg:w-7rem column-input-text",
                    bodyClass: "text-right"
                  }, {
                    body: _withCtx(({ data }) => [
                      _createVNode(_component_InputNumber, {
                        modelValue: data.credit,
                        "onUpdate:modelValue": ($event: any) => ((data.credit) = $event),
                        mode: "currency",
                        currency: "USD",
                        class: "text-sm w-full column-input-text",
                        inputClass: "single-field text-right",
                        onFocus: _cache[13] || (_cache[13] = ($event: any) => ($event.target.select())),
                        onInput: ($event: any) => (_ctx.handleDebitChange($event, data, 'credit')),
                        onKeydown: _withKeys(($event: any) => (_ctx.handleTabPressed($event, data)), ["tab"]),
                        onBlur: ($event: any) => (_ctx.handleExitDebitOrCredit($event, data, 'credit'))
                      }, null, 8, ["modelValue", "onUpdate:modelValue", "onInput", "onKeydown", "onBlur"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_Column, {
                    class: "col-1",
                    style: {
                  width: '50px !important',
                  maxWidth: '55px !important',
                },
                    bodyStyle: {
                  width: '50px !important',
                  maxWidth: '55px !important',
                }
                  }, {
                    body: _withCtx(({ data }) => [
                      _createElementVNode("div", _hoisted_30, [
                        _createVNode(_component_Button, {
                          icon: "pi pi-trash",
                          class: "p-button-sm py-1 px-2",
                          title: 'Delete Entry ' + data.li,
                          style: { backgroundColor: 'red', width: '30px' },
                          onClick: ($event: any) => (_ctx.handleDeleteEntry(data))
                        }, null, 8, ["title", "onClick"])
                      ])
                    ]),
                    _: 1
                  })
                ]),
                _: 2
              }, [
                (_ctx.transaction.lineItems && _ctx.transaction.lineItems.length > 0)
                  ? {
                      name: "footer",
                      fn: _withCtx(() => [
                        _createElementVNode("div", _hoisted_31, [
                          _createElementVNode("div", _hoisted_32, [
                            _createElementVNode("div", _hoisted_33, [
                              (!_ctx.areQuantitiesMatching)
                                ? (_openBlock(), _createElementBlock("span", _hoisted_34, "Debit - Credit totals don't match"))
                                : _createCommentVNode("", true),
                              (!_ctx.areQuantitiesMatching)
                                ? (_openBlock(), _createBlock(_component_icon, {
                                    key: 1,
                                    class: "pi pi-exclamation-triangle pl-0 sm:pl-2",
                                    style: { color: 'orange', fontWeight: '500' }
                                  }))
                                : _createCommentVNode("", true),
                              (_ctx.areQuantitiesMatching)
                                ? (_openBlock(), _createBlock(_component_icon, {
                                    key: 2,
                                    class: "pi pi-check-circle pl-0 lg:pl-2",
                                    style: {
                          color: '#00ba00',
                          fontWeight: '700',
                          fontSize: '14px',
                        }
                                  }))
                                : _createCommentVNode("", true)
                            ]),
                            _cache[29] || (_cache[29] = _createElementVNode("div", { class: "hidden col-1 lg:flex p-0" }, "Total", -1)),
                            _cache[30] || (_cache[30] = _createElementVNode("div", { class: "col-4 sm:col-1 md:col-1 lg:hidden p-0 text-end" }, " Debit: ", -1)),
                            _createElementVNode("div", _hoisted_35, _toDisplayString(_ctx.formatPrice(_ctx.totalDebit.toString())), 1),
                            _cache[31] || (_cache[31] = _createElementVNode("div", { class: "col-4 sm:col-3 md:col-3 p-0 lg:hidden text-end" }, " Credit: ", -1)),
                            _createElementVNode("div", _hoisted_36, _toDisplayString(_ctx.formatPrice(_ctx.totalCredit.toString())), 1),
                            _cache[32] || (_cache[32] = _createElementVNode("div", {
                              class: "hidden lg:flex col-1",
                              style: { width: '4vw !important', minWidth: '55px' }
                            }, null, -1))
                          ])
                        ])
                      ]),
                      key: "0"
                    }
                  : undefined
              ]), 1032, ["value", "class"])
            ])
          ])
        ]),
        _: 1
      })
    ]),
    _createVNode(_component_MessageBox, {
      showDialog: _ctx.showBalanceErrorDialog,
      icon: "WARNING",
      header: "Cannot save transaction!",
      message: _ctx.saveErrorMessage,
      "message-items": _ctx.saveErrorMessageItems,
      primaryButton: "Close",
      onPrimaryButtonClick: _cache[14] || (_cache[14] = ($event: any) => (_ctx.showBalanceErrorDialog = false))
    }, null, 8, ["showDialog", "message", "message-items"]),
    _createVNode(_component_MessageBox, {
      showDialog: _ctx.showConfirmDeleteDialog,
      icon: "WARNING",
      header: "Delete Entry",
      message: "Are you sure you want to delete this entry?",
      "message-items": _ctx.getMessageItemToDelete(_ctx.entryToDelete),
      primaryButton: "Yes",
      cancelButton: "Cancel",
      onPrimaryButtonClick: _cache[15] || (_cache[15] = ($event: any) => (_ctx.deleteEntry())),
      onCancelButtonClick: _cache[16] || (_cache[16] = ($event: any) => {
      _ctx.showConfirmDeleteDialog = false;
      _ctx.entryToDelete = {} as GeneralLedgerLineItem;
    })
    }, null, 8, ["showDialog", "message-items"]),
    _createVNode(_component_MessageBox, {
      showDialog: _ctx.showConfirmUpdateAmountDialog,
      icon: "WARNING",
      header: `Updating Li#: ${_ctx.entryToUpdate.data.li} ${
      _ctx.entryToUpdate.amountType === 'debit' ? 'Credit' : 'Debit'
    } Amount`,
      message: `If Accept, ${
      _ctx.entryToUpdate.amountType !== 'debit' ? 'Credit' : 'Debit'
    } will be set to $0.00.`,
      primaryButton: "Update",
      cancelButton: "Cancel",
      onPrimaryButtonClick: _ctx.updateAmount,
      onCancelButtonClick: _ctx.handleCloseConfirmUpdateAmountDialog
    }, null, 8, ["showDialog", "header", "message", "onPrimaryButtonClick", "onCancelButtonClick"])
  ], 64))
}
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "products-list-wrapper pr-3",
  ref: "itemsContainer"
}
const _hoisted_2 = ["id"]
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { class: "col-8 flex flex-column justify-content-center" }
const _hoisted_5 = {
  key: 0,
  class: "blue-text font-bold product-name-label inline-block"
}
const _hoisted_6 = {
  key: 1,
  class: "blue-text font-bold product-name-label inline-block emphasized"
}
const _hoisted_7 = { class: "part-name-label inline-block" }
const _hoisted_8 = { class: "col-4 flex flex-column justify-content-between" }
const _hoisted_9 = ["onClick"]
const _hoisted_10 = {
  key: 1,
  class: "flex align-items-end justify-content-between"
}
const _hoisted_11 = { class: "text-sm part-name-label font-semibold pl-3" }
const _hoisted_12 = { class: "text-sm part-name-label font-semibold" }
const _hoisted_13 = {
  key: 0,
  class: "text-sm part-name-label font-semibold pl-3"
}
const _hoisted_14 = { class: "font-bold ml-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_OrderSummaryItemWrapper = _resolveComponent("OrderSummaryItemWrapper")!
  const _component_Divider = _resolveComponent("Divider")!

  return (_ctx.showPartsInformation)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(this.order.lis_items, (lineItem, index) => {
          return (_openBlock(), _createElementBlock("div", {
            key: index,
            id: `order-item-${lineItem.li_parts}`
          }, [
            (lineItem.li_parts)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  class: "grid pointer focus:surface-200",
                  tabindex: "0",
                  onClick: ($event: any) => (_ctx.openCartDialog(index))
                }, [
                  _createVNode(_component_OrderSummaryItemWrapper, { part: lineItem }, {
                    content: _withCtx(() => [
                      _createElementVNode("div", _hoisted_4, [
                        (lineItem.wrap_desc)
                          ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(lineItem.wrap_desc), 1))
                          : (_openBlock(), _createElementBlock("span", _hoisted_6, "No description available ")),
                        _createElementVNode("span", _hoisted_7, [
                          _createTextVNode(_toDisplayString(_ctx.partNumberLabel) + " " + _toDisplayString(lineItem.li_parts) + " ", 1),
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.controlFieldValues('PARTS', '0'), (field) => {
                            return (_openBlock(), _createElementBlock("span", {
                              key: field.field_no
                            }, " | " + _toDisplayString(field.label) + " " + _toDisplayString(lineItem[field.json_name]), 1))
                          }), 128))
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_8, [
                        (
                  _ctx.$route.fullPath !== '/pos/payment' &&
                  _ctx.$route.fullPath !== '/pos/confirmation' &&
                  _ctx.$route.fullPath !== '/pos/ship' &&
                  _ctx.$route.fullPath !== '/pos/shipping' &&
                  _ctx.$route.fullPath !== '/pos/order-created' &&
                  _ctx.$route.fullPath !== '/pos/parts' &&
                  !_ctx.checkAccountingRoute
                )
                          ? (_openBlock(), _createElementBlock("span", {
                              key: 0,
                              style: {"z-index":"10"},
                              onClick: ($event: any) => (_ctx.removePart($event, index))
                            }, _cache[0] || (_cache[0] = [
                              _createElementVNode("i", {
                                class: "pi pi-trash pointer",
                                style: {"color":"red","display":"flex","justify-content":"right"}
                              }, null, -1)
                            ]), 8, _hoisted_9))
                          : _createCommentVNode("", true),
                        (parseFloat(lineItem.li_order_qtys) > 1)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                              _createElementVNode("span", _hoisted_11, _toDisplayString(lineItem.li_order_qtys) + "x", 1),
                              _createElementVNode("span", _hoisted_12, _toDisplayString(_ctx.formatPrice(lineItem.li_prices)), 1)
                            ]))
                          : _createCommentVNode("", true),
                        _createElementVNode("div", {
                          class: _normalizeClass(["flex align-items-end", {
                  'justify-content-end': parseFloat(lineItem.li_order_qtys) > 1,
                  'justify-content-between':
                    parseFloat(lineItem.li_order_qtys) <= 1,
                }])
                        }, [
                          (parseFloat(lineItem.li_order_qtys) === 1)
                            ? (_openBlock(), _createElementBlock("span", _hoisted_13, _toDisplayString(lineItem.li_order_qtys) + "x", 1))
                            : _createCommentVNode("", true),
                          _createElementVNode("span", _hoisted_14, _toDisplayString(_ctx.formatPrice(
                      parseFloat(lineItem.li_order_qtys) *
                        parseFloat(lineItem.li_prices),
                    )), 1)
                        ], 2)
                      ])
                    ]),
                    _: 2
                  }, 1032, ["part"])
                ], 8, _hoisted_3))
              : _createCommentVNode("", true),
            _createVNode(_component_Divider)
          ], 8, _hoisted_2))
        }), 128))
      ], 512))
    : _createCommentVNode("", true)
}
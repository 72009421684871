
import { Options, Vue } from "vue-class-component";
import { mapState, mapActions } from "vuex";
import Button from "primevue/button";
import Message from "primevue/message";
import Toast from "primevue/toast";
import Notification, { NotificationType } from "@/types/notification";
import Dialog from "primevue/dialog";
@Options({
  components: {
    Button,
    Message,
    Toast,
    Dialog,
  },
  computed: {
    ...mapState("notification", ["notifications"]),
    notificationItems() {
      const toastItems = this.notifications.forEach((element: any) => {
        if (!element.dialog) {
          this.$toast.add({
            severity: element.type,
            summary: element.message,
            id: element.id,
            life: 5000,
          });
          this.remove(element);
        }
      });
      return toastItems;
    },
    dialogNotifications() {
      return this.notifications.filter((item: any) => item.dialog);
    },
  },
  methods: {
    ...mapActions({
      remove: "notification/remove",
      validateSalesOrder: "pos/validateSalesOrder",
    }),
    confirmButtonText(item: any) {
      return item.confirmButtonLabel || "Confirm";
    },
    closeButtonText(item: any) {
      return item.closeButtonLabel || "Close";
    },
    handleCloseNotification(item: any) {
      if (item.type === NotificationType.CONFIRM_VALIDATE_OVERRIDE) {
        this.handleValidateOverride(item, false);
      } else {
        this.closeNotification(item);
      }
    },
    closeNotification(item: any) {
      this.remove(item);
      if (item.closeLink) {
        this.$router.push(item.closeLink);
      }
    },
    handleValidateOverride(item: any, isConfirm: boolean) {
      this.remove(item);
      this.validateSalesOrder({
        metaData: {
          validate_only: "Y",
          validate_reason: item.validateMetaData.validate_reason,
          validate_override: isConfirm ? "Y" : "N",
        },
      });
    },
    notificationHeader(item: any) {
      if (item.title) {
        return item.title;
      }
      switch (item.type) {
        case NotificationType.ERROR:
          return "Error";
        case NotificationType.INFO:
          return "Information";
        case NotificationType.SUCCESS:
          return "Success";
        case NotificationType.WARN:
          return "Warning";
        case NotificationType.CONFIRM_VALIDATE_OVERRIDE:
          return "Warning";
        default:
          return "Notification";
      }
    },
    getClassForNotificationType(type: string) {
      //return `pi pi-exclamation-triangle mr-3" style="font-size: 2rem`;
      switch (type) {
        case NotificationType.ERROR:
          return "pi pi-exclamation-circle mr-3";
        case NotificationType.INFO:
          return "pi pi-info-circle mr-3";
        case NotificationType.SUCCESS:
          return "pi pi-check-circle mr-3";
        case NotificationType.WARN:
          return "pi pi-exclamation-triangle mr-3";
        default:
          return "pi pi-bell mr-3";
      }
    },
  },
})
export default class NotificationCard extends Vue {
  public notification!: Notification;
}

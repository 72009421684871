import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, vModelText as _vModelText, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "grid m-0" }
const _hoisted_2 = { class: "col-12 p-0 m-0 mt-1" }
const _hoisted_3 = { class: "grid mt-1" }
const _hoisted_4 = { class: "col-12 lg:col-4" }
const _hoisted_5 = { class: "grid p-0" }
const _hoisted_6 = { class: "col-12 lg:col-12" }
const _hoisted_7 = { class: "p-inputgroup mt-2" }
const _hoisted_8 = { class: "p-inputgroup mt-2" }
const _hoisted_9 = { class: "p-inputgroup mt-2" }
const _hoisted_10 = { class: "p-inputgroup mt-2" }
const _hoisted_11 = { class: "p-inputgroup mt-2" }
const _hoisted_12 = { class: "col-12 lg:col-8" }
const _hoisted_13 = { class: "grid col-12 p-0 m-0" }
const _hoisted_14 = { class: "formgrid grid" }
const _hoisted_15 = { class: "col-12 p-0 m-0" }
const _hoisted_16 = { class: "p-inputgroup mt-2" }
const _hoisted_17 = {
  class: "p-inputgroup-addon font-semibold general-label-color",
  style: { width: '104px' }
}
const _hoisted_18 = { class: "formgrid grid p-0 m-0" }
const _hoisted_19 = {
  key: 0,
  class: "col-12 lg:col-6"
}
const _hoisted_20 = { class: "p-inputgroup mt-2" }
const _hoisted_21 = {
  key: 1,
  class: "col-12 lg:col-6"
}
const _hoisted_22 = { class: "p-inputgroup mt-2" }
const _hoisted_23 = {
  key: 2,
  class: "col-12 lg:col-6"
}
const _hoisted_24 = { class: "p-inputgroup mt-2" }
const _hoisted_25 = {
  key: 3,
  class: "col-12 lg:col-6"
}
const _hoisted_26 = { class: "p-inputgroup mt-2" }
const _hoisted_27 = { class: "flex col-12 justify-content-end p-0 mt-2" }
const _hoisted_28 = { key: 0 }
const _hoisted_29 = { class: "col-12 p-0 m-0 mt-1" }
const _hoisted_30 = { class: "grid mt-1" }
const _hoisted_31 = { class: "col-12" }
const _hoisted_32 = { class: "grid p-0" }
const _hoisted_33 = { class: "col-12 md:col-4 lg:col-4 xl:col-4" }
const _hoisted_34 = { class: "p-inputgroup mt-2" }
const _hoisted_35 = { class: "col-12 md:col-8 lg:col-8 xl:col-8" }
const _hoisted_36 = { class: "p-inputgroup mt-2" }
const _hoisted_37 = { class: "col-12 md:col-6 lg:col-4 xl:col-4" }
const _hoisted_38 = { class: "p-inputgroup mt-2" }
const _hoisted_39 = { class: "col-12 md:col-6 lg:col-4 xl:col-4" }
const _hoisted_40 = { class: "p-inputgroup mt-2" }
const _hoisted_41 = { class: "col-12 md:col-6 lg:col-4 xl:col-4" }
const _hoisted_42 = { class: "p-inputgroup mt-2" }
const _hoisted_43 = { class: "col-12 p-0 m-0 mt-1" }
const _hoisted_44 = { class: "grid mt-1" }
const _hoisted_45 = { class: "col-12 lg:col-3" }
const _hoisted_46 = { class: "grid" }
const _hoisted_47 = { class: "col-12" }
const _hoisted_48 = { class: "p-inputgroup" }
const _hoisted_49 = { class: "p-inputgroup mt-2" }
const _hoisted_50 = { class: "p-inputgroup mt-2" }
const _hoisted_51 = { class: "p-inputgroup mt-2" }
const _hoisted_52 = { class: "p-inputgroup mt-2" }
const _hoisted_53 = { class: "field-checkbox mt-2" }
const _hoisted_54 = { class: "col-12 lg:col-3" }
const _hoisted_55 = { class: "grid" }
const _hoisted_56 = { class: "col-12 lg:col-12" }
const _hoisted_57 = { class: "p-inputgroup" }
const _hoisted_58 = { class: "p-inputgroup mt-2" }
const _hoisted_59 = { class: "p-inputgroup ml-2 pr-2" }
const _hoisted_60 = { class: "field-checkbox mt-1" }
const _hoisted_61 = { class: "col-12 lg:col-3" }
const _hoisted_62 = { class: "grid" }
const _hoisted_63 = { class: "col-12 lg:col-12" }
const _hoisted_64 = { class: "p-inputgroup" }
const _hoisted_65 = { class: "p-inputgroup mt-2" }
const _hoisted_66 = { class: "p-inputgroup ml-2 pr-2" }
const _hoisted_67 = { class: "p-inputgroup ml-2 pr-2" }
const _hoisted_68 = { class: "field-checkbox mt-1 ml-2" }
const _hoisted_69 = { class: "col-12 lg:col-3" }
const _hoisted_70 = {
  id: "datatable-representative",
  class: "formgrid flex justify-content-between align-items-center"
}
const _hoisted_71 = { class: "col-12 p-0 m-0 mt-1" }
const _hoisted_72 = { class: "confirmation-content" }
const _hoisted_73 = { key: 0 }
const _hoisted_74 = { class: "field grid" }
const _hoisted_75 = { class: "col" }
const _hoisted_76 = { class: "field grid" }
const _hoisted_77 = { class: "col" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Calendar = _resolveComponent("Calendar")!
  const _component_Card = _resolveComponent("Card")!
  const _component_Button = _resolveComponent("Button")!
  const _component_CollapsibleSection = _resolveComponent("CollapsibleSection")!
  const _component_DropDown = _resolveComponent("DropDown")!
  const _component_CheckBox = _resolveComponent("CheckBox")!
  const _component_Column = _resolveComponent("Column")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _component_TextArea = _resolveComponent("TextArea")!
  const _component_Dialog = _resolveComponent("Dialog")!
  const _component_ConfirmChangesDialog = _resolveComponent("ConfirmChangesDialog")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_CollapsibleSection, {
        isOpen: _ctx.isContactIconDown,
        header: "Contact Info",
        "onUpdate:isOpen": _cache[9] || (_cache[9] = ($event: any) => (_ctx.toggleIcon('Contacts')))
      }, {
        content: _withCtx(() => [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_Card, null, {
                content: _withCtx(() => [
                  _cache[45] || (_cache[45] = _createElementVNode("h4", { id: "header-contact-info" }, "Contact Info", -1)),
                  _createElementVNode("div", _hoisted_5, [
                    _createElementVNode("div", _hoisted_6, [
                      _createElementVNode("div", _hoisted_7, [
                        _cache[40] || (_cache[40] = _createElementVNode("small", {
                          class: "p-inputgroup-addon font-semibold general-label-color",
                          style: { width: '104px' }
                        }, "Customer ID", -1)),
                        _createVNode(_component_InputText, {
                          type: "text",
                          modelValue: this.customer.cust_id,
                          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((this.customer.cust_id) = $event)),
                          class: "text-sm"
                        }, null, 8, ["modelValue"])
                      ]),
                      _createElementVNode("div", _hoisted_8, [
                        _cache[41] || (_cache[41] = _createElementVNode("small", {
                          class: "p-inputgroup-addon font-semibold general-label-color",
                          style: { width: '104px' }
                        }, "Name", -1)),
                        _createVNode(_component_InputText, {
                          type: "text",
                          modelValue: _ctx.customer.name,
                          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.customer.name) = $event)),
                          class: "text-sm"
                        }, null, 8, ["modelValue"])
                      ]),
                      _createElementVNode("div", _hoisted_9, [
                        _cache[42] || (_cache[42] = _createElementVNode("small", {
                          class: "p-inputgroup-addon font-semibold general-label-color",
                          style: { width: '104px' }
                        }, "Phone", -1)),
                        _createVNode(_component_InputText, {
                          type: "text",
                          modelValue: _ctx.customer.phone,
                          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.customer.phone) = $event)),
                          class: "text-sm"
                        }, null, 8, ["modelValue"])
                      ]),
                      _createElementVNode("div", _hoisted_10, [
                        _cache[43] || (_cache[43] = _createElementVNode("small", {
                          class: "p-inputgroup-addon font-semibold general-label-color",
                          style: { width: '104px' }
                        }, "Date", -1)),
                        _createVNode(_component_Calendar, {
                          modelValue: _ctx.customer.date,
                          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.customer.date) = $event)),
                          showIcon: true,
                          dateFormat: "mm-dd-yy",
                          inputClass: "text-sm",
                          onDateSelect: _ctx.formatDate
                        }, null, 8, ["modelValue", "onDateSelect"])
                      ]),
                      _createElementVNode("div", _hoisted_11, [
                        _cache[44] || (_cache[44] = _createElementVNode("small", {
                          class: "p-inputgroup-addon font-semibold general-label-color",
                          style: { width: '104px' }
                        }, "Inactive Date", -1)),
                        _createVNode(_component_Calendar, {
                          modelValue: _ctx.customer.inactive_date,
                          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.customer.inactive_date) = $event)),
                          showIcon: true,
                          dateFormat: "mm-dd-yy",
                          inputClass: "text-sm",
                          onDateSelect: _ctx.formatInactiveDate,
                          onChange: _ctx.formatInactiveDate,
                          onClearClick: _ctx.formatInactiveDate,
                          showButtonBar: ""
                        }, null, 8, ["modelValue", "onDateSelect", "onChange", "onClearClick"])
                      ])
                    ])
                  ])
                ]),
                _: 1
              })
            ]),
            _createElementVNode("div", _hoisted_12, [
              _createVNode(_component_Card, null, {
                content: _withCtx(() => [
                  _cache[50] || (_cache[50] = _createElementVNode("h4", { id: "header-mailing-address" }, "Mailing Address", -1)),
                  _createElementVNode("div", _hoisted_13, [
                    _createElementVNode("div", _hoisted_14, [
                      _createElementVNode("div", _hoisted_15, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.customer.address_items, (item, index) => {
                          return (_openBlock(), _createElementBlock("div", {
                            class: "col-12 lg:col-6",
                            key: item.cust_id
                          }, [
                            _createElementVNode("div", _hoisted_16, [
                              _createElementVNode("small", _hoisted_17, "Street - " + _toDisplayString(index), 1),
                              _createVNode(_component_InputText, {
                                type: "text",
                                id: "Street",
                                modelValue: item.address,
                                "onUpdate:modelValue": ($event: any) => ((item.address) = $event),
                                class: "text-sm"
                              }, null, 8, ["modelValue", "onUpdate:modelValue"]),
                              _createVNode(_component_Button, {
                                title: "Add Address Line",
                                icon: "pi pi-plus",
                                class: "text-sm font-bold",
                                onClick: ($event: any) => (_ctx.addAddressLine(index))
                              }, null, 8, ["onClick"]),
                              _createVNode(_component_Button, {
                                icon: "pi pi-trash",
                                title: "Remove Address Line",
                                class: "text-sm font-bold",
                                onClick: ($event: any) => (_ctx.deleteAddressLine(index))
                              }, null, 8, ["onClick"])
                            ])
                          ]))
                        }), 128))
                      ]),
                      _createElementVNode("div", _hoisted_18, [
                        (_ctx.customer.address_items != null)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
                              _createElementVNode("div", _hoisted_20, [
                                _cache[46] || (_cache[46] = _createElementVNode("small", {
                                  class: "p-inputgroup-addon font-semibold general-label-color",
                                  style: { width: '104px' }
                                }, "City", -1)),
                                _createVNode(_component_InputText, {
                                  type: "text",
                                  class: "text-sm",
                                  modelValue: _ctx.customer.sort_city,
                                  "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.customer.sort_city) = $event))
                                }, null, 8, ["modelValue"])
                              ])
                            ]))
                          : _createCommentVNode("", true),
                        (_ctx.customer.address_items != null)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_21, [
                              _createElementVNode("div", _hoisted_22, [
                                _cache[47] || (_cache[47] = _createElementVNode("small", {
                                  class: "p-inputgroup-addon font-semibold general-label-color",
                                  style: { width: '104px' }
                                }, " State", -1)),
                                _createVNode(_component_InputText, {
                                  type: "text",
                                  modelValue: _ctx.customer.sort_state,
                                  "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.customer.sort_state) = $event)),
                                  class: "text-sm"
                                }, null, 8, ["modelValue"])
                              ])
                            ]))
                          : _createCommentVNode("", true),
                        (_ctx.customer.address_items != null)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_23, [
                              _createElementVNode("div", _hoisted_24, [
                                _cache[48] || (_cache[48] = _createElementVNode("small", {
                                  class: "p-inputgroup-addon font-semibold general-label-color",
                                  style: { width: '104px' }
                                }, "Zip", -1)),
                                _createVNode(_component_InputText, {
                                  type: "text",
                                  modelValue: _ctx.customer.sort_zip,
                                  "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.customer.sort_zip) = $event)),
                                  class: "text-sm"
                                }, null, 8, ["modelValue"])
                              ])
                            ]))
                          : _createCommentVNode("", true),
                        (_ctx.customer.address_items != null)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_25, [
                              _createElementVNode("div", _hoisted_26, [
                                _cache[49] || (_cache[49] = _createElementVNode("small", {
                                  class: "p-inputgroup-addon font-semibold general-label-color",
                                  style: { width: '104px' }
                                }, "Country", -1)),
                                _createVNode(_component_InputText, {
                                  type: "text",
                                  modelValue: _ctx.customer.sort_country,
                                  "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.customer.sort_country) = $event)),
                                  class: "text-sm"
                                }, null, 8, ["modelValue"])
                              ])
                            ]))
                          : _createCommentVNode("", true)
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_27, [
                      (_ctx.customer.address_items != null)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_28))
                        : _createCommentVNode("", true)
                    ])
                  ])
                ]),
                _: 1
              })
            ])
          ])
        ]),
        _: 1
      }, 8, ["isOpen"])
    ]),
    _createElementVNode("div", _hoisted_29, [
      _createVNode(_component_CollapsibleSection, {
        isOpen: _ctx.isARIconDown,
        header: "AR",
        "onUpdate:isOpen": _cache[15] || (_cache[15] = ($event: any) => (_ctx.toggleIcon('AR')))
      }, {
        content: _withCtx(() => [
          _createElementVNode("div", _hoisted_30, [
            _createElementVNode("div", _hoisted_31, [
              _createVNode(_component_Card, null, {
                content: _withCtx(() => [
                  _cache[56] || (_cache[56] = _createElementVNode("h4", { id: "header-contact-info" }, "AR", -1)),
                  _createElementVNode("div", _hoisted_32, [
                    _createElementVNode("div", _hoisted_33, [
                      _createElementVNode("div", _hoisted_34, [
                        _cache[51] || (_cache[51] = _createElementVNode("small", {
                          class: "p-inputgroup-addon font-semibold general-label-color",
                          style: { width: '104px' }
                        }, "Open Orders", -1)),
                        _createVNode(_component_InputText, {
                          type: "text",
                          modelValue: _ctx.customer.open_order_bal,
                          "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.customer.open_order_bal) = $event)),
                          style: {"text-align":"right"},
                          class: "text-sm",
                          readonly: ""
                        }, null, 8, ["modelValue"])
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_35, [
                      _createElementVNode("div", _hoisted_36, [
                        _cache[52] || (_cache[52] = _createElementVNode("small", {
                          class: "p-inputgroup-addon font-semibold general-label-color",
                          style: { width: '104px' }
                        }, "Message", -1)),
                        _createVNode(_component_InputText, {
                          type: "text",
                          modelValue: _ctx.creditWarning,
                          "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.creditWarning) = $event)),
                          class: "text-sm",
                          readonly: ""
                        }, null, 8, ["modelValue"])
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_37, [
                      _createElementVNode("div", _hoisted_38, [
                        _cache[53] || (_cache[53] = _createElementVNode("small", {
                          class: "p-inputgroup-addon font-semibold general-label-color",
                          style: { width: '104px' }
                        }, "Current AR Bal", -1)),
                        _createVNode(_component_InputText, {
                          type: "text",
                          modelValue: _ctx.customer.ar_bal,
                          "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.customer.ar_bal) = $event)),
                          style: {"text-align":"right"},
                          class: "text-sm",
                          readonly: ""
                        }, null, 8, ["modelValue"])
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_39, [
                      _createElementVNode("div", _hoisted_40, [
                        _cache[54] || (_cache[54] = _createElementVNode("small", {
                          class: "p-inputgroup-addon font-semibold general-label-color",
                          style: { width: '104px' }
                        }, "Highest AR Bal", -1)),
                        _createVNode(_component_InputText, {
                          type: "text",
                          modelValue: _ctx.customer.highest_ar_bal,
                          "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.customer.highest_ar_bal) = $event)),
                          style: {"text-align":"right"},
                          class: "text-sm",
                          readonly: ""
                        }, null, 8, ["modelValue"])
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_41, [
                      _createElementVNode("div", _hoisted_42, [
                        _cache[55] || (_cache[55] = _createElementVNode("small", {
                          class: "p-inputgroup-addon font-semibold general-label-color",
                          style: { width: '104px' }
                        }, "Average AR Bal", -1)),
                        _createVNode(_component_InputText, {
                          type: "text",
                          modelValue: _ctx.customer.avg_ar_bal,
                          "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.customer.avg_ar_bal) = $event)),
                          style: {"text-align":"right"},
                          class: "text-sm",
                          readonly: ""
                        }, null, 8, ["modelValue"])
                      ])
                    ])
                  ])
                ]),
                _: 1
              })
            ])
          ])
        ]),
        _: 1
      }, 8, ["isOpen"])
    ]),
    _createElementVNode("div", _hoisted_43, [
      _createVNode(_component_CollapsibleSection, {
        isOpen: _ctx.isCreditIconDown,
        header: "Credit Settings",
        "onUpdate:isOpen": _cache[32] || (_cache[32] = ($event: any) => (_ctx.toggleIcon('Credit')))
      }, {
        content: _withCtx(() => [
          _createElementVNode("div", _hoisted_44, [
            _createElementVNode("div", _hoisted_45, [
              _createVNode(_component_Card, null, {
                content: _withCtx(() => [
                  _cache[63] || (_cache[63] = _createElementVNode("h4", { id: "header-credit-settings" }, "Credit Settings", -1)),
                  _createElementVNode("div", _hoisted_46, [
                    _createElementVNode("div", _hoisted_47, [
                      _createElementVNode("div", _hoisted_48, [
                        _cache[57] || (_cache[57] = _createElementVNode("small", {
                          class: "p-inputgroup-addon font-semibold general-label-color",
                          style: { width: '104px' }
                        }, "Terms", -1)),
                        _createVNode(_component_DropDown, {
                          id: "terms",
                          modelValue: _ctx.customer.terms,
                          "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.customer.terms) = $event)),
                          options: _ctx.termsList,
                          placeholder: "Select Terms",
                          style: {"width":"20px"},
                          class: "text-sm"
                        }, null, 8, ["modelValue", "options"])
                      ]),
                      _createElementVNode("div", _hoisted_49, [
                        _cache[58] || (_cache[58] = _createElementVNode("small", {
                          class: "p-inputgroup-addon font-semibold general-label-color",
                          style: { width: '104px' }
                        }, "Currency", -1)),
                        _createVNode(_component_DropDown, {
                          modelValue: _ctx.customer.currency_code,
                          "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.customer.currency_code) = $event)),
                          options: _ctx.currencyList,
                          placeholder: "Select Currency",
                          class: "text-sm"
                        }, null, 8, ["modelValue", "options"])
                      ]),
                      _createElementVNode("div", _hoisted_50, [
                        _cache[59] || (_cache[59] = _createElementVNode("small", {
                          class: "p-inputgroup-addon font-semibold general-label-color",
                          style: { width: '104px' }
                        }, "Dollar Limit", -1)),
                        _createVNode(_component_InputText, {
                          id: "dollarlimit",
                          type: "text",
                          modelValue: _ctx.customer.credit_limit,
                          "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.customer.credit_limit) = $event)),
                          class: "text-sm"
                        }, null, 8, ["modelValue"])
                      ]),
                      _createElementVNode("div", _hoisted_51, [
                        _cache[60] || (_cache[60] = _createElementVNode("small", {
                          class: "p-inputgroup-addon font-semibold general-label-color",
                          style: { width: '104px' }
                        }, "Days Limit", -1)),
                        _createVNode(_component_InputText, {
                          type: "text",
                          modelValue: _ctx.customer.past_due_days,
                          "onUpdate:modelValue": _cache[19] || (_cache[19] = ($event: any) => ((_ctx.customer.past_due_days) = $event)),
                          class: "text-sm"
                        }, null, 8, ["modelValue"])
                      ]),
                      _createElementVNode("div", _hoisted_52, [
                        _cache[61] || (_cache[61] = _createElementVNode("small", {
                          class: "p-inputgroup-addon font-semibold general-label-color",
                          style: { width: '104px' }
                        }, "Bank No", -1)),
                        _createVNode(_component_InputText, {
                          type: "text",
                          modelValue: _ctx.customer.bank_no,
                          "onUpdate:modelValue": _cache[20] || (_cache[20] = ($event: any) => ((_ctx.customer.bank_no) = $event)),
                          class: "text-sm"
                        }, null, 8, ["modelValue"])
                      ]),
                      _createElementVNode("div", _hoisted_53, [
                        _createVNode(_component_CheckBox, {
                          id: "credit",
                          name: "Credit Hold",
                          modelValue: _ctx.customer.credit_hold,
                          "onUpdate:modelValue": _cache[21] || (_cache[21] = ($event: any) => ((_ctx.customer.credit_hold) = $event)),
                          trueValue: "Y",
                          falseValue: null,
                          binary: true,
                          class: "field-checkbox m-2"
                        }, null, 8, ["modelValue"]),
                        _cache[62] || (_cache[62] = _createElementVNode("label", {
                          for: "credit",
                          class: "text-sm"
                        }, "Credit Hold", -1))
                      ])
                    ])
                  ])
                ]),
                _: 1
              })
            ]),
            _createElementVNode("div", _hoisted_54, [
              _createVNode(_component_Card, null, {
                content: _withCtx(() => [
                  _cache[69] || (_cache[69] = _createElementVNode("h4", { id: "header-pricing" }, "Pricing", -1)),
                  _createElementVNode("div", _hoisted_55, [
                    _createElementVNode("div", _hoisted_56, [
                      _createElementVNode("div", _hoisted_57, [
                        _cache[64] || (_cache[64] = _createElementVNode("small", {
                          class: "p-inputgroup-addon font-semibold general-label-color",
                          style: { width: '104px' }
                        }, "Code", -1)),
                        _createVNode(_component_DropDown, {
                          options: this.priceCodes,
                          modelValue: _ctx.customer.code,
                          "onUpdate:modelValue": _cache[22] || (_cache[22] = ($event: any) => ((_ctx.customer.code) = $event)),
                          class: "text-sm"
                        }, null, 8, ["options", "modelValue"])
                      ]),
                      _createElementVNode("div", _hoisted_58, [
                        _cache[65] || (_cache[65] = _createElementVNode("small", {
                          class: "p-inputgroup-addon font-semibold general-label-color",
                          style: { width: '104px' }
                        }, "Discount(%)", -1)),
                        _createVNode(_component_InputText, {
                          type: "text",
                          modelValue: _ctx.customer.disc,
                          "onUpdate:modelValue": _cache[23] || (_cache[23] = ($event: any) => ((_ctx.customer.disc) = $event)),
                          class: "text-sm"
                        }, null, 8, ["modelValue"])
                      ])
                    ]),
                    _cache[68] || (_cache[68] = _createElementVNode("h4", {
                      class: "ml-2",
                      id: "header-bill-to-customer"
                    }, " Bill To Customer ", -1)),
                    _createElementVNode("div", _hoisted_59, [
                      _cache[66] || (_cache[66] = _createElementVNode("small", {
                        class: "p-inputgroup-addon font-semibold general-label-color",
                        style: { width: '104px' }
                      }, "Bill To", -1)),
                      _createVNode(_component_InputText, {
                        type: "text",
                        modelValue: _ctx.customer.bill_to,
                        "onUpdate:modelValue": _cache[24] || (_cache[24] = ($event: any) => ((_ctx.customer.bill_to) = $event)),
                        class: "text-sm"
                      }, null, 8, ["modelValue"])
                    ]),
                    _createElementVNode("div", _hoisted_60, [
                      _createVNode(_component_CheckBox, {
                        id: "marketing",
                        name: "Marketing",
                        modelValue: _ctx.customer.mrk_opt_out,
                        "onUpdate:modelValue": _cache[25] || (_cache[25] = ($event: any) => ((_ctx.customer.mrk_opt_out) = $event)),
                        trueValue: "Y",
                        falseValue: null,
                        binary: true,
                        class: "field-checkbox m-2"
                      }, null, 8, ["modelValue"]),
                      _cache[67] || (_cache[67] = _createElementVNode("label", {
                        for: "marketing",
                        class: "text-sm"
                      }, "Exclude from marketing", -1))
                    ])
                  ])
                ]),
                _: 1
              })
            ]),
            _createElementVNode("div", _hoisted_61, [
              _createVNode(_component_Card, null, {
                content: _withCtx(() => [
                  _cache[78] || (_cache[78] = _createElementVNode("h4", { id: "header-shipping" }, "Shipping", -1)),
                  _createElementVNode("div", _hoisted_62, [
                    _createElementVNode("div", _hoisted_63, [
                      _createElementVNode("div", _hoisted_64, [
                        _cache[70] || (_cache[70] = _createElementVNode("small", {
                          class: "p-inputgroup-addon font-semibold general-label-color",
                          style: { width: '104px' }
                        }, "Ship Via", -1)),
                        _createVNode(_component_DropDown, {
                          modelValue: _ctx.customer.ship_via,
                          "onUpdate:modelValue": _cache[26] || (_cache[26] = ($event: any) => ((_ctx.customer.ship_via) = $event)),
                          options: this.shipVia,
                          class: "text-sm"
                        }, null, 8, ["modelValue", "options"])
                      ]),
                      _createElementVNode("div", _hoisted_65, [
                        _cache[71] || (_cache[71] = _createElementVNode("small", {
                          class: "p-inputgroup-addon font-semibold general-label-color",
                          style: { width: '104px' }
                        }, "Freight", -1)),
                        _createVNode(_component_DropDown, {
                          class: "text-sm",
                          modelValue: this.freightCode,
                          "onUpdate:modelValue": _cache[27] || (_cache[27] = ($event: any) => ((this.freightCode) = $event)),
                          options: this.freightList,
                          placeholder: "Select Freight",
                          onChange: _ctx.freightChange
                        }, null, 8, ["modelValue", "options", "onChange"])
                      ])
                    ]),
                    _cache[76] || (_cache[76] = _createElementVNode("h4", {
                      class: "ml-2",
                      id: "header-website"
                    }, "Ecommerce Level", -1)),
                    _createElementVNode("div", _hoisted_66, [
                      _cache[72] || (_cache[72] = _createElementVNode("small", {
                        class: "p-inputgroup-addon font-semibold general-label-color",
                        style: { width: '104px' }
                      }, "Ecommerce Level", -1)),
                      _createVNode(_component_InputText, {
                        type: "text",
                        modelValue: _ctx.customer.ecommerce_level,
                        "onUpdate:modelValue": _cache[28] || (_cache[28] = ($event: any) => ((_ctx.customer.ecommerce_level) = $event)),
                        class: "text-sm"
                      }, null, 8, ["modelValue"])
                    ]),
                    _cache[77] || (_cache[77] = _createElementVNode("h4", {
                      class: "ml-2",
                      id: "header-website"
                    }, "Website", -1)),
                    _createElementVNode("div", _hoisted_67, [
                      _cache[73] || (_cache[73] = _createElementVNode("small", {
                        class: "p-inputgroup-addon font-semibold general-label-color",
                        style: { width: '104px' }
                      }, "URL", -1)),
                      _createVNode(_component_InputText, {
                        type: "text",
                        modelValue: _ctx.customer.website,
                        "onUpdate:modelValue": _cache[29] || (_cache[29] = ($event: any) => ((_ctx.customer.website) = $event)),
                        class: "text-sm"
                      }, null, 8, ["modelValue"])
                    ]),
                    _createElementVNode("div", _hoisted_68, [
                      _createVNode(_component_CheckBox, {
                        id: "Partials",
                        name: "Partials",
                        modelValue: _ctx.customer.partials_ok,
                        "onUpdate:modelValue": _cache[30] || (_cache[30] = ($event: any) => ((_ctx.customer.partials_ok) = $event)),
                        trueValue: "Y",
                        falseValue: null,
                        binary: true
                      }, null, 8, ["modelValue"]),
                      _cache[74] || (_cache[74] = _createElementVNode("label", {
                        for: "Partials",
                        class: "text-sm"
                      }, "Partials", -1)),
                      _createVNode(_component_CheckBox, {
                        id: "exporter",
                        name: "Exporter",
                        modelValue: _ctx.customer.exporter,
                        "onUpdate:modelValue": _cache[31] || (_cache[31] = ($event: any) => ((_ctx.customer.exporter) = $event)),
                        trueValue: "Y",
                        falseValue: null,
                        binary: true,
                        class: "field-checkbox m-2"
                      }, null, 8, ["modelValue"]),
                      _cache[75] || (_cache[75] = _createElementVNode("label", {
                        for: "marketing",
                        class: "text-sm"
                      }, "Exporter", -1))
                    ])
                  ])
                ]),
                _: 1
              })
            ]),
            _createElementVNode("div", _hoisted_69, [
              _createVNode(_component_Card, null, {
                content: _withCtx(() => [
                  _createElementVNode("div", _hoisted_70, [
                    _cache[79] || (_cache[79] = _createElementVNode("h4", null, "Representative", -1)),
                    _createVNode(_component_Button, {
                      label: "Add",
                      icon: "pi pi-plus",
                      class: "text-sm font-bold",
                      onClick: _ctx.newRep
                    }, null, 8, ["onClick"])
                  ]),
                  _createVNode(_component_DataTable, {
                    value: _ctx.customer.rep_items,
                    class: "p-datatable-sm text-sm font-semibold",
                    breakpoint: "1400px"
                  }, {
                    empty: _withCtx(() => _cache[80] || (_cache[80] = [
                      _createTextVNode(" No records found ")
                    ])),
                    default: _withCtx(() => [
                      _createVNode(_component_Column, {
                        field: "rep",
                        header: "Name",
                        bodyClass: "font-bold",
                        headerClass: "general-label-color"
                      }),
                      _createVNode(_component_Column, {
                        field: "order_pct",
                        header: "Order Percent",
                        bodyClass: "font-bold",
                        headerClass: "general-label-color",
                        colspan: 2
                      }),
                      _createVNode(_component_Column, null, {
                        body: _withCtx((slotProps) => [
                          _createVNode(_component_Button, {
                            icon: "pi pi-pencil",
                            title: "Edit",
                            class: "mr-2 text-sm font-bold",
                            onClick: ($event: any) => (_ctx.editRep(slotProps.data))
                          }, null, 8, ["onClick"]),
                          _createVNode(_component_Button, {
                            icon: "pi pi-trash",
                            title: "Delete",
                            class: "mt-2 text-sm font-bold",
                            onClick: ($event: any) => (_ctx.confirmDeleteRep(slotProps.data))
                          }, null, 8, ["onClick"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["value"])
                ]),
                _: 1
              })
            ])
          ])
        ]),
        _: 1
      }, 8, ["isOpen"])
    ]),
    _createElementVNode("div", _hoisted_71, [
      _createVNode(_component_CollapsibleSection, {
        isOpen: _ctx.isNoteIconDown,
        header: "Notes",
        "onUpdate:isOpen": _cache[34] || (_cache[34] = ($event: any) => (_ctx.toggleIcon('Notes')))
      }, {
        content: _withCtx(() => [
          _createVNode(_component_Card, { class: "mt-2" }, {
            content: _withCtx(() => [
              _cache[81] || (_cache[81] = _createElementVNode("h4", null, "Notes", -1)),
              _createVNode(_component_TextArea, {
                modelValue: _ctx.customer.notes,
                "onUpdate:modelValue": _cache[33] || (_cache[33] = ($event: any) => ((_ctx.customer.notes) = $event)),
                value: _ctx.customer.notes,
                rows: "9",
                placeholder: "Enter note here...",
                class: "text-sm"
              }, null, 8, ["modelValue", "value"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["isOpen"])
    ]),
    _createVNode(_component_Dialog, {
      visible: _ctx.deleteRepDialog,
      "onUpdate:visible": _cache[36] || (_cache[36] = ($event: any) => ((_ctx.deleteRepDialog) = $event)),
      style: { width: '450px' },
      header: "Confirm",
      modal: true,
      draggable: false
    }, {
      footer: _withCtx(() => [
        _createVNode(_component_Button, {
          label: "No",
          icon: "pi pi-times",
          class: "p-button-text",
          onClick: _cache[35] || (_cache[35] = ($event: any) => (_ctx.deleteRepDialog = false))
        }),
        _createVNode(_component_Button, {
          label: "Yes",
          icon: "pi pi-check",
          class: "p-button-text",
          onClick: _ctx.deleteRep
        }, null, 8, ["onClick"])
      ]),
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_72, [
          _cache[82] || (_cache[82] = _createElementVNode("i", {
            class: "pi pi-exclamation-triangle mr-3",
            style: {"font-size":"2rem"}
          }, null, -1)),
          (_ctx.rep)
            ? (_openBlock(), _createElementBlock("small", _hoisted_73, "Are you sure you want to delete the selected Rep?"))
            : _createCommentVNode("", true)
        ])
      ]),
      _: 1
    }, 8, ["visible"]),
    _createVNode(_component_Dialog, {
      visible: _ctx.editRepDialog,
      "onUpdate:visible": _cache[39] || (_cache[39] = ($event: any) => ((_ctx.editRepDialog) = $event)),
      style: { width: '450px' },
      header: "Rep Details",
      modal: true,
      draggable: false,
      class: "p-fluid"
    }, {
      footer: _withCtx(() => [
        _createVNode(_component_Button, {
          label: "Cancel",
          icon: "pi pi-times",
          onClick: _ctx.closeEditModal,
          class: "p-button-text"
        }, null, 8, ["onClick"]),
        _createVNode(_component_Button, {
          label: "Yes",
          icon: "pi pi-check",
          onClick: _ctx.saveRep,
          autofocus: ""
        }, null, 8, ["onClick"])
      ]),
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_74, [
          _cache[83] || (_cache[83] = _createElementVNode("label", {
            for: "rep",
            style: {"width":"100px"}
          }, "Name", -1)),
          _createElementVNode("div", _hoisted_75, [
            _withDirectives(_createElementVNode("input", {
              "onUpdate:modelValue": _cache[37] || (_cache[37] = ($event: any) => ((this.rep.rep) = $event)),
              type: "text",
              class: "inputfield"
            }, null, 512), [
              [_vModelText, this.rep.rep]
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_76, [
          _cache[84] || (_cache[84] = _createElementVNode("label", {
            for: "reppct",
            style: {"width":"100px"}
          }, "Percent", -1)),
          _createElementVNode("div", _hoisted_77, [
            _withDirectives(_createElementVNode("input", {
              "onUpdate:modelValue": _cache[38] || (_cache[38] = ($event: any) => ((this.rep.order_pct) = $event)),
              type: "text",
              class: "inputfield"
            }, null, 512), [
              [_vModelText, this.rep.order_pct]
            ])
          ])
        ])
      ]),
      _: 1
    }, 8, ["visible"]),
    _createVNode(_component_ConfirmChangesDialog, { ref: "confirmChanges" }, null, 512)
  ]))
}
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, withKeys as _withKeys, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "grid grid-nogutter m-0" }
const _hoisted_2 = { class: "sm:col-12 md:col-12 lg:col-12" }
const _hoisted_3 = { class: "grid no-gutters flex justify-content-center m-0" }
const _hoisted_4 = { class: "col-12 md:col-12 lg:col-2" }
const _hoisted_5 = { class: "flex justify-content-between mb-3" }
const _hoisted_6 = { class: "text-900 font-medium text-xl" }
const _hoisted_7 = { class: "col-12 md:col-12 lg:col-2" }
const _hoisted_8 = { class: "flex justify-content-between mb-3" }
const _hoisted_9 = { class: "text-900 font-medium text-xl" }
const _hoisted_10 = { class: "col-12 md:col-12 lg:col-2" }
const _hoisted_11 = { class: "flex justify-content-between mb-3" }
const _hoisted_12 = { class: "text-900 font-medium text-xl" }
const _hoisted_13 = { class: "text-500" }
const _hoisted_14 = { class: "col-12 md:col-12 lg:col-2" }
const _hoisted_15 = { class: "flex justify-content-between mb-3" }
const _hoisted_16 = { class: "text-900 font-medium text-xl" }
const _hoisted_17 = { class: "col-12 md:col-12 lg:col-2" }
const _hoisted_18 = { class: "flex justify-content-between mb-3" }
const _hoisted_19 = { class: "text-900 font-medium text-xl" }
const _hoisted_20 = { class: "sm:col-12 md:col-12 lg:col-12 my-2 flex justify-content-center w-full my-4" }
const _hoisted_21 = { class: "p-inputgroup w-9" }
const _hoisted_22 = { class: "grid no-gutters flex justify-content-center w-full m-0" }
const _hoisted_23 = { class: "col-12 md:col-12 lg:col-12" }
const _hoisted_24 = ["onClick"]
const _hoisted_25 = { class: "col-12 md:col-12 lg:col-12" }
const _hoisted_26 = { class: "col-12 md:col-12 lg:col-12" }
const _hoisted_27 = { class: "grid" }
const _hoisted_28 = { class: "grid" }
const _hoisted_29 = { class: "col-12 flex justify-content-between" }
const _hoisted_30 = { class: "my-0 inline" }
const _hoisted_31 = { class: "col-12" }
const _hoisted_32 = { class: "block" }
const _hoisted_33 = { class: "col-12 flex justify-content-between" }
const _hoisted_34 = { class: "col-12 flex justify-content-between" }
const _hoisted_35 = { class: "col-12 flex justify-content-between" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Card = _resolveComponent("Card")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Column = _resolveComponent("Column")!
  const _component_InputNumber = _resolveComponent("InputNumber")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _component_PartModal = _resolveComponent("PartModal")!
  const _component_Dialog = _resolveComponent("Dialog")!
  const _component_ConfirmDialog = _resolveComponent("ConfirmDialog")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_Card, null, {
              content: _withCtx(() => [
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("div", null, [
                    _cache[6] || (_cache[6] = _createElementVNode("span", { class: "block text-500 font-medium mb-3" }, " Work Order #", -1)),
                    _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.workorder.wo_id), 1)
                  ]),
                  _cache[7] || (_cache[7] = _createElementVNode("div", {
                    class: "flex align-items-center justify-content-center bg-blue-100 border-round",
                    style: {"width":"2.5rem","height":"2.5rem"}
                  }, [
                    _createElementVNode("i", { class: "pi pi-exclamation-circle text-blue-500 text-xl" })
                  ], -1))
                ])
              ]),
              _: 1
            })
          ]),
          _createElementVNode("div", _hoisted_7, [
            _createVNode(_component_Card, null, {
              content: _withCtx(() => [
                _createElementVNode("div", _hoisted_8, [
                  _createElementVNode("div", null, [
                    _cache[8] || (_cache[8] = _createElementVNode("span", { class: "block text-500 font-medium mb-3" }, " Status ", -1)),
                    _createElementVNode("div", _hoisted_9, _toDisplayString(this.status[_ctx.workorder.status]), 1)
                  ]),
                  _cache[9] || (_cache[9] = _createElementVNode("div", {
                    class: "flex align-items-center justify-content-center bg-blue-100 border-round",
                    style: {"width":"2.5rem","height":"2.5rem"}
                  }, [
                    _createElementVNode("i", { class: "pi pi-exclamation-circle text-blue-500 text-xl" })
                  ], -1))
                ])
              ]),
              _: 1
            })
          ]),
          _createElementVNode("div", _hoisted_10, [
            _createVNode(_component_Card, null, {
              content: _withCtx(() => [
                _createElementVNode("div", _hoisted_11, [
                  _createElementVNode("div", null, [
                    _cache[10] || (_cache[10] = _createElementVNode("span", { class: "block text-500 font-medium mb-3" }, " Part ", -1)),
                    _createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.workorder.part_no), 1)
                  ]),
                  _cache[11] || (_cache[11] = _createElementVNode("div", {
                    class: "flex align-items-center justify-content-center bg-blue-100 border-round",
                    style: {"width":"2.5rem","height":"2.5rem"}
                  }, [
                    _createElementVNode("i", { class: "pi pi-exclamation-circle text-blue-500 text-xl" })
                  ], -1))
                ]),
                _createElementVNode("span", _hoisted_13, _toDisplayString(_ctx.workorder.description), 1)
              ]),
              _: 1
            })
          ]),
          _createElementVNode("div", _hoisted_14, [
            _createVNode(_component_Card, null, {
              content: _withCtx(() => [
                _createElementVNode("div", _hoisted_15, [
                  _createElementVNode("div", null, [
                    _cache[12] || (_cache[12] = _createElementVNode("span", { class: "block text-500 font-medium mb-3" }, " Release Date #", -1)),
                    _createElementVNode("div", _hoisted_16, _toDisplayString(_ctx.workorder.rel_date), 1)
                  ]),
                  _cache[13] || (_cache[13] = _createElementVNode("div", {
                    class: "flex align-items-center justify-content-center bg-blue-100 border-round",
                    style: {"width":"2.5rem","height":"2.5rem"}
                  }, [
                    _createElementVNode("i", { class: "pi pi-exclamation-circle text-blue-500 text-xl" })
                  ], -1))
                ])
              ]),
              _: 1
            })
          ]),
          _createElementVNode("div", _hoisted_17, [
            _createVNode(_component_Card, null, {
              content: _withCtx(() => [
                _createElementVNode("div", _hoisted_18, [
                  _createElementVNode("div", null, [
                    _cache[14] || (_cache[14] = _createElementVNode("span", { class: "block text-500 font-medium mb-3" }, " Required Date #", -1)),
                    _createElementVNode("div", _hoisted_19, _toDisplayString(_ctx.workorder.req_date), 1)
                  ]),
                  _cache[15] || (_cache[15] = _createElementVNode("div", {
                    class: "flex align-items-center justify-content-center bg-blue-100 border-round",
                    style: {"width":"2.5rem","height":"2.5rem"}
                  }, [
                    _createElementVNode("i", { class: "pi pi-exclamation-circle text-blue-500 text-xl" })
                  ], -1))
                ])
              ]),
              _: 1
            })
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_20, [
        _createElementVNode("div", _hoisted_21, [
          _createVNode(_component_InputText, {
            type: "text",
            placeholder: "Part Number...",
            modelValue: this.partNum,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((this.partNum) = $event)),
            ref: "input",
            autofocus: "",
            class: "h-3rem",
            onKeyup: _cache[1] || (_cache[1] = _withKeys(($event: any) => (this.addPart(this.partNum)), ["enter"]))
          }, null, 8, ["modelValue"]),
          _createVNode(_component_Button, {
            onClick: _cache[2] || (_cache[2] = ($event: any) => (this.addPart(this.partNum))),
            icon: "pi pi-search"
          })
        ])
      ]),
      _createElementVNode("div", _hoisted_22, [
        _createElementVNode("div", _hoisted_23, [
          _createVNode(_component_Card, null, {
            content: _withCtx(() => [
              _createVNode(_component_DataTable, {
                value: this.pickerList || [],
                responsiveLayout: "stack",
                breakpoint: "1372px"
              }, {
                header: _withCtx(() => _cache[16] || (_cache[16] = [
                  _createElementVNode("div", { class: "flex justify-content-between" }, [
                    _createElementVNode("h3", null, "Pick List")
                  ], -1)
                ])),
                empty: _withCtx(() => _cache[17] || (_cache[17] = [
                  _createTextVNode(" No Items to pick. ")
                ])),
                footer: _withCtx(() => [
                  _createVNode(_component_Button, {
                    label: "Submit",
                    icon: "pi pi-check",
                    iconPos: "left",
                    onClick: this.submitPick,
                    loading: this.loading
                  }, null, 8, ["onClick", "loading"]),
                  _createVNode(_component_Button, {
                    label: "Pull Items",
                    icon: "pi pi-sort-down",
                    iconPos: "left",
                    class: "ml-1",
                    onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.confirmPull()))
                  })
                ]),
                default: _withCtx(() => [
                  _createVNode(_component_Column, {
                    field: "part_no",
                    header: "Part No."
                  }, {
                    body: _withCtx(({ data }) => [
                      _createElementVNode("span", {
                        class: "link",
                        onClick: ($event: any) => (_ctx.inventoryModal(data.part_no))
                      }, _toDisplayString(data.part_no), 9, _hoisted_24)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_Column, {
                    field: "scan_qty",
                    header: "Scanned #"
                  }, {
                    body: _withCtx(({ data }) => [
                      _createVNode(_component_InputNumber, {
                        modelValue: data.scan_qty,
                        "onUpdate:modelValue": ($event: any) => ((data.scan_qty) = $event),
                        min: 0,
                        buttonLayout: "horizontal",
                        showButtons: "",
                        decrementButtonClass: "p-button-danger",
                        incrementButtonClass: "p-button-success",
                        incrementButtonIcon: "pi pi-plus",
                        decrementButtonIcon: "pi pi-minus",
                        inputStyle: { 'max-width': '120px' }
                      }, null, 8, ["modelValue", "onUpdate:modelValue"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_Column, {
                    field: "bin_number",
                    header: "Bin#"
                  }, {
                    body: _withCtx(({ data }) => [
                      _createVNode(_component_InputText, {
                        modelValue: data.bin_number,
                        "onUpdate:modelValue": ($event: any) => ((data.bin_number) = $event)
                      }, null, 8, ["modelValue", "onUpdate:modelValue"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_Column, {
                    field: "pull_lot",
                    header: "Lot#"
                  }, {
                    body: _withCtx(({ data }) => [
                      _createVNode(_component_InputText, {
                        modelValue: data.pull_lot,
                        "onUpdate:modelValue": ($event: any) => ((data.pull_lot) = $event)
                      }, null, 8, ["modelValue", "onUpdate:modelValue"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_Column, {
                    field: "ext_qty",
                    header: "Qty#"
                  }, {
                    body: _withCtx(({ data }) => [
                      _createVNode(_component_InputText, {
                        modelValue: data.ext_qty,
                        "onUpdate:modelValue": ($event: any) => ((data.ext_qty) = $event)
                      }, null, 8, ["modelValue", "onUpdate:modelValue"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_Column, {
                    field: "pull_loc",
                    header: "Pull Location"
                  }, {
                    body: _withCtx(({ data }) => [
                      _createVNode(_component_InputText, {
                        modelValue: data.pull_loc,
                        "onUpdate:modelValue": ($event: any) => ((data.pull_loc) = $event)
                      }, null, 8, ["modelValue", "onUpdate:modelValue"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_Column, null, {
                    body: _withCtx(({ data, index }) => [
                      _createElementVNode("div", null, [
                        _createVNode(_component_Button, {
                          type: "button",
                          icon: "pi pi-clone",
                          onClick: ($event: any) => (_ctx.duplicatePart(data)),
                          title: "Duplicate"
                        }, null, 8, ["onClick"]),
                        _createVNode(_component_Button, {
                          tyoe: "button",
                          icon: "pi pi-trash",
                          style: { 'margin-left': '10px' },
                          title: "Delete",
                          onClick: ($event: any) => (this.delete(index))
                        }, null, 8, ["onClick"])
                      ])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["value"])
            ]),
            _: 1
          })
        ]),
        _createElementVNode("div", _hoisted_25, [
          _createVNode(_component_Card, null, {
            content: _withCtx(() => [
              _createVNode(_component_DataTable, {
                responsiveLayout: "stack",
                breakpoint: "1372px",
                value: _ctx.workorder.oper_items || []
              }, {
                header: _withCtx(() => _cache[18] || (_cache[18] = [
                  _createElementVNode("div", { class: "flex justify-content-between" }, [
                    _createElementVNode("h3", null, "Operations")
                  ], -1)
                ])),
                empty: _withCtx(() => _cache[19] || (_cache[19] = [
                  _createTextVNode(" No Operations Items. ")
                ])),
                default: _withCtx(() => [
                  _createVNode(_component_Column, {
                    field: "oper",
                    header: "Oper Id"
                  }),
                  _createVNode(_component_Column, {
                    field: "operation_description",
                    header: "Oper Description"
                  }),
                  _createVNode(_component_Column, {
                    field: "setup_hours",
                    header: "Setup Hours"
                  }),
                  _createVNode(_component_Column, {
                    field: "run_hours",
                    header: "Run Hours",
                    bodyStyle: "text-align: right"
                  }),
                  _createVNode(_component_Column, {
                    field: "capacity_hours",
                    header: "Capacity Hours",
                    headerStyle: "text-align: right",
                    bodyStyle: "text-align: right"
                  }),
                  _createVNode(_component_Column, {
                    field: "oper_labor_hours",
                    header: "Labour Hours",
                    bodyStyle: "text-align: right"
                  })
                ]),
                _: 1
              }, 8, ["value"])
            ]),
            _: 1
          })
        ]),
        _createElementVNode("div", _hoisted_26, [
          _createVNode(_component_Card, null, {
            content: _withCtx(() => [
              _createVNode(_component_DataTable, {
                responsiveLayout: "stack",
                breakpoint: "1372px"
              }, {
                header: _withCtx(() => _cache[20] || (_cache[20] = [
                  _createElementVNode("div", { class: "flex justify-content-between" }, [
                    _createElementVNode("h3", null, "Inspection Log")
                  ], -1)
                ])),
                empty: _withCtx(() => _cache[21] || (_cache[21] = [
                  _createTextVNode(" No Inspection Log Items. ")
                ])),
                default: _withCtx(() => [
                  _createVNode(_component_Column, {
                    field: "date",
                    header: "Date"
                  }),
                  _createVNode(_component_Column, {
                    field: "inv",
                    header: "Inv"
                  }),
                  _createVNode(_component_Column, {
                    field: "hold",
                    header: "Hold"
                  }),
                  _createVNode(_component_Column, {
                    field: "rejected",
                    header: "Rejected"
                  }),
                  _createVNode(_component_Column, {
                    field: "rework",
                    header: "Rework"
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ])
      ])
    ]),
    _createVNode(_component_Dialog, {
      header: "Part Details",
      visible: this.showPartModal,
      "onUpdate:visible": _cache[4] || (_cache[4] = ($event: any) => ((this.showPartModal) = $event)),
      dismissableMask: true,
      style: { width: '450px' },
      modal: true
    }, {
      default: _withCtx(() => [
        _createVNode(_component_PartModal, {
          submit: _ctx.addNewPart()
        }, null, 8, ["submit"])
      ]),
      _: 1
    }, 8, ["visible"]),
    _createVNode(_component_Dialog, {
      header: "Inventory",
      visible: this.showInventoryModal,
      "onUpdate:visible": _cache[5] || (_cache[5] = ($event: any) => ((this.showInventoryModal) = $event)),
      dismissableMask: true,
      modal: true,
      style: { 'max-width': '500px', width: '100%' },
      draggable: false
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_27, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.invLocations, (inv) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "col-12",
              key: inv.invloc
            }, [
              _createVNode(_component_Card, { class: "mt-1" }, {
                content: _withCtx(() => [
                  _createElementVNode("div", _hoisted_28, [
                    _createElementVNode("div", _hoisted_29, [
                      _createElementVNode("h3", _hoisted_30, _toDisplayString(inv.invloc), 1),
                      _createVNode(_component_Button, {
                        label: "Add Item",
                        onClick: ($event: any) => (this.formatPart(inv.invloc))
                      }, null, 8, ["onClick"])
                    ]),
                    _createElementVNode("div", _hoisted_31, [
                      _createElementVNode("span", _hoisted_32, "On Hand: " + _toDisplayString(inv.on_hand), 1)
                    ]),
                    _createElementVNode("div", _hoisted_33, [
                      _cache[22] || (_cache[22] = _createElementVNode("span", null, "Quanity", -1)),
                      _createVNode(_component_InputNumber, {
                        decrementButtonClass: "p-button-danger",
                        incrementButtonClass: "p-button-success",
                        incrementButtonIcon: "pi pi-plus",
                        decrementButtonIcon: "pi pi-minus",
                        showButtons: "",
                        modelValue: this.invAmounts[inv.invloc].amount,
                        "onUpdate:modelValue": ($event: any) => ((this.invAmounts[inv.invloc].amount) = $event),
                        inputStyle: { width: '50px' },
                        style: { width: '175px' },
                        buttonLayout: "horizontal"
                      }, null, 8, ["modelValue", "onUpdate:modelValue"])
                    ]),
                    _createElementVNode("div", _hoisted_34, [
                      _cache[23] || (_cache[23] = _createElementVNode("span", null, "Lot", -1)),
                      _createVNode(_component_InputText, {
                        type: "text",
                        modelValue: this.invAmounts[inv.invloc].lot_loc,
                        "onUpdate:modelValue": ($event: any) => ((this.invAmounts[inv.invloc].lot_loc) = $event),
                        style: { width: '175px' }
                      }, null, 8, ["modelValue", "onUpdate:modelValue"])
                    ]),
                    _createElementVNode("div", _hoisted_35, [
                      _cache[24] || (_cache[24] = _createElementVNode("span", null, "Bin", -1)),
                      _createVNode(_component_InputText, {
                        type: "text",
                        modelValue: this.invAmounts[inv.invloc].bin_loc,
                        "onUpdate:modelValue": ($event: any) => ((this.invAmounts[inv.invloc].bin_loc) = $event),
                        style: { width: '175px' }
                      }, null, 8, ["modelValue", "onUpdate:modelValue"])
                    ])
                  ])
                ]),
                _: 2
              }, 1024)
            ]))
          }), 128))
        ])
      ]),
      _: 1
    }, 8, ["visible"]),
    _createVNode(_component_ConfirmDialog)
  ]))
}
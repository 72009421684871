
import { defineComponent } from "vue";
import { mapGetters } from "vuex";
import { FilterMatchMode } from "primevue/api";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Tooltip from "primevue/tooltip";
import Utils from "../../utility/utils";
import PartsService, { PartsResponse } from "@/services/PartsService";
import _debounce from "lodash/debounce";
import _uniq from "lodash/uniq";
import Part from "@/types/part";
import Image from "primevue/image";

const partsService = new PartsService();

export default defineComponent({
  components: {
    DataTable,
    Column,
    Image,
  },
  directives: {
    tooltip: Tooltip,
  },
  emits: ["row-click"],
  props: {
    tableName: {
      type: String,
      default: "Parts Table",
    },
    isCustomTab: {
      type: Boolean,
      default: false,
    },
    range: {
      type: Object,
      default: () => ({}) as any,
    },
    partNumber: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      isLoading: false,
      rowsPerPage: 10,
      page: 1,
      totalRecords: 0,
      first: 0,
      sortBy: "wrap_desc",
      sortOrder: 1,
      rangeStart: 1,
      rangeEnd: 10,
      filters: {
        part_no: { value: "", matchMode: FilterMatchMode.EQUALS },
        wrap_desc: { value: "", matchMode: FilterMatchMode.CONTAINS },
        avail_qty: { value: "", matchMode: FilterMatchMode.EQUALS },
        status: { value: [], matchMode: FilterMatchMode.IN },
      },
      parts: [] as Part[],
      searchedFilterVendor: [] as any,
      selectedBuyer: "",
      selectedFilterDate: [] as any,
      selectedVendor: [] as any,
      dateStart: "",
      dateEnd: "",
      handleVendorSelectFilter: "" as any,
    };
  },
  created() {
    this.fetchParts();
  },
  computed: {
    ...mapGetters({
      getField: "fdict/getField",
    }),
    partStatus(): any {
      return [
        { valid_desc: "Active", valid_value: "A" },
        { valid_desc: "Inactive", valid_value: "I" },
        { valid_desc: "Pending", valid_value: "P" },
      ];
    },
  },
  watch: {
    partNumber: {
      handler() {
        this.fetchParts();
      },
    },
  },
  methods: {
    clearFilterByStatus() {
      this.filters.status.value = [];
      this.fetchParts();
    },
    clearFilter() {
      this.filters.part_no.value = "";
      this.filters.wrap_desc.value = "";
      this.filters.avail_qty.value = "";
      this.filters.status.value = [];
      this.fetchParts();
    },
    async fetchParts() {
      this.isLoading = true;

      try {
        const resp = (await partsService.getPartsRequest({
          part_no: this.partNumber,
          client: "Rover",
          code: [],
          rangeStart: this.rangeStart,
          rangeEnd: this.rangeEnd,
          status: this.filters.status.value,
          filterValues: this.filters,
          fieldNames: "part_no wrap_desc status plan_group invloc",
          sortBy: this.sortBy.replaceAll("_", "."),
          sortOrder: this.sortOrder === 1 ? "ASC" : "DEC",
          correls:
            "std_price avail_qty code image on_order on_hand commit_qty alloc_qty qty_short",
        })) as PartsResponse;

        this.parts = resp.parts_items || [];

        this.totalRecords = parseInt(
          resp.total_records_found ?? resp.parts_items.length,
        );

        if (this.parts.length === 1) {
          this.$emit("row-click", this.parts[0]);
        }
      } catch (e) {
        console.log(e);
      }

      this.isLoading = false;
    },
    formatDate(date: string): string {
      return Utils.formatDate(date);
    },
    handleRowClick(event: any) {
      this.$emit("row-click", event.data);
    },
    getStatusTitle(status: string): string {
      return (
        this.partStatus.find((stat: any) => stat.valid_value === status)
          ?.valid_desc ?? ""
      );
    },
    onPage(event: any) {
      this.first = event.first;
      this.rangeStart = this.rangeEnd + 1;
      this.rangeEnd = this.rangeEnd + this.rowsPerPage;
      this.fetchParts();
    },
    sortData(event: any) {
      this.sortBy = event.sortField;
      this.sortOrder = event.sortOrder;
      this.resetRange();

      this.fetchParts();
    },
    resetRange() {
      this.rangeStart = 1;
      this.rangeEnd = this.rowsPerPage;
    },
    handleFilter(event: any) {
      this.resetRange();
      this.fetchParts();
    },
  },
});

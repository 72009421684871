import ApiService from "./ApiService";

export interface UsageRequest {
  part_no: string;
}

export default class UsageService extends ApiService {
  constructor() {
    super();
  }

  async fetchUsage(usageRequest: UsageRequest) {
    const response = await this.Get("usage", usageRequest);
    return response;
  }

  async fetchUsageById(id: string, usageRequest?: UsageRequest) {
    const response = await this.Get(`usage/${id}`, usageRequest);
    return response;
  }
}


import { defineComponent } from "vue";
import Card from "primevue/card";
import { mapGetters } from "vuex";
import ChangeHistoryDataTable from "../UI/ChangeHistoryDataTable.vue";

export default defineComponent({
  name: "ChangeHistory",
  props: {
    index: Number,
  },
  components: {
    Card,
    ChangeHistoryDataTable,
  },
  computed: {
    ...mapGetters({ getCustomerMap: "customerInquiry/getCustomerMap" }),
  },

  methods: {
    handleFilter(event: any) {
      this.$emit(
        "update-badge",
        "change_date_items",
        event.filteredValue.length,
      );
    },
  },
});

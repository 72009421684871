import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 1 }
const _hoisted_2 = {
  key: 2,
  class: "px-2"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Column = _resolveComponent("Column")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _component_Card = _resolveComponent("Card")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createBlock(_component_Dialog, {
    header: "Keyboard Shortcuts",
    visible: _ctx.showDialog,
    "onUpdate:visible": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.showDialog) = $event)),
    style: { width: '500px' },
    modal: "",
    class: "shortcut-dialog",
    onHide: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('close')))
  }, {
    default: _withCtx(() => [
      _createVNode(_component_Card, null, {
        title: _withCtx(() => _cache[2] || (_cache[2] = [
          _createElementVNode("div", { class: "shortcut-header" }, "POS Parts", -1)
        ])),
        content: _withCtx(() => [
          _createVNode(_component_DataTable, {
            value: _ctx.posPartsShortCuts,
            class: "shortcut-table",
            responsiveLayout: "scroll"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_Column, {
                field: "action",
                header: "Action",
                headerStyle: { width: '60%', fontWeight: 'bold' },
                style: { padding: '12px' }
              }),
              _createVNode(_component_Column, {
                field: "shortcut",
                header: "Shortcut",
                headerStyle: {
              width: '40%',
              fontWeight: 'bold',
              textAlign: 'center',
            },
                style: { padding: '12px', textAlign: 'center' }
              }, {
                body: _withCtx((slotProps) => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(slotProps.data.shortcut, (key, index) => {
                    return (_openBlock(), _createElementBlock("span", {
                      key: index,
                      style: {"display":"inline-block"}
                    }, [
                      (key.includes('pi'))
                        ? (_openBlock(), _createElementBlock("i", {
                            key: 0,
                            class: _normalizeClass(key)
                          }, null, 2))
                        : (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(key), 1)),
                      (index < slotProps.data.shortcut.length - 1)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_2, "+"))
                        : _createCommentVNode("", true)
                    ]))
                  }), 128))
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["value"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["visible"]))
}
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "grid p-0 m-0 justify-content-between w-full" }
const _hoisted_2 = { class: "col-12 grid p-0 m-0 vertical-stat-headers" }
const _hoisted_3 = {
  key: 0,
  class: "col-4 p-0 m-0 receivable-title justify-content-left"
}
const _hoisted_4 = { class: "p-0 m-0 mt-2" }
const _hoisted_5 = {
  key: 1,
  class: "grid p-0 m-0 mr-1 vertical-stat-headers-item left"
}
const _hoisted_6 = { class: "col-6 qty" }
const _hoisted_7 = { class: "col-6 grid" }
const _hoisted_8 = { class: "col-12 title-2" }
const _hoisted_9 = { class: "flex p-0 m-0 ml-1 vertical-stat-headers-item right amount" }
const _hoisted_10 = {
  key: 0,
  class: "col-12 grid p-0 pl-2 justify-content-between"
}
const _hoisted_11 = { class: "col-2 justify-content-center h-auto" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BarChart = _resolveComponent("BarChart")!
  const _component_SingleBarStat = _resolveComponent("SingleBarStat")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: _normalizeClass(["grid p-0 m-0", { 'col-6': !_ctx.isGlCard, 'col-4': _ctx.isGlCard }])
      }, [
        (_ctx.isGlCard)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createElementVNode("h3", _hoisted_4, _toDisplayString(_ctx.title), 1)
            ]))
          : _createCommentVNode("", true),
        (!_ctx.isGlCard)
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.statInfo.daysQty ?? "0"), 1),
              _createElementVNode("div", _hoisted_7, [
                _cache[0] || (_cache[0] = _createElementVNode("div", { class: "col-12 title-1" }, "Days", -1)),
                _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.statInfo.daysType ?? "0"), 1)
              ])
            ]))
          : _createCommentVNode("", true)
      ], 2),
      _createElementVNode("div", {
        class: _normalizeClass(["grid p-0 m-0", { 'col-6': !_ctx.isGlCard, 'col-8': _ctx.isGlCard }])
      }, [
        _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.formatValue(_ctx.statInfo.amount ?? "0")), 1)
      ], 2)
    ]),
    (_ctx.statInfo.data)
      ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
          _createVNode(_component_BarChart, {
            class: "col-10 h-auto",
            data: _ctx.statInfo.data ?? [0, 0, 0, 0, 0]
          }, null, 8, ["data"]),
          _createElementVNode("div", _hoisted_11, [
            _createVNode(_component_SingleBarStat, {
              data: _ctx.statInfo.data ?? [0, 0, 0, 0, 0]
            }, null, 8, ["data"])
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}
import AbstractAccessGetter, { AccessRights } from "../AbstractAccessGetter";

export default class InventoryAccessGetter extends AbstractAccessGetter {
  constructor() {
    super();
  }

  getAccess(path: string, commands: string[]): boolean {
    if (commands.includes("INVENTORY")) {
      return this.hasCommand(this.getWebCommand(path), commands);
    } else {
      return false;
    }
  }

  getWebCommand(path: string): string {
    const pathList = path.split("/");
    let webName = "INVENTORY";
    if (pathList[2] == "parts") {
      webName = "INV.Q";
    } else if (pathList[2] == "receipts") {
      webName = "RECEIPTS.E";
    } else if (pathList[2] == "purchase-orders") {
      webName = "RECEIPTS.E";
    } else if (pathList[2] == "transactions") {
      webName = "IT.BE";
    }
    return webName;
  }
}

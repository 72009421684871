import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock, toDisplayString as _toDisplayString, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "grid m-0 order-tab-content p-0" }
const _hoisted_2 = { class: "grid col-12 p-0 m-0 pl-2 justify-content-between" }
const _hoisted_3 = { class: "grid justify-content-center col-6 lg:col-3 xl:col-2 m-0 p-0 pr-1" }
const _hoisted_4 = { class: "p-inputgroup p-0 m-0 mt-1 col-12 lg:col-819" }
const _hoisted_5 = { class: "grid justify-content-center col-6 lg:col-3 xl:col-2 m-0 p-0 pr-1" }
const _hoisted_6 = { class: "p-inputgroup p-0 m-0 mt-1 col-12 lg:col-8" }
const _hoisted_7 = { class: "grid justify-content-center col-6 lg:col-3 xl:col-2 m-0 p-0" }
const _hoisted_8 = { class: "p-inputgroup p-0 m-0 mt-1 col-12 lg:col-8" }
const _hoisted_9 = { class: "grid p-0 m-0 mt-1" }
const _hoisted_10 = { class: "grid col-12 xl:col-6 p-0 pt-1 m-0 pl-2 xl:align-items-start xl:align-content-start" }
const _hoisted_11 = { class: "grid col-12 lg:col-6 m-0 p-0 lg:pr-1 xl:justify-content-end xl:align-items-start" }
const _hoisted_12 = { class: "p-0 m-0 pt-1 col-8 pr-1" }
const _hoisted_13 = { class: "p-inputgroup flex p-0 m-0" }
const _hoisted_14 = { class: "p-0 m-0 pt-1 col-4" }
const _hoisted_15 = { class: "p-inputgroup flex p-0 m-0" }
const _hoisted_16 = { class: "p-0 m-0 mt-1 col-12 lg:col-12 xl:col-12 xl:pr-0" }
const _hoisted_17 = { class: "p-inputgroup flex p-0 m-0" }
const _hoisted_18 = { class: "grid col-12 lg:col-6 m-0 p-0 lg:pl-1 xl:align-items-start" }
const _hoisted_19 = { class: "p-0 m-0 mt-1 col-12 xl:col-12" }
const _hoisted_20 = { class: "p-inputgroup flex p-0 m-0" }
const _hoisted_21 = { class: "grid col-12 lg:col-12 xl:col-12 m-0 p-0 lg:pt-1" }
const _hoisted_22 = { class: "p-0 m-0 pt-1 lg:pb-1 col-6 lg:col-6 lg:pl-0 lg:pr-1" }
const _hoisted_23 = { class: "p-inputgroup flex p-0 m-0" }
const _hoisted_24 = { class: "p-0 m-0 pt-1 lg:pb-1 col-6 lg:col-6 pl-1" }
const _hoisted_25 = { class: "p-inputgroup flex p-0 m-0" }
const _hoisted_26 = { class: "p-0 m-0 pt-1 lg:pb-1 col-6 lg:col-6 lg:pl-0 lg:pr-1" }
const _hoisted_27 = { class: "p-inputgroup flex p-0 m-0" }
const _hoisted_28 = { class: "p-0 m-0 pt-1 lg:pb-1 col-6 lg:col-6 pl-1" }
const _hoisted_29 = { class: "p-inputgroup flex p-0 m-0" }
const _hoisted_30 = { class: "p-0 m-0 pt-1 lg:pb-1 col-6 lg:col-6 lg:pl-0 lg:pr-1" }
const _hoisted_31 = { class: "p-inputgroup flex p-0 m-0" }
const _hoisted_32 = { class: "p-0 m-0 pt-1 lg:pb-1 col-6 lg:col-6 pl-1" }
const _hoisted_33 = { class: "p-inputgroup flex p-0 m-0" }
const _hoisted_34 = { class: "p-0 m-0 pt-1 lg:pb-1 col-6 lg:col-6 lg:pl-0 lg:pr-1" }
const _hoisted_35 = { class: "p-inputgroup flex p-0 m-0" }
const _hoisted_36 = { class: "p-0 m-0 pt-1 lg:pb-1 col-6 lg:col-6 pl-1" }
const _hoisted_37 = { class: "p-inputgroup flex p-0 m-0" }
const _hoisted_38 = { class: "p-0 m-0 pt-1 lg:pb-1 col-6 lg:col-6 lg:pl-0 lg:pr-1" }
const _hoisted_39 = { class: "p-inputgroup flex p-0 m-0" }
const _hoisted_40 = { class: "field-checkbox p-0 m-0 pt-1 lg:pb-1 col-6 lg:col-6 pl-1" }
const _hoisted_41 = { class: "grid col-12 pl-2 p-0 pt-2 m-0" }
const _hoisted_42 = { key: 0 }
const _hoisted_43 = { class: "grid col-12 pl-2 xl:col-6 p-0 pt-2 m-0 justify-content-center" }
const _hoisted_44 = { class: "col-12 xl:col-12 m-0 p-0" }
const _hoisted_45 = { class: "flex justify-content-center" }
const _hoisted_46 = { class: "grid col-12 p-0 m-0 pt-0 lg:pl-2 lg:pr-0 flex-wrap-reverse" }
const _hoisted_47 = { class: "grid col-12 lg:col-6 m-0 p-0 pl-2 lg:pl-1" }
const _hoisted_48 = { class: "grid col-12 m-0 p-0 lg:pr-1 xl:justify-content-center align-items-start align-content-start" }
const _hoisted_49 = { class: "p-0 m-0 pt-1 lg:pb-1 col-6 pr-1" }
const _hoisted_50 = { class: "p-inputgroup flex p-0 m-0" }
const _hoisted_51 = { class: "p-0 m-0 pt-1 lg:pb-1 col-6 lg:pl-1" }
const _hoisted_52 = { class: "p-inputgroup flex p-0 m-0" }
const _hoisted_53 = { class: "p-0 m-0 pt-1 lg:pb-1 col-6 pr-1" }
const _hoisted_54 = { class: "p-inputgroup flex p-0 m-0" }
const _hoisted_55 = { class: "p-0 m-0 pt-1 lg:pb-1 col-6 lg:pl-1" }
const _hoisted_56 = { class: "p-inputgroup flex p-0 m-0" }
const _hoisted_57 = { class: "p-0 m-0 pt-1 col-12 pb-1" }
const _hoisted_58 = { class: "p-inputgroup flex p-0 m-0" }
const _hoisted_59 = { class: "grid col-12 lg:col-6 xl:col-6 m-0 p-0" }
const _hoisted_60 = { class: "grid col-6 xl:col-6 m-0 p-0 justify-content-around lg:justify-content-center align-items-center item-image" }
const _hoisted_61 = {
  key: 1,
  class: "image-not-available"
}
const _hoisted_62 = { class: "grid col-6 xl:col-6 m-0 p-0 justify-content-center align-content-start pr-1 xl:pr-0" }
const _hoisted_63 = { class: "col-7 lg:col-9 p-0 m-0 pt-1" }
const _hoisted_64 = { class: "col-7 lg:col-9 p-0 m-0 pt-1" }
const _hoisted_65 = { class: "col-7 lg:col-9 p-0 m-0 pt-1" }
const _hoisted_66 = { class: "col-7 lg:col-9 p-0 m-0 pt-1" }
const _hoisted_67 = { class: "grid col-12 p-0 m-0 pl-2" }
const _hoisted_68 = {
  key: 0,
  class: "hidden lg:flex grid col-12 p-0 m-0"
}
const _hoisted_69 = { class: "grid col-12 lg:col-6 p-0 m-0" }
const _hoisted_70 = {
  key: 0,
  class: "grid col-5 p-2 pl-0 m-0"
}
const _hoisted_71 = { class: "flex justify-content-center" }
const _hoisted_72 = { class: "grid col-7 p-2 pr-0 lg:pr-1 m-0 lg:pl-1" }
const _hoisted_73 = { class: "flex justify-content-center" }
const _hoisted_74 = {
  key: 1,
  class: "grid col-12 lg:col-6 p-0 pt-2 lg:pl-1 m-0"
}
const _hoisted_75 = { class: "flex justify-content-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputText = _resolveComponent("InputText")!
  const _component_TextArea = _resolveComponent("TextArea")!
  const _component_CheckBox = _resolveComponent("CheckBox")!
  const _component_CustomFields = _resolveComponent("CustomFields")!
  const _component_Column = _resolveComponent("Column")!
  const _component_AddSchedDialog = _resolveComponent("AddSchedDialog")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Row = _resolveComponent("Row")!
  const _component_ColumnGroup = _resolveComponent("ColumnGroup")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _component_Divider = _resolveComponent("Divider")!
  const _component_Calendar = _resolveComponent("Calendar")!
  const _component_Image = _resolveComponent("Image")!
  const _component_ProgressSpinner = _resolveComponent("ProgressSpinner")!
  const _component_AddWorkOrderDialog = _resolveComponent("AddWorkOrderDialog")!
  const _component_AddRepDialog = _resolveComponent("AddRepDialog")!
  const _component_AddShipmentDialog = _resolveComponent("AddShipmentDialog")!
  const _component_Card = _resolveComponent("Card")!
  const _component_LookupPartNumber = _resolveComponent("LookupPartNumber")!
  const _component_LookupSingleLevel = _resolveComponent("LookupSingleLevel")!
  const _component_LookupMultiLevel = _resolveComponent("LookupMultiLevel")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_Card, { class: "col-12 border-round-3xl mb-0 p-0 pl-2 tab-card-content" }, {
        content: _withCtx(() => [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _cache[27] || (_cache[27] = _createElementVNode("small", { class: "p-inputgroup-addon medium-addon" }, "Line Num", -1)),
                _createVNode(_component_InputText, {
                  type: "text",
                  class: "text-sm",
                  modelValue: _ctx.currentItem.lis,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.currentItem.lis) = $event)),
                  disabled: !_ctx.editable
                }, null, 8, ["modelValue", "disabled"])
              ])
            ]),
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _cache[28] || (_cache[28] = _createElementVNode("small", { class: "p-inputgroup-addon medium-addon" }, "Quote", -1)),
                _createVNode(_component_InputText, {
                  type: "text",
                  class: "text-sm",
                  modelValue: _ctx.currentItem.li_quote_no,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.currentItem.li_quote_no) = $event)),
                  disabled: !_ctx.editable
                }, null, 8, ["modelValue", "disabled"])
              ])
            ]),
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("div", _hoisted_8, [
                _cache[29] || (_cache[29] = _createElementVNode("small", { class: "p-inputgroup-addon medium-addon" }, "Quote Line", -1)),
                _createVNode(_component_InputText, {
                  type: "text",
                  class: "text-sm",
                  modelValue: _ctx.currentItem.li_quote_li,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.currentItem.li_quote_li) = $event)),
                  disabled: !_ctx.editable
                }, null, 8, ["modelValue", "disabled"])
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_9, [
            _cache[46] || (_cache[46] = _createElementVNode("div", { class: "hidden xl:flex grid col-12 p-0 m-0" }, [
              _createElementVNode("div", { class: "col-6" }),
              _createElementVNode("div", { class: "col-6 p-0 m-0" }, [
                _createElementVNode("h4", { class: "p-0 m-0 pt-2 pb-1 text-center" }, "Schedule")
              ])
            ], -1)),
            _createElementVNode("div", _hoisted_10, [
              _createElementVNode("div", _hoisted_11, [
                _createElementVNode("div", _hoisted_12, [
                  _createElementVNode("div", _hoisted_13, [
                    _cache[30] || (_cache[30] = _createElementVNode("small", { class: "p-inputgroup-addon medium-addon" }, "Part Num", -1)),
                    _createVNode(_component_InputText, {
                      type: "text",
                      class: "text-sm",
                      modelValue: _ctx.currentItem.li_parts,
                      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.currentItem.li_parts) = $event)),
                      disabled: !_ctx.editable
                    }, null, 8, ["modelValue", "disabled"])
                  ])
                ]),
                _createElementVNode("div", _hoisted_14, [
                  _createElementVNode("div", _hoisted_15, [
                    _cache[31] || (_cache[31] = _createElementVNode("small", { class: "p-inputgroup-addon short-addon" }, "Rev", -1)),
                    _createVNode(_component_InputText, {
                      type: "text",
                      class: "text-sm",
                      modelValue: _ctx.currentItem.li_revs,
                      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.currentItem.li_revs) = $event)),
                      disabled: !_ctx.editable
                    }, null, 8, ["modelValue", "disabled"])
                  ])
                ]),
                _createElementVNode("div", _hoisted_16, [
                  _createElementVNode("div", _hoisted_17, [
                    _cache[32] || (_cache[32] = _createElementVNode("small", { class: "p-inputgroup-addon medium-addon" }, "Cust Part", -1)),
                    _createVNode(_component_InputText, {
                      type: "text",
                      class: "text-sm",
                      modelValue: _ctx.currentItem.cust_part,
                      "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.currentItem.cust_part) = $event)),
                      disabled: !_ctx.editable
                    }, null, 8, ["modelValue", "disabled"])
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_18, [
                _createElementVNode("div", _hoisted_19, [
                  _createElementVNode("div", _hoisted_20, [
                    _cache[33] || (_cache[33] = _createElementVNode("small", { class: "p-inputgroup-addon medium-addon" }, "Description", -1)),
                    _createVNode(_component_TextArea, {
                      rows: "3",
                      placeholder: "Enter a description...",
                      modelValue: _ctx.currentItem.wrap_desc,
                      "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.currentItem.wrap_desc) = $event)),
                      class: "text-area text-sm",
                      disabled: !_ctx.editable
                    }, null, 8, ["modelValue", "disabled"])
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_21, [
                _createElementVNode("div", _hoisted_22, [
                  _createElementVNode("div", _hoisted_23, [
                    _cache[34] || (_cache[34] = _createElementVNode("small", { class: "p-inputgroup-addon medium-addon" }, "Price", -1)),
                    _createVNode(_component_InputText, {
                      type: "text",
                      class: "text-sm",
                      modelValue: _ctx.currentItem.li_prices,
                      "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.currentItem.li_prices) = $event)),
                      disabled: !_ctx.editable
                    }, null, 8, ["modelValue", "disabled"])
                  ])
                ]),
                _createElementVNode("div", _hoisted_24, [
                  _createElementVNode("div", _hoisted_25, [
                    _cache[35] || (_cache[35] = _createElementVNode("small", { class: "p-inputgroup-addon medium-addon" }, "FG Loc", -1)),
                    _createVNode(_component_InputText, {
                      type: "text",
                      class: "text-sm",
                      modelValue: _ctx.currentItem.li_fg_locs,
                      "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.currentItem.li_fg_locs) = $event)),
                      disabled: !_ctx.editable
                    }, null, 8, ["modelValue", "disabled"])
                  ])
                ]),
                _createElementVNode("div", _hoisted_26, [
                  _createElementVNode("div", _hoisted_27, [
                    _cache[36] || (_cache[36] = _createElementVNode("small", { class: "p-inputgroup-addon medium-addon" }, "Per Unit", -1)),
                    _createVNode(_component_InputText, {
                      type: "text",
                      class: "text-sm",
                      modelValue: _ctx.currentItem.price_per,
                      "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.currentItem.price_per) = $event)),
                      disabled: !_ctx.editable
                    }, null, 8, ["modelValue", "disabled"])
                  ])
                ]),
                _createElementVNode("div", _hoisted_28, [
                  _createElementVNode("div", _hoisted_29, [
                    _cache[37] || (_cache[37] = _createElementVNode("small", { class: "p-inputgroup-addon medium-addon" }, "CG Loc", -1)),
                    _createVNode(_component_InputText, {
                      type: "text",
                      class: "text-sm",
                      modelValue: _ctx.currentItem.cg_loc,
                      "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.currentItem.cg_loc) = $event)),
                      disabled: !_ctx.editable
                    }, null, 8, ["modelValue", "disabled"])
                  ])
                ]),
                _createElementVNode("div", _hoisted_30, [
                  _createElementVNode("div", _hoisted_31, [
                    _cache[38] || (_cache[38] = _createElementVNode("small", { class: "p-inputgroup-addon medium-addon" }, "Disc %", -1)),
                    _createVNode(_component_InputText, {
                      type: "text",
                      class: "text-sm",
                      modelValue: _ctx.currentItem.li_discs,
                      "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.currentItem.li_discs) = $event)),
                      disabled: !_ctx.editable
                    }, null, 8, ["modelValue", "disabled"])
                  ])
                ]),
                _createElementVNode("div", _hoisted_32, [
                  _createElementVNode("div", _hoisted_33, [
                    _cache[39] || (_cache[39] = _createElementVNode("small", { class: "p-inputgroup-addon medium-addon" }, "Sales Acct", -1)),
                    _createVNode(_component_InputText, {
                      type: "text",
                      class: "text-sm",
                      modelValue: _ctx.currentItem.li_sales_accts,
                      "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.currentItem.li_sales_accts) = $event)),
                      disabled: !_ctx.editable
                    }, null, 8, ["modelValue", "disabled"])
                  ])
                ]),
                _createElementVNode("div", _hoisted_34, [
                  _createElementVNode("div", _hoisted_35, [
                    _cache[40] || (_cache[40] = _createElementVNode("small", { class: "p-inputgroup-addon medium-addon" }, "Disc Amt", -1)),
                    _createVNode(_component_InputText, {
                      type: "text",
                      class: "text-sm",
                      modelValue: _ctx.currentItem.li_disc_amts,
                      "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.currentItem.li_disc_amts) = $event)),
                      disabled: !_ctx.editable
                    }, null, 8, ["modelValue", "disabled"])
                  ])
                ]),
                _createElementVNode("div", _hoisted_36, [
                  _createElementVNode("div", _hoisted_37, [
                    _cache[41] || (_cache[41] = _createElementVNode("small", { class: "p-inputgroup-addon medium-addon" }, "Lot Number", -1)),
                    _createVNode(_component_InputText, {
                      type: "text",
                      class: "text-sm",
                      modelValue: _ctx.currentItem.lot_number,
                      "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.currentItem.lot_number) = $event)),
                      disabled: !_ctx.editable
                    }, null, 8, ["modelValue", "disabled"])
                  ])
                ]),
                _createElementVNode("div", _hoisted_38, [
                  _createElementVNode("div", _hoisted_39, [
                    _cache[42] || (_cache[42] = _createElementVNode("small", { class: "p-inputgroup-addon medium-addon" }, "Foreign $", -1)),
                    _createVNode(_component_InputText, {
                      type: "text",
                      class: "text-sm",
                      disabled: !_ctx.editable
                    }, null, 8, ["disabled"])
                  ])
                ]),
                _createElementVNode("div", _hoisted_40, [
                  _cache[43] || (_cache[43] = _createElementVNode("small", { class: "p-inputgroup-addon medium-addon text-sm single-last-field" }, "Taxable", -1)),
                  _createVNode(_component_CheckBox, {
                    id: "taxable",
                    name: "taxable",
                    modelValue: _ctx.currentItem.li_taxables,
                    "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.currentItem.li_taxables) = $event)),
                    trueValue: "Y",
                    falseValue: '',
                    binary: true,
                    class: "field-checkbox p-0 m-1 mt-0 mb-0 ml-3",
                    disabled: !_ctx.editable
                  }, null, 8, ["modelValue", "disabled"])
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_41, [
              (_ctx.customFields && _ctx.customFields.length > 0)
                ? (_openBlock(), _createElementBlock("div", _hoisted_42, [
                    _createVNode(_component_CustomFields, {
                      data: _ctx.currentItem,
                      customFields: _ctx.customFields,
                      readOnly: !_ctx.editable,
                      onUpdateCustomFields: _ctx.updateCustomFields
                    }, null, 8, ["data", "customFields", "readOnly", "onUpdateCustomFields"])
                  ]))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", _hoisted_43, [
              _createElementVNode("div", _hoisted_44, [
                _cache[45] || (_cache[45] = _createElementVNode("div", { class: "col-12 p-0 m-0 xl:hidden" }, [
                  _createElementVNode("h4", { class: "p-0 m-0 pt-2 pb-1 text-center" }, "Schedule")
                ], -1)),
                _createVNode(_component_DataTable, {
                  value: _ctx.currentItem.li_sched_dates_items,
                  class: "p-datatable-sm w-12 text-sm font-semibold bordered-datatable",
                  paginator: "",
                  rows: 3,
                  rowsPerPageOptions: [3, 5, 10],
                  breakpoint: "800px"
                }, {
                  empty: _withCtx(() => _cache[44] || (_cache[44] = [
                    _createElementVNode("div", { class: "text-center" }, "No records found", -1)
                  ])),
                  default: _withCtx(() => [
                    _createVNode(_component_Column, {
                      field: "li_sched_dates",
                      header: "Date",
                      bodyClass: "text-center justify-content-center",
                      headerClass: "general-label-color justify-content-center"
                    }),
                    _createVNode(_component_Column, {
                      field: "li_sched_qtys",
                      header: "Quantity",
                      bodyClass: "text-center justify-content-center",
                      headerClass: "general-label-color justify-content-center"
                    }),
                    _createVNode(_component_Column, {
                      field: "remaining",
                      header: "Remaining",
                      bodyClass: "text-center justify-content-center",
                      headerClass: "general-label-color justify-content-center"
                    }),
                    _createVNode(_component_Column, {
                      field: "commited",
                      header: "Commited",
                      bodyClass: "text-center justify-content-center",
                      headerClass: "general-label-color justify-content-center"
                    }, {
                      body: _withCtx((slotProps) => [
                        _createVNode(_component_CheckBox, {
                          Id: "cmt",
                          name: "cmt",
                          modelValue: slotProps.data.commited,
                          "onUpdate:modelValue": ($event: any) => ((slotProps.data.commited) = $event),
                          trueValue: "Y",
                          falseValue: '',
                          binary: true,
                          disabled: !_ctx.editable
                        }, null, 8, ["modelValue", "onUpdate:modelValue", "disabled"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_Column, {
                      field: "original",
                      header: "Original",
                      bodyClass: "text-center justify-content-center",
                      headerClass: "general-label-color justify-content-center"
                    }),
                    (_ctx.editable)
                      ? (_openBlock(), _createBlock(_component_Column, { key: 0 }, {
                          header: _withCtx(() => [
                            _createVNode(_component_AddSchedDialog, { onAddSched: _ctx.addSched }, null, 8, ["onAddSched"])
                          ]),
                          body: _withCtx((slotProps) => [
                            _createElementVNode("div", _hoisted_45, [
                              _createVNode(_component_Button, {
                                icon: "pi pi-trash",
                                title: "Delete",
                                class: "mt-2 text-sm font-bold",
                                onClick: ($event: any) => (_ctx.deleteSchedule(slotProps.index))
                              }, null, 8, ["onClick"])
                            ])
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true),
                    _createVNode(_component_ColumnGroup, { type: "footer" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_Row, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_Column, {
                              footer: "Totals:",
                              footerStyle: "text-align:right"
                            }),
                            _createVNode(_component_Column, {
                              footer: _ctx.schedQtyTotal,
                              footerStyle: "text-align:center"
                            }, null, 8, ["footer"]),
                            _createVNode(_component_Column, {
                              footer: _ctx.schedRemainingTotal,
                              footerStyle: "text-align:center"
                            }, null, 8, ["footer"]),
                            _createVNode(_component_Column, {
                              footer: "",
                              colspan: 3
                            })
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["value"])
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_46, [
            _createElementVNode("div", _hoisted_47, [
              _createVNode(_component_Divider, {
                type: "dashed",
                class: "hidden lg:flex"
              }),
              _createElementVNode("div", _hoisted_48, [
                _createElementVNode("div", _hoisted_49, [
                  _createElementVNode("div", _hoisted_50, [
                    _cache[47] || (_cache[47] = _createElementVNode("small", { class: "p-inputgroup-addon medium-addon" }, "Project", -1)),
                    _createVNode(_component_InputText, {
                      type: "text",
                      class: "text-sm",
                      modelValue: _ctx.currentItem.project_id,
                      "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.currentItem.project_id) = $event)),
                      disabled: !_ctx.editable
                    }, null, 8, ["modelValue", "disabled"])
                  ])
                ]),
                _createElementVNode("div", _hoisted_51, [
                  _createElementVNode("div", _hoisted_52, [
                    _cache[48] || (_cache[48] = _createElementVNode("small", { class: "p-inputgroup-addon medium-addon" }, "Task", -1)),
                    _createVNode(_component_InputText, {
                      type: "text",
                      class: "text-sm",
                      modelValue: _ctx.currentItem.task_id,
                      "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.currentItem.task_id) = $event)),
                      disabled: !_ctx.editable
                    }, null, 8, ["modelValue", "disabled"])
                  ])
                ]),
                _createElementVNode("div", _hoisted_53, [
                  _createElementVNode("div", _hoisted_54, [
                    _cache[49] || (_cache[49] = _createElementVNode("small", { class: "p-inputgroup-addon medium-addon" }, "Hold Code", -1)),
                    _createVNode(_component_InputText, {
                      type: "text",
                      class: "text-sm",
                      modelValue: _ctx.currentItem.li_holds,
                      "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.currentItem.li_holds) = $event)),
                      disabled: !_ctx.editable
                    }, null, 8, ["modelValue", "disabled"])
                  ])
                ]),
                _createElementVNode("div", _hoisted_55, [
                  _createElementVNode("div", _hoisted_56, [
                    _cache[50] || (_cache[50] = _createElementVNode("small", { class: "p-inputgroup-addon medium-addon" }, "Hold Date", -1)),
                    _createVNode(_component_Calendar, {
                      showIcon: true,
                      dateFormat: "mm-dd-yy",
                      inputClass: "text-sm",
                      modelValue: _ctx.currentItem.li_hold_dates,
                      "onUpdate:modelValue": _cache[19] || (_cache[19] = ($event: any) => ((_ctx.currentItem.li_hold_dates) = $event)),
                      disabled: !_ctx.editable
                    }, null, 8, ["modelValue", "disabled"])
                  ])
                ]),
                _createElementVNode("div", _hoisted_57, [
                  _createElementVNode("div", _hoisted_58, [
                    _cache[51] || (_cache[51] = _createElementVNode("small", { class: "p-inputgroup-addon medium-addon" }, "Notes", -1)),
                    _createVNode(_component_TextArea, {
                      rows: "3",
                      placeholder: "Enter a note...",
                      class: "text-area text-sm",
                      modelValue: _ctx.currentItem.li_notes,
                      "onUpdate:modelValue": _cache[20] || (_cache[20] = ($event: any) => ((_ctx.currentItem.li_notes) = $event)),
                      disabled: !_ctx.editable
                    }, null, 8, ["modelValue", "disabled"])
                  ])
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_59, [
              _cache[53] || (_cache[53] = _createElementVNode("div", { class: "hidden lg:flex grid col-12 p-0 m-0" }, [
                _createElementVNode("div", { class: "col-8 xl:col-8" }),
                _createElementVNode("div", { class: "lg:col-2 xl:col-2 p-0 m-0" }, [
                  _createElementVNode("h4", { class: "p-0 m-0 pt-2 pb-1 text-center" }, "Lookups")
                ])
              ], -1)),
              _createElementVNode("div", _hoisted_60, [
                (
                  !_ctx.isLoadingImages && _ctx.web_image_path && _ctx.web_image_path !== ''
                )
                  ? (_openBlock(), _createBlock(_component_Image, {
                      key: 0,
                      src: _ctx.web_image_path,
                      alt: _ctx.web_image_desc,
                      width: "250",
                      height: "200",
                      preview: ""
                    }, null, 8, ["src", "alt"]))
                  : _createCommentVNode("", true),
                (!_ctx.isLoadingImages && !_ctx.web_image_path)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_61, _cache[52] || (_cache[52] = [
                      _createElementVNode("span", { class: "font-bold" }, "Image not available", -1)
                    ])))
                  : _createCommentVNode("", true),
                (_ctx.isLoadingImages)
                  ? (_openBlock(), _createBlock(_component_ProgressSpinner, {
                      key: 2,
                      style: {"width":"50px","height":"50px"},
                      strokeWidth: "3",
                      class: "mt-2"
                    }))
                  : _createCommentVNode("", true)
              ]),
              _createElementVNode("div", _hoisted_62, [
                _createElementVNode("div", _hoisted_63, [
                  _createVNode(_component_Button, {
                    label: "Part Number",
                    class: "text-sm w-12 single-field",
                    onClick: _cache[21] || (_cache[21] = ($event: any) => (_ctx.openLookupDialog('partNumber')))
                  })
                ]),
                _createElementVNode("div", _hoisted_64, [
                  _createVNode(_component_Button, {
                    label: "Single Level",
                    class: "text-sm w-12 single-field",
                    onClick: _cache[22] || (_cache[22] = ($event: any) => (_ctx.openLookupDialog('singleLevel')))
                  })
                ]),
                _createElementVNode("div", _hoisted_65, [
                  _createVNode(_component_Button, {
                    label: "Multi Level",
                    class: "text-sm w-12 single-field",
                    onClick: _cache[23] || (_cache[23] = ($event: any) => (_ctx.openLookupDialog('multiLevel')))
                  })
                ]),
                _createElementVNode("div", _hoisted_66, [
                  _createVNode(_component_Button, {
                    label: "Avail To Pro",
                    class: "text-sm w-12 single-field"
                  })
                ])
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_67, [
            (
              _ctx.currentItem.li_ship_nos_items &&
              _ctx.currentItem.li_ship_nos_items.length > 0
            )
              ? (_openBlock(), _createElementBlock("div", _hoisted_68, _cache[54] || (_cache[54] = [
                  _createElementVNode("div", { class: "col-6" }, null, -1),
                  _createElementVNode("div", { class: "col-6 p-0 m-0" }, [
                    _createElementVNode("h4", { class: "p-0 m-0 pt-2 pb-1 text-center" }, "Shipment")
                  ], -1)
                ])))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_69, [
              (_ctx.currentItem.li_wos_items?.length > 0)
                ? (_openBlock(), _createElementBlock("div", _hoisted_70, [
                    _createVNode(_component_DataTable, {
                      value: _ctx.currentItem.li_wos_items,
                      class: "p-datatable-sm w-12 text-sm font-semibold bordered-datatable",
                      paginator: 
                  _ctx.currentItem.li_wos_items &&
                  _ctx.currentItem.li_wos_items.length > 3
                ,
                      rows: 3,
                      rowsPerPageOptions: [3, 5, 10],
                      breakpoint: "800px"
                    }, {
                      empty: _withCtx(() => _cache[55] || (_cache[55] = [
                        _createElementVNode("div", { class: "text-center" }, "No work orders found", -1)
                      ])),
                      default: _withCtx(() => [
                        _createVNode(_component_Column, {
                          field: "li_wos",
                          header: "Work Order",
                          bodyClass: "text-center",
                          headerClass: "general-label-color"
                        }),
                        (_ctx.editable)
                          ? (_openBlock(), _createBlock(_component_Column, { key: 0 }, {
                              header: _withCtx(() => [
                                _createVNode(_component_AddWorkOrderDialog, { onAddWorkOrder: _ctx.addWorkOrder }, null, 8, ["onAddWorkOrder"])
                              ]),
                              body: _withCtx((slotProps) => [
                                _createElementVNode("div", _hoisted_71, [
                                  _createVNode(_component_Button, {
                                    icon: "pi pi-trash",
                                    title: "Delete",
                                    class: "mt-2 text-sm font-bold",
                                    onClick: ($event: any) => (_ctx.deleteWorkOrder(slotProps.data.li_wos))
                                  }, null, 8, ["onClick"])
                                ])
                              ]),
                              _: 1
                            }))
                          : _createCommentVNode("", true)
                      ]),
                      _: 1
                    }, 8, ["value", "paginator"])
                  ]))
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_72, [
                _createVNode(_component_DataTable, {
                  value: _ctx.currentItem.li_reps_items,
                  class: "p-datatable-sm w-12 text-sm font-semibold bordered-datatable",
                  paginator: 
                  _ctx.currentItem.li_reps_items &&
                  _ctx.currentItem.li_reps_items.length > 3
                ,
                  rows: 3,
                  rowsPerPageOptions: [3, 5, 10],
                  breakpoint: "800px"
                }, {
                  empty: _withCtx(() => _cache[56] || (_cache[56] = [
                    _createElementVNode("div", { class: "text-center" }, "No reps found", -1)
                  ])),
                  default: _withCtx(() => [
                    _createVNode(_component_Column, {
                      field: "li_reps",
                      header: "Rep",
                      bodyClass: "text-center",
                      headerClass: "general-label-color"
                    }),
                    _createVNode(_component_Column, {
                      field: "li_comms",
                      header: "Comm%",
                      bodyClass: "text-center",
                      headerClass: "general-label-color"
                    }, {
                      body: _withCtx((slotProps) => [
                        _createElementVNode("span", null, _toDisplayString(slotProps.data.li_comms ? slotProps.data.li_comms : "0.00"), 1)
                      ]),
                      _: 1
                    }),
                    (_ctx.editable)
                      ? (_openBlock(), _createBlock(_component_Column, { key: 0 }, {
                          header: _withCtx(() => [
                            _createVNode(_component_AddRepDialog, { onAddRep: _ctx.addRepresentative }, null, 8, ["onAddRep"])
                          ]),
                          body: _withCtx((slotProps) => [
                            _createElementVNode("div", _hoisted_73, [
                              _createVNode(_component_Button, {
                                icon: "pi pi-trash",
                                title: "Delete",
                                class: "mt-2 text-sm font-bold",
                                onClick: ($event: any) => (_ctx.deleteRepresentatives(slotProps.index))
                              }, null, 8, ["onClick"])
                            ])
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                }, 8, ["value", "paginator"])
              ])
            ]),
            (_ctx.currentItem.li_ship_nos_items?.length > 0)
              ? (_openBlock(), _createElementBlock("div", _hoisted_74, [
                  _cache[58] || (_cache[58] = _createElementVNode("div", { class: "col-12 p-0 m-0 lg:hidden" }, [
                    _createElementVNode("h4", { class: "p-0 m-0 pt-1 pb-1 text-center" }, "Shipment")
                  ], -1)),
                  _createVNode(_component_DataTable, {
                    value: _ctx.currentItem.li_ship_nos_items,
                    class: "p-datatable-sm w-12 text-sm font-semibold bordered-datatable",
                    paginator: 
                _ctx.currentItem.li_ship_nos_items &&
                _ctx.currentItem.li_ship_nos_items.length > 3
              ,
                    rows: 3,
                    rowsPerPageOptions: [3, 5, 10],
                    breakpoint: "800px"
                  }, {
                    empty: _withCtx(() => _cache[57] || (_cache[57] = [
                      _createElementVNode("div", { class: "text-center" }, "No shipments found", -1)
                    ])),
                    default: _withCtx(() => [
                      _createVNode(_component_Column, {
                        field: "li_ship_dates",
                        header: "Date",
                        bodyClass: "text-center",
                        headerClass: "general-label-color"
                      }),
                      _createVNode(_component_Column, {
                        field: "li_ship_nos",
                        header: "Num",
                        bodyClass: "text-center",
                        headerClass: "general-label-color"
                      }),
                      _createVNode(_component_Column, {
                        field: "li_ship_qtys",
                        header: "Quantity",
                        bodyClass: "text-center",
                        headerClass: "general-label-color"
                      }),
                      _createVNode(_component_Column, {
                        header: "Open",
                        bodyClass: "text-center",
                        headerClass: "general-label-color"
                      }, {
                        body: _withCtx((slotProps) => [
                          _createVNode(_component_CheckBox, {
                            id: "open",
                            name: "open",
                            modelValue: slotProps.data.open,
                            "onUpdate:modelValue": ($event: any) => ((slotProps.data.open) = $event),
                            trueValue: "Y",
                            falseValue: '',
                            binary: true,
                            disabled: !_ctx.editable
                          }, null, 8, ["modelValue", "onUpdate:modelValue", "disabled"])
                        ]),
                        _: 1
                      }),
                      (_ctx.editable)
                        ? (_openBlock(), _createBlock(_component_Column, { key: 0 }, {
                            header: _withCtx(() => [
                              _createVNode(_component_AddShipmentDialog, { onAddShipment: _ctx.addShipment }, null, 8, ["onAddShipment"])
                            ]),
                            body: _withCtx((slotProps) => [
                              _createElementVNode("div", _hoisted_75, [
                                _createVNode(_component_Button, {
                                  icon: "pi pi-trash",
                                  title: "Delete",
                                  class: "mt-2 text-sm font-bold",
                                  onClick: ($event: any) => (_ctx.deleteShipment(slotProps.data.li_ship_nos))
                                }, null, 8, ["onClick"])
                              ])
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      _createVNode(_component_ColumnGroup, { type: "footer" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_Row, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_Column, {
                                footer: "Total Qty:",
                                colspan: 2,
                                footerStyle: "text-align:right"
                              }),
                              _createVNode(_component_Column, {
                                footer: _ctx.shipmentsQtyTotal,
                                footerStyle: "text-align:center"
                              }, null, 8, ["footer"]),
                              _createVNode(_component_Column, {
                                footer: "",
                                colspan: 2
                              })
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["value", "paginator"])
                ]))
              : _createCommentVNode("", true)
          ])
        ]),
        _: 1
      })
    ]),
    _createVNode(_component_LookupPartNumber, {
      partNumber: _ctx.currentItem.li_parts,
      show: _ctx.showLookupPartNumberDialog,
      onOnClose: _cache[24] || (_cache[24] = ($event: any) => (_ctx.closeLookupDialog('partNumber')))
    }, null, 8, ["partNumber", "show"]),
    _createVNode(_component_LookupSingleLevel, {
      partNumber: _ctx.currentItem.li_parts,
      show: _ctx.showLookupSingleLevelDialog,
      onOnClose: _cache[25] || (_cache[25] = ($event: any) => (_ctx.closeLookupDialog('singleLevel')))
    }, null, 8, ["partNumber", "show"]),
    _createVNode(_component_LookupMultiLevel, {
      partNumber: _ctx.currentItem.li_parts,
      show: _ctx.showLookupMultiLevelDialog,
      onOnClose: _cache[26] || (_cache[26] = ($event: any) => (_ctx.closeLookupDialog('multiLevel')))
    }, null, 8, ["partNumber", "show"])
  ], 64))
}
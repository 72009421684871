import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, createBlock as _createBlock, withCtx as _withCtx, createSlots as _createSlots } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Column = _resolveComponent("Column")!
  const _component_DataTable = _resolveComponent("DataTable")!

  return (_openBlock(), _createBlock(_component_DataTable, {
    paginator: true,
    rows: this.rows,
    first: this.firstRow,
    breakpoint: _ctx.breakpoint,
    responsiveLayout: "stack",
    value: this.items,
    rowsPerPageOptions: [10, 20, 50],
    selection: _ctx.selectedRow,
    "onUpdate:selection": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedRow) = $event)),
    selectionMode: "single",
    dataKey: this.dataKey,
    onRowClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.rowClick($event))),
    "onUpdate:first": _cache[2] || (_cache[2] = ($event: any) => ((this.firstRow) = $event)),
    onPage: _ctx.onPageClicked,
    onSort: _cache[3] || (_cache[3] = ($event: any) => (_ctx.sortData($event))),
    expandedRows: _ctx.expandedRows,
    "onUpdate:expandedRows": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.expandedRows) = $event)),
    filters: _ctx.filters1,
    "onUpdate:filters": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.filters1) = $event)),
    filterDisplay: "menu",
    globalFilterFields: [
      'name',
      'country.name',
      'representative.name',
      'balance',
      'status',
    ]
  }, _createSlots({
    empty: _withCtx(() => [
      _cache[6] || (_cache[6] = _createTextVNode(" No records found "))
    ]),
    default: _withCtx(() => [
      _createVNode(_component_Column, {
        expander: this.expansion,
        headerStyle: "width: 3rem"
      }, null, 8, ["expander"]),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.columns, (col) => {
        return (_openBlock(), _createBlock(_component_Column, {
          field: col.field,
          header: col.header,
          key: col.field,
          sortable: col.sort,
          style: _normalizeStyle(col.style)
        }, null, 8, ["field", "header", "sortable", "style"]))
      }), 128))
    ]),
    _: 2
  }, [
    (this.expansion && this.expansionName == 'Inventory')
      ? {
          name: "expansion",
          fn: _withCtx((slotProps) => [
            _createVNode(_component_DataTable, {
              value: slotProps.data.bin_items,
              responsiveLayout: "stack"
            }, {
              empty: _withCtx(() => _cache[7] || (_cache[7] = [
                _createTextVNode(" No rows to show. ")
              ])),
              default: _withCtx(() => [
                _createVNode(_component_Column, {
                  field: "bin",
                  header: "Bin Number"
                }),
                _createVNode(_component_Column, {
                  field: "bin_qty",
                  header: "Quantity"
                }),
                _createVNode(_component_Column, {
                  field: "bin_allocated",
                  header: "Allocated"
                })
              ]),
              _: 2
            }, 1032, ["value"])
          ]),
          key: "0"
        }
      : undefined
  ]), 1032, ["rows", "first", "breakpoint", "value", "selection", "dataKey", "onPage", "expandedRows", "filters"]))
}
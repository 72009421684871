import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, resolveDirective as _resolveDirective, withDirectives as _withDirectives, normalizeClass as _normalizeClass, withCtx as _withCtx, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "col-12" }
const _hoisted_2 = { class: "grid col-12 p-0 m-0 align-items-start" }
const _hoisted_3 = { class: "grid col-12 align-items-center flex-wrap pt-1" }
const _hoisted_4 = { class: "grid col-12 align-items-center justify-content-end p-0 m-0 mt-3 mb-2 pr-4" }
const _hoisted_5 = { class: "col-5 md:col-4 xl:col-3 flex justify-content-end" }
const _hoisted_6 = {
  key: 0,
  class: "grid col-12 justify-content-center",
  style: {
                height: '248px',
                display: 'flex',
                alignItems: 'center',
              }
}
const _hoisted_7 = {
  key: 0,
  class: "grid col-12 p-0 m-0 justify-content-center"
}
const _hoisted_8 = { class: "card pt-3 col-12" }
const _hoisted_9 = {
  key: 0,
  style: {
                        color: 'rgb(138, 138, 253)',
                        display: 'flex',
                        justifyContent: 'end',
                        marginRight: '18px',
                        marginTop: '10px',
                      },
  class: "pi pi-check-circle"
}
const _hoisted_10 = { class: "p-0 m-0 ship-card" }
const _hoisted_11 = { class: "p-0 m-0" }
const _hoisted_12 = {
  key: 0,
  style: {"font-size":"14px","margin":"5px"}
}
const _hoisted_13 = { class: "flex p-0 m-0 mb-1" }
const _hoisted_14 = {
  key: 0,
  class: "address-item text-base font-bold"
}
const _hoisted_15 = { class: "flex p-0 m-0 mb-2" }
const _hoisted_16 = {
  class: "address-item text-base font-normal",
  style: {"white-space":"pre-line"}
}
const _hoisted_17 = { class: "flex p-0 m-0 mb-1" }
const _hoisted_18 = {
  key: 0,
  class: "address-item text-base font-italic"
}
const _hoisted_19 = {
  key: 0,
  style: {"display":"flex","justify-content":"flex-end","width":"100%"}
}
const _hoisted_20 = {
  key: 0,
  style: {"display":"flex","justify-content":"center"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ShipToAddressesTable = _resolveComponent("ShipToAddressesTable")!
  const _component_Button = _resolveComponent("Button")!
  const _component_ProgressSpinner = _resolveComponent("ProgressSpinner")!
  const _component_Card = _resolveComponent("Card")!
  const _component_Carousel = _resolveComponent("Carousel")!
  const _component_CollapsibleSection = _resolveComponent("CollapsibleSection")!
  const _component_AddShippingAddressDialog = _resolveComponent("AddShippingAddressDialog")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_CollapsibleSection, {
        isOpen: _ctx.sectionsStatus.addressesInfo,
        header: "Address Information",
        "onUpdate:isOpen": _cache[4] || (_cache[4] = ($event: any) => (_ctx.toggleSectionIcon('addressesInfo')))
      }, {
        content: _withCtx(() => [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("div", _hoisted_5, [
                  _createVNode(_component_ShipToAddressesTable, {
                    shipToAddresses: _ctx.addresses,
                    showAllButton: false,
                    salesOrder: _ctx.getSalesOrder,
                    customerId: _ctx.getCustomer?.cust_id,
                    onOnClose: _ctx.handleOnClose,
                    onOpenAddAddressDialog: _ctx.openAddAddressDialog,
                    onOnRowClick: _ctx.handleRowClick
                  }, null, 8, ["shipToAddresses", "salesOrder", "customerId", "onOnClose", "onOpenAddAddressDialog", "onOnRowClick"])
                ]),
                (!_ctx.hideUseNewAddressButton)
                  ? (_openBlock(), _createBlock(_component_Button, {
                      key: 0,
                      label: "Use New Address",
                      icon: "pi pi-plus",
                      onClick: _ctx.openAddAddressDialog,
                      class: "col-5 md:col-4 xl:col-2 m-0 text-center",
                      style: { width: '170px' }
                    }, null, 8, ["onClick"]))
                  : _createCommentVNode("", true)
              ]),
              (_ctx.isLoading)
                ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                    _createVNode(_component_ProgressSpinner)
                  ]))
                : _createCommentVNode("", true)
            ])
          ]),
          (!_ctx.isLoading)
            ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                _createElementVNode("div", _hoisted_8, [
                  _createVNode(_component_Carousel, {
                    ref: "carousel",
                    value: _ctx.addresses,
                    numVisible: 4,
                    numScroll: _ctx.scrollLength,
                    responsiveOptions: _ctx.carouselResponsiveOptions,
                    page: _ctx.page,
                    showIndicators: false,
                    showNavigators: false
                  }, {
                    item: _withCtx((slotProps) => [
                      (_openBlock(), _createBlock(_component_Card, {
                        key: slotProps.data.ship_seq,
                        class: _normalizeClass(_ctx.getCardTitleClass(slotProps.data)),
                        onClick: ($event: any) => (_ctx.setShipAdr(slotProps.data))
                      }, {
                        title: _withCtx(() => [
                          (_ctx.isAddressSelected(slotProps.data))
                            ? (_openBlock(), _createElementBlock("i", _hoisted_9))
                            : _createCommentVNode("", true)
                        ]),
                        content: _withCtx(() => [
                          _createElementVNode("div", _hoisted_10, [
                            _createElementVNode("div", _hoisted_11, [
                              (slotProps.data.ship_seq && _ctx.showShipToId)
                                ? (_openBlock(), _createElementBlock("p", _hoisted_12, " Ship ID: " + _toDisplayString(slotProps.data.ship_seq), 1))
                                : _createCommentVNode("", true)
                            ]),
                            _createElementVNode("div", _hoisted_13, [
                              (slotProps.data.ship_name)
                                ? (_openBlock(), _createElementBlock("p", _hoisted_14, _toDisplayString(slotProps.data.ship_name), 1))
                                : _createCommentVNode("", true)
                            ]),
                            _createElementVNode("div", _hoisted_15, [
                              _createElementVNode("p", _hoisted_16, _toDisplayString(_ctx.formattedAddress(slotProps.data)), 1)
                            ]),
                            _createElementVNode("div", _hoisted_17, [
                              (slotProps.data.ship_attn)
                                ? (_openBlock(), _createElementBlock("p", _hoisted_18, " Attention: " + _toDisplayString(slotProps.data.ship_attn), 1))
                                : _createCommentVNode("", true)
                            ])
                          ])
                        ]),
                        footer: _withCtx(() => [
                          (_ctx.allowAddressChange)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
                                (_ctx.isAddressSelected(slotProps.data))
                                  ? _withDirectives((_openBlock(), _createBlock(_component_Button, {
                                      key: 0,
                                      onClick: _ctx.editOrderAddress,
                                      icon: 'pi pi-pencil',
                                      iconPos: "right",
                                      class: "p-button-text"
                                    }, null, 8, ["onClick"])), [
                                      [_directive_tooltip, 'Edit Order Address']
                                    ])
                                  : _withDirectives((_openBlock(), _createBlock(_component_Button, {
                                      key: 1,
                                      onClick: ($event: any) => (
                          _ctx.openOverrideAddressDialog($event, slotProps.data)
                        ),
                                      icon: 'pi pi-pencil',
                                      iconPos: "right",
                                      class: "p-button-text"
                                    }, null, 8, ["onClick"])), [
                                      [_directive_tooltip, 'Override address']
                                    ])
                              ]))
                            : _createCommentVNode("", true)
                        ]),
                        _: 2
                      }, 1032, ["class", "onClick"]))
                    ]),
                    footer: _withCtx(() => [
                      (_ctx.showCarouselFooter)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_20, [
                            _createVNode(_component_Button, {
                              icon: "pi pi-angle-double-left",
                              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handleUpdatePage(0))),
                              disabled: _ctx.page === 0,
                              class: "p-button-rounded p-button-text mr-5"
                            }, null, 8, ["disabled"]),
                            _createVNode(_component_Button, {
                              icon: "pi pi-angle-left",
                              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.handleUpdatePage(_ctx.page - 1))),
                              disabled: _ctx.page === 0,
                              class: "p-button-rounded p-button-text mr-5"
                            }, null, 8, ["disabled"]),
                            _createVNode(_component_Button, {
                              icon: "pi pi-angle-right",
                              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.handleUpdatePage(_ctx.page + 1))),
                              disabled: _ctx.page === _ctx.maxCarouselPage,
                              class: "p-button-rounded p-button-text ml-5"
                            }, null, 8, ["disabled"]),
                            _createVNode(_component_Button, {
                              icon: "pi pi-angle-double-right",
                              onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.handleUpdatePage(_ctx.maxCarouselPage))),
                              disabled: _ctx.page === _ctx.maxCarouselPage,
                              class: "p-button-rounded p-button-text ml-5"
                            }, null, 8, ["disabled"])
                          ]))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  }, 8, ["value", "numScroll", "responsiveOptions", "page"])
                ])
              ]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["isOpen"])
    ]),
    _createVNode(_component_AddShippingAddressDialog, {
      header: _ctx.modalHeader,
      showDialog: _ctx.showAddAddressDialog,
      addAddressToCustomerSwitch: _ctx.customerModuleAccess,
      customerId: _ctx.getCustomer?.cust_id,
      shipAddress: _ctx.selectedAddress,
      editMode: _ctx.editMode,
      onCloseDialog: _ctx.closeAddressDialog,
      onSaveAddress: _ctx.saveNewAddress,
      "update-address-label": _ctx.updateAddressLabel
    }, null, 8, ["header", "showDialog", "addAddressToCustomerSwitch", "customerId", "shipAddress", "editMode", "onCloseDialog", "onSaveAddress", "update-address-label"])
  ], 64))
}
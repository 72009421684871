
import { defineComponent } from "vue";
import { mapActions as vuexMapActions, useStore } from "vuex";
import { mapActions, mapState } from "pinia";
import Receipts from "@/types/receipts";
import FormOptionsDialog from "../FormOptionsDialog.vue";
import PO from "@/types/po";

import { useNotifications } from "@/composables/Notification/useNotifications";

import ReceiptsView from "./ReceiptsView.vue";
import ModalSpinner from "@/components/UI/ModalSpinner.vue";
import { forEach } from "lodash";
import { NotificationType } from "@/types/notification";

import { useInventoryStore } from "@/store/ui/inventoryModule";

export default defineComponent({
  components: {
    ReceiptsView,
    ModalSpinner,
    FormOptionsDialog,
  },
  setup() {
    const store = useStore();
    const {
      addSuccessNotification,
      addWarnNotification,
      addErrorNotification,
    } = useNotifications(store);
    return {
      addSuccessNotification,
      addWarnNotification,
      addErrorNotification,
    };
  },
  async created() {
    await this.fetchFdicts("RECEIPTS");
    this.fetchControls({
      id: "FORM",
      procedure: "FORM.CONTROL",
      filename: "CONTROL",
    });
  },
  computed: {
    ...mapState(useInventoryStore, { getActiveReceipts: "getActiveReceipts" }),
  },
  data() {
    return {
      loading: false,
      showFormOptions: false,
      currentReceiptId: "",
    };
  },
  methods: {
    ...vuexMapActions({
      addNotification: "notification/add",
      fetchFdicts: "fdict/fetchFdict",
      fetchControls: "control/fetchControl",
    }),
    ...mapActions(useInventoryStore, [
      "getReceiptPDF",
      "createReceiptsById",
      "createReceiptsFromPurchaseOrder",
      "updateReceiptsById",
      "postReceiptsById",
    ]),
    async downloadPdf(id: string, report: string, pageWidth: string) {
      await this.handleReceipt(id, report, pageWidth);
    },
    async sendEmail(
      id: string,
      reportName: string,
      pageWidth: string,
      email: any,
    ) {
      await this.handleReceipt(id, reportName, pageWidth, email);
    },
    async handleReceipt(
      id: string,
      reportName: string,
      pageWidth: string,
      email?: any,
    ) {
      const resp = await this.getReceiptPDF({
        recordId: id,
        reportName: reportName,
        pageWidth: pageWidth,
        email: email,
      });

      if (resp.success) {
        this.addSuccessNotification(resp.message || "success");
      } else {
        this.addErrorNotification(
          (resp.error as string) || `Receipt was not sent`,
        );
      }
    },
    async handleSaveReceipts(receipts: any) {
      if (!this.getActiveReceipts) {
        return;
      }
      this.loading = true;
      if (this.getActiveReceipts.record.receipts_id) {
        await this.handleUpdateReceipts(receipts);
      } else {
        await this.handleCreateReceipts(receipts);
      }
      this.loading = false;
    },
    async handleCreateReceipts(receipts: Receipts) {
      if (!this.getActiveReceipts) {
        return;
      }

      const response = await this.createReceiptsById({
        receipts: receipts,
        id: this.getActiveReceipts.id,
      });

      const { recordId } = response;

      if (recordId) {
        this.showFormOptions = true;
        this.currentReceiptId = recordId;
        if (response.metaData?.info) {
          const info = response.metaData.info;
          forEach(info, (value, key) => {
            this.addNotification({
              type: NotificationType.INFO,
              message: value.content,
              dialog: true,
              title: value.title,
              subtitle: value.subtitle,
            });
          });
        } else {
          this.addSuccessNotification(
            `Receipts #${recordId} created successfully`,
          );
        }
      }
    },
    async handleUpdateReceipts(receipts: Receipts) {
      if (!this.getActiveReceipts) {
        return;
      }
      const response = await this.postReceiptsById({
        receipts: receipts,
        id: this.getActiveReceipts.id,
      });

      const { status } = response;

      if (status === "success") {
        this.showFormOptions = true;
        this.currentReceiptId = receipts.receipts_id;
        if (response.metaData?.info) {
          const info = response.metaData.info;
          forEach(info, (value, key) => {
            this.addNotification({
              type: NotificationType.INFO,
              message: value.content,
              dialog: true,
              title: value.title,
              subtitle: value.subtitle,
            });
          });
        } else {
          this.addSuccessNotification(
            `Receipts #${receipts.receipts_id} updated successfully`,
          );
        }
      }
    },
    async handleSetPurchaseOrder(po: any) {
      if (!this.getActiveReceipts) {
        return;
      }
      const id = this.getActiveReceipts.id;
      if (this.getActiveReceipts.record.receipts_id) {
        this.updateReceiptsById(
          this.getActiveReceipts.record,
          this.getActiveReceipts.id,
          po,
        );
      } else {
        await this.createReceiptsFromPurchaseOrder({
          po: po,
          id: id,
        });
      }
    },
    handleSetReceipts({ receipts, po }: { receipts: Receipts; po: PO }) {
      if (!this.getActiveReceipts) {
        return;
      }
      this.updateReceiptsById(
        receipts,
        this.getActiveReceipts.id,
        po ?? this.getActiveReceipts.po,
      );
    },
  },
});

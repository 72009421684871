
import { defineComponent } from "vue";
import { mapState } from "vuex";
import { mapActions } from "pinia";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Button from "primevue/button";
import { useInventoryStore } from "@/store/ui/inventoryModule";
import Order from "@/types/order";

export default defineComponent({
  props: {
    partNumber: {
      type: String,
      default: "",
    },
  },
  components: {
    DataTable,
    Column,
    Button,
  },
  computed: {
    ...mapState({
      getClient: "session/getClient",
    }),
  },
  data() {
    return {
      ordersInfo: {} as Order,
      dateInfo: [] as any[],
      isLoadingOrders: false,
      rowsPerPage: 10,
      page: 1,
      first: 0,
      sortBy: "formatted_date",
      sortOrder: -1,
    };
  },
  async mounted() {
    await this.fetchOrders();
  },
  methods: {
    ...mapActions(useInventoryStore, ["fetchOrdersById"]),
    async fetchOrders(refresh = false) {
      if (this.partNumber === "") return;
      this.isLoadingOrders = true;
      const resp = await this.fetchOrdersById(this.partNumber, refresh);
      if (resp.success && resp.order) {
        this.ordersInfo = resp.order;
        this.dateInfo = this.ordersInfo.date_items;
        this.dateInfo.map((item: any) => {
          item.formatted_date = new Date(item.date);
        });
      }
      this.isLoadingOrders = false;
    },
  },
  watch: {
    partNumber() {
      this.fetchOrders();
    },
  },
});

import { AxiosInstance } from "axios";
import axios from "axios";
import store from "../store";
import ClientAPI from "./ClientAPI";

export default class RegService {
  private readonly client: AxiosInstance;

  constructor(serviceUrl: string) {
    this.client = axios.create({
      baseURL: serviceUrl,
      withCredentials: false,
    });
  }

  async fetchHardwareRequest() {
    return ClientAPI.Get(`getHardwareRequest`, "");
  }

  async fetchRegisterHardware(registerRequest: {
    sessionId?: string;
    fingerprint?: string;
  }) {
    const params = {
      sessionId: registerRequest.sessionId,
      fingerprint: registerRequest.fingerprint,
    };
    return ClientAPI.Get(`registerHardware`, params);
  }

  async updateRegisterHardware(payload: any) {
    const { SessionID, Fingerprint, Name, RegisterID } = payload;
    return ClientAPI.Post(`registerHardware`, {
      SessionID,
      Fingerprint,
      Name,
      RegisterID,
    });
  }

  async fetchRegister(regNum: string) {
    const params = {
      id: regNum,
    };
    return ClientAPI.Get(`register`, params);
  }

  async fetchStoreRegister(storeNum: string) {
    const params = {
      store: storeNum,
    };
    return ClientAPI.Get(`register`, params);
  }

  async updateReg(payload: any) {
    const { reg_id, newReg, oldReg, user } = payload;

    return ClientAPI.Put(`register/${reg_id}`, { newReg, oldReg, user });
  }
}


import { defineComponent } from "vue";
import { mapActions } from "pinia";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Button from "primevue/button";
import Lot from "@/types/lot";
import { useInventoryStore } from "@/store/ui/inventoryModule";

export default defineComponent({
  props: {
    partNumber: String,
  },

  components: {
    DataTable,
    Column,
    Button,
  },
  async mounted() {
    await this.handleFetchLot();
  },
  computed: {
    formattedLotInfo() {
      const reformattedLotInfo: any = [];

      this.lotInfo?.forEach((lotObject) => {
        if (
          lotObject.inv_loc_items != null &&
          lotObject.inv_loc_items.length > 0
        ) {
          let newLotObject: any = {
            lot_number: lotObject.lot_number,
            part_number: lotObject.part_number,
            revision: lotObject.revision,
          };
          lotObject.inv_loc_items?.forEach((locationObject) => {
            newLotObject = {
              ...newLotObject,
              inv_loc: locationObject.inv_loc,
              on_hand: locationObject.inv_qty,
            };
          });

          lotObject.bin_loc_items?.forEach((bin) => {
            newLotObject["bin"] = bin.bin_loc;
          });

          reformattedLotInfo.push(newLotObject);
        }
      });
      return reformattedLotInfo;
    },
  },
  methods: {
    ...mapActions(useInventoryStore, ["fetchLotById"]),
    async handleFetchLot(refresh = false) {
      if (!this.partNumber) {
        return;
      }

      this.isLoadingLotInfo = true;

      const resp = await this.fetchLotById(this.partNumber, refresh);

      if (resp.success && resp.lots) {
        this.lotInfo = resp.lots;
      }

      this.isLoadingLotInfo = false;
    },
  },

  data() {
    return {
      lotInfo: [] as Array<Lot>,
      isLoadingLotInfo: false,
      rowsPerPage: 10,
    };
  },
});

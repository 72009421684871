export default interface GeneralLedgerState {
  trialBalance: TrialBalance;
}

export class GeneralLedgerEntry {
  transaction_id?: string;
  loadAcct?: string;
  postDate?: string;
  fiscalYear?: string;
  date = "";
  refJournal?: string;
  yearToReverse?: string;
  dateToReverse?: string;
  desc?: string;
  lineItems = [] as GeneralLedgerLineItem[];
  totalDebit?: string;
  totalCredit?: string;
  attachments_items = [] as any[];

  getId(): string {
    return this.transaction_id || "";
  }

  get isObject(): boolean {
    // really don't like this but I need to know if the object has been initialized since loading from draft or edit does not automatically set the object with the defined methods
    return true;
  }
}

export interface TrialBalance {
  [key: string]: GeneralLedgerLineItem[];
}

export interface GeneralLedgerLineItem {
  account: string;
  accountDescription: string;
  debit: string;
  credit: string;
  notes?: string;
  inactive?: string | boolean;
  li?: string;
}

import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, withKeys as _withKeys, withCtx as _withCtx, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  class: "col-12",
  ref: "parentContainer"
}
const _hoisted_2 = { class: "mt-2" }
const _hoisted_3 = {
  key: 0,
  class: "flex justify-content-between"
}
const _hoisted_4 = { class: "p-input-icon-left" }
const _hoisted_5 = { key: 1 }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { key: 1 }
const _hoisted_8 = { class: "p-3" }
const _hoisted_9 = {
  key: 0,
  class: "right-buttons"
}
const _hoisted_10 = { class: "grid" }
const _hoisted_11 = { class: "p-inputgroup mt-2" }
const _hoisted_12 = { class: "grid" }
const _hoisted_13 = { class: "grid" }
const _hoisted_14 = { class: "field-radiobutton col-12" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SalesHeaderButtons = _resolveComponent("SalesHeaderButtons")!
  const _component_Button = _resolveComponent("Button")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Column = _resolveComponent("Column")!
  const _component_Calendar = _resolveComponent("Calendar")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _component_StageDialog = _resolveComponent("StageDialog")!
  const _component_Dialog = _resolveComponent("Dialog")!
  const _component_RadioButton = _resolveComponent("RadioButton")!
  const _component_ConfirmChangesDialog = _resolveComponent("ConfirmChangesDialog")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_SalesHeaderButtons, {
      title: _ctx.pageTitle,
      "new-button-text": _ctx.newButtonText,
      showNewButton: this.getRights('add', 'addButton'),
      showTypeButtons: false,
      dataTestButtonTag: "newSalesOrder",
      lastUpdated: _ctx.lastUpdated,
      onNewButtonClicked: _cache[0] || (_cache[0] = ($event: any) => (_ctx.addNew())),
      "copy-button-text": _ctx.copyButtonText,
      showCopyButton: this.getRights('add', 'copyButton'),
      onCopyButtonClicked: _cache[1] || (_cache[1] = ($event: any) => (_ctx.copySelectedItems())),
      showSplitButton: this.getRights('add', 'splitButton'),
      "split-button-text": "Split",
      onSplitButtonClicked: _cache[2] || (_cache[2] = ($event: any) => (_ctx.splitSelectedItems())),
      showDeleteButton: this.getRights('delete', 'deleteButton'),
      "delete-button-text": "Delete",
      showArchiveButton: this.getRights('delete', 'archiveButton'),
      "archive-button-text": "Archive",
      onDeleteButtonClicked: _cache[3] || (_cache[3] = ($event: any) => (_ctx.deleteSelectedItems())),
      showExportButton: false,
      showPrintButton: _ctx.showPrintButton,
      onPrintButtonClicked: _cache[4] || (_cache[4] = ($event: any) => (_ctx.printTable())),
      onArchiveButtonClicked: _cache[5] || (_cache[5] = ($event: any) => (_ctx.archiveSelectedItems())),
      onExportButtonClicked: _cache[6] || (_cache[6] = ($event: any) => (_ctx.exportTable())),
      onIsResultView: _cache[7] || (_cache[7] = ($event: any) => (_ctx.isResultViewChange($event)))
    }, null, 8, ["title", "new-button-text", "showNewButton", "lastUpdated", "copy-button-text", "showCopyButton", "showSplitButton", "showDeleteButton", "showArchiveButton", "showPrintButton"]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_DataTable, {
        value: _ctx.items,
        lazy: _ctx.lazyLoad,
        loading: _ctx.isLoadingSales,
        stripedRows: "",
        removableSort: "",
        breakpoint: "1267px",
        dataKey: "so_id",
        rows: _ctx.rows,
        first: _ctx.first,
        "onUpdate:first": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.first) = $event)),
        selectionMode: "showSelectionCheckbox ? 'single' : null",
        paginator: true,
        responsiveLayout: "stack",
        rowsPerPageOptions: [10, 25, 50, 100, 250, 500],
        onPage: _ctx.pageClick,
        onSort: _cache[16] || (_cache[16] = ($event: any) => (_ctx.sortData($event))),
        filters: _ctx.filters,
        onRowClick: _ctx.rowClick,
        filterDisplay: "menu",
        class: _normalizeClass(["p-datatable-sm tablehead-no-border-bc", {
          'datatable-only-header column-header-separator': !_ctx.isResultView,
        }]),
        ref: "dt",
        "export-filename": _ctx.getExportFileName,
        expandedRows: _ctx.expandedRows,
        "onUpdate:expandedRows": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.expandedRows) = $event)),
        onFilter: _ctx.handleFilter,
        rowGroupMode: "subheader",
        groupRowsBy: "ORIGINAL_ID",
        sortMode: "multiple",
        multiSortMeta: _ctx.multiSortMeta,
        totalRecords: _ctx.totalRecords,
        paginatorTemplate: "FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink  RowsPerPageDropdown CurrentPageReport",
        currentPageReportTemplate: "Total Records: {totalRecords}",
        onSelectAllChange: _ctx.selectAllChange,
        selection: _ctx.selectedRow,
        "onUpdate:selection": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.selectedRow) = $event)),
        selectAll: _ctx.selectAll,
        onRowSelect: _ctx.onRowSelect,
        onRowUnselect: _ctx.onRowUnselect
      }, {
        header: _withCtx(() => [
          (_ctx.showExpand())
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createVNode(_component_Button, {
                  type: "button",
                  icon: "pi pi-filter-slash",
                  label: "Clear",
                  outlined: "",
                  onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.clearHeaderFilter()))
                }),
                _createElementVNode("span", _hoisted_4, [
                  _cache[36] || (_cache[36] = _createElementVNode("i", { class: "pi pi-search" }, null, -1)),
                  _createVNode(_component_InputText, {
                    modelValue: _ctx.dynamicFilters.SRT_FORMULA_PART_NO.value,
                    "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.dynamicFilters.SRT_FORMULA_PART_NO.value) = $event)),
                    placeholder: "Line Detail Search"
                  }, null, 8, ["modelValue"]),
                  _createVNode(_component_Button, {
                    type: "button",
                    label: "Apply",
                    onClick: _cache[10] || (_cache[10] = ($event: any) => (_ctx.handleFilter()))
                  })
                ])
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createVNode(_component_Button, {
                  type: "button",
                  icon: "pi pi-filter-slash",
                  label: "Clear",
                  outlined: "",
                  onClick: _cache[11] || (_cache[11] = ($event: any) => (_ctx.clearAllFilters()))
                })
              ]))
        ]),
        empty: _withCtx(() => _cache[37] || (_cache[37] = [
          _createTextVNode(" No records found ")
        ])),
        expansion: _withCtx((slotProps) => [
          _createElementVNode("div", _hoisted_8, [
            (this.getRights('add', 'orignalOrdersCopyButton'))
              ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                  _createVNode(_component_Button, {
                    class: "new-sales-order-btn",
                    label: "Copy",
                    onClick: _cache[12] || (_cache[12] = ($event: any) => (_ctx.copySelectedDetailItems()))
                  })
                ]))
              : _createCommentVNode("", true),
            _createVNode(_component_DataTable, {
              value: slotProps.data.lis_items,
              responsiveLayout: "stack",
              selection: _ctx.selectedDetailRow,
              "onUpdate:selection": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.selectedDetailRow) = $event))
            }, {
              empty: _withCtx(() => _cache[38] || (_cache[38] = [
                _createTextVNode(" No rows to show. ")
              ])),
              default: _withCtx(() => [
                (_ctx.showSelectionCheckbox)
                  ? (_openBlock(), _createBlock(_component_Column, {
                      key: 0,
                      style: {"width":"3rem"},
                      selectionMode: "multiple"
                    }))
                  : _createCommentVNode("", true),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.detailColumns, (column) => {
                  return (_openBlock(), _createBlock(_component_Column, {
                    key: column.field,
                    field: column.field,
                    header: column.header,
                    filter: true,
                    showFilterMatchModes: false,
                    showFilterOperator: false,
                    sortable: _ctx.isResultView
                  }, {
                    filter: _withCtx(() => [
                      (column.input)
                        ? (_openBlock(), _createBlock(_component_InputText, {
                            key: 0,
                            type: "text",
                            modelValue: _ctx.dynamicFilters[column.field].value,
                            "onUpdate:modelValue": ($event: any) => ((_ctx.dynamicFilters[column.field].value) = $event),
                            class: "p-column-filter"
                          }, null, 8, ["modelValue", "onUpdate:modelValue"]))
                        : _createCommentVNode("", true),
                      (column.calendar)
                        ? (_openBlock(), _createBlock(_component_Calendar, {
                            key: 1,
                            modelValue: _ctx.dynamicFilters[column.field].value,
                            "onUpdate:modelValue": ($event: any) => ((_ctx.dynamicFilters[column.field].value) = $event),
                            dateFormat: "dd-mm-yy",
                            placeholder: "dd-mm-yy",
                            selectionMode: "range"
                          }, null, 8, ["modelValue", "onUpdate:modelValue"]))
                        : _createCommentVNode("", true)
                    ]),
                    filterapply: _withCtx(() => [
                      _createVNode(_component_Button, {
                        type: "button",
                        label: "Apply",
                        onClick: _cache[13] || (_cache[13] = ($event: any) => (_ctx.fetchFilteredOrders()))
                      })
                    ]),
                    filterclear: _withCtx(() => [
                      _createVNode(_component_Button, {
                        type: "button",
                        label: "Clear",
                        onClick: ($event: any) => (_ctx.clearDynamicSearch(column.field))
                      }, null, 8, ["onClick"])
                    ]),
                    _: 2
                  }, 1032, ["field", "header", "sortable"]))
                }), 128))
              ]),
              _: 2
            }, 1032, ["value", "selection"])
          ])
        ]),
        default: _withCtx(() => [
          (_ctx.showExpand())
            ? (_openBlock(), _createBlock(_component_Column, {
                key: 0,
                expander: "",
                style: {"width":"5rem"}
              }))
            : _createCommentVNode("", true),
          (!_ctx.showExpand())
            ? (_openBlock(), _createBlock(_component_Column, {
                key: 1,
                style: {"width":"3rem"},
                selectionMode: "multiple"
              }))
            : _createCommentVNode("", true),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.dynamicColumns, (column) => {
            return (_openBlock(), _createBlock(_component_Column, {
              key: column.field,
              field: column.field,
              header: column.header,
              showFilterMatchModes: true,
              showFilterOperator: false,
              sortable: _ctx.isResultView,
              "body-style": _ctx.getColumnStyle(column.align)
            }, {
              body: _withCtx(({ data }) => [
                (column.input)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(data[column.field]), 1))
                  : _createCommentVNode("", true),
                (column.calendar)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(this.formatStringDate(data[column.field])), 1))
                  : _createCommentVNode("", true)
              ]),
              filter: _withCtx(({ filterCallback }) => [
                (column.input)
                  ? (_openBlock(), _createBlock(_component_InputText, {
                      key: 0,
                      type: "text",
                      modelValue: _ctx.dynamicFilters[column.field].value,
                      "onUpdate:modelValue": ($event: any) => ((_ctx.dynamicFilters[column.field].value) = $event),
                      class: "p-column-filter",
                      onKeypress: _withKeys(($event: any) => (filterCallback(), _ctx.dismissFilterInput()), ["enter"])
                    }, null, 8, ["modelValue", "onUpdate:modelValue", "onKeypress"]))
                  : _createCommentVNode("", true),
                (column.calendar)
                  ? (_openBlock(), _createBlock(_component_Calendar, {
                      key: 1,
                      modelValue: _ctx.dynamicFilters[column.field].value,
                      "onUpdate:modelValue": ($event: any) => ((_ctx.dynamicFilters[column.field].value) = $event),
                      dateFormat: "mm-dd-yy",
                      selectionMode: "range",
                      placeholder: "Select Date Range",
                      manualInput: false
                    }, null, 8, ["modelValue", "onUpdate:modelValue"]))
                  : _createCommentVNode("", true)
              ]),
              filterapply: _withCtx(({ filterCallback }) => [
                _createVNode(_component_Button, {
                  type: "button",
                  label: "Apply",
                  onClick: ($event: any) => (filterCallback())
                }, null, 8, ["onClick"])
              ]),
              filterclear: _withCtx(({ filterCallback }) => [
                _createVNode(_component_Button, {
                  type: "button",
                  label: "Clear",
                  onClick: ($event: any) => (filterCallback(), _ctx.clearDynamicSearch(column.field))
                }, null, 8, ["onClick"])
              ]),
              _: 2
            }, 1032, ["field", "header", "sortable", "body-style"]))
          }), 128))
        ]),
        _: 1
      }, 8, ["value", "lazy", "loading", "rows", "first", "onPage", "filters", "onRowClick", "class", "export-filename", "expandedRows", "onFilter", "multiSortMeta", "totalRecords", "onSelectAllChange", "selection", "selectAll", "onRowSelect", "onRowUnselect"])
    ]),
    _createVNode(_component_StageDialog, {
      order: this.salesOrder,
      show: this.showSalesDialog,
      BulkView: _ctx.isBulkView,
      readOnly: this.getReadOnly(),
      orderId: this.id,
      onOnSave: _cache[19] || (_cache[19] = ($event: any) => (_ctx.refreshAfterSave())),
      onHide: _cache[20] || (_cache[20] = 
        (hidden) => {
          this.salesOrder = null;
          this.showSalesDialog = hidden;
        }
      )
    }, null, 8, ["order", "show", "BulkView", "readOnly", "orderId"]),
    _createVNode(_component_Dialog, {
      header: "Split Order",
      visible: this.showSplitDialog,
      "onUpdate:visible": _cache[24] || (_cache[24] = ($event: any) => ((this.showSplitDialog) = $event)),
      onHide: _cache[25] || (_cache[25] = ($event: any) => (_ctx.$emit('hide', this.hidden))),
      style: { width: '500px' },
      modal: true,
      draggable: false
    }, {
      footer: _withCtx(() => [
        _createVNode(_component_Button, {
          "data-test": "cancelDialogButton",
          ref: "cancelDialogButton",
          label: "Cancel",
          icon: "pi pi-times",
          onClick: _cache[22] || (_cache[22] = 
            () => {
              this.showSplitDialog = false;
            }
          ),
          class: "p-button-text"
        }, null, 512),
        _createVNode(_component_Button, {
          "data-test": "submitDialogButton",
          ref: "submitDialogButton",
          label: "Save",
          icon: "pi pi-check",
          onClick: _cache[23] || (_cache[23] = ($event: any) => (this.submitSplitQty())),
          autofocus: ""
        }, null, 512)
      ]),
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_10, [
          _createElementVNode("div", _hoisted_11, [
            _cache[39] || (_cache[39] = _createElementVNode("span", {
              class: "p-inputgroup-addon",
              style: { width: '103px' }
            }, "Enter Split Qty", -1)),
            _createVNode(_component_InputText, {
              modelValue: this.splitQty,
              "onUpdate:modelValue": _cache[21] || (_cache[21] = ($event: any) => ((this.splitQty) = $event))
            }, null, 8, ["modelValue"])
          ])
        ])
      ]),
      _: 1
    }, 8, ["visible"]),
    _createVNode(_component_Dialog, {
      header: "Bulk Available",
      visible: this.showBulkDialog,
      "onUpdate:visible": _cache[29] || (_cache[29] = ($event: any) => ((this.showBulkDialog) = $event)),
      onHide: _cache[30] || (_cache[30] = ($event: any) => (_ctx.$emit('hide', this.hidden))),
      style: { width: '800px' },
      modal: true,
      draggable: false
    }, {
      footer: _withCtx(() => [
        _createVNode(_component_Button, {
          "data-test": "cancelDialogButton",
          ref: "cancelDialogButton",
          label: "Cancel",
          icon: "pi pi-times",
          onClick: _cache[27] || (_cache[27] = 
            () => {
              this.showBulkDialog = false;
            }
          ),
          class: "p-button-text"
        }, null, 512),
        _createVNode(_component_Button, {
          "data-test": "submitDialogButton",
          ref: "submitDialogButton",
          label: "Use Bulk",
          icon: "pi pi-check",
          onClick: _cache[28] || (_cache[28] = ($event: any) => (this.copyBulkItem())),
          autofocus: ""
        }, null, 512)
      ]),
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_12, [
          _createVNode(_component_DataTable, {
            value: _ctx.bulkItems,
            responsiveLayout: "stack",
            selection: _ctx.selectedBulkDialogRow,
            "onUpdate:selection": _cache[26] || (_cache[26] = ($event: any) => ((_ctx.selectedBulkDialogRow) = $event))
          }, {
            empty: _withCtx(() => _cache[40] || (_cache[40] = [
              _createTextVNode(" No rows to show. ")
            ])),
            default: _withCtx(() => [
              (_ctx.showSelectionCheckbox)
                ? (_openBlock(), _createBlock(_component_Column, {
                    key: 0,
                    style: {"width":"3rem"},
                    selectionMode: "multiple"
                  }))
                : _createCommentVNode("", true),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.bulkDialogColumns, (column) => {
                return (_openBlock(), _createBlock(_component_Column, {
                  key: column.field,
                  field: column.field,
                  header: column.header,
                  filter: true,
                  showFilterMatchModes: false,
                  showFilterOperator: false,
                  sortable: _ctx.isResultView
                }, {
                  filter: _withCtx(() => [
                    (column.input)
                      ? (_openBlock(), _createBlock(_component_InputText, {
                          key: 0,
                          type: "text",
                          modelValue: _ctx.dynamicFilters[column.field].value,
                          "onUpdate:modelValue": ($event: any) => ((_ctx.dynamicFilters[column.field].value) = $event),
                          class: "p-column-filter"
                        }, null, 8, ["modelValue", "onUpdate:modelValue"]))
                      : _createCommentVNode("", true),
                    (column.calendar)
                      ? (_openBlock(), _createBlock(_component_Calendar, {
                          key: 1,
                          modelValue: _ctx.dynamicFilters[column.field].value,
                          "onUpdate:modelValue": ($event: any) => ((_ctx.dynamicFilters[column.field].value) = $event),
                          dateFormat: "dd-mm-yy",
                          placeholder: "dd-mm-yy"
                        }, null, 8, ["modelValue", "onUpdate:modelValue"]))
                      : _createCommentVNode("", true)
                  ]),
                  filterapply: _withCtx(({ filterCallback }) => [
                    _createVNode(_component_Button, {
                      type: "button",
                      label: "Apply",
                      onClick: ($event: any) => (filterCallback())
                    }, null, 8, ["onClick"])
                  ]),
                  filterclear: _withCtx(() => [
                    _createVNode(_component_Button, {
                      type: "button",
                      label: "Clear",
                      onClick: ($event: any) => (_ctx.clearDynamicSearch(column.field))
                    }, null, 8, ["onClick"])
                  ]),
                  _: 2
                }, 1032, ["field", "header", "sortable"]))
              }), 128))
            ]),
            _: 1
          }, 8, ["value", "selection"])
        ])
      ]),
      _: 1
    }, 8, ["visible"]),
    _createVNode(_component_Dialog, {
      header: this.copyMoveHeader,
      visible: this.showCopyMoveSelectionDialog,
      "onUpdate:visible": _cache[34] || (_cache[34] = ($event: any) => ((this.showCopyMoveSelectionDialog) = $event)),
      onHide: _cache[35] || (_cache[35] = ($event: any) => (_ctx.$emit('hide', this.hidden))),
      style: { width: '800px' },
      modal: true,
      draggable: false
    }, {
      footer: _withCtx(() => [
        _createVNode(_component_Button, {
          "data-test": "cancelDialogButton",
          ref: "cancelDialogButton",
          label: "Cancel",
          icon: "pi pi-times",
          onClick: _cache[32] || (_cache[32] = 
            () => {
              this.showCopyMoveSelectionDialog = false;
            }
          ),
          class: "p-button-text"
        }, null, 512),
        _createVNode(_component_Button, {
          "data-test": "submitDialogButton",
          ref: "submitDialogButton",
          label: "Continue",
          icon: "pi pi-check",
          onClick: _cache[33] || (_cache[33] = 
            () => {
              this.continueCopyMove = true;
              this.showCopyMoveSelectionDialog = false;
            }
          ),
          autofocus: ""
        }, null, 512)
      ]),
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_13, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.availableCopyMoveDestinations, (destination) => {
            return (_openBlock(), _createElementBlock("div", {
              key: destination.value
            }, [
              _createElementVNode("div", _hoisted_14, [
                _createVNode(_component_RadioButton, {
                  modelValue: _ctx.selectedCopyMoveValue,
                  "onUpdate:modelValue": _cache[31] || (_cache[31] = ($event: any) => ((_ctx.selectedCopyMoveValue) = $event)),
                  value: destination.value
                }, null, 8, ["modelValue", "value"]),
                _createElementVNode("label", null, _toDisplayString(destination.label), 1)
              ])
            ]))
          }), 128))
        ])
      ]),
      _: 1
    }, 8, ["header", "visible"]),
    _createVNode(_component_ConfirmChangesDialog, { ref: "confirmChanges" }, null, 512)
  ], 512))
}
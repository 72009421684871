import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "grid justify-content-end mt-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_Column = _resolveComponent("Column")!
  const _component_DataTable = _resolveComponent("DataTable")!

  return (_openBlock(), _createBlock(_component_DataTable, {
    loading: _ctx.isLoadingTransactions,
    value: _ctx.transactionInfo,
    breakpoint: "1267px",
    sortField: _ctx.sortField,
    sortOrder: _ctx.sortOrder,
    paginator: "",
    rows: _ctx.rowsPerPage,
    rowsPerPageOptions: [10, 25, 50],
    class: "p-datatable-sm w-12 text-sm bordered-datatable"
  }, {
    header: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_ctx.allowAddTransactions)
          ? (_openBlock(), _createBlock(_component_Button, {
              key: 0,
              label: "Add Transaction",
              icon: "pi pi-plus",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('add-transaction')))
            }))
          : _createCommentVNode("", true),
        _createVNode(_component_Button, {
          class: "ml-2",
          label: "Refresh",
          icon: "pi pi-refresh",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.fetchTransactionData(true)))
        })
      ])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_Column, {
        field: "date",
        header: "Date",
        sortable: "",
        "header-class": "column-header-left"
      }, {
        body: _withCtx((slotProps) => [
          _createTextVNode(_toDisplayString(slotProps.data.date.toLocaleDateString()), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_Column, {
        field: "qty",
        header: "Quantity",
        sortable: "",
        bodyStyle: "text-align: right",
        "header-class": "column-header-right"
      }),
      _createVNode(_component_Column, {
        field: "from_loc",
        header: "From Location",
        "header-class": "column-header-left"
      }),
      _createVNode(_component_Column, {
        field: "to_loc",
        header: "To Location",
        "header-class": "column-header-left"
      }),
      _createVNode(_component_Column, {
        field: "auth_type",
        header: "Document Type",
        sortable: "",
        "header-class": "column-header-left"
      }),
      _createVNode(_component_Column, {
        field: "auth_doc",
        header: "Document Number",
        sortable: "",
        bodyStyle: "text-align: right",
        "header-class": "column-header-right"
      }),
      _createVNode(_component_Column, {
        field: "from_wo",
        header: "From Wo",
        sortable: "",
        bodyStyle: "text-align: right",
        "header-class": "column-header-right"
      }),
      _createVNode(_component_Column, {
        field: "to_wo",
        header: "To Wo",
        sortable: "",
        bodyStyle: "text-align: right",
        "header-class": "column-header-right"
      }),
      _createVNode(_component_Column, {
        field: "from_lot",
        header: "From Lot",
        bodyStyle: "text-align: right",
        "header-class": "column-header-right"
      }),
      _createVNode(_component_Column, {
        field: "to_lot",
        header: "To Lot",
        bodyStyle: "text-align: right",
        "header-class": "column-header-right"
      })
    ]),
    _: 1
  }, 8, ["loading", "value", "sortField", "sortOrder", "rows"]))
}
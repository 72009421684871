import store from "@/store";
import { RouteLocationNormalizedLoaded } from "vue-router";

const accountingRoutes = {
  path: "/accounting",
  name: "Accounts",
  redirect: getRedirectPath,
  component: () => import("../views/Accounts.vue"),
  children: [
    {
      name: "Accounts Payable",
      path: "/accounting/accounts-payable",
      component: () =>
        import("../components/Accounting/Payables/AccountsPayable.vue"),
      props: true,
      beforeEnter: (
        to: RouteLocationNormalizedLoaded,
        from: RouteLocationNormalizedLoaded,
        next: () => void,
      ): void => {
        resetTab(from);
        next();
      },
    },
    {
      name: "Accounts Payable Details",
      path: "/accounting/accounts-payable/:payableId",
      component: (): Promise<unknown> =>
        import("@/components/Accounting/Payables/PayableHeaderTab.vue"),
      props: true,
    },
    {
      name: "Accounts Payable Line Item",
      path: "/accounting/accounts-payable/:payableId/line-item/:itemId",
      props: true,
      component: (): Promise<unknown> =>
        import("@/components/Accounting/LineItemTab.vue"),
    },
    {
      name: "Accounts Payable Related AP Items",
      path: "/accounting/accounts-payable/:payableId/related-ap-items",
      component: (): Promise<unknown> =>
        import("@/components/Accounting/Payables/PayableRelatedAPItemsTab.vue"),
      props: true,
    },
    {
      name: "Accounts Payable Register History",
      path: "/accounting/accounts-payable/:payableId/register-history",
      component: (): Promise<unknown> =>
        import(
          "@/components/Accounting/Payables/PayableRegisterHistoryTab.vue"
        ),
      props: true,
    },
    {
      name: "Accounts Payable Attachments",
      path: "/accounting/accounts-payable/:payableId/attachments",
      component: (): Promise<unknown> =>
        import("@/components/Accounting/Payables/PayableAttachmentsTab.vue"),
      props: true,
    },
    {
      name: "Accounts Receivable",
      path: "/accounting/accounts-receivable",
      component: () =>
        import("../components/Accounting/Receivables/AccountsReceivable.vue"),
      props: true,
      beforeEnter: (
        to: RouteLocationNormalizedLoaded,
        from: RouteLocationNormalizedLoaded,
        next: () => void,
      ): void => {
        resetTab(from);
        next();
      },
    },
    {
      name: "GL",
      path: "/accounting/gl",
      component: () => import("../components/Accounting/GL/AccountsGL.vue"),
      props: true,
      beforeEnter: (
        to: RouteLocationNormalizedLoaded,
        from: RouteLocationNormalizedLoaded,
        next: () => void,
      ): void => {
        resetTab(from);
        next();
      },
    },
    {
      name: "GL Entry",
      path: "/accounting/gl/:transaction_id/gl-transaction",
      component: (): Promise<unknown> =>
        import("@/components/Accounting/GL/GLTransaction.vue"),
      props: true,
    },
    {
      name: "GL Attachments",
      path: "/accounting/gl/:transaction_id/attachments",
      component: (): Promise<unknown> =>
        import("@/components/Accounting/GL/GLAttachmentsTab.vue"),
      props: true,
    },
    {
      name: "Accounts Orders on Hold",
      path: "/accounting/orders",
      component: () =>
        import("../components/Accounting/Orders/AccountingOrders.vue"),
      props: true,
      beforeEnter: (
        to: RouteLocationNormalizedLoaded,
        from: RouteLocationNormalizedLoaded,
        next: () => void,
      ): void => {
        resetTab(from);
        next();
      },
    },
    {
      name: "Accounting Orders",
      path: "/accounting/orders/:id",
      component: () => import("@/components/Accounting/Orders/Orders.vue"),
      props: true,
    },
  ],
  meta: {
    requiresAuth: true,
    title: "Accounting",
  },
};

function resetTab(path: RouteLocationNormalizedLoaded) {
  if (
    path.fullPath.includes("accounting") &&
    path.fullPath.split("/").length > 2
  ) {
    store.dispatch("accountingInquiry/changeActiveTab", 0);
  }
}

function getRedirectPath() {
  if (store.getters["session/getAccess"]("/accounting/accounts-payable")) {
    return "/accounting/accounts-payable";
  } else if (
    store.getters["session/getAccess"]("/accounting/accounts-receivable")
  ) {
    return "/accounting/accounts-receivable";
  } else {
    return "/accounting/gl";
  }
}

export default accountingRoutes;

import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, _cache[0] || (_cache[0] = [
    _createElementVNode("span", { style: {"display":"flex","justify-content":"center","font-size":"14px"} }, [
      _createTextVNode("Change History has received an upgrade on the back-end and for now is only visible through the desktop. Please  "),
      _createElementVNode("a", { href: "mailto:help@zumasys.com?Subject=Please add in change history for the Customers page!" }, " let us know "),
      _createTextVNode("  if you'd like to see it return to the web more urgently.")
    ], -1)
  ])))
}
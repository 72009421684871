import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "grid" }
const _hoisted_2 = { class: "col-4" }
const _hoisted_3 = { class: "p-inputgroup mt-2" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { class: "p-error" }
const _hoisted_6 = { class: "col-4" }
const _hoisted_7 = { class: "p-inputgroup mt-2" }
const _hoisted_8 = { class: "col-4" }
const _hoisted_9 = { class: "p-inputgroup mt-2" }
const _hoisted_10 = { class: "col-4" }
const _hoisted_11 = { class: "p-inputgroup mt-2" }
const _hoisted_12 = { class: "col-4" }
const _hoisted_13 = { class: "p-inputgroup mt-2" }
const _hoisted_14 = { class: "col-4" }
const _hoisted_15 = { class: "p-inputgroup mt-2" }
const _hoisted_16 = { class: "col-4" }
const _hoisted_17 = { class: "p-inputgroup mt-2" }
const _hoisted_18 = { class: "col-4" }
const _hoisted_19 = { class: "p-inputgroup mt-2" }
const _hoisted_20 = { class: "col-4" }
const _hoisted_21 = { class: "p-inputgroup mt-2" }
const _hoisted_22 = { class: "col-12" }
const _hoisted_23 = { class: "grid" }
const _hoisted_24 = {
  key: 0,
  class: "col-12 lg:col-4"
}
const _hoisted_25 = { class: "grid mb-2" }
const _hoisted_26 = { class: "field col-12" }
const _hoisted_27 = { class: "flex" }
const _hoisted_28 = {
  key: 0,
  class: "grid mb-2"
}
const _hoisted_29 = { class: "field col-12" }
const _hoisted_30 = { class: "flex" }
const _hoisted_31 = {
  key: 1,
  class: "col-12 lg:col-4"
}
const _hoisted_32 = { class: "grid mb-2" }
const _hoisted_33 = { class: "field col-12" }
const _hoisted_34 = { class: "flex" }
const _hoisted_35 = {
  key: 0,
  class: "grid mb-2"
}
const _hoisted_36 = { class: "field col-12" }
const _hoisted_37 = { class: "flex" }
const _hoisted_38 = {
  key: 0,
  class: "col-12"
}
const _hoisted_39 = { class: "grid" }
const _hoisted_40 = { class: "col-12" }
const _hoisted_41 = { class: "flex col-12 justify-content-between" }
const _hoisted_42 = { style: {"text-align":"left !important"} }
const _hoisted_43 = { style: {"text-align":"right !important"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Search = _resolveComponent("Search")!
  const _component_ContactAutocomplete = _resolveComponent("ContactAutocomplete")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_InputNumber = _resolveComponent("InputNumber")!
  const _component_Calendar = _resolveComponent("Calendar")!
  const _component_Button = _resolveComponent("Button")!
  const _component_AttachmentRecords = _resolveComponent("AttachmentRecords")!
  const _component_TextArea = _resolveComponent("TextArea")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_Dialog, {
      visible: this.hidden,
      "onUpdate:visible": _cache[22] || (_cache[22] = ($event: any) => ((this.hidden) = $event)),
      onHide: _cache[23] || (_cache[23] = ($event: any) => (_ctx.$emit('hide', this.hidden))),
      header: this.header,
      style: { width: '1000px' },
      modal: true,
      draggable: false
    }, {
      footer: _withCtx(() => [
        _createElementVNode("div", _hoisted_41, [
          _createElementVNode("div", _hoisted_42, [
            (_ctx.isEditing)
              ? (_openBlock(), _createBlock(_component_Button, {
                  key: 0,
                  icon: "pi pi-plus",
                  label: "Create Quote",
                  iconPos: "left",
                  loading: _ctx.isLoadingQuotes,
                  onClick: _cache[19] || (_cache[19] = ($event: any) => (_ctx.createQuote()))
                }, null, 8, ["loading"]))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_43, [
            _createVNode(_component_Button, {
              label: "Cancel",
              icon: "pi pi-times",
              onClick: _cache[20] || (_cache[20] = 
                () => {
                  _ctx.$emit('hide', false);
                }
              ),
              class: "p-button-text"
            }),
            _createVNode(_component_Button, {
              label: "Save",
              icon: "pi pi-check",
              onClick: _cache[21] || (_cache[21] = ($event: any) => (_ctx.save())),
              loading: this.submitLoading
            }, null, 8, ["loading"])
          ])
        ])
      ]),
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _cache[24] || (_cache[24] = _createElementVNode("span", {
                class: "p-inputgroup-addon",
                style: { width: '103px' }
              }, "Customer", -1)),
              _createVNode(_component_Search, {
                modelValue: this.customerName,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((this.customerName) = $event)),
                placeHolder: "Search by ID or Name",
                file: "NEWSALES",
                label: "name",
                onFilterCust: _cache[1] || (_cache[1] = ($event: any) => (_ctx.setCustomer($event.value))),
                class: _normalizeClass({
                'p-invalid':
                  _ctx.isFormSubmitted && _ctx.v$.customerName.required.$invalid,
              })
              }, null, 8, ["modelValue", "class"])
            ]),
            (_ctx.isFormSubmitted && _ctx.v$.customerName.required.$invalid)
              ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                  _createElementVNode("small", _hoisted_5, _toDisplayString(_ctx.v$.customerName.required.$message), 1)
                ]))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              _cache[25] || (_cache[25] = _createElementVNode("span", {
                class: "p-inputgroup-addon",
                style: { width: '103px' }
              }, "Prospect", -1)),
              _createVNode(_component_Search, {
                modelValue: this.prospectName,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((this.prospectName) = $event)),
                onFilter: _cache[3] || (_cache[3] = ($event: any) => (_ctx.setProspect($event.value))),
                label: "name",
                file: "PROSPECT"
              }, null, 8, ["modelValue"])
            ])
          ]),
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("div", _hoisted_9, [
              _cache[26] || (_cache[26] = _createElementVNode("span", {
                class: "p-inputgroup-addon",
                style: { width: '103px' }
              }, "Contact", -1)),
              _createVNode(_component_ContactAutocomplete, {
                "selected-id": _ctx.saleOpp.contact,
                onItemSelect: _cache[4] || (_cache[4] = ($event: any) => (_ctx.handleContactSelected($event)))
              }, null, 8, ["selected-id"])
            ])
          ]),
          _createElementVNode("div", _hoisted_10, [
            _createElementVNode("div", _hoisted_11, [
              _cache[27] || (_cache[27] = _createElementVNode("span", {
                class: "p-inputgroup-addon",
                style: { width: '103px' }
              }, "Status", -1)),
              _createVNode(_component_Dropdown, {
                modelValue: this.saleOpp.status,
                "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((this.saleOpp.status) = $event)),
                options: _ctx.control.saleOpportunity.status_items,
                optionLabel: "status",
                optionValue: "status"
              }, null, 8, ["modelValue", "options"])
            ])
          ]),
          _createElementVNode("div", _hoisted_12, [
            _createElementVNode("div", _hoisted_13, [
              _cache[28] || (_cache[28] = _createElementVNode("span", {
                class: "p-inputgroup-addon",
                style: { width: '103px' }
              }, "Type", -1)),
              _createVNode(_component_Dropdown, {
                modelValue: this.saleOpp.type,
                "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((this.saleOpp.type) = $event)),
                options: _ctx.control.saleOpportunity.type_items,
                optionLabel: "type",
                optionValue: "type"
              }, null, 8, ["modelValue", "options"])
            ])
          ]),
          _createElementVNode("div", _hoisted_14, [
            _createElementVNode("div", _hoisted_15, [
              _cache[29] || (_cache[29] = _createElementVNode("span", {
                class: "p-inputgroup-addon",
                style: { width: '103px' }
              }, "Assigned To", -1)),
              _createVNode(_component_InputText, {
                modelValue: this.saleOpp.assigned_to,
                "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((this.saleOpp.assigned_to) = $event))
              }, null, 8, ["modelValue"])
            ])
          ]),
          _createElementVNode("div", _hoisted_16, [
            _createElementVNode("div", _hoisted_17, [
              _cache[30] || (_cache[30] = _createElementVNode("span", {
                class: "p-inputgroup-addon",
                style: { width: '103px' }
              }, "Value", -1)),
              _createVNode(_component_InputNumber, {
                modelValue: this.saleOppAmount,
                "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((this.saleOppAmount) = $event)),
                mode: "decimal",
                minFractionDigits: 2,
                maxFractionDigits: 2
              }, null, 8, ["modelValue"])
            ])
          ]),
          _createElementVNode("div", _hoisted_18, [
            _createElementVNode("div", _hoisted_19, [
              _cache[31] || (_cache[31] = _createElementVNode("span", {
                class: "p-inputgroup-addon",
                style: { width: '103px' }
              }, "Est Close", -1)),
              _createVNode(_component_Calendar, {
                modelValue: this.saleOpp.est_close,
                "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((this.saleOpp.est_close) = $event)),
                dateFormat: "mm-dd-yy",
                onDateSelect: _cache[10] || (_cache[10] = ($event: any) => (_ctx.formatDate($event)))
              }, null, 8, ["modelValue"])
            ])
          ]),
          _createElementVNode("div", _hoisted_20, [
            _createElementVNode("div", _hoisted_21, [
              _cache[32] || (_cache[32] = _createElementVNode("span", {
                class: "p-inputgroup-addon",
                style: { width: '103px' }
              }, "Source", -1)),
              _createVNode(_component_Dropdown, {
                modelValue: this.saleOpp.source,
                "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((this.saleOpp.source) = $event)),
                options: _ctx.control.saleOpportunity.source_items,
                optionLabel: "source",
                optionValue: "source"
              }, null, 8, ["modelValue", "options"])
            ])
          ]),
          _createElementVNode("div", _hoisted_22, [
            _createElementVNode("div", _hoisted_23, [
              (_ctx.isEditing)
                ? (_openBlock(), _createElementBlock("div", _hoisted_24, [
                    _cache[35] || (_cache[35] = _createElementVNode("div", { class: "flex justify-content-between align-items-center" }, [
                      _createElementVNode("h3", null, "Quotes")
                    ], -1)),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(this.saleOpp.quote_items, (ele, index) => {
                      return (_openBlock(), _createElementBlock("div", { key: index }, [
                        _createElementVNode("div", _hoisted_25, [
                          _createElementVNode("div", _hoisted_26, [
                            _cache[33] || (_cache[33] = _createElementVNode("label", { for: "email_address" }, "ID", -1)),
                            _createElementVNode("div", _hoisted_27, [
                              _createVNode(_component_InputText, {
                                modelValue: ele.quote,
                                "onUpdate:modelValue": ($event: any) => ((ele.quote) = $event)
                              }, null, 8, ["modelValue", "onUpdate:modelValue"]),
                              _createVNode(_component_Button, {
                                icon: "pi pi-minus",
                                class: "p-button-text",
                                onClick: ($event: any) => (this.saleOpp.quote_items.splice(index, 1)),
                                title: "Delete Quote"
                              }, null, 8, ["onClick"])
                            ])
                          ])
                        ])
                      ]))
                    }), 128)),
                    (this.isEditing)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_28, [
                          _createElementVNode("div", _hoisted_29, [
                            _cache[34] || (_cache[34] = _createElementVNode("label", { for: "email_address" }, "ID", -1)),
                            _createElementVNode("div", _hoisted_30, [
                              _createVNode(_component_InputText, {
                                modelValue: this.quote.quote,
                                "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((this.quote.quote) = $event)),
                                ref: "id"
                              }, null, 8, ["modelValue"]),
                              _createVNode(_component_Button, {
                                icon: "pi pi-plus",
                                class: "p-button-text",
                                onClick: _cache[13] || (_cache[13] = ($event: any) => (_ctx.pushQuote())),
                                title: "New Quote"
                              })
                            ])
                          ])
                        ]))
                      : _createCommentVNode("", true)
                  ]))
                : _createCommentVNode("", true),
              (_ctx.isEditing)
                ? (_openBlock(), _createElementBlock("div", _hoisted_31, [
                    _cache[38] || (_cache[38] = _createElementVNode("div", { class: "flex justify-content-between align-items-center" }, [
                      _createElementVNode("h3", null, "Sales Order")
                    ], -1)),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(this.saleOpp.so_items, (ele, index) => {
                      return (_openBlock(), _createElementBlock("div", { key: index }, [
                        _createElementVNode("div", _hoisted_32, [
                          _createElementVNode("div", _hoisted_33, [
                            _cache[36] || (_cache[36] = _createElementVNode("label", { for: "email_address" }, "ID", -1)),
                            _createElementVNode("div", _hoisted_34, [
                              _createVNode(_component_InputText, {
                                modelValue: ele.so,
                                "onUpdate:modelValue": ($event: any) => ((ele.so) = $event)
                              }, null, 8, ["modelValue", "onUpdate:modelValue"]),
                              _createVNode(_component_Button, {
                                icon: "pi pi-minus",
                                class: "p-button-text",
                                onClick: ($event: any) => (this.saleOpp.so_items.splice(index, 1)),
                                title: "remove Sales Order"
                              }, null, 8, ["onClick"])
                            ])
                          ])
                        ])
                      ]))
                    }), 128)),
                    (this.isEditing)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_35, [
                          _createElementVNode("div", _hoisted_36, [
                            _cache[37] || (_cache[37] = _createElementVNode("label", { for: "email_address" }, "ID", -1)),
                            _createElementVNode("div", _hoisted_37, [
                              _createVNode(_component_InputText, {
                                modelValue: this.salesOrder.so,
                                "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((this.salesOrder.so) = $event)),
                                ref: "id"
                              }, null, 8, ["modelValue"]),
                              _createVNode(_component_Button, {
                                icon: "pi pi-plus",
                                class: "p-button-text",
                                onClick: _cache[15] || (_cache[15] = ($event: any) => (_ctx.pushSalesOrder())),
                                title: "New Sales Order"
                              })
                            ])
                          ])
                        ]))
                      : _createCommentVNode("", true)
                  ]))
                : _createCommentVNode("", true)
            ])
          ]),
          (_ctx.isEditing)
            ? (_openBlock(), _createElementBlock("div", _hoisted_38, [
                _createElementVNode("div", _hoisted_39, [
                  _cache[39] || (_cache[39] = _createElementVNode("h3", { class: "m-2" }, "Attachments", -1)),
                  _createVNode(_component_AttachmentRecords, {
                    hostFile: "SALEOPP",
                    record: this.saleOpp,
                    onOnDeleted: _cache[16] || (_cache[16] = 
                (event) => {
                  _ctx.handleDeleted(event);
                }
              ),
                    onOnSaved: _cache[17] || (_cache[17] = 
                (event) => {
                  _ctx.handleSaved(event);
                }
              )
                  }, null, 8, ["record"])
                ])
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_40, [
            _createVNode(_component_TextArea, {
              rows: "5",
              "aria-label": "Sale Opportunity Notes",
              placeholder: "Notes",
              modelValue: this.saleOpp.notes,
              "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => ((this.saleOpp.notes) = $event))
            }, null, 8, ["modelValue"])
          ])
        ])
      ]),
      _: 1
    }, 8, ["visible", "header"])
  ]))
}
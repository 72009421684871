
import { defineComponent } from "vue";
import { mapGetters } from "vuex";
import { mapActions } from "pinia";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Button from "primevue/button";

import { useInventoryStore } from "@/store/ui/inventoryModule";

export default defineComponent({
  props: {
    partNumber: String,
  },
  components: {
    DataTable,
    Column,
    Button,
  },
  computed: {
    ...mapGetters({
      getClient: "session/getClient",
    }),
  },
  created() {
    this.fetchShortsData();
  },

  methods: {
    ...mapActions(useInventoryStore, ["fetchShortsById"]),
    async fetchShortsData(refresh = false) {
      if (!this.partNumber) return;

      this.isLoadingShorts = true;

      const resp = await this.fetchShortsById(this.partNumber, refresh);

      if (resp.success && resp.shorts) {
        this.workOrderInfo = resp.shorts.wo_items;
      }
      this.isLoadingShorts = false;
    },
  },
  watch: {
    partNumber: {
      handler: "fetchShortsData",
      immediate: true,
    },
  },
  data() {
    return {
      workOrderInfo: [] as Array<any>,
      isLoadingShorts: false,
      sortField: "",
      sortOrder: 1,
      rowsPerPage: 10,
    };
  },
});

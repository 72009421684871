import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "grid m-0" }
const _hoisted_2 = { class: "flex col-12 justify-content-end align-items-center my-3" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { class: "field" }
const _hoisted_6 = { class: "field" }
const _hoisted_7 = { class: "field" }
const _hoisted_8 = {
  key: 0,
  class: "p-error"
}
const _hoisted_9 = { class: "field" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_LoadingSpinner = _resolveComponent("LoadingSpinner")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Column = _resolveComponent("Column")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _component_Card = _resolveComponent("Card")!
  const _component_Calendar = _resolveComponent("Calendar")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_Textarea = _resolveComponent("Textarea")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", null, [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_Button, {
            class: "split-sales-order-btn",
            label: "Print/Export",
            icon: "pi pi-print",
            onClick: _ctx.printExportData
          }, null, 8, ["onClick"]),
          _createVNode(_component_Button, {
            label: "New Contact Log",
            icon: "pi pi-plus",
            onClick: _ctx.openNew
          }, null, 8, ["onClick"])
        ])
      ]),
      _createVNode(_component_Card, null, {
        content: _withCtx(() => [
          _createVNode(_component_LoadingSpinner, { loading: _ctx.loading }, null, 8, ["loading"]),
          (!_ctx.loading)
            ? (_openBlock(), _createBlock(_component_DataTable, {
                key: 0,
                value: _ctx.contactLogItems,
                paginator: true,
                rows: 10,
                paginatorTemplate: "FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown",
                rowsPerPageOptions: [10, 25, 50],
                currentPageReportTemplate: "Showing {first} to {last} of {totalRecords} entries",
                breakpoint: "1279px",
                class: "p-datatable-sm",
                sortField: 'date_created',
                sortOrder: -1
              }, {
                empty: _withCtx(() => _cache[5] || (_cache[5] = [
                  _createTextVNode(" No logs to display. ")
                ])),
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.columns, (col) => {
                    return (_openBlock(), _createBlock(_component_Column, {
                      field: col.field,
                      header: col.header,
                      key: col.field,
                      sortable: ""
                    }, {
                      body: _withCtx(({ field, data }) => [
                        (field !== 'date_created')
                          ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(data[field]), 1))
                          : _createCommentVNode("", true),
                        (field === 'date_created')
                          ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.formatDate(data[field])), 1))
                          : _createCommentVNode("", true)
                      ]),
                      editor: _withCtx(({ data, field }) => [
                        _createVNode(_component_InputText, {
                          modelValue: data[field],
                          "onUpdate:modelValue": ($event: any) => ((data[field]) = $event),
                          autofocus: ""
                        }, null, 8, ["modelValue", "onUpdate:modelValue"])
                      ]),
                      _: 2
                    }, 1032, ["field", "header"]))
                  }), 128)),
                  _createVNode(_component_Column, null, {
                    body: _withCtx(({ data, index }) => [
                      _createVNode(_component_Button, {
                        icon: "pi pi-pencil",
                        title: "Edit",
                        onClick: ($event: any) => (_ctx.editContactLog(data, index))
                      }, null, 8, ["onClick"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["value"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    _createVNode(_component_Dialog, {
      visible: _ctx.cntLogInfoDialog,
      "onUpdate:visible": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.cntLogInfoDialog) = $event)),
      style: { width: '450px' },
      header: "Contact Log Details",
      modal: true,
      draggable: false,
      class: "p-fluid"
    }, {
      footer: _withCtx(() => [
        _createVNode(_component_Button, {
          label: "Cancel",
          icon: "pi pi-times",
          class: "p-button-text",
          onClick: _ctx.hideDialog
        }, null, 8, ["onClick"]),
        _createVNode(_component_Button, {
          label: "Save",
          icon: "pi pi-check",
          class: "p-button-text",
          onClick: _ctx.saveLogInfo
        }, null, 8, ["onClick"])
      ]),
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_5, [
          _cache[6] || (_cache[6] = _createElementVNode("label", { for: "subject" }, "Date", -1)),
          _createVNode(_component_Calendar, {
            modelValue: _ctx.cntLog.date_created,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.cntLog.date_created) = $event)),
            format: "MM/dd/yyyy",
            placeholder: "Select Date",
            showIcon: true
          }, null, 8, ["modelValue"])
        ]),
        _createElementVNode("div", _hoisted_6, [
          _cache[7] || (_cache[7] = _createElementVNode("label", { for: "subject" }, "Type", -1)),
          _createVNode(_component_Dropdown, {
            id: "logType",
            autofocus: "",
            autoResize: true,
            type: "text",
            class: "text-sm",
            modelValue: _ctx.cntLog.type,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.cntLog.type) = $event)),
            options: _ctx.getLogTypes,
            placeholder: "Select Type",
            optionLabel: "types",
            optionValue: "types",
            style: {"width":"100%"}
          }, null, 8, ["modelValue", "options"])
        ]),
        _createElementVNode("div", _hoisted_7, [
          _cache[8] || (_cache[8] = _createElementVNode("label", { for: "subject" }, "Subject", -1)),
          _createVNode(_component_Textarea, {
            id: "subject",
            modelValue: _ctx.cntLog.subject,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.cntLog.subject) = $event)),
            required: "true",
            autofocus: "",
            class: _normalizeClass({ 'p-invalid': _ctx.isSubmitted && !_ctx.cntLog.subject }),
            autoResize: true,
            rows: "5",
            cols: "30"
          }, null, 8, ["modelValue", "class"]),
          (_ctx.isSubmitted && !_ctx.cntLog.subject)
            ? (_openBlock(), _createElementBlock("small", _hoisted_8, "Subject is required."))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_9, [
          _cache[9] || (_cache[9] = _createElementVNode("label", { for: "subject" }, "Details", -1)),
          _createVNode(_component_Textarea, {
            id: "details",
            modelValue: _ctx.cntLog.details,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.cntLog.details) = $event)),
            autofocus: "",
            autoResize: true,
            rows: "5",
            cols: "30"
          }, null, 8, ["modelValue"])
        ])
      ]),
      _: 1
    }, 8, ["visible"])
  ], 64))
}

import { computed, defineComponent } from "vue";
import { mapActions, mapGetters } from "vuex";
import TopStatCards from "@/components/StatCards/TopStatCards.vue";
import MessageBox from "@/components/MessageBox.vue";

import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import Button from "primevue/button";

import { AccountingObject } from "@/types/state/accountingInquiry";
import Utils from "@/utility/utils";
import SalesOrder from "@/types/salesorder";
import RoverInput from "./UI/RoverInput.vue";
import { Field } from "@/types/fdict";
import Dialog from "primevue/dialog";
import PrintPickTicket from "./Pos/PrintPickTicket.vue";
import SalesService from "@/services/SalesService";
import cloneDeep from "lodash/cloneDeep";
import { FDICT_SO } from "@/utility/fdicts/so";
const colors = ["#58b41c", "#04789f", "#f5a623", "#fa332e", "#ca0c04"];

const salesService = new SalesService();

export default defineComponent({
  name: "AccountsForm",
  components: {
    TabView,
    TabPanel,
    TopStatCards,
    Button,
    MessageBox,
    RoverInput,
    Dialog,
    PrintPickTicket,
  },
  data() {
    return {
      showReleaseDialog: false,
      showPickTicketPrinterDialog: false,
      loadingReleaseOrder: false,
      order: {} as SalesOrder,
      tabToDelete: undefined as AccountingObject | undefined,
      showConfirmDeleteTab: false,
      activeLineItemTab: 0,
      active: 0,
      accountItems: {
        payables: {
          label: "Payables",
          to: "/accounting/accounts-payable",
          statInfo: {
            horizontalStatsInfo: [],
            verticalStatsInfo: {
              daysQty: "0",
              daysType: "DPO",
              amount: "0",
              agingType: "",
            },
          },
          getter: this.fetchAccountsPayable,
          component: "StatCard",
        },
        receivables: {
          label: "Receivables",
          to: "/accounting/accounts-receivable",
          statInfo: {
            horizontalStatsInfo: [],
            verticalStatsInfo: {
              daysQty: "0",
              daysType: "DSO",
              amount: "0",
              agingType: "",
            },
          },
          getter: this.fetchAccountsReceivable,
          component: "StatCard",
        },
        gl: {
          label: "GL",
          to: "/accounting/gl",
          statInfo: {
            verticalStatsInfo: {
              daysQty: "999",
              daysType: "",
              amount: "999",
              data: ["999", "999", "999", "999"],
            },
            totals: [
              { label: "REV", actual: "999", budget: "999" },
              { label: "EXP", actual: "999", budget: "999" },
              { label: "EBITDA", actual: "999", budget: "999" },
            ],
          },
          getter: null,
          component: "StatCardGl",
        },
        orders: {
          label: "Orders",
          to: "/accounting/orders",
          statInfo: {},
          amountArray: [],
          accessCheckTo: "/accounting/orders",
          getter: this.fetchOrders,
          component: "DataStatCard",
          subtitle: "On Credit Hold",
        },
      } as any,
    };
  },
  inject: ["isProduction"],
  provide() {
    return {
      accountsReceivableAgingType: computed(
        () =>
          this.accountItems.receivables.statInfo.verticalStatsInfo.agingType,
      ),
      accountsPayableAgingType: computed(
        () => this.accountItems.payables.statInfo.verticalStatsInfo.agingType,
      ),
      orderAccessRights: computed(() =>
        this.getAccessRights("/accounting/orders"),
      ),
    };
  },
  computed: {
    ...mapGetters({
      getActiveTab: "accountingForm/getActiveTab",
      getAPKpi: "accountingPayables/getKpi",
      getARKpi: "invoice/getKpi",
      getAccountingInquiryMap: "accountingInquiry/getAccountingInquiryMap",
      getActiveMainTab: "accountingInquiry/getActiveTab",
      getActiveLineItemsTab: "accountingInquiry/getActiveLineItemsTab",
      getActiveInnerTab: "accountingInquiry/getActiveInnerTab",
      getActiveInnerTabSuffix: "accountingInquiry/getActiveInnerTabSuffix",
      innerTabs: "accountingInquiry/getInnerTabs",
      getLineItemsMap: "accountingInquiry/getLineItemsMap",
      getAccess: "session/getAccess",
      accountingOrdersLookup: "mrkControl/getAccountingOrdersLookup",
      getField: "fdict/getField",
      getClient: "session/getClient",
      getAccessRights: "session/getAccessRights",
    }),
    salesOrderReleaseReasonField(): Field {
      return this.getField("SO", FDICT_SO.RELEASE_CODE);
    },
    isSaveButtonVisible(): boolean {
      const disabledInTabs = [
        "related-ap-items",
        "register-history",
        "attachments",
        "line-item",
        "gl-transaction",
      ];
      const pathElements = this.$route.fullPath
        .split("/")
        .filter((element) => element !== "");
      return (
        pathElements.length > 3 &&
        !disabledInTabs.includes(pathElements[pathElements.length - 1]) &&
        !this.$route.fullPath.includes("line-item")
      );
    },
    filteredAccountItems(): Record<
      string,
      { label: string; to: string; statInfo: any }
    > {
      let filteredItems: Record<
        string,
        { label: string; to: string; statInfo: any }
      > = {};
      for (const item in this.accountItems) {
        if (
          this.getAccess(
            this.accountItems[item].to || this.accountItems[item].accessCheckTo,
          )
        ) {
          filteredItems = { ...filteredItems, [item]: this.accountItems[item] };
        }
      }
      return filteredItems;
    },
  },
  methods: {
    ...mapActions({
      setActiveTab: "accountingForm/setActiveTab",
      fetchAccountsPayableKpi: "accountingPayables/fetchAccountsPayableKpi",
      fetchAccountsReceivableKpi: "invoice/fetchAccountsReceivableKpi",
      removeAccountingInquiryTab:
        "accountingInquiry/removeOpenedAccountingInquiryTab",
      changeActiveMainTab: "accountingInquiry/changeActiveTab",
      changeActiveInnerTab: "accountingInquiry/changeActiveInnerTab",
      changeLineItemTab: "accountingInquiry/changeLineItemTab",
      removeOpenedLineItem: "accountingInquiry/removeOpenedLineItem",
      fetchSoMonthlyData: "sales/fetchSoMonthlyData",
      addNotification: "notification/add",
      getPrinterControl: "printControl/getPrintControl",
    }),
    isInnerTabVisible(tab: any) {
      if (tab.accountType === "accounts-payable") {
        return tab.record.ap_id.includes("New") || tab.record.transaction_id
          ? false
          : true;
      }
      return true;
    },
    handleReleaseOrder(salesOrder: SalesOrder) {
      this.order = salesOrder;
      this.showReleaseDialog = true;
    },
    async releaseOrder() {
      this.loadingReleaseOrder = true;

      try {
        const resp = await salesService.updateOrder(this.order.so_id, {
          newSalesOrder: this.order,
          oldSalesOrder: cloneDeep(this.order),
          metaData: JSON.stringify({
            process_type: "SAVE.RELEASE",
          }),
        });

        if (resp.status === "success") {
          if (resp.metaData.show_pick_ticket) {
            this.showPickTicketPrinterDialog = true;
          }
          this.addNotification({
            type: "success",
            message: "Order released successfully",
          });
          this.showReleaseDialog = false;
        }
      } catch (error) {
        console.error(error);
      }

      this.loadingReleaseOrder = false;
    },
    getConfirmDeleteTabMessage() {
      if (!this.tabToDelete) {
        return "";
      }
      const tab = new AccountingObject(
        this.tabToDelete.old_record,
        this.tabToDelete.record,
        this.tabToDelete.accountType,
        this.tabToDelete.lineItemsMap,
        this.tabToDelete.lineItemsActiveTab,
        this.tabToDelete.activeInnerTab,
      );

      return `Unsaved Changes in ${tab.getHumanReadableType()} #${tab.getRecordId()}`;
    },
    areInnerTabsVisible(tab: any) {
      if (tab.accountType === "gl") {
        return true;
      } else if (tab.accountType === "accounts-payable") {
        return (
          this.getActiveLineItemsTab(tab.record.ap_id) === 0 &&
          tab.accountType === "accounts-payable" &&
          !this.$route.fullPath.includes("line-item")
        );
      }
    },
    handleTabChange(e: any) {
      this.active = e.index;
    },
    handleStatCardTabChange(e: any) {
      this.setActiveTab(e.index);
    },
    fetchAccountsPayable() {
      if (!this.getAPKpi) {
        this.fetchAccountsPayableKpi().then((res: any) => {
          this.setAccountsPayableKpiCardData(this.getAPKpi);
        });
      } else {
        this.setAccountsPayableKpiCardData(this.getAPKpi);
      }
    },
    setAccountsPayableKpiCardData(kpi: any) {
      this.accountItems.payables.statInfo.horizontalStatsInfo = kpi?.aging?.map(
        (item: any, index: number) => {
          return {
            label: item.label,
            value: item.value,
            rangeStart: parseInt(item.rangeStart) || null,
            rangeEnd: parseInt(item.rangeEnd) || null,
            color: colors[index],
          };
        },
      );
      this.accountItems.payables.statInfo.verticalStatsInfo.amount = kpi?.total;
      this.accountItems.payables.statInfo.verticalStatsInfo.daysQty = kpi?.dpo;
      this.accountItems.payables.statInfo.verticalStatsInfo.agingType =
        kpi?.agingType;
    },
    fetchAccountsReceivable() {
      if (!this.getARKpi) {
        this.fetchAccountsReceivableKpi().then((res: any) => {
          this.setAccountsReceivableKpiCardData(this.getARKpi);
        });
      } else {
        this.setAccountsReceivableKpiCardData(this.getARKpi);
      }
    },
    async fetchOrders() {
      const response = await salesService.getOrders(
        undefined,
        "",
        undefined,
        "order_amount",
        "",
        "SO",
        true,
      );
      const soItems = response.so_items;
      const count = soItems.length;
      const total = soItems.reduce((acc: number, item: any) => {
        return acc + parseFloat(item.order_amount);
      }, 0);

      this.accountItems.orders.statInfo.data = [
        { label: "Total Orders", value: count },
        {
          label: "Total Amount",
          value: Utils.formatAmount(total, false, false),
        },
      ];
    },
    setAccountsReceivableKpiCardData(kpi: any) {
      this.accountItems.receivables.statInfo.horizontalStatsInfo =
        kpi?.aging.map((item: any, index: number) => {
          return {
            label: item.label,
            value: item.value,
            rangeStart: parseInt(item.rangeStart) || null,
            rangeEnd: parseInt(item.rangeEnd) || null,
            color: colors[index],
          };
        });
      this.accountItems.receivables.statInfo.verticalStatsInfo.amount =
        kpi?.total;
      this.accountItems.receivables.statInfo.verticalStatsInfo.daysQty =
        kpi?.dso;
      this.accountItems.receivables.statInfo.verticalStatsInfo.agingType =
        kpi?.agingType;
    },
    handleCreatedOrUpdated(hookName?: string) {
      const activeAccountingTab = this.getActiveMainTab;
      const accountingItems = this.getAccountingInquiryMap;
      const item = AccountingObject.hydrate(
        accountingItems.length > 0 && accountingItems[activeAccountingTab - 1]
          ? (accountingItems[activeAccountingTab - 1] as AccountingObject)
          : undefined,
      );

      const accountType = item ? item.accountType : "";

      const accountId = item ? item.getRecordId() : "";

      if (accountType === "accounts-payable") {
        this.activeLineItemTab = this.getActiveLineItemsTab(accountId) || 0;
        if (
          activeAccountingTab > 0 &&
          accountingItems.length > 0 &&
          this.activeLineItemTab > 0
        ) {
          const itemId =
            this.getLineItemsMap(accountId)[this.activeLineItemTab - 1].li;
          this.$router.push(
            `/accounting/accounts-payable/${accountId}/line-item/${itemId}`,
          );
        } else if (
          hookName == "created" &&
          activeAccountingTab > 0 &&
          accountingItems.length > 0 &&
          this.activeLineItemTab === 0
        ) {
          this.$router.push(`/accounting/accounts-payable/${accountId}`);
        }
        if (
          this.activeLineItemTab === 0 &&
          this.getActiveInnerTab(accountId) > 0
        ) {
          const suffix = this.getActiveInnerTabSuffix(accountId);
          this.$router.push(
            `/accounting/accounts-payable/${accountId}/${suffix}`,
          );
        }
      } else if (accountType === "accounts-receivable") {
        this.$router.push(`/accounting/accounts-receivable/${accountId}`);
      } else if (accountType === "gl") {
        const suffix = this.getActiveInnerTabSuffix(accountId);
        this.$router.push(`/accounting/gl/${accountId}/${suffix}`);
      }
    },
    getTabTitle(tab: AccountingObject) {
      let title = "";

      const titleTab = AccountingObject.hydrate(tab);
      if (!titleTab) {
        return title;
      }

      if (titleTab.getRecordId()?.startsWith("New")) {
        return (title += `New ${titleTab.getHumanReadableType()}`);
      }

      title += `${titleTab.getHumanReadableType()}`;
      return title;
    },
    formatTabId(tab: any) {
      let id = AccountingObject.hydrate(tab)?.record.getId();
      return id?.includes("New") ? `#${id.split("-")[1]}` : `#${id}`;
    },
    hasChanged(data: any) {
      const oldRecord = JSON.parse(JSON.stringify(data.old_record || {}));
      const record = JSON.parse(JSON.stringify(data.record));

      if (
        (record.ap_id && record.ap_id?.includes("New")) ||
        (record.id && record.id?.includes("New")) ||
        (record.transaction_id && record.transaction_id?.includes("New")) ||
        (record.so_id && record.so_id?.includes("New"))
      ) {
        return true;
      }

      const ignoreFields = [
        "isChanged",
        "accountType",
        "ap_id",
        "so_id",
        "id",
        "transaction_id",
        "lineItemsActiveTab",
        "activeInnerTab",
        "lineItemsMap",
      ];
      if (record.notes === undefined) {
        ignoreFields.push("notes");
      }

      const result = Utils.compareTwoObjects(record, oldRecord, ignoreFields);
      return result;
    },
    handleRemoveAccountingTab(tab: AccountingObject) {
      if (this.hasChanged(tab)) {
        this.tabToDelete = tab;
        this.showConfirmDeleteTab = true;
      } else {
        this.removeAccountingInquiryTab({ ...tab });
      }
    },
    handleMainTabChange(e: any) {
      this.changeActiveMainTab(e.index !== undefined ? e.index : 0);
    },
    handleCloseSaveFieldServiceTab() {
      this.showConfirmDeleteTab = false;
      this.removeAccountingInquiryTab({ ...this.tabToDelete });
      this.tabToDelete = undefined;
    },
    handleCancelSaveFieldServiceTab() {
      this.showConfirmDeleteTab = false;
      this.tabToDelete = undefined;
    },
    isReadOnly(tab: any) {
      return !tab.record.getId().startsWith("New");
    },
    handleLineItemTabChange(event: any, tab: any) {
      this.changeLineItemTab({
        accountId: tab.record.ap_id,
        tabIndex: event.index,
      });
      this.activeLineItemTab = this.getActiveLineItemsTab(tab.record.ap_id);
      if (
        this.activeLineItemTab === 0 &&
        this.getActiveInnerTab(tab.record.ap_id) !== undefined &&
        this.getActiveInnerTab(tab.record.ap_id) > 0
      ) {
        const suffix = this.getActiveInnerTabSuffix(tab.record.ap_id);
        this.$router.push(
          `/accounting/accounts-payable/${tab.record.ap_id}/${suffix}`,
        );
      } else if (this.activeLineItemTab === 0) {
        this.$router.push(`/accounting/accounts-payable/${tab.record.ap_id}`);
      } else if (this.activeLineItemTab > 0) {
        const itemId = this.getLineItemsMap(tab.record.ap_id)[
          this.activeLineItemTab - 1
        ].li;
        this.$router.push(
          `/accounting/accounts-payable/${tab.record.ap_id}/line-item/${itemId}`,
        );
      }
    },
    handleInnerTabChange(event: any, tab: any) {
      this.changeActiveInnerTab({
        accountId: tab.record.ap_id ?? tab.record.transaction_id,
        tabIndex: event.index,
      });
    },
    handleRemoveLineTab(item: any, accountId: string) {
      this.removeOpenedLineItem({ accountId, lineItem: item });
      this.$router.push(`/accounting/accounts-payable/${accountId}`);
    },
  },
  async created() {
    this.handleCreatedOrUpdated("created");
    this.accountItems.orders.to =
      this.accountingOrdersLookup === "" ? "" : "/accounting/orders";

    await this.getPrinterControl({ client: this.getClient });
  },
  updated() {
    this.handleCreatedOrUpdated();
  },
  watch: {
    "$route.params.payableId": {
      handler: function (val: any) {
        this.activeLineItemTab = this.getActiveLineItemsTab(val);
      },
      immediate: true,
    },
    "$route.params.itemId": {
      handler: function (val: any) {
        this.activeLineItemTab = this.getActiveLineItemsTab(
          this.$route.params.payableId,
        );
      },
      immediate: true,
    },
    "$route.fullPath": {
      handler: function (val: any) {
        if (val.includes("accounting")) {
          this.handleCreatedOrUpdated();
        }
      },
      immediate: true,
    },
    ordersMonthlyData(newVal: any) {
      this.accountItems.orders.amountArray = newVal;
    },
    accountingOrdersLookup(newVal: any) {
      this.accountItems.orders.to =
        this.isProduction || newVal === "" ? "" : "/accounting/orders";
    },
  },
});

import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, withModifiers as _withModifiers, createBlock as _createBlock, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "grid p-0 m-0" }
const _hoisted_2 = { class: "sales-tab" }
const _hoisted_3 = { class: "col-12 m-0 p-0" }
const _hoisted_4 = { class: "sales-tab p-0" }
const _hoisted_5 = { class: "line-height-4 sales-tab-item" }
const _hoisted_6 = { class: "align-self-center" }
const _hoisted_7 = {
  key: 0,
  class: "flex justify-content-end col-2"
}
const _hoisted_8 = { class: "col-12 m-0 p-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_WorkOrderTable = _resolveComponent("WorkOrderTable")!
  const _component_TabPanel = _resolveComponent("TabPanel")!
  const _component_Button = _resolveComponent("Button")!
  const _component_TabView = _resolveComponent("TabView")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_MessageBox = _resolveComponent("MessageBox")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["col-10 p-0 m-0", { 'col-12': _ctx.getActiveTab === 0 }])
    }, [
      _createVNode(_component_TabView, {
        onTabChange: _ctx.handleTabChange,
        class: "customers-list-tabview",
        "active-index": _ctx.getActiveTab
      }, {
        default: _withCtx(() => [
          _createVNode(_component_TabPanel, null, {
            header: _withCtx(() => [
              _createElementVNode("div", _hoisted_2, [
                _createVNode(_component_router_link, {
                  to: "/production/work-order/",
                  class: "line-height-4 sales-tab-item",
                  onClick: _ctx.handleTabChange
                }, {
                  default: _withCtx(() => _cache[0] || (_cache[0] = [
                    _createElementVNode("span", { class: "align-self-center" }, " Work Orders ", -1)
                  ])),
                  _: 1
                }, 8, ["onClick"])
              ])
            ]),
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_3, [
                _createVNode(_component_WorkOrderTable, {
                  onOpenWorkOrder: _ctx.onOpenWorkOrder,
                  workCenterList: _ctx.getWorkCenterItems
                }, null, 8, ["onOpenWorkOrder", "workCenterList"])
              ])
            ]),
            _: 1
          }),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getOpenedWorkOrders, (tab) => {
            return (_openBlock(), _createBlock(_component_TabPanel, {
              key: tab.oldRecord.wo_id,
              class: "line-height-4 sales-tab-item"
            }, {
              header: _withCtx(() => [
                _createElementVNode("div", _hoisted_4, [
                  _createElementVNode("span", _hoisted_5, [
                    _createElementVNode("span", _hoisted_6, " Work Order #" + _toDisplayString(tab.oldRecord.wo_id) + " " + _toDisplayString(_ctx.orderHasChanges(tab)), 1)
                  ]),
                  _createVNode(_component_Button, {
                    icon: "pi pi-times",
                    class: "p-button-text p-0 m-0 pb-1",
                    onClick: _withModifiers(($event: any) => (_ctx.handleRemoveWorkOrderTab(tab)), ["stop"])
                  }, null, 8, ["onClick"])
                ])
              ]),
              _: 2
            }, 1024))
          }), 128))
        ]),
        _: 1
      }, 8, ["onTabChange", "active-index"])
    ], 2),
    (_ctx.getActiveTab > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
          (_ctx.hasSaveAccess)
            ? (_openBlock(), _createBlock(_component_Button, {
                key: 0,
                label: "Save",
                icon: "pi pi-save",
                iconPos: "left",
                style: {"height":"40px"},
                class: "text-sm font-bold align-self-center",
                loading: _ctx.loadingSaveWorkOrder,
                onClick: _ctx.saveWorkOrder
              }, null, 8, ["loading", "onClick"]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_8, [
      _createVNode(_component_router_view, { workCenterList: _ctx.getWorkCenterItems }, null, 8, ["workCenterList"])
    ]),
    _createVNode(_component_MessageBox, {
      showDialog: _ctx.showConfirmDeleteTab && _ctx.hasSaveAccess,
      icon: "WARNING",
      header: "Unsaved Changes",
      message: "Are you sure you want to close the tab without saving the changes?",
      primaryButton: "Yes",
      cancelButton: "Cancel",
      onPrimaryButtonClick: _ctx.handleCloseSaveFieldServiceTab,
      onCancelButtonClick: _ctx.handleCancelSaveFieldServiceTab
    }, null, 8, ["showDialog", "onPrimaryButtonClick", "onCancelButtonClick"])
  ]))
}
import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "flex p-0 m-0 justify-content-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProgressSpinner = _resolveComponent("ProgressSpinner")!
  const _component_Column = _resolveComponent("Column")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createBlock(_component_Dialog, {
    visible: _ctx.visible,
    "onUpdate:visible": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.visible) = $event)),
    header: _ctx.getTitle,
    modal: true,
    draggable: false,
    closable: false,
    class: "p-fluid text-center w-11 lg:w-9 xl:w-6 lkp-single-lvl-dialog"
  }, {
    footer: _withCtx(() => [
      _createVNode(_component_Button, { onClick: _ctx.handleClose }, {
        default: _withCtx(() => _cache[2] || (_cache[2] = [
          _createTextVNode("Close")
        ])),
        _: 1
      }, 8, ["onClick"])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_ctx.isLoading)
          ? (_openBlock(), _createBlock(_component_ProgressSpinner, {
              key: 0,
              class: "col-12"
            }))
          : (_openBlock(), _createBlock(_component_DataTable, {
              key: 1,
              rowGroupMode: "rowspan",
              groupRowsBy: "invloc",
              sortMode: "single",
              sortField: "invloc",
              value: [],
              class: "p-datatable-sm w-12 text-sm font-semibold bordered-datatable",
              stripedRows: "",
              responsiveLayout: "stack",
              breakpoint: "767px"
            }, {
              empty: _withCtx(() => _cache[1] || (_cache[1] = [
                _createElementVNode("div", { class: "flex justify-content-center" }, [
                  _createElementVNode("span", { class: "text-center" }, "No Inventory Found")
                ], -1)
              ])),
              default: _withCtx(() => [
                _createVNode(_component_Column, {
                  field: "part_number",
                  header: "Part Number",
                  class: "lkp-single-lvl-column"
                }),
                _createVNode(_component_Column, {
                  field: "invloc",
                  header: "Invloc",
                  class: "lkp-single-lvl-column"
                }),
                _createVNode(_component_Column, {
                  field: "group",
                  header: "Group",
                  class: "lkp-single-lvl-column"
                }),
                _createVNode(_component_Column, {
                  field: "on_hand",
                  header: "On Hand",
                  class: "lkp-single-lvl-column"
                }),
                _createVNode(_component_Column, {
                  field: "commited",
                  header: "Commited",
                  class: "lkp-single-lvl-column"
                }),
                _createVNode(_component_Column, {
                  field: "allocated",
                  header: "Allocated",
                  class: "lkp-single-lvl-column"
                }),
                _createVNode(_component_Column, {
                  field: "shortages",
                  header: "Shortages",
                  class: "lkp-single-lvl-column"
                }),
                _createVNode(_component_Column, {
                  field: "available",
                  header: "Available",
                  class: "lkp-single-lvl-column"
                }),
                _createVNode(_component_Column, {
                  field: "on_order",
                  header: "On Order",
                  class: "lkp-single-lvl-column"
                })
              ]),
              _: 1
            }))
      ])
    ]),
    _: 1
  }, 8, ["visible", "header"]))
}
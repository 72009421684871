import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "formgrid grid m-0" }
const _hoisted_2 = { class: "col-12 lg:col-6" }
const _hoisted_3 = { class: "p-inputgroup mt-2" }
const _hoisted_4 = { class: "col-6" }
const _hoisted_5 = { class: "col-12 lg:col-6" }
const _hoisted_6 = { class: "p-inputgroup mt-2" }
const _hoisted_7 = { class: "col-12 lg:col-6" }
const _hoisted_8 = { class: "p-inputgroup mt-2" }
const _hoisted_9 = { class: "col-12 lg:col-6" }
const _hoisted_10 = { class: "p-inputgroup mt-2" }
const _hoisted_11 = { class: "col-12 lg:col-6" }
const _hoisted_12 = { class: "p-inputgroup mt-2" }
const _hoisted_13 = { class: "col-12 lg:col-6" }
const _hoisted_14 = { class: "p-inputgroup mt-2" }
const _hoisted_15 = { class: "col-12 lg:col-6" }
const _hoisted_16 = { class: "p-inputgroup mt-2" }
const _hoisted_17 = {
  key: 0,
  class: "p-error"
}
const _hoisted_18 = { class: "col-12 lg:col-6" }
const _hoisted_19 = { class: "p-inputgroup mt-2" }
const _hoisted_20 = { class: "col-12 lg:col-6" }
const _hoisted_21 = { class: "p-inputgroup mt-2" }
const _hoisted_22 = { class: "col-12 lg:col-6 mt-2" }
const _hoisted_23 = {
  key: 0,
  class: "grid col-12 lg:col-6 ml-2 mt-2 align-items-center"
}
const _hoisted_24 = { for: "add-address" }
const _hoisted_25 = { class: "col-12" }
const _hoisted_26 = { class: "grid" }
const _hoisted_27 = { class: "col-12 lg:col-6" }
const _hoisted_28 = { class: "col-12 lg:col-6" }
const _hoisted_29 = { class: "p-inputgroup" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputText = _resolveComponent("InputText")!
  const _component_AddressItems = _resolveComponent("AddressItems")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_Checkbox = _resolveComponent("Checkbox")!
  const _component_InputSwitch = _resolveComponent("InputSwitch")!
  const _component_TaxCodes = _resolveComponent("TaxCodes")!
  const _component_MultiSelect = _resolveComponent("MultiSelect")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createBlock(_component_Dialog, {
    header: _ctx.header,
    visible: _ctx.show,
    "onUpdate:visible": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.show) = $event)),
    style: { width: '800px' },
    modal: true,
    draggable: true,
    class: "p-fluid",
    onHide: _ctx.closeAddressDialog
  }, {
    footer: _withCtx(() => [
      _createVNode(_component_Button, {
        label: "Cancel",
        icon: "pi pi-times",
        onClick: _ctx.closeAddressDialog,
        class: "p-button-text"
      }, null, 8, ["onClick"]),
      _createVNode(_component_Button, {
        label: "Save",
        icon: "pi pi-check",
        loading: _ctx.isSubmitting,
        onClick: _ctx.saveAddressInfo
      }, null, 8, ["loading", "onClick"])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _cache[14] || (_cache[14] = _createElementVNode("span", { class: "p-inputgroup-addon" }, "Name", -1)),
            _createVNode(_component_InputText, {
              type: "text",
              modelValue: _ctx.ship.ship_name,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.ship.ship_name) = $event)),
              onInput: _ctx.onInputChange
            }, null, 8, ["modelValue", "onInput"])
          ])
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_AddressItems, {
            address: _ctx.ship.ship_to_items,
            addressName: "ship_to",
            onUpdateAddress: _ctx.updateShipAddress,
            inline: true
          }, null, 8, ["address", "onUpdateAddress"])
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _cache[15] || (_cache[15] = _createElementVNode("span", { class: "p-inputgroup-addon" }, "City", -1)),
            _createVNode(_component_InputText, {
              type: "text",
              modelValue: _ctx.ship.ship_city,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.ship.ship_city) = $event)),
              onInput: _ctx.onInputChange
            }, null, 8, ["modelValue", "onInput"])
          ])
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("div", _hoisted_8, [
            _cache[16] || (_cache[16] = _createElementVNode("span", { class: "p-inputgroup-addon" }, "State", -1)),
            _createVNode(_component_InputText, {
              type: "text",
              modelValue: _ctx.ship.ship_state,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.ship.ship_state) = $event)),
              onInput: _ctx.onInputChange
            }, null, 8, ["modelValue", "onInput"])
          ])
        ]),
        _createElementVNode("div", _hoisted_9, [
          _createElementVNode("div", _hoisted_10, [
            _cache[17] || (_cache[17] = _createElementVNode("span", { class: "p-inputgroup-addon" }, "Zip", -1)),
            _createVNode(_component_InputText, {
              type: "text",
              modelValue: _ctx.ship.ship_zip,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.ship.ship_zip) = $event)),
              onInput: _ctx.onInputChange
            }, null, 8, ["modelValue", "onInput"])
          ])
        ]),
        _createElementVNode("div", _hoisted_11, [
          _createElementVNode("div", _hoisted_12, [
            _cache[18] || (_cache[18] = _createElementVNode("span", { class: "p-inputgroup-addon" }, "Country", -1)),
            _createVNode(_component_Dropdown, {
              type: "text",
              modelValue: _ctx.ship.ship_country,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.ship.ship_country) = $event)),
              options: _ctx.getCountries(),
              onChange: _cache[5] || (_cache[5] = ($event: any) => (_ctx.getCountryCode(_ctx.ship.ship_country)))
            }, null, 8, ["modelValue", "options"])
          ])
        ]),
        _createElementVNode("div", _hoisted_13, [
          _createElementVNode("div", _hoisted_14, [
            _cache[19] || (_cache[19] = _createElementVNode("span", { class: "p-inputgroup-addon" }, "Attention", -1)),
            _createVNode(_component_InputText, {
              type: "text",
              modelValue: _ctx.ship.ship_attn,
              "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.ship.ship_attn) = $event)),
              onInput: _ctx.onInputChange
            }, null, 8, ["modelValue", "onInput"])
          ])
        ]),
        _createElementVNode("div", _hoisted_15, [
          _createElementVNode("div", _hoisted_16, [
            _cache[20] || (_cache[20] = _createElementVNode("span", { class: "p-inputgroup-addon" }, "Email", -1)),
            _createVNode(_component_InputText, {
              type: "text",
              modelValue: _ctx.ship.ship_email,
              "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.ship.ship_email) = $event)),
              class: _normalizeClass({
              'p-invalid':
                _ctx.isSubmitted &&
                _ctx.ship.ship_email &&
                _ctx.emailValidation(_ctx.ship.ship_email) === false,
            })
            }, null, 8, ["modelValue", "class"])
          ]),
          (
            _ctx.isSubmitted &&
            _ctx.ship.ship_email &&
            _ctx.emailValidation(_ctx.ship.ship_email) === false
          )
            ? (_openBlock(), _createElementBlock("small", _hoisted_17, "Value is not a valid email address"))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_18, [
          _createElementVNode("div", _hoisted_19, [
            _cache[21] || (_cache[21] = _createElementVNode("span", { class: "p-inputgroup-addon" }, "Phone", -1)),
            _createVNode(_component_InputText, {
              type: "text",
              modelValue: _ctx.ship.ship_phone,
              "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.ship.ship_phone) = $event)),
              onInput: _ctx.onInputChange
            }, null, 8, ["modelValue", "onInput"])
          ])
        ]),
        _createElementVNode("div", _hoisted_20, [
          _createElementVNode("div", _hoisted_21, [
            _cache[22] || (_cache[22] = _createElementVNode("span", { class: "p-inputgroup-addon" }, "Fax", -1)),
            _createVNode(_component_InputText, {
              type: "text",
              modelValue: _ctx.ship.ship_fax,
              "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.ship.ship_fax) = $event)),
              onInput: _ctx.onInputChange
            }, null, 8, ["modelValue", "onInput"])
          ])
        ]),
        _createElementVNode("div", _hoisted_22, [
          _createVNode(_component_Checkbox, {
            id: "binary",
            modelValue: _ctx.ship.ship_comm,
            "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.ship.ship_comm) = $event)),
            trueValue: "Y",
            falseValue: null,
            binary: true
          }, null, 8, ["modelValue"]),
          _cache[23] || (_cache[23] = _createElementVNode("label", { for: "binary" }, " Commercial ", -1))
        ]),
        (_ctx.addAddressToCustomerSwitch)
          ? (_openBlock(), _createElementBlock("div", _hoisted_23, [
              _createElementVNode("label", _hoisted_24, _toDisplayString(_ctx.updateAddressLabel), 1),
              _createVNode(_component_InputSwitch, {
                id: "add-address",
                modelValue: _ctx.addAddressToCustomer,
                "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.addAddressToCustomer) = $event)),
                class: "ml-2"
              }, null, 8, ["modelValue"])
            ]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_25, [
        _createElementVNode("div", _hoisted_26, [
          _createElementVNode("div", _hoisted_27, [
            _createVNode(_component_TaxCodes, {
              taxCodesItems: _ctx.shipTaxCodes,
              onUpdateTaxCodes: _ctx.updateTaxCodes
            }, null, 8, ["taxCodesItems", "onUpdateTaxCodes"])
          ]),
          _createElementVNode("div", _hoisted_28, [
            _cache[25] || (_cache[25] = _createElementVNode("h4", null, "Third Party Billing Data", -1)),
            _createElementVNode("div", _hoisted_29, [
              _cache[24] || (_cache[24] = _createElementVNode("span", { class: "p-inputgroup-addon" }, "Carrier", -1)),
              _createVNode(_component_MultiSelect, {
                modelValue: _ctx.ship.ship_acct_no_items,
                "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.ship.ship_acct_no_items) = $event)),
                options: _ctx.getCarrierOptions(),
                optionLabel: "ship_acct_no",
                display: "chip"
              }, {
                option: _withCtx((slotProps) => [
                  _createTextVNode(_toDisplayString(slotProps.option.ship_carrier +
                  ": " +
                  slotProps.option.ship_acct_no), 1)
                ]),
                _: 1
              }, 8, ["modelValue", "options"])
            ])
          ])
        ])
      ])
    ]),
    _: 1
  }, 8, ["header", "visible", "onHide"]))
}
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "p-fluid" }
const _hoisted_2 = { class: "p-inputgroup mt-2" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { class: "p-error p-0 m-0 pl-2" }
const _hoisted_5 = { class: "flex justify-content-end p-0 m-0" }
const _hoisted_6 = { class: "p-0 m-0" }
const _hoisted_7 = { class: "p-0 m-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createBlock(_component_Dialog, {
    header: _ctx.getModalTitle,
    visible: _ctx.showDialog,
    modal: true,
    closable: false,
    class: "w-6 lg:w-4 xl:w-3",
    onHide: _ctx.closeDialog
  }, {
    footer: _withCtx(() => [
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          _createVNode(_component_Button, {
            label: "Cancel",
            onClick: _ctx.closeDialog,
            class: "p-button-text",
            icon: "pi pi-times"
          }, null, 8, ["onClick"])
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createVNode(_component_Button, {
            label: "Send",
            disabled: !_ctx.email || _ctx.v$.email.email.$invalid,
            icon: "pi pi-check",
            onClick: _ctx.emailReceipt,
            loading: _ctx.isLoading
          }, null, 8, ["disabled", "onClick", "loading"])
        ])
      ])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _cache[1] || (_cache[1] = _createElementVNode("small", {
            class: "p-inputgroup-addon font-semibold general-label-color",
            style: { width: '104px' }
          }, "Email", -1)),
          _createVNode(_component_InputText, {
            type: "text",
            modelValue: _ctx.email,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.email) = $event)),
            class: _normalizeClass({ 'text-sm p-invalid': _ctx.v$.email.email.$invalid })
          }, null, 8, ["modelValue", "class"])
        ]),
        (_ctx.v$.email.email.$invalid)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createElementVNode("small", _hoisted_4, _toDisplayString(_ctx.v$.email.email.$message), 1)
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }, 8, ["header", "visible", "onHide"]))
}
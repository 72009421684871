import { resolveComponent as _resolveComponent, vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, createBlock as _createBlock, resolveDirective as _resolveDirective, Fragment as _Fragment, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "grid" }
const _hoisted_2 = { class: "col-12" }
const _hoisted_3 = { class: "col-12" }
const _hoisted_4 = { class: "grid" }
const _hoisted_5 = { class: "col-12 lg:col-4" }
const _hoisted_6 = { class: "p-inputgroup mt-2" }
const _hoisted_7 = { key: 0 }
const _hoisted_8 = { class: "p-error" }
const _hoisted_9 = { class: "col-12 lg:col-4" }
const _hoisted_10 = { class: "p-inputgroup mt-2" }
const _hoisted_11 = { class: "col-12 lg:col-4" }
const _hoisted_12 = { class: "p-inputgroup mt-2" }
const _hoisted_13 = { class: "grid" }
const _hoisted_14 = { class: "col-12 lg:col-4" }
const _hoisted_15 = { class: "p-inputgroup mt-2" }
const _hoisted_16 = { class: "col-12 lg:col-4" }
const _hoisted_17 = { class: "p-inputgroup mt-2" }
const _hoisted_18 = { class: "col-12 lg:col-4" }
const _hoisted_19 = { class: "p-inputgroup mt-2" }
const _hoisted_20 = { class: "col-12 lg:col-4" }
const _hoisted_21 = { class: "p-inputgroup mt-2" }
const _hoisted_22 = { key: 0 }
const _hoisted_23 = { class: "grid" }
const _hoisted_24 = { class: "col-12 lg:col-4" }
const _hoisted_25 = { class: "p-inputgroup mt-2" }
const _hoisted_26 = { class: "col-12 lg:col-4" }
const _hoisted_27 = { class: "p-inputgroup mt-2" }
const _hoisted_28 = { class: "col-12 lg:col-4" }
const _hoisted_29 = { class: "p-inputgroup mt-2" }
const _hoisted_30 = { class: "col-12 lg:col-4" }
const _hoisted_31 = { class: "p-inputgroup mt-2" }
const _hoisted_32 = { class: "col-12 lg:col-4" }
const _hoisted_33 = { class: "p-inputgroup mt-2" }
const _hoisted_34 = { class: "grid" }
const _hoisted_35 = { class: "col-12 lg:col-4" }
const _hoisted_36 = { class: "p-inputgroup mt-2" }
const _hoisted_37 = { class: "col-12 lg:col-4" }
const _hoisted_38 = { class: "p-inputgroup mt-2" }
const _hoisted_39 = { class: "col-12 lg:col-4" }
const _hoisted_40 = { class: "p-inputgroup mt-2" }
const _hoisted_41 = { class: "col-12 lg:col-4" }
const _hoisted_42 = { class: "p-inputgroup mt-2" }
const _hoisted_43 = { class: "col-12 lg:col-4" }
const _hoisted_44 = { class: "p-inputgroup mt-2" }
const _hoisted_45 = { class: "col-12" }
const _hoisted_46 = { class: "flex justify-content-between align-items-center" }
const _hoisted_47 = { class: "flex justify-content-between align-items-center" }
const _hoisted_48 = { key: 1 }
const _hoisted_49 = ["onClick"]
const _hoisted_50 = ["onClick"]
const _hoisted_51 = ["onClick"]
const _hoisted_52 = { class: "flex justify-content-between align-items-center" }
const _hoisted_53 = {
  key: 1,
  class: "description"
}
const _hoisted_54 = ["onClick"]
const _hoisted_55 = ["onClick"]
const _hoisted_56 = ["onClick"]
const _hoisted_57 = { class: "flex col-12 justify-content-between" }
const _hoisted_58 = { style: {"text-align":"left !important"} }
const _hoisted_59 = { style: {"text-align":"right !important"} }
const _hoisted_60 = { class: "grid" }
const _hoisted_61 = { class: "col-12 mt-2" }
const _hoisted_62 = { class: "col-12" }
const _hoisted_63 = { class: "p-inputgroup mt-2" }
const _hoisted_64 = { class: "col-12" }
const _hoisted_65 = { class: "p-inputgroup mt-2" }
const _hoisted_66 = {
  key: 0,
  class: "p-error"
}
const _hoisted_67 = {
  key: 1,
  class: "p-error"
}
const _hoisted_68 = {
  key: 0,
  class: "p-error"
}
const _hoisted_69 = {
  key: 1,
  class: "p-error"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_Search = _resolveComponent("Search")!
  const _component_ContactAutocomplete = _resolveComponent("ContactAutocomplete")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_AccordionTab = _resolveComponent("AccordionTab")!
  const _component_Calendar = _resolveComponent("Calendar")!
  const _component_InputNumber = _resolveComponent("InputNumber")!
  const _component_Accordion = _resolveComponent("Accordion")!
  const _component_Column = _resolveComponent("Column")!
  const _component_Row = _resolveComponent("Row")!
  const _component_ColumnGroup = _resolveComponent("ColumnGroup")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _component_Dialog = _resolveComponent("Dialog")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_Dialog, {
      header: this.header,
      visible: this.hidden,
      "onUpdate:visible": _cache[28] || (_cache[28] = ($event: any) => ((this.hidden) = $event)),
      onHide: _cache[29] || (_cache[29] = 
        () => {
          _ctx.isOppLoadedInDialog = false;
          _ctx.$emit('hide', this.hidden);
          _ctx.editPartNumberIndex = -1;
        }
      ),
      style: { width: '1480px' },
      modal: true,
      draggable: false
    }, {
      footer: _withCtx(() => [
        _createElementVNode("div", _hoisted_57, [
          _createElementVNode("div", _hoisted_58, [
            _withDirectives(_createVNode(_component_Button, {
              label: "Create Order",
              icon: "pi pi-plus",
              onClick: _ctx.createOrder,
              loading: this.isLoadingOrder
            }, null, 8, ["onClick", "loading"]), [
              [_vShow, !this.newQuote]
            ])
          ]),
          _createElementVNode("div", _hoisted_59, [
            _createVNode(_component_Button, {
              label: "Cancel",
              icon: "pi pi-times",
              onClick: _cache[27] || (_cache[27] = 
                () => {
                  _ctx.$emit('hide', false);
                }
              ),
              class: "p-button-text"
            }),
            _createVNode(_component_Button, {
              label: "Save",
              icon: "pi pi-check",
              onClick: this.submit,
              loading: this.submitLoading,
              autofocus: ""
            }, null, 8, ["onClick", "loading"])
          ])
        ])
      ]),
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _withDirectives(_createVNode(_component_Button, {
              icon: "pi pi-plus",
              label: "Load Opportunity",
              iconPos: "left",
              class: "m-2",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openAddOpportunityDialog()))
            }, null, 512), [
              [_vShow, this.newQuote]
            ]),
            _withDirectives(_createVNode(_component_Button, {
              icon: "pi pi-plus",
              label: "Load Existing Quote",
              iconPos: "left",
              class: "m-2",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.openAddExistingQuoteDialog()))
            }, null, 512), [
              [_vShow, this.newQuote]
            ])
          ]),
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_Accordion, {
              activeIndex: [0],
              multiple: true
            }, {
              default: _withCtx(() => [
                _createVNode(_component_AccordionTab, { header: "Customer Details" }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_4, [
                      _createElementVNode("div", _hoisted_5, [
                        _createElementVNode("div", _hoisted_6, [
                          _cache[49] || (_cache[49] = _createElementVNode("span", {
                            class: "p-inputgroup-addon",
                            style: { width: '103px' }
                          }, "Customer", -1)),
                          _createVNode(_component_Search, {
                            modelValue: this.newSoQuoteCust,
                            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((this.newSoQuoteCust) = $event)),
                            placeHolder: "Search for a Customer",
                            onFilterCust: _cache[3] || (_cache[3] = ($event: any) => (_ctx.setCust($event.value))),
                            file: "NEWSALES",
                            label: "name",
                            id: "newSoQuoteCust",
                            ref: "newSoQuoteCust",
                            class: _normalizeClass({
                        'p-invalid':
                          _ctx.isFormSubmitted &&
                          _ctx.v$.newSoQuoteCust.required.$invalid,
                      })
                          }, null, 8, ["modelValue", "class"])
                        ]),
                        (
                      _ctx.isFormSubmitted && _ctx.v$.newSoQuoteCust.required.$invalid
                    )
                          ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                              _createElementVNode("small", _hoisted_8, _toDisplayString(_ctx.v$.newSoQuoteCust.required.$message), 1)
                            ]))
                          : _createCommentVNode("", true)
                      ]),
                      _createElementVNode("div", _hoisted_9, [
                        _createElementVNode("div", _hoisted_10, [
                          _cache[50] || (_cache[50] = _createElementVNode("span", {
                            class: "p-inputgroup-addon",
                            style: { width: '103px' }
                          }, "Contact", -1)),
                          _createVNode(_component_ContactAutocomplete, {
                            "selected-id": _ctx.soQuote.contact_id,
                            onItemSelect: _cache[4] || (_cache[4] = ($event: any) => (_ctx.handleContactSelected($event)))
                          }, null, 8, ["selected-id"])
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_11, [
                        _createElementVNode("div", _hoisted_12, [
                          _cache[51] || (_cache[51] = _createElementVNode("span", {
                            class: "p-inputgroup-addon",
                            style: { width: '103px' }
                          }, "Prospect Id", -1)),
                          _createVNode(_component_Search, {
                            modelValue: this.prospectName,
                            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((this.prospectName) = $event)),
                            onFilter: _cache[6] || (_cache[6] = ($event: any) => (_ctx.setProspect($event.value))),
                            placeHolder: "Search for a Prospect",
                            label: "name",
                            file: "PROSPECT"
                          }, null, 8, ["modelValue"])
                        ])
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_13, [
                      _createElementVNode("div", _hoisted_14, [
                        _createElementVNode("div", _hoisted_15, [
                          _cache[52] || (_cache[52] = _createElementVNode("span", {
                            class: "p-inputgroup-addon",
                            style: { width: '103px' }
                          }, "Email", -1)),
                          _createVNode(_component_InputText, {
                            modelValue: this.soQuote.email,
                            "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((this.soQuote.email) = $event))
                          }, null, 8, ["modelValue"])
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_16, [
                        _createElementVNode("div", _hoisted_17, [
                          _cache[53] || (_cache[53] = _createElementVNode("span", {
                            class: "p-inputgroup-addon",
                            style: { width: '103px' }
                          }, "Phone", -1)),
                          _createVNode(_component_InputText, {
                            modelValue: this.soQuote.phone,
                            "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((this.soQuote.phone) = $event))
                          }, null, 8, ["modelValue"])
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_18, [
                        _createElementVNode("div", _hoisted_19, [
                          _cache[54] || (_cache[54] = _createElementVNode("span", {
                            class: "p-inputgroup-addon",
                            style: { width: '103px' }
                          }, "Fax", -1)),
                          _createVNode(_component_InputText, {
                            modelValue: this.soQuote.fax,
                            "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((this.soQuote.fax) = $event))
                          }, null, 8, ["modelValue"])
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_20, [
                        _createElementVNode("div", _hoisted_21, [
                          _cache[55] || (_cache[55] = _createElementVNode("span", {
                            class: "p-inputgroup-addon",
                            style: { width: '103px' }
                          }, "Company", -1)),
                          _createVNode(_component_Dropdown, {
                            modelValue: this.soQuote.co_code,
                            "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((this.soQuote.co_code) = $event)),
                            options: _ctx.customerInquiry.coCodes,
                            optionLabel: "name",
                            optionValue: "code",
                            class: _normalizeClass({
                        'p-invalid':
                          _ctx.isFormSubmitted && _ctx.v$.co_code.required.$invalid,
                      })
                          }, null, 8, ["modelValue", "options", "class"])
                        ]),
                        (
                      _ctx.isFormSubmitted && _ctx.v$.soQuote.co_code.required.$invalid
                    )
                          ? (_openBlock(), _createElementBlock("div", _hoisted_22, _cache[56] || (_cache[56] = [
                              _createElementVNode("small", { class: "p-error" }, "The value is required", -1)
                            ])))
                          : _createCommentVNode("", true)
                      ])
                    ])
                  ]),
                  _: 1
                }),
                _createVNode(_component_AccordionTab, { header: "Order Details" }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_23, [
                      _createElementVNode("div", _hoisted_24, [
                        _createElementVNode("div", _hoisted_25, [
                          _cache[57] || (_cache[57] = _createElementVNode("span", {
                            class: "p-inputgroup-addon",
                            style: { width: '103px' }
                          }, "Quote Date", -1)),
                          _createVNode(_component_Calendar, {
                            modelValue: this.soQuote.date,
                            "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((this.soQuote.date) = $event)),
                            showIcon: true,
                            dateFormat: "mm-dd-yy",
                            manualInput: false,
                            onDateSelect: _cache[12] || (_cache[12] = ($event: any) => (_ctx.formatDate($event, 'QUOTED_DATE')))
                          }, null, 8, ["modelValue"])
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_26, [
                        _createElementVNode("div", _hoisted_27, [
                          _cache[58] || (_cache[58] = _createElementVNode("span", {
                            class: "p-inputgroup-addon",
                            style: { width: '103px' }
                          }, "Status Date", -1)),
                          _createVNode(_component_Calendar, {
                            modelValue: this.soQuote.status_date,
                            "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((this.soQuote.status_date) = $event)),
                            showIcon: true,
                            dateFormat: "mm-dd-yy",
                            manualInput: false,
                            onDateSelect: _cache[14] || (_cache[14] = ($event: any) => (_ctx.formatDate($event, 'STATUS_DATE')))
                          }, null, 8, ["modelValue"])
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_28, [
                        _createElementVNode("div", _hoisted_29, [
                          _cache[59] || (_cache[59] = _createElementVNode("span", {
                            class: "p-inputgroup-addon",
                            style: { width: '103px' }
                          }, "Valid Thru", -1)),
                          _createVNode(_component_Calendar, {
                            modelValue: this.soQuote.valid_thru,
                            "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((this.soQuote.valid_thru) = $event)),
                            showIcon: true,
                            dateFormat: "mm-dd-yy",
                            manualInput: false,
                            onDateSelect: _cache[16] || (_cache[16] = ($event: any) => (_ctx.formatDate($event, 'VALID_THRU_DATE')))
                          }, null, 8, ["modelValue"])
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_30, [
                        _createElementVNode("div", _hoisted_31, [
                          _cache[60] || (_cache[60] = _createElementVNode("span", {
                            class: "p-inputgroup-addon",
                            style: { width: '103px' }
                          }, "Status", -1)),
                          _createVNode(_component_Dropdown, {
                            modelValue: this.soQuote.status,
                            "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => ((this.soQuote.status) = $event)),
                            options: _ctx.status,
                            optionLabel: "description",
                            optionValue: "value"
                          }, null, 8, ["modelValue", "options"])
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_32, [
                        _createElementVNode("div", _hoisted_33, [
                          _cache[61] || (_cache[61] = _createElementVNode("span", {
                            class: "p-inputgroup-addon",
                            style: { width: '103px' }
                          }, "Quoted By", -1)),
                          _createVNode(_component_Dropdown, {
                            modelValue: this.selectedQuoter,
                            "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => ((this.selectedQuoter) = $event)),
                            options: _ctx.getUsers,
                            optionLabel: "user_name",
                            optionValue: "user_id",
                            dataKey: "user_id",
                            filter: true,
                            showClear: this.showClear,
                            filterMatchMode: "startsWith"
                          }, null, 8, ["modelValue", "options", "showClear"])
                        ])
                      ])
                    ])
                  ]),
                  _: 1
                }),
                _createVNode(_component_AccordionTab, { header: "Pricing & Shipping Details" }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_34, [
                      _createElementVNode("div", _hoisted_35, [
                        _createElementVNode("div", _hoisted_36, [
                          _cache[62] || (_cache[62] = _createElementVNode("span", {
                            class: "p-inputgroup-addon",
                            style: { width: '113px' }
                          }, "Pay Terms", -1)),
                          _createVNode(_component_Dropdown, {
                            modelValue: this.soQuote.terms,
                            "onUpdate:modelValue": _cache[19] || (_cache[19] = ($event: any) => ((this.soQuote.terms) = $event)),
                            options: _ctx.customerInquiry.terms,
                            optionLabel: "desc",
                            optionValue: "terms_id"
                          }, null, 8, ["modelValue", "options"])
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_37, [
                        _createElementVNode("div", _hoisted_38, [
                          _cache[63] || (_cache[63] = _createElementVNode("span", {
                            class: "p-inputgroup-addon",
                            style: { width: '113px' }
                          }, "Price Code", -1)),
                          _createVNode(_component_Dropdown, {
                            modelValue: this.soQuote.code,
                            "onUpdate:modelValue": _cache[20] || (_cache[20] = ($event: any) => ((this.soQuote.code) = $event)),
                            options: _ctx.customerInquiry.pricingCodes,
                            optionLabel: "description",
                            optionValue: "code"
                          }, null, 8, ["modelValue", "options"])
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_39, [
                        _createElementVNode("div", _hoisted_40, [
                          _cache[64] || (_cache[64] = _createElementVNode("span", {
                            class: "p-inputgroup-addon",
                            style: { width: '113px' }
                          }, "Est Close(%)", -1)),
                          _createVNode(_component_InputNumber, {
                            modelValue: this.soQuote.est_close,
                            "onUpdate:modelValue": [
                              _cache[21] || (_cache[21] = ($event: any) => ((this.soQuote.est_close) = $event)),
                              _cache[22] || (_cache[22] = ($event: any) => (_ctx.updateEstClose($event)))
                            ]
                          }, null, 8, ["modelValue"])
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_41, [
                        _createElementVNode("div", _hoisted_42, [
                          _cache[65] || (_cache[65] = _createElementVNode("span", {
                            class: "p-inputgroup-addon",
                            style: { width: '113px' }
                          }, "Exch Rate", -1)),
                          _createVNode(_component_InputNumber, {
                            modelValue: this.soQuote.exchange_rate,
                            "onUpdate:modelValue": _cache[23] || (_cache[23] = ($event: any) => ((this.soQuote.exchange_rate) = $event))
                          }, null, 8, ["modelValue"])
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_43, [
                        _createElementVNode("div", _hoisted_44, [
                          _cache[66] || (_cache[66] = _createElementVNode("span", {
                            class: "p-inputgroup-addon",
                            style: { width: '113px' }
                          }, "Price Method", -1)),
                          _createVNode(_component_Dropdown, {
                            modelValue: this.soQuote.cost_method,
                            "onUpdate:modelValue": _cache[24] || (_cache[24] = ($event: any) => ((this.soQuote.cost_method) = $event)),
                            options: this.cost_method,
                            optionLabel: "description",
                            optionValue: "value"
                          }, null, 8, ["modelValue", "options"])
                        ])
                      ])
                    ])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _createElementVNode("div", _hoisted_45, [
            _createVNode(_component_DataTable, {
              value: _ctx.formatPartInfo,
              responsiveLayout: "scroll"
            }, {
              header: _withCtx(() => [
                _createElementVNode("div", _hoisted_46, [
                  _cache[67] || (_cache[67] = _createElementVNode("h4", { class: "m-0" }, "Line Items", -1)),
                  _createVNode(_component_Button, {
                    icon: "pi pi-plus",
                    label: "Add Part",
                    iconPos: "left",
                    onClick: _ctx.validatePartReq
                  }, null, 8, ["onClick"])
                ])
              ]),
              empty: _withCtx(() => _cache[68] || (_cache[68] = [
                _createTextVNode(" No rows to show. ")
              ])),
              default: _withCtx(() => [
                _createVNode(_component_Column, {
                  field: "part",
                  header: "Part Number"
                }, {
                  body: _withCtx((slotProps) => [
                    _createElementVNode("div", _hoisted_47, [
                      (slotProps.index === _ctx.editPartNumberIndex)
                        ? (_openBlock(), _createBlock(_component_Search, {
                            key: 0,
                            modelValue: _ctx.soQuote.li_items[_ctx.editPartNumberIndex].part,
                            "onUpdate:modelValue": _cache[25] || (_cache[25] = ($event: any) => ((_ctx.soQuote.li_items[_ctx.editPartNumberIndex].part) = $event)),
                            placeHolder: "Search for a Part",
                            onFilterPart: _cache[26] || (_cache[26] = ($event: any) => (_ctx.setPart($event.value))),
                            file: "PARTS",
                            label: "part_no"
                          }, null, 8, ["modelValue"]))
                        : (_openBlock(), _createElementBlock("p", _hoisted_48, _toDisplayString(slotProps.data[slotProps.field]), 1)),
                      (slotProps.index === _ctx.editPartNumberIndex)
                        ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                            _withDirectives(_createElementVNode("i", {
                              class: "pi pi-times cursor-pointer",
                              onClick: ($event: any) => (_ctx.resetEditPartNumberIndex(slotProps.index))
                            }, null, 8, _hoisted_49), [
                              [_directive_tooltip, 'Close']
                            ]),
                            _withDirectives(_createElementVNode("i", {
                              class: "pi pi-check cursor-pointer",
                              onClick: ($event: any) => (_ctx.savePartNumber(slotProps.index))
                            }, null, 8, _hoisted_50), [
                              [_directive_tooltip, 'Save']
                            ])
                          ], 64))
                        : _withDirectives((_openBlock(), _createElementBlock("i", {
                            key: 3,
                            class: "pi pi-pencil cursor-pointer",
                            onClick: ($event: any) => (_ctx.setEditPartNumberIndex(slotProps.index))
                          }, null, 8, _hoisted_51)), [
                            [_directive_tooltip, 'Edit']
                          ])
                    ]),
                    _createElementVNode("div", _hoisted_52, [
                      (slotProps.index === _ctx.editPartIndex)
                        ? (_openBlock(), _createBlock(_component_InputText, {
                            key: 0,
                            placeholder: "Enter Description",
                            modelValue: slotProps.data.wrap_desc,
                            "onUpdate:modelValue": ($event: any) => ((slotProps.data.wrap_desc) = $event)
                          }, null, 8, ["modelValue", "onUpdate:modelValue"]))
                        : (_openBlock(), _createElementBlock("span", _hoisted_53, _toDisplayString(slotProps.data.wrap_desc), 1)),
                      (slotProps.index === _ctx.editPartIndex)
                        ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                            _withDirectives(_createElementVNode("i", {
                              class: "pi pi-times cursor-pointer",
                              onClick: ($event: any) => (_ctx.resetEditpartIndex(slotProps.index))
                            }, null, 8, _hoisted_54), [
                              [_directive_tooltip, 'Close']
                            ]),
                            _withDirectives(_createElementVNode("i", {
                              class: "pi pi-check cursor-pointer",
                              onClick: ($event: any) => (_ctx.savePartDescription(slotProps.index))
                            }, null, 8, _hoisted_55), [
                              [_directive_tooltip, 'Save']
                            ])
                          ], 64))
                        : _withDirectives((_openBlock(), _createElementBlock("i", {
                            key: 3,
                            class: "pi pi-pencil cursor-pointer",
                            onClick: ($event: any) => (_ctx.setEditpartIndex(slotProps.index))
                          }, null, 8, _hoisted_56)), [
                            [_directive_tooltip, 'Edit']
                          ])
                    ])
                  ]),
                  _: 1
                }),
                _createVNode(_component_Column, {
                  field: "qty",
                  header: "Quantity",
                  headerClass: "right-align",
                  bodyStyle: "text-align:right"
                }, {
                  body: _withCtx((slotProps) => [
                    _createVNode(_component_InputNumber, {
                      inputStyle: "width:100px",
                      showButtons: "",
                      buttonLayout: "horizontal",
                      incrementButtonIcon: "pi pi-plus",
                      decrementButtonIcon: "pi pi-minus",
                      modelValue: slotProps.data[slotProps.field],
                      "onUpdate:modelValue": ($event: any) => ((slotProps.data[slotProps.field]) = $event),
                      min: 0,
                      maxFractionDigits: 4,
                      onInput: ($event: any) => (_ctx.onInput(slotProps, $event))
                    }, null, 8, ["modelValue", "onUpdate:modelValue", "onInput"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_Column, {
                  field: "price",
                  header: "Price",
                  headerClass: "right-align",
                  bodyStyle: "text-align:right"
                }, {
                  body: _withCtx((slotProps) => [
                    _createVNode(_component_InputNumber, {
                      modelValue: slotProps.data[slotProps.field],
                      "onUpdate:modelValue": ($event: any) => ((slotProps.data[slotProps.field]) = $event),
                      min: 0,
                      onInput: ($event: any) => (_ctx.onInput(slotProps, $event)),
                      mode: "decimal",
                      minFractionDigits: 2,
                      maxFractionDigits: 2
                    }, null, 8, ["modelValue", "onUpdate:modelValue", "onInput"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_Column, {
                  field: "disc_pct",
                  header: "Discount %",
                  headerClass: "right-align",
                  bodyStyle: "text-align:right"
                }, {
                  body: _withCtx((slotProps) => [
                    _createVNode(_component_InputNumber, {
                      modelValue: slotProps.data[slotProps.field],
                      "onUpdate:modelValue": ($event: any) => ((slotProps.data[slotProps.field]) = $event),
                      min: 0,
                      onInput: ($event: any) => (_ctx.onInput(slotProps, $event)),
                      mode: "decimal",
                      minFractionDigits: 4,
                      maxFractionDigits: 4
                    }, null, 8, ["modelValue", "onUpdate:modelValue", "onInput"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_Column, {
                  field: "extended_price",
                  header: "Extended Price",
                  headerClass: "right-align",
                  bodyStyle: "text-align:right"
                }, {
                  body: _withCtx((slotProps) => [
                    _createTextVNode(_toDisplayString(this.formatCurrency(slotProps.data[slotProps.field])), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_Column, { header: "Actions" }, {
                  body: _withCtx((slotProps) => [
                    _createVNode(_component_Button, {
                      onClick: _withModifiers(($event: any) => (_ctx.removePart(slotProps.index)), ["stop"]),
                      icon: "pi pi-trash",
                      style: {"background-color":"red !important"}
                    }, null, 8, ["onClick"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_ColumnGroup, { type: "footer" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_Row, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_Column, {
                          footer: 'Total: ' + this.formatCurrency(this.totalPrice),
                          colspan: 5,
                          footerStyle: "text-align:right"
                        }, null, 8, ["footer"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["value"])
          ])
        ])
      ]),
      _: 1
    }, 8, ["header", "visible"]),
    _createVNode(_component_Dialog, {
      visible: this.showPartModal,
      "onUpdate:visible": _cache[36] || (_cache[36] = ($event: any) => ((this.showPartModal) = $event)),
      header: "Add Part",
      modal: true,
      style: { width: '500px' }
    }, {
      footer: _withCtx(() => [
        _createVNode(_component_Button, {
          label: "Cancel",
          icon: "pi pi-times",
          onClick: _cache[35] || (_cache[35] = 
            () => {
              this.showPartModal = false;
            }
          ),
          class: "p-button-text"
        }),
        _createVNode(_component_Button, {
          label: "Save",
          icon: "pi pi-check",
          onClick: _ctx.addPart,
          autofocus: "",
          loading: this.partLoading
        }, null, 8, ["onClick", "loading"])
      ]),
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_60, [
          _createElementVNode("div", _hoisted_61, [
            _createVNode(_component_Search, {
              modelValue: this.part.number,
              "onUpdate:modelValue": _cache[30] || (_cache[30] = ($event: any) => ((this.part.number) = $event)),
              placeHolder: "Search for a Part",
              onFilterPart: _cache[31] || (_cache[31] = ($event: any) => (_ctx.setPart($event.value))),
              file: "PARTS",
              label: "part_no"
            }, null, 8, ["modelValue"])
          ]),
          _createElementVNode("div", _hoisted_62, [
            _createElementVNode("div", _hoisted_63, [
              _cache[69] || (_cache[69] = _createElementVNode("span", {
                class: "p-inputgroup-addon",
                style: { width: '103px' }
              }, "Quantity", -1)),
              _createVNode(_component_InputNumber, {
                modelValue: this.part.quantity,
                "onUpdate:modelValue": _cache[32] || (_cache[32] = ($event: any) => ((this.part.quantity) = $event)),
                min: 0,
                maxFractionDigits: 4
              }, null, 8, ["modelValue"])
            ])
          ]),
          _createElementVNode("div", _hoisted_64, [
            _createElementVNode("div", _hoisted_65, [
              _cache[70] || (_cache[70] = _createElementVNode("span", {
                class: "p-inputgroup-addon",
                style: { width: '103px' }
              }, "Date", -1)),
              _createVNode(_component_Calendar, {
                modelValue: this.part.date,
                "onUpdate:modelValue": _cache[33] || (_cache[33] = ($event: any) => ((this.part.date) = $event)),
                selectionMode: "single",
                dateFormat: "mm-dd-yy",
                onDateSelect: _cache[34] || (_cache[34] = ($event: any) => (_ctx.formatDate($event, 'PART')))
              }, null, 8, ["modelValue"])
            ])
          ])
        ])
      ]),
      _: 1
    }, 8, ["visible"]),
    _createVNode(_component_Dialog, {
      visible: this.showAddExistingQuoteDialog,
      "onUpdate:visible": _cache[40] || (_cache[40] = ($event: any) => ((this.showAddExistingQuoteDialog) = $event)),
      header: "Search for Quote",
      modal: true,
      style: { width: '500px' },
      onHide: _cache[41] || (_cache[41] = 
        () => {
          this.resetFields(`QUOTE`);
        }
      ),
      onShow: _cache[42] || (_cache[42] = 
        () => {
          this.focusSOQuoteSearch();
        }
      )
    }, {
      footer: _withCtx(() => [
        _createVNode(_component_Button, {
          label: "Cancel",
          icon: "pi pi-times",
          onClick: _cache[38] || (_cache[38] = 
            () => {
              this.showAddExistingQuoteDialog = false;
            }
          ),
          class: "p-button-text"
        }),
        _createVNode(_component_Button, {
          label: "Populate Quote",
          icon: "pi pi-check",
          onClick: _cache[39] || (_cache[39] = ($event: any) => (_ctx.populateDialog())),
          loading: this.isLoadingSOQuote
        }, null, 8, ["loading"])
      ]),
      default: _withCtx(() => [
        _createVNode(_component_InputText, {
          ref: "soquoteInputText",
          placeholder: "Search by Id",
          modelValue: this.existingSOQuote,
          "onUpdate:modelValue": _cache[37] || (_cache[37] = ($event: any) => ((this.existingSOQuote) = $event))
        }, null, 8, ["modelValue"]),
        _cache[71] || (_cache[71] = _createTextVNode("   ")),
        (_ctx.isOrderEmpty && !!this.statusMessage)
          ? (_openBlock(), _createElementBlock("small", _hoisted_66, _toDisplayString(this.statusMessage), 1))
          : _createCommentVNode("", true),
        (_ctx.isLoadQuoteFormSubmitted && _ctx.v$.existingSOQuote.required.$invalid)
          ? (_openBlock(), _createElementBlock("small", _hoisted_67, _toDisplayString(_ctx.v$.existingSOQuote.required.$message), 1))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["visible"]),
    _createVNode(_component_Dialog, {
      visible: this.showAddOpportunityDialog,
      "onUpdate:visible": _cache[46] || (_cache[46] = ($event: any) => ((this.showAddOpportunityDialog) = $event)),
      header: "Search for Opportunity",
      modal: true,
      style: { width: '500px' },
      onHide: _cache[47] || (_cache[47] = 
        () => {
          this.resetFields(`OPPS`);
        }
      ),
      onShow: _cache[48] || (_cache[48] = 
        () => {
          this.focusOpportunitySearch();
        }
      )
    }, {
      footer: _withCtx(() => [
        _createVNode(_component_Button, {
          label: "Cancel",
          icon: "pi pi-times",
          onClick: _cache[44] || (_cache[44] = 
            () => {
              this.showAddOpportunityDialog = false;
            }
          ),
          class: "p-button-text"
        }),
        _createVNode(_component_Button, {
          label: "Populate Opportunity",
          icon: "pi pi-check",
          onClick: _cache[45] || (_cache[45] = ($event: any) => (_ctx.populateDialogWithOpp())),
          loading: this.isLoadingOpportunity
        }, null, 8, ["loading"])
      ]),
      default: _withCtx(() => [
        _createVNode(_component_InputText, {
          ref: "opportunityInputText",
          placeholder: "Search by Id",
          modelValue: this.existingOpportunity,
          "onUpdate:modelValue": _cache[43] || (_cache[43] = ($event: any) => ((this.existingOpportunity) = $event))
        }, null, 8, ["modelValue"]),
        _cache[72] || (_cache[72] = _createTextVNode("   ")),
        (_ctx.isOpportunityEmpty && !!this.statusMessage)
          ? (_openBlock(), _createElementBlock("small", _hoisted_68, _toDisplayString(this.statusMessage), 1))
          : _createCommentVNode("", true),
        (
          _ctx.isLoadOppFormSubmitted && _ctx.v$.existingOpportunity.required.$invalid
        )
          ? (_openBlock(), _createElementBlock("small", _hoisted_69, _toDisplayString(_ctx.v$.existingOpportunity.required.$message), 1))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["visible"])
  ]))
}
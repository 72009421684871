import AbstractAccessGetter, { AccessRights } from "../AbstractAccessGetter";

export default class AccountingAccessGetter extends AbstractAccessGetter {
  constructor() {
    super();
  }

  getAccess(path: string, commands: string[]): boolean {
    return this.hasCommand(this.getWebCommand(path), commands);
  }

  getWebCommand(path: string): string {
    const pathList = path.split("/");
    let webName = "ACCOUNTING";
    if (pathList[2] == "accounts-payable") {
      webName = "AP.E";
    } else if (pathList[2] == "accounts-receivable") {
      webName = "AR.E";
    } else if (pathList[2] == "gl") {
      webName = "GLCHART.E";
    } else if (pathList[2] == "orders") webName = "SO.E2";
    return webName;
  }
}

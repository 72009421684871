import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "grid p-0 m-0" }
const _hoisted_2 = {
  key: 0,
  class: "part-number blue-text block col-12 p-0 m-0"
}
const _hoisted_3 = {
  key: 1,
  class: "part-number blue-text block emphasized"
}
const _hoisted_4 = { class: "part-manufactured-description block col-12 p-0 m-0" }
const _hoisted_5 = { class: "grid" }
const _hoisted_6 = {
  key: 0,
  class: "text-right pb-0 col-12"
}
const _hoisted_7 = { class: "grid" }
const _hoisted_8 = {
  key: 0,
  class: "text-right pb-0 col-12"
}
const _hoisted_9 = { key: 0 }
const _hoisted_10 = { class: "p-error" }
const _hoisted_11 = { class: "grid" }
const _hoisted_12 = {
  key: 0,
  class: "text-right pb-0 col-12"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Column = _resolveComponent("Column")!
  const _component_InputNumber = _resolveComponent("InputNumber")!
  const _component_VendorAutocomplete = _resolveComponent("VendorAutocomplete")!
  const _component_RoverInput = _resolveComponent("RoverInput")!
  const _component_Datatable = _resolveComponent("Datatable")!
  const _component_Card = _resolveComponent("Card")!

  return (_openBlock(), _createBlock(_component_Card, { class: "mt-3" }, {
    content: _withCtx(() => [
      _createVNode(_component_Datatable, {
        value: _ctx.line_items,
        class: "parts-table p-datatable-sm"
      }, {
        empty: _withCtx(() => _cache[2] || (_cache[2] = [
          _createElementVNode("div", { class: "p-text-center" }, [
            _createElementVNode("span", { class: "p-text-bold" }, "No Items Found")
          ], -1)
        ])),
        default: _withCtx(() => [
          _createVNode(_component_Column, {
            field: "li_parts",
            header: "Part Number"
          }, {
            body: _withCtx((slotProps) => [
              _createElementVNode("div", _hoisted_1, [
                (slotProps.data.wrap_desc)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(slotProps.data.wrap_desc), 1))
                  : (_openBlock(), _createElementBlock("span", _hoisted_3, "No description available")),
                _createElementVNode("span", _hoisted_4, " Part #" + _toDisplayString(slotProps.data.li_parts), 1)
              ])
            ]),
            _: 1
          }),
          _createVNode(_component_Column, {
            class: "blue-text font-bold code-price-wrapper",
            field: "li_prices",
            header: "Price",
            "body-class": "text-right pr-2 xl:pr-5"
          }, {
            body: _withCtx((slotProps) => [
              _createElementVNode("div", _hoisted_5, [
                (slotProps.data.li_prices)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(_ctx.formatPrice(slotProps.data.li_prices)), 1))
                  : _createCommentVNode("", true)
              ])
            ]),
            _: 1
          }),
          _createVNode(_component_Column, {
            field: "li_order_qtys",
            header: "Order Quantity",
            class: "quantity-wrapper",
            "body-class": "text-right pr-2 xl:pr-5"
          }, {
            body: _withCtx((slotProps) => [
              _createElementVNode("div", _hoisted_7, [
                (slotProps.data.li_order_qtys)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(slotProps.data.li_order_qtys), 1))
                  : _createCommentVNode("", true)
              ])
            ]),
            _: 1
          }),
          (_ctx.showShipQuantity)
            ? (_openBlock(), _createBlock(_component_Column, {
                key: 0,
                field: "li_ship_qtys",
                header: "Ship Quantity",
                class: "quantity-wrapper",
                "body-class": "text-right pr-2 xl:pr-5"
              }, {
                body: _withCtx((slotProps) => [
                  _createElementVNode("div", {
                    onClick: _cache[0] || (_cache[0] = ($event) => $event.stopPropagation())
                  }, [
                    _createVNode(_component_InputNumber, {
                      class: "quantity-input border-round-md",
                      modelValue: slotProps.data.li_ship_qtys,
                      "onUpdate:modelValue": ($event: any) => ((slotProps.data.li_ship_qtys) = $event),
                      inputProps: { inputmode: 'numeric' },
                      inputStyle: {
                  borderRadius: '6px !important',
                  minWidth: '70px',
                },
                      min: 0,
                      max: _ctx.getMax(slotProps.data),
                      disabled: _ctx.getMax(slotProps.data) === 0,
                      onInput: ($event: any) => (
                  _ctx.updateShipLine({
                    liIndex: parseInt(slotProps.data.lis) - 1,
                    li: slotProps.data,
                  })
                )
                    }, null, 8, ["modelValue", "onUpdate:modelValue", "max", "disabled", "onInput"])
                  ])
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          (_ctx.showVendor)
            ? (_openBlock(), _createBlock(_component_Column, {
                key: 1,
                field: "vendor",
                header: "PO Vendor",
                "body-class": "text-left pr-2 xl:pr-5"
              }, {
                body: _withCtx((slotProps) => [
                  _createElementVNode("div", {
                    onClick: _cache[1] || (_cache[1] = ($event) => $event.stopPropagation())
                  }, [
                    _createVNode(_component_VendorAutocomplete, {
                      selectedId: slotProps.data.vendor,
                      modelValue: slotProps.data.vendor,
                      "onUpdate:modelValue": ($event: any) => ((slotProps.data.vendor) = $event),
                      onItemSelect: ($event: any) => (_ctx.handleVendorSelected($event, slotProps)),
                      disabled: slotProps.data.po_id,
                      class: _normalizeClass({
                  'p-invalid':
                    Array.isArray(
                      _ctx.v$.line_items.$each.$response.$errors[slotProps.index]
                        .vendor,
                    ) &&
                    _ctx.v$.line_items.$each.$response.$errors[slotProps.index]
                      .vendor.length,
                })
                    }, null, 8, ["selectedId", "modelValue", "onUpdate:modelValue", "onItemSelect", "disabled", "class"]),
                    (
                  Array.isArray(
                    _ctx.v$.line_items.$each.$response.$errors[slotProps.index]
                      .vendor,
                  ) &&
                  _ctx.v$.line_items.$each.$response.$errors[slotProps.index].vendor
                    .length
                )
                      ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                          _createElementVNode("small", _hoisted_10, _toDisplayString(_ctx.v$.line_items.$each.$response.$errors[slotProps.index]
                      .vendor[0].$message), 1)
                        ]))
                      : _createCommentVNode("", true)
                  ])
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          (_ctx.showPONumber)
            ? (_openBlock(), _createBlock(_component_Column, {
                key: 2,
                field: "po_id",
                header: "PO Number",
                "body-class": "text-left pr-2 xl:pr-5"
              }, {
                body: _withCtx((slotProps) => [
                  _createElementVNode("div", _hoisted_11, [
                    (slotProps.data.po_id)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_12, _toDisplayString(slotProps.data.po_id), 1))
                      : _createCommentVNode("", true)
                  ])
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.editableFields, (field) => {
            return (_openBlock(), _createBlock(_component_Column, {
              key: field.field_no,
              field: field.json_name,
              header: field.display_name
            }, {
              body: _withCtx((slotProps) => [
                (_ctx.isFieldCustom(field))
                  ? (_openBlock(), _createBlock(_component_RoverInput, {
                      key: 0,
                      field: field,
                      modelValue: slotProps.data.custom_fields[field.json_name],
                      "onUpdate:modelValue": [($event: any) => ((slotProps.data.custom_fields[field.json_name]) = $event), ($event: any) => (_ctx.updateField($event, field, slotProps.data))]
                    }, null, 8, ["field", "modelValue", "onUpdate:modelValue"]))
                  : (_openBlock(), _createBlock(_component_RoverInput, {
                      key: 1,
                      field: field,
                      modelValue: slotProps.data[field.json_name],
                      "onUpdate:modelValue": [($event: any) => ((slotProps.data[field.json_name]) = $event), ($event: any) => (_ctx.updateField($event, field, slotProps.data))]
                    }, null, 8, ["field", "modelValue", "onUpdate:modelValue"]))
              ]),
              _: 2
            }, 1032, ["field", "header"]))
          }), 128))
        ]),
        _: 1
      }, 8, ["value"])
    ]),
    _: 1
  }))
}
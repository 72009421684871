
import { defineComponent } from "vue";
import { mapGetters, mapActions as vuexMapActions } from "vuex";
import { mapActions } from "pinia";
import Card from "primevue/card";
import Button from "primevue/button";
import Tooltip from "primevue/tooltip";
import AutoComplete from "primevue/autocomplete";
import ModalSpinner from "@/components/UI/ModalSpinner.vue";
import PartsTable from "./PartsTable.vue";
import { useInventoryStore } from "@/store/ui/inventoryModule";
import Search from "../Search.vue";

export default defineComponent({
  name: "Parts",
  components: {
    Card,
    PartsTable,
    Button,
    ModalSpinner,
    Search,
  },
  directives: {
    tooltip: Tooltip,
  },
  data() {
    return {
      isLoading: false,
      searchPartNumber: "",
      partNumber: "",
      poOptions: [],
      searchOptions: [],
    };
  },
  async created() {
    await this.fetchFdicts("PARTS");
  },
  mounted() {
    const search = this.$refs.inventoryPartAutoComplete as any;
    search.$refs.autocomplete.$refs.focusInput.focus();
  },
  computed: {
    ...mapGetters({
      getField: "fdict/getField",
      getWebAccess: "session/getWebAccess",
    }),
    userHasPartsAccess(): boolean {
      return this.getWebAccess("PARTS.E");
    },
  },
  watch: {
    searchPartNumber(newValue: string) {
      if (newValue === "") {
        this.partNumber = "";
      }
    },
  },
  methods: {
    ...vuexMapActions({
      fetchFdicts: "fdict/fetchFdict",
    }),
    ...mapActions(useInventoryStore, [
      "fetchPartById",
      "addNewPart",
      "setActiveTabById",
    ]),
    clearAllFilters() {
      (this.$refs.partsTable as any).clearFilter();
    },
    searchPurchaseOrders(event: any) {
      this.partNumber = event;
      const search = this.$refs.inventoryPartAutoComplete as any;
      search.$refs.autocomplete.$refs.focusInput.focus();
    },
    async handlePartRowClick(part: any) {
      this.$router.push(`/inventory/parts/${part.part_no}`);
    },
    handleAddNewPart() {
      const { id } = this.addNewPart();
      this.$router.push(`/inventory/parts/new/${id}`);
      this.setActiveTabById(id);
    },
  },
});

import { resolveComponent as _resolveComponent, withKeys as _withKeys, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "grid flex justify-content-center mt-2" }
const _hoisted_2 = { class: "col-11 lg:col-11" }
const _hoisted_3 = { class: "grid align-items-center" }
const _hoisted_4 = {
  key: 0,
  style: { whiteSpace: 'break-spaces' }
}
const _hoisted_5 = { key: 0 }
const _hoisted_6 = {
  key: 0,
  class: "col-12"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Column = _resolveComponent("Column")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _component_LoadingSpinner = _resolveComponent("LoadingSpinner")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.rowClass)
          }, [
            _createVNode(_component_InputText, {
              ref: "part",
              modelValue: _ctx.part,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.part) = $event)),
              placeHolder: "Part Number",
              class: "scan-input-text",
              onKeydown: _withKeys(_ctx.searchPart, ["enter"]),
              onFocus: _cache[1] || (_cache[1] = ($event: any) => ($event.target.select()))
            }, null, 8, ["modelValue", "onKeydown"])
          ], 2),
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.rowClassText)
          }, [
            (_ctx.validData)
              ? (_openBlock(), _createElementBlock("span", _hoisted_4, [
                  _cache[3] || (_cache[3] = _createElementVNode("strong", null, "Description:", -1)),
                  _createTextVNode(" " + _toDisplayString(_ctx.part_desc), 1)
                ]))
              : _createCommentVNode("", true)
          ], 2),
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.rowClassText)
          }, [
            (_ctx.validData)
              ? (_openBlock(), _createElementBlock("span", _hoisted_5, [
                  _cache[4] || (_cache[4] = _createElementVNode("strong", null, "Unit of Measure: ", -1)),
                  _createTextVNode(_toDisplayString(_ctx.inventory.um), 1)
                ]))
              : _createCommentVNode("", true)
          ], 2)
        ])
      ])
    ]),
    (_ctx.validData)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          _createVNode(_component_DataTable, {
            value: _ctx.inventoryItems,
            scrollable: "",
            showGridlines: ""
          }, {
            default: _withCtx(() => [
              _createVNode(_component_Column, {
                field: "inv_loc",
                header: "Location",
                headerStyle: "text-align: right",
                bodyStyle: "text-align: right"
              }),
              _createVNode(_component_Column, {
                field: "bin",
                header: "Bin",
                headerStyle: "text-align: right",
                bodyStyle: "text-align: right"
              }),
              _createVNode(_component_Column, {
                field: "qty",
                header: "On Hand",
                headerStyle: "text-align: right",
                bodyStyle: "text-align: right"
              })
            ]),
            _: 1
          }, 8, ["value"])
        ]))
      : _createCommentVNode("", true),
    (_ctx.loading)
      ? (_openBlock(), _createBlock(_component_LoadingSpinner, {
          key: 1,
          loading: ""
        }))
      : _createCommentVNode("", true),
    _createVNode(_component_Dialog, {
      visible: _ctx.showOptions,
      "onUpdate:visible": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.showOptions) = $event)),
      modal: "",
      header: "Part Numbers"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_DataTable, {
          value: _ctx.options,
          stripedRows: "",
          onRowClick: _ctx.pickPart,
          scrollable: "",
          showGridlines: ""
        }, {
          default: _withCtx(() => [
            _createVNode(_component_Column, {
              field: "part_no",
              header: "Part Number"
            }),
            _createVNode(_component_Column, {
              field: "wrap_desc",
              header: "Description"
            }),
            _createVNode(_component_Column, {
              field: "status",
              header: "Status"
            })
          ]),
          _: 1
        }, 8, ["value", "onRowClick"])
      ]),
      _: 1
    }, 8, ["visible"])
  ], 64))
}
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withKeys as _withKeys, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "flex justify-content-center" }
const _hoisted_2 = { class: "grid col-12 lg:col-10 xl:col-7 justify-content-center pb-5" }
const _hoisted_3 = { class: "grid col-12 justify-content-center text-6xl pb-2 pt-2 mt-4 mb-4" }
const _hoisted_4 = {
  key: 0,
  class: "grid col-12 md:col-4 lg:col-4 justify-content-center"
}
const _hoisted_5 = { class: "grid justify-content-center" }
const _hoisted_6 = {
  key: 1,
  class: "grid col-12 md:col-4 lg:col-4 justify-content-center"
}
const _hoisted_7 = {
  key: 2,
  class: "grid col-12 md:col-4 lg:col-4 justify-content-center"
}
const _hoisted_8 = { class: "grid justify-content-center" }
const _hoisted_9 = {
  key: 0,
  class: "pi pi-spin pi-spinner col-12",
  style: {"font-size":"7rem"}
}
const _hoisted_10 = {
  key: 1,
  class: "pi pi-box col-12",
  style: {"font-size":"7rem"}
}
const _hoisted_11 = {
  key: 3,
  class: "grid col-12 md:col-4 lg:col-4 justify-content-center"
}
const _hoisted_12 = { class: "grid justify-content-center" }
const _hoisted_13 = {
  key: 0,
  class: "pi pi-spin pi-spinner col-12",
  style: {"font-size":"7rem"}
}
const _hoisted_14 = {
  key: 1,
  class: "pi pi-print col-12",
  style: {"font-size":"7rem"}
}
const _hoisted_15 = { class: "grid col-12 md:col-4 lg:col-4 justify-content-center" }
const _hoisted_16 = { class: "p-inputgroup mt-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_EmailFileDialog = _resolveComponent("EmailFileDialog")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Dialog = _resolveComponent("Dialog")!
  const _component_ConfirmDialog = _resolveComponent("ConfirmDialog")!
  const _component_PrintPickTicket = _resolveComponent("PrintPickTicket")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("span", _hoisted_3, [
          _cache[10] || (_cache[10] = _createElementVNode("i", {
            class: "pi pi-check-circle pr-3 pt-2 pl-2",
            style: {"font-size":"3rem"}
          }, null, -1)),
          _createTextVNode(" Order " + _toDisplayString(_ctx.getLastOrderId) + " Created ", 1)
        ]),
        (!_ctx.hideOrderButtons)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createVNode(_component_Button, {
                class: "m-3 pt-3 confirmation-btn",
                style: { maxWidth: '16rem' },
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handleGetOrderPDF(_ctx.getLastOrderId)))
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_5, [
                    _createElementVNode("i", {
                      class: _normalizeClass(_ctx.orderPDFIcon(_ctx.getLastOrderId)),
                      style: {"font-size":"7rem"}
                    }, null, 2),
                    _cache[11] || (_cache[11] = _createElementVNode("span", { class: "col-12" }, " Download PDF", -1))
                  ])
                ]),
                _: 1
              })
            ]))
          : _createCommentVNode("", true),
        (!_ctx.hideOrderButtons)
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
              _createVNode(_component_Button, {
                class: "m-3 pt-3 confirmation-btn",
                style: { maxWidth: '16rem' },
                onClick: _ctx.handleEmailPDF
              }, {
                default: _withCtx(() => _cache[12] || (_cache[12] = [
                  _createElementVNode("div", { class: "grid justify-content-center" }, [
                    _createElementVNode("i", {
                      class: "pi pi-at col-12",
                      style: {"font-size":"7rem"}
                    }),
                    _createElementVNode("span", { class: "col-12" }, [
                      _createElementVNode("i", { class: "pi pi-file-pdf" }),
                      _createTextVNode(" Email PDF")
                    ])
                  ], -1)
                ])),
                _: 1
              }, 8, ["onClick"])
            ]))
          : _createCommentVNode("", true),
        (_ctx.showShipOrderButton)
          ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
              _createVNode(_component_Button, {
                class: "m-3 pt-3 confirmation-btn",
                style: { maxWidth: '16rem' },
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.handleShipOrder(_ctx.getLastOrderId)))
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_8, [
                    (_ctx.loadingShipOrder)
                      ? (_openBlock(), _createElementBlock("i", _hoisted_9))
                      : (_openBlock(), _createElementBlock("i", _hoisted_10)),
                    _cache[13] || (_cache[13] = _createElementVNode("span", { class: "col-12" }, "Ship Order", -1))
                  ])
                ]),
                _: 1
              })
            ]))
          : _createCommentVNode("", true),
        (_ctx.showPickTicketPrint)
          ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
              _createVNode(_component_Button, {
                class: "m-3 pt-3 confirmation-btn",
                style: { maxWidth: '16rem' },
                onClick: _ctx.handlePickTicketPrinterSelection
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_12, [
                    (_ctx.loading)
                      ? (_openBlock(), _createElementBlock("i", _hoisted_13))
                      : (_openBlock(), _createElementBlock("i", _hoisted_14)),
                    _cache[14] || (_cache[14] = _createElementVNode("span", { class: "col-12" }, "Print Pick Ticket", -1))
                  ])
                ]),
                _: 1
              }, 8, ["onClick"])
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_15, [
          _createVNode(_component_Button, {
            class: "m-3 pt-3 confirmation-btn",
            style: { maxWidth: '16rem' },
            onClick: _ctx.handleContinueShopping
          }, {
            default: _withCtx(() => _cache[15] || (_cache[15] = [
              _createElementVNode("div", { class: "grid justify-content-center" }, [
                _createElementVNode("i", {
                  class: "pi pi-arrow-right col-12",
                  style: {"font-size":"7rem"}
                }),
                _createElementVNode("span", { class: "col-12" }, "Continue Shopping")
              ], -1)
            ])),
            _: 1
          }, 8, ["onClick"])
        ])
      ])
    ]),
    _createVNode(_component_EmailFileDialog, {
      show: _ctx.showEmailPDFDialog,
      header: 'Email Order #' + _ctx.getLastOrderId,
      fileId: _ctx.getLastOrderId,
      fileName: _ctx.getLastOrderId,
      contacts: _ctx.getLastOrderCustomerContacts,
      onHide: _cache[2] || (_cache[2] = 
      (isHidden) => {
        _ctx.showEmailPDFDialog = isHidden;
      }
    ),
      onOnEmail: _ctx.sendEmail,
      onOnAddressAdded: _ctx.handleAddressAdded
    }, null, 8, ["show", "header", "fileId", "fileName", "contacts", "onOnEmail", "onOnAddressAdded"]),
    _createVNode(_component_Dialog, {
      visible: _ctx.showNoteModal,
      "onUpdate:visible": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.showNoteModal) = $event)),
      modal: ""
    }, {
      header: _withCtx(() => _cache[16] || (_cache[16] = [
        _createTextVNode(" Complete Payout ")
      ])),
      footer: _withCtx(() => [
        _createVNode(_component_Button, {
          label: "Submit",
          icon: "pi pi-check",
          onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.addPayNote()))
        })
      ]),
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_16, [
          _cache[17] || (_cache[17] = _createElementVNode("small", {
            class: "p-inputgroup-addon font-semibold general-label-color",
            style: { width: '104px' }
          }, "Note", -1)),
          _createVNode(_component_InputText, {
            modelValue: _ctx.payNote,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.payNote) = $event)),
            id: "reg",
            class: "text-sm",
            onKeyup: _cache[4] || (_cache[4] = _withKeys(($event: any) => (_ctx.addPayNote()), ["enter"]))
          }, null, 8, ["modelValue"])
        ])
      ]),
      _: 1
    }, 8, ["visible"]),
    _createVNode(_component_Dialog, { visible: _ctx.showCfee }, {
      footer: _withCtx(() => [
        _createVNode(_component_Button, {
          label: "'Yes'",
          icon: "pi pi-check",
          onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.confirmCfee()))
        }),
        _createVNode(_component_Button, {
          label: "'No'",
          icon: "pi pi-times",
          onClick: _cache[8] || (_cache[8] = ($event: any) => {
          _ctx.submit('nocc');
          _ctx.showCfee = false;
        })
        })
      ]),
      default: _withCtx(() => [
        _cache[18] || (_cache[18] = _createElementVNode("span", null, "Will this be paid with a Credit Card?", -1))
      ]),
      _: 1
    }, 8, ["visible"]),
    _createVNode(_component_ConfirmDialog),
    _createVNode(_component_PrintPickTicket, {
      visible: _ctx.showPickTicketPrinterDialog,
      salesOrder: _ctx.getLastOrder,
      onHandleClose: _cache[9] || (_cache[9] = ($event: any) => (_ctx.showPickTicketPrinterDialog = false))
    }, null, 8, ["visible", "salesOrder"])
  ], 64))
}
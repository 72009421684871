
import { defineComponent } from "vue";
import Inventory from "../components/Inventory/Inventory.vue";
import { useInventoryStore } from "@/store/ui/inventoryModule";

const inventoryStore = useInventoryStore();

export default defineComponent({
  components: {
    Inventory,
  },
  beforeCreate() {
    inventoryStore.loadStore();
  },
});

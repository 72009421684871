import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "grid justify-content-end mt-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_Column = _resolveComponent("Column")!
  const _component_DataTable = _resolveComponent("DataTable")!

  return (_openBlock(), _createBlock(_component_DataTable, {
    loading: _ctx.isLoadingOrders,
    value: _ctx.dateInfo,
    paginator: true,
    filterDisplay: "menu",
    first: _ctx.first,
    "onUpdate:first": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.first) = $event)),
    rows: _ctx.rowsPerPage,
    rowsPerPageOptions: [10, 25, 50],
    breakpoint: "960px",
    responsiveLayout: "stack",
    "sort-field": _ctx.sortBy,
    "sort-order": _ctx.sortOrder,
    class: "p-datatable-sm w-12 text-sm bordered-datatable"
  }, {
    header: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_Button, {
          class: "ml-2",
          label: "Refresh",
          icon: "pi pi-refresh",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.fetchOrders(true)))
        })
      ])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_Column, {
        field: "formatted_date",
        header: "Date",
        sortable: "",
        "header-class": "column-header-left"
      }, {
        body: _withCtx((slotProps) => [
          _createElementVNode("span", null, _toDisplayString(slotProps.data.formatted_date.toLocaleDateString()), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_Column, {
        field: "quantity",
        header: "Quantity",
        sortable: "",
        bodyStyle: "text-align: right",
        "header-class": "column-header-right"
      }),
      _createVNode(_component_Column, {
        field: "container_qty",
        header: "Container Quantity",
        sortable: "",
        bodyStyle: "text-align: right",
        "header-class": "column-header-right"
      }),
      _createVNode(_component_Column, {
        field: "type",
        header: "Type",
        sortable: "",
        "header-class": "column-header-left"
      }),
      _createVNode(_component_Column, {
        field: "id",
        header: "Record ID",
        sortable: "",
        "header-class": "column-header-left"
      }),
      _createVNode(_component_Column, {
        field: "li_no",
        header: "Li No",
        sortable: "",
        bodyStyle: "text-align: right",
        "header-class": "column-header-right"
      }),
      _createVNode(_component_Column, {
        field: "invloc",
        header: "Inventory Location",
        sortable: "",
        "header-class": "column-header-left"
      })
    ]),
    _: 1
  }, 8, ["loading", "value", "first", "rows", "sort-field", "sort-order"]))
}

import { computed, defineComponent } from "vue";
import { mapActions, mapGetters } from "vuex";
import TabPanel from "primevue/tabpanel";
import TabView from "primevue/tabview";
import CollapsibleSection from "@/components/UI/CollapsibleSection.vue";
import Card from "primevue/card";
import DropDown from "primevue/dropdown";
import InputText from "primevue/inputtext";
import CheckBox from "primevue/checkbox";
import CurrencyCode from "@/types/currency";
import Term from "@/types/Term";
import PriceCode from "@/types/pricingcode";
import Customer from "@/types/customer";
import OrderLineItems from "./OrderLineItems.vue";
import CustomerService from "@/services/CustomerService";
import ReceiptHistory from "@/components/Pos/ReceiptHistory.vue";
import Skeleton from "primevue/skeleton";
import Button from "primevue/button";
import SalesOrder from "@/types/salesorder";
import OrderTotal from "./OrderTotal.vue";
import { AccessRights } from "@/types/accessControl/AbstractAccessGetter";
import ReceivablesTable from "../Receivables/ReceivablesTable.vue";

const customerService = new CustomerService();

export default defineComponent({
  components: {
    TabPanel,
    TabView,
    CollapsibleSection,
    DropDown,
    Card,
    InputText,
    CheckBox,
    OrderLineItems,
    ReceiptHistory,
    Skeleton,
    Button,
    OrderTotal,
    ReceivablesTable,
  },
  emits: ["release-order"],
  inject: ["orderAccessRights"],
  provide() {
    return {
      customerId: computed(() => this.customer.cust_id),
      customer: computed(() => this.customer),
    };
  },
  created() {
    this.checkAndGetControls();
    this.fetchOrdersData();
  },
  updated() {
    this.fetchOrdersData();
  },
  methods: {
    ...mapActions({
      addNotification: "notification/add",
      changeActiveMainTab: "accountingOrders/changeMainActiveTab",
      fetchTerms: "customerInquiry/getTerms",
      fetchCustomerControls: "customerInquiry/getControls",
    }),
    handleMainTabChange(e: any) {
      this.changeActiveMainTab(e.index !== undefined ? e.index : 0);
    },
    checkAndGetControls() {
      const client = this.getClient;
      if (this.getPricingCodes.length === 0) {
        const CONTROLS = [
          {
            Client: client,
            control: "price",
            id: "PRICE",
            filename: "CONTROL",
            procedure: "price.CONTROL",
            getter: "customerInquiry/getPricingCodes",
          },
          {
            Client: client,
            id: "SHIP",
            procedure: "SHIP.CONTROL",
            filename: "CONTROL",
            getter: "customerInquiry/getCountries",
          },
          {
            Client: client,
            control: "currency",
            id: "CURRENCY",
            filename: "CONTROL",
            procedure: "CURRENCY.CONTROL",
            getter: "customerInquiry/getCurrency",
          },
          {
            Client: client,
            control: "group",
            id: "GROUP",
            filename: "CONTROL",
            procedure: "group.CONTROL",
            getter: "customerInquiry/getGroups",
          },
          {
            Client: client,
            id: "CONTACT",
            procedure: "CONTACT.CONTROL",
            filename: "CONTROL",
            getter: "customerInquiry/getContactTypes",
          },
          {
            id: "MAIL",
            procedure: "MAIL.CONTROL",
            filename: "CONTROL",
            Client: client,
            getter: "customerInquiry/getMailControls",
          },
          {
            Client: client,
            id: "MRK",
            procedure: "MRK.CONTROL",
            filename: "CONTROL",
            getter: "customerInquiry/getHoldCodes",
          },
          {
            Client: client,
            id: "PLAN",
            procedure: "PLAN.CONTROL",
            filename: "CONTROL",
            getter: "customerInquiry/getPlanGroupCodes",
          },
        ];

        CONTROLS.forEach((element: any) => {
          this.fetchCustomerControls(element);
        });
      }
      if (!this.getTerms || this.getTerms.length === 0) {
        this.fetchTerms({ client });
      }
    },
    async getCustomer(customerId: string) {
      if (customerId !== this.customer.cust_id) {
        try {
          await customerService
            .getCustomer(customerId, this.getClient, "contact_email")
            .then((response: any) => {
              this.customer = response;
            });
        } catch (error) {
          this.addNotification({
            type: "error",
            message: "error getting customer information",
          });
        }
      }
    },
    async fetchOrdersData() {
      this.isLoading = true;
      const orderId = this.$attrs.id as any;

      this.order = this.getActiveOrderTab(orderId).record;
      this.getCustomer(this.order.sold_to);

      this.isLoading = false;
    },
    handleOrderRelease() {
      this.$emit("release-order", this.order);
    },
  },
  computed: {
    ...mapGetters({
      getCurrency: "customerInquiry/getCurrency",
      getPricingCodes: "customerInquiry/getPricingCodes",
      getTerms: "customerInquiry/getTerms",
      getClient: "session/getClient",
      getMainActiveTab: "accountingOrders/getMainActiveTab",
      getRequireReleaseCode: "mrkControl/getRequireReleaseCode",
      getField: "fdict/getField",
      showPickTicketPrint: "mrkControl/showPickTicketPrint",
      getAccessRights: "session/getAccessRights",
      getActiveOrderTab: "accountingInquiry/getActiveOrderTab",
    }),
    currencyList(): any {
      const list = this.getCurrency as Array<CurrencyCode>;
      const options =
        list != null ? list.map((item) => item.currency_code) : [];
      return options;
    },
    termsList(): Array<any> {
      const list = this.getTerms as Array<Term>;

      if (list != null) {
        const options = list.map((item) => item.terms_id);
        return options;
      }
      return [];
    },
    priceCodes(): Array<any> {
      const priceCodes = this.getPricingCodes as Array<PriceCode>;
      let map = [] as Array<any>;
      if (priceCodes) {
        map =
          priceCodes.map((item) => {
            return item.code;
          }) || [];
      }
      return map;
    },
    editAccess(): boolean {
      return (this.orderAccessRights as AccessRights).change;
    },
  },
  data() {
    return {
      customer: {} as Customer,
      order: {} as SalesOrder,
      isLoading: false,
      sectionStatus: {
        orderDetails: true,
        customerDetails: true,
      },
      loadingRelease: false,
    };
  },
});

import { createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Column = _resolveComponent("Column")!
  const _component_Button = _resolveComponent("Button")!
  const _component_DataTable = _resolveComponent("DataTable")!

  return (_openBlock(), _createBlock(_component_DataTable, {
    dataKey: "cust_id",
    value: _ctx.customers,
    scrollable: "",
    selectionMode: "single",
    scrollHeight: "60vh",
    breakpoint: "1267px",
    onRowClick: _ctx.handleCustomersRowClick,
    class: "p-datatable-sm",
    lazy: _ctx.lazyLoad,
    rows: _ctx.rows,
    first: _ctx.first,
    paginator: _ctx.paginate,
    responsiveLayout: "stack",
    rowsPerPageOptions: [50, 150, 200],
    onPage: _ctx.pageClick,
    page: _ctx.page,
    totalRecords: _ctx.customers?.length || 0,
    paginatorTemplate: "FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink  RowsPerPageDropdown CurrentPageReport",
    currentPageReportTemplate: "Total Records: {totalRecords}"
  }, {
    empty: _withCtx(() => _cache[0] || (_cache[0] = [
      _createTextVNode(" No rows to show. ")
    ])),
    default: _withCtx(() => [
      _createVNode(_component_Column, {
        field: "cust_id",
        header: "ID"
      }, {
        body: _withCtx((slotProps) => [
          _createElementVNode("span", null, _toDisplayString(slotProps.data.custno || slotProps.data.cust_id), 1),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.idFields, (field) => {
            return (_openBlock(), _createElementBlock("span", {
              key: field.field_no
            }, [
              _cache[1] || (_cache[1] = _createElementVNode("br", null, null, -1)),
              _createTextVNode(" " + _toDisplayString(field.label) + " " + _toDisplayString(slotProps.data[field.json_name]), 1)
            ]))
          }), 128))
        ]),
        _: 1
      }),
      _createVNode(_component_Column, {
        field: "name",
        header: "Customer Name"
      }),
      (_ctx.showServiceRep)
        ? (_openBlock(), _createBlock(_component_Column, {
            key: 0,
            field: "service_rep",
            header: "Service Rep"
          }))
        : (_openBlock(), _createBlock(_component_Column, {
            key: 1,
            field: "rep_items",
            header: "Reps"
          }, {
            body: _withCtx((slotProps) => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(slotProps.data.rep_items, (rep, index) => {
                return (_openBlock(), _createElementBlock("span", { key: index }, [
                  _createTextVNode(_toDisplayString(rep.rep_name) + " ", 1),
                  (index < slotProps.data.rep_items.length - 1)
                    ? (_openBlock(), _createElementBlock("br", _hoisted_1))
                    : _createCommentVNode("", true)
                ]))
              }), 128))
            ]),
            _: 1
          })),
      _createVNode(_component_Column, { header: "Shipping Address" }, {
        body: _withCtx((slotProps) => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.shippingAddress(slotProps.data), (address, i) => {
            return (_openBlock(), _createElementBlock("span", { key: i }, [
              _createTextVNode(_toDisplayString(address) + " ", 1),
              _cache[2] || (_cache[2] = _createElementVNode("br", null, null, -1))
            ]))
          }), 128))
        ]),
        _: 1
      }),
      _createVNode(_component_Column, {
        field: "phone",
        header: "Phone #",
        bodyStyle: "text-align: right"
      }, {
        body: _withCtx((slotProps) => [
          _createElementVNode("a", {
            href: 'tel:' + slotProps.data.phone
          }, _toDisplayString(slotProps.data.phone), 9, _hoisted_2)
        ]),
        _: 1
      }),
      (_ctx.allowRemove)
        ? (_openBlock(), _createBlock(_component_Column, { key: 2 }, {
            body: _withCtx((slotProps) => [
              _createVNode(_component_Button, {
                onClick: _withModifiers(($event: any) => (_ctx.removeCustomer(slotProps.data.cust_id)), ["stop"]),
                icon: "pi pi-times",
                style: {"background-color":"red !important"}
              }, null, 8, ["onClick"])
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["value", "onRowClick", "lazy", "rows", "first", "paginator", "onPage", "page", "totalRecords"]))
}
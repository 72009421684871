import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "grid justify-content-center" }
const _hoisted_2 = { class: "col-12 mt-2" }
const _hoisted_3 = { class: "flex justify-content-between align-items-center" }
const _hoisted_4 = { style: {"margin":"0px"} }
const _hoisted_5 = { class: "flex justify-content-between align-items-center" }
const _hoisted_6 = { class: "description" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_Column = _resolveComponent("Column")!
  const _component_InputNumber = _resolveComponent("InputNumber")!
  const _component_DataTable = _resolveComponent("DataTable")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.selectedParts.length > 0)
        ? (_openBlock(), _createBlock(_component_DataTable, {
            key: 0,
            value: _ctx.selectedParts,
            responsiveLayout: "scroll",
            scrollable: "",
            scrollHeight: "360px"
          }, {
            header: _withCtx(() => [
              _createElementVNode("div", _hoisted_3, [
                _cache[0] || (_cache[0] = _createElementVNode("h4", { class: "m-0" }, "Parts By Price Code", -1)),
                _createVNode(_component_Button, {
                  icon: "pi pi-plus",
                  label: "Add Parts",
                  iconPos: "left",
                  onClick: _ctx.addParts
                }, null, 8, ["onClick"])
              ])
            ]),
            default: _withCtx(() => [
              _createVNode(_component_Column, {
                field: "part",
                header: "Part Number"
              }, {
                body: _withCtx((slotProps) => [
                  _createElementVNode("p", _hoisted_4, _toDisplayString(slotProps.data[slotProps.field]), 1),
                  _createElementVNode("div", _hoisted_5, [
                    _createElementVNode("span", _hoisted_6, _toDisplayString(slotProps.data["description"]), 1)
                  ])
                ]),
                _: 1
              }),
              _createVNode(_component_Column, {
                field: "price",
                header: "Price",
                headerClass: "right-align",
                bodyStyle: "text-align:right"
              }, {
                body: _withCtx((slotProps) => [
                  _createVNode(_component_InputNumber, {
                    modelValue: slotProps.data[slotProps.field],
                    "onUpdate:modelValue": ($event: any) => ((slotProps.data[slotProps.field]) = $event),
                    min: 0,
                    mode: "decimal",
                    inputStyle: "width:200px",
                    minFractionDigits: 4,
                    maxFractionDigits: 4
                  }, null, 8, ["modelValue", "onUpdate:modelValue"])
                ]),
                _: 1
              }),
              _createVNode(_component_Column, {
                field: "qty",
                header: "Quantity",
                headerClass: "right-align",
                bodyStyle: "text-align:right"
              }, {
                body: _withCtx((slotProps) => [
                  _createVNode(_component_InputNumber, {
                    inputStyle: "width:75px",
                    style: {"margin":"0px","padding":"0px"},
                    showButtons: "",
                    buttonLayout: "horizontal",
                    incrementButtonIcon: "pi pi-plus",
                    decrementButtonIcon: "pi pi-minus",
                    modelValue: slotProps.data[slotProps.field],
                    "onUpdate:modelValue": ($event: any) => ((slotProps.data[slotProps.field]) = $event),
                    min: 0
                  }, null, 8, ["modelValue", "onUpdate:modelValue"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["value"]))
        : _createCommentVNode("", true)
    ])
  ]))
}
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "formgrid grid" }
const _hoisted_2 = { class: "col-11 field pt-3" }
const _hoisted_3 = { for: "to" }
const _hoisted_4 = { class: "col-11 field" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { class: "p-error" }
const _hoisted_7 = { class: "col-11 field" }
const _hoisted_8 = { class: "col-11 field" }
const _hoisted_9 = { class: "col-11 field" }
const _hoisted_10 = { class: "field" }
const _hoisted_11 = { class: "field" }
const _hoisted_12 = { class: "confirmation-content" }
const _hoisted_13 = { class: "font-semibold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AutoComplete = _resolveComponent("AutoComplete")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Textarea = _resolveComponent("Textarea")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Dialog = _resolveComponent("Dialog")!
  const _component_DataGrid = _resolveComponent("DataGrid")!
  const _component_ContactDialog = _resolveComponent("ContactDialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Dialog, {
      visible: _ctx.showEmailModal,
      "onUpdate:visible": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.showEmailModal) = $event)),
      header: _ctx.header,
      style: { width: '1000px' },
      modal: true,
      draggable: false,
      class: "p-fluid",
      onHide: _cache[13] || (_cache[13] = 
      () => {
        _ctx.hideDialog();
        _ctx.$emit('hide', _ctx.showEmailModal);
      }
    )
    }, {
      footer: _withCtx(() => [
        _createVNode(_component_Button, {
          label: "Cancel",
          icon: "pi pi-times",
          class: "p-button-text",
          onClick: _cache[11] || (_cache[11] = 
          () => {
            _ctx.$emit('hide', false);
          }
        )
        }),
        _createVNode(_component_Button, {
          label: "Send",
          icon: "pi pi-send",
          class: "p-button-text",
          loading: _ctx.isSendingEmail,
          onClick: _ctx.email
        }, null, 8, ["loading", "onClick"])
      ]),
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _cache[18] || (_cache[18] = _createElementVNode("div", { class: "col-1 field text-right pt-3" }, [
            _createElementVNode("label", { for: "from" }, "From")
          ], -1)),
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("label", _hoisted_3, _toDisplayString(_ctx.from[0]), 1)
          ]),
          _cache[19] || (_cache[19] = _createElementVNode("div", { class: "col-1 field text-right" }, [
            _createElementVNode("label", { for: "to" }, "To")
          ], -1)),
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_AutoComplete, {
              id: "to",
              required: "true",
              autofocus: "",
              modelValue: _ctx.to,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.to) = $event)),
              multiple: "",
              suggestions: _ctx.emails,
              onKeyup: _cache[1] || (_cache[1] = ($event: any) => (_ctx.addEmail($event, _ctx.to))),
              onComplete: _ctx.search,
              onBlur: _cache[2] || (_cache[2] = ($event: any) => (_ctx.handleBlurEmails($event, _ctx.to))),
              delay: 0,
              dropdown: "",
              class: _normalizeClass({
            'p-invalid': _ctx.isSubmitted && _ctx.v$.to.required.$invalid,
          })
            }, null, 8, ["modelValue", "suggestions", "onComplete", "class"]),
            (_ctx.isSubmitted && _ctx.v$.to.required.$invalid)
              ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                  _createElementVNode("small", _hoisted_6, _toDisplayString(_ctx.v$.to.required.$message), 1)
                ]))
              : _createCommentVNode("", true)
          ]),
          _cache[20] || (_cache[20] = _createElementVNode("div", { class: "col-1 field text-right" }, [
            _createElementVNode("label", { for: "cc" }, "CC")
          ], -1)),
          _createElementVNode("div", _hoisted_7, [
            _createVNode(_component_AutoComplete, {
              id: "cc",
              modelValue: _ctx.cc,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.cc) = $event)),
              multiple: "",
              suggestions: _ctx.emails,
              onKeyup: _cache[4] || (_cache[4] = ($event: any) => (_ctx.addEmail($event, _ctx.cc))),
              onComplete: _ctx.search,
              onBlur: _cache[5] || (_cache[5] = ($event: any) => (_ctx.handleBlurEmails($event, _ctx.cc))),
              delay: 0,
              dropdown: ""
            }, null, 8, ["modelValue", "suggestions", "onComplete"])
          ]),
          _cache[21] || (_cache[21] = _createElementVNode("div", { class: "col-1 field text-right" }, [
            _createElementVNode("label", { for: "bcc" }, "BCC")
          ], -1)),
          _createElementVNode("div", _hoisted_8, [
            _createVNode(_component_AutoComplete, {
              id: "bcc",
              modelValue: _ctx.bcc,
              "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.bcc) = $event)),
              multiple: "",
              suggestions: _ctx.emails,
              onKeyup: _cache[7] || (_cache[7] = ($event: any) => (_ctx.addEmail($event, _ctx.bcc))),
              onComplete: _ctx.search,
              onBlur: _cache[8] || (_cache[8] = ($event: any) => (_ctx.handleBlurEmails($event, _ctx.bcc))),
              delay: 0,
              dropdown: ""
            }, null, 8, ["modelValue", "suggestions", "onComplete"])
          ]),
          _cache[22] || (_cache[22] = _createElementVNode("div", { class: "col-1 field text-right" }, [
            _createElementVNode("label", { for: "subject" }, "Subject")
          ], -1)),
          _createElementVNode("div", _hoisted_9, [
            _createVNode(_component_InputText, {
              id: "subject",
              modelValue: _ctx.subject,
              "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.subject) = $event)),
              required: "false"
            }, null, 8, ["modelValue"])
          ])
        ]),
        _createElementVNode("div", _hoisted_10, [
          _createVNode(_component_Textarea, {
            id: "body",
            modelValue: _ctx.body,
            "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.body) = $event)),
            placeholder: "Insert Email Text...",
            required: "false",
            rows: "10",
            cols: "50"
          }, null, 8, ["modelValue"])
        ]),
        _createElementVNode("div", _hoisted_11, [
          _cache[23] || (_cache[23] = _createElementVNode("label", { for: "att" }, "Attachment  ", -1)),
          _createVNode(_component_InputText, {
            id: "att",
            required: "false",
            modelValue: _ctx.fileName ? _ctx.fileName : 'File_' + _ctx.fileId,
            disabled: ""
          }, null, 8, ["modelValue"])
        ])
      ]),
      _: 1
    }, 8, ["visible", "header"]),
    _createVNode(_component_Dialog, {
      visible: _ctx.showSaveNewEmail,
      "onUpdate:visible": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.showSaveNewEmail) = $event)),
      style: { width: '550px' },
      header: "Save New Email",
      modal: true,
      class: "p-fluid text-center"
    }, {
      footer: _withCtx(() => [
        _createVNode(_component_Button, {
          label: "Add To Existing Contact",
          icon: "pi pi-user-edit",
          class: "p-button-text",
          onClick: _cache[14] || (_cache[14] = ($event: any) => (_ctx.addEmailToContact(false)))
        }),
        _createVNode(_component_Button, {
          label: "Add To New Contact ",
          icon: "pi pi-user-plus",
          class: "p-button-text",
          onClick: _cache[15] || (_cache[15] = ($event: any) => (_ctx.addEmailToContact(true)))
        })
      ]),
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_12, [
          _cache[24] || (_cache[24] = _createElementVNode("p", null, "The following email address is not assigned to a contact:", -1)),
          _createElementVNode("p", _hoisted_13, _toDisplayString(_ctx.newEmailsToSave.join(", ")), 1),
          _cache[25] || (_cache[25] = _createElementVNode("p", null, "Would you like to assign it to a new or existing contact?", -1))
        ])
      ]),
      _: 1
    }, 8, ["visible"]),
    _createVNode(_component_Dialog, {
      visible: _ctx.showContactListDialog,
      "onUpdate:visible": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.showContactListDialog) = $event)),
      style: { width: '550px' },
      header: "Select Contact",
      modal: true,
      class: "p-fluid text-center"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_DataGrid, {
          items: _ctx.contacts,
          columns: _ctx.columns,
          dataKey: "contact_id",
          onSelectRow: _ctx.onExistingContactSelected
        }, null, 8, ["items", "columns", "onSelectRow"])
      ]),
      _: 1
    }, 8, ["visible"]),
    _createVNode(_component_ContactDialog, {
      visible: _ctx.contactDialogOpened,
      isNewContact: _ctx.isNewContact,
      index: 0,
      onOnDialogClose: _ctx.handleCloseContactDialog,
      contactsInfoToEdit: _ctx.contactsInfoToEdit,
      definedCustomerId: _ctx.custId,
      onOnContactSave: _ctx.handleContactSave
    }, null, 8, ["visible", "isNewContact", "onOnDialogClose", "contactsInfoToEdit", "definedCustomerId", "onOnContactSave"])
  ], 64))
}
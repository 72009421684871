import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withKeys as _withKeys, createVNode as _createVNode, normalizeClass as _normalizeClass, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "grid flex justify-content-center mt-2" }
const _hoisted_2 = { class: "col-11 lg:col-11" }
const _hoisted_3 = { class: "grid align-items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Button = _resolveComponent("Button")!
  const _component_MessageBox = _resolveComponent("MessageBox")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.rowClass)
          }, [
            _cache[29] || (_cache[29] = _createElementVNode("span", { class: "p-inputgroup-addon" }, "Employee ID", -1)),
            _createVNode(_component_InputText, {
              ref: "emp_id",
              class: "scan-input-text",
              modelValue: _ctx.shopTransactionPayload.emp_id,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.shopTransactionPayload.emp_id) = $event)),
              onFocus: _cache[1] || (_cache[1] = ($event: any) => ($event.target.select())),
              onKeypress: _withKeys(_ctx.handleEmployeeId, ["enter"]),
              onKeydown: _withKeys(_ctx.handleEmployeeId, ["down"])
            }, null, 8, ["modelValue", "onKeypress", "onKeydown"])
          ], 2),
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.rowClass)
          }, [
            _cache[30] || (_cache[30] = _createElementVNode("span", { class: "p-inputgroup-addon" }, "WO ID", -1)),
            _createVNode(_component_InputText, {
              ref: "wo_id",
              class: "scan-input-text",
              modelValue: _ctx.shopTransactionPayload.wo_id,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.shopTransactionPayload.wo_id) = $event)),
              onFocus: _cache[3] || (_cache[3] = ($event: any) => ($event.target.select())),
              onKeypress: _withKeys(_ctx.handleWorkOrder, ["enter"]),
              onKeydown: [
                _withKeys(_ctx.handleWorkOrder, ["down"]),
                _cache[4] || (_cache[4] = _withKeys(($event: any) => (_ctx.focusInput('emp_id')), ["up"]))
              ]
            }, null, 8, ["modelValue", "onKeypress", "onKeydown"])
          ], 2),
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.rowClass)
          }, [
            _cache[31] || (_cache[31] = _createElementVNode("span", { class: "p-inputgroup-addon" }, "Model", -1)),
            _createVNode(_component_InputText, {
              ref: "model",
              class: "scan-input-text",
              modelValue: _ctx.shopTransactionPayload.model,
              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.shopTransactionPayload.model) = $event)),
              onFocus: _cache[6] || (_cache[6] = ($event: any) => ($event.target.select())),
              onKeypress: _withKeys(_ctx.handleModel, ["enter"]),
              onKeydown: [
                _withKeys(_ctx.handleModel, ["down"]),
                _cache[7] || (_cache[7] = _withKeys(($event: any) => (_ctx.focusInput('wo_id')), ["up"]))
              ]
            }, null, 8, ["modelValue", "onKeypress", "onKeydown"])
          ], 2),
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.rowClass)
          }, [
            _cache[32] || (_cache[32] = _createElementVNode("span", { class: "p-inputgroup-addon" }, "Quantity", -1)),
            _createVNode(_component_InputText, {
              ref: "quantity",
              class: "scan-input-text",
              modelValue: _ctx.shopTransactionPayload.quantity,
              "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.shopTransactionPayload.quantity) = $event)),
              onFocus: _cache[9] || (_cache[9] = ($event: any) => ($event.target.select())),
              onKeypress: _withKeys(_ctx.handleQuantity, ["enter"]),
              onKeydown: [
                _withKeys(_ctx.handleQuantity, ["down"]),
                _cache[10] || (_cache[10] = _withKeys(($event: any) => (_ctx.focusInput('from_oper')), ["up"]))
              ]
            }, null, 8, ["modelValue", "onKeypress", "onKeydown"])
          ], 2),
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.rowClass)
          }, [
            _cache[33] || (_cache[33] = _createElementVNode("span", { class: "p-inputgroup-addon" }, "From Oper", -1)),
            _createVNode(_component_InputText, {
              ref: "from_oper",
              class: "scan-input-text",
              modelValue: _ctx.shopTransactionPayload.from_oper,
              "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.shopTransactionPayload.from_oper) = $event)),
              onFocus: _cache[12] || (_cache[12] = ($event: any) => ($event.target.select())),
              onKeypress: _withKeys(_ctx.handleFromOperation, ["enter"]),
              onKeydown: [
                _withKeys(_ctx.handleFromOperation, ["down"]),
                _cache[13] || (_cache[13] = _withKeys(($event: any) => (_ctx.focusInput('from_wc')), ["up"]))
              ]
            }, null, 8, ["modelValue", "onKeypress", "onKeydown"])
          ], 2),
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.rowClass)
          }, [
            _cache[34] || (_cache[34] = _createElementVNode("span", { class: "p-inputgroup-addon" }, "From WC", -1)),
            _createVNode(_component_InputText, {
              ref: "from_wc",
              class: "scan-input-text",
              modelValue: _ctx.shopTransactionPayload.from_wc,
              "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.shopTransactionPayload.from_wc) = $event)),
              onFocus: _cache[15] || (_cache[15] = ($event: any) => ($event.target.select())),
              onKeypress: _withKeys(_ctx.handleFromWorkCenter, ["enter"]),
              onKeydown: [
                _withKeys(_ctx.handleFromWorkCenter, ["down"]),
                _cache[16] || (_cache[16] = _withKeys(($event: any) => (_ctx.focusInput('from_oper')), ["up"]))
              ]
            }, null, 8, ["modelValue", "onKeypress", "onKeydown"])
          ], 2),
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.rowClass)
          }, [
            _cache[35] || (_cache[35] = _createElementVNode("span", { class: "p-inputgroup-addon" }, "From Desc", -1)),
            _createVNode(_component_InputText, {
              ref: "from_desc",
              class: "scan-input-text",
              modelValue: _ctx.shopTransactionPayload.from_desc,
              "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.shopTransactionPayload.from_desc) = $event)),
              onFocus: _cache[18] || (_cache[18] = ($event: any) => ($event.target.select())),
              onKeypress: _withKeys(_ctx.handleFromDesc, ["enter"]),
              onKeydown: [
                _withKeys(_ctx.handleFromDesc, ["down"]),
                _cache[19] || (_cache[19] = _withKeys(($event: any) => (_ctx.focusInput('from_oper')), ["up"]))
              ]
            }, null, 8, ["modelValue", "onKeypress", "onKeydown"])
          ], 2),
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.rowClass)
          }, [
            _cache[36] || (_cache[36] = _createElementVNode("span", { class: "p-inputgroup-addon" }, "To Oper", -1)),
            _createVNode(_component_InputText, {
              ref: "to_oper",
              class: "scan-input-text",
              modelValue: _ctx.shopTransactionPayload.to_oper,
              "onUpdate:modelValue": _cache[20] || (_cache[20] = ($event: any) => ((_ctx.shopTransactionPayload.to_oper) = $event)),
              onFocus: _cache[21] || (_cache[21] = ($event: any) => ($event.target.select())),
              onKeypress: _withKeys(_ctx.handleToOperation, ["enter"]),
              onKeydown: [
                _withKeys(_ctx.handleToOperation, ["down"]),
                _cache[22] || (_cache[22] = _withKeys(($event: any) => (_ctx.focusInput('from_desc')), ["up"]))
              ]
            }, null, 8, ["modelValue", "onKeypress", "onKeydown"])
          ], 2),
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.rowClass)
          }, [
            _cache[37] || (_cache[37] = _createElementVNode("span", { class: "p-inputgroup-addon" }, "To WC", -1)),
            _createVNode(_component_InputText, {
              ref: "to_wc",
              class: "scan-input-text",
              modelValue: _ctx.shopTransactionPayload.to_wc,
              "onUpdate:modelValue": _cache[23] || (_cache[23] = ($event: any) => ((_ctx.shopTransactionPayload.to_wc) = $event)),
              onFocus: _cache[24] || (_cache[24] = ($event: any) => ($event.target.select())),
              onKeypress: _withKeys(_ctx.handleToWorkCenter, ["enter"]),
              onKeydown: [
                _withKeys(_ctx.handleToWorkCenter, ["down"]),
                _cache[25] || (_cache[25] = _withKeys(($event: any) => (_ctx.focusInput('to_oper')), ["up"]))
              ]
            }, null, 8, ["modelValue", "onKeypress", "onKeydown"])
          ], 2),
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.rowClass)
          }, [
            _cache[38] || (_cache[38] = _createElementVNode("span", { class: "p-inputgroup-addon" }, "To Desc", -1)),
            _createVNode(_component_InputText, {
              ref: "to_desc",
              class: "scan-input-text",
              modelValue: _ctx.shopTransactionPayload.to_desc,
              "onUpdate:modelValue": _cache[26] || (_cache[26] = ($event: any) => ((_ctx.shopTransactionPayload.to_desc) = $event)),
              onFocus: _cache[27] || (_cache[27] = ($event: any) => ($event.target.select())),
              onKeypress: _withKeys(_ctx.handleToDesc, ["enter"]),
              onKeydown: [
                _withKeys(_ctx.handleToDesc, ["down"]),
                _cache[28] || (_cache[28] = _withKeys(($event: any) => (_ctx.focusInput('to_wc')), ["up"]))
              ]
            }, null, 8, ["modelValue", "onKeypress", "onKeydown"])
          ], 2),
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.rowClass)
          }, [
            _createVNode(_component_Button, {
              label: "Save",
              loading: _ctx.loadingSaveButton,
              onClick: _ctx.processShopTransaction,
              class: "scan-input-button"
            }, null, 8, ["loading", "onClick"])
          ], 2),
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.rowClass)
          }, [
            _createVNode(_component_Button, {
              label: "Exit",
              onClick: _ctx.handleExit,
              class: "scan-input-button"
            }, null, 8, ["onClick"])
          ], 2)
        ])
      ])
    ]),
    _createVNode(_component_MessageBox, {
      showDialog: _ctx.showErrorDialog,
      icon: "INFO",
      header: "Error",
      message: _ctx.errorMessage,
      primaryButton: "OK",
      onPrimaryButtonClick: _ctx.clickConfirmErrorDialog
    }, null, 8, ["showDialog", "message", "onPrimaryButtonClick"])
  ], 64))
}
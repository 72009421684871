import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "bar-chart" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data, (value, index) => {
      return _withDirectives((_openBlock(), _createElementBlock("div", {
        key: index,
        class: "bar pr-3 xl:pr-0",
        style: _normalizeStyle({ height: `${((value as number) / _ctx.getMax) * 100}%` })
      }, _cache[0] || (_cache[0] = [
        _createElementVNode("div", { class: "border-right-dashed" }, null, -1)
      ]), 4)), [
        [
          _directive_tooltip,
          _ctx.formatValue(value as string),
          void 0,
          { right: true }
        ]
      ])
    }), 128))
  ]))
}
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "grid p-0 m-0" }
const _hoisted_2 = { class: "flex justify-content-center gap-2" }
const _hoisted_3 = { class: "flex justify-content-center gap-2" }
const _hoisted_4 = {
  key: 0,
  class: "w-full flex justify-content-center align-items-center spinner-wrapper",
  style: {"height":"100vh"}
}
const _hoisted_5 = {
  key: 1,
  class: "col-12 p-0 m-0"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_Dialog = _resolveComponent("Dialog")!
  const _component_ProgressSpinner = _resolveComponent("ProgressSpinner")!
  const _component_LoadingSpinner = _resolveComponent("LoadingSpinner")!
  const _component_PosForm = _resolveComponent("PosForm")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Dialog, {
      visible: _ctx.expiredRegSession,
      "onUpdate:visible": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.expiredRegSession) = $event)),
      modal: "",
      closable: false,
      header: "Register Session Expired",
      style: { width: '25rem' }
    }, {
      default: _withCtx(() => [
        _cache[2] || (_cache[2] = _createElementVNode("span", { class: "text-center block mb-5" }, "Close the register to continue.", -1)),
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_Button, {
            label: "Close Register",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.closeClearRegister()))
          })
        ])
      ]),
      _: 1
    }, 8, ["visible"]),
    _createVNode(_component_Dialog, {
      visible: _ctx.getValidationLoading,
      closable: false,
      modal: "",
      class: "loadingDialog"
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_ProgressSpinner)
        ])
      ]),
      _: 1
    }, 8, ["visible"]),
    (_ctx.closingReg)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createVNode(_component_LoadingSpinner, { loading: _ctx.closingReg }, null, 8, ["loading"])
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createVNode(_component_PosForm, { ref: "posForm" }, null, 512)
        ]))
  ]))
}
import store from "@/store";
import Customer, { ShipItem, TaxItems, shipAddress } from "@/types/customer";
import { fieldFilter } from "@/types/fieldFilter";
import pako from "pako";
import router from "@/router";
import InvoiceService from "@/services/InvoiceService";
import SalesOrder, { LisItems } from "@/types/salesorder";
import Part from "@/types/part";
import Price from "@/types/price";
import Stax from "@/types/stax";
import { Fdict, Field } from "@/types/fdict";
import { FDICT_SO } from "./fdicts/so";
import PO, { LineNumberItem as PoLineNumberItem } from "@/types/po";
import {
  LineNumberItem as ReceiptsLineNumberItem,
  ShipToItem,
  VendorPartNoItem,
} from "@/types/receipts";
import Receipts from "@/types/receipts";
import { NotificationType } from "@/types/notification";
const invoiceService = new InvoiceService();

export default class Utils {
  /**
   * This method will return the date (string or date object) passed in the format MM-DD-YY
   * @param date - The date to format. Can be a string or a date object
   * @returns A string in the rover date format (MM-DD-YY)
   */
  static formatDate(date: Date | string, fullYear = false): string {
    if (!date) {
      return "";
    }
    if (date instanceof Date) {
      return this.roverDateString(date, fullYear);
    } else {
      if (date.includes("-") && !date.includes(":")) {
        date = date.replaceAll("-", "/");
      }
      const d = new Date(date);
      return this.roverDateString(d, fullYear);
    }
  }

  /**
   * This method will return the status based on the code passed
   * @param status - The string to format. Can be a string with a status code
   * @returns the full status name
   */
  static formatStatus(status: string) {
    switch (status) {
      case "N":
        return "New";
      case "B":
        return "Back Order";
      case "C":
        return "Closed";
      default:
        return status;
    }
  }

  /**
   * Returns the date and will shift by the number of days passed in the parameter
   *
   * @param day - The number of days to shift the current date by
   * @returns The date object shifted by the number of days passed in the parameter
   */
  static getShiftedDay(day = 1) {
    const date = new Date();
    date.setDate(date.getDate() + day);
    return date;
  }

  /**
   * Returns a stringified date in the rover format (MM-DD-YY)
   * @param date - The date to format.
   * @returns A string in the rover date format (MM-DD-YY)
   */
  static roverDateString(date: Date, fullYear = false) {
    const month = (date.getMonth() + 1).toString();
    const day = date.getDate().toString();
    const year = date.getFullYear().toString();
    return `${month.padStart(2, "0")}-${day.padStart(2, "0")}-${
      fullYear ? year : year.substring(2, 4)
    }`;
  }

  static handleLeadingDecimal(event: any, updateInputFieldCallback: any) {
    let value = event.value;
    if (event.originalEvent.key === "." && (value === null || value === "")) {
      value = "0.";
      updateInputFieldCallback(value);
      setTimeout(() => {
        event.originalEvent.target.setSelectionRange(2, 2); // Set cursor position after the decimal point
      }, 0);
    } else {
      updateInputFieldCallback(value);
    }
  }

  static roverTimeString(date: Date) {
    const hours = date.getHours().toString();
    const minutes = date.getMinutes().toString();
    const seconds = date.getSeconds().toString();
    return `${hours.padStart(2, "0")}:${minutes.padStart(
      2,
      "0",
    )}:${seconds.padStart(2, "0")}`;
  }

  static formatTime(date: Date | string) {
    if (date instanceof Date) return this.roverTimeString(date);
    else if (date.includes("-") && !date.includes(":")) {
      date = date.replaceAll("-", "/");
    }
    return this.roverTimeString(new Date(date));
  }

  static formatCompleteTimeRover(date: Date | string) {
    if (date instanceof Date) {
      return date.toTimeString().split(" ")[0];
    } else {
      if (date.includes("-") && !date.includes(":")) {
        date = date.replaceAll("-", "/");
      }
      return new Date(date).toTimeString().split(" ")[0];
    }
  }

  static formatDateToRoverTime(date: Date | string, time: string) {
    let formatedTime = "";
    if (time) {
      if (date instanceof String) {
        if (date.includes("-") && !date.includes(":")) {
          date = date.replaceAll("-", "/");
        }
      }
      formatedTime = Utils.formatCompleteTimeRover(
        new Date(`${Utils.formatDate(date)} ${time}`),
      );
    }

    if (formatedTime === "Invalid Date") {
      return "";
    } else {
      return formatedTime;
    }
  }

  static convertRoverTimeToDate(time: string) {
    if (time) {
      const timeArray = time.split(":");
      const date = new Date();
      date.setHours(parseInt(timeArray[0]));
      date.setMinutes(parseInt(timeArray[1]));
      date.setSeconds(parseInt(timeArray[2]));
      return date;
    } else {
      return null;
    }
  }

  static formatRoverTime(date: Date | string, time: string) {
    let formatedTime = "";
    if (time) {
      if (date instanceof String) {
        if (date.includes("-") && !date.includes(":")) {
          date = date.replaceAll("-", "/");
        }
      }
      formatedTime = Utils.formatTime(
        new Date(`${Utils.formatDate(date)} ${time}`),
      );
    }

    if (formatedTime === "Invalid Date") {
      return "";
    } else {
      return formatedTime;
    }
  }

  static formatDictionaryName(name: string) {
    if (name) {
      return name
        .split(/[.]|_/)
        .map((word) => {
          const w = word.toLowerCase();
          return w[0].toUpperCase() + w.substring(1);
        })
        .join(" ");
    } else {
      ("");
    }
  }

  static formatPrice(amount: string | number) {
    let amt = typeof amount === "string" ? parseFloat(amount) : amount;
    if (amt && typeof amt === "number") {
      return amt.toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    } else {
      if (isNaN(amt)) {
        amt = 0;
      }
      return amt.toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    }
  }

  // Method to validate if a scan input based on suffix/prefix
  // If toggle is true, only allow scanned input
  // If toggle is false, block scanned input
  static validateScanInput(input: string, toggle: boolean) {
    const scanSettings = store.getters["scan/getScanSettings"];
    if (typeof input !== "string") {
      return input;
    }

    input = input.trim();

    if (scanSettings.scanMode) {
      if (toggle) {
        if (scanSettings.prefix) {
          if (!input.startsWith(scanSettings.prefix)) {
            input = "";
          } else {
            input = input.substring(1);
          }
        }

        if (scanSettings.suffix) {
          if (!input.endsWith(scanSettings.suffix)) {
            input = "";
          } else {
            input = input.substring(0, input.length - 1);
          }
        }
      } else {
        if (scanSettings.prefix && input.startsWith(scanSettings.prefix)) {
          input = "";
        }

        if (scanSettings.suffix && input.endsWith(scanSettings.suffix)) {
          input = "";
        }
      }
    }
    return input;
  }

  static reduceTotal(objectArray: any[], property: string): number {
    if (!objectArray) {
      return 0;
    }
    let total = [];
    total = objectArray.map((elem) => elem[property] || 0);
    return total.reduce(
      (accumulator, currentValue) =>
        parseFloat(accumulator) + parseFloat(currentValue),
      0,
    );
  }

  static formatCharToLineBreak(text: string): string {
    if (text) {
      return text.replaceAll("^", "\n");
    } else {
      return text;
    }
  }

  static formatExpDateString(dateString: string | undefined): string {
    if (!dateString || dateString.length < 4 || dateString.length > 5) {
      return "";
    }

    if (
      dateString.length === 4 &&
      !dateString.includes("/") &&
      !dateString.includes("-")
    ) {
      return dateString.substring(0, 2) + "/" + dateString.substring(2, 4);
    } else {
      if (dateString[2] === "/") {
        return dateString;
      } else if (dateString[2] === "-") {
        return dateString.replace("-", "/");
      }
      return "";
    }
  }

  static base64ToArrayBuffer(data: string) {
    const bString = window.atob(data);
    const bLength = bString.length;
    const bytes = new Uint8Array(bLength);
    for (let i = 0; i < bLength; i++) {
      const ascii = bString.charCodeAt(i);
      bytes[i] = ascii;
    }
    return bytes;
  }

  static zipBase64Encode(str: string) {
    const decodedData = Uint8Array.from(atob(str), (c) => c.charCodeAt(0));

    const zipped: Uint8Array = pako.gzip(decodedData);
    const len = zipped.byteLength;
    let zippedString = "";
    for (let i = 0; i < len; i++) {
      zippedString += String.fromCharCode(zipped[i]);
    }
    const b64 = btoa(zippedString);
    return b64;
  }

  static base64DecodeUnzip(str: string) {
    if (str) {
      const decoded = atob(str);
      const len = decoded.length;
      const bytes = new Uint8Array(len);
      for (let i = 0; i < len; i++) {
        bytes[i] = decoded.charCodeAt(i);
      }
      const unzipped = pako.ungzip(bytes);
      const lenUnzipped = unzipped.byteLength;
      let unzippedString = "";
      for (let i = 0; i < lenUnzipped; i++) {
        unzippedString += String.fromCharCode(unzipped[i]);
      }
      const b64 = btoa(unzippedString);
      return b64;
    }
    return "";
  }

  static splitStringInTwo(str: string, separator: string) {
    if (str) {
      const index = str.indexOf(separator);
      if (index === -1) {
        return [str, ""];
      } else {
        return [str.substring(0, index), str.substring(index + 1)];
      }
    } else {
      return ["", ""];
    }
  }

  static convertOrderShippingAddressToArray(order: SalesOrder): Array<string> {
    let s: Array<string> = [];
    const addressItems = order.ship_address_items || [];
    s = (addressItems?.map((item) => item.ship_address) as string[]) || [];
    const city = order.ship_city || "";
    const state = order.ship_state || "";
    const zip = order.ship_zip || "";
    const country = order.ship_country || "";
    const validCityStateZip = city && state && zip;
    if (validCityStateZip) {
      s.push(city + ", " + state + " " + zip);
    }

    if (country) {
      s.push(country);
    }

    return s;
  }

  static customerShippingAddress(customer: Customer): Array<string> {
    let s: Array<string> = [];
    const shippingAddress = customer.ship_seq_items;
    const validShippingAddress =
      shippingAddress &&
      shippingAddress.length > 0 &&
      shippingAddress[0].ship_to_items &&
      shippingAddress[0].ship_to_items.length > 0;
    if (validShippingAddress) {
      const shipAddress = shippingAddress[0];
      s =
        (shipAddress?.ship_to_items.map((item) => item.ship_to) as string[]) ||
        [];
      const city = shipAddress.ship_city || "";
      const state = shipAddress.ship_state || "";
      const zip = shipAddress.ship_zip || "";
      const country = shipAddress.ship_country || "";
      const validCityStateZip = city && state && zip;
      if (validCityStateZip) {
        s.push(city + ", " + state + " " + zip);
      }

      if (country) {
        s.push(country);
      }
    } else {
      const addressItems = customer.address_items;
      s = (addressItems?.map((item) => item.address) as string[]) || [];
      const city = customer.sort_city || "";
      const state = customer.sort_state || "";
      const zip = customer.sort_zip || "";
      const country = customer.sort_country || "";
      const validCityStateZip = city && state && zip;
      if (validCityStateZip) {
        s.push(city + ", " + state + " " + zip);
      }

      if (country) {
        s.push(country);
      }
    }

    return s;
  }

  static compareTwoObjects<T>(
    newObj: any,
    oldObj: any,
    ignore_array: string[],
  ): boolean {
    const date = new Date();
    if (
      newObj === null ||
      newObj === undefined ||
      oldObj === null ||
      oldObj === undefined
    ) {
      return true;
    }
    return Object.keys(newObj).some((field: string) => {
      if (ignore_array.includes(field)) {
        return false;
      } else if (!(field in newObj) || !(field in oldObj)) {
        // If the field is not in both objects check if the field evaluates to a real value
        // This case handle is we need to initialize an array field but we don't want to trigger a change
        if (field in newObj && newObj[field] == 0) {
          return false;
        }
        return true;
      }

      const newObjField = newObj[field as keyof T];
      const oldObjField = oldObj[field as keyof T];

      if (field.includes("date") && newObjField != null) {
        const newDate = Utils.formatDate(newObjField as string);
        const oldDate = Utils.formatDate(oldObjField as string);
        return newDate != oldDate;
      } else if (field.includes("time") && newObjField != null) {
        const newDate = Utils.formatRoverTime(date, newObjField as string);
        const oldDate = Utils.formatRoverTime(date, oldObjField as string);
        return newDate != oldDate;
      } else if (typeof newObjField === "object") {
        if (Array.isArray(newObjField)) {
          if (newObjField.length != oldObjField.length) {
            return true;
          }
        }
        return Utils.compareTwoObjects<typeof field>(
          newObjField,
          oldObjField,
          ignore_array,
        );
      } else {
        return newObjField != oldObjField;
      }
    });
  }

  static filterObjectAdd(
    filtersObject: { filters: fieldFilter[] },
    fieldname: string,
    compare: string,
    value: string,
  ) {
    filtersObject.filters.push({
      fieldname: fieldname,
      value: value,
      compare: compare,
    });
  }

  static setCookie(name: string, value: boolean, days = 1): void {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    const expires = "expires=" + date.toUTCString();
    document.cookie = name + "=" + value + ";" + expires + ";path=/";
  }

  static getCookie(name: string): string {
    const cookieName = name + "=";
    const cookies = document.cookie.split(";");
    for (let i = 0; i < cookies.length; i++) {
      let cookie = cookies[i];
      while (cookie.charAt(0) === " ") {
        cookie = cookie.substring(1);
      }
      if (cookie.indexOf(cookieName) === 0) {
        return cookie.substring(cookieName.length, cookie.length);
      }
    }
    return "";
  }

  static updateCookie(name: string, newValue: boolean, days = 1): void {
    const existingValue = this.getCookie(name);

    if (existingValue !== "") {
      this.setCookie(name, newValue, days);
    }
  }

  static getDisplayFormat(error: any): boolean {
    if (error?.response?.data?.errorDisplay) {
      return error?.response?.data?.errorDisplay?.dialog;
    } else if (error?.data?.errorDisplay) {
      return error.data?.errorDisplay.dialog;
    } else {
      return false;
    }
  }

  static getCloseLink(error: any) {
    if (error?.response?.data?.errorDisplay?.closeLink) {
      return error?.response?.data?.errorDisplay?.closeLink;
    } else if (error?.data?.errorDisplay?.closeLink) {
      return error?.data?.errorDisplay?.closeLink;
    } else {
      return null;
    }
  }

  static getNotificationType(error: any) {
    if (error?.response?.data?.errorDisplay?.warning) {
      return NotificationType.WARN;
    } else if (error?.data?.errorDisplay?.warning) {
      return NotificationType.WARN;
    } else if (error?.data?.errorDisplay?.confirmvalidate) {
      return NotificationType.CONFIRM_VALIDATE_OVERRIDE;
    } else {
      return null;
    }
  }
  static parseErrorMessage(error: any): string | Array<string> {
    if (error?.response?.data?.errorDisplay?.errors) {
      return error.response?.data.errorDisplay.errors;
    } else if (
      error?.response?.data?.error &&
      typeof error.response.data.error === "string"
    ) {
      return error.response.data.error;
    } else if (error?.response?.data?.message) {
      return error.response.data.message;
    } else if (error?.data?.errorDisplay?.errors) {
      return error?.data?.errorDisplay.errors;
    } else if (error?.error) {
      return error.error;
    } else if (error?.message) {
      return error.message;
    } else {
      return "";
    }
  }

  static parseSuccessMessage(response: any): string | Array<string> {
    if (response?.response?.data?.message) {
      return response.response.data.message;
    } else if (response?.data?.message) {
      return response.data.message;
    } else {
      return "";
    }
  }

  static until(conditionFunction: any) {
    const poll = (resolve: any) => {
      if (conditionFunction()) resolve();
      else setTimeout((_: any) => poll(resolve), 400);
    };

    return new Promise(poll);
  }

  static async invoiceSearch(
    inv: any,
    setLoading: any,
    handleContinueShopping: any,
    successCallback: () => void,
    cycle: number,
    numCycles: number,
  ) {
    await invoiceService
      .setInvoices(
        1,
        1,
        store.getters["pos/getCustomer"].cust_id,
        store.getters["session/getClient"],
        "",
        "",
        "",
        "",
        inv,
        "",
        "so_total so_status",
      )
      .then((invResponse: any) => {
        if (invResponse.ar_items?.length > 0) {
          // add to order
          let invList = JSON.parse(
            JSON.stringify(
              store.getters["pos/getStoredCartItems"].orderInvoice || [],
            ),
          );
          const inv_record = JSON.parse(
            JSON.stringify(invResponse.ar_items[0]),
          );
          if (
            parseFloat(inv_record.balance) < 0 &&
            store.getters["mrkControl/posAllowPayout"]
          ) {
            //need to prompt for a note
            inv_record.type = "PO";
            invList = [inv_record];
          } else {
            inv_record.type = "IN";
            invList.push(inv_record);
          }

          store.dispatch("pos/setStoredCartItems", {
            items: JSON.parse(JSON.stringify(invList)),
            type: "orderInvoice",
          });
          // route to payment
          setLoading();
          successCallback();
        } else if (cycle === numCycles - 1) {
          setLoading();
          handleContinueShopping();
        }
      });
  }

  static async selectOrderInvoice(
    response: any,
    setLoading: any,
    handleContinueShopping: any,
  ) {
    let cycle = 0;
    const numCycles = 5;
    let success = false;

    while (cycle < numCycles && !success) {
      if (response.data) {
        if (response.data.invoice && response.data.payment_req === "Y") {
          await this.invoiceSearch(
            response.data.invoice,
            setLoading,
            handleContinueShopping,
            () => (success = true),
            cycle,
            numCycles,
          );
        } else if (
          response.record?.invoice_no &&
          store.getters["mrkControl/postInvoiceOnShip"]
        ) {
          await this.invoiceSearch(
            response.record.invoice_no,
            setLoading,
            handleContinueShopping,
            () => (success = true),
            cycle,
            numCycles,
          );
        } else {
          success = true;
          setLoading();
          handleContinueShopping();
        }
      } else if (
        response.record?.invoice_no &&
        store.getters["mrkControl/postInvoiceOnShip"]
      ) {
        await this.invoiceSearch(
          response.record.invoice_no,
          setLoading,
          handleContinueShopping,
          () => (success = true),
          cycle,
          numCycles,
        );
      } else {
        success = true;
        setLoading();
        handleContinueShopping();
      }

      cycle += 1;

      if (!success && cycle! < numCycles) {
        await new Promise((r) => setTimeout(r, 2000));
      }
    }
  }

  static formatAmount(
    value: string,
    roundTotals = false,
    useCompactNotation = true,
  ): string {
    let amt = typeof value === "string" ? parseFloat(value) : value;
    if (amt && typeof amt === "number") {
      return amt.toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
        notation: useCompactNotation ? "compact" : "standard",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        maximumSignificantDigits: roundTotals ? 4 : undefined,
      });
    } else {
      if (isNaN(amt)) {
        amt = 0;
      }
      return amt.toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
        notation: useCompactNotation ? "compact" : "standard",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        maximumSignificantDigits: roundTotals ? 4 : undefined,
      });
    }
  }

  static addPartToOrder({
    order,
    part,
    quantity,
    price,
  }: {
    order: SalesOrder;
    part: Price;
    quantity: number;
    price: string;
  }) {
    const partToAdd = {} as LisItems;
    const id = Utils.getNewSalesOrderLineItemId(order);
    partToAdd.lis = id;
    partToAdd.li_parts = part.part_number;
    partToAdd.li_order_qtys = quantity.toString();
    partToAdd.li_prices = price;
    partToAdd.wrap_desc = part.desc || "";

    if (order.lis_items) {
      order.lis_items.push(partToAdd);
    } else {
      order.lis_items = [partToAdd];
    }
  }

  static getCustomerMailAddress(customer: Customer): ShipItem {
    const address: shipAddress[] = customer.address_items?.map((item: any) => {
      return {
        ship_to: item.address,
      };
    }) || [{ ship_to: "" }];

    const shipItem = new ShipItem();
    shipItem.ship_seq = "CUST";
    shipItem.ship_city = customer.sort_city || "";
    shipItem.ship_state = customer.sort_state || "";
    shipItem.ship_zip = customer.sort_zip || "";
    shipItem.ship_country = customer.sort_country || "";
    shipItem.ship_to_items = address;
    shipItem.ship_name = customer.name || "";
    shipItem.ship_attn = "";

    return shipItem;
  }

  static initNewOrderFromCustomer(
    customer: Customer,
    company_code: string,
    tax_codes: Stax[] = [],
  ): SalesOrder {
    const order = new SalesOrder();
    order.co_code = company_code;
    order.date = Utils.formatDate(Utils.getShiftedDay());
    order.status = "N";
    order.book_date = Utils.formatDate(new Date());
    order.sold_to = customer.cust_id as string;
    order.ship_via = customer.ship_via || "";
    Utils.setOrderAddress(customer, order, null, tax_codes);
    return order;
  }

  static setOrderAddress(
    customer: Customer,
    order: SalesOrder,
    address?: any,
    tax_codes: Stax[] = [],
  ) {
    if (!address) {
      if (customer.ship_seq_items && customer.ship_seq_items.length > 0) {
        address = customer.ship_seq_items[0];
      } else {
        address = null;
      }
    }

    if (!address) {
      order.ship_address_items = [];
      if (customer.address_items && customer.address_items.length > 0) {
        order.ship_address_items = customer.address_items.map((item: any) => {
          return {
            ship_address: item.address,
          };
        });
      }

      if (!order.ship_address_items) {
        order.ship_address_items = [{ ship_address: "" }];
      }

      order.ship_seq = "CUST";
      order.ship_city = customer.sort_city || "";
      order.ship_state = customer.sort_state || "";
      order.ship_zip = customer.sort_zip || "";
      order.ship_country = customer.sort_country || "";
      order.attn = "";
      order.ship_name = customer.name || "";
      order.ship_phone = "";
      order.contact_email = "";
      order.tax_codes_items = [];
      if (customer.tax_codes_items && customer.tax_codes_items.length > 0) {
        order.tax_codes_items = customer.tax_codes_items.map((item: any) => {
          const taxCode = tax_codes.find(
            (code: Stax) => code.id === item.tax_codes,
          );
          return {
            tax_codes: item.tax_codes,
            tax_code_rate: taxCode?.rate || "",
          };
        });
      }

      return;
    }

    order.ship_address_items = [];
    if (address.ship_to_items && address.ship_to_items.length > 0) {
      order.ship_address_items = address.ship_to_items.map((item: any) => {
        return {
          ship_address: item.ship_to,
        };
      });
    }

    if (!order.ship_address_items) {
      order.ship_address_items = [{ ship_address: "" }];
    }

    order.ship_seq = address.ship_seq;
    order.ship_city = address.ship_city;
    order.ship_state = address.ship_state;
    order.ship_zip = address.ship_zip;
    order.ship_country = address.ship_country;
    order.attn = address.ship_attn;
    order.ship_name = address.ship_name;
    order.ship_phone = address.ship_phone;
    order.contact_email = address.ship_email;
    order.tax_codes_items = [];
    if (address.ship_to_tax_items && address.ship_to_tax_items.length > 0) {
      order.tax_codes_items = address.ship_to_tax_items.map((item: any) => {
        const taxCode = tax_codes.find(
          (code: Stax) => code.id === item.ship_to_tax,
        );
        return {
          tax_codes: item.ship_to_tax,
          tax_code_rate: taxCode?.rate || "",
        };
      });
    }
  }

  static getNewSalesOrderLineItemId(order: SalesOrder): string {
    const lineItemIds =
      order.lis_items?.map((item: LisItems) => item.lis) || [];
    let newId = (lineItemIds.length + 1).toString();

    while (lineItemIds.includes(newId)) {
      newId = (parseInt(newId) + 1).toString();
    }

    return newId;
  }

  static salesOrderSetCustomFields(order: SalesOrder) {
    const customFields: Field[] = store.getters["fdict/customFields"]("SO");

    if (!order.custom_fields) {
      order.custom_fields = {};
    }

    customFields.forEach((field) => {
      const json_name: string = field.json_name as string;
      if (!(json_name in order) && json_name in order.custom_fields) {
        // If the field is not in the order object but is in the custom fields object, set the field to the custom field value
        order[field.json_name as string] =
          order.custom_fields[field.json_name as string];
      }

      if (!field.multi_line || field.multi_line !== "Y") {
        order.custom_fields[field.json_name as string] =
          field.default_selection || "";
      } else {
        // Initialize multifield to an empty array
        order.custom_fields[field.json_name as string] = [];
      }

      if (order[field.json_name as any]) {
        order.custom_fields[field.json_name as string] =
          order[field.json_name as string];
      }

      if (!order.custom_fields[field.json_name as string]) {
        order.custom_fields[field.json_name as string] =
          field.default_selection || "";
      }
    });
  }

  static salesOrderLineItemsSetCustomFields(order: SalesOrder) {
    const customFields: Field[] = store.getters["fdict/customFields"](
      "SO",
      FDICT_SO.LIS,
    );

    order.lis_items?.forEach((item: LisItems) => {
      customFields.forEach((field) => {
        if (!item.custom_fields) {
          item.custom_fields = {};
        } else if (
          !item[field.json_name as string] &&
          item.custom_fields[field.json_name as string]
        ) {
          // If the field is not in the order object but is in the custom fields object, set the field to the custom field value
          item[field.json_name as string] =
            item.custom_fields[field.json_name as string];
        }

        if (!field.multi_line || field.multi_line !== "Y") {
          item.custom_fields[field.json_name as string] =
            field.default_selection || "";
        } else {
          // Initialize multifield to an empty array
          item.custom_fields[field.json_name as string] = [];
        }

        if (item[field.json_name as any]) {
          item.custom_fields[field.json_name as string] =
            item[field.json_name as string];
        }
      });
    });
  }

  static reorderSalesOrderLineItemsIds(order: SalesOrder) {
    order.lis_items?.forEach((item: LisItems, index: number) => {
      item.lis = (index + 1).toString();
    });
  }

  static getColorByIndex(index: number): string {
    const colors = [
      "#D3CCE4",
      "#CDDDF0",
      "#91AAE5",
      "#8CD3E8",
      "#F26722",
      "#fcf1c8",
      "#f7e2c8",
      "#57B6FA",
      "#1ACEFA",
      "#55ECFA",
    ];
    return colors[Math.floor(index % 10)];
  }

  static getSortFiltersToStore({
    filters,
    sortField = "",
    sortOrder = 0,
  }: {
    filters?: any;
    sortField?: string;
    sortOrder?: number;
  }) {
    // We use the filters object used on the DataTable to get the filters
    const filtersToStore: any = {};
    Object.entries(filters).forEach(([key, value]: any) => {
      if (value.value.length > 0) {
        filtersToStore[key] = value.value;
      }
    });

    const sortFiltersToStore = {
      filters: filtersToStore,
      sort: {
        sortField: sortField,
        sortOrder: sortOrder,
      },
    };

    return sortFiltersToStore;
  }

  // Method to convert a fdict object to a JSON object
  // Add to the fdict folder to use as a constant string
  static convertFdictToJSON(fdict: Fdict) {
    const obj = {} as any;
    fdict.field_no_items.forEach((field) => {
      const str = field.dict_name.replaceAll(".", "_").toUpperCase() as string;
      obj[str] = field.field_no;
    });
    return JSON.stringify(obj);
  }

  static booleanCheck(value?: string) {
    if (!value) {
      return false;
    }

    const options = ["Y", "YES", "TRUE", "1"];
    return options.includes(value.toUpperCase());
  }
}

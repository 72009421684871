import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "col-12 h-full p-0 m-0"
}
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.dashboard.url)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("iframe", {
            src: _ctx.dashboard.url,
            frameborder: "0",
            style: {"border":"0","width":"100%","height":"100%","overflow":"hidden"},
            allowfullscreen: ""
          }, null, 8, _hoisted_2)
        ]))
      : _createCommentVNode("", true),
    _cache[0] || (_cache[0] = _createTextVNode("   "))
  ], 64))
}

import { defineComponent } from "vue";
import { mapActions } from "pinia";
import { mapState } from "vuex";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Button from "primevue/button";
import Alloc from "@/types/alloc";

import { useInventoryStore } from "@/store/ui/inventoryModule";

export default defineComponent({
  props: {
    partNumber: {
      type: String,
      default: "",
    },
  },
  components: {
    DataTable,
    Column,
    Button,
  },
  computed: {
    ...mapState({
      getClient: "session/getClient",
    }),
  },
  data() {
    return {
      allocationInfo: {} as Alloc,
      dateInfo: [] as any[],
      isLoadingAllocations: false,
      rowsPerPage: 10,
      page: 1,
      totalRecords: 0,
      first: 0,
      sortBy: "formatted_date",
      sortOrder: -1,
    };
  },
  async mounted() {
    await this.fetchAllocations();
  },
  methods: {
    ...mapActions(useInventoryStore, ["fetchAllocationsById"]),
    async fetchAllocations(refresh = false) {
      if (!this.partNumber) {
        return;
      }

      this.isLoadingAllocations = true;

      const resp = await this.fetchAllocationsById({
        id: this.partNumber,
        client: this.getClient,
        refresh,
      });

      if (resp.success && resp.alloc) {
        this.allocationInfo = resp.alloc;
        this.dateInfo = this.allocationInfo.date_items;
        this.dateInfo.map((item: any) => {
          item.formatted_date = item.date ? new Date(item.date) : null;
        });
      }
      this.isLoadingAllocations = false;
    },
  },
  watch: {
    partNumber() {
      this.fetchAllocations();
    },
  },
});

import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex justify-content-center" }
const _hoisted_2 = { class: "grid col-12 lg:col-10 justify-content-center" }
const _hoisted_3 = { class: "grid col-12 md:col-6 lg:col-4" }
const _hoisted_4 = { class: "grid col-12 md:col-6 lg:col-4" }
const _hoisted_5 = { class: "grid col-12 md:col-6 lg:col-4" }
const _hoisted_6 = {
  class: "grid col-12 justify-content-center text-6xl pb-3",
  style: {"display":"grid"}
}
const _hoisted_7 = { style: {"font-size":"3rem","justify-self":"center"} }
const _hoisted_8 = { class: "flex justify-content-center" }
const _hoisted_9 = { class: "grid col-12 lg:col-10 justify-content-center" }
const _hoisted_10 = { class: "grid col-12 justify-content-center text-green-500 text-6xl pb-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_Card = _resolveComponent("Card")!
  const _component_SendReceiptDialog = _resolveComponent("SendReceiptDialog")!
  const _component_PrinterSelectionDialog = _resolveComponent("PrinterSelectionDialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Card, null, {
      content: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _cache[7] || (_cache[7] = _createElementVNode("span", { class: "grid col-12 justify-content-center text-6xl pb-3" }, [
              _createElementVNode("i", {
                class: "pi pi-check-circle pr-3 pt-2 pl-2",
                style: {"font-size":"3rem"}
              }),
              _createTextVNode(" Payment Complete!")
            ], -1)),
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_Button, {
                label: "No Receipt",
                class: "m-3",
                style: {"width":"190px","height":"190px","justify-self":"center"},
                onClick: _ctx.noReceipt
              }, {
                default: _withCtx(() => _cache[2] || (_cache[2] = [
                  _createElementVNode("div", { class: "grid justify-content-center" }, [
                    _createElementVNode("i", {
                      class: "pi pi-times-circle col-12",
                      style: {"font-size":"7rem"}
                    }),
                    _createElementVNode("span", { class: "col-12" }, "No Receipt")
                  ], -1)
                ])),
                _: 1
              }, 8, ["onClick"])
            ]),
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_Button, {
                label: "Email Receipt",
                class: "m-3",
                style: {"width":"190px","height":"190px","justify-self":"center"},
                onClick: _cache[0] || (_cache[0] = ($event: any) => (this.displayEmailDialog = true))
              }, {
                default: _withCtx(() => _cache[3] || (_cache[3] = [
                  _createElementVNode("div", { class: "grid justify-content-center" }, [
                    _createElementVNode("i", {
                      class: "pi pi-at col-12",
                      style: {"font-size":"7rem"}
                    }),
                    _createElementVNode("span", { class: "col-12" }, "Email Receipt")
                  ], -1)
                ])),
                _: 1
              })
            ]),
            _createElementVNode("div", _hoisted_5, [
              (
                this.getReg !== null ||
                (this.allowMixedMode && this.hostReceiptPrint)
              )
                ? (_openBlock(), _createBlock(_component_Button, {
                    key: 0,
                    label: "Print Receipt",
                    class: "m-3",
                    style: {"width":"190px","height":"190px","justify-self":"center"},
                    onClick: _ctx.printReceipt
                  }, {
                    default: _withCtx(() => _cache[4] || (_cache[4] = [
                      _createElementVNode("div", { class: "grid justify-content-center" }, [
                        _createElementVNode("i", {
                          class: "pi pi-ticket col-12",
                          style: {"font-size":"7rem"}
                        }),
                        _createElementVNode("span", { class: "col-12" }, "Print Receipt")
                      ], -1)
                    ])),
                    _: 1
                  }, 8, ["onClick"]))
                : (_openBlock(), _createBlock(_component_Button, {
                    key: 1,
                    label: "Download Receipt",
                    class: "m-3",
                    style: {"width":"190px","height":"190px","justify-self":"center"},
                    onClick: _ctx.downloadReceipt
                  }, {
                    default: _withCtx(() => _cache[5] || (_cache[5] = [
                      _createElementVNode("div", { class: "grid justify-content-center" }, [
                        _createElementVNode("i", {
                          class: "pi pi-download col-12",
                          style: {"font-size":"7rem"}
                        }),
                        _createElementVNode("span", { class: "col-12" }, "Download Receipt")
                      ], -1)
                    ])),
                    _: 1
                  }, 8, ["onClick"]))
            ]),
            _createElementVNode("div", _hoisted_6, [
              _cache[6] || (_cache[6] = _createElementVNode("span", { style: {"font-size":"2rem","justify-self":"center"} }, " Receipt Number ", -1)),
              _createElementVNode("span", _hoisted_7, _toDisplayString(this.getTranId), 1)
            ])
          ])
        ])
      ]),
      _: 1
    }),
    (this.getChangeAmount)
      ? (_openBlock(), _createBlock(_component_Card, { key: 0 }, {
          content: _withCtx(() => [
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("div", _hoisted_9, [
                _createElementVNode("span", _hoisted_10, "Change Due: " + _toDisplayString(_ctx.formatPrice(this.getChangeAmount)), 1)
              ])
            ])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    _createVNode(_component_SendReceiptDialog, {
      visible: _ctx.displayEmailDialog,
      transId: _ctx.getTranId,
      onOnClose: _ctx.onHide,
      onOnSend: _ctx.onSent
    }, null, 8, ["visible", "transId", "onOnClose", "onOnSend"]),
    _createVNode(_component_PrinterSelectionDialog, {
      visible: _ctx.showPrinterDialog,
      heading: 'Select Receipt Printer',
      printers: _ctx.getPrinterQueues.serverPrinters,
      defaultPrinter: _ctx.getDefaultPickTicketPrinter,
      onOnPrintDialogPrint: _ctx.handleHostReceiptPrint,
      onOnPrintDialogCancel: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showPrinterDialog = false))
    }, null, 8, ["visible", "printers", "defaultPrinter", "onOnPrintDialogPrint"])
  ], 64))
}
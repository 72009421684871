import { resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, createVNode as _createVNode, withDirectives as _withDirectives, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "grid m-0 justify-content-center" }
const _hoisted_2 = { class: "col-12" }
const _hoisted_3 = { class: "flex justify-content-end align-items-center my-3" }
const _hoisted_4 = { class: "col-12" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_SalesQuotesDataTableWrapper = _resolveComponent("SalesQuotesDataTableWrapper")!
  const _component_SoQuoteDialog = _resolveComponent("SoQuoteDialog")!
  const _component_Card = _resolveComponent("Card")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _withDirectives(_createVNode(_component_Button, {
          class: "split-sales-order-btn",
          icon: "pi pi-filter-slash",
          outlined: "",
          onClick: _ctx.clearFilters
        }, null, 8, ["onClick"]), [
          [_directive_tooltip, 'Clear All Filters']
        ]),
        _createVNode(_component_Button, {
          class: "split-sales-order-btn",
          label: "Print/Export",
          icon: "pi pi-print",
          onClick: _ctx.printExportData
        }, null, 8, ["onClick"]),
        _createVNode(_component_Button, {
          style: {"margin":"5px"},
          label: "New Quote",
          icon: "pi pi-plus",
          "data-test": "newSalesQuote",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openSoQuote(false)))
        })
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_Card, null, {
        content: _withCtx(() => [
          _createVNode(_component_SalesQuotesDataTableWrapper, {
            ref: "salesQuotesTable",
            "customer-index": _ctx.index,
            "current-view": _ctx.currentView,
            onUpdateBadge: _cache[1] || (_cache[1] = 
              (field, value) =>
                _ctx.$emit('update-badge', field, value)
            ),
            maxExportRows: 1000,
            printExportData: _ctx.printExportData,
            clearFilters: _ctx.clearFilters
          }, null, 8, ["customer-index", "current-view", "printExportData", "clearFilters"]),
          _createVNode(_component_SoQuoteDialog, {
            quote: this.soQuote,
            show: this.showSoQuoteDialog,
            readOnly: this.readOnly,
            newQuote: this.newQuote,
            customer: _ctx.getCustomerFields,
            onHide: _cache[2] || (_cache[2] = 
              (hidden) => {
                this.soQuote = null;
                this.showSoQuoteDialog = hidden;
              }
            ),
            onOnSave: _ctx.fetchSaleQuotes
          }, null, 8, ["quote", "show", "readOnly", "newQuote", "customer", "onOnSave"])
        ]),
        _: 1
      })
    ])
  ]))
}
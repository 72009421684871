import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "grid" }
const _hoisted_3 = { class: "col-12 lg:col-4" }
const _hoisted_4 = { class: "p-inputgroup mt-2" }
const _hoisted_5 = { class: "col-12 lg:col-4" }
const _hoisted_6 = { class: "p-inputgroup mt-2" }
const _hoisted_7 = { class: "col-12 lg:col-4" }
const _hoisted_8 = { class: "p-inputgroup mt-2" }
const _hoisted_9 = { class: "col-12 lg:col-4" }
const _hoisted_10 = { class: "p-inputgroup mt-2" }
const _hoisted_11 = { class: "col-12 lg:col-4" }
const _hoisted_12 = { class: "p-inputgroup mt-2" }
const _hoisted_13 = { class: "col-12 lg:col-4" }
const _hoisted_14 = { class: "p-inputgroup mt-2" }
const _hoisted_15 = { key: 1 }
const _hoisted_16 = { class: "grid" }
const _hoisted_17 = { class: "col-12 lg:col-4" }
const _hoisted_18 = { class: "p-inputgroup mt-2" }
const _hoisted_19 = { class: "col-12 lg:col-4" }
const _hoisted_20 = { class: "p-inputgroup mt-2" }
const _hoisted_21 = { class: "col-12 lg:col-4" }
const _hoisted_22 = { class: "p-inputgroup mt-2" }
const _hoisted_23 = { class: "col-12 lg:col-4" }
const _hoisted_24 = { class: "p-inputgroup mt-2" }
const _hoisted_25 = { class: "col-12 lg:col-4" }
const _hoisted_26 = { class: "p-inputgroup mt-2" }
const _hoisted_27 = { class: "col-12 lg:col-4" }
const _hoisted_28 = { class: "p-inputgroup mt-2" }
const _hoisted_29 = { class: "col-12 lg:col-4" }
const _hoisted_30 = { class: "p-inputgroup mt-2" }
const _hoisted_31 = { class: "col-12 lg:col-4" }
const _hoisted_32 = { class: "p-inputgroup mt-2" }
const _hoisted_33 = { class: "col-12 lg:col-4" }
const _hoisted_34 = { class: "p-inputgroup mt-2" }
const _hoisted_35 = { class: "col-12 lg:col-4" }
const _hoisted_36 = { class: "p-inputgroup mt-2" }
const _hoisted_37 = { class: "col-12 lg:col-4" }
const _hoisted_38 = { class: "p-inputgroup mt-2" }
const _hoisted_39 = { class: "col-12 lg:col-4" }
const _hoisted_40 = { class: "p-inputgroup mt-2" }
const _hoisted_41 = { class: "col-12 lg:col-4" }
const _hoisted_42 = { class: "p-inputgroup mt-2" }
const _hoisted_43 = { class: "col-12 lg:col-4" }
const _hoisted_44 = { class: "p-inputgroup mt-2" }
const _hoisted_45 = { class: "col-12 lg:col-4" }
const _hoisted_46 = { class: "p-inputgroup mt-2" }
const _hoisted_47 = { class: "col-12 lg:col-4" }
const _hoisted_48 = { class: "p-inputgroup mt-2" }
const _hoisted_49 = { class: "col-12 lg:col-4" }
const _hoisted_50 = { class: "p-inputgroup mt-2" }
const _hoisted_51 = { class: "col-12 lg:col-4" }
const _hoisted_52 = { class: "p-inputgroup mt-2" }
const _hoisted_53 = { class: "col-12 lg:col-4" }
const _hoisted_54 = { class: "p-inputgroup mt-2" }
const _hoisted_55 = { class: "col-12 lg:col-4" }
const _hoisted_56 = { class: "p-inputgroup mt-2" }
const _hoisted_57 = { class: "col-12 lg:col-4" }
const _hoisted_58 = { class: "p-inputgroup mt-2" }
const _hoisted_59 = { class: "col-12 lg:col-4" }
const _hoisted_60 = { class: "p-inputgroup mt-2" }
const _hoisted_61 = { class: "col-12 lg:col-4" }
const _hoisted_62 = { class: "p-inputgroup mt-2" }
const _hoisted_63 = { class: "col-12 lg:col-4" }
const _hoisted_64 = { class: "p-inputgroup mt-2" }
const _hoisted_65 = { class: "col-12 lg:col-4" }
const _hoisted_66 = { class: "p-inputgroup mt-2" }
const _hoisted_67 = { class: "col-12 lg:col-4" }
const _hoisted_68 = { class: "p-inputgroup mt-2" }
const _hoisted_69 = { class: "col-12 lg:col-4" }
const _hoisted_70 = { class: "p-inputgroup mt-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputText = _resolveComponent("InputText")!
  const _component_TextArea = _resolveComponent("TextArea")!
  const _component_Calendar = _resolveComponent("Calendar")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createBlock(_component_Dialog, {
    header: this.header,
    visible: this.hidden,
    "onUpdate:visible": _cache[43] || (_cache[43] = ($event: any) => ((this.hidden) = $event)),
    onHide: _cache[44] || (_cache[44] = ($event: any) => (_ctx.$emit('hide', this.hidden))),
    style: { width: '1000px' },
    modal: true,
    draggable: false
  }, {
    footer: _withCtx(() => [
      _createVNode(_component_Button, {
        "data-test": "cancelDialogButton",
        ref: "cancelDialogButton",
        label: "Cancel",
        icon: "pi pi-times",
        onClick: _cache[41] || (_cache[41] = 
          () => {
            _ctx.$emit('hide', false);
          }
        ),
        class: "p-button-text"
      }, null, 512),
      _createVNode(_component_Button, {
        "data-test": "submitDialogButton",
        ref: "submitDialogButton",
        label: "Save",
        icon: "pi pi-check",
        onClick: _cache[42] || (_cache[42] = ($event: any) => (this.submit())),
        loading: this.submitLoading,
        disabled: this.readOnly,
        autofocus: ""
      }, null, 8, ["loading", "disabled"])
    ]),
    default: _withCtx(() => [
      (_ctx.BulkView)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                  _cache[45] || (_cache[45] = _createElementVNode("span", {
                    class: "p-inputgroup-addon",
                    style: { width: '103px' }
                  }, "Formula Part#", -1)),
                  _createVNode(_component_InputText, {
                    modelValue: this.salesOrder.custom_fields.FORMULA_PART_VIEW,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((this.salesOrder.custom_fields.FORMULA_PART_VIEW) = $event)),
                    disabled: this.readOnly
                  }, null, 8, ["modelValue", "disabled"])
                ])
              ]),
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("div", _hoisted_6, [
                  _cache[46] || (_cache[46] = _createElementVNode("span", {
                    class: "p-inputgroup-addon",
                    style: { width: '103px' }
                  }, "Gallons", -1)),
                  _createVNode(_component_InputText, {
                    modelValue: this.salesOrder.custom_fields.BULK_GALLONS,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((this.salesOrder.custom_fields.BULK_GALLONS) = $event)),
                    disabled: this.readOnly
                  }, null, 8, ["modelValue", "disabled"])
                ])
              ]),
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("div", _hoisted_8, [
                  _cache[47] || (_cache[47] = _createElementVNode("span", {
                    class: "p-inputgroup-addon",
                    style: { width: '103px' }
                  }, "Product Desc", -1)),
                  _createVNode(_component_TextArea, {
                    modelValue: this.salesOrder.custom_fields.PROD_DESC_VIEW,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((this.salesOrder.custom_fields.PROD_DESC_VIEW) = $event)),
                    disabled: this.readOnly
                  }, null, 8, ["modelValue", "disabled"])
                ])
              ]),
              _createElementVNode("div", _hoisted_9, [
                _createElementVNode("div", _hoisted_10, [
                  _cache[48] || (_cache[48] = _createElementVNode("span", {
                    class: "p-inputgroup-addon",
                    style: { width: '103px' }
                  }, "Day Last Used", -1)),
                  _createVNode(_component_Calendar, {
                    showIcon: true,
                    modelValue: this.salesOrder.custom_fields.BULK_LAST_USED_DATE,
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((this.salesOrder.custom_fields.BULK_LAST_USED_DATE) = $event)),
                    dateFormat: "mm-dd-yy",
                    manualInput: false,
                    onDateSelect: _cache[4] || (_cache[4] = ($event: any) => (_ctx.formatDate($event, 'HOLD_DATE'))),
                    disabled: this.readOnly,
                    showButtonBar: ""
                  }, null, 8, ["modelValue", "disabled"])
                ])
              ]),
              _createElementVNode("div", _hoisted_11, [
                _createElementVNode("div", _hoisted_12, [
                  _cache[49] || (_cache[49] = _createElementVNode("span", {
                    class: "p-inputgroup-addon",
                    style: { width: '103px' }
                  }, "Exp Date", -1)),
                  _createVNode(_component_Calendar, {
                    showIcon: true,
                    modelValue: this.salesOrder.custom_fields.BULK_EXP_DATE,
                    "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((this.salesOrder.custom_fields.BULK_EXP_DATE) = $event)),
                    dateFormat: "mm-dd-yy",
                    manualInput: false,
                    onDateSelect: _cache[6] || (_cache[6] = ($event: any) => (_ctx.formatDate($event, 'HOLD_DATE'))),
                    disabled: this.readOnly,
                    showButtonBar: ""
                  }, null, 8, ["modelValue", "disabled"])
                ])
              ]),
              _createElementVNode("div", _hoisted_13, [
                _createElementVNode("div", _hoisted_14, [
                  _cache[50] || (_cache[50] = _createElementVNode("span", {
                    class: "p-inputgroup-addon",
                    style: { width: '103px' }
                  }, "Batch Code", -1)),
                  _createVNode(_component_InputText, {
                    modelValue: this.salesOrder.custom_fields.BULK_BATCH,
                    "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((this.salesOrder.custom_fields.BULK_BATCH) = $event)),
                    disabled: this.readOnly
                  }, null, 8, ["modelValue", "disabled"])
                ])
              ])
            ])
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_15, [
            _createElementVNode("div", _hoisted_16, [
              _createElementVNode("div", _hoisted_17, [
                _createElementVNode("div", _hoisted_18, [
                  _cache[51] || (_cache[51] = _createElementVNode("span", {
                    class: "p-inputgroup-addon",
                    style: { width: '103px' }
                  }, "Cosway Part", -1)),
                  _createVNode(_component_InputText, {
                    modelValue: this.salesOrder.custom_fields.COSWAY_PART,
                    "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((this.salesOrder.custom_fields.COSWAY_PART) = $event)),
                    disabled: this.readOnly
                  }, null, 8, ["modelValue", "disabled"])
                ])
              ]),
              _createElementVNode("div", _hoisted_19, [
                _createElementVNode("div", _hoisted_20, [
                  _cache[52] || (_cache[52] = _createElementVNode("span", {
                    class: "p-inputgroup-addon",
                    style: { width: '103px' }
                  }, "Cust Part No", -1)),
                  _createVNode(_component_InputText, {
                    modelValue: this.salesOrder.custom_fields.CUST_PART_NO_VIEW,
                    "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((this.salesOrder.custom_fields.CUST_PART_NO_VIEW) = $event)),
                    disabled: this.readOnly
                  }, null, 8, ["modelValue", "disabled"])
                ])
              ]),
              _createElementVNode("div", _hoisted_21, [
                _createElementVNode("div", _hoisted_22, [
                  _cache[53] || (_cache[53] = _createElementVNode("span", {
                    class: "p-inputgroup-addon",
                    style: { width: '140px' }
                  }, "Customer Acct No", -1)),
                  _createVNode(_component_InputText, {
                    modelValue: this.salesOrder.custom_fields.CUST_CODE,
                    "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((this.salesOrder.custom_fields.CUST_CODE) = $event)),
                    disabled: this.readOnly
                  }, null, 8, ["modelValue", "disabled"])
                ])
              ]),
              _createElementVNode("div", _hoisted_23, [
                _createElementVNode("div", _hoisted_24, [
                  _cache[54] || (_cache[54] = _createElementVNode("span", {
                    class: "p-inputgroup-addon",
                    style: { width: '103px' }
                  }, "Customer PO", -1)),
                  _createVNode(_component_InputText, {
                    modelValue: this.salesOrder.custom_fields.PO_NUMBER,
                    "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((this.salesOrder.custom_fields.PO_NUMBER) = $event)),
                    disabled: this.readOnly
                  }, null, 8, ["modelValue", "disabled"])
                ])
              ]),
              _createElementVNode("div", _hoisted_25, [
                _createElementVNode("div", _hoisted_26, [
                  _cache[55] || (_cache[55] = _createElementVNode("span", {
                    class: "p-inputgroup-addon",
                    style: { width: '103px' }
                  }, "PO Qty", -1)),
                  _createVNode(_component_InputText, {
                    modelValue: this.salesOrder.custom_fields.SOH_ORD_QTY,
                    "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((this.salesOrder.custom_fields.SOH_ORD_QTY) = $event)),
                    disabled: this.readOnly
                  }, null, 8, ["modelValue", "disabled"])
                ])
              ]),
              _createElementVNode("div", _hoisted_27, [
                _createElementVNode("div", _hoisted_28, [
                  _cache[56] || (_cache[56] = _createElementVNode("span", {
                    class: "p-inputgroup-addon",
                    style: { width: '103px' }
                  }, "Unit Price", -1)),
                  _createVNode(_component_InputText, {
                    modelValue: this.salesOrder.custom_fields.SOH_UNIT_PRICE,
                    "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((this.salesOrder.custom_fields.SOH_UNIT_PRICE) = $event)),
                    disabled: this.readOnly
                  }, null, 8, ["modelValue", "disabled"])
                ])
              ]),
              _createElementVNode("div", _hoisted_29, [
                _createElementVNode("div", _hoisted_30, [
                  _cache[57] || (_cache[57] = _createElementVNode("span", {
                    class: "p-inputgroup-addon",
                    style: { width: '103px' }
                  }, "Qty Sched", -1)),
                  _createVNode(_component_InputText, {
                    modelValue: this.salesOrder.custom_fields.QTY_SCHED,
                    "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((this.salesOrder.custom_fields.QTY_SCHED) = $event)),
                    disabled: this.readOnly
                  }, null, 8, ["modelValue", "disabled"])
                ])
              ]),
              _createElementVNode("div", _hoisted_31, [
                _createElementVNode("div", _hoisted_32, [
                  _cache[58] || (_cache[58] = _createElementVNode("span", {
                    class: "p-inputgroup-addon",
                    style: { width: '103px' }
                  }, "Qty Prod", -1)),
                  _createVNode(_component_InputText, {
                    modelValue: this.salesOrder.custom_fields.QTY_PROD,
                    "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((this.salesOrder.custom_fields.QTY_PROD) = $event)),
                    disabled: this.readOnly
                  }, null, 8, ["modelValue", "disabled"])
                ])
              ]),
              _createElementVNode("div", _hoisted_33, [
                _createElementVNode("div", _hoisted_34, [
                  _cache[59] || (_cache[59] = _createElementVNode("span", {
                    class: "p-inputgroup-addon",
                    style: { width: '103px' }
                  }, "Qty Ship", -1)),
                  _createVNode(_component_InputText, {
                    modelValue: this.salesOrder.custom_fields.QTY_SHIP,
                    "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((this.salesOrder.custom_fields.QTY_SHIP) = $event)),
                    disabled: this.readOnly
                  }, null, 8, ["modelValue", "disabled"])
                ])
              ]),
              _createElementVNode("div", _hoisted_35, [
                _createElementVNode("div", _hoisted_36, [
                  _cache[60] || (_cache[60] = _createElementVNode("span", {
                    class: "p-inputgroup-addon",
                    style: { width: '103px' }
                  }, "Formula Part#", -1)),
                  _createVNode(_component_InputText, {
                    modelValue: this.salesOrder.custom_fields.FORMULA_PART_VIEW,
                    "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => ((this.salesOrder.custom_fields.FORMULA_PART_VIEW) = $event)),
                    disabled: this.readOnly
                  }, null, 8, ["modelValue", "disabled"])
                ])
              ]),
              _createElementVNode("div", _hoisted_37, [
                _createElementVNode("div", _hoisted_38, [
                  _cache[61] || (_cache[61] = _createElementVNode("span", {
                    class: "p-inputgroup-addon",
                    style: { width: '103px' }
                  }, "Batch Size", -1)),
                  _createVNode(_component_InputText, {
                    modelValue: this.salesOrder.custom_fields.BATCH_SIZE,
                    "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => ((this.salesOrder.custom_fields.BATCH_SIZE) = $event)),
                    disabled: this.readOnly
                  }, null, 8, ["modelValue", "disabled"])
                ])
              ]),
              _createElementVNode("div", _hoisted_39, [
                _createElementVNode("div", _hoisted_40, [
                  _cache[62] || (_cache[62] = _createElementVNode("span", {
                    class: "p-inputgroup-addon",
                    style: { width: '103px' }
                  }, "Batch Code", -1)),
                  _createVNode(_component_InputText, {
                    modelValue: this.salesOrder.custom_fields.BATCH_CODE,
                    "onUpdate:modelValue": _cache[19] || (_cache[19] = ($event: any) => ((this.salesOrder.custom_fields.BATCH_CODE) = $event)),
                    disabled: this.readOnly
                  }, null, 8, ["modelValue", "disabled"])
                ])
              ]),
              _createElementVNode("div", _hoisted_41, [
                _createElementVNode("div", _hoisted_42, [
                  _cache[63] || (_cache[63] = _createElementVNode("span", {
                    class: "p-inputgroup-addon",
                    style: { width: '103px' }
                  }, "Approved Date", -1)),
                  _createVNode(_component_Calendar, {
                    showIcon: true,
                    modelValue: this.salesOrder.custom_fields.APPROVED_DATE,
                    "onUpdate:modelValue": _cache[20] || (_cache[20] = ($event: any) => ((this.salesOrder.custom_fields.APPROVED_DATE) = $event)),
                    dateFormat: "mm-dd-yy",
                    manualInput: false,
                    onDateSelect: _cache[21] || (_cache[21] = ($event: any) => (_ctx.formatDate($event, 'HOLD_DATE'))),
                    disabled: this.readOnly,
                    showButtonBar: ""
                  }, null, 8, ["modelValue", "disabled"])
                ])
              ]),
              _createElementVNode("div", _hoisted_43, [
                _createElementVNode("div", _hoisted_44, [
                  _cache[64] || (_cache[64] = _createElementVNode("span", {
                    class: "p-inputgroup-addon",
                    style: { width: '103px' }
                  }, "Special Instr", -1)),
                  _createVNode(_component_TextArea, {
                    modelValue: this.salesOrder.custom_fields.SPECIAL_INSTRUCTIONS,
                    "onUpdate:modelValue": _cache[22] || (_cache[22] = ($event: any) => ((this.salesOrder.custom_fields.SPECIAL_INSTRUCTIONS) = $event)),
                    disabled: this.readOnly
                  }, null, 8, ["modelValue", "disabled"])
                ])
              ]),
              _createElementVNode("div", _hoisted_45, [
                _createElementVNode("div", _hoisted_46, [
                  _cache[65] || (_cache[65] = _createElementVNode("span", {
                    class: "p-inputgroup-addon",
                    style: { width: '103px' }
                  }, "Product Desc", -1)),
                  _createVNode(_component_TextArea, {
                    modelValue: this.salesOrder.custom_fields.PROD_DESC_VIEW,
                    "onUpdate:modelValue": _cache[23] || (_cache[23] = ($event: any) => ((this.salesOrder.custom_fields.PROD_DESC_VIEW) = $event)),
                    disabled: this.readOnly
                  }, null, 8, ["modelValue", "disabled"])
                ])
              ]),
              _createElementVNode("div", _hoisted_47, [
                _createElementVNode("div", _hoisted_48, [
                  _cache[66] || (_cache[66] = _createElementVNode("span", {
                    class: "p-inputgroup-addon",
                    style: { width: '103px' }
                  }, "Size", -1)),
                  _createVNode(_component_InputText, {
                    modelValue: this.salesOrder.custom_fields.UNIT_SIZE,
                    "onUpdate:modelValue": _cache[24] || (_cache[24] = ($event: any) => ((this.salesOrder.custom_fields.UNIT_SIZE) = $event)),
                    disabled: this.readOnly
                  }, null, 8, ["modelValue", "disabled"])
                ])
              ]),
              _createElementVNode("div", _hoisted_49, [
                _createElementVNode("div", _hoisted_50, [
                  _cache[67] || (_cache[67] = _createElementVNode("span", {
                    class: "p-inputgroup-addon",
                    style: { width: '103px' }
                  }, "FG Work Order", -1)),
                  _createVNode(_component_InputText, {
                    modelValue: this.salesOrder.custom_fields.FG_WO_NO,
                    "onUpdate:modelValue": _cache[25] || (_cache[25] = ($event: any) => ((this.salesOrder.custom_fields.FG_WO_NO) = $event)),
                    disabled: this.readOnly
                  }, null, 8, ["modelValue", "disabled"])
                ])
              ]),
              _createElementVNode("div", _hoisted_51, [
                _createElementVNode("div", _hoisted_52, [
                  _cache[68] || (_cache[68] = _createElementVNode("span", {
                    class: "p-inputgroup-addon",
                    style: { width: '103px' }
                  }, "Formula WO", -1)),
                  _createVNode(_component_InputText, {
                    modelValue: this.salesOrder.custom_fields.FORMULA_WO_NO,
                    "onUpdate:modelValue": _cache[26] || (_cache[26] = ($event: any) => ((this.salesOrder.custom_fields.FORMULA_WO_NO) = $event)),
                    disabled: this.readOnly
                  }, null, 8, ["modelValue", "disabled"])
                ])
              ]),
              _createElementVNode("div", _hoisted_53, [
                _createElementVNode("div", _hoisted_54, [
                  _cache[69] || (_cache[69] = _createElementVNode("span", {
                    class: "p-inputgroup-addon",
                    style: { width: '103px' }
                  }, "Ship To", -1)),
                  _createVNode(_component_TextArea, {
                    modelValue: this.salesOrder.custom_fields.SHIP_TO,
                    "onUpdate:modelValue": _cache[27] || (_cache[27] = ($event: any) => ((this.salesOrder.custom_fields.SHIP_TO) = $event)),
                    disabled: this.readOnly
                  }, null, 8, ["modelValue", "disabled"])
                ])
              ]),
              _createElementVNode("div", _hoisted_55, [
                _createElementVNode("div", _hoisted_56, [
                  _cache[70] || (_cache[70] = _createElementVNode("span", {
                    class: "p-inputgroup-addon",
                    style: { width: '103px' }
                  }, "Status", -1)),
                  _createVNode(_component_InputText, {
                    modelValue: this.salesOrder.custom_fields.SOH_STATUS,
                    "onUpdate:modelValue": _cache[28] || (_cache[28] = ($event: any) => ((this.salesOrder.custom_fields.SOH_STATUS) = $event)),
                    disabled: this.readOnly
                  }, null, 8, ["modelValue", "disabled"])
                ])
              ]),
              _createElementVNode("div", _hoisted_57, [
                _createElementVNode("div", _hoisted_58, [
                  _cache[71] || (_cache[71] = _createElementVNode("span", {
                    class: "p-inputgroup-addon",
                    style: { width: '103px' }
                  }, "Location", -1)),
                  _createVNode(_component_TextArea, {
                    modelValue: this.salesOrder.custom_fields.LOCATION,
                    "onUpdate:modelValue": _cache[29] || (_cache[29] = ($event: any) => ((this.salesOrder.custom_fields.LOCATION) = $event)),
                    disabled: this.readOnly
                  }, null, 8, ["modelValue", "disabled"])
                ])
              ]),
              _createElementVNode("div", _hoisted_59, [
                _createElementVNode("div", _hoisted_60, [
                  _cache[72] || (_cache[72] = _createElementVNode("span", {
                    class: "p-inputgroup-addon",
                    style: { width: '103px' }
                  }, "Original PO Date", -1)),
                  _createVNode(_component_Calendar, {
                    showIcon: true,
                    modelValue: this.salesOrder.custom_fields.ORIG_PO_DATE,
                    "onUpdate:modelValue": _cache[30] || (_cache[30] = ($event: any) => ((this.salesOrder.custom_fields.ORIG_PO_DATE) = $event)),
                    dateFormat: "mm-dd-yy",
                    manualInput: false,
                    onDateSelect: _cache[31] || (_cache[31] = ($event: any) => (_ctx.formatDate($event, 'HOLD_DATE'))),
                    disabled: this.readOnly,
                    showButtonBar: ""
                  }, null, 8, ["modelValue", "disabled"])
                ])
              ]),
              _createElementVNode("div", _hoisted_61, [
                _createElementVNode("div", _hoisted_62, [
                  _cache[73] || (_cache[73] = _createElementVNode("span", {
                    class: "p-inputgroup-addon",
                    style: { width: '103px' }
                  }, "Date Req", -1)),
                  _createVNode(_component_Calendar, {
                    showIcon: true,
                    modelValue: this.salesOrder.custom_fields.SOH_REQ_DATE,
                    "onUpdate:modelValue": _cache[32] || (_cache[32] = ($event: any) => ((this.salesOrder.custom_fields.SOH_REQ_DATE) = $event)),
                    dateFormat: "mm-dd-yy",
                    manualInput: false,
                    onDateSelect: _cache[33] || (_cache[33] = ($event: any) => (_ctx.formatDate($event, 'HOLD_DATE'))),
                    disabled: this.readOnly,
                    showButtonBar: ""
                  }, null, 8, ["modelValue", "disabled"])
                ])
              ]),
              _createElementVNode("div", _hoisted_63, [
                _createElementVNode("div", _hoisted_64, [
                  _cache[74] || (_cache[74] = _createElementVNode("span", {
                    class: "p-inputgroup-addon",
                    style: { width: '103px' }
                  }, "Date Rel", -1)),
                  _createVNode(_component_Calendar, {
                    showIcon: true,
                    modelValue: this.salesOrder.custom_fields.DATE_REL,
                    "onUpdate:modelValue": _cache[34] || (_cache[34] = ($event: any) => ((this.salesOrder.custom_fields.DATE_REL) = $event)),
                    dateFormat: "mm-dd-yy",
                    manualInput: false,
                    onDateSelect: _cache[35] || (_cache[35] = ($event: any) => (_ctx.formatDate($event, 'HOLD_DATE'))),
                    disabled: this.readOnly,
                    showButtonBar: ""
                  }, null, 8, ["modelValue", "disabled"])
                ])
              ]),
              _createElementVNode("div", _hoisted_65, [
                _createElementVNode("div", _hoisted_66, [
                  _cache[75] || (_cache[75] = _createElementVNode("span", {
                    class: "p-inputgroup-addon",
                    style: { width: '103px' }
                  }, "Schedule Date", -1)),
                  _createVNode(_component_Calendar, {
                    showIcon: true,
                    modelValue: this.salesOrder.custom_fields.SCHEDULE_DATE,
                    "onUpdate:modelValue": _cache[36] || (_cache[36] = ($event: any) => ((this.salesOrder.custom_fields.SCHEDULE_DATE) = $event)),
                    dateFormat: "mm-dd-yy",
                    manualInput: false,
                    onDateSelect: _cache[37] || (_cache[37] = ($event: any) => (_ctx.formatDate($event, 'HOLD_DATE'))),
                    disabled: this.readOnly,
                    showButtonBar: ""
                  }, null, 8, ["modelValue", "disabled"])
                ])
              ]),
              _createElementVNode("div", _hoisted_67, [
                _createElementVNode("div", _hoisted_68, [
                  _cache[76] || (_cache[76] = _createElementVNode("span", {
                    class: "p-inputgroup-addon",
                    style: { width: '103px' }
                  }, "Prod Schedule", -1)),
                  _createVNode(_component_Calendar, {
                    showIcon: true,
                    modelValue: this.salesOrder.custom_fields.PROD_SCHED,
                    "onUpdate:modelValue": _cache[38] || (_cache[38] = ($event: any) => ((this.salesOrder.custom_fields.PROD_SCHED) = $event)),
                    dateFormat: "mm-dd-yy",
                    manualInput: false,
                    onDateSelect: _cache[39] || (_cache[39] = ($event: any) => (_ctx.formatDate($event, 'HOLD_DATE'))),
                    disabled: this.readOnly,
                    showButtonBar: ""
                  }, null, 8, ["modelValue", "disabled"])
                ])
              ]),
              _createElementVNode("div", _hoisted_69, [
                _createElementVNode("div", _hoisted_70, [
                  _cache[77] || (_cache[77] = _createElementVNode("span", {
                    class: "p-inputgroup-addon",
                    style: { width: '103px' }
                  }, "Delv Ref", -1)),
                  _createVNode(_component_InputText, {
                    modelValue: this.salesOrder.custom_fields.SOD_DLV_REF,
                    "onUpdate:modelValue": _cache[40] || (_cache[40] = ($event: any) => ((this.salesOrder.custom_fields.SOD_DLV_REF) = $event)),
                    disabled: this.readOnly
                  }, null, 8, ["modelValue", "disabled"])
                ])
              ])
            ])
          ]))
    ]),
    _: 1
  }, 8, ["header", "visible"]))
}
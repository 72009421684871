import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "grid justify-content-center" }
const _hoisted_2 = { class: "col-12 py-5 md:px-4 xl:col-11 sm:px-1" }
const _hoisted_3 = { class: "grid m-0 p-0" }
const _hoisted_4 = { class: "p-inputgroup" }
const _hoisted_5 = { class: "p-inputgroup" }
const _hoisted_6 = { class: "p-inputgroup" }
const _hoisted_7 = { class: "p-inputgroup" }
const _hoisted_8 = { class: "p-inputgroup" }
const _hoisted_9 = { class: "p-inputgroup" }
const _hoisted_10 = { class: "grid m-0 p-0" }
const _hoisted_11 = { class: "p-inputgroup" }
const _hoisted_12 = { class: "p-inputgroup" }
const _hoisted_13 = { class: "p-inputgroup" }
const _hoisted_14 = { class: "flex" }
const _hoisted_15 = { class: "grid m-0 p-0" }
const _hoisted_16 = { class: "p-inputgroup" }
const _hoisted_17 = { class: "grid m-0 p-0" }
const _hoisted_18 = { class: "p-inputgroup" }
const _hoisted_19 = { class: "p-inputgroup" }
const _hoisted_20 = { class: "p-inputgroup" }
const _hoisted_21 = { class: "flex align-items-center" }
const _hoisted_22 = { class: "p-inputgroup" }
const _hoisted_23 = { class: "flex align-items-center" }
const _hoisted_24 = { class: "flex" }
const _hoisted_25 = { class: "grid m-0 p-0" }
const _hoisted_26 = { class: "p-inputgroup w-full" }
const _hoisted_27 = { class: "flex align-items-center" }
const _hoisted_28 = { class: "p-inputgroup w-full" }
const _hoisted_29 = { class: "flex align-items-center" }
const _hoisted_30 = { class: "p-inputgroup w-full" }
const _hoisted_31 = { class: "flex align-items-center" }
const _hoisted_32 = { class: "p-inputgroup w-full" }
const _hoisted_33 = { class: "flex align-items-center" }
const _hoisted_34 = { class: "p-inputgroup w-full" }
const _hoisted_35 = { class: "flex align-items-center" }
const _hoisted_36 = { class: "p-inputgroup w-full" }
const _hoisted_37 = { class: "flex align-items-center" }
const _hoisted_38 = { class: "p-inputgroup" }
const _hoisted_39 = { class: "flex" }
const _hoisted_40 = { class: "grid m-0 p-0" }
const _hoisted_41 = { class: "p-inputgroup" }
const _hoisted_42 = { class: "flex align-items-center" }
const _hoisted_43 = { class: "p-inputgroup" }
const _hoisted_44 = { class: "flex align-items-center" }
const _hoisted_45 = { class: "p-inputgroup" }
const _hoisted_46 = { class: "p-inputgroup" }
const _hoisted_47 = { class: "flex" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Card = _resolveComponent("Card")!
  const _component_CollapsibleSection = _resolveComponent("CollapsibleSection")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_Button = _resolveComponent("Button")!
  const _component_InputSwitch = _resolveComponent("InputSwitch")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_CollapsibleSection, {
        isOpen: _ctx.sectionStatus.userInfo,
        header: "User Information",
        "onUpdate:isOpen": _cache[6] || (_cache[6] = ($event: any) => (_ctx.toggleSectionIcon('userInfo')))
      }, {
        content: _withCtx(() => [
          _createVNode(_component_Card, { class: "mr-3 mb-4" }, {
            content: _withCtx(() => [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", {
                  class: _normalizeClass(_ctx.inputDivClass)
                }, [
                  _createElementVNode("div", _hoisted_4, [
                    _cache[32] || (_cache[32] = _createElementVNode("small", {
                      class: "p-inputgroup-addon font-semibold general-label-color",
                      style: { width: '150px' }
                    }, "User ID", -1)),
                    _createVNode(_component_InputText, {
                      type: "text",
                      modelValue: _ctx.getUser.user_id,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.getUser.user_id) = $event)),
                      class: "text-sm w-full",
                      disabled: true
                    }, null, 8, ["modelValue"])
                  ])
                ], 2),
                _createElementVNode("div", {
                  class: _normalizeClass(_ctx.inputDivClass)
                }, [
                  _createElementVNode("div", _hoisted_5, [
                    _cache[33] || (_cache[33] = _createElementVNode("small", {
                      class: "p-inputgroup-addon font-semibold general-label-color",
                      style: { width: '150px' }
                    }, "First Name", -1)),
                    _createVNode(_component_InputText, {
                      type: "text",
                      modelValue: _ctx.getUser.first_name,
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.getUser.first_name) = $event)),
                      class: "text-sm w-full",
                      disabled: true
                    }, null, 8, ["modelValue"])
                  ])
                ], 2),
                _createElementVNode("div", {
                  class: _normalizeClass(_ctx.inputDivClass)
                }, [
                  _createElementVNode("div", _hoisted_6, [
                    _cache[34] || (_cache[34] = _createElementVNode("small", {
                      class: "p-inputgroup-addon font-semibold general-label-color",
                      style: { width: '150px' }
                    }, "Last Name", -1)),
                    _createVNode(_component_InputText, {
                      type: "text",
                      modelValue: _ctx.getUser.last_name,
                      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.getUser.last_name) = $event)),
                      class: "text-sm w-full",
                      disabled: true
                    }, null, 8, ["modelValue"])
                  ])
                ], 2),
                _createElementVNode("div", {
                  class: _normalizeClass(_ctx.inputDivClass)
                }, [
                  _createElementVNode("div", _hoisted_7, [
                    _cache[35] || (_cache[35] = _createElementVNode("small", {
                      class: "p-inputgroup-addon font-semibold general-label-color",
                      style: { width: '150px' }
                    }, "Email Address", -1)),
                    _createVNode(_component_InputText, {
                      type: "text",
                      modelValue: _ctx.getUser.email,
                      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.getUser.email) = $event)),
                      class: "text-sm w-full",
                      disabled: true
                    }, null, 8, ["modelValue"])
                  ])
                ], 2),
                _createElementVNode("div", {
                  class: _normalizeClass(_ctx.inputDivClass)
                }, [
                  _createElementVNode("div", _hoisted_8, [
                    _cache[36] || (_cache[36] = _createElementVNode("small", {
                      class: "p-inputgroup-addon font-semibold general-label-color",
                      style: { width: '150px' }
                    }, "Telephone", -1)),
                    _createVNode(_component_InputText, {
                      type: "text",
                      modelValue: _ctx.getUser.telephone,
                      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.getUser.telephone) = $event)),
                      class: "text-sm w-full",
                      disabled: true
                    }, null, 8, ["modelValue"])
                  ])
                ], 2),
                _createElementVNode("div", {
                  class: _normalizeClass(_ctx.inputDivClass)
                }, [
                  _createElementVNode("div", _hoisted_9, [
                    _cache[37] || (_cache[37] = _createElementVNode("small", {
                      class: "p-inputgroup-addon font-semibold general-label-color",
                      style: { width: '150px' }
                    }, "Security Level", -1)),
                    _createVNode(_component_InputText, {
                      type: "text",
                      modelValue: _ctx.getUser.security_level,
                      "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.getUser.security_level) = $event)),
                      class: "text-sm w-full",
                      disabled: true
                    }, null, 8, ["modelValue"])
                  ])
                ], 2)
              ])
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["isOpen"]),
      (_ctx.pos.register?.reg_id)
        ? (_openBlock(), _createBlock(_component_CollapsibleSection, {
            key: 0,
            isOpen: _ctx.sectionStatus.registerInfo,
            header: "Register Information",
            "onUpdate:isOpen": _cache[10] || (_cache[10] = ($event: any) => (_ctx.toggleSectionIcon('registerInfo')))
          }, {
            content: _withCtx(() => [
              _createVNode(_component_Card, { class: "mr-3 mb-4" }, {
                content: _withCtx(() => [
                  _createElementVNode("div", _hoisted_10, [
                    _createElementVNode("div", {
                      class: _normalizeClass(_ctx.inputDivClass)
                    }, [
                      _createElementVNode("div", _hoisted_11, [
                        _cache[38] || (_cache[38] = _createElementVNode("small", {
                          class: "p-inputgroup-addon font-semibold general-label-color",
                          style: { width: '150px' }
                        }, "Store Number", -1)),
                        _createVNode(_component_InputText, {
                          type: "text",
                          modelValue: _ctx.pos.register.store,
                          "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.pos.register.store) = $event)),
                          class: "text-sm w-full",
                          disabled: true
                        }, null, 8, ["modelValue"])
                      ])
                    ], 2),
                    _createElementVNode("div", {
                      class: _normalizeClass(_ctx.inputDivClass)
                    }, [
                      _createElementVNode("div", _hoisted_12, [
                        _cache[39] || (_cache[39] = _createElementVNode("small", {
                          class: "p-inputgroup-addon font-semibold general-label-color",
                          style: { width: '150px' }
                        }, "Register Number", -1)),
                        _createVNode(_component_InputText, {
                          type: "text",
                          modelValue: _ctx.pos.register.reg_id,
                          "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.pos.register.reg_id) = $event)),
                          class: "text-sm w-full",
                          disabled: true
                        }, null, 8, ["modelValue"])
                      ])
                    ], 2),
                    _createElementVNode("div", {
                      class: _normalizeClass(_ctx.inputDivClass)
                    }, [
                      _createElementVNode("div", _hoisted_13, [
                        _cache[40] || (_cache[40] = _createElementVNode("small", {
                          class: "p-inputgroup-addon font-semibold general-label-color",
                          style: { width: '150px' }
                        }, "Receipt Printer", -1)),
                        _createVNode(_component_Dropdown, {
                          modelValue: _ctx.registerPrinter,
                          "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.registerPrinter) = $event)),
                          options: _ctx.printers,
                          placeholder: "Select a Printer",
                          class: "text-sm",
                          showClear: true
                        }, null, 8, ["modelValue", "options"])
                      ])
                    ], 2)
                  ])
                ]),
                footer: _withCtx(() => [
                  _createElementVNode("div", _hoisted_14, [
                    _createVNode(_component_Button, {
                      "data-testid": "saveRegisterOptions",
                      label: "Save",
                      onClick: _ctx.saveRegisterOptions,
                      class: "ml-2"
                    }, null, 8, ["onClick"])
                  ])
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["isOpen"]))
        : (_openBlock(), _createBlock(_component_CollapsibleSection, {
            key: 1,
            isOpen: _ctx.sectionStatus.accountInfo,
            header: "Account Information",
            "onUpdate:isOpen": _cache[12] || (_cache[12] = ($event: any) => (_ctx.toggleSectionIcon('accountInfo')))
          }, {
            content: _withCtx(() => [
              _createVNode(_component_Card, { class: "mr-3 mb-4" }, {
                content: _withCtx(() => [
                  _createElementVNode("div", _hoisted_15, [
                    _createElementVNode("div", {
                      class: _normalizeClass(_ctx.inputDivClass)
                    }, [
                      _createElementVNode("div", _hoisted_16, [
                        _cache[41] || (_cache[41] = _createElementVNode("small", {
                          class: "p-inputgroup-addon font-semibold general-label-color",
                          style: { width: '150px' }
                        }, "Current Account", -1)),
                        _createVNode(_component_InputText, {
                          type: "text",
                          modelValue: _ctx.session.client,
                          "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.session.client) = $event)),
                          class: "text-sm w-full",
                          disabled: true
                        }, null, 8, ["modelValue"])
                      ])
                    ], 2)
                  ])
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["isOpen"])),
      (
          _ctx.getLinks.find((link) => link.label === 'Scan' || link.label === 'POS')
        )
        ? (_openBlock(), _createBlock(_component_CollapsibleSection, {
            key: 2,
            isOpen: _ctx.sectionStatus.roverScan,
            header: "Scan",
            "onUpdate:isOpen": _cache[17] || (_cache[17] = ($event: any) => (_ctx.toggleSectionIcon('roverScan')))
          }, {
            content: _withCtx(() => [
              _createVNode(_component_Card, { class: "mr-3 mb-4 rover-scan-settings" }, {
                content: _withCtx(() => [
                  _createElementVNode("div", _hoisted_17, [
                    _createElementVNode("div", {
                      class: _normalizeClass(_ctx.inputDivClass)
                    }, [
                      _createElementVNode("div", _hoisted_18, [
                        _cache[42] || (_cache[42] = _createElementVNode("small", { class: "p-inputgroup-addon font-semibold general-label-color" }, "Prefix Character", -1)),
                        _createVNode(_component_InputText, {
                          type: "text",
                          modelValue: _ctx.prefix,
                          "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.prefix) = $event)),
                          class: "text-sm w-full",
                          disabled: false,
                          maxlength: "1"
                        }, null, 8, ["modelValue"])
                      ])
                    ], 2),
                    _createElementVNode("div", {
                      class: _normalizeClass(_ctx.inputDivClass)
                    }, [
                      _createElementVNode("div", _hoisted_19, [
                        _cache[43] || (_cache[43] = _createElementVNode("small", { class: "p-inputgroup-addon font-semibold general-label-color" }, "Suffix Character", -1)),
                        _createVNode(_component_InputText, {
                          "data-testid": "scanSuffix",
                          type: "text",
                          modelValue: _ctx.suffix,
                          "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.suffix) = $event)),
                          class: "text-sm w-full",
                          disabled: false,
                          maxlength: "1"
                        }, null, 8, ["modelValue"])
                      ])
                    ], 2),
                    _createElementVNode("div", {
                      class: _normalizeClass(_ctx.inputDivClass)
                    }, [
                      _createElementVNode("div", _hoisted_20, [
                        _createElementVNode("small", {
                          class: _normalizeClass(_ctx.inputSwitchClass)
                        }, " Scanner Mode", 2),
                        _createElementVNode("div", _hoisted_21, [
                          _createVNode(_component_InputSwitch, {
                            "data-testid": "scannerModeToggle",
                            modelValue: _ctx.scanMode,
                            "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.scanMode) = $event)),
                            class: "ml-3"
                          }, null, 8, ["modelValue"])
                        ])
                      ])
                    ], 2),
                    _createElementVNode("div", {
                      class: _normalizeClass(_ctx.inputDivClass)
                    }, [
                      _createElementVNode("div", _hoisted_22, [
                        _createElementVNode("small", {
                          class: _normalizeClass(_ctx.inputSwitchClass)
                        }, " Use User ID as Employee ID", 2),
                        _createElementVNode("div", _hoisted_23, [
                          _createVNode(_component_InputSwitch, {
                            "data-testid": "userAsEmployeeId",
                            modelValue: _ctx.userIdAsEmployeeId,
                            "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.userIdAsEmployeeId) = $event)),
                            class: "ml-3"
                          }, null, 8, ["modelValue"])
                        ])
                      ])
                    ], 2)
                  ])
                ]),
                footer: _withCtx(() => [
                  _createElementVNode("div", _hoisted_24, [
                    _createVNode(_component_Button, {
                      "data-testid": "saveScanOptions",
                      label: "Save",
                      onClick: _ctx.saveScanOptions,
                      class: "ml-2"
                    }, null, 8, ["onClick"])
                  ])
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["isOpen"]))
        : _createCommentVNode("", true),
      (_ctx.getLinks.find((link) => link.label === 'POS'))
        ? (_openBlock(), _createBlock(_component_CollapsibleSection, {
            key: 3,
            isOpen: _ctx.sectionStatus.pos,
            header: "POS",
            "onUpdate:isOpen": _cache[26] || (_cache[26] = ($event: any) => (_ctx.toggleSectionIcon('pos')))
          }, {
            content: _withCtx(() => [
              _createVNode(_component_Card, { class: "mr-3 mb-4 pos-settings" }, {
                content: _withCtx(() => [
                  _createElementVNode("div", _hoisted_25, [
                    (!_ctx.hideLoadCustomerButton)
                      ? (_openBlock(), _createElementBlock("div", {
                          key: 0,
                          class: _normalizeClass(_ctx.inputDivClass)
                        }, [
                          _createElementVNode("div", _hoisted_26, [
                            _createElementVNode("small", {
                              class: _normalizeClass(_ctx.inputSwitchClass),
                              title: "Loads default customers automatically when entering POS."
                            }, "Load Searched Order as Edit Order", 2),
                            _createElementVNode("div", _hoisted_27, [
                              _createVNode(_component_InputSwitch, {
                                id: "loadOpenOrdersInParts",
                                modelValue: _ctx.getLoadOpenOrdersInParts,
                                "onUpdate:modelValue": [
                                  _cache[18] || (_cache[18] = ($event: any) => ((_ctx.getLoadOpenOrdersInParts) = $event)),
                                  _ctx.toggleLoadOpenOrdersInParts
                                ],
                                class: "ml-3",
                                title: "When enabled searching for an order will load the order as an edit order. Disabled will load the order to finalize."
                              }, null, 8, ["modelValue", "onUpdate:modelValue"])
                            ])
                          ])
                        ], 2))
                      : _createCommentVNode("", true),
                    (!_ctx.hideLoadCustomerButton)
                      ? (_openBlock(), _createElementBlock("div", {
                          key: 1,
                          class: _normalizeClass(_ctx.inputDivClass)
                        }, [
                          _createElementVNode("div", _hoisted_28, [
                            _createElementVNode("small", {
                              class: _normalizeClass(_ctx.inputSwitchClass),
                              title: "Loads default customers automatically when entering POS."
                            }, "Load Default Customers", 2),
                            _createElementVNode("div", _hoisted_29, [
                              _createVNode(_component_InputSwitch, {
                                id: "autoLoadCustomers",
                                modelValue: _ctx.getAutoLoadDefaultCustomers,
                                "onUpdate:modelValue": [
                                  _cache[19] || (_cache[19] = ($event: any) => ((_ctx.getAutoLoadDefaultCustomers) = $event)),
                                  _ctx.toggleAutoLoadDefaultCustomers
                                ],
                                class: "ml-3",
                                title: "Loads default customers automatically when entering POS."
                              }, null, 8, ["modelValue", "onUpdate:modelValue"])
                            ])
                          ])
                        ], 2))
                      : _createCommentVNode("", true),
                    _createElementVNode("div", {
                      class: _normalizeClass(_ctx.inputDivClass)
                    }, [
                      _createElementVNode("div", _hoisted_30, [
                        _createElementVNode("small", {
                          class: _normalizeClass(_ctx.inputSwitchClass),
                          title: "Disables display of line item custom fields in shopping cart view."
                        }, "Hide Line Item Custom Fields", 2),
                        _createElementVNode("div", _hoisted_31, [
                          _createVNode(_component_InputSwitch, {
                            id: "hidePosCustomLineItemFields",
                            modelValue: _ctx.getHidePosCustomLineItems,
                            "onUpdate:modelValue": [
                              _cache[20] || (_cache[20] = ($event: any) => ((_ctx.getHidePosCustomLineItems) = $event)),
                              _ctx.setHidePosCustomLineItems
                            ],
                            class: "ml-3",
                            title: "Disables display of line item custom fields in shopping cart view."
                          }, null, 8, ["modelValue", "onUpdate:modelValue"])
                        ])
                      ])
                    ], 2),
                    _createElementVNode("div", {
                      class: _normalizeClass(_ctx.inputDivClass)
                    }, [
                      _createElementVNode("div", _hoisted_32, [
                        _createElementVNode("small", {
                          class: _normalizeClass(_ctx.inputSwitchClass),
                          title: "Adds part to order automatically when only 1 parts is returned after search."
                        }, "Auto Add Part", 2),
                        _createElementVNode("div", _hoisted_33, [
                          _createVNode(_component_InputSwitch, {
                            id: "autoAdd",
                            modelValue: _ctx.getAutoAddParts,
                            "onUpdate:modelValue": [
                              _cache[21] || (_cache[21] = ($event: any) => ((_ctx.getAutoAddParts) = $event)),
                              _ctx.toggleAutoAddParts
                            ],
                            class: "ml-3",
                            title: "Adds part to order automatically when only 1 parts is returned after search."
                          }, null, 8, ["modelValue", "onUpdate:modelValue"])
                        ])
                      ])
                    ], 2),
                    _createElementVNode("div", {
                      class: _normalizeClass(_ctx.inputDivClass)
                    }, [
                      _createElementVNode("div", _hoisted_34, [
                        _createElementVNode("small", {
                          class: _normalizeClass(_ctx.inputSwitchClass),
                          title: "Enable keyboard shortcuts for POS Order."
                        }, [
                          _cache[44] || (_cache[44] = _createTextVNode("Keyboard Shortcuts ")),
                          _withDirectives(_createElementVNode("i", {
                            class: "pi pi-info-circle",
                            onClick: _cache[22] || (_cache[22] = 
//@ts-ignore
(...args) => (_ctx.showKeyboardShortcuts && _ctx.showKeyboardShortcuts(...args)))
                          }, null, 512), [
                            [_directive_tooltip, 
                          `Press CTRL + SHIFT + '?' key to view shortcuts`
                        ]
                          ])
                        ], 2),
                        _createElementVNode("div", _hoisted_35, [
                          _createVNode(_component_InputSwitch, {
                            id: "posKeyboardShortCuts",
                            modelValue: _ctx.posKeyboardShortCutsMode,
                            "onUpdate:modelValue": [
                              _cache[23] || (_cache[23] = ($event: any) => ((_ctx.posKeyboardShortCutsMode) = $event)),
                              _ctx.setPosKeyboardShortcutsMode
                            ],
                            class: "ml-3",
                            title: "Enable keyboard shortcuts for POS."
                          }, null, 8, ["modelValue", "onUpdate:modelValue"])
                        ])
                      ])
                    ], 2),
                    (!_ctx.hideDraftedOrders)
                      ? (_openBlock(), _createElementBlock("div", {
                          key: 2,
                          class: _normalizeClass(_ctx.inputDivClass)
                        }, [
                          _createElementVNode("div", _hoisted_36, [
                            _createElementVNode("small", {
                              class: _normalizeClass(_ctx.inputSwitchClass),
                              title: "Automatically save orders as draft when updating parts."
                            }, "Auto Save Orders as Draft", 2),
                            _createElementVNode("div", _hoisted_37, [
                              _createVNode(_component_InputSwitch, {
                                id: "posAutosaveDraftOrder",
                                modelValue: _ctx.posAutosaveDraftOrder,
                                "onUpdate:modelValue": [
                                  _cache[24] || (_cache[24] = ($event: any) => ((_ctx.posAutosaveDraftOrder) = $event)),
                                  _ctx.setAutosaveDraftOrder
                                ],
                                class: "ml-3",
                                title: "Automatically save orders as draft when updating parts."
                              }, null, 8, ["modelValue", "onUpdate:modelValue"])
                            ])
                          ])
                        ], 2))
                      : _createCommentVNode("", true),
                    (_ctx.showPickTicketPrint)
                      ? (_openBlock(), _createElementBlock("div", {
                          key: 3,
                          class: _normalizeClass(_ctx.inputDivClass)
                        }, [
                          _createElementVNode("div", _hoisted_38, [
                            _cache[45] || (_cache[45] = _createElementVNode("small", { class: "p-inputgroup-addon font-semibold general-label-color single-addon" }, "Default Pick Ticket Printer", -1)),
                            _createVNode(_component_Dropdown, {
                              modelValue: _ctx.defaultPickTicketPrinter,
                              "onUpdate:modelValue": _cache[25] || (_cache[25] = ($event: any) => ((_ctx.defaultPickTicketPrinter) = $event)),
                              options: _ctx.getPrinterQueues.serverPrinters,
                              optionLabel: "svr_path_name",
                              optionValue: "svr_path_name",
                              placeholder: "Select a Printer",
                              class: "text-sm",
                              showClear: true
                            }, null, 8, ["modelValue", "options"])
                          ])
                        ], 2))
                      : _createCommentVNode("", true)
                  ])
                ]),
                footer: _withCtx(() => [
                  _createElementVNode("div", _hoisted_39, [
                    _createVNode(_component_Button, {
                      label: "Save",
                      onClick: _ctx.savePOSOptions,
                      class: "ml-2"
                    }, null, 8, ["onClick"])
                  ])
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["isOpen"]))
        : _createCommentVNode("", true),
      _createVNode(_component_CollapsibleSection, {
        isOpen: _ctx.sectionStatus.preferences,
        header: "Preferences",
        "onUpdate:isOpen": _cache[31] || (_cache[31] = ($event: any) => (_ctx.toggleSectionIcon('preferences')))
      }, {
        content: _withCtx(() => [
          _createVNode(_component_Card, { class: "mr-3 mb-4 rover-scan-settings" }, {
            content: _withCtx(() => [
              _createElementVNode("div", _hoisted_40, [
                _createElementVNode("div", {
                  class: _normalizeClass(_ctx.inputDivClass)
                }, [
                  _createElementVNode("div", _hoisted_41, [
                    _createElementVNode("small", {
                      class: _normalizeClass(_ctx.inputSwitchClass)
                    }, "Floating Sidebar ", 2),
                    _createElementVNode("div", _hoisted_42, [
                      _createVNode(_component_InputSwitch, {
                        modelValue: _ctx.floatingSidebar,
                        "onUpdate:modelValue": _cache[27] || (_cache[27] = ($event: any) => ((_ctx.floatingSidebar) = $event)),
                        class: "ml-3"
                      }, null, 8, ["modelValue"])
                    ])
                  ])
                ], 2),
                (_ctx.getACHFee || _ctx.getCCFee)
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 0,
                      class: _normalizeClass(_ctx.inputDivClass)
                    }, [
                      _createElementVNode("div", _hoisted_43, [
                        _createElementVNode("small", {
                          class: _normalizeClass(_ctx.inputSwitchClass)
                        }, "Waive Convenience Fee by Default ", 2),
                        _createElementVNode("div", _hoisted_44, [
                          _createVNode(_component_InputSwitch, {
                            modelValue: _ctx.getWaiveConvenienceFeeByDefault,
                            "onUpdate:modelValue": [
                              _cache[28] || (_cache[28] = ($event: any) => ((_ctx.getWaiveConvenienceFeeByDefault) = $event)),
                              _ctx.toggleWaiveConvenienceFeeByDefault
                            ],
                            disabled: !_ctx.allowWaiveConvenienceFee,
                            class: "ml-3"
                          }, null, 8, ["modelValue", "onUpdate:modelValue", "disabled"])
                        ])
                      ])
                    ], 2))
                  : _createCommentVNode("", true),
                _createElementVNode("div", {
                  class: _normalizeClass(_ctx.inputDivClass)
                }, [
                  _createElementVNode("div", _hoisted_45, [
                    _cache[46] || (_cache[46] = _createElementVNode("small", { class: "p-inputgroup-addon font-semibold general-label-color" }, "Default Landing Page", -1)),
                    _createVNode(_component_Dropdown, {
                      modelValue: _ctx.defaultLandingPage,
                      "onUpdate:modelValue": _cache[29] || (_cache[29] = ($event: any) => ((_ctx.defaultLandingPage) = $event)),
                      options: _ctx.getLinks,
                      optionLabel: "label",
                      placeholder: "Select a Module",
                      class: "text-sm",
                      showClear: true
                    }, null, 8, ["modelValue", "options"])
                  ])
                ], 2),
                _createElementVNode("div", {
                  class: _normalizeClass(_ctx.inputDivClass)
                }, [
                  _createElementVNode("div", _hoisted_46, [
                    _cache[47] || (_cache[47] = _createElementVNode("small", { class: "p-inputgroup-addon font-semibold general-label-color" }, "Automatic Search Delay", -1)),
                    _createVNode(_component_Dropdown, {
                      modelValue: _ctx.selectedDelay,
                      "onUpdate:modelValue": _cache[30] || (_cache[30] = ($event: any) => ((_ctx.selectedDelay) = $event)),
                      options: _ctx.autoDelayOptions,
                      optionLabel: "label",
                      placeholder: "Select a delay",
                      class: "text-sm",
                      showClear: true
                    }, null, 8, ["modelValue", "options"])
                  ])
                ], 2)
              ])
            ]),
            footer: _withCtx(() => [
              _createElementVNode("div", _hoisted_47, [
                _createVNode(_component_Button, {
                  label: "Save",
                  onClick: _ctx.savePreferenceOptions,
                  class: "ml-2"
                }, null, 8, ["onClick"])
              ])
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["isOpen"])
    ])
  ]))
}
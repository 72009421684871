
import { defineComponent } from "vue";
import { mapActions, mapGetters, mapState } from "vuex";
import Card from "primevue/card";
import MenuBar from "primevue/menubar";
import store from "@/store";
import Search from "@/components/Search.vue";
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import Button from "primevue/button";
import Column from "primevue/column";
import DataTable from "primevue/datatable";
import Badge from "primevue/badge";
import NewCustomer from "@/components/NewCustomer.vue";
import Utils from "@/utility/utils";
import Customer from "@/types/customer";
import TopKPICards from "@/components/CustomerInquiry/component/TopKPICards.vue";

export default defineComponent({
  name: "CustomerInquiryForm",
  components: {
    MenuBar,
    Search,
    TabView,
    TabPanel,
    Button,
    Column,
    DataTable,
    Badge,
    Card,
    NewCustomer,
    TopKPICards,
  },
  computed: {
    ...mapState(["customerInquiry"]),
    ...mapGetters({
      getClient: "session/getClient",
      getAccess: "session/getAccess",
      getAllCustomers: "customer/getAllCusts",
      getRecentlyViewedCustomers: "customerInquiry/getRecentlyViewed",
      getCurrentCustomer: "customerInquiry/getCurrentCustomer",
      getOpenCustomers: "customerInquiry/getCustomerMap",
      getSalesOppsIds: "customerInquiry/getSalesOppsIds",
      fieldExists: "fdict/fieldExists",
    }),
    visibleMenuItems(): any {
      return this.items.filter((item) =>
        this.getAccess(item.to.replace("/customers", "")),
      );
    },
    showServiceRep(): boolean {
      return this.fieldExists("CUST", "SERVICE.REP");
    },
  },
  created() {
    const client = this.getClient;
    const CONTROLS = [
      {
        Client: client,
        control: "price",
        id: "PRICE",
        filename: "CONTROL",
        procedure: "price.CONTROL",
        getter: "customerInquiry/getPricingCodes",
      },
      {
        Client: client,
        id: "SHIP",
        procedure: "SHIP.CONTROL",
        filename: "CONTROL",
        getter: "customerInquiry/getCountries",
      },
      {
        Client: store.getters["session/getClient"],
        control: "currency",
        id: "CURRENCY",
        filename: "CONTROL",
        procedure: "CURRENCY.CONTROL",
        getter: "customerInquiry/getCurrency",
      },
      {
        Client: client,
        control: "group",
        id: "GROUP",
        filename: "CONTROL",
        procedure: "group.CONTROL",
        getter: "customerInquiry/getGroups",
      },
      {
        Client: client,
        id: "CONTACT",
        procedure: "CONTACT.CONTROL",
        filename: "CONTROL",
        getter: "customerInquiry/getContactTypes",
      },
      {
        id: "MAIL",
        procedure: "MAIL.CONTROL",
        filename: "CONTROL",
        Client: store.getters["session/getClient"],
        getter: "customerInquiry/getMailControls",
      },
      {
        Client: client,
        id: "MRK",
        procedure: "MRK.CONTROL",
        filename: "CONTROL",
        getter: "customerInquiry/getHoldCodes",
      },
      {
        Client: client,
        id: "PLAN",
        procedure: "PLAN.CONTROL",
        filename: "CONTROL",
        getter: "customerInquiry/getPlanGroupCodes",
      },
    ];

    CONTROLS.forEach((element: any) => {
      this.fetchControls(element);
    });

    this.badgeCounts = this.getBadgeCountArray([
      ...this.customerInquiry.CustomerMap,
    ]);
    this.active = this.customerInquiry.custTabIndex;
  },

  data() {
    return {
      activeTabCustomerId: "",
      isCustomerOpened: false,
      active: 0,
      activeInnerTab: 0,
      customerSearchTab: 0,
      customers: [],
      items: [
        {
          label: "General",
          to: "/customers/general",
          command: function () {
            store.dispatch("customerInquiry/saveInnerTabItem", this.to);
          },
          dataTestId: "customers-general-tab-link",
        },
        {
          label: "Ship to Addresses",
          to: "/customers/ship-to-addresses",
          command: function () {
            store.dispatch("customerInquiry/saveInnerTabItem", this.to);
          },
          badge: "ship_seq_items",
        },
        {
          label: "Contacts",
          to: "/customers/contacts",
          command: function () {
            store.dispatch("customerInquiry/saveInnerTabItem", this.to);
          },
          badge: "contact_id_items",
        },
        {
          label: "Contact Log",
          to: "/customers/contact-log",
          command: function () {
            store.dispatch("customerInquiry/saveInnerTabItem", this.to);
          },
          badge: "log_ids",
        },
        {
          label: "Credit Cards",
          to: "/customers/credit-cards",
          command: function () {
            store.dispatch("customerInquiry/saveInnerTabItem", this.to);
          },
          badge: "credit_card_no_items",
        },
        {
          label: "Deal Sheet",
          to: "/customers/deal-sheet",
          command: function () {
            store.dispatch("customerInquiry/saveInnerTabItem", this.to);
          },
          badge: "deal_sheet",
        },
        {
          label: "Change History",
          to: "/customers/change-history",
          command: function () {
            store.dispatch("customerInquiry/saveInnerTabItem", this.to);
          },
          badge: "change_date_items",
        },
        {
          label: "Attachments",
          to: "/customers/attachments",
          command: function () {
            store.dispatch("customerInquiry/saveInnerTabItem", this.to);
          },
          badge: "attachments_items",
        },
        {
          label: "Opportunities",
          to: "/customers/sales/opportunities",
          command: function () {
            store.dispatch("customerInquiry/saveInnerTabItem", this.to);
          },
          badge: "saleopp_ids",
        },
        {
          label: "Quotes",
          to: "/customers/sales/quotes",
          command: function () {
            store.dispatch("customerInquiry/saveInnerTabItem", this.to);
          },
          badge: "soquote_ids",
        },
        {
          label: "Orders",
          to: "/customers/sales/orders",
          command: function () {
            store.dispatch("customerInquiry/saveInnerTabItem", this.to);
          },
          badge: "so_ids",
          dataTestId: "customers-orders-tab-link",
        },
        {
          label: "RMA",
          to: "/customers/rma",
          command: function () {
            store.dispatch("customerInquiry/saveInnerTabItem", this.to);
          },
          badge: "rma_ids",
        },
        {
          label: "Accounts Receivable",
          to: "/customers/ar",
          command: function () {
            store.dispatch("customerInquiry/saveInnerTabItem", this.to);
          },
          badge: "ar_ids",
          dataTestId: "customers-ar-tab-link",
        },
        {
          label: "Tickets & Time",
          to: "/customers/billing",
          command: function () {
            store.dispatch("customerInquiry/saveInnerTabItem", this.to);
          },
          badge: "ticket_ids",
          dataTestId: "customers-billing-tab-link",
        },
        {
          label: "Field Services",
          to: "/customers/fieldservices",
          command: function () {
            store.dispatch("customerInquiry/saveInnerTabItem", this.to);
          },
          badge: "fso_ids",
        },
      ],
      responsiveOptions: [],
      badgeCounts: [] as any[],
    };
  },
  methods: {
    ...mapActions({
      getContacts: "customerInquiry/getContacts",
      saveCustTabIndex: "customerInquiry/saveCustTabIndex",
      saveInnerTabItem: "customerInquiry/saveInnerTabItem",
      removeCustomerTab: "customerInquiry/removeCustomer",
      updateCustomerAction: "customerInquiry/updateCustomerAction",
      fetchControls: "customerInquiry/getControls",
      updateCustomer: "customerInquiry/updateCustomer",
      fetchCustomer: "customerInquiry/getCustomer",
      addRecentlyViewedCustomer: "customerInquiry/addRecentlyViewed",
      removeRecentlyViewedCustomer:
        "customerInquiry/removeRecentlyViewedCustomer",
    }),
    handleCardClick(to: string) {
      const index = this.visibleMenuItems.findIndex((item: any) => {
        return item.to === to;
      });

      if (index >= 0) {
        this.activeInnerTab = index;
        this.saveInnerTabItem(to);
      }
    },
    shippingAddress(customer: Customer) {
      return Utils.customerShippingAddress(customer);
    },
    getBadgeCount(optionLabel: string | undefined, tab: any, index: number) {
      if (
        optionLabel &&
        this.badgeCounts[index] &&
        this.badgeCounts[index][optionLabel] === "0"
      ) {
        return "";
      }

      if (
        optionLabel &&
        this.badgeCounts[index] &&
        this.badgeCounts[index][optionLabel]
      ) {
        return this.badgeCounts[index][optionLabel].toString();
      }

      return optionLabel && tab.customer[optionLabel]
        ? tab.customer[optionLabel].length.toString()
        : "";
    },
    updateBadge(index: number, field: string, value: number) {
      this.badgeCounts[index][field] = value + "";
    },
    navToRoute(path: string) {
      return this.$router.push(path);
    },
    fetchContacts() {
      this.getContacts({
        Client: this.getClient,
      });
    },
    handleTabChange(event: any) {
      this.active = event.index;
      this.badgeCounts = this.getBadgeCountArray([
        ...this.customerInquiry.CustomerMap,
      ]);
      if (event.index) {
        this.saveCustTabIndex(event.index);
        let innerIndex = this.visibleMenuItems.findIndex((item: any) => {
          return (
            item.to ===
            this.customerInquiry.CustomerMap[event.index - 1].custInnerTabValue
          );
        });

        this.customerInquiry.CustomerMap.forEach(
          (customer: any, index: number) => {
            if (event.index === index + 1) {
              this.activeTabCustomerId = customer.customer.cust_id;
            }
          },
        );

        this.activeInnerTab = innerIndex;
        this.$router.push(
          this.customerInquiry.CustomerMap[event.index - 1].custInnerTabValue,
        );
      }
    },
    handleCustomerMenuTabChange(event: any) {
      this.customerSearchTab = event.index;
      this.badgeCounts = this.getBadgeCountArray([
        ...this.customerInquiry.CustomerMap,
      ]);
    },
    handleInnerTabChange(event: any) {
      this.activeInnerTab = event.index;

      this.badgeCounts = this.getBadgeCountArray([
        ...this.customerInquiry.CustomerMap,
      ]);
    },
    removeCustomer(event: any) {
      this.removeCustomerTab(event);
      this.badgeCounts = this.getBadgeCountArray([
        ...this.customerInquiry.CustomerMap,
      ]);
      this.active = 0;
    },
    updateInnerTabValue(value: string) {
      this.saveInnerTabItem(value);
    },
    handleCustomersRowClick(event: any) {
      this.addRecentlyViewedCustomer(event.data);
      this.fetchCustomer({
        id: event.data.cust_id,
        Client: store.getters["session/getClient"],
        correls:
          "saleopp_ids soquote_ids so_ids ticket_ids ar_ids rma_ids log_ids highest_ar_bal avg_ar_bal fso_ids contact_name contact_email bank_token",
      }).then((resp) => {
        // Look for customer in map the change active index to that customer
        const index = this.getOpenCustomers.findIndex((cust: any) => {
          return cust.customer.cust_id === event.data.cust_id;
        });

        if (index != -1) {
          if (this.isCustomerOpened) {
            this.activeInnerTab = 0;
            this.saveInnerTabItem("/customers/general");
            this.$router.push("/customers/general");
          }
          this.active = index + 1;
          this.saveCustTabIndex(index + 1);
        }
      });
    },
    saveCustomer() {
      const { cust_id } = this.getCurrentCustomer;

      this.updateCustomerAction({
        custId: cust_id,
        client: this.getClient,
      }).finally(() => {
        if (!this.getOpenCustomers.length) {
          this.active = 0;
        }
      });
    },
    getBadgeCountArray(customers: any) {
      let aux = [] as any[];
      customers.forEach((elem: any) => {
        aux.push({
          change_date_items: elem.customer.change_date_items
            ? elem.customer.change_date_items.length
            : "",
          saleopp_ids: elem.customer.saleopp_ids
            ? elem.customer.saleopp_ids.length
            : "",
          soquote_ids: elem.customer.soquote_ids
            ? elem.customer.soquote_ids.length
            : "",
          so_ids: elem.customer.so_ids ? elem.customer.so_ids.length : "",
          ticket_ids: elem.customer.ticket_ids
            ? elem.customer.ticket_ids.length
            : "",
          fso_ids: elem.customer.fso_ids ? elem.customer.fso_ids.length : "0",
          deal_sheet: elem.customer.dealsheet_ids
            ? elem.customer.dealsheet_ids.length
            : "",
        });
      });
      return aux;
    },
  },
  watch: {
    getAllCustomers: function (customers) {
      if (customers.cust_items.length === 1) {
        let customer = {
          data: customers.cust_items[0],
        };
        this.handleCustomersRowClick(customer);
      }
      this.active = 0;
      this.customerSearchTab = 1;
    },
    getOpenCustomers: function (customers, oldCustomers) {
      this.badgeCounts = this.getBadgeCountArray([
        ...this.customerInquiry.CustomerMap,
      ]);
      this.active = this.customerInquiry.custTabIndex;

      this.isCustomerOpened = customers.length > oldCustomers.length;
    },
    activeInnerTab: function () {
      this.badgeCounts = this.getBadgeCountArray([
        ...this.customerInquiry.CustomerMap,
      ]);
    },

    customerSearchTab: function () {
      this.badgeCounts = this.getBadgeCountArray([
        ...this.customerInquiry.CustomerMap,
      ]);
    },

    CustomerMap: function () {
      this.badgeCounts = this.getBadgeCountArray([
        ...this.customerInquiry.CustomerMap,
      ]);
    },
    "customerInquiry.CustomerMap": {
      deep: true,
      handler(newVal) {
        newVal.map((customer: any, index: number) => {
          if (this.active === index + 1) {
            this.activeTabCustomerId = customer.customer.cust_id;
          }
        });
      },
    },

    $route: function (from: any, to: any) {
      if (from.path === "/customers/fieldservices/general") {
        this.badgeCounts = this.getBadgeCountArray([
          ...this.customerInquiry.CustomerMap,
        ]);
      }
    },
  },
});

import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withKeys as _withKeys, createVNode as _createVNode, normalizeClass as _normalizeClass, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputText = _resolveComponent("InputText")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.rowClass)
    }, [
      _cache[7] || (_cache[7] = _createElementVNode("span", { class: "p-inputgroup-addon" }, " Employee ID ", -1)),
      _createVNode(_component_InputText, {
        ref: "employee_id",
        class: "scan-input-text",
        "model-value": _ctx.employeeId,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:employeeId', $event))),
        onFocus: _cache[1] || (_cache[1] = ($event: any) => ($event.target.select())),
        onKeypress: _withKeys(_ctx.handleEmployeeID, ["enter"]),
        onKeydown: _withKeys(_ctx.handleEmployeeID, ["down"])
      }, null, 8, ["model-value", "onKeypress", "onKeydown"])
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.rowClass)
    }, [
      _cache[8] || (_cache[8] = _createElementVNode("span", { class: "p-inputgroup-addon" }, " Employee Name ", -1)),
      _createVNode(_component_InputText, {
        ref: "employeeName",
        class: "scan-input-text",
        modelValue: _ctx.employeeName,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.employeeName) = $event)),
        onFocus: _cache[3] || (_cache[3] = ($event: any) => (_ctx.focusInput(_ctx.nextInput))),
        readonly: "",
        onKeypress: _cache[4] || (_cache[4] = _withKeys(($event: any) => (_ctx.focusInput(_ctx.nextInput)), ["enter"])),
        onKeydown: [
          _cache[5] || (_cache[5] = _withKeys(($event: any) => (_ctx.focusInput(_ctx.nextInput)), ["down"])),
          _cache[6] || (_cache[6] = _withKeys(($event: any) => (_ctx.focusInput(_ctx.nextInput)), ["up"]))
        ]
      }, null, 8, ["modelValue"])
    ], 2)
  ], 64))
}
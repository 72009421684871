import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, vShow as _vShow, withDirectives as _withDirectives, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "grid p-0 m-0"
}
const _hoisted_2 = { class: "col-12 p-0 m-0 pr-1" }
const _hoisted_3 = { class: "collapsible-container m-2" }
const _hoisted_4 = { class: "collapsible-item" }
const _hoisted_5 = { class: "payment-quantities pr-3 pt-2" }
const _hoisted_6 = { class: "collapsible-item" }
const _hoisted_7 = { class: "card-container" }
const _hoisted_8 = { class: "collapsible-item" }
const _hoisted_9 = { id: "header-credit-settings" }
const _hoisted_10 = { class: "grid" }
const _hoisted_11 = { class: "col-12" }
const _hoisted_12 = { class: "p-inputgroup" }
const _hoisted_13 = { class: "p-inputgroup mt-2" }
const _hoisted_14 = { class: "p-inputgroup mt-2" }
const _hoisted_15 = { class: "p-inputgroup mt-2" }
const _hoisted_16 = { class: "p-inputgroup mt-2" }
const _hoisted_17 = { class: "field-checkbox mt-2" }
const _hoisted_18 = { style: {"width":"100%"} }
const _hoisted_19 = { id: "header-pricing" }
const _hoisted_20 = { class: "grid" }
const _hoisted_21 = { class: "col-12 lg:col-12" }
const _hoisted_22 = { class: "p-inputgroup" }
const _hoisted_23 = { class: "p-inputgroup mt-2" }
const _hoisted_24 = { class: "p-inputgroup ml-2 pr-2" }
const _hoisted_25 = { class: "field-checkbox mt-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_Skeleton = _resolveComponent("Skeleton")!
  const _component_OrderLineItems = _resolveComponent("OrderLineItems")!
  const _component_OrderTotal = _resolveComponent("OrderTotal")!
  const _component_Card = _resolveComponent("Card")!
  const _component_CollapsibleSection = _resolveComponent("CollapsibleSection")!
  const _component_DropDown = _resolveComponent("DropDown")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_CheckBox = _resolveComponent("CheckBox")!
  const _component_TabPanel = _resolveComponent("TabPanel")!
  const _component_ReceivablesTable = _resolveComponent("ReceivablesTable")!
  const _component_ReceiptHistory = _resolveComponent("ReceiptHistory")!
  const _component_TabView = _resolveComponent("TabView")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", null, [
      _createVNode(_component_TabView, {
        activeIndex: _ctx.getMainActiveTab,
        onTabChange: _ctx.handleMainTabChange,
        scrollable: true
      }, {
        default: _withCtx(() => [
          _createVNode(_component_TabPanel, null, {
            header: _withCtx(() => _cache[12] || (_cache[12] = [
              _createElementVNode("div", { class: "accounting-tab" }, [
                _createElementVNode("span", { class: "align-self-center pr-3 pl-0" }, "Main")
              ], -1)
            ])),
            default: _withCtx(() => [
              (_ctx.editAccess)
                ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                    _createElementVNode("div", _hoisted_2, [
                      _createVNode(_component_Button, {
                        style: {"float":"right"},
                        label: "Release",
                        icon: "pi pi-send",
                        iconPos: "left",
                        onClick: _ctx.handleOrderRelease
                      }, null, 8, ["onClick"])
                    ])
                  ]))
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                  _createVNode(_component_CollapsibleSection, {
                    isOpen: _ctx.sectionStatus.orderDetails,
                    header: "Order Details",
                    "onUpdate:isOpen": _cache[0] || (_cache[0] = ($event: any) => (
                  _ctx.sectionStatus.orderDetails = !_ctx.sectionStatus.orderDetails
                ))
                  }, {
                    content: _withCtx(() => [
                      _createVNode(_component_Card, { style: {
                      minHeight: '381px',
                    } }, {
                        content: _withCtx(() => [
                          _withDirectives(_createElementVNode("div", null, [
                            (_openBlock(), _createElementBlock(_Fragment, null, _renderList(4, (n) => {
                              return _createVNode(_component_Skeleton, {
                                key: n,
                                width: "100%",
                                height: "4rem",
                                style: { marginBottom: '10px' }
                              })
                            }), 64))
                          ], 512), [
                            [_vShow, _ctx.isLoading]
                          ]),
                          _withDirectives(_createElementVNode("div", _hoisted_5, [
                            _createVNode(_component_OrderLineItems, {
                              order: this.order
                            }, null, 8, ["order"]),
                            _createVNode(_component_OrderTotal, {
                              order: this.order
                            }, null, 8, ["order"])
                          ], 512), [
                            [_vShow, !_ctx.isLoading]
                          ])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["isOpen"])
                ]),
                _createElementVNode("div", _hoisted_6, [
                  _createVNode(_component_CollapsibleSection, {
                    isOpen: _ctx.sectionStatus.customerDetails,
                    header: "Customer Details",
                    "onUpdate:isOpen": _cache[11] || (_cache[11] = ($event: any) => (
                  _ctx.sectionStatus.customerDetails = !_ctx.sectionStatus.customerDetails
                ))
                  }, {
                    content: _withCtx(() => [
                      _createElementVNode("div", _hoisted_7, [
                        _createElementVNode("div", _hoisted_8, [
                          _createVNode(_component_Card, null, {
                            content: _withCtx(() => [
                              _withDirectives(_createElementVNode("h4", _hoisted_9, " Credit Settings ", 512), [
                                [_vShow, !_ctx.isLoading]
                              ]),
                              _withDirectives(_createElementVNode("div", null, [
                                (_openBlock(), _createElementBlock(_Fragment, null, _renderList(4, (n) => {
                                  return _createVNode(_component_Skeleton, {
                                    key: n,
                                    width: "100%",
                                    height: "4rem",
                                    style: { marginBottom: '10px' }
                                  })
                                }), 64))
                              ], 512), [
                                [_vShow, _ctx.isLoading]
                              ]),
                              _withDirectives(_createElementVNode("div", _hoisted_10, [
                                _createElementVNode("div", _hoisted_11, [
                                  _createElementVNode("div", _hoisted_12, [
                                    _cache[13] || (_cache[13] = _createElementVNode("small", {
                                      class: "p-inputgroup-addon font-semibold general-label-color",
                                      style: { width: '104px' }
                                    }, "Terms", -1)),
                                    _createVNode(_component_DropDown, {
                                      id: "terms",
                                      modelValue: _ctx.customer.terms,
                                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.customer.terms) = $event)),
                                      options: _ctx.termsList,
                                      placeholder: "Select Terms",
                                      style: {"width":"20px"},
                                      class: "text-sm",
                                      disabled: true
                                    }, null, 8, ["modelValue", "options"])
                                  ]),
                                  _createElementVNode("div", _hoisted_13, [
                                    _cache[14] || (_cache[14] = _createElementVNode("small", {
                                      class: "p-inputgroup-addon font-semibold general-label-color",
                                      style: { width: '104px' }
                                    }, "Currency", -1)),
                                    _createVNode(_component_DropDown, {
                                      modelValue: _ctx.customer.currency_code,
                                      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.customer.currency_code) = $event)),
                                      options: _ctx.currencyList,
                                      placeholder: "Select Currency",
                                      class: "text-sm",
                                      disabled: true
                                    }, null, 8, ["modelValue", "options"])
                                  ]),
                                  _createElementVNode("div", _hoisted_14, [
                                    _cache[15] || (_cache[15] = _createElementVNode("small", {
                                      class: "p-inputgroup-addon font-semibold general-label-color",
                                      style: { width: '104px' }
                                    }, "Dollar Limit", -1)),
                                    _createVNode(_component_InputText, {
                                      id: "dollarlimit",
                                      type: "text",
                                      modelValue: _ctx.customer.credit_limit,
                                      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.customer.credit_limit) = $event)),
                                      class: "text-sm",
                                      disabled: true
                                    }, null, 8, ["modelValue"])
                                  ]),
                                  _createElementVNode("div", _hoisted_15, [
                                    _cache[16] || (_cache[16] = _createElementVNode("small", {
                                      class: "p-inputgroup-addon font-semibold general-label-color",
                                      style: { width: '104px' }
                                    }, "Days Limit", -1)),
                                    _createVNode(_component_InputText, {
                                      type: "text",
                                      modelValue: _ctx.customer.past_due_days,
                                      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.customer.past_due_days) = $event)),
                                      class: "text-sm",
                                      disabled: true
                                    }, null, 8, ["modelValue"])
                                  ]),
                                  _createElementVNode("div", _hoisted_16, [
                                    _cache[17] || (_cache[17] = _createElementVNode("small", {
                                      class: "p-inputgroup-addon font-semibold general-label-color",
                                      style: { width: '104px' }
                                    }, "Bank No", -1)),
                                    _createVNode(_component_InputText, {
                                      type: "text",
                                      modelValue: _ctx.customer.bank_no,
                                      "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.customer.bank_no) = $event)),
                                      class: "text-sm",
                                      disabled: true
                                    }, null, 8, ["modelValue"])
                                  ]),
                                  _createElementVNode("div", _hoisted_17, [
                                    _createVNode(_component_CheckBox, {
                                      id: "credit",
                                      name: "Credit Hold",
                                      modelValue: _ctx.customer.credit_hold,
                                      "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.customer.credit_hold) = $event)),
                                      trueValue: "Y",
                                      falseValue: null,
                                      binary: true,
                                      class: "field-checkbox m-2",
                                      disabled: true
                                    }, null, 8, ["modelValue"]),
                                    _cache[18] || (_cache[18] = _createElementVNode("label", {
                                      for: "credit",
                                      class: "text-sm"
                                    }, "Credit Hold", -1))
                                  ])
                                ])
                              ], 512), [
                                [_vShow, !_ctx.isLoading]
                              ])
                            ]),
                            _: 1
                          })
                        ]),
                        _createElementVNode("div", _hoisted_18, [
                          _createVNode(_component_Card, null, {
                            content: _withCtx(() => [
                              _withDirectives(_createElementVNode("h4", _hoisted_19, " Pricing ", 512), [
                                [_vShow, !_ctx.isLoading]
                              ]),
                              _withDirectives(_createElementVNode("div", null, [
                                (_openBlock(), _createElementBlock(_Fragment, null, _renderList(4, (n) => {
                                  return _createVNode(_component_Skeleton, {
                                    key: n,
                                    width: "100%",
                                    height: "4rem",
                                    style: { marginBottom: '10px' }
                                  })
                                }), 64))
                              ], 512), [
                                [_vShow, _ctx.isLoading]
                              ]),
                              _withDirectives(_createElementVNode("div", _hoisted_20, [
                                _createElementVNode("div", _hoisted_21, [
                                  _createElementVNode("div", _hoisted_22, [
                                    _cache[19] || (_cache[19] = _createElementVNode("small", {
                                      class: "p-inputgroup-addon font-semibold general-label-color",
                                      style: { width: '104px' }
                                    }, "Code", -1)),
                                    _createVNode(_component_DropDown, {
                                      options: _ctx.priceCodes,
                                      modelValue: _ctx.customer.code,
                                      "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.customer.code) = $event)),
                                      placeholder: "Select Code",
                                      class: "text-sm",
                                      disabled: true
                                    }, null, 8, ["options", "modelValue"])
                                  ]),
                                  _createElementVNode("div", _hoisted_23, [
                                    _cache[20] || (_cache[20] = _createElementVNode("small", {
                                      class: "p-inputgroup-addon font-semibold general-label-color",
                                      style: { width: '104px' }
                                    }, "Discount(%)", -1)),
                                    _createVNode(_component_InputText, {
                                      type: "text",
                                      modelValue: _ctx.customer.disc,
                                      "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.customer.disc) = $event)),
                                      class: "text-sm",
                                      disabled: true
                                    }, null, 8, ["modelValue"])
                                  ])
                                ]),
                                _cache[23] || (_cache[23] = _createElementVNode("h4", {
                                  class: "ml-2",
                                  id: "header-bill-to-customer"
                                }, " Bill To Customer ", -1)),
                                _createElementVNode("div", _hoisted_24, [
                                  _cache[21] || (_cache[21] = _createElementVNode("small", {
                                    class: "p-inputgroup-addon font-semibold general-label-color",
                                    style: { width: '104px' }
                                  }, "Bill To", -1)),
                                  _createVNode(_component_InputText, {
                                    type: "text",
                                    modelValue: _ctx.customer.bill_to,
                                    "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.customer.bill_to) = $event)),
                                    class: "text-sm",
                                    disabled: true
                                  }, null, 8, ["modelValue"])
                                ]),
                                _createElementVNode("div", _hoisted_25, [
                                  _createVNode(_component_CheckBox, {
                                    id: "marketing",
                                    name: "Marketing",
                                    modelValue: _ctx.customer.mrk_opt_out,
                                    "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.customer.mrk_opt_out) = $event)),
                                    trueValue: "Y",
                                    falseValue: null,
                                    binary: true,
                                    class: "field-checkbox m-2",
                                    disabled: true
                                  }, null, 8, ["modelValue"]),
                                  _cache[22] || (_cache[22] = _createElementVNode("label", {
                                    for: "marketing",
                                    class: "text-sm"
                                  }, "Exclude from marketing", -1))
                                ])
                              ], 512), [
                                [_vShow, !_ctx.isLoading]
                              ])
                            ]),
                            _: 1
                          })
                        ])
                      ])
                    ]),
                    _: 1
                  }, 8, ["isOpen"])
                ])
              ])
            ]),
            _: 1
          }),
          _createVNode(_component_TabPanel, null, {
            header: _withCtx(() => _cache[24] || (_cache[24] = [
              _createElementVNode("div", { class: "accounting-tab" }, [
                _createElementVNode("span", { class: "align-self-center pr-3 pl-0" }, "Accounts Receivable")
              ], -1)
            ])),
            default: _withCtx(() => [
              _createVNode(_component_Card, null, {
                content: _withCtx(() => [
                  _createVNode(_component_ReceivablesTable, {
                    range: ['', ''],
                    showType: false,
                    showToPay: false,
                    noDefaultSort: true,
                    hideFilters: true
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_TabPanel, null, {
            header: _withCtx(() => _cache[25] || (_cache[25] = [
              _createElementVNode("div", { class: "accounting-tab" }, [
                _createElementVNode("span", { class: "align-self-center pr-3 pl-0" }, "Receipt History")
              ], -1)
            ])),
            default: _withCtx(() => [
              (_ctx.customer?.cust_id)
                ? (_openBlock(), _createBlock(_component_ReceiptHistory, {
                    key: 0,
                    customerId: _ctx.customer?.cust_id
                  }, null, 8, ["customerId"]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }),
          _createVNode(_component_Button, {
            label: "Release",
            icon: "pi pi-send",
            iconPos: "left",
            class: "text-sm font-bold mt-2 align-self-center",
            style: {"height":"40px"},
            onClick: _ctx.handleOrderRelease
          }, null, 8, ["onClick"])
        ]),
        _: 1
      }, 8, ["activeIndex", "onTabChange"])
    ]),
    _createVNode(_component_router_view)
  ]))
}
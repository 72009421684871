import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withKeys as _withKeys, createVNode as _createVNode, normalizeClass as _normalizeClass, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "grid flex justify-content-center mt-2" }
const _hoisted_2 = { class: "col-11 lg:col-11" }
const _hoisted_3 = { class: "grid align-items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Button = _resolveComponent("Button")!
  const _component_MessageBox = _resolveComponent("MessageBox")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.rowClass)
          }, [
            _cache[32] || (_cache[32] = _createElementVNode("span", { class: "p-inputgroup-addon" }, " To Location", -1)),
            _createVNode(_component_InputText, {
              ref: "to_loc",
              class: "scan-input-text",
              modelValue: _ctx.shipmentPayload.to_loc,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.shipmentPayload.to_loc) = $event)),
              onFocus: _cache[1] || (_cache[1] = ($event: any) => ($event.target.select())),
              onKeypress: _withKeys(_ctx.handleToLocation, ["enter"]),
              onKeydown: _withKeys(_ctx.handleToLocation, ["down"])
            }, null, 8, ["modelValue", "onKeypress", "onKeydown"])
          ], 2),
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.rowClass)
          }, [
            _cache[33] || (_cache[33] = _createElementVNode("span", { class: "p-inputgroup-addon" }, " Ship # ", -1)),
            _createVNode(_component_InputText, {
              ref: "ship_id",
              class: "scan-input-text",
              modelValue: _ctx.shipmentPayload.ship_id,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.shipmentPayload.ship_id) = $event)),
              onFocus: _cache[3] || (_cache[3] = ($event: any) => ($event.target.select())),
              onKeypress: _withKeys(_ctx.handleShipId, ["enter"]),
              onKeydown: [
                _withKeys(_ctx.handleShipId, ["down"]),
                _cache[4] || (_cache[4] = _withKeys(($event: any) => (_ctx.focusInput('to_loc')), ["up"]))
              ]
            }, null, 8, ["modelValue", "onKeypress", "onKeydown"])
          ], 2),
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.rowClass)
          }, [
            _cache[34] || (_cache[34] = _createElementVNode("span", { class: "p-inputgroup-addon" }, " From Location ", -1)),
            _createVNode(_component_InputText, {
              ref: "from_loc",
              class: "scan-input-text",
              modelValue: _ctx.shipmentPayload.from_loc,
              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.shipmentPayload.from_loc) = $event)),
              onFocus: _cache[6] || (_cache[6] = ($event: any) => ($event.target.select())),
              onKeypress: _withKeys(_ctx.handleFromLocation, ["enter"]),
              onKeydown: [
                _withKeys(_ctx.handleFromLocation, ["down"]),
                _cache[7] || (_cache[7] = _withKeys(($event: any) => (_ctx.focusInput('from_bin')), ["up"]))
              ]
            }, null, 8, ["modelValue", "onKeypress", "onKeydown"])
          ], 2),
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.rowClass)
          }, [
            _cache[35] || (_cache[35] = _createElementVNode("span", { class: "p-inputgroup-addon" }, " From Bin ", -1)),
            _createVNode(_component_InputText, {
              ref: "from_bin",
              class: "scan-input-text",
              modelValue: _ctx.shipmentPayload.from_bin,
              "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.shipmentPayload.from_bin) = $event)),
              onFocus: _cache[9] || (_cache[9] = ($event: any) => ($event.target.select())),
              onKeypress: _withKeys(_ctx.handleFromBin, ["enter"]),
              onKeydown: [
                _withKeys(_ctx.handleFromBin, ["down"]),
                _cache[10] || (_cache[10] = _withKeys(($event: any) => (_ctx.focusInput('from_loc')), ["up"]))
              ]
            }, null, 8, ["modelValue", "onKeypress", "onKeydown"])
          ], 2),
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.rowClass)
          }, [
            _cache[36] || (_cache[36] = _createElementVNode("span", { class: "p-inputgroup-addon" }, " Part # ", -1)),
            _createVNode(_component_InputText, {
              ref: "part",
              class: "scan-input-text",
              modelValue: _ctx.shipmentPayload.part,
              "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.shipmentPayload.part) = $event)),
              onFocus: _cache[12] || (_cache[12] = ($event: any) => ($event.target.select())),
              onKeypress: _withKeys(_ctx.handlePart, ["enter"]),
              onKeydown: [
                _withKeys(_ctx.handlePart, ["down"]),
                _cache[13] || (_cache[13] = _withKeys(($event: any) => (_ctx.focusInput('from_bin')), ["up"]))
              ]
            }, null, 8, ["modelValue", "onKeypress", "onKeydown"])
          ], 2),
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.rowClass)
          }, [
            _cache[37] || (_cache[37] = _createElementVNode("span", { class: "p-inputgroup-addon" }, " Lot # ", -1)),
            _createVNode(_component_InputText, {
              ref: "lot",
              class: "scan-input-text",
              modelValue: _ctx.shipmentPayload.lot,
              "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.shipmentPayload.lot) = $event)),
              onFocus: _cache[15] || (_cache[15] = ($event: any) => ($event.target.select())),
              onKeypress: _withKeys(_ctx.handleLot, ["enter"]),
              onKeydown: [
                _withKeys(_ctx.handleLot, ["down"]),
                _cache[16] || (_cache[16] = _withKeys(($event: any) => (_ctx.focusInput('part')), ["up"]))
              ]
            }, null, 8, ["modelValue", "onKeypress", "onKeydown"])
          ], 2),
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.rowClass)
          }, [
            _cache[38] || (_cache[38] = _createElementVNode("span", { class: "p-inputgroup-addon" }, " Balance ", -1)),
            _createVNode(_component_InputText, {
              ref: "balance",
              class: "scan-input-text",
              modelValue: _ctx.balance,
              "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.balance) = $event)),
              onKeypress: _cache[18] || (_cache[18] = _withKeys(($event: any) => (_ctx.focusInput('lot')), ["enter"])),
              onKeydown: [
                _cache[19] || (_cache[19] = _withKeys(($event: any) => (_ctx.focusInput('lot')), ["down"])),
                _cache[20] || (_cache[20] = _withKeys(($event: any) => (_ctx.focusInput('from_bin')), ["up"]))
              ],
              readonly: ""
            }, null, 8, ["modelValue"])
          ], 2),
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.rowClass)
          }, [
            _cache[39] || (_cache[39] = _createElementVNode("span", { class: "p-inputgroup-addon" }, " Quantity ", -1)),
            _createVNode(_component_InputText, {
              ref: "quantity",
              class: "scan-input-text",
              modelValue: _ctx.shipmentPayload.quantity,
              "onUpdate:modelValue": _cache[21] || (_cache[21] = ($event: any) => ((_ctx.shipmentPayload.quantity) = $event)),
              onFocus: _cache[22] || (_cache[22] = ($event: any) => ($event.target.select())),
              onKeypress: _withKeys(_ctx.handleQuantity, ["enter"]),
              onKeydown: [
                _withKeys(_ctx.handleQuantity, ["down"]),
                _cache[23] || (_cache[23] = _withKeys(($event: any) => (_ctx.focusInput('balance')), ["up"]))
              ]
            }, null, 8, ["modelValue", "onKeypress", "onKeydown"])
          ], 2),
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.rowClass)
          }, [
            _createVNode(_component_Button, {
              label: "Save",
              loading: _ctx.loadingSaveButton,
              onClick: _ctx.start,
              class: "scan-input-button"
            }, null, 8, ["loading", "onClick"])
          ], 2),
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.rowClass)
          }, [
            _createVNode(_component_Button, {
              label: "Exit",
              loading: _ctx.loadingExitButton,
              onClick: _ctx.handleExitTime,
              class: "scan-input-button"
            }, null, 8, ["loading", "onClick"])
          ], 2)
        ])
      ])
    ]),
    _createVNode(_component_MessageBox, {
      showDialog: _ctx.showIsShipComplete,
      icon: "INFO",
      header: "Done?",
      message: "Is the picking for this shipment complete?",
      primaryButton: "Yes",
      cancelButton: "No",
      onPrimaryButtonClick: _cache[24] || (_cache[24] = ($event: any) => (_ctx.clickedIsShipComplete(true))),
      onCancelButtonClick: _cache[25] || (_cache[25] = ($event: any) => (_ctx.clickedIsShipComplete(false)))
    }, null, 8, ["showDialog"]),
    _createVNode(_component_MessageBox, {
      showDialog: _ctx.showOpenItemList,
      icon: "INFO",
      header: "Continue Scanning?",
      message: _ctx.openItemListMessage,
      primaryButton: "Yes",
      cancelButton: "No",
      onPrimaryButtonClick: _cache[26] || (_cache[26] = ($event: any) => (_ctx.clickedOpenItemList(true))),
      onCancelButtonClick: _cache[27] || (_cache[27] = ($event: any) => (_ctx.clickedOpenItemList(false)))
    }, null, 8, ["showDialog", "message"]),
    _createVNode(_component_MessageBox, {
      showDialog: _ctx.showConfirmPickDone,
      icon: "INFO",
      header: "Verify Complete?",
      message: "Do you want to complete this shipment pull short?",
      primaryButton: "Yes",
      cancelButton: "No",
      onPrimaryButtonClick: _cache[28] || (_cache[28] = ($event: any) => (_ctx.clickedConfirmPickDone(true))),
      onCancelButtonClick: _cache[29] || (_cache[29] = ($event: any) => (_ctx.clickedConfirmPickDone(false)))
    }, null, 8, ["showDialog"]),
    _createVNode(_component_MessageBox, {
      showDialog: _ctx.showConfirmQuantity,
      icon: "INFO",
      header: "Warning!",
      message: _ctx.messageConfirmQuantity,
      primaryButton: "Yes",
      cancelButton: "No",
      onPrimaryButtonClick: _cache[30] || (_cache[30] = ($event: any) => (_ctx.clickedConfirmQuantity(true))),
      onCancelButtonClick: _cache[31] || (_cache[31] = ($event: any) => (_ctx.clickedConfirmQuantity(false)))
    }, null, 8, ["showDialog", "message"]),
    _createVNode(_component_MessageBox, {
      showDialog: _ctx.showErrorDialog,
      icon: "INFO",
      header: "Error",
      message: _ctx.errorMessage,
      primaryButton: "OK",
      onPrimaryButtonClick: _ctx.clickConfirmErrorDialog
    }, null, 8, ["showDialog", "message", "onPrimaryButtonClick"])
  ], 64))
}
import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, createVNode as _createVNode, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "col-12" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "mt-2" }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { class: "mt-2" }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { class: "flex justify-content-center" }
const _hoisted_8 = { class: "flex justify-content-center" }
const _hoisted_9 = {
  key: 0,
  class: "mt-2 sales-quotes-board-wrapper"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_LookupDataTable = _resolveComponent("LookupDataTable")!
  const _component_LoadingSpinner = _resolveComponent("LoadingSpinner")!
  const _component_Column = _resolveComponent("Column")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_MultiselectWithButton = _resolveComponent("MultiselectWithButton")!
  const _component_Calendar = _resolveComponent("Calendar")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _component_KanbanView = _resolveComponent("KanbanView")!
  const _component_SalesDialog = _resolveComponent("SalesDialog")!
  const _component_EmailFileDialog = _resolveComponent("EmailFileDialog")!
  const _component_OrderSalesActionDialog = _resolveComponent("OrderSalesActionDialog")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.lookupName)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_LookupDataTable, {
              ref: "lookupDataTable",
              lookup: _ctx.getLookup,
              params: _ctx.getLookupParams,
              lazyLoadPagination: true,
              manualLoad: _ctx.manuallyLoadOrders,
              tableClass: "",
              onRowClick: _ctx.rowClick,
              onOnLoad: _ctx.onLookupLoad,
              getFullRecords: ""
            }, {
              actions: _withCtx(({ data }) => [
                _withDirectives((_openBlock(), _createBlock(_component_Button, {
                  id: "downloadButton",
                  title: "Download",
                  onClick: ($event: any) => (_ctx.getSalesOrderPDF(data.so_id)),
                  style: {
                width: '37px',
                height: '37px',
                justifyContent: 'center',
                alignItems: 'center',
                margin: '2px',
              }
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("i", {
                      class: _normalizeClass(_ctx.salePDFIcon(data.so_id))
                    }, null, 2)
                  ]),
                  _: 2
                }, 1032, ["onClick"])), [
                  [
                    _directive_tooltip,
                    'Generate PDF Sales Order ' + data.so_id,
                    void 0,
                    { left: true }
                  ]
                ]),
                _withDirectives(_createVNode(_component_Button, {
                  id: "emailButton",
                  icon: "pi pi-envelope",
                  title: "Email",
                  onClick: ($event: any) => (_ctx.handleEmailPDF(data)),
                  style: {
                width: '37px',
                height: '37px',
                justifyContent: 'center',
                alignItems: 'center',
                margin: '2px',
              }
                }, null, 8, ["onClick"]), [
                  [
                    _directive_tooltip,
                    'Email PDF Sales Order ' + data.so_id,
                    void 0,
                    { left: true }
                  ]
                ])
              ]),
              _: 1
            }, 8, ["lookup", "params", "manualLoad", "onRowClick", "onOnLoad"])
          ])
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createVNode(_component_LoadingSpinner, { loading: _ctx.isLoadingSales }, null, 8, ["loading"]),
          _createElementVNode("div", _hoisted_5, [
            (_ctx.isResultView && !_ctx.isLoadingSales)
              ? (_openBlock(), _createBlock(_component_DataTable, {
                  key: 0,
                  value: _ctx.items,
                  breakpoint: "1267px",
                  dataKey: "so_id",
                  rows: _ctx.rowsPerPage,
                  rowsPerPageOptions: [10, 25, 50, 100],
                  selectionMode: _ctx.showSelectionCheckbox ? 'single' : undefined,
                  paginator: true,
                  sortField: _ctx.sortField,
                  sortOrder: _ctx.sortOrder,
                  responsiveLayout: "stack",
                  lazy: true,
                  loading: _ctx.isLoadingSales,
                  onPage: _ctx.onPage,
                  onSort: _cache[29] || (_cache[29] = ($event: any) => (_ctx.sortData($event))),
                  onFilter: _ctx.handleFilter,
                  onRowClick: _ctx.rowClick,
                  first: _ctx.first,
                  "onUpdate:first": _cache[30] || (_cache[30] = ($event: any) => ((_ctx.first) = $event)),
                  filters: _ctx.filters,
                  "onUpdate:filters": _cache[31] || (_cache[31] = ($event: any) => ((_ctx.filters) = $event)),
                  filterDisplay: "menu",
                  "total-records": _ctx.displayTotalRecords,
                  paginatorTemplate: "FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink  RowsPerPageDropdown CurrentPageReport",
                  currentPageReportTemplate: "Total Records: {totalRecords}"
                }, {
                  empty: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.emptyTableLabel), 1)
                  ]),
                  default: _withCtx(() => [
                    (_ctx.showSelectionCheckbox)
                      ? (_openBlock(), _createBlock(_component_Column, {
                          key: 0,
                          style: {"width":"3em"},
                          selectionMode: "multiple"
                        }))
                      : _createCommentVNode("", true),
                    _createVNode(_component_Column, {
                      field: "so_id",
                      header: "ID",
                      showAddButton: false,
                      showFilterMatchModes: false,
                      showFilterOperator: false,
                      sortable: _ctx.isResultView
                    }, {
                      body: _withCtx(({ data }) => [
                        _createTextVNode(_toDisplayString(data.so_id), 1)
                      ]),
                      filter: _withCtx(() => [
                        _createVNode(_component_InputText, {
                          type: "text",
                          modelValue: _ctx.selectedFilterId,
                          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedFilterId) = $event)),
                          class: "p-column-filter",
                          placeholder: "Search by Id"
                        }, null, 8, ["modelValue"])
                      ]),
                      filterclear: _withCtx(() => [
                        _createVNode(_component_Button, {
                          type: "button",
                          label: "Clear",
                          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.clearSalesSearchBar()))
                        })
                      ]),
                      filterapply: _withCtx(() => [
                        _createVNode(_component_Button, {
                          type: "button",
                          label: "Apply",
                          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.fetchOrdersData(false, false)))
                        })
                      ]),
                      _: 1
                    }, 8, ["sortable"]),
                    (_ctx.customerIndex == null)
                      ? (_openBlock(), _createBlock(_component_Column, {
                          key: 1,
                          field: "cust_name",
                          header: "Customer",
                          showAddButton: false,
                          showFilterMatchModes: false,
                          showFilterOperator: false,
                          sortable: _ctx.isResultView
                        }, {
                          filter: _withCtx(() => [
                            _createVNode(_component_MultiselectWithButton, {
                              value: _ctx.selectedFilterCusts,
                              options: _ctx.searchedFilterCusts,
                              filterFields: ['name', 'cust_id'],
                              filter: true,
                              optionLabel: "name",
                              placeHolder: "Enter Customer ID or Name",
                              maxSelectedLabels: 2,
                              btnLabel: "Search",
                              onBtnCallback: _cache[3] || (_cache[3] = ($event: any) => (_ctx.filterCust())),
                              onUpdateFilterValue: _cache[4] || (_cache[4] = 
                  (filterValue) => (this.selectedFilterCusts = filterValue)
                ),
                              onFilter: _cache[5] || (_cache[5] = ($event: any) => (_ctx.handleCustomerSelectFilter($event)))
                            }, null, 8, ["value", "options"])
                          ]),
                          filterclear: _withCtx(() => [
                            _createVNode(_component_Button, {
                              type: "button",
                              label: "Clear",
                              onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.clearCustomerSearch()))
                            })
                          ]),
                          filterapply: _withCtx(() => [
                            _createVNode(_component_Button, {
                              type: "button",
                              label: "Search",
                              onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.filterCust()))
                            })
                          ]),
                          _: 1
                        }, 8, ["sortable"]))
                      : _createCommentVNode("", true),
                    (_ctx.isResultView)
                      ? (_openBlock(), _createBlock(_component_Column, {
                          key: 2,
                          field: "stamp_id",
                          header: "Created By",
                          showAddButton: false,
                          showFilterMatchModes: false,
                          showFilterOperator: false,
                          sortable: _ctx.isResultView
                        }, {
                          body: _withCtx((slotProps) => [
                            _createElementVNode("span", null, _toDisplayString(slotProps.data.stamp_id), 1)
                          ]),
                          filter: _withCtx(() => [
                            _createVNode(_component_InputText, {
                              type: "text",
                              modelValue: _ctx.selectedCreatedBy,
                              "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.selectedCreatedBy) = $event)),
                              class: "p-column-filter",
                              placeholder: "Search by Id"
                            }, null, 8, ["modelValue"])
                          ]),
                          filterclear: _withCtx(() => [
                            _createVNode(_component_Button, {
                              type: "button",
                              label: "Clear",
                              onClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.clearCreatedBySearchBar()))
                            })
                          ]),
                          filterapply: _withCtx(() => [
                            _createVNode(_component_Button, {
                              type: "button",
                              label: "Apply",
                              onClick: _cache[10] || (_cache[10] = ($event: any) => (_ctx.fetchOrdersData(false, false)))
                            })
                          ]),
                          _: 1
                        }, 8, ["sortable"]))
                      : _createCommentVNode("", true),
                    (_ctx.isResultView)
                      ? (_openBlock(), _createBlock(_component_Column, {
                          key: 3,
                          field: "rep_name",
                          header: "Reps",
                          sortable: _ctx.isResultView
                        }, {
                          body: _withCtx((slotProps) => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(slotProps.data.rep_items, (rep, index) => {
                              return (_openBlock(), _createElementBlock("span", { key: index }, [
                                _createTextVNode(_toDisplayString(rep.rep_name) + " ", 1),
                                (index < slotProps.data.rep_items.length - 1)
                                  ? (_openBlock(), _createElementBlock("br", _hoisted_6))
                                  : _createCommentVNode("", true)
                              ]))
                            }), 128))
                          ]),
                          filter: _withCtx(() => [
                            _createVNode(_component_MultiselectWithButton, {
                              value: _ctx.selectedRepType,
                              options: _ctx.getFilteredReps,
                              optionLabel: "name",
                              optionValue: "rep_id",
                              dataKey: "rep_id",
                              placeHolder: "Filter by Rep",
                              filter: true,
                              filterMatchMode: "startsWith",
                              style: { width: '200px' },
                              btnLabel: "Apply",
                              onBtnCallback: _cache[11] || (_cache[11] = ($event: any) => (_ctx.fetchOrdersData(false, false))),
                              onUpdateFilterValue: _cache[12] || (_cache[12] = 
                  (filterValue) => (this.selectedRepType = filterValue)
                )
                            }, null, 8, ["value", "options"])
                          ]),
                          filterclear: _withCtx(() => [
                            _createVNode(_component_Button, {
                              type: "button",
                              label: "Clear",
                              onClick: _cache[13] || (_cache[13] = ($event: any) => (_ctx.clearRepTypeSearchBar()))
                            })
                          ]),
                          filterapply: _withCtx(() => [
                            _createVNode(_component_Button, {
                              type: "button",
                              label: "Apply",
                              onClick: _cache[14] || (_cache[14] = ($event: any) => (_ctx.fetchOrdersData(false, false)))
                            })
                          ]),
                          _: 1
                        }, 8, ["sortable"]))
                      : _createCommentVNode("", true),
                    (_ctx.isResultView)
                      ? (_openBlock(), _createBlock(_component_Column, {
                          key: 4,
                          field: "type",
                          header: "Type",
                          sortable: _ctx.isResultView
                        }, {
                          filter: _withCtx(() => [
                            _createVNode(_component_MultiselectWithButton, {
                              value: _ctx.selectedFilterType,
                              options: _ctx.unassignedSaleType,
                              optionLabel: "so_type_code_description",
                              optionValue: "valid_so_type",
                              dataKey: "valid_so_type",
                              placeHolder: "Filter by Type",
                              filter: true,
                              filterMatchMode: "startsWith",
                              style: { width: '200px' },
                              btnLabel: "Apply",
                              onBtnCallback: _cache[15] || (_cache[15] = ($event: any) => (_ctx.fetchOrdersData(false, false))),
                              onUpdateFilterValue: _cache[16] || (_cache[16] = 
                  (filterValue) => (this.selectedFilterType = filterValue)
                )
                            }, null, 8, ["value", "options"])
                          ]),
                          filterclear: _withCtx(() => [
                            _createVNode(_component_Button, {
                              type: "button",
                              label: "Clear",
                              onClick: _cache[17] || (_cache[17] = ($event: any) => (_ctx.clearTypeSearchBar()))
                            })
                          ]),
                          filterapply: _withCtx(() => [
                            _createVNode(_component_Button, {
                              type: "button",
                              label: "Apply",
                              onClick: _cache[18] || (_cache[18] = ($event: any) => (_ctx.fetchOrdersData(false, false)))
                            })
                          ]),
                          body: _withCtx((slotProps) => [
                            _createElementVNode("div", _hoisted_7, [
                              _createElementVNode("span", {
                                class: _normalizeClass(slotProps.data.type ? 'status-column' : '')
                              }, _toDisplayString(_ctx.getTypeCodeDescription(slotProps.data.type)), 3)
                            ])
                          ]),
                          _: 1
                        }, 8, ["sortable"]))
                      : _createCommentVNode("", true),
                    _createVNode(_component_Column, {
                      field: "date",
                      header: "Order Date",
                      showAddButton: false,
                      showFilterMatchModes: true,
                      showFilterOperator: false,
                      sortable: _ctx.isResultView,
                      headerStyle: "text-align: center",
                      bodyStyle: "text-align: center"
                    }, {
                      body: _withCtx(({ data }) => [
                        _createTextVNode(_toDisplayString(this.formatStringDate(data.date)), 1)
                      ]),
                      filter: _withCtx(() => [
                        _createVNode(_component_Calendar, {
                          modelValue: _ctx.selectedFilterDate,
                          "onUpdate:modelValue": _cache[19] || (_cache[19] = ($event: any) => ((_ctx.selectedFilterDate) = $event)),
                          dateFormat: "mm-dd-yy",
                          class: "p-column-filter",
                          selectionMode: "range",
                          placeholder: "Select Date Range"
                        }, null, 8, ["modelValue"])
                      ]),
                      filterclear: _withCtx(() => [
                        _createVNode(_component_Button, {
                          type: "button",
                          label: "Clear",
                          onClick: _cache[20] || (_cache[20] = ($event: any) => (_ctx.clearDateFilter()))
                        })
                      ]),
                      filterapply: _withCtx(() => [
                        _createVNode(_component_Button, {
                          type: "button",
                          label: "Apply",
                          onClick: _cache[21] || (_cache[21] = ($event: any) => (_ctx.fetchOrdersData(false, false)))
                        })
                      ]),
                      _: 1
                    }, 8, ["sortable"]),
                    _createVNode(_component_Column, {
                      field: "status",
                      header: "Status",
                      showAddButton: false,
                      showFilterMatchModes: false,
                      showFilterOperator: false,
                      sortable: _ctx.isResultView
                    }, {
                      filter: _withCtx(() => [
                        _createVNode(_component_MultiselectWithButton, {
                          value: _ctx.selectedFilterStatus,
                          options: _ctx.status,
                          optionLabel: "status",
                          optionValue: "initial",
                          dataKey: "initial",
                          placeHolder: "Filter by Status",
                          filter: true,
                          filterMatchMode: "startsWith",
                          style: { width: '200px' },
                          btnLabel: "Apply",
                          onBtnCallback: _cache[22] || (_cache[22] = ($event: any) => (_ctx.fetchOrdersData(false, false))),
                          onUpdateFilterValue: _cache[23] || (_cache[23] = 
                  (filterValue) =>
                    (this.selectedFilterStatus = filterValue)
                )
                        }, null, 8, ["value", "options"])
                      ]),
                      filterclear: _withCtx(() => [
                        _createVNode(_component_Button, {
                          type: "button",
                          label: "Clear",
                          onClick: _cache[24] || (_cache[24] = ($event: any) => (_ctx.clearStatusSearchBar()))
                        })
                      ]),
                      filterapply: _withCtx(() => [
                        _createVNode(_component_Button, {
                          type: "button",
                          label: "Apply",
                          onClick: _cache[25] || (_cache[25] = ($event: any) => (_ctx.fetchOrdersData(false, false)))
                        })
                      ]),
                      body: _withCtx((slotProps) => [
                        _createElementVNode("div", _hoisted_8, [
                          _createElementVNode("span", {
                            class: _normalizeClass({ 'status-column': slotProps.data.status }),
                            style: {"min-width":"67px","height":"25px","text-align":"center","word-wrap":"break-word"}
                          }, _toDisplayString(_ctx.statusLabel[slotProps.data.status]), 3)
                        ])
                      ]),
                      _: 1
                    }, 8, ["sortable"]),
                    _createVNode(_component_Column, {
                      field: "po_number",
                      header: "Purchase Order #",
                      showAddButton: false,
                      showFilterMatchModes: false,
                      showFilterOperator: false,
                      sortable: _ctx.isResultView
                    }, {
                      body: _withCtx(({ data }) => [
                        _createTextVNode(_toDisplayString(data.po_number), 1)
                      ]),
                      filter: _withCtx(() => [
                        _createVNode(_component_InputText, {
                          type: "text",
                          modelValue: _ctx.selectedPONumber,
                          "onUpdate:modelValue": _cache[26] || (_cache[26] = ($event: any) => ((_ctx.selectedPONumber) = $event)),
                          class: "p-column-filter",
                          placeholder: "Search by Id"
                        }, null, 8, ["modelValue"])
                      ]),
                      filterclear: _withCtx(() => [
                        _createVNode(_component_Button, {
                          type: "button",
                          label: "Clear",
                          onClick: _cache[27] || (_cache[27] = ($event: any) => (_ctx.clearPONumberSearchBar()))
                        })
                      ]),
                      filterapply: _withCtx(() => [
                        _createVNode(_component_Button, {
                          type: "button",
                          label: "Apply",
                          onClick: _cache[28] || (_cache[28] = ($event: any) => (_ctx.fetchOrdersData(false, false)))
                        })
                      ]),
                      _: 1
                    }, 8, ["sortable"]),
                    (_ctx.isResultView)
                      ? (_openBlock(), _createBlock(_component_Column, {
                          key: 5,
                          field: "order_amount",
                          header: "Order Total",
                          bodyStyle: "text-align: right; padding-right: 1rem"
                        }, {
                          body: _withCtx(({ data }) => [
                            _createTextVNode(_toDisplayString(_ctx.formatCurrency(data.order_amount)), 1)
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true),
                    (_ctx.isResultView)
                      ? (_openBlock(), _createBlock(_component_Column, { key: 6 }, {
                          body: _withCtx(({ data }) => [
                            _withDirectives((_openBlock(), _createBlock(_component_Button, {
                              id: "downloadButton",
                              title: "Download",
                              onClick: ($event: any) => (_ctx.getSalesOrderPDF(data.so_id)),
                              style: {
                  width: '37px',
                  height: '37px',
                  justifyContent: 'center',
                  alignItems: 'center',
                  margin: '2px',
                }
                            }, {
                              default: _withCtx(() => [
                                _createElementVNode("i", {
                                  class: _normalizeClass(_ctx.salePDFIcon(data.so_id))
                                }, null, 2)
                              ]),
                              _: 2
                            }, 1032, ["onClick"])), [
                              [
                                _directive_tooltip,
                                'Generate PDF Sales Order ' + data.so_id,
                                void 0,
                                { left: true }
                              ]
                            ]),
                            _withDirectives(_createVNode(_component_Button, {
                              id: "emailButton",
                              icon: "pi pi-envelope",
                              title: "Email",
                              onClick: ($event: any) => (_ctx.handleEmailPDF(data)),
                              style: {
                  width: '37px',
                  height: '37px',
                  justifyContent: 'center',
                  alignItems: 'center',
                  margin: '2px',
                }
                            }, null, 8, ["onClick"]), [
                              [
                                _directive_tooltip,
                                'Email PDF Sales Order ' + data.so_id,
                                void 0,
                                { left: true }
                              ]
                            ])
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                }, 8, ["value", "rows", "selectionMode", "sortField", "sortOrder", "loading", "onPage", "onFilter", "onRowClick", "first", "filters", "total-records"]))
              : _createCommentVNode("", true)
          ]),
          (!_ctx.isResultView && !_ctx.isLoadingSales)
            ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                _createVNode(_component_KanbanView, {
                  lists: _ctx.formatedLists,
                  section: "orders",
                  loading: false,
                  onOnClickEdit: _ctx.rowClick,
                  onOnStatusChange: _ctx.onStatusChange
                }, null, 8, ["lists", "onOnClickEdit", "onOnStatusChange"])
              ]))
            : _createCommentVNode("", true)
        ])),
    _createVNode(_component_SalesDialog, {
      order: this.salesOrder,
      show: this.showSalesDialog,
      readOnly: this.readOnly,
      orderId: this.id,
      showLoadExistingQuoteBtn: this.showLoadExistingQuoteBtn,
      onHide: _cache[32] || (_cache[32] = 
        (hidden) => {
          this.salesOrder = null;
          this.showSalesDialog = hidden;
        }
      ),
      onOnSave: _cache[33] || (_cache[33] = (event) => {})
    }, null, 8, ["order", "show", "readOnly", "orderId", "showLoadExistingQuoteBtn"]),
    _createVNode(_component_EmailFileDialog, {
      show: _ctx.showEmailDialog,
      header: 'Email Sales Order ' + _ctx.orderIdToEmail,
      onHide: _cache[34] || (_cache[34] = 
        (isHidden) => {
          _ctx.showEmailDialog = isHidden;
        }
      ),
      fileId: _ctx.orderIdToEmail,
      fileName: _ctx.orderIdToEmail,
      onOnEmail: _ctx.sendEmail,
      contacts: _ctx.customerContacts
    }, null, 8, ["show", "header", "fileId", "fileName", "onOnEmail", "contacts"]),
    _createVNode(_component_OrderSalesActionDialog, {
      show: _ctx.showOrderSalesActionDialog,
      order: this.salesOrder,
      header: "Sales Actions",
      modal: "true",
      onHide: _cache[35] || (_cache[35] = 
        (isHidden) => {
          _ctx.showOrderSalesActionDialog = isHidden;
        }
      )
    }, null, 8, ["show", "order"])
  ]))
}
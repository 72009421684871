import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withKeys as _withKeys, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, toDisplayString as _toDisplayString, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "w-19rem" }
const _hoisted_2 = { class: "p-inputgroup pb-2" }
const _hoisted_3 = {
  key: 0,
  class: "p-inputgroup pb-2"
}
const _hoisted_4 = {
  key: 1,
  class: "p-inputgroup pb-2"
}
const _hoisted_5 = {
  key: 2,
  class: "p-inputgroup pb-2"
}
const _hoisted_6 = {
  key: 3,
  class: "p-inputgroup pb-2"
}
const _hoisted_7 = {
  key: 4,
  class: "p-inputgroup pb-2"
}
const _hoisted_8 = {
  key: 0,
  class: "flex align-items-center"
}
const _hoisted_9 = { class: "flex align-items-center" }
const _hoisted_10 = { class: "p-inputgroup pb-2" }
const _hoisted_11 = { class: "p-inputgroup pb-2" }
const _hoisted_12 = { class: "p-inputgroup pb-2" }
const _hoisted_13 = { style: {"display":"flex","flex-direction":"column"} }
const _hoisted_14 = {
  key: 5,
  class: "flex justify-content-between pt-3"
}
const _hoisted_15 = {
  key: 6,
  class: "flex justify-content-start-between pt-3"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_Calendar = _resolveComponent("Calendar")!
  const _component_Textarea = _resolveComponent("Textarea")!
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createElementVNode("div", _hoisted_2, [
      _cache[11] || (_cache[11] = _createElementVNode("small", {
        class: "p-inputgroup-addon font-semibold general-label-color",
        style: { width: '105px', minWidth: '105px' }
      }, " Title: ", -1)),
      _createVNode(_component_InputText, {
        modelValue: _ctx.barSelectedLocal.barLabel,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.barSelectedLocal.barLabel) = $event)),
        type: "text",
        class: "text-sm w-full",
        onKeydown: _withKeys(_ctx.onSaveBarData, ["enter"]),
        ref: "barSelectedLocalInputText"
      }, null, 8, ["modelValue", "onKeydown"])
    ], 512), [
      [_vShow, _ctx.isGenericBar]
    ]),
    (!_ctx.isGenericBar)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _cache[12] || (_cache[12] = _createElementVNode("small", {
            class: "p-inputgroup-addon font-semibold general-label-color",
            style: { width: '105px', minWidth: '105px' }
          }, " Work Order # ", -1)),
          _createVNode(_component_InputText, {
            disabled: "",
            modelValue: _ctx.workOrderLocal.wo_id,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.workOrderLocal.wo_id) = $event)),
            type: "text",
            class: "text-sm w-full"
          }, null, 8, ["modelValue"])
        ]))
      : _createCommentVNode("", true),
    (!_ctx.isGenericBar)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _cache[13] || (_cache[13] = _createElementVNode("small", {
            class: "p-inputgroup-addon font-semibold general-label-color",
            style: { width: '105px', minWidth: '105px' }
          }, " Operation # ", -1)),
          _createVNode(_component_InputText, {
            disabled: "",
            modelValue: _ctx.barSelectedLocal.oper,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.barSelectedLocal.oper) = $event)),
            type: "text",
            class: "text-sm w-full"
          }, null, 8, ["modelValue"])
        ]))
      : _createCommentVNode("", true),
    (!_ctx.isGenericBar)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _cache[14] || (_cache[14] = _createElementVNode("small", {
            class: "p-inputgroup-addon font-semibold general-label-color",
            style: { width: '105px', minWidth: '105px' }
          }, " Part # ", -1)),
          _createVNode(_component_InputText, {
            disabled: "",
            modelValue: _ctx.workOrderLocal.part_no,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.workOrderLocal.part_no) = $event)),
            type: "text",
            class: "text-sm w-full"
          }, null, 8, ["modelValue"])
        ]))
      : _createCommentVNode("", true),
    (!_ctx.isGenericBar)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          _cache[15] || (_cache[15] = _createElementVNode("small", {
            class: "p-inputgroup-addon font-semibold general-label-color",
            style: { width: '105px', minWidth: '105px' }
          }, " Quantity ", -1)),
          _createVNode(_component_InputText, {
            modelValue: _ctx.workOrderLocal.wo_qty,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.workOrderLocal.wo_qty) = $event)),
            type: "text",
            class: "text-sm w-full",
            disabled: true
          }, null, 8, ["modelValue"])
        ]))
      : _createCommentVNode("", true),
    (!_ctx.isGenericBar)
      ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
          _cache[16] || (_cache[16] = _createElementVNode("small", {
            class: "p-inputgroup-addon font-semibold general-label-color",
            style: { width: '105px', minWidth: '105px' }
          }, " Work Center ", -1)),
          _createVNode(_component_Dropdown, {
            modelValue: _ctx.barSelectedLocal.workCenter,
            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.barSelectedLocal.workCenter) = $event)),
            options: _ctx.workCenterList,
            class: "text-sm w-full",
            optionLabel: "description",
            optionValue: "work_center",
            disabled: !_ctx.isWorkOrderEditable(_ctx.workOrderLocal)
          }, {
            value: _withCtx(({ value }) => [
              value
                ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                    _createElementVNode("div", {
                      style: _normalizeStyle([{"height":"20px","width":"6px","border-radius":"2px"}, { backgroundColor: _ctx.dotColor(value) }]),
                      class: "mr-2"
                    }, null, 4),
                    _createElementVNode("span", null, _toDisplayString(_ctx.selectedWorkCenterLabel(value)), 1)
                  ]))
                : _createCommentVNode("", true)
            ]),
            option: _withCtx(({ option }) => [
              _createElementVNode("div", _hoisted_9, [
                _createElementVNode("div", {
                  style: _normalizeStyle([{"height":"20px","width":"6px","border-radius":"2px"}, { backgroundColor: _ctx.dotColor(option.work_center) }]),
                  class: "mr-2"
                }, null, 4),
                _createElementVNode("span", null, _toDisplayString(option.description), 1)
              ])
            ]),
            _: 1
          }, 8, ["modelValue", "options", "disabled"])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_10, [
      _cache[17] || (_cache[17] = _createElementVNode("small", {
        class: "p-inputgroup-addon font-semibold general-label-color",
        style: { width: '105px', minWidth: '105px' }
      }, " Start Time ", -1)),
      _createVNode(_component_Calendar, {
        modelValue: _ctx.barSelectedLocal.startDate,
        "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.barSelectedLocal.startDate) = $event)),
        dateFormat: "mm-dd-yy",
        inputClass: "text-sm",
        showTime: "",
        hourFormat: "24",
        showIcon: true,
        "input-style": { minWidth: '93px' },
        onDateSelect: _cache[7] || (_cache[7] = ($event: any) => (_ctx.dateSelected($event, 'from'))),
        disabled: !_ctx.isWorkOrderEditable(_ctx.workOrderLocal),
        "step-minute": _ctx.getCalendarTimeIncrement,
        "show-on-focus": false
      }, null, 8, ["modelValue", "disabled", "step-minute"])
    ]),
    _createElementVNode("div", _hoisted_11, [
      _cache[18] || (_cache[18] = _createElementVNode("small", {
        class: "p-inputgroup-addon font-semibold general-label-color",
        style: { width: '105px', minWidth: '105px' }
      }, " End Time ", -1)),
      _createVNode(_component_Calendar, {
        modelValue: _ctx.barSelectedLocal.endDate,
        "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.barSelectedLocal.endDate) = $event)),
        showIcon: "",
        inputClass: "text-sm",
        dateFormat: "mm-dd-yy",
        showTime: "",
        hourFormat: "24",
        "input-style": { minWidth: '93px' },
        onDateSelect: _cache[9] || (_cache[9] = ($event: any) => (_ctx.dateSelected($event, 'to'))),
        disabled: !_ctx.isWorkOrderEditable(_ctx.workOrderLocal),
        "step-minute": _ctx.getCalendarTimeIncrement,
        "show-on-focus": false
      }, null, 8, ["modelValue", "disabled", "step-minute"])
    ]),
    _createElementVNode("div", _hoisted_12, [
      _cache[19] || (_cache[19] = _createElementVNode("small", {
        class: "p-inputgroup-addon font-semibold general-label-color",
        style: { width: '105px', minWidth: '105px' }
      }, " Est. Run time ", -1)),
      _createVNode(_component_InputText, {
        disabled: "",
        value: _ctx.estRunTime,
        type: "text",
        class: "text-sm w-full"
      }, null, 8, ["value"])
    ]),
    _createElementVNode("div", _hoisted_13, [
      _cache[20] || (_cache[20] = _createElementVNode("small", {
        class: "font-semibold general-label-color",
        style: { width: '105px', minWidth: '105px' }
      }, " Notes: ", -1)),
      _createVNode(_component_Textarea, {
        value: _ctx.notes,
        onInput: _cache[10] || (_cache[10] = ($event: any) => (_ctx.notes = $event.target.value)),
        type: "text",
        class: "text-sm w-full mt-1 mb-2",
        rows: "5",
        disabled: !_ctx.isWorkOrderEditable(_ctx.workOrderLocal)
      }, null, 8, ["value", "disabled"])
    ]),
    (_ctx.isWorkOrderEditable(_ctx.workOrderLocal))
      ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
          _withDirectives(_createVNode(_component_Button, {
            icon: "pi pi-undo",
            "aria-label": "Undo",
            onClick: _ctx.undoChanges
          }, null, 8, ["onClick"]), [
            [_vShow, _ctx.showUndoButton]
          ]),
          (_ctx.showDeleteButton)
            ? (_openBlock(), _createBlock(_component_Button, {
                key: 0,
                icon: "pi pi-trash",
                "aria-label": "Remove",
                onClick: _ctx.onRemoveBar
              }, null, 8, ["onClick"]))
            : _createCommentVNode("", true),
          _withDirectives(_createVNode(_component_Button, {
            label: "Add Operation",
            "aria-label": "Add Operation",
            onClick: _ctx.addOperation
          }, null, 8, ["onClick"]), [
            [_vShow, !_ctx.isGenericBar && _ctx.showAddOperationButton]
          ]),
          (_ctx.hasChangeAccess)
            ? (_openBlock(), _createBlock(_component_Button, {
                key: 1,
                label: "Save",
                onClick: _ctx.onSaveBarData,
                disabled: !!_ctx.invalidFields.length
              }, null, 8, ["onClick", "disabled"]))
            : _createCommentVNode("", true)
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_15, [
          _createElementVNode("span", null, "Work Order Operation #" + _toDisplayString(_ctx.barSelectedLocal.oper) + " current quantity is 0.00. ", 1)
        ]))
  ]))
}